/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const adults_handicrafts : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HANDICRAFTS_TOOLS", "N\u00e1stroje"],
        ["HANDICRAFTS_COLORS", "Barva, lepidlo, povrchov\u00e1 \u00faprava"],
        ["HANDICRAFTS_FABRIC", "L\u00e1tky, pap\u00edr"],
        ["HANDICRAFTS_THREADS", "Vlna, nit\u011b, prov\u00e1zek"],
        ["HANDICRAFTS_SMALL_MATERIAL", "Drobn\u00fd materi\u00e1l"],
        ["HANDICRAFTS_FORMS", "Formy, p\u0159edlohy"],
        ["HANDICRAFTS_TUTORIALS", "N\u00e1vody"],
        ["HANDICRAFTS_MISC", "Jin\u00e9"],
    ],
    p_color: commonProp4,
};
