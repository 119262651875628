import {
    strollersAddQuestionUrl,
    strollersAddReviewScoreUrl,
    strollersAddReviewUrl,
    strollersAllReviewsPhotosGalleryUrl,
    strollersAllReviewsPhotosUrl,
    strollersArticlesOfSectionUrl,
    strollersArticlesUrl,
    strollersBrandsUrl,
    strollersChartsUrl,
    strollersComparisonUrl,
    strollersDetailUrl,
    strollersDetailVariantUrl,
    strollersEditDescription,
    strollersEditPhotosUrl,
    strollersListUrl,
    strollersMergeUrl,
    strollersPhotosGalleryUrl,
    strollersPhotoEditorUrl,
    strollersPhotoUrl,
    strollersRenameUrl,
    strollersReviewsUrl,
    strollersReviewPhotoUrl,
    strollersSearchByTypeUrl,
    strollersSearchUrl,
    strollersUrl,
    strollersVariantPhotosGalleryUrl,
    strollersVariantPhotoUrl,
    strollersWikiUrl,
} from 'mk2/apps/strollers/urls';
import { Route } from 'mk2/containers/Application/Application';

export const routes: Route[] = [{
    app: 'strollers',
    exact: true,
    path: strollersUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/Home/HomePage' /* webpackChunkName: "strollers.Home" */);
    },
    modules: [
        'mk2/apps/strollers/containers/Home/HomePage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersBrandsUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/Brands/BrandsPage' /* webpackChunkName: "strollers.Brands" */);
    },
    modules: [
        'mk2/apps/strollers/containers/Brands/BrandsPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersSearchUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollersSearch/StrollersSearchPage' /* webpackChunkName: "strollers.StrollersSearch" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollersSearch/StrollersSearchPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersMergeUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerMerge/StrollerMergePage' /* webpackChunkName: "strollers.StrollerMerge" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerMerge/StrollerMergePage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersSearchByTypeUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollersSearch/StrollersSearchPage' /* webpackChunkName: "strollers.StrollersSearch" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollersSearch/StrollersSearchPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersComparisonUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollersComparison/StrollersComparisonPage' /* webpackChunkName: "strollers.StrollersComparison" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollersComparison/StrollersComparisonPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersChartsUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/Charts/ChartsPage' /* webpackChunkName: "strollers.Charts" */);
    },
    modules: [ 'mk2/apps/strollers/containers/Charts/ChartsPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersListUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/BrandsStrollersList/BrandsStrollersListPage' /* webpackChunkName: "strollers.BrandsStrollersList" */);
    },
    modules: [
        'mk2/apps/strollers/containers/BrandsStrollersList/BrandsStrollersListPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersAddReviewUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerAddReview/StrollerAddReviewPage' /* webpackChunkName: "strollers.StrollerAddReview" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerAddReview/StrollerAddReviewPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersReviewPhotoUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerReviewPhoto/StrollerReviewPhotoPage' /* webpackChunkName: "strollers.StrollerReviewPhoto" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerReviewPhoto/StrollerReviewPhotoPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersAllReviewsPhotosGalleryUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerAllReviewsPhotosGallery/StrollerAllReviewsPhotosGalleryPage' /* webpackChunkName: "strollers.StrollerAllReviewsPhotosGallery" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerAllReviewsPhotosGallery/StrollerAllReviewsPhotosGalleryPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersAllReviewsPhotosUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerAllReviewsPhotos/StrollerAllReviewsPhotosPage' /* webpackChunkName: "strollers.StrollerAllReviewsPhotos" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerAllReviewsPhotos/StrollerAllReviewsPhotosPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersAddReviewScoreUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerAddReview/StrollerAddReviewPage' /* webpackChunkName: "strollers.StrollerAddReview" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerAddReview/StrollerAddReviewPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersReviewsUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerReviews/StrollerReviewsPage' /* webpackChunkName: "strollers.StrollerReviews" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerReviews/StrollerReviewsPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersAddQuestionUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerAddQuestion/StrollerAddQuestionPage' /* webpackChunkName: "strollers.StrollerAddQuestion" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerAddQuestion/StrollerAddQuestionPage' ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersVariantPhotosGalleryUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerProductPhotosGallery/StrollerProductPhotosGalleryPage' /* webpackChunkName: "strollers.StrollerProductPhotosGalleryPage" */);
    },
    modules: [
        'mk2/apps/strollers/containers/StrollerProductPhotosGallery/StrollerProductPhotosGalleryPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersPhotosGalleryUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerProductPhotosGallery/StrollerProductPhotosGalleryPage' /* webpackChunkName: "strollers.StrollerProductPhotosGalleryPage" */);
    },
    modules: [
        'mk2/apps/strollers/containers/StrollerProductPhotosGallery/StrollerProductPhotosGalleryPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersVariantPhotoUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerProductPhoto/StrollerProductPhotoPage' /* webpackChunkName: "strollers.StrollerProductPhotoPage" */);
    },
    modules: [
        'mk2/apps/strollers/containers/StrollerPhoto/StrollerPhotoPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersPhotoUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerProductPhoto/StrollerProductPhotoPage' /* webpackChunkName: "strollers.StrollerProductPhotoPage" */);
    },
    modules: [
        'mk2/apps/strollers/containers/StrollerPhoto/StrollerPhotoPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersWikiUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/WikiArticle/WikiArticlePage' /* webpackChunkName: "strollers.WikiArticle" */);
    },
    modules: [
        'mk2/apps/strollers/containers/WikiArticle/WikiArticlePage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersArticlesUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSectionsPage' /* webpackChunkName: "strollers.ArticlesOfSections" */);
    },
    modules: [
        'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSectionsPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersArticlesOfSectionUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSectionsPage' /* webpackChunkName: "strollers.ArticlesOfSections" */);
    },
    modules: [
        'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSectionsPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersRenameUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerRename/StrollerRenamePage');
    },
    modules: [
        'mk2/apps/strollers/containers/StrollerRename/StrollerRenamePage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersEditDescription,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerEditDescription/StrollerEditDescriptionPage');
    },
    modules: [
        'mk2/apps/strollers/containers/StrollerEditDescription/StrollerEditDescriptionPage',
    ],
},
{
    app: 'strollers',
    exact: true,
    path: strollersEditPhotosUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerEditPhotos/StrollerEditPhotosPage' /* webpackChunkName: "strollers.StrollerEditPhotos" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerEditPhotos/StrollerEditPhotosPage' ],
},
{
    app: 'strollers',
    exact: true,
    path: strollersPhotoEditorUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollerPhotoEditor/StrollerPhotoEditorPage' /* webpackChunkName: "strollers.StrollerPhotoEditor" */);
    },
    modules: [ 'mk2/apps/strollers/containers/StrollerPhotoEditor/StrollerPhotoEditorPage' ],
},

// Keep StrollersDetailPage as last item
{
    app: 'strollers',
    exact: true,
    path: strollersDetailVariantUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollersDetail/StrollersDetailPage' /* webpackChunkName: "strollers.StrollersDetail" */);
    },
    modules: [
        'mk2/apps/strollers/containers/StrollersDetail/StrollersDetailPage',
    ],
}, {
    app: 'strollers',
    exact: true,
    path: strollersDetailUrl,
    getPage: () => {
        return import('mk2/apps/strollers/containers/StrollersDetail/StrollersDetailPage' /* webpackChunkName: "strollers.StrollersDetail" */);
    },
    modules: [
        'mk2/apps/strollers/containers/StrollersDetail/StrollersDetailPage',
    ],
}];
