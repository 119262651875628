/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const ping_pong_bedminton_rackets : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("Acra"),
        d("Artis"),
        d("Atemi"),
        d("Babolat"),
        d("Barna Original"),
        d("Butterfly"),
        d("Carlton"),
        d("Cornilleau"),
        d("Donic"),
        d("Dr. Neubauer"),
        d("Dunlop"),
        d("Duvlan"),
        d("Get & Go"),
        d("Gewo"),
        d("Giant Dragon"),
        d("InSPORTline"),
        d("Joola"),
        d("Karakal"),
        d("Kock sport"),
        d("Merco"),
        d("Meteor"),
        d("Nils"),
        d("Nittaku"),
        d("Oliver"),
        d("OSP Palatinus"),
        d("Pongori"),
        d("ProKennex"),
        d("Schildkrot"),
        d("Sedco"),
        d("SMJ"),
        d("Spartan"),
        d("Spokey"),
        d("Sponeta"),
        d("Stiga"),
        d("Sulov"),
        d("TecnoPro"),
        d("Tibhair"),
        d("Tregare"),
        d("V3 tec"),
        d("Victor"),
        d("Vinex"),
        d("Wilson"),
        d("Yasaka"),
        d("Yonex"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
