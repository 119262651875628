import { createLikeableSchema, LikeableEntity } from 'mk2/containers/Like/Like.schemas';
import {
    BlogPostEntity,
    Entity,
    PhotoEntity,
    PostEntity, PostSchema,
    ProVendorEntity,
    StrollerEntity,
    UserEntity,
} from 'mk2/schemas';
import { schema } from 'normalizr';

export interface ArticleConceptPath {
    conceptId: number;
    title: string;
    articleSlug: string;
    categorySlug: string;
}

export interface WikiCategoryEntity extends Entity {
    id: number;
    name: string;
    slug: string;
    categoryArticleId: number;
    categoryArticleSlug: string;
}

export interface ExperiencePhotoEntity extends Entity {
    experience: ExperienceEntity;
    photofile: PhotoEntity;
}
export enum ExperienceVerificationStatus {
    NOT_WANT_VERIFIED = 0,
    WANT_VERIFIED = 1,
    VERIFIED = 2,

}

export interface ExperienceEntity extends Entity {
    id: number;
    text: string;
    title: string;
    created: string;
    approved: boolean;
    deleted: boolean;
    authorUser: UserEntity;
    likeable: LikeableEntity;
    positives: string[];
    negatives: string[];
    starsCount: number;
    photos: ExperiencePhotoEntity[];
    article: WikiArticleEntity;
    vendorReplyMessage: string;
    vendorReplyUser: UserEntity;
    vendorReplyVendor: ProVendorEntity;
    verificationStatus: number;
}

export interface AST {
    type: string;
    elements?: AST[];
    text?: string;

    /**
     * The linked object's type based on object's resolved_url
     * Use `obj_schema_key` instead `obj_type`!
     * @deprecated
     */
    obj_type?: ASTLinkedObjectType;

    /**
     * The linked object's content type's schema key
     * This is a new field - old link_to_object nodes may missing this field
     * TODO convert obj_type to obj_schema_key for old data
     */
    obj_schema_key?: string;

    obj_id?: number; // linked object's id
    obj_qoh?: string; // linked object's link's trailing query/hash part
}

export enum ASTLinkedObjectType {
    FORUM_POST_DETAIL = 'forum_post_detail',
    WIKI_ARTICLE = 'wiki_article',
    PHOTOBLOG_ARTICLE = 'photoblog_article',
    PHOTOBLOG_MESSAGE = 'photoblog_message',
    PHOTOBLOG_ALBUM = 'photoblog_album',
    STROLLERS_STROLLER_DETAIL = 'strollers_app_detail',
    STROLLERS_STROLLER_BRAND = 'strollers_app_list',
    ESHOP_COLLECTION = 'eshop_collection',
    ESHOP_CATEGORY = 'eshop_category',
}

export interface ASTLinkedObjectsData {
    [ASTLinkedObjectType.FORUM_POST_DETAIL]?: {
        [articleId: number]: {url: string, title: string},
    };
    [ASTLinkedObjectType.WIKI_ARTICLE]?: {
        [articleId: number]: {url: string, title: string, countExperiences: number},
    };
    [ASTLinkedObjectType.PHOTOBLOG_ARTICLE]?: {
        [articleId: number]: {url: string, title: string},
    };
    [ASTLinkedObjectType.PHOTOBLOG_MESSAGE]?: {
        [articleId: number]: {url: string, title: string},
    };
    [ASTLinkedObjectType.PHOTOBLOG_ALBUM]?: {
        [articleId: number]: {url: string, title: string},
    };
    [ASTLinkedObjectType.STROLLERS_STROLLER_DETAIL]?: {
        [articleId: number]: {url: string, title: string},
    };
    [ASTLinkedObjectType.STROLLERS_STROLLER_BRAND]?: {
        [articleId: number]: {url: string, title: string},
    };
    [ASTLinkedObjectType.ESHOP_COLLECTION]?: {
        [collectionId: string]: {url: string, title: string},
    };
    [ASTLinkedObjectType.ESHOP_CATEGORY]?: {
        [categoryId: string]: {url: string, title: string},
    };
}

export interface ASTInstagramData {
    [code: string]: {html: string};
}

export interface ProductBoxWidgetData {
    newHits: number;
    newMinPrice: string;
    newMaxPrice: string;
    oldHits: number;
    oldMinPrice: string;
    oldMaxPrice: string;
    eshopHits: number;
    eshopMinPrice: string;
    eshopMaxPrice: string;
}

export interface StrollerBoxWidgetData {
    title: string;
    slug: string;
    reviewsCount: number;
    reviewsStars: number;
    utmCampaign: string;
    strollerId: number;
    productId?: string;
    productTypeId?: string;
    photoUrl: string;
    isEshopProduct: boolean;
    index: number;
}

export interface ASTAdditionalDataFromServer {
    linkedObjects?: ASTLinkedObjectsData;
    instagramData?: ASTInstagramData;
    productBoxes?: Record<string, ProductBoxWidgetData>;
    strollerBoxes?: Record<string, StrollerBoxWidgetData>;
}
export interface WikiArticleEntity extends Entity {
    id: number;
    author: UserEntity;
    canVendorReplyOnExperiences: boolean;
    createdAt: string;
    modifiedAt: string;
    deletedAt: string;
    title: string;
    instrumentalTitle: string;
    photos: PhotoEntity[];
    bodyAST: AST;
    bodyASTAdditionalData: ASTAdditionalDataFromServer;
    extraEntitiesOfArticle: WikiArticleEntity[];
    extraEntitiesOfStroller: StrollerEntity[];
    extraEntitiesOfPost: BlogPostEntity[];
    countTopicContributors: {[topicSlug: string]: number};
    experiencesAllowed: boolean;
    experiencesStarsAllowed: boolean;
    experiencesPositivesNegativesAllowed: boolean;
    experiencesCount: number;
    experiencesScore: number;
    slug: string;
    likeable: LikeableEntity;
    experiences: ExperienceEntity[];
    category: WikiCategoryEntity;
    disabledAds: boolean;
    seoTitle: string; // text co ide do <title>...</title> a google zobrazuje v SERP
    seoDescription: string; // text co ide <meta name="description" content="..."> a google zobrazuje v SERP
    seoFaqRichSnippet?: string; // google zobrazuje v SERP
    editableByAmbassadorOfBrandId: number;
}

export const WikiCategorySchema = new schema.Entity('wiki.wikicategory');

export const ExperiencePhotoSchema = new schema.Entity('wiki.experiencephoto', {});

export const ExperienceSchema = new schema.Entity('wiki.experience', {
    likeable: createLikeableSchema('wiki.experience'),
    photos: [ExperiencePhotoSchema],
});

ExperiencePhotoSchema.define({
    experience: ExperienceSchema,
});

export const WikiArticleSchema = new schema.Entity('wiki.article', {
    experiences: [ExperienceSchema],
    category: WikiCategorySchema,
    likeable: createLikeableSchema('wiki.article'),
});

ExperienceSchema.define({ article: WikiArticleSchema });

export interface ArticleLinkDataEntity extends Entity {
    title: string;
    categorySlug: string;
    articleSlug: string;
    experiencesCount: number;
}

export const ArticleLinkDataSchema = new schema.Entity('articleLinkData', {});

export interface ReviewCategoryEntity extends Entity {
    id: number;
    article: WikiArticleEntity;
    parent: ReviewCategoryEntity;
    icon: string;
    position: number;
}

export const ReviewCategorySchema = new schema.Entity('wiki.reviewcategory', {
    article: WikiArticleSchema,
});

ReviewCategorySchema.define({ parent: ReviewCategorySchema });

// keep in sync with python code in wiki/models.py
export enum ProductSegment {
    Strollers = 'strollers',
    Carseats = 'car_seats',
}

// keep in sync with python code in wiki/models.py
export enum ProductCatalogImportStatus {
    Init = 0,
    Running = 1,
    Success = 2,
    Failed = 3,
}

export interface ProductCatalogImportEntity extends Entity {
    id: number;
    status: ProductCatalogImportStatus;
    catalog: ProductSegment;
    user: UserEntity;
    isDry: boolean;
    log: string;
    createdAt: string;
    updatedAt: string;
    finishedAt: string;
}

export const ProductCatalogImportSchema = new schema.Entity('wiki.reviewproductcatalogimport', {
});
