import { Entity, PhotoEntity, UserEntity } from 'mk2/schemas';
import { schema } from 'normalizr';

export enum MentionAction {
    FORUM_MSG = 1,
    BLOG_COMMENT_ALBUM = 2,
    BLOG_COMMENT_PHOTO = 3,
    BLOG_COMMENT_ARTICLE = 4,
    BLOG_COMMENT_TWITTER = 5,
    BLOG_ALBUM = 6,
    BLOG_PHOTO = 7,
    BLOG_ARTICLE = 8,
    BLOG_TWITTER = 9,
    VENDOR_PRESENTATION = 12, // TODO: not used anymore, remove this when there are no references in DB
    USER_PHOTOBLOG = 13,  // TODO: not used anymore, remove this when there are no references in DB
    FORUM_ANSWER = 14,
    RECOMMENDATION = 15,
    BAZAAR_SELLER = 16,  // TODO: not used anymore, remove this when there are no references in DB
    FORUM_THREAD_QUESTION = 17,
    FORUM_THREAD_DISCUSSION = 18,
    REVIEW_SELLER = 19,
    REVIEW_BUYER = 20,
    REVIEW_REPLY = 21,
    WIKI_EXP = 22,
    HEART_GOT = 23,
    HEART_GOT_WITH_MSG = 24,
    WISHLIST_PRICEOFF = 25,
    NEW_GROUP_POST = 27,
    BLOG_PHOTOS = 28,
    BLOG_AUTOMSG = 29,
    BLOG_COMMENT_AUTOMSG = 30,
    CONTEST_ANNOUNCEMENT = 31,
    BAZAAR_ADMIN_MODIFICATION = 32,
    HEART_THANKS = 33,
    BLOG_SHARE = 34,
    BLOG_COMMENT_SHARE = 35,
    TESTING_ANNOUNCEMENT = 36,
    USER_PROFILE = 37,
    TICKET_NEW_ANSWER = 38,
    BAZAAR_ADMIN_DELETION = 39,
    FORUM_ADMIN_COMMENT_DELETION = 40,
    FORUM_ADMIN_POST_DELETION = 41,
    FORUM_COUNSELLING_ANSWER = 42,
    GROUP_COMMENT = 43,
    GROUP_POST = 44,
    GROUP_CONTENT = 45,
    FORUM_CONTENT = 46,
    TICKET_NEW_TICKET = 47,
    TICKET_NEW_MESSAGE = 48,
    SETTINGS_INVALID_EMAIL = 49,
    STROLLER_REVIEW = 50,
    SELLING_STROLLER_REVIEW = 51,
    SELLING_STROLLER_REVIEW_TIPS = 52,
}

export enum MentionType {
    MENTION = 0,
    RECENT = 1,
    LIKE = 2,
    RECOMMENDATION = 3,
    COMMENT = 4,
    REVIEW = 5,
    ALSOCOMMENT = 6,
    HEART = 7,
    WISHLIST = 8,
    POST = 10,
    NEW_ALBUM_PHOTO = 11,  // new photo(s) added to album
    CONTEST = 12,
    BAZAAR_ADMIN_MODIFICATION = 13,  // your bazaar listing was adjusted by an admin,
    TESTING = 14,  // Modry konik testuje...
    MAGAZINE_PICK = 15, // pick article for magazine
    ALSOLIKE = 16,
    SHARE = 17,
    TICKET = 18, // new answer to your ticket
    ADMIN_DELETION = 19,
    COMPLAINT = 20,
    MEMBERSHIP_REQUEST = 21,
    SETTINGS = 22,
    POST_OWNER_DELETION = 23, // deletion of comment by parent post's author (see Deletable.DELETED_BY_PARENT_OWNER)
    SELLING_STROLLER_REVIEW = 24,
    SELLING_STROLLER_REVIEW_TIPS = 25,
}

declare type HeartType = number;

export interface MentionEntity extends Entity {
    type: MentionType;
    createdDate: string;
    objectName: any;
    text: string;
    action: MentionAction;
    subtype: HeartType;
    perex: string;
    photo?: PhotoEntity;
    objectId: number;
    authorUser: UserEntity;
    otherAuthors: UserEntity[];
    followChannelName: string;
    followChannelId: number;
}

export const MentionSchema = new schema.Entity('mentions', {
});
