/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const hiking_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("4F"),
        d("adidas"),
        d("alpine pro"),
        d("apasox"),
        d("aquawave"),
        d("arcore"),
        d("asics"),
        d("asolo"),
        d("axis"),
        d("axon"),
        d("bennon"),
        d("bergans"),
        d("bula"),
        d("burton"),
        d("cmp"),
        d("colmar"),
        d("columbia"),
        d("craft"),
        d("crossroad"),
        d("dynafit"),
        d("eleven"),
        d("etape"),
        d("hanwag"),
        d("high point"),
        d("highlander"),
        d("icebreaker"),
        d("jack wolfskin"),
        d("johanna"),
        d("kensis"),
        d("klimatex"),
        d("loap"),
        d("mc kinley"),
        d("meindl"),
        d("millet condor"),
        d("nike"),
        d("no name"),
        d("northfinder"),
        d("oakley"),
        d("pro touch"),
        d("regatta"),
        d("relax"),
        d("salewa"),
        d("salomon"),
        d("saxx"),
        d("scott"),
        d("sensor"),
        d("sir joseph"),
        d("sportalm"),
        d("sun valley"),
        d("tarrago"),
        d("terrex"),
        d("the north face"),
        d("toko"),
        d("trekmates"),
        d("trimm"),
        d("under armour"),
        d("unuo"),
        d("warmpeace"),
        d("willard"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
