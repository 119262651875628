import {
    BlogPostEntity,
    BlogPostSchema,
    CommentEntity,
    CommentSchema,
    Entity,
    UserEntity,
} from 'mk2/schemas';
import { schema } from 'normalizr';

export const deletionKey = (entity: string, id: number): string => `${entity}/${id}`;

export enum DeletionTargetType {
    PhotoblogComment = 'photoblog-comment',
    PhotoblogPost = 'photoblog-post',
}

export type DeletionTargetEntity = BlogPostEntity | CommentEntity;

interface UnionKey {
    schema: DeletionTargetType;
}

export interface DeletionEntity extends Entity {
    readonly user: UserEntity;
    readonly target: DeletionTargetEntity & UnionKey;
    readonly comment: string;
    readonly createdAt: string;
    readonly deletedAt: string;
}

export const DeletionTargetSchema = new schema.Union({}, 'target');

export const DeletionSchema = new schema.Entity('deletions', {
    target: DeletionTargetSchema,
});
