import { PAGE_SLUG } from 'mk/autogenerated/translations/urls.2f99b3c69c1be98699aa952488768c7a'
import { serializeFilterForPathname, serializeFilterForQuery } from 'mk/bazaar/product/filter/filterUtils';
import {
    bazaar_single_page_my_bazaar_category_filters_url,
    bazaar_single_page_my_bazaar_category_url,
    bazaar_single_page_product_grid_category_filters_url,
    bazaar_single_page_product_grid_category_url,
    bazaar_single_page_seller_category_filters_url,
    bazaar_single_page_seller_category_url,
    bazaar_single_page_wishlist_category_filters_url,
    bazaar_single_page_wishlist_category_url,
} from 'mk/urls/functions';
import { FilterType } from 'mk2/apps/bazaar/schemas';
import { ID_RE, USERNAME } from 'mk2/helpers/urls';

const CATEGORY = ':category([a-z\\d_\\-]+)';
const PRODUCT_HASH = ':hash([0-9a-z]+)';
const PRODUCT_SLUG = ':title([0-9a-z\\d_\\-]+)';
const BAZAAR_ORDER_ID = ':bazaarOrderId([0-9a-z]+)'; // id === slug
const PAYMENT_ID = `:paymentId(${ID_RE})`;

const filterParams = `:category([a-z\\d_-]+)?/:filters([\\w-]+)?/:page(${PAGE_SLUG}-\\d+)?/`;

export const bazaarUrl = `/market/`;
export const bazaarCheckoutUrl = `/market/${CATEGORY}/${PRODUCT_HASH}/${PRODUCT_SLUG}/checkout/`;
export const bazaarCheckoutPaymentSuccessUrl = `/market/checkout/success/${PAYMENT_ID}`;
export const bazaarCheckoutPaymentFailureUrl = `/market/checkout/failure/${PAYMENT_ID}`;
export const bazaarOrderProblemUrl = `/market/${CATEGORY}/${PRODUCT_HASH}/${PRODUCT_SLUG}/problem/`;
export const bazaarOrderStateUrl = `/market/${BAZAAR_ORDER_ID}/state/`;
export const bazaarAllDropoffPointsUrl = '/market/all-dropoff-points/';
export const bazaarSellerSearchUrl = `/market/seller/search/`;
export const bazaarSellerUrl = `/market/seller/${USERNAME}/`;
export const bazaarSellerBazaarUrl = `/market/seller/${USERNAME}/${filterParams}`;
export const bazaarSellerReviewsFromBuyersUrl = `/market/seller/${USERNAME}/reviews/`;
export const bazaarSellerReviewsFromSellersUrl = `/market/seller/${USERNAME}/reviews-from-sellers/`;
export const bazaarMyBazaarUrl = `/market/my_bazaar/${USERNAME}/${filterParams}`;
export const bazaarMyReviewsFromBuyersUrl = `/market/my_bazaar/${USERNAME}/reviews/`;
export const bazaarMyReviewsFromSellersUrl = `/market/my_bazaar/${USERNAME}/reviews-from-sellers/`;
export const bazaarMyWidgetUrl = `/market/my_bazaar/${USERNAME}/widget/`;
export const bazaarMyTransactionsUrl = `/market/my_bazaar/${USERNAME}/buyers/`;
export const bazaarProductDetailUrl = `/market/${CATEGORY}/${PRODUCT_HASH}/${PRODUCT_SLUG}/`;
export const bazaarGridUrl = `/market/${filterParams}`;
export const bazaarWishlistInactiveUrl = `/market/wishlist/${USERNAME}/inactive/:page(${PAGE_SLUG}-\\d+)?/`;
export const bazaarWishlistUrl = `/market/wishlist/${USERNAME}/${filterParams}`;
export const bazaarShoppingUrl = `/market/shopping/${USERNAME}/`;
export const bazaarCreateProductUrl = `/market/edit/`;
export const bazaarEditProductUrl = `/market/edit/${CATEGORY}/${PRODUCT_HASH}/${PRODUCT_SLUG}/`;
export const bazaarWatchdogSettingsUrl = `/market/watchdog/settings/:token([0-9a-zA-Z-_]+:[0-9a-zA-Z-_]+:[0-9a-zA-Z-_]+)?/`;
export const bazaarLastProductNotFoundUrl = `/market/my_bazaar/edit-last-stroller-not-found/`;

export function bzCategoryUrl(cat, filter, filterType: FilterType) {
    const pathnameFilters = serializeFilterForPathname(filter);
    const query = serializeFilterForQuery(filter, true);

    if (filterType === FilterType.BAZAAR) {
        return pathnameFilters
            ? bazaar_single_page_product_grid_category_filters_url(cat, pathnameFilters, query)
            : bazaar_single_page_product_grid_category_url(cat, query);
    }
    if (filterType === FilterType.MY_BAZAAR) {
        return pathnameFilters
            ? bazaar_single_page_my_bazaar_category_filters_url(filter.users[0], cat, pathnameFilters, query)
            : bazaar_single_page_my_bazaar_category_url(filter.users[0], cat, query);
    }
    if (filterType === FilterType.HER_BAZAAR) {
        return pathnameFilters
            ? bazaar_single_page_seller_category_filters_url(filter.users[0], cat, pathnameFilters, query)
            : bazaar_single_page_seller_category_url(filter.users[0], cat, query);
    }
    if (filterType === FilterType.WISHLIST) {
        return pathnameFilters
            ? bazaar_single_page_wishlist_category_filters_url(filter.users[0], cat, pathnameFilters, query)
            : bazaar_single_page_wishlist_category_url(filter.users[0], cat, query);
    }
    return '';
}
