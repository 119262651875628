/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const sleepwear : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["ONEPIECE", "Overal na span\u00ed"],
        ["PYJAMAS", "Dvojd\u00edln\u00e9 py\u017eamo"],
        ["NIGHTIES", "No\u010dn\u00ed ko\u0161ile"],
        ["DRESSING_GOWNS", "\u017dupany"],
    ],
    p_size: commonProp7,
    p_length: [
        ["SHORT_LEG_LENGTH", "Kr\u00e1tk\u00e9"],
        ["LONG_LEG_LENGTH", "Dlouh\u00e9"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
