/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const skis : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: [
        d("<70 cm"),
        d("70-79 cm"),
        d("80-89 cm"),
        d("90-99 cm"),
        d("100-109 cm"),
        d("110-119 cm"),
        d("120-129 cm"),
        d("130-139 cm"),
        d("140-149 cm"),
        d("150-159 cm"),
        d("160-169 cm"),
        d("170-180 cm"),
    ],
    p_brand: [
        d("Atomic"),
        d("Blizzard"),
        d("Dynastar"),
        d("Elan"),
        d("FISCHER"),
        d("Hamax"),
        d("Head"),
        d("Movement"),
        d("ROSSIGNOL"),
        d("Salomon"),
        d("Scott"),
        d("Shumee"),
        d("Volkl"),
        d("Wedze"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
