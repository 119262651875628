import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
import * as SentryBrowser from '@sentry/browser';
import rootSagas from 'mk2/apps/forum/sagas';
import { appInit } from 'mk2/client/init';
import Application from 'mk2/containers/Application/Application';
import { routes } from 'mk2/router';
import * as schemas from 'mk2/schemas';
import { initSentry } from 'mk2/sentry';

schemas.load(); // let the main schema be the first loaded schema in the browser

initSentry(SentryBrowser);

// svg styles are already included, don't load again
faConfig.autoAddCss = false;

appInit(
    Application,
    routes,
    rootSagas,
);
