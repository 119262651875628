import { likeKey, LikeableEntity } from './Like.schemas';

export interface LikeableEntitiesState {
    [entitySlashId: string]: LikeableEntity;
}

export const getIsLiked = (state: LikeableEntitiesState, entity: string, id: number): boolean => {
    const key = likeKey(entity, id);
    return state && state[key] ? state[key].isLiked : false;
};
export const getLikesCount = (state: LikeableEntitiesState, entity: string, id: number): number => {
    const key = likeKey(entity, id);
    return state && state[key] ? state[key].likesCount : 0;
};
