import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { BooleanPair } from 'mk2/apps/strollers/features/other';
import React from 'react';
import styles from './BooleanMark.mscss';

interface OwnProps {
    booleanPair: BooleanPair;
    className?: string;
}

const Text = (props) => {
    const { text } = props;
    return text ? (
        <span className={styles.BooleanMark__text}> {text}</span>
    ) : null;
};

const Yes = (props) => {
    const { className, text } = props;
    return (
        <span className={cx(styles.BooleanMark__mark, className)}>
            <FontAwesomeIcon icon={faCheck} className={styles['BooleanMark__mark--yes']} />
            <Text text={text} />
        </span>
    );
};

const No = (props) => {
    const { className } = props;
    return (
        <FontAwesomeIcon icon={faTimes} className={cx(styles.BooleanMark__mark, className, styles['BooleanMark__mark--no'])} />
    );
};

export class BooleanMark extends React.Component<OwnProps> {
    public render() {
        const [ boolValue, text ] = this.props.booleanPair;
        const Mark = boolValue ? Yes : No;
        return (
            <Mark className={this.props.className} text={text} />
        );
    }
}
