/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const hiking_poles : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("acra"),
        d("axon"),
        d("black diamond"),
        d("blizzard"),
        d("camp"),
        d("carter"),
        d("crossroad"),
        d("ferrino"),
        d("fizan"),
        d("gabel"),
        d("hannah"),
        d("husky"),
        d("lafuma"),
        d("leki"),
        d("msr"),
        d("northfinder"),
        d("spokey"),
        d("sport team"),
        d("sulov"),
        d("truly"),
        d("vipole"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
