/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp17 } from './commonProp17';

export const multimedia_books : {[key: string]: Array<[string, string]>} = {
    p_for_whom: [
        ["SMALL_KIDS", "Pro nejmen\u0161\u00ed"],
        ["SCHOOL_KIDS", "\u0160kol\u00e1k"],
        ["YOUTH", "Ml\u00e1de\u017e 15+"],
        ["FOR_ADULTS", "Pro dosp\u011bl\u00e9"],
        ["OTHER_AGE", "Neuveden\u00fd"],
    ],
    p_subject: [
        ["BOOK_FIRST_BOOKS", "Prvn\u00ed knihy"],
        ["BOOK_FAIRYTALES", "Poh\u00e1dky, p\u0159\u00edb\u011bhy"],
        ["BOOK_ENCYCLOPEDIA", "Encyklopedie a nau\u010dn\u00e9 knihy"],
        ["BOOK_EDUCATION", "U\u010debnice, skripta"],
        ["BOOK_HOBBIES", "Hobby a voln\u00fd \u010das"],
        ["BOOK_COVERING", "Z\u00e1lo\u017eky a obaly na knihy"],
        ["BOOK_POEMS_RHYMES", "B\u00e1sni\u010dky, \u0159\u00edkanky"],
        ["BOOK_FICTION", "Rom\u00e1ny, beletrie"],
        ["BOOK_POETRY", "Poezie"],
        ["BOOK_FOOD_RECEPIES", "Va\u0159en\u00ed, pe\u010den\u00ed a v\u00fd\u017eiva"],
        ["BOOK_HOME_GARDEN", "Domov a zahrada"],
        ["BOOK_PREGNANCY_BIRTH", "T\u011bhotenstv\u00ed, porod a kojen\u00ed"],
        ["BOOK_FAMILY_RELATIONSHIPS", "P\u00e9\u010de o d\u00edt\u011b, v\u00fdchova"],
        ["BOOK_SPIRITUAL", "Ezoterika, astrologie"],
        ["BOOK_HEALTH", "Zdrav\u00ed a fitness"],
        ["BOOK_TRAVEL", "Cestov\u00e1n\u00ed"],
        ["BOOK_OTHER", "Jin\u00fd"],
    ],
    p_language: commonProp17,
};
