/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const household_help : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["REGULAR_HOUSEHOLD_CLEANING", "\u00daklid dom\u00e1cnosti b\u011b\u017en\u00fd"],
        ["WASHING_WINDOWS", "Myt\u00ed oken"],
        ["WASHING_AND_IRONING", "Pran\u00ed a \u017eehlen\u00ed"],
        ["SHOPPING_AND_DELIVERY", "N\u00e1kupy a don\u00e1\u0161ky"],
        ["COOKING_AND_BAKING", "Va\u0159en\u00ed a pe\u010den\u00ed"],
        ["ANIMAL_CARE", "P\u00e9\u010de o zv\u00ed\u0159ata"],
        ["GARDEN_MAINTENANCE", "\u00dadr\u017eba zahrady"],
        ["BIG_CLEANING", "Velk\u00fd \u00faklid"],
        ["OTHER_HOUSEHOLD_HELP", "Jin\u00e1 v\u00fdpomoc"],
    ],
    p_space_type: [
        ["FLAT_1_TO_3_ROOMS", "1-3-pokojov\u00fd byt"],
        ["FLAT_4_PLUS_ROOMS", "4 a v\u00edce pokojov\u00fd byt"],
        ["FAMILY_HOUSE", "Rodinn\u00fd d\u016fm"],
        ["OFFICE_SPACE", "Kancel\u00e1\u0159sk\u00e9 prostory"],
        ["COMMON_NONRESIDENTIAL_PREMISES", "Spole\u010dn\u00e9 nebytov\u00e9 prostory"],
        ["GARDEN", "Zahrada"],
        ["OTHER_SPACE_TYPE", "Jin\u00fd"],
    ],
    p_frequency: [
        ["ABRUPTLY", "N\u00e1razov\u011b"],
        ["EVERY_DAY", "Ka\u017ed\u00fd den"],
        ["ONCE_PER_WEEK", "1x t\u00fddn\u011b"],
        ["OTHER_FREQUENCY", "Jin\u00e1"],
    ],
};
