/**
 * Sanitize HTML from text - should be used only in the browser
 *
 * @param body
 * @returns string
 */
export const sanitizeString = (body: string) => {
    if (!body) {
        return '';
    }
    body = body
        // removing style tags
        .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '')
        // removing comments
        .replace(/<!--.*?-->/gi, '')
        // removing html tags
        .replace(/(<([^>]+)>)/ig, '')
        // if more then 2 empty lines in a row, reduce them to 2 empty lines
        .replace(/(\s*\n){3,}/g, '\n\n');

    // replacing html entities and more sanitizing
    const doc = new DOMParser().parseFromString(body, 'text/html');
    return doc.documentElement.textContent;
};

/**
 * Replace all <br> tags with newline
 *
 * @param text
 * @returns string
 */
export const br2n = (text: string) => {
    if (!text) {
        return '';
    }

    const regexp = /(<br[^>]*>)/ig;
    return text.replace(regexp, '\n');
};

/**
 * Replace special HTML entities to their applicable characters
 *
 * @param text
 * @returns string
 */
export const htmlSpecEntities2char = (text: string) => {
    if (!text) {
        return '';
    }
    const mapping: Array<Array<(string | RegExp)>> = [
        [/(&quot;)/ig, '"'],
        [/(&amp;)/ig, '&'],
        // ignore angle brackets (they would be consumed by sanitizeString())
        // [/(&lt;)/ig, '<'],
        // [/(&gt;)/ig, '>'],
        [/(&euro;)/ig, '€'],
    ];
    let pair: any;
    for (pair of mapping) {
        text = text.replace(pair[0], pair[1]);
    }
    return text;
};


/**
 * Check if value is not nullish
 *
 * @param value
 * @returns boolean
 */
export const hasVal = (value: any) => {
    return (value !== undefined) && (value !== null);
};
