/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const tennis_squash_balls : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("Acra"),
        d("Artengo"),
        d("Babolat"),
        d("Dunlop"),
        d("Head"),
        d("Kuikma"),
        d("Merco"),
        d("Penn"),
        d("Robin Soderling"),
        d("Sandever"),
        d("Sedco"),
        d("Slazenger"),
        d("SMJ Sport"),
        d("Spartan"),
        d("Stepanek"),
        d("Tecnifibre"),
        d("Tretorn"),
        d("Wilson"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
