/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp9: Array<[string, string]> = [
    ["SEASON_SUMMER", "lehk\u00e9 / letn\u00ed"],
    ["SEASON_WINTER", "hodn\u011b tepl\u00e9 / zimn\u00ed"],
    ["SEASON_OTHER", "jin\u00e9"],
];
