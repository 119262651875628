import { anltx_api_ab_url } from 'mk/urls/functions';
import { abFetchApi, AB_FETCH, ABFetchAction } from 'mk2/containers/AB/AB.actions';
import { ABsState } from 'mk2/containers/AB/AB.reducers';
import { XHRAction } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { AppState } from 'mk2/reducers';
import { isSuccess } from 'mk2/reducers/loadingState';
import React from 'react';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

const logger = getLogger('AB.sagas');

function* abFetch({ names, xhr }: ABFetchAction & XHRAction) {
    const ABs: ABsState = yield select((state: AppState) => state.containers.global.ab) || {};
    const namesToFetch = names
        .split(/,/)
        .filter((name) => ABs[name] === undefined || isSuccess(ABs[name].loadingState));

    if (!namesToFetch.length) {
        return;
    }

    names = namesToFetch.join(',');

    yield put(abFetchApi.request(names));

    try {
        const response = yield call(
            () => xhr.get(anltx_api_ab_url(), { name: names }),
        );

        yield put(abFetchApi.success(names, response.body));
    } catch (error) {
        logger.error(error);
        yield put(abFetchApi.failure(names, error));
    }
}

export default function* root() {
    yield all([
        takeEvery(AB_FETCH, abFetch),
    ]);
}
