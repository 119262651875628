import { BASE_URL } from 'mk/settings';
import { Route } from 'mk2/containers/Application/Application';
import { matchPath } from 'react-router';
import { parse } from 'url';

export function hasRoute(to: string, routes: Route[]) {
    const parsed = parse(to);

    // Same base url
    if (`${parsed.protocol}//${parsed.hostname}` === BASE_URL
        || (!parsed.protocol && !parsed.host)) {

        // Check if we have a route for this URL
        for (const route of routes) {
            if (!route.path) { // Ignore all catch routes
                continue;
            }

            const match = matchPath(parsed.pathname, route);
            if (match) {
                return true;
            }
        }

        // No route matches this link - use <a>
        return false;
    } else {
        // Different hostname - always use <a>
        return false;
    }
}
