import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { SERVER_FULL_NAME, SERVER_SLOGAN } from 'mk/autogenerated/translations/Header.072c0ff9b83d49c26107d384ca3c6c23'
import assetUrl from 'mk/common/assetUrl';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { homepageUrl } from 'mk2/apps/home/urls';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import React from 'react';
import styles from './Header.mscss';

interface OwnProps {
    /** Centered text */
    heading?: string;

    /** Left content - e.g. back button or hamburger menu */
    left?: React.ReactNode;

    /** Right content - e.g. primary action button */
    right?: React.ReactNode;

    /** Boolean value if we are on the mobile */
    isMobile: boolean;

    /** Should we display a notification circle on the hamburger/ */
    showNotificationsDot?: boolean;

    /** Should we display a logo instead of heading text? */
    showLogo?: boolean;
}

export class Header extends React.PureComponent<OwnProps> {

    public render() {
        const {
            heading, left, right, isMobile, showNotificationsDot, showLogo,
        } = this.props;

        const logo = (
            <Img
                width={29}
                height={23}
                alt={SERVER_FULL_NAME + ' - ' + SERVER_SLOGAN}
                src={assetUrl + 'img/logos/ver-4/horse@2x.png'}
                lazy={false}
            />
        );

        const title = !!heading
            ? heading
            : showLogo
                ? (
                    <Link
                        className={styles.Header__logo}
                        title={SERVER_FULL_NAME + ' - ' + SERVER_SLOGAN}
                        to={homepageUrl}
                    >
                        {logo}
                    </Link>
                )
                : null;

        const canHaveHamburger = isMobile;
        const hasLongTitle = !!heading && heading.length > 25;

        const hasLeft = !!left || canHaveHamburger;
        const hasMiddle = hasLongTitle && !!title;
        const hasRight = !!right;

        return (
            <div
                className={cx(
                    styles.Header,
                    hasRight && !hasLeft && !hasMiddle && styles['Header--rightOnly'],
                )}
            >
                {!hasLongTitle && !!title && (
                    // nadpis/logo su vycentrovane na stred obrazovky
                    <div className={styles.Header__screenCenter}>
                        {title}
                    </div>
                )}

                {hasLeft && (
                    <div className={styles.Header__left}>
                        {!!left
                            ? left
                            : canHaveHamburger && (
                                <div className={styles.Header__hamburger}>
                                    <label htmlFor="mobileMenuTrigger" className={styles['Header__hamburger--icon']}>
                                        <FontAwesomeIcon icon={faBars} />
                                        {showNotificationsDot && (
                                            <span className={styles['Header__hamburger--notification']} />
                                        )}
                                    </label>
                                </div>
                            )
                        }
                    </div>
                )}

                {hasMiddle && (
                    // nadpis je zarovnany dolava
                    <div
                        className={cx(
                            styles.Header__middle,
                            !hasLeft && styles['Header__middle--noLeft'],
                        )}
                    >
                        {title}
                    </div>
                )}

                {hasRight && (
                    <div className={styles.Header__right}>
                        {right}
                    </div>
                )}
            </div>
        );
    }
}
