import { MenuToggleAction, MENU_TOGGLE } from 'mk2/containers/Menu/Menu.actions';
import { Reducer } from 'redux';

interface OneMenuState {
    show: boolean;
}

export interface MenuState {
    [menuId: string]: OneMenuState;
}

export const menuReducer: Reducer<MenuState> = (state: MenuState = {}, action: MenuToggleAction) => {
    switch (action.type) {
        case MENU_TOGGLE:
            return {
                ...state,
                [action.menuId]: {
                    ...state[action.menuId],
                    // When action.show is not specified - toggle current state
                    show: action.show === undefined ? !(state[action.menuId] && state[action.menuId].show) : action.show,
                },
            };
        default:
            return state;
    }
};
