import { Features } from 'mk2/apps/strollers/schemas';
import { findCountryById, niceSort } from 'mk2/apps/strollers/utils';
import React from 'react';
import styles from './CountriesFeature.mscss';

interface OwnProps {
    features: Features;
    feature: any;
}

type Props = OwnProps;

export class CountriesFeature extends React.Component<Props> {
    public render() {
        const { feature, features } = this.props;

        const arrValue = feature.rawValue(features);
        if (!arrValue || arrValue.length === 0) {
            return null;
        }

        let countryNames: string[] = arrValue.map((cid) => (
            findCountryById(cid)?.name || `COUNTRY-${cid}`
        ));
        countryNames = niceSort(countryNames);

        return (
            <div className={styles.CountriesFeature}>
                {countryNames.join(', ')}
            </div>
        );
    }
}
