/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp18: Array<[string, string]> = [
    d("Acer"),
    d("ALCATEL"),
    d("Apple"),
    d("ASUS"),
    d("Belkin"),
    d("BlackBerry"),
    d("Canon"),
    d("Caselogic"),
    d("Dell"),
    d("Dicota"),
    d("Fuji"),
    d("Garmin"),
    d("Genius"),
    d("GOPRO"),
    d("Hama"),
    d("HP (Hewlett-Packard)"),
    d("HTC"),
    d("Huawei"),
    d("IBM"),
    d("Lamax"),
    d("Lenovo"),
    d("LG"),
    d("Lowepro"),
    d("Microsoft"),
    d("Nikon"),
    d("Nokia"),
    d("Olympus"),
    d("Panasonic"),
    d("Philips"),
    d("Prestigio"),
    d("Samsonite"),
    d("Samsung"),
    d("Sony"),
    d("Trust"),
    d("Xiaomi"),
    ["HANDMADE", "Handmade"],
    ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
];
