/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const photography : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PHOTOGRAPHY_BACKGROUNDS", "Fotopozad\u00ed"],
        ["PHOTOGRAPHY_PROPS", "Rekvizity a dopl\u0148ky na focen\u00ed"],
    ],
};
