/**
 * Cache last value based on arguments
 *
 * @param lazy if true it behaves as React.useMemo() but can be used outside of React.FunctionComponent
 */
export function cacheLast<T = any>(lazy?: false): (fn: T, ...args: any[]) => T;
export function cacheLast<T = any>(lazy?: true): (fn: () => T, ...args: any[]) => T;
export function cacheLast<T = any>(lazy: boolean = false) {
    let lastArgs = [];
    let lastFn;
    let lastThis;

    const isArgEqualToLast = (newArg: any, index: number) => newArg === lastArgs[index];

    return function(fn, ...args) {
        if (lastFn
            && lastThis === this
            && lastArgs.length === args.length
            && args.every(isArgEqualToLast)) {
            return lastFn;
        }

        lastThis = this;
        lastArgs = args;
        lastFn = lazy ? fn() : fn;
        return lastFn;
    };
}

export function fastArrayIsEqualShallow(a: any[], b: any[]): boolean {
    if (a && b) {
        if (a.length !== b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    } else {
        return !a && !b;  // true if both are null, false otherwise
    }
}

interface LRUCacheEntry<V> {
    key: any[];
    value: V;
}

export class LRUCache<V> {
    private limit: number;
    private entries: Array<LRUCacheEntry<V>> = [];

    constructor(limit: number) {
        this.limit = limit;
    }

    public get(key: any[]) {
        const cacheIndex = this.entries.findIndex((entry) => fastArrayIsEqualShallow(key, entry.key));

        // We found a cached entry
        if (cacheIndex > -1) {
            const entry = this.entries[cacheIndex];

            // Cached entry not at top of cache, move it to the top
            if (cacheIndex > 0) {
                this.entries.splice(cacheIndex, 1);
                this.entries.unshift(entry);
            }

            return entry.value;
        }

        // No entry found in cache, return null
        return undefined;
    }

    public put(key: any[], value: V) {
        if (!this.get(key)) {
            this.entries.unshift({ key, value });
            if (this.entries.length > this.limit) {
                this.entries.pop();
            }
        }
    }
}

export function memoizeLRU<F extends Function>(limit: number, fn: F): F { // tslint:disable-line:ban-types
    const cache = new LRUCache(limit);

    return ((...args: any[]) => {
        let value = cache.get(args);

        if (value === undefined) {
            value = fn.apply(fn, args);
            cache.put(args, value);
        }

        return value;
    }) as any;
}

export function memoizeOne<F extends Function>(fn: F): F { // tslint:disable-line:ban-types
    return memoizeLRU(1, fn);
}
