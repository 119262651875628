/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const hobby_classes : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["MUSIC_CLASS", "Hudba"],
        ["THEATER_AND_SINGING_CLASS", "Divadlo a zp\u011bv"],
        ["SPORTS_AND_DANCING_CLASS", "Sport a tanec"],
        ["HORSEBACK_RIDING_CLASS", "Jezdectv\u00ed"],
        ["CERAMICS_CLASS", "Keramika"],
        ["MODELER_AND_HANDYMAN_CLASS", "Model\u00e1\u0159 a kutil"],
        ["MULTIMEDIA_AND_GAMES_CLASS", "Multim\u00e9dia a PC hry"],
        ["OUTDOOR_AND_SELFDEFENSE_CLASS", "Outdoor a sebeobrana"],
        ["SCIENCE_AND_BREEDING_CLASS", "P\u0159\u00edrodov\u011bda a chovatelstv\u00ed"],
        ["CREATING_AND_SEWING_CLASS", "Tvo\u0159en\u00ed a \u0161it\u00ed"],
        ["ART_ACTIVITIES_CLASS", "V\u00fdtvarn\u00e9 \u010dinnosti"],
        ["FOREIGN_LANGUAGES_CLASS", "Ciz\u00ed jazyky"],
    ],
    p_for_whom: [
        ["FOR_KIDS", "Pro d\u011bti"],
        ["FOR_PARENTS_WITH_KIDS", "Pro rodi\u010de s d\u011btmi"],
        ["FOR_ADULTS", "Pro dosp\u011bl\u00e9"],
    ],
};
