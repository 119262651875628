import {
    BlogsLoadUserGroupMembershipsSuccessAction,
    BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_SUCCESS,
} from 'mk2/apps/blogs/containers/BlogsShared/BlogsShared.actions';
import { tupdate } from 'mk2/helpers/types';
import { Reducer } from 'redux';

export interface UserGroupsState {
    memberships: number[];
}

export const initialuserGroupsState: UserGroupsState = {
    memberships: [],
};

type BlogsSharedActions =
    BlogsLoadUserGroupMembershipsSuccessAction;

const reducer: Reducer<UserGroupsState> = (state: UserGroupsState = initialuserGroupsState, action: BlogsSharedActions) => {
    switch (action.type) {
        case BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_SUCCESS:
            return tupdate(state, {
                memberships: action.memberships,
            });

        default:
            return state;
    }
};

export default reducer;
