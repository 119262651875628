/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const floorball_sticks : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("exel"),
        d("fatpipe"),
        d("freez"),
        d("kensis"),
        d("oxdog"),
        d("salming"),
        d("Schildkrot"),
        d("Spartan Sport"),
        d("Stiga"),
        d("unihoc"),
        d("zone"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
