/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const home_food_storage : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FOOD_CONTAINERS", "D\u00f3zy, boxy a misky"],
        ["LUNCHBOXES", "Termota\u0161ky a termoobaly"],
        ["FOOD_STORAGE_BAGS", "S\u00e1\u010dky, f\u00f3lie a pytl\u00edky na potraviny"],
        ["FOOD_CONSERVING", "Zava\u0159ov\u00e1n\u00ed"],
        ["OTHER_FOOD_STORAGE", "Jin\u00e9"],
    ],
};
