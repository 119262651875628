import { CreateDeleteActionFunction } from 'mk2/containers/Deletion/Deletion.actions';
import { initialDeletionsState, DeletionsState } from 'mk2/containers/Deletion/Deletion.reducers';
import { deletionKey, DeletionTargetEntity, DeletionTargetType } from 'mk2/containers/Deletion/Deletion.schemas';
import { memoizeLRU, memoizeOne } from 'mk2/helpers/cache';
import { AppState } from 'mk2/reducers';
import { BlogPostEntity, CommentEntity } from 'mk2/schemas';

export const getDeletionsState = (state: AppState): DeletionsState =>
    state.containers.global.deletion || initialDeletionsState;

export const getDeletionShownForTarget = memoizeOne(
    (deletionsState: DeletionsState, targetType: DeletionTargetType, targetId: number): boolean => {
        const key = deletionKey(targetType, targetId);
        return deletionsState[key] ? deletionsState[key].show : false;
    },
);

const getDeletionsShownForTargets = memoizeLRU(50,
    (deletionsState: DeletionsState, targetType: DeletionTargetType, targets: DeletionTargetEntity[]): boolean[] =>
        targets.map((target) => {
            const key = deletionKey(targetType, target.id);
            return deletionsState[key] ? deletionsState[key].show : false;
        }),
);

export const getDeletionsShownForBlogPosts = (state: AppState, posts: BlogPostEntity[]): boolean[] =>
    getDeletionsShownForTargets(getDeletionsState(state), DeletionTargetType.PhotoblogPost, posts);

export const getDeletionsShownForBlogComments = (state: AppState, comments: CommentEntity[]): boolean[] =>
    getDeletionsShownForTargets(getDeletionsState(state), DeletionTargetType.PhotoblogComment, comments);

export const getDeletionCreateDeleteAction = (state: AppState, targetType: DeletionTargetType, targetId: number): CreateDeleteActionFunction => {
    const deletionsState = getDeletionsState(state);
    const key = deletionKey(targetType, targetId);
    return deletionsState[key] ? deletionsState[key].createDeleteAction : null;
};
