import {
    InstallPromptSetReadyAction,
    INSTALL_PROMPT_SET_READY,
} from 'mk2/containers/InstallPrompt/InstallPrompt.actions';
import { Reducer } from 'redux';

export interface InstallPromptState {
    ready: boolean;
}

const initialInstallPromptState: InstallPromptState = {
    ready: false,
};

export const installPromptReducer: Reducer<InstallPromptState> =
    (state: InstallPromptState = initialInstallPromptState, action: InstallPromptSetReadyAction) =>
{
    switch (action.type) {
        case INSTALL_PROMPT_SET_READY:
            return {
                ...state,
                ready: action.ready,
            };
        default:
            return state;
    }
};
