/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp22: Array<[string, string]> = [
    ["UNDER_ONE_YEAR", "Do 1 roku"],
    ["ONE_TO_FIVE_YEARS", "1-5 let"],
    ["MORE_THAN_FIVE_YEARS", "D\u00e9le ne\u017e 5let"],
];
