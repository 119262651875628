import { ComplaintFormData } from 'mk2/containers/Complaint/ComplaintForm';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { Action } from 'redux';

// API
export interface ComplaintSaveAction extends Action {
    readonly entity: string;
    readonly id: number;
    readonly complaint: ComplaintFormData;
}

export const COMPLAINT_SAVE_REQUEST = 'COMPLAINT_SAVE_REQUEST';

export interface ComplaintSaveRequestAction extends ComplaintSaveAction, APIRequestAction {
    readonly type: typeof COMPLAINT_SAVE_REQUEST;
}

export const COMPLAINT_SAVE_SUCCESS = 'COMPLAINT_SAVE_SUCCESS';

export interface ComplaintSaveSuccessAction extends ComplaintSaveAction, APISuccessAction {
    readonly type: typeof COMPLAINT_SAVE_SUCCESS;
}

export const COMPLAINT_SAVE_FAILURE = 'COMPLAINT_SAVE_FAILURE';

export interface ComplaintSaveFailureAction extends ComplaintSaveAction, APIFailureAction {
    readonly type: typeof COMPLAINT_SAVE_FAILURE;
}

export interface ComplaintNormalizedResponse extends NormalizedResponse {
    readonly result: number;
}

export const complaintSave = {
    request: (entity: string, id: number, complaint: ComplaintFormData): ComplaintSaveRequestAction => ({
        type: COMPLAINT_SAVE_REQUEST,
        entity, id, complaint,
    }),
    success: (entity: string, id: number, complaint: ComplaintFormData, response: ComplaintNormalizedResponse): ComplaintSaveSuccessAction => ({
        type: COMPLAINT_SAVE_SUCCESS, response,
        entity, id, complaint,
    }),
    failure: (entity: string, id: number, complaint: ComplaintFormData, error: any): ComplaintSaveFailureAction => ({
        type: COMPLAINT_SAVE_FAILURE, error,
        entity, id, complaint,
    }),
};

// Triggers
export const COMPLAINT_SEND = 'COMPLAINT_SEND';

export interface ComplaintSendAction extends ComplaintSaveAction {
    readonly type: typeof COMPLAINT_SEND;
}

export const complaintSend = (entity: string, id: number, complaint: ComplaintFormData): ComplaintSendAction => ({
    type: COMPLAINT_SEND,
    entity, id, complaint,
});

export const COMPLAINT_TOGGLE = 'COMPLAINT_TOGGLE';

export interface ComplaintToggleAction extends Action {
    readonly type: typeof COMPLAINT_TOGGLE;
    readonly entity: string;
    readonly id: number;
    readonly opened?: boolean;
}

export const complaintToggle = (entity: string, id: number, opened?: boolean): ComplaintToggleAction => ({
    type: COMPLAINT_TOGGLE,
    entity, id, opened,
});

export const COMPLAINT_OPEN_TRIGGER = 'COMPLAINT_OPEN_TRIGGER';
export interface ComplaintOpenTriggerAction extends Action {
    readonly type: typeof COMPLAINT_OPEN_TRIGGER;
    readonly entity: string;
    readonly id: number;
}

export const complaintOpenTrigger = (entity: string, id: number): ComplaintOpenTriggerAction => ({
    type: COMPLAINT_OPEN_TRIGGER, entity, id,
});
