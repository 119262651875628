import {
    STROLLER_FEATURE_PRODUCT_IS_NO_LONGER_SOLD,
    STROLLER_FEATURE_PRODUCT_IS_NO_LONGER_SOLD_SINCE_date,
    STROLLER_RATING_1,
    STROLLER_RATING_10,
    STROLLER_RATING_2,
    STROLLER_RATING_3,
    STROLLER_RATING_4,
    STROLLER_RATING_5,
    STROLLER_RATING_6,
    STROLLER_RATING_7,
    STROLLER_RATING_8,
    STROLLER_RATING_9,
} from 'mk/autogenerated/translations/helpers.d2044d2795d2bd293484aa4a0e47e225'
import { Features } from 'mk2/apps/strollers/schemas';
import { interpolate } from 'mk2/services/i18n';

export function translateScore(score: number) {
    return interpolate(
        {
            1: STROLLER_RATING_1,
            2: STROLLER_RATING_2,
            3: STROLLER_RATING_3,
            4: STROLLER_RATING_4,
            5: STROLLER_RATING_5,
            6: STROLLER_RATING_6,
            7: STROLLER_RATING_7,
            8: STROLLER_RATING_8,
            9: STROLLER_RATING_9,
            10: STROLLER_RATING_10,
        }[score],
    );
}

export function capitalizeFirstLetter(text: string) {
    return text?.charAt(0).toUpperCase() + text.slice(1);
}

export function getProductIsNoLongerSoldLabel(stroller: {
    features: Pick<
        Features,
        'product_is_no_longer_sold_bool' | 'product_is_no_longer_sold_int'
    >;
}) {
    return stroller.features.product_is_no_longer_sold_bool
        ? stroller.features.product_is_no_longer_sold_int
            ? interpolate(STROLLER_FEATURE_PRODUCT_IS_NO_LONGER_SOLD_SINCE_date, {
                date: `${stroller.features.product_is_no_longer_sold_int}`,
            })
            : STROLLER_FEATURE_PRODUCT_IS_NO_LONGER_SOLD
        : null;
}

export function convertStrollerType(s, fromTo : '_to-' | '-to_') {
    if (fromTo === '_to-') {
        return s.replace(new RegExp('_', 'g'), '-');
    } else {
        return s.replace(new RegExp('-', 'g'), '_');
    }
}
