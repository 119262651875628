import { ACTIVE_SERVER_ID } from 'mk/settings';
import { Option } from 'mk2/components/forms/SelectField';

const COUNTRY_PHONE_PREFIXES = [
    '+1',       // United States and Canada
    '+20',      // Egypt
    '+30',      // Greece
    '+31',      // Netherlands
    '+32',      // Belgium
    '+33',      // France
    '+34',      // Spain
    '+36',      // Hungary
    '+350',     // Gibraltar
    '+351',     // Portugal
    '+352',     // Luxembourg
    '+353',     // Ireland
    '+354',     // Iceland
    '+355',     // Albania
    '+356',     // Malta
    '+357',     // Cyprus
    '+358',     // Finland
    '+359',     // Bulgaria
    '+370',     // Lithuania
    '+371',     // Latvia
    '+372',     // Estonia
    '+373',     // Moldova
    '+374',     // Armenia
    '+375',     // Belarus
    '+376',     // Andorra
    '+377',     // Monaco
    '+378',     // San Marino
    '+380',     // Ukraine
    '+381',     // Serbia
    '+382',     // Montenegro
    '+383',     // Kosovo
    '+385',     // Croatia
    '+386',     // Slovenia
    '+387',     // Bosnia and Herzegovina
    '+389',     // Macedonia
    '+39',      // Italy
    '+40',      // Romania
    '+41',      // Switzerland
    '+420',     // Czech Republic
    '+421',     // Slovakia
    '+423',     // Liechtenstein
    '+43',      // Austria
    '+44',      // United Kingdom
    '+45',      // Denmark
    '+46',      // Sweden
    '+47',      // Norway
    '+48',      // Poland
    '+49',      // Germany
    '+52',      // Mexico
    '+54',      // Argentina
    '+55',      // Brazil
    '+61',      // Australia
    '+64',      // New Zealand
    '+7',       // Russia
    '+81',      // Japan
    '+82',      // South Korea
    '+852',     // Hong Kong
    '+90',      // Turkey
    '+971',     // United Arab Emirates
    '+972',      // Israel
];

export const HOME_COUNTRY_PHONE_PREFIX = {201: '+421', 202: '+420'}[ACTIVE_SERVER_ID];
export const SIBLING_COUNTRY_PHONE_PREFIX = {201: '+420', 202: '+421'}[ACTIVE_SERVER_ID];

export const FOREIGN_COUNTRIES_PHONE_PREFIXES_OPTIONS: Option[] = COUNTRY_PHONE_PREFIXES
    .filter((prefix) => HOME_COUNTRY_PHONE_PREFIX !== prefix)
    .map((prefix) => ({ value: prefix, label: prefix }));
