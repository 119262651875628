/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp5: Array<[string, string]> = [
    ["WOMENS", "Pro \u017eeny"],
    ["MENS", "Pro mu\u017ee"],
    ["GIRLS", "Pro holky"],
    ["BOYS", "Pro kluky"],
];
