export interface DebugState {
    request?: string;
    path?: string;
    url?: string;
    duration?: string;
    description?: string;
}

export function debugReducer(state: DebugState = {}, action: any) {
    if (!!action?.response?.debug) {
        return {
            request: action.response.debug.request,
            path: action.response.debug.path,
            url: action.response.debug.url,
            duration: action.response.debug.duration,
            description: action.response.debug.description,
        };
    }

    if (!!action?.response?.result?.debug) {
        return {
            request: action.response.result.debug.request,
            path: action.response.result.debug.path,
            url: action.response.result.debug.url,
            duration: action.response.result.debug.duration,
            description: action.response.result.debug.description,
        };
    }

    return state;
}
