/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp15 } from './commonProp15';
import { commonProp4 } from './commonProp4';

export const ice_hockey_helmets : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp15,
    p_brand: [
        d("Adidas"),
        d("Bauer"),
        d("Blue Sports"),
        d("Bosport"),
        d("CCM"),
        d("Comp-o-stik"),
        d("Crowned"),
        d("Fischer"),
        d("Fox"),
        d("Franklin"),
        d("K2"),
        d("Mission"),
        d("Opro"),
        d("Opus"),
        d("Oroks"),
        d("POC"),
        d("Raptor-X"),
        d("Reebok"),
        d("Rucanor"),
        d("Sher-wood"),
        d("Stiga"),
        d("Tempish"),
        d("True Temper"),
        d("Vaughn"),
        d("Venum"),
        d("Wall"),
        d("Warrior"),
        d("White Bear"),
        d("Winnwell"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
