/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const ski_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("Alpina"),
        d("Atomic"),
        d("Blizzard"),
        d("Boot Doc"),
        d("Dalbello"),
        d("Dynastar"),
        d("Elan"),
        d("FISCHER"),
        d("Fundango"),
        d("Hamax"),
        d("Head"),
        d("K2"),
        d("Lange"),
        d("Movement"),
        d("Nordica"),
        d("Roces"),
        d("ROSSIGNOL"),
        d("Roxa"),
        d("Salomon"),
        d("Scott"),
        d("Shumee"),
        d("Tecnica"),
        d("Volkl"),
        d("Wedze"),
        d("ZIENER"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
