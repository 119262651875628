import {
    MY_FILTER_VALUES,
    MY_SORTING_VALUES,
    QUALITY_VALUES,
    SORTING_VALUES,
    WISHLIST_SORTING_VALUES,
} from 'mk/bazaar/common/enums';

import { DeviceChangedAction, DEVICE_CHANGED, LoginAction, LOGIN, PlanChangedAction, PLAN_CHANGED } from 'mk2/actions';
import { BazaarGridLoadApiSuccessAction } from 'mk2/apps/bazaar/containers/BazaarGrid/BazaarGrid.actions';
import { HerBazaarLoadApiSuccessAction } from 'mk2/apps/bazaar/containers/HerBazaar/HerBazaar.actions';
import {
    MyBazaarClearLastCreatedProductAction,
    MyBazaarLoadApiSuccessAction,
} from 'mk2/apps/bazaar/containers/MyBazaar/MyBazaar.actions';
import { ShoppingLoadApiSuccessAction } from 'mk2/apps/bazaar/containers/Shopping/Shopping.actions';
import { ShoppingSorting } from 'mk2/apps/bazaar/containers/Shopping/Shopping.contants';
import { WishlistLoadApiSuccessAction } from 'mk2/apps/bazaar/containers/Wishlist/Wishlist.actions';
import {
    ProfileSaveSuccessAction,
    PROFILE_SAVE_SUCCESS,
} from 'mk2/apps/settings/containers/Profile/Profile.actions';
import { OldBrowserIgnoreWarningAction } from 'mk2/apps/users/containers/OldBrowser/OldBrowser.actions';
import { PhoneVerificationValidateTokenSuccessAction } from 'mk2/apps/users/containers/PhoneVerification/PhoneVerification.actions';
import {
    WebpushNotificationsDisableAction,
    WebpushNotificationsSubscribedAction,
    WEBPUSH_NOTIFICATIONS_DISABLE,
    WEBPUSH_NOTIFICATIONS_SUBSCRIBED,
} from 'mk2/containers/WebpushNotifications/WebpushNotifications.actions';
import { UserAgentParsed } from 'mk2/helpers/detects';
import { tupdate } from 'mk2/helpers/types';
import { Plan, UserEntity } from 'mk2/schemas';
import { Reducer } from 'redux';

export interface RequestDeviceState {
    mobile: boolean;
}

export type Quality = keyof typeof QUALITY_VALUES;
export type GridSort = keyof typeof SORTING_VALUES;
export type MyGridSort = keyof typeof MY_SORTING_VALUES;
export type MyGridFilter = keyof typeof MY_FILTER_VALUES;
export type WishlistSort = keyof typeof WISHLIST_SORTING_VALUES;

export interface Settings {
    cleanSort?: boolean;
    placesRadius?: boolean;
    quality?: Quality[];
    gridSort?: GridSort;
    myGridSort?: MyGridSort;
    myGridFilter?: MyGridFilter;
    wishlistSort?: WishlistSort;
    buySort?: ShoppingSorting;
    sendBazaarEmails?: boolean;
    afterCreateShareModalDisabled?: boolean;
}

export const initialSettings: Settings = {
    afterCreateShareModalDisabled: false,
    buySort: ShoppingSorting.NEW,
    cleanSort: false,
    placesRadius: false,
    gridSort: 'update',
    myGridFilter: 'all',
    myGridSort: 'new_messages',
    quality: ['old', 'new', 'handmade', 'services'],
    sendBazaarEmails: true,
    wishlistSort: 'wishlist',
};

export interface RequestStateProvidedFromServer {
    baseUrl: string;
    user: UserEntity;
    permissions: string[];
    settings: Settings;
    userAgent: UserAgentParsed;
    webpush: string;
}

export interface RequestState extends RequestStateProvidedFromServer {
    device: RequestDeviceState;
    lastKnownUsername: string;
    pregnancyNewsletterSubscribed: boolean;
    newGroupsEnabled: boolean;
    hasIgnoredOldBrowserWarning: boolean;
}

export const initialState: RequestState = {
    baseUrl: '',
    user: {
        id: 0,
        username: 'anonymous',
        avatar: '', // TODO: Add generic avatar
        avatarCampaign: '',
        // county: null,
        activePlan: Plan.FREE,
        gender: 'f',
        phoneVerified: false,
        isBlocked: false,
        isBlockedSelf: false,
        likesCount: 0,
        isAmbassadorOfBrandId: null,
        isUntrustworthy: false,
        isVerifiedOrganization: false,
        isOfficialProfile: false,
    },
    permissions: [],
    settings: initialSettings,
    userAgent: null,
    device: {
        // Defaults to mobile
        mobile: true,
    },
    lastKnownUsername: null,
    webpush: null,
    pregnancyNewsletterSubscribed: false,
    newGroupsEnabled: null,
    hasIgnoredOldBrowserWarning: false,
};

type RequestReducerAction =
    | DeviceChangedAction
    | WebpushNotificationsSubscribedAction
    | WebpushNotificationsDisableAction
    | OldBrowserIgnoreWarningAction
    | MyBazaarClearLastCreatedProductAction
    | PhoneVerificationValidateTokenSuccessAction
    | LoginAction
    | PlanChangedAction
    | BazaarGridLoadApiSuccessAction
    | WishlistLoadApiSuccessAction
    | MyBazaarLoadApiSuccessAction
    | HerBazaarLoadApiSuccessAction
    | ShoppingLoadApiSuccessAction
    | ProfileSaveSuccessAction;

export const requestReducer: Reducer<RequestState> = (state = initialState, action: RequestReducerAction) => {
    switch (action.type) {
        case DEVICE_CHANGED:
            return tupdate(state, {
                device: tupdate(state.device, {
                    mobile: action.mobile,
                }),
            });
        case WEBPUSH_NOTIFICATIONS_SUBSCRIBED:
            return tupdate(state, {
                webpush: action.token,
            });
        case WEBPUSH_NOTIFICATIONS_DISABLE:
            return tupdate(state, {
                webpush: 'disabled',
            });
        case 'OLD_BROWSER_IGNORE_WARNING': // Keep as string so that we don't bundle whole 'OldBrowser.actions';
            return tupdate(state, {
                hasIgnoredOldBrowserWarning: true,
            });
        case 'MY_BAZAAR_CLEAR_LAST_CREATED_PRODUCT': // Keep as string so that we don't bundle whole 'MyBazaar.actions';
            if (action.disable) {
                return tupdate(state, {
                    settings: tupdate(state.settings, {
                        afterCreateShareModalDisabled: true,
                    }),
                });
            } else {
                return state;
            }
        case 'PHONE_VERIFICATION_VALIDATE_TOKEN_SUCCESS': // Keep as string so that we don't bundle whole 'mk2/apps/users/containers/PhoneVerification/PhoneVerification.actions';
            return tupdate(state, {
                user: tupdate(state.user, {
                    phoneVerified: true,
                }),
            });

        case PROFILE_SAVE_SUCCESS: {
            return {
                ...state,
                user: action.requestUser,
            };
        }

        case 'BAZAAR_GRID_LOAD_API_SUCCESS': // Keep as string so that we don't bundle whole 'mk2/apps/bazaar/containers/BazaarGrid/BazaarGrid.actions';
            if (
                // score_new should not be persisted
                (state.settings.gridSort !== action.filter.settings.gridSort &&
                    action.filter.settings.gridSort !== 'score_new') ||
                state.settings.quality?.join(',') !== action.filter.settings.quality?.join(',') ||
                state.settings.cleanSort !== action.filter.settings.cleanSort
            ) {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        gridSort:
                            action.filter.settings.gridSort === 'score_new'
                                ? state.settings.gridSort
                                : action.filter.settings.gridSort,
                        quality: action.filter.settings.quality,
                        cleanSort: action.filter.settings.cleanSort,
                        placesRadius: action.filter.settings.placesRadius,
                    },
                };
            } else {
                return state;
            }
        case 'WISHLIST_LOAD_API_SUCCESS': // Keep as string so that we don't bundle whole 'mk2/apps/bazaar/containers/Wishlist/Wishlist.actions';
            if (
                state.settings.wishlistSort !== action.filter.settings.wishlistSort ||
                state.settings.quality?.join(',') !== action.filter.settings.quality?.join(',') ||
                state.settings.cleanSort !== action.filter.settings.cleanSort
            ) {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        wishlistSort: action.filter.settings.wishlistSort,
                        quality: action.filter.settings.quality,
                        cleanSort: action.filter.settings.cleanSort,
                        placesRadius: action.filter.settings.placesRadius,
                    },
                };
            } else {
                return state;
            }
        case 'HER_BAZAAR_LOAD_API_SUCCESS': // Keep as string so that we don't bundle whole 'mk2/apps/users/containers/HerBazaar/HerBazaar.actions';
            if (
                state.settings.gridSort !== action.filter.settings.gridSort &&
                action.filter.settings.gridSort !== 'score_new'
            ) {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        gridSort: action.filter.settings.gridSort,
                    },
                };
            } else {
                return state;
            }
        case 'MY_BAZAAR_LOAD_API_SUCCESS': // Keep as string so that we don't bundle whole 'mk2/apps/users/containers/MyBazaar/MyBazaar.actions';
            if (
                state.settings.myGridSort !== action.filter.settings.myGridSort ||
                state.settings.myGridFilter !== action.filter.settings.myGridFilter
            ) {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        myGridSort: action.filter.settings.myGridSort,
                        myGridFilter: action.filter.settings.myGridFilter,
                    },
                };
            } else {
                return state;
            }
        case 'SHOPPING_LOAD_API_SUCCESS': // Keep as string so that we don't bundle whole 'mk2/apps/users/containers/Shopping/Shopping.actions';
            // Modify only if sorting has changed
            if (action.sort !== state.settings.buySort) {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        buySort: action.sort,
                    },
                };
            } else {
                return state;
            }
        case LOGIN:
            return {
                ...state,
                user: action.initialState.request.user,
                permissions: action.initialState.request.permissions,
                settings: action.initialState.request.settings,
                // Ignore - cannot be changed - only server.ts is sending this and some
                // API endpoints are reseting it to NULL :(
                // userAgent: action.initialState.request.userAgent,
                webpush: action.initialState.request.webpush,
            };

        case PLAN_CHANGED: {
            return {
                ...state,
                user: action.initialState.request.user,
            };
        }

        default:
            return state;
    }
};
