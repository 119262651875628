/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp15 } from './commonProp15';
import { commonProp4 } from './commonProp4';

export const roller_skating_helmets : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp15,
    p_brand: [
        d("Abus"),
        d("Bell"),
        d("Bern"),
        d("Blade Shades"),
        d("Disney"),
        d("Ennui"),
        d("Giro"),
        d("Hamax"),
        d("K2"),
        d("Met"),
        d("Micro"),
        d("POC"),
        d("Powerslide"),
        d("Rollerblade"),
        d("Stiga"),
        d("Tempish"),
        d("URBIS"),
        d("Varnet"),
        d("Warner Bros"),
        d("Zealot"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
