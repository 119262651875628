import {
    forumBrandDaysArchiveUrl,
    forumCommentEditUrl,
    forumCommonPlaceForeignTopicsUrl,
    forumCommonPlaceTopicsUrl,
    forumContestEditUrl,
    forumContestNewUrl,
    forumMyActivityFeedUrl,
    forumPostCommentLikersUrl,
    forumPostDetailUrl,
    forumPostEditUrl,
    forumPostLikersUrl,
    forumPostNewInCategoryUrl,
    forumPostNewInTopicUrl,
    forumPostNewUrl,
    forumReportedUrl,
    forumSearchUrl,
    forumTopicsCategoryUrl,
    forumTopicsUrl,
    forumTopicEditUrl,
    forumTopicInfoUrl,
    forumTopicModeratorInvitationUrl,
    forumTopicNewUrl,
    forumTopicUrl,
    forumUntrustworthyUserFeedUrl,
    forumUpdatedUrl,
    forumUrl,
} from 'mk2/apps/forum/urls';
import { DynamicRoute } from 'mk2/containers/Application/Application';

export const routes: DynamicRoute[] = [{
    app: 'forum',
    exact: true,
    path: forumUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ForumFeed/ForumFeedPage' /* webpackChunkName: "forum.ForumFeed" */);
    },
    modules: [ 'mk2/apps/forum/containers/ForumFeed/ForumFeedPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumUpdatedUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ForumFeed/ForumFeedPage' /* webpackChunkName: "forum.ForumFeed" */);
    },
    modules: [ 'mk2/apps/forum/containers/ForumFeed/ForumFeedPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumMyActivityFeedUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ForumMyActivityFeed/ForumMyActivityFeedPage' /* webpackChunkName: "forum.ForumMyActivityFeed" */);
    },
    modules: [ 'mk2/apps/forum/containers/ForumMyActivityFeed/ForumMyActivityFeedPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumReportedUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/FeedReported/FeedReportedPage' /* webpackChunkName: "forum.FeedReported" */);
    },
    modules: [ 'mk2/apps/forum/containers/FeedReported/FeedReportedPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumTopicsUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ListOfForums/ListOfForumsPage' /* webpackChunkName: "forum.ListOfForums" */);
    },
    modules: [ 'mk2/apps/forum/containers/ListOfForums/ListOfForumsPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumCommonPlaceForeignTopicsUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/CommonPlaceTopics/CommonPlaceTopicsPage' /* webpackChunkName: "forum.CommonPlaceTopics" */);
    },
    modules: [ 'mk2/apps/forum/containers/CommonPlaceTopics/CommonPlaceTopicsPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumCommonPlaceTopicsUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/CommonPlaceTopics/CommonPlaceTopicsPage' /* webpackChunkName: "forum.CommonPlaceTopics" */);
    },
    modules: [ 'mk2/apps/forum/containers/CommonPlaceTopics/CommonPlaceTopicsPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumTopicsCategoryUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ForumTopics/ForumTopicsPage' /* webpackChunkName: "forum.ForumTopics" */);
    },
    modules: [ 'mk2/apps/forum/containers/ForumTopics/ForumTopicsPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumBrandDaysArchiveUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ArchivedCampaigns/ArchivedCampaignsPage' /* webpackChunkName: "forum.ArchivedCampaigns" */);
    },
    modules: [ 'mk2/apps/forum/containers/ArchivedCampaigns/ArchivedCampaignsPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumTopicModeratorInvitationUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/TopicModeratorInvitation/TopicModeratorInvitationPage' /* webpackChunkName: "forum.TopicModeratorInvitation" */);
    },
    modules: [ 'mk2/apps/forum/containers/TopicModeratorInvitation/TopicModeratorInvitationPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumTopicInfoUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/TopicInfo/TopicInfoPage' /* webpackChunkName: "forum.TopicInfo" */);
    },
    modules: [ 'mk2/apps/forum/containers/TopicInfo/TopicInfoPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumTopicNewUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/TopicCreate/TopicCreatePage' /* webpackChunkName: "forum.TopicCreate" */);
    },
    modules: [
        'mk2/apps/forum/containers/TopicCreate/TopicCreatePage',
        'mk2/apps/forum/containers/ForumCategories/ForumCategoriesPage',
    ],
}, {
    app: 'forum',
    exact: true,
    path: forumTopicEditUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/TopicEdit/TopicEditPage' /* webpackChunkName: "forum.TopicEdit" */);
    },
    modules: [ 'mk2/apps/forum/containers/TopicEdit/TopicEditPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumTopicUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/Topic/TopicPage' /* webpackChunkName: "forum.Topic" */);
    },
    modules: [ 'mk2/apps/forum/containers/Topic/TopicPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumContestNewUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ContestCreate/ContestCreatePage' /* webpackChunkName: "forum.ContestCreate" */);
    },
    modules: [ 'mk2/apps/forum/containers/ContestCreate/ContestCreatePage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumContestEditUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ContestEdit/ContestEditPage' /* webpackChunkName: "forum.ContestEdit" */);
    },
    modules: [ 'mk2/apps/forum/containers/ContestEdit/ContestEditPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumPostNewUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/PostCreate/PostCreateSelectCategoryPage' /* webpackChunkName: "forum.ForumCategories" */);
    },
    modules: [ 'mk2/apps/forum/containers/PostCreate/PostCreateSelectCategoryPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumPostNewInCategoryUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/PostCreate/PostCreateSelectTopicPage' /* webpackChunkName: "forum.ForumTopics" */);
    },
    modules: [ 'mk2/apps/forum/containers/PostCreate/PostCreateSelectTopicPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumPostNewInTopicUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/PostCreate/PostCreatePage' /* webpackChunkName: "forum.PostCreate" */);
    },
    modules: [ 'mk2/apps/forum/containers/PostCreate/PostCreatePage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumPostEditUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/PostEdit/PostEditPage' /* webpackChunkName: "forum.PostEdit" */);
    },
    modules: [ 'mk2/apps/forum/containers/PostEdit/PostEditPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumPostLikersUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/PostLikers/PostLikersPage' /* webpackChunkName: "forum.PostLikers" */);
    },
    modules: [ 'mk2/apps/forum/containers/PostLikers/PostLikersPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumPostCommentLikersUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/PostCommentLikers/PostCommentLikersPage' /* webpackChunkName: "forum.PostCommentLikers" */);
    },
    modules: [ 'mk2/apps/forum/containers/PostCommentLikers/PostCommentLikersPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumUntrustworthyUserFeedUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/FeedUntrustworthyUser/FeedUntrustworthyUserPage' /* webpackChunkName: "forum.FeedUntrustworthyUser" */);
    },
    modules: [ 'mk2/apps/forum/containers/FeedUntrustworthyUser/FeedUntrustworthyUserPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumPostDetailUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/PostDetail/PostDetailPage' /* webpackChunkName: "forum.PostDetail" */);
    },
    modules: [ 'mk2/apps/forum/containers/PostDetail/PostDetailPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumCommentEditUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/CommentEdit/CommentEditPage' /* webpackChunkName: "forum.CommentEdit" */);
    },
    modules: [ 'mk2/apps/forum/containers/CommentEdit/CommentEditPage' ],
}, {
    app: 'forum',
    exact: true,
    path: forumSearchUrl,
    getPage: () => {
        return import('mk2/apps/forum/containers/ForumSearch/ForumSearchPage' /* webpackChunkName: "forum.ForumSearch" */);
    },
    modules: [ 'mk2/apps/forum/containers/ForumSearch/ForumSearchPage' ],
}];
