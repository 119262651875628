import intersection from 'lodash-es/intersection';

/**
 * Checks if request user has one of the required permissions or passes the given custom test.
 *
 * @param requestUserPermissions
 * @param permissionsAnyOf: name of permission(s) or boolean (permision was evaluated elsewhere)
 */
export function hasRequiredPermissions<Props>(requestUserPermissions: string[],
                                              permissionsAnyOf: string | string[] | boolean): boolean {

    if (permissionsAnyOf === true || permissionsAnyOf === false) {
        return permissionsAnyOf;
    } else if (!permissionsAnyOf) { // null, undefined, '', ...
        return true;
    } else if (Array.isArray(permissionsAnyOf)) {
        return intersection(requestUserPermissions, permissionsAnyOf).length > 0;
    } else {
        return requestUserPermissions.indexOf(permissionsAnyOf) >= 0;
    }
}
