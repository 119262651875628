/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const nursing_bra : {[key: string]: Array<[string, string]>} = {
    p_size: [
        d("60"),
        d("65"),
        d("70"),
        d("75"),
        d("80"),
        d("85"),
        d("90"),
        d("95"),
        d("100"),
        d("105"),
        d("110"),
        d("115"),
        d("120"),
        d("125"),
    ],
    p_cup_size: [
        d("AA"),
        d("A"),
        d("B"),
        d("C"),
        d("D"),
        d("E"),
        d("F"),
        d("G"),
        d("H"),
        d("I"),
        d("J"),
    ],
};
