/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const snowboard_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("DC"),
        d("Burton Grom Boa\u00ae"),
        d("FTWO"),
        d("Head"),
        d("Morrow"),
        d("Nitro"),
        d("Northwave"),
        d("ROBLA"),
        d("Rossignol"),
        d("Salomon"),
        d("Trans"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
