/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const babysitting : {[key: string]: Array<[string, string]>} = {
    p_frequency: [
        ["ONE_TIME_OR_IRREGULARLY", "Jednor\u00e1zov\u011b nebo nepravideln\u011b"],
        ["REGULARLY_IN_THE_MORNING", "Pravideln\u011b dopoledne"],
        ["REGULARLY_AFTERNOON_AND_EVENING", "Pravideln\u011b odpoledne a ve\u010der"],
        ["REGULARLY_ALL_DAY", "Pravideln\u011b cel\u00fd den"],
    ],
    p_age_years: [
        ["0-1YEARS", "Miminko (0-1 rok)"],
        ["1-3YEARS", "Batole (1-3 roky)"],
        ["3-6YEARS", "\u0160kolka\u0159 (3-6 rok\u016f)"],
        ["6-10YEARS", "Mal\u00fd \u0161kol\u00e1k (6-10 rok\u016f)"],
        ["10-15YEARS", "Velk\u00fd \u0161kol\u00e1k (10-15 rok\u016f)"],
    ],
    p_practice_length: commonProp22,
    p_place: [
        ["CHILDREN_RESIDENCE", "V bydli\u0161ti d\u011bt\u00ed"],
        ["BABYSITTER_RESIDENCE", "V bydli\u0161ti hl\u00edda\u010dky"],
    ],
};
