/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const bouncers : {[key: string]: Array<[string, string]>} = {
    p_color: commonProp4,
};
