import { FeedItemOrGroup } from 'mk2/containers/PageHeader/PageHeader.reducers';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { PageHeaderUserData } from 'mk2/schemas';
import { Action } from 'redux';

/////////////////////////////////////////////////////////////////////
// Fetch flagged counts

export const PAGEHEADER_FETCH_FLAGGED_COUNTS_TRIGGER = 'PAGEHEADER_FETCH_FLAGGED_COUNTS_TRIGGER';
export interface PageHeaderFetchFlaggedCountsTriggerAction {
    readonly type: typeof PAGEHEADER_FETCH_FLAGGED_COUNTS_TRIGGER;
}
export const PAGEHEADER_FETCH_FLAGGED_COUNTS_REQUEST = 'PAGEHEADER_FETCH_FLAGGED_COUNTS_REQUEST';
export interface PageHeaderFetchFlaggedCountsRequestAction extends APIRequestAction {
    readonly type: typeof PAGEHEADER_FETCH_FLAGGED_COUNTS_REQUEST;
}
export const PAGEHEADER_FETCH_FLAGGED_COUNTS_SUCCESS = 'PAGEHEADER_FETCH_FLAGGED_COUNTS_SUCCESS';
export interface PageHeaderFetchFlaggedCountsSuccessAction extends APISuccessAction {
    readonly type: typeof PAGEHEADER_FETCH_FLAGGED_COUNTS_SUCCESS;
    readonly flaggedCount: number;
    readonly flaggedGroupCount: number;
}
export const PAGEHEADER_FETCH_FLAGGED_COUNTS_FAILURE = 'PAGEHEADER_FETCH_FLAGGED_COUNTS_FAILURE';
export interface PageHeaderFetchFlaggedCountsFailureAction extends APIFailureAction {
    readonly type: typeof PAGEHEADER_FETCH_FLAGGED_COUNTS_FAILURE;
}

export const pageHeaderFetchFlaggedCountsTrigger = (): PageHeaderFetchFlaggedCountsTriggerAction => ({
    type: PAGEHEADER_FETCH_FLAGGED_COUNTS_TRIGGER,
});
export const pageHeaderFetchFlaggedCountsApi = {
    request: (): PageHeaderFetchFlaggedCountsRequestAction => ({
        type: PAGEHEADER_FETCH_FLAGGED_COUNTS_REQUEST,
    }),
    success: (
        flaggedCount: number,
        flaggedGroupCount: number,
        response: any,
    ): PageHeaderFetchFlaggedCountsSuccessAction => ({
        type: PAGEHEADER_FETCH_FLAGGED_COUNTS_SUCCESS,
        flaggedCount,
        flaggedGroupCount,
        response,
    }),
    failure: (error: any): PageHeaderFetchFlaggedCountsFailureAction => ({
        type: PAGEHEADER_FETCH_FLAGGED_COUNTS_FAILURE,
        error,
    }),
};

///////////////////////////////////////////////////////////////////
// Progress broadcast

export interface PageHeaderFetchAction extends Action {}
export const PAGEHEADER_FETCH_REQUEST = 'PAGEHEADER_FETCH_REQUEST';
export interface PageHeaderFetchRequestAction extends PageHeaderFetchAction, APIRequestAction {
    readonly type: typeof PAGEHEADER_FETCH_REQUEST;
}
export const PAGEHEADER_FETCH_SUCCESS = 'PAGEHEADER_FETCH_SUCCESS';
export interface PageHeaderFetchSuccessAction extends PageHeaderFetchAction, APISuccessAction {
    readonly type: typeof PAGEHEADER_FETCH_SUCCESS;
    readonly data: PageHeaderUserData;
}
export const PAGEHEADER_FETCH_FAILURE = 'PAGEHEADER_FETCH_FAILURE';
export interface PageHeaderFetchFailureAction extends PageHeaderFetchAction, APIFailureAction {
    readonly type: typeof PAGEHEADER_FETCH_FAILURE;
}

export const pageHeaderFetch = {
    request: (): PageHeaderFetchRequestAction => ({
        type: PAGEHEADER_FETCH_REQUEST,
    }),
    success: (data: PageHeaderUserData): PageHeaderFetchSuccessAction => ({
        type: PAGEHEADER_FETCH_SUCCESS,
        data,
        response: null,
    }),
    failure: (error: any): PageHeaderFetchFailureAction => ({
        type: PAGEHEADER_FETCH_FAILURE,
        error,
    }),
};

///////////////////////////////////////////////////////////////////
// Triggers

// PageHeader load
export const PAGEHEADER_LOAD = 'PAGEHEADER_LOAD';
export interface PageHeaderLoadAction extends PageHeaderFetchAction {
    readonly type: typeof PAGEHEADER_LOAD;
}
export const pageHeaderLoad = (): PageHeaderLoadAction => ({
    type: PAGEHEADER_LOAD,
});

/////////////////////////////////////////////////////////////////////
// Add new feedMenu item

export const PAGEHEADER_ADD_FEEDMENU_ITEM = 'PAGEHEADER_ADD_FEEDMENU_ITEM';
export interface PageHeaderAddFeedMenuItemAction {
    readonly type: typeof PAGEHEADER_ADD_FEEDMENU_ITEM;
    readonly menuGroup: string;
    readonly name: string;
    readonly title: string;
    readonly icon: string;
    readonly url: string;
}
export const pageHeaderAddFeedMenuItem = (
    menuGroup: string,
    name: string,
    title: string,
    icon: string,
    url: string,
): PageHeaderAddFeedMenuItemAction => ({
    type: PAGEHEADER_ADD_FEEDMENU_ITEM,
    menuGroup,
    name,
    title,
    icon,
    url,
});

/////////////////////////////////////////////////////////////////////
// Remove feedMenu item

export const PAGEHEADER_REMOVE_FEEDMENU_ITEM = 'PAGEHEADER_REMOVE_FEEDMENU_ITEM';
export interface PageHeaderRemoveFeedMenuItemAction {
    readonly type: typeof PAGEHEADER_REMOVE_FEEDMENU_ITEM;
    readonly menuGroup: string;
    readonly name: string;
}
export const pageHeaderRemoveFeedMenuItem = (menuGroup: string, name: string): PageHeaderRemoveFeedMenuItemAction => ({
    type: PAGEHEADER_REMOVE_FEEDMENU_ITEM,
    menuGroup,
    name,
});

export interface PageheaderRefreshFeedMenuApiAction extends Action {}

export const PAGEHEADER_REFRESH_FEED_MENU_API_REQUEST = 'PAGEHEADER_REFRESH_FEED_MENU_API_REQUEST';

export interface PageheaderRefreshFeedMenuApiRequestAction
    extends PageheaderRefreshFeedMenuApiAction,
        APIRequestAction {
    readonly type: typeof PAGEHEADER_REFRESH_FEED_MENU_API_REQUEST;
}

export const PAGEHEADER_REFRESH_FEED_MENU_API_SUCCESS = 'PAGEHEADER_REFRESH_FEED_MENU_API_SUCCESS';

export interface PageheaderRefreshFeedMenuApiSuccessAction
    extends PageheaderRefreshFeedMenuApiAction {
    readonly type: typeof PAGEHEADER_REFRESH_FEED_MENU_API_SUCCESS;
    readonly feedMenu: FeedItemOrGroup[];
}

export const PAGEHEADER_REFRESH_FEED_MENU_API_FAILURE = 'PAGEHEADER_REFRESH_FEED_MENU_API_FAILURE';

export interface PageheaderRefreshFeedMenuApiFailureAction
    extends PageheaderRefreshFeedMenuApiAction,
        APIFailureAction {
    readonly type: typeof PAGEHEADER_REFRESH_FEED_MENU_API_FAILURE;
}

export const pageheaderRefreshFeedMenuApi = {
    request: (): PageheaderRefreshFeedMenuApiRequestAction => ({ type: PAGEHEADER_REFRESH_FEED_MENU_API_REQUEST }),
    success: (feedMenu: FeedItemOrGroup[]): PageheaderRefreshFeedMenuApiSuccessAction => ({
        type: PAGEHEADER_REFRESH_FEED_MENU_API_SUCCESS,
        feedMenu,
    }),
    failure: (error: any): PageheaderRefreshFeedMenuApiFailureAction => ({
        type: PAGEHEADER_REFRESH_FEED_MENU_API_FAILURE,
        error,
    }),
};

export const PAGEHEADER_REFRESH_FEED_MENU = 'PAGEHEADER_REFRESH_FEED_MENU';

export interface PageheaderRefreshFeedMenuAction extends PageheaderRefreshFeedMenuApiAction {
    readonly type: typeof PAGEHEADER_REFRESH_FEED_MENU;
}

export const pageheaderRefreshFeedMenu = (): PageheaderRefreshFeedMenuAction => ({
    type: PAGEHEADER_REFRESH_FEED_MENU,
});
