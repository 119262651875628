/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp21 } from './commonProp21';
import { commonProp4 } from './commonProp4';

export const home_accessories_decorations : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PHOTOS_PHOTO_FRAMES", "Obrazy a r\u00e1my"],
        ["MIRRORS", "Zrcadla"],
        ["TAPESTRY_WALL_STICKERS", "Tapety, bordury a n\u00e1lepky"],
        ["NOTICEBOARDS", "Jmenovky, cedulky a z\u00e1v\u011bsn\u00e9 dekorace"],
        ["CLOCKS", "Hodiny, bud\u00edky"],
        ["HOME_SCULPTURES_FIGURINES", "Mal\u00e9 nez\u00e1v\u011bsn\u00e9 dekorace"],
        ["MAGNETS", "Magnetky"],
        ["FLOWER_ARRANGEMENTS", "Kytice, ikebany"],
        ["VASES_BOWLS", "V\u00e1zy a misky"],
        ["PLANTS_PLANT_POTS", "Kv\u011bty a kv\u011btin\u00e1\u010de"],
        ["CANDLE_HOLDERS_CANDLES", "Sv\u00edcny a sv\u00ed\u010dky"],
        ["STORAGE_BOXES_BASKETS_BAGS", "Krabice, ko\u0161e a ta\u0161ky"],
        ["WASTE_SORTING_BINS_BAGS", "V\u011bnce"],
        ["LAUNDRY_BASKETS", "Dopl\u0148ky do sk\u0159\u00edn\u011b, \u0161atn\u00edku"],
        ["BATHROOM_ACCESSORIES", "Koupelnov\u00e9 dopl\u0148ky"],
        ["OFFICE_ACCESSORIES", "Dopl\u0148ky do pracovny"],
        ["OTHER_HOME_ACCESSORIES", "Jin\u00e9"],
    ],
    p_thema: commonProp21,
    p_color: commonProp4,
};
