/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const animals : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PETS_ANIMAL", "Zv\u00ed\u0159e"],
        ["PETS_FEED", "Krmivo"],
        ["PETS_REWARD", "Pamlsky, odm\u011bny"],
        ["PETS_VITAMINS", "Vitam\u00edny a l\u00e9\u010diva"],
        ["PETS_HOUSE", "Bouda, akv\u00e1rium, klec"],
        ["PETS_CUSHION", "Pol\u0161t\u00e1\u0159, pel\u00ed\u0161ek"],
        ["PETS_BOWL", "P\u00edtko a miska na potravu"],
        ["PETS_TOY", "Hra\u010dky"],
        ["PETS_TRAVEL", "Cestov\u00e1n\u00ed, p\u0159eprava"],
        ["PETS_CLOTHES", "Oble\u010den\u00ed"],
        ["PETS_COSMETICS", "Kosmetika"],
        ["PETS_PLANT", "Rostlina"],
        ["PETS_SET", "Kompletn\u00ed v\u00fdbava"],
        ["COLLAR_HALTER_LEASH", "Obojek, ohl\u00e1vka, vod\u00edtko"],
        ["LITERATURE", "Literatura"],
        ["PETS_OTHER", "Jin\u00e9"],
    ],
    p_animal: [
        ["DOG", "Pes"],
        ["CAT", "Ko\u010dka"],
        ["BIRD", "Pt\u00e1\u010dci"],
        ["FISH", "Ryby a \u017eelvy"],
        ["HAMSTER", "K\u0159e\u010dek, mor\u010de, kr\u00e1l\u00edk"],
        ["LIZARD", "Teraristika"],
        ["FARM_ANIMALS", "Hospod\u00e1\u0159sk\u00e1 zv\u00ed\u0159ata"],
        ["PETS_OTHER", "Jin\u00e9"],
    ],
};
