import { Action } from 'redux';

export const MENU_TOGGLE = 'MENU_TOGGLE';

export interface MenuToggleAction extends Action {
    readonly type: typeof MENU_TOGGLE;
    readonly menuId: string;
    readonly show?: boolean;
}

export const menuToggle = (menuId: string, show?: boolean) => ({
    type: MENU_TOGGLE,
    menuId, show,
});
