import cx from 'classnames';
import { BooleanMark } from 'mk2/apps/strollers/components/BooleanMark';
import { Features } from 'mk2/apps/strollers/schemas';
import React from 'react';
import styles from './BooleanListFeature.mscss';

interface OwnProps {
    className?: string;
    features: Features;
    feature: any;
}

export class BooleanListFeature extends React.Component<OwnProps> {
    public render() {
        const { feature, features, className } = this.props;

        const arrValue = feature.booleanPairs(features);

        return (
            <div className={cx(styles.BooleanListFeature, className)}>
                {arrValue.map((booleanPair, idx) => {
                    return (
                        <BooleanMark key={idx} booleanPair={booleanPair} />
                    );
                })}
            </div>
        );
    }
}
