/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const cycling_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("Adidas"),
        d("ALPINE PRO"),
        d("Arcore"),
        d("Automax"),
        d("BBB"),
        d("Bonavelo"),
        d("Briko"),
        d("Cappa"),
        d("Castelli"),
        d("Cerva"),
        d("CMP"),
        d("Craft"),
        d("CRANE"),
        d("CRIVIT"),
        d("CRV"),
        d("Dare 2b"),
        d("Decathlon"),
        d("Etape"),
        d("Fox"),
        d("G-Form"),
        d("Giro"),
        d("GORE"),
        d("Hannah"),
        d("Head"),
        d("Holokolo"),
        d("Jack Wolfskin"),
        d("Karpos"),
        d("Karrimor"),
        d("KELLYS"),
        d("Kli"),
        d("Klimatex"),
        d("Legea"),
        d("L\u00f6ffler"),
        d("Malfini"),
        d("Maloja"),
        d("Mons Royale"),
        d("MUDDYFOX"),
        d("NAKAMURA"),
        d("Nalini"),
        d("Nike"),
        d("Northfinder"),
        d("Northwave"),
        d("Oakley"),
        d("POC"),
        d("Reebok"),
        d("Rosti"),
        d("Russell Athletic"),
        d("Scott"),
        d("Sensor"),
        d("SILVINI"),
        d("Sondico"),
        d("SPECIALIZED"),
        d("Sportful"),
        d("TCM"),
        d("Under Armour"),
        d("Wista"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
