import { interpolate, INTERPOLATE_REGEXP } from 'mk2/services/i18n';
import React from 'react';

interface Props {
    className?: string;
    parent?: string;
    i18nKey: string | string[];
    useDangerouslySetInnerHTML?: boolean;
    dangerouslySetInnerHTMLPartElement?: string;
    components?: React.ReactNode[];
    count?: number;
}

export type OwnProps = Props & {
    [name: string]: React.ReactNode;
};

export class Interpolate extends React.PureComponent<OwnProps> {
    public static defaultProps = {
        parent: 'span',
        dangerouslySetInnerHTMLPartElement: 'span',
        useDangerouslySetInnerHTML: false,
    };

    public render() {
        const {
            parent,
            i18nKey,
            useDangerouslySetInnerHTML,
            dangerouslySetInnerHTMLPartElement,
            children,
            className,
            components,
            ...rest
        } = this.props;
        const element = parent || 'span';

        const message = interpolate(i18nKey, { count: rest.count });

        if (process.env.NODE_ENV !== 'production') {
            if (useDangerouslySetInnerHTML) {
                // Detect variables inside HTML tags
                const re = /<([^ >]+)[^>]*>.*?<\/\1>/g;
                let match;
                do {
                    match = re.exec(message);
                    if (match && INTERPOLATE_REGEXP.test(match[0])) {
                        throw new Error(
                            `Interpolate: Cannot use 'useDangerouslySetInnerHTML' prop with key that contains HTML around variable. It will produce invalid HTML and break React with very strange error.`,
                        );
                    }
                } while (match);
            }
        }

        const parts = this.replaceTag(
            message,
            components,
            rest,
            useDangerouslySetInnerHTML,
            dangerouslySetInnerHTMLPartElement,
        );

        return React.createElement(element, { className }, ...parts);
    }

    private replaceTag(
        message: string,
        components: React.ReactNode[],
        variables: { [name: string]: React.ReactNode },
        useDangerouslySetInnerHTML: boolean,
        dangerouslySetInnerHTMLPartElement: string,
    ): React.ReactNode[] {
        if (!/<(\d+)>(.*?)<\/\1>/g.test(message)) {
            return this.replaceVariables(
                message,
                variables,
                useDangerouslySetInnerHTML,
                dangerouslySetInnerHTMLPartElement,
            );
        }

        const parts = message.split(/<(\d+)>(.*?)<\/\1>/g);
        const result: React.ReactNode[] = [];
        for (let i = 0; i < parts.length; i++) {
            if (i % 3 === 0 && parts[i].length) {
                result.push(
                    this.replaceVariables(
                        parts[i],
                        variables,
                        useDangerouslySetInnerHTML,
                        dangerouslySetInnerHTMLPartElement,
                    ),
                );
            } else if (i % 3 === 2) {
                const child = parts[i];
                // Element
                const comp = components[parseInt(parts[i - 1], 10)];
                const node = React.isValidElement<{ children: React.ReactNode[] }>(comp)
                    ? React.cloneElement(comp, {
                          children: this.replaceTag(
                              child,
                              components,
                              variables,
                              useDangerouslySetInnerHTML,
                              dangerouslySetInnerHTMLPartElement,
                          ),
                      })
                    : comp;
                result.push(node);
            }
        }

        return result;
    }

    private replaceVariables(
        message: string,
        variables: { [name: string]: React.ReactNode },
        useDangerouslySetInnerHTML: boolean,
        dangerouslySetInnerHTMLPartElement: string,
    ): React.ReactNode[] {
        return message.split(INTERPOLATE_REGEXP).reduce<React.ReactNode[]>((acc, cur, index) => {
            let child;

            if (index % 2 === 0) {
                if (cur.length === 0) {
                    return acc;
                }

                child = useDangerouslySetInnerHTML
                    ? React.createElement(dangerouslySetInnerHTMLPartElement, {
                          dangerouslySetInnerHTML: { __html: cur },
                      })
                    : cur;
            } else {
                child = variables[cur];
            }

            acc.push(child);
            return acc;
        }, []);
    }
}
