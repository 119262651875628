/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const nappies : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["DISPOSABLE", "Jednor\u00e1zov\u00e9"],
        ["REUSABLE", "L\u00e1tkov\u00e9 plenky"],
        ["FABRIC_SQUARES", "L\u00e1tkov\u00e9 \u010dtverce a vkl\u00e1da\u010dky"],
        ["AIO_NAPPIES", "AIO"],
        ["SWIM_NAPPIES", "Na plav\u00e1n\u00ed"],
        ["SWIM_FABRIC_NAPPIES", "Na pl\u00e1van\u00ed l\u00e1tkov\u00e9"],
        ["POSTOLKY", "Ortopedick\u00e9 kalhotky"],
        ["NAPPIES_ACCESSORIES", "P\u0159\u00edslu\u0161enstv\u00ed"],
    ],
    p_size_weight: [
        d("2 kg - 6 kg"),
        d("4 kg - 9 kg"),
        d("7 kg - 18 kg"),
        d("9 kg - 20 kg"),
        d("11 kg - 25 kg"),
        ["ONE_SIZE_FIT_ALL", "Univerz\u00e1ln\u00ed velikost"],
    ],
    p_brand: [
        d("aden+anias"),
        d("Aitu"),
        d("Akuku"),
        d("Alana"),
        d("Alva Baby"),
        d("Anavy"),
        d("Antony"),
        d("Attitude"),
        d("Baba Boo"),
        d("Baby BeeHinds"),
        d("Baby Lucy"),
        d("Babydream"),
        d("Babyidea"),
        d("BabyLegs"),
        d("Babylove"),
        d("babyono"),
        d("Bambi Roxy"),
        d("Bambinex"),
        d("Bambino Mio"),
        d("BAMBO"),
        d("Bamboola"),
        d("Bamboolik"),
        d("BATOLINI"),
        d("Bella Baby Happy"),
        d("Best Bottom"),
        d("Blueberry"),
        d("BoboBaby"),
        d("bumGenius"),
        d("Bumkins"),
        d("by Jitu"),
        d("B\u00b4bies"),
        d("Charlie Banana"),
        d("Chicco"),
        d("COOP Jednota"),
        d("Dada"),
        d("Disana"),
        d("Disney"),
        d("Dudeybaba"),
        d("ECObabynz"),
        d("Ecobu"),
        d("Ekomama"),
        d("Ella's House"),
        d("Euroshopper"),
        d("Fillikid"),
        d("Fixies"),
        d("Gaga\u2019s"),
        d("Gaji"),
        d("Gmini"),
        d("GroVia"),
        d("Haipa-Daipa"),
        d("Handri\u010dky"),
        d("Happy Nappy"),
        d("Hartmann"),
        d("Helen Harper"),
        d("Huggies"),
        d("Hybler"),
        d("IKEA"),
        d("Imsevimse"),
        d("itti bitti"),
        d("Joli Petit"),
        d("Kaarsgaren"),
        d("Kanga Care"),
        d("Katyv Baby"),
        d("KaWaii Baby"),
        d("Kikko/Xkko"),
        d("KOLORKY"),
        d("Kushies"),
        d("Libero"),
        d("Lib\u0161t\u00e1tsk\u00e9 pleny"),
        d("Littlelamb"),
        d("Lotties"),
        d("Loveybums"),
        d("Lupilu"),
        d("Majab"),
        d("MayLily"),
        d("Merries"),
        d("MiniLaLa"),
        d("Moltex"),
        d("MonPeri"),
        d("Moony"),
        d("MUUMI BABY"),
        d("Nappy"),
        d("Nepia Baby Nappy"),
        d("NEW BABY"),
        d("No Fear"),
        d("Odenw\u00e4lder BabyNest"),
        d("Pampers"),
        d("Papilios"),
        d("Petit Lulu"),
        d("Petite&Mars"),
        d("Piccolo Bambino"),
        d("plienk\u00e1\u010dik"),
        d("Pop In"),
        d("Pop \u2018N\u2019 Grow"),
        d("Popolini"),
        d("RealEasy"),
        d("Richelieu"),
        d("Sendy"),
        d("Shine Baby"),
        d("Skapi"),
        d("Sketch"),
        d("Smartipants"),
        d("Swaddlebees"),
        d("Sweet Monkey"),
        d("Swimmies"),
        d("T-tomi"),
        d("Tesco"),
        d("Thirsties"),
        d("Tots Bots"),
        d("toujours"),
        d("unuo"),
        d("Vibelle"),
        d("VULPI"),
        d("Wonderoo"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
};
