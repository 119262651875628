import {
    number_DAYS,
    number_MONTHS,
    number_WEEKS,
    number_YEARS,
    AVATAR_AGE__age_major_AND_age_minor,
    AVATAR_AGE__EXACTLY_age,
} from 'mk/autogenerated/translations/timeUtils.a0ffe57b066b8db07dbeb038eadc4f6e'
import { dateDiff } from 'mk2/helpers/timeformat';

// we only accept what we generate on server, which is
// the javascript-toISOString format
import { interpolate } from 'mk2/services/i18n';

const STRING_RE = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\dZ$/;

export function stringToDate(dateString) {
    const isValidString = STRING_RE.exec(dateString);

    if (!isValidString) {
        throw new Error('stringToDate: invalid date-string:' + dateString);
    }

    const unixTimeMilliseconds = Date.parse(dateString);

    // we get NaN if the date was invalid
    if (isNaN(unixTimeMilliseconds)) {
        throw new Error('stringToDate: invalid date-string:' + dateString);
    }

    return new Date(unixTimeMilliseconds);
}

export function anyToDate(dateThing) {
    if (dateThing instanceof Date) {
        return dateThing;
    } else {
        if (typeof dateThing === 'string') {
            return stringToDate(dateThing);
        } else {
            throw new Error('anyToDate: got non-convertible thing:' + dateThing);
        }
    }
}

/**
 * Convert local datum '2018-10-23' to Date() object where time
 * is set to '00:00:00.000' in local (browser) timezone.
 */
export function localDatumToDate(dateString) {
    return dateString ? new Date(Date.parse(dateString)) : null;
}


export function isLastDayOfMonth(d) {
    const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    return d.toDateString() === lastDay.toDateString();
}


/**
 *  Age as number of years, months, weeks and days; either the two most significant values [1]
 *  or one value preceded by the word "exactly" for exact matches [2]
 *  Examples (sk):
 *      5 rokov a 2 mesiace [1]
 *      2 tyzdne a 6 dni [1]
 *      presne 2 roky [2]
 */
export function getAgeText(birthDate, today = new Date()) {
    if (!birthDate || birthDate > today) {
        return '';
    }

    let s = '';

    // calc count of months and count of days between birthday and today
    let deltaM = (today.getFullYear() - birthDate.getFullYear()) * 12;
    deltaM += today.getMonth() - birthDate.getMonth();
    // decrease count of months if bithday not happened yet in this month (except no other days remain this month)
    if (birthDate.getDate() > today.getDate() && !isLastDayOfMonth(today)) {
        deltaM -= 1;
    }
    let deltaD = dateDiff(birthDate, today).diffDays;

    let years = null;
    let months = null;
    let weeks = null;
    let days = null;

    // Finalize count of years / relative count of months / relative count of weeks / relative count of days
    if (deltaM === 0) {
        years = 0;
        months = 0;
        weeks = Math.floor(deltaD / 7);
        days = deltaD % 7;
    } else {

        if (today.getDate() === birthDate.getDate()) {
            weeks = 0;
            days = 0;
        } else {
            // helper variable to move from birthDate further with deltaM months
            const helperDate = new Date(birthDate);
            helperDate.setMonth(helperDate.getMonth() + deltaM);

            // if the day of month not equal with the original day then decrease helperDate with 1 or 2 days
            // (e.g. Jan 31 + 2 months is Mar 2 therefore 31 != 2 ...)
            if (birthDate.getDate() !== helperDate.getDate()) {
                // (... accordingly decrease Mar 2 with 2 days to get the last day of February)
                helperDate.setDate(helperDate.getDate() - (helperDate.getDate() === 2 ? 2 : 1));
            }

            deltaD = dateDiff(helperDate, today).diffDays;
            weeks = Math.floor(deltaD / 7);
            days = deltaD % 7;
        }

        years = Math.floor(deltaM / 12);
        months = deltaM % 12;
    }

    // Generating result text

    if (years > 0) {
        const sYears = interpolate(number_YEARS, {count: years, number: years});
        if (months === 0 && weeks === 0 && days === 0) {
            s = interpolate(AVATAR_AGE__EXACTLY_age, {age: sYears});
        } else {
            if (months > 0 && years < 6) {
                const sMonths = interpolate(number_MONTHS, {count: months, number: months});
                s = interpolate(AVATAR_AGE__age_major_AND_age_minor, {age_major: sYears, age_minor: sMonths});
            } else {
                s = sYears;
            }
        }

    } else if (months > 0) {
        const sMonths = interpolate(number_MONTHS, {count: months, number: months});
        if (weeks === 0 && days === 0) {
            s = interpolate(AVATAR_AGE__EXACTLY_age, {age: sMonths});
        } else {
            if (weeks > 0) {
                const sWeeks = interpolate(number_WEEKS, {count: weeks, number: weeks});
                s = interpolate(AVATAR_AGE__age_major_AND_age_minor, {age_major: sMonths, age_minor: sWeeks});
            } else {
                s = sMonths;
            }
        }

    } else if (weeks > 0) {
        const sWeeks = interpolate(number_WEEKS, {count: weeks, number: weeks});
        if (days === 0) {
            s = interpolate(AVATAR_AGE__EXACTLY_age, {age: sWeeks});
        } else {
            if (days > 0) {
                const sDays = interpolate(number_DAYS, {count: days, number: days});
                s = interpolate(AVATAR_AGE__age_major_AND_age_minor, {age_major: sWeeks, age_minor: sDays});
            } else {
                s = sWeeks;
            }
        }
    } else {
        s = interpolate(number_DAYS, {count: days, number: days});
    }

    return s;
}
