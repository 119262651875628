/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const gymnastics_ballet_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("Bloch"),
        d("botas"),
        d("Capezio"),
        d("DanceMaster"),
        d("dansez vous"),
        d("FR Duval"),
        d("Freed of London"),
        d("G.Westerleigh"),
        d("intermezzo"),
        d("K.H. Martin"),
        d("leo"),
        d("mdm"),
        d("meteor"),
        d("mirella"),
        d("Pridance"),
        d("sansha"),
        d("TECH Dance"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
