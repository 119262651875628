/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const toys : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["TOYS_FOR_PRAMS", "Na ko\u010d\u00e1rek a autoseda\u010dku"],
        ["TOYS_CREATIVE_KITS", "Hrac\u00ed deky a hrazdi\u010dky"],
        ["TOYS_FIRST_TOYS", "Chrast\u00edtka, prvn\u00ed hra\u010dky"],
        ["TOYS_PUSH_AND_PULL", "Na tah\u00e1n\u00ed a tla\u010den\u00ed"],
        ["TOYS_DOLLS", "Panenky a p\u0159\u00edslu\u0161enstv\u00ed"],
        ["TOYS_VEHICLES", "Auta, dopravn\u00ed prost\u0159edky a p\u0159\u00edslu\u0161enstv\u00ed"],
        ["TOYS_TOOLS", "Kuchy\u0148ky, dom\u00e1cnost, n\u00e1\u0159ad\u00ed"],
        ["TOYS_MUSIC", "Hudebn\u00ed hra\u010dky a n\u00e1stroje"],
        ["TOYS_CONSTRUCTION_KITS", "Lego a Stavebnice"],
        ["TOYS_PUZZLE", "Puzzle, skl\u00e1da\u010dky, hlavolamy"],
        ["TOYS_GAMES", "Spole\u010densk\u00e9 hry"],
        ["COMPUTER_GAMES", "Elektronick\u00e9 hra\u010dky"],
        ["TOYS_FIGURINE", "Postavi\u010dky, figurky"],
        ["TOYS_ANIMALS", "Zv\u00ed\u0159ata"],
        ["TOYS_ROBOTS", "Roboti"],
        ["TOYS_ARMS", "Zbran\u011b"],
        ["TOYS_SLIDES_HOUSES", "Klouza\u010dky, houpa\u010dky, dome\u010dky"],
        ["TOYS_OUTDOOR", "Hra\u010dky na ven"],
        ["TOWS_WATER", "Do vody"],
        ["TOYS_HANDICRAFTS", "Tvo\u0159en\u00ed, malov\u00e1n\u00ed, ru\u010dn\u00ed pr\u00e1ce"],
        ["TOYS_EDUCATIONAL", "Vzd\u011bl\u00e1vac\u00ed a didaktick\u00e9 hra\u010dky"],
        ["TOYS_COLLECTIBLES", "Sb\u011bratelstv\u00ed"],
        ["WALKER_PUSHALONG", "Chod\u00edtka"],
        ["RIDEON", "Odr\u00e1\u017eedla"],
        ["ELECTRIC-RIDEON", "Motorov\u00e9, \u0161lapac\u00ed"],
        ["TOYS_ADULTS", "Hry pro dosp\u011bl\u00e9"],
    ],
    p_material: [
        ["FROM_WOOD", "D\u0159ev\u011bn\u00e9"],
        ["FROM_PLUSH", "Ply\u0161ov\u00e9"],
        ["FROM_FABRIC", "L\u00e1tkov\u00e9"],
        ["FROM_RUBBER", "Gumov\u00e9"],
        ["FROM_FOAM", "P\u011bnov\u00e9"],
        ["FROM_AIR", "Nafukovac\u00ed"],
        ["FROM_PVC", "Plastov\u00e9"],
        ["FROM_METAL", "Kovov\u00e9"],
        ["FROM_PAPER", "Pap\u00edrov\u00e9"],
        ["FROM_LIQUID_MOLDABLE", "Tekut\u00e9, plastick\u00e9"],
        ["FROM_KNIT", "H\u00e1\u010dkovan\u00e9, pleten\u00e9"],
        ["FROM_CERAMIC", "Keramick\u00e9"],
    ],
    p_sex: [
        ["GIRLS", "Pro holky"],
        ["BOYS", "Pro kluky"],
        ["ADULTS", "Pro dosp\u011bl\u00e9"],
    ],
};
