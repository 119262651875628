import { AppStateProvidedFromServer } from 'mk2/reducers';
import React from 'react';
import { actions as toastrActions, toastType, transitionInType } from 'react-redux-toastr';
import { Action } from 'redux';
import { v4 as uuid } from 'uuid';

// Triggers
export const DEVICE_CHANGED = 'DEVICE_CHANGED';

export interface DeviceChangedAction extends Action {
    readonly type: typeof DEVICE_CHANGED;
    readonly mobile: boolean;
}

export const deviceChanged = (mobile: boolean): DeviceChangedAction => ({ type: DEVICE_CHANGED, mobile });

// export actions from toastr
export interface ToastOptions {
    timeOut?: number;
    removeOnHover?: boolean;
    removeOnHoverTimeOut?: number;
    component?: React.ComponentType<{ remove: () => void }>;
}

export const toastAdd: {
    (type: toastType, options?: ToastOptions): Action;
    (type: toastType, id: string, options?: ToastOptions): Action;
} = (type: toastType, id: string | ToastOptions, options?: ToastOptions) => {
    let toastId: string;
    if (typeof id === 'object') {
        options = id;
        toastId = uuid();
    } else {
        toastId = id;
    }

    // react-redux-toastr types don't support function components :( - for now hack it
    const component: any = options.component;

    return toastrActions.add({
        type,
        id: toastId,
        position: 'top-center',
        options: {
            timeOut: 5000,
            removeOnHover: true,
            removeOnHoverTimeOut: 2500,
            transitionIn: 'bounceInDown' as transitionInType,
            ...options,
            component,
        },
    });
};

export const ANALYTICS_STORE_EVENT = 'ANALYTICS_STORE_EVENT';

export interface AnalyticsStoreEventAction extends Action {
    readonly type: typeof ANALYTICS_STORE_EVENT;
    readonly name: string;
    readonly props: any;
    readonly redirect: string;
}

export const analyticsStoreEvent = (name: string, props: object = {}, redirect: string = null): AnalyticsStoreEventAction => ({
    type: ANALYTICS_STORE_EVENT,
    name,
    props,
    redirect,
});

// Action for unauthenticated users
export const FORUM_POST_ADD_UNAUTHENTICATED = 'FORUM_POST_ADD_UNAUTHENTICATED';

export interface AddPostUnauthenticatedAction extends Action {
    readonly type: typeof FORUM_POST_ADD_UNAUTHENTICATED;
}

export const addPostUnauthenticated = (): AddPostUnauthenticatedAction => ({
    type: FORUM_POST_ADD_UNAUTHENTICATED,
});

export const LOGIN = 'LOGIN';
export interface LoginAction extends Action {
    readonly type: typeof LOGIN;
    readonly initialState: AppStateProvidedFromServer;
}

export const login = (initialState: AppStateProvidedFromServer): LoginAction =>
    ({ type: LOGIN, initialState });


export const PLAN_CHANGED = 'PLAN_CHANGED';
export interface PlanChangedAction extends Action {
    readonly type: typeof PLAN_CHANGED;
    readonly initialState: AppStateProvidedFromServer;
}

export const planChangedTrigger = (initialState: AppStateProvidedFromServer): PlanChangedAction =>
    ({ type: PLAN_CHANGED, initialState });
