/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp13 } from './commonProp13';
import { commonProp4 } from './commonProp4';

export const car_seats_accessories : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["TRAVEL_PILLOW", "Cestovn\u00ed pol\u0161t\u00e1\u0159ek"],
        ["CAR_SEATS_BASE", "Z\u00e1kladna, b\u00e1ze"],
        ["SUN_VISORS", "Slune\u010dn\u00ed clony"],
        ["FOOTMUFFS_BLANKETS", "Fusaky, deky"],
        ["CAR_STICKERS", "N\u00e1lepky na auto"],
        ["CAR_SEAT_INSERTS", "Vlo\u017eka"],
        ["CAR_SEAT_COVERS", "Potah"],
        ["CAR_SEAT_PROTECTIONS", "Ochrana sedadla"],
        ["CAR_SEAT_ORGANIZERS", "Kaps\u00e1\u0159, organiz\u00e9r"],
        ["CAR_SEAT_BELT_PROTECTIONS", "Chr\u00e1ni\u010d p\u00e1s\u016f"],
        ["CAR_REARVIEW_MIRRORS", "Zp\u011btn\u00e1 zrc\u00e1tka do auta"],
        ["CAR_SEATS_ACCESSORIES", "P\u0159\u00edslu\u0161enstv\u00ed nebo jin\u00e9"],
    ],
    p_brand: commonProp13,
    p_color: commonProp4,
};
