import cx from 'classnames';
import { Features } from 'mk2/apps/strollers/schemas';
import { niceNumber } from 'mk2/apps/strollers/utils';
import { hasVal } from 'mk2/helpers/sanitize';
import React from 'react';
import styles from './TwoDimsListFeature.mscss';

interface OwnProps {
    className?: string;
    features: Features;
    feature: any;
}

const renderValue = (value) => {
    return hasVal(value) ? niceNumber(value) : '?';
};

const Text = (props) => {
    const { text } = props;
    return text ? (
        <span className={styles.TwoDimsListFeature__item__text}> {text}</span>
    ) : null;
};

export class TwoDimsListFeature extends React.Component<OwnProps> {
    public render() {
        const { feature, features, className } = this.props;

        const arrValue = feature.getDims(features);

        return (
            <div className={cx(styles.TwoDimsListFeature, className)}>
                {arrValue.map((dimsVariant, idx) => {
                    return (
                        <div key={idx} className={styles.TwoDimsListFeature__item}>
                            {renderValue(dimsVariant.width)}
                            &nbsp;×&nbsp;
                            {renderValue(dimsVariant.depth)}
                            <Text text={dimsVariant.variant} />
                        </div>
                    );
                })}
            </div>
        );
    }
}
