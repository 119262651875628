import { isAnonymous } from 'mk/bazaar/common/userUtils';
import { loginUrl, signupUrl } from 'mk2/apps/users/urls';
import { getLastKnownUsernameCookie } from 'mk2/apps/users/utils';
import { makeAbsoluteUrl, url } from 'mk2/helpers/urls';
import { getRequestUser } from 'mk2/reducers';
import { getRoutingLocation } from 'mk2/selectors';
import { pushInPWASaga } from 'mk2/services/browserHistory';
import React from 'react';
import { select } from 'redux-saga/effects';

export function* checkIfUserIsLoggedIn(action: string, next?: string) {
    const requestUser = yield select(getRequestUser);
    if (isAnonymous(requestUser)) {
        const location = yield select(getRoutingLocation);
        const lastKnownUsername = getLastKnownUsernameCookie();
        const redirectUrl = url(lastKnownUsername ? loginUrl : signupUrl, {},  {next: next || `${location.pathname}${location.search}`});
        yield pushInPWASaga(makeAbsoluteUrl(redirectUrl));
        return false;
    }
    return true;
}
