import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { Action } from 'redux';

export interface ABFetchApiAction extends Action {
    readonly names: string;
}

export const AB_FETCH_API_REQUEST = 'AB_FETCH_API_REQUEST';

export interface ABFetchApiRequestAction extends ABFetchApiAction, APIRequestAction {
    readonly type: typeof AB_FETCH_API_REQUEST;
}

export const AB_FETCH_API_SUCCESS = 'AB_FETCH_API_SUCCESS';

export interface ABFetchApiSuccessAction extends ABFetchApiAction, APISuccessAction {
    readonly type: typeof AB_FETCH_API_SUCCESS;
}

export const AB_FETCH_API_FAILURE = 'AB_FETCH_API_FAILURE';

export interface ABFetchApiFailureAction extends ABFetchApiAction, APIFailureAction {
    readonly type: typeof AB_FETCH_API_FAILURE;
}

export const abFetchApi = {
    request: (names: string): ABFetchApiRequestAction => ({ type: AB_FETCH_API_REQUEST, names }),
    success: (names: string, response: any): ABFetchApiSuccessAction => ({ type: AB_FETCH_API_SUCCESS, names, response }),
    failure: (names: string, error: any): ABFetchApiFailureAction => ({ type: AB_FETCH_API_FAILURE, names, error }),
};

export const AB_FETCH = 'AB_FETCH';

export interface ABFetchAction extends ABFetchApiAction {
    readonly type: typeof AB_FETCH;
}

export const abFetch = (names: string): ABFetchAction => ({ type: AB_FETCH, names });
