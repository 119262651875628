import {
    LikeSaveFailureAction,
    LikeSaveRequestAction,
    LikeSaveSuccessAction,
    LikeToggleAction,
    LIKE_SAVE_FAILURE,
    LIKE_SAVE_REQUEST,
    LIKE_SAVE_SUCCESS,
} from 'mk2/containers/Like/Like.actions';
import { APIAction } from 'mk2/helpers/action';
import { tupdate } from 'mk2/helpers/types';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

export interface ActionButtonState {
    loadingState: LoadingState;
}

export const initialActionButtonState: ActionButtonState = {
    loadingState: LoadingState.INIT,
};

export interface ActionButtonsState {
    [buttonId: string]: ActionButtonState;
}

declare type ActionButtonReducerAction =
    APIAction
    | LikeSaveSuccessAction
    | LikeSaveFailureAction
    | LikeSaveRequestAction;

const initialActionButtonsState: ActionButtonsState = {};

export const actionButtonsReducer: Reducer<ActionButtonsState> = (state = initialActionButtonsState, action: ActionButtonReducerAction) => {
    switch (action.type) {
        case LIKE_SAVE_REQUEST:
        case LIKE_SAVE_SUCCESS:
        case LIKE_SAVE_FAILURE: {
            const likeAction: LikeToggleAction = action as LikeToggleAction;
            const buttonId = likeAction.id + action.type.substring(0, action.type.lastIndexOf('_'));
            return tupdate(state, {
                [buttonId]: tupdate(state[buttonId] || initialActionButtonState, {
                    loadingState: loadingState(undefined, action),
                }),
            });
        }

        default: {
            if (action.type.endsWith('REQUEST') || action.type.endsWith('SUCCESS') || action.type.endsWith('FAILURE')) {
                const buttonId = action.type.substring(0, action.type.lastIndexOf('_'));
                return tupdate(state, {
                    [buttonId]: tupdate(state[buttonId] || initialActionButtonState, {
                        loadingState: loadingState(undefined, action),
                    }),
                });
            } else {
                return state;
            }
        }
    }
};
