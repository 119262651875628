/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const rainwear : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["RAINCOATS_AND_RAIN_JACKETS", "Pl\u00e1\u0161t\u011bnky a bundy do de\u0161t\u011b"],
        ["RAIN_PANTS", "Kalhoty do de\u0161t\u011b"],
        ["RAIN_SET", "Komplet do de\u0161t\u011b"],
        ["WATERPROOF_OVERALLS", "Nepromokav\u00e1 kombin\u00e9za, overal"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
