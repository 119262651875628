/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp16 } from './commonProp16';
import { commonProp4 } from './commonProp4';

export const face_masks : {[key: string]: Array<[string, string]>} = {
    p_for_whom: commonProp16,
    p_material: [
        ["NANOROUCHES", "Nanorou\u0161ky"],
        ["FROM_FABRIC", "L\u00e1tkov\u00e9"],
        ["DISPOSABLE", "Jednor\u00e1zov\u00e9"],
    ],
    p_fit: [
        ["FIT_RUBBER_BANDS", "Gumi\u010dky"],
        ["FIT_LACES", "\u0160\u0148\u016frky"],
    ],
    p_layers: [
        ["SINGLE_LAYER", "Jednovrstv\u00e9"],
        ["MULTI_LAYER", "V\u00edcevrstv\u00e9"],
    ],
    p_color: commonProp4,
};
