import { hasBuggyClamp, hasBuggySelectImages, isOldBrowserVersion, UserAgentParsed } from 'mk2/helpers/detects';
import { UAParser } from 'ua-parser-js';

const UAParserExtensions = {
    device: [
        [
            // Lenovo tablets
            /(lenovo)\s?((?:TAB|TB|YT|YB|A3300|A3500|A5500|A7600)(?:[\w\s-]*))/i,
        ],
        [['vendor', 'Lenovo'], 'model', ['type', 'tablet']],
        [
            // Huawei tablets
            /((?:AGS|T1|MediaPad|HUAWEI)[\w\s\.-]*)\s+Build\/(?:HuaweiMediaPad|HUAWEIAGS|HUAWEIM2)[\w-]*/i,
        ],
        [['vendor', 'Huawei'], 'model', ['type', 'tablet']],
    ],
};

// tato funkcia sa nema pouzivat, pretoze sa chceme zbavit na klientovi zavislosti od 'ua-parser-js',
// tak aby sme libku nemuseli includovat do js bundle
//
// namiesto toho server posiela v initialState uz naparsovany user agent.
// Tato funkcia bude nakoniec len v server.ts (client ju nebude mat)
export function deprecatedParseUserAgent(userAgent: string): UserAgentParsed {
    const res: UAParser.IResult = new UAParser(userAgent, UAParserExtensions).getResult();

    // copy only useful parts from IResult, so that we do not store and transfer values that are not used
    const ua: UserAgentParsed = {
        os: {
            name: res.os ? res.os.name || '' : '',
            version: res.os ? res.os.version || '' : '',
        },
        browser: {
            name: res.browser ? res.browser.name || '' : '',
            version: res.browser ? res.browser.version || '' : '',
        },
        device: {
            type: res.device ? res.device.type || '' : '',
        },
        flags : {
            buggyClamp: false,
            buggySelectImages: false,
            oldBrowserVersion: false,
        },
    };

    ua.flags.buggyClamp = hasBuggyClamp(ua);
    ua.flags.buggySelectImages = hasBuggySelectImages(ua);
    ua.flags.oldBrowserVersion = isOldBrowserVersion(ua);

    return ua;
}
