/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const nourishment : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FORMULA_MILK", "Um\u011bl\u00e1 ml\u00e9ka"],
        ["PORRIDGES", "Ka\u0161e"],
        ["BABY_FOOD", "D\u011btsk\u00e9 p\u0159\u00edkrmy"],
        ["DIETARY_SUPPLEMENTS", "Potravinov\u00e9 dopl\u0148ky"],
        ["JUICES_DRINKS", "\u0160\u0165avy, n\u00e1poje"],
        ["FRUIT_PUREE", "Ovocn\u00e9 pyr\u00e9, kapsi\u010dky"],
        ["BISCUITS", "Su\u0161ienky, sladkosti"],
        ["TEES", "\u010caje"],
    ],
};
