import { blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { testingsHomeUrl, testingDetailUrl, testingFinishedPostEditUrl } from 'mk2/apps/testings/urls';
import { Route } from 'mk2/containers/Application/Application';
import { url } from 'mk2/helpers/urls';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [{
    app: 'testings',
    exact: true,
    path: testingFinishedPostEditUrl,
    getPage: () => {
        return import('mk2/apps/testings/containers/TestingFinishedPostEdit/TestingFinishedPostEditPage' /* webpackChunkName: "testings.TestingFinishedPostEdit" */);
    },
    modules: [ 'mk2/apps/testings/containers/TestingFinishedPostEdit/TestingFinishedPostEditPage' ],
}, {
    app: 'testings',
    exact: true,
    path: testingDetailUrl,
    getPage: () => {
        return import('mk2/apps/testings/containers/TestingDetail/TestingDetailPage' /* webpackChunkName: "testings.TestingDetail" */);
    },
    modules: [ 'mk2/apps/testings/containers/TestingDetail/TestingDetailPage' ],
}, {
    app: 'testings',
    exact: true,
    path: testingsHomeUrl,
    page: new RedirectPage({
        to: url(blogsProfileUrl, {username: 'konik_testuje'}),
        permanent: true,
    }),
}];
