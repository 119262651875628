/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const breastfeeding_accessories : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BREASTPUMP", "Ods\u00e1va\u010dky mate\u0159sk\u00e9ho ml\u00e9ka"],
        ["NURSING_PILLOW", "Pol\u0161t\u00e1\u0159e na kojen\u00ed"],
        ["NURSING_NIGHTY", "No\u010dn\u00ed ko\u0161ile na kojen\u00ed"],
        ["NURSING_SHIRTS", "Tri\u010dka, halenky, svetry na kojen\u00ed"],
        ["BREASTS", "Na bradavky"],
        ["BREASTFEED_BOOSTER", "Na podporu kojen\u00ed"],
        ["BREASTFEED_ARMBAND", "\u0160\u00e1tky na kojen\u00ed"],
    ],
};
