/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';

export const clothing_megapacks : {[key: string]: Array<[string, string]>} = {
    p_size: [
        d("50\u201362"),
        d("68\u201380"),
        d("86\u201398"),
        d("104\u2013116"),
        d("122\u2013134"),
        d("140\u2013152"),
        d("158\u2013170"),
        d("XS"),
        d("S"),
        d("M"),
        d("L"),
        d("XL"),
        d("XXL"),
        d("XXXL"),
        d("4XL"),
        d("5XL"),
        d("24"),
        d("26"),
        d("28"),
        d("30"),
        d("32"),
        d("34"),
        d("35"),
        d("36"),
        d("37"),
        d("38"),
        d("39"),
        d("40"),
        d("41"),
        d("42"),
        d("43"),
        d("44"),
        d("45"),
        d("46"),
        d("47"),
        d("48"),
        d("49"),
        d("50"),
        d("52"),
        d("54"),
        d("56"),
        d("58"),
        d("60"),
        d("62"),
        ["ONE_SIZE_FIT_ALL", "Univerz\u00e1ln\u00ed velikost"],
    ],
    p_sex: commonProp5,
};
