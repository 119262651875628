import { starSave, StarToggleAction, STAR_TOGGLE } from 'mk2/containers/Star/Star.actions';
import { saveStar as apiSaveStar } from 'mk2/containers/Star/Star.api';
import { starKey, StarrableEntity } from 'mk2/containers/Star/Star.schemas';
import { handleXHRPostErrorSaga, XHRAction, XHRPostError } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { getEntitiesEntity } from 'mk2/reducers';
import React from 'react';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

const logger = getLogger('Star.sagas');

export function* saveStar({ entity, id, xhr }: StarToggleAction & XHRAction) {
    const key = starKey(entity, id);

    // Get current state
    const current: StarrableEntity = yield select(getEntitiesEntity, 'starrable', key);

    // Sent fake response in the request action, so that entities reducer updates data
    const newState = {
        ...current,
        isStarred: current ? !current.isStarred : true,
        starsCount: Math.max(0, current ? current.starsCount + (current.isStarred ? -1 : 1) : 1),
        createdTime: current && current.isStarred ? null : new Date().toISOString(), // Update created time
    };

    const fakeResponse = {
        entities: {
            starrable: {
                [key]: newState,
            },
        },
    };

    // TODO: Display toast?

    yield put(starSave.request(entity, id, fakeResponse));

    try {
        yield call(apiSaveStar, xhr, entity, id, newState.isStarred);
        yield put(starSave.success(entity, id, fakeResponse));
    } catch (error) {
        yield put(starSave.failure(entity, id, error));
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
    }
}

export default function* root() {
    yield all([takeEvery(STAR_TOGGLE, saveStar)]);
}
