/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const running_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("adidas"),
        d("arcore"),
        d("asics"),
        d("columbia"),
        d("crossroad"),
        d("lotto"),
        d("merrell"),
        d("mizuno"),
        d("new balance"),
        d("nike"),
        d("True"),
        d("reebok"),
        d("salomon"),
        d("umbro"),
        d("under armour"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
