import { createLikeableSchema, LikeableEntity } from 'mk2/containers/Like/Like.schemas';
import { DeletedByRole } from 'mk2/helpers/deleteable';
import {
    AST,
    ASTAdditionalDataFromServer,
    Entity,
    ExperienceEntity, MoneyAmount,
    PhotoEntity,
    StrollerEntity,
    StrollerUserReviewEntity,
    StrollerUserReviewStatus,
    UserDomainDataEntity,
    UserEntity,
    WikiArticleEntity,
} from 'mk2/schemas';
import { schema } from 'normalizr';

export enum PostVisibility {
    Public = 0,
    Authenticated = 1,
    Friends = 2,
    Private = 3,
    Group = 4,
}

export enum PostCommentability {
    CommentableByNobody = 0,
    CommentableByAuthenticated = 1,
}

export interface CommentParent {
    model: string;
    id: number;
}

export interface CommentEntity extends Entity {
    id: number;
    textAST: AST;
    textASTAdditionalData: ASTAdditionalDataFromServer;
    extraEntitiesOfArticle: WikiArticleEntity[];
    extraEntitiesOfStroller: StrollerEntity[];
    extraEntitiesOfPost: BlogPostEntity[];
    authorUser: UserEntity;
    created: string;
    stickerId: number;
    postId: number;
    positionInPost: number;
    photos: PhotoEntity[];
    targetOwner: UserEntity;
    likeable: LikeableEntity;
    isLikeable: boolean;
    canDelete: boolean;
    deletedAt?: string;
    deletedReason?: string;
    deletedBy?: UserEntity;
    deletedByRole?: DeletedByRole;
    byAdmin: boolean;
}

export const CommentSchema = new schema.Entity('comments', {
    likeable: createLikeableSchema('comment'),
});

export interface ImageEntity extends Entity {
    postUrl: string;
    username: string;
    title: string;
    commentsCount: number;
    likesCount: number;
    photo: PhotoEntity;
    likeable: LikeableEntity;
    canDelete: boolean;
    canEdit: boolean;
    canRotate: boolean;
    complaintsCount: number;
    createdTime?: string;
}

export const ImageSchema = new schema.Entity('photoblog.image', {
    likeable: createLikeableSchema('photoblog.image'),
});

export interface GroupEntity extends Entity {
    categoryId: number;
    name: string;
    description: string;
    coverPhoto: PhotoEntity | null;
    deletedAt: string;
    // deletedReason: string;
    // deletedBy: UserEntity;
    activeMembersCount: number;
    locked: boolean;
    imagesCount: number;
    membersCount: number;
    postsCount: number;
    isDomainGroup: boolean;
    isListedPrivate: boolean;
    isNoAds: boolean;
    showDeletedPosts: boolean;
    domainMonth: string;
    eshopCollections: string[];
    eshopCategories: string[];
    eshopProducts: string[];
}

export const GroupSchema = new schema.Entity('photoblog.group', {
});

export enum GroupMemberRoles {
    MEMBER = 0,
    MODERATOR = 1,
}

export interface GroupMemberEntity extends Entity {
    user: UserEntity;
    group: GroupEntity;
    role: GroupMemberRoles;
    createdTime: string;
    blockedTime: string;
    blockedReason: string;
    blockedByUser: UserEntity;
    hideGroupInFeed: boolean;
}

export const GroupMemberSchema = new schema.Entity('blogGroupMembers', {
    group: GroupSchema,
});

export enum GroupInvitationStatuses {
    PENDING = 0,
    ACCEPTED = 1,
    REFUSED = 2,
}

export interface GroupInvitationEntity extends Entity {
    user: UserEntity;
    group: GroupEntity;
    role: GroupMemberRoles;
    inviter: UserEntity;
    status: GroupInvitationStatuses;
    createdTime: string;
}

export const GroupInvitationSchema = new schema.Entity('blogGroupInvitations', {
    group: GroupSchema,
});

export interface HashtagEntity extends Entity {
    label: string;
}

export const HashtagSchema = new schema.Entity('photoblog.hashtag', {});

export interface HashtagItemEntity extends Entity {
    hashtag: HashtagEntity;
}

export const HashtagItemSchema = new schema.Entity('photoblog.hashtagitem', {
    hashtag: HashtagSchema,
});

export enum BlogFeedItemType {
    POST = 'POST',
    POST_LIKE = 'POST_LIKE',
    PHOTOBLOG_LIKE = 'PHOTOBLOG_LIKE', // legacy type
    PROFILE_LIKE = 'PROFILE_LIKE',
    GROUP_MEMBER = 'GROUP_MEMBER',
    WIKI_EXP = 'WIKI_EXP', // legacy type
    CUSTOM = 'CUSTOM', // legacy type
}

export enum BlogPostType {
    ALBUM = 1,
    SHORT_MESSAGE = 2,
    ARTICLE = 3,
    AUTOMATIC_MESSAGE = 4,
    SHARE = 5,
}

export interface BlogPostEntity extends Entity {
    group?: GroupEntity;
    author: UserEntity; // Deprecated, use 'user'
    user: UserEntity;
    // @deprecated - remove when no code will be using it - use ".createdAt"
    created: string;
    createdAt: string;
    lastChanged?: string;
    heading: string;
    titleAST: AST;
    titleASTAdditionalData: ASTAdditionalDataFromServer;
    perexAST: AST; // Deprecated
    perexASTAdditionalData: ASTAdditionalDataFromServer; // Deprecated
    extraEntitiesOfArticle: WikiArticleEntity[];
    extraEntitiesOfStroller: StrollerEntity[];
    extraEntitiesOfPost: BlogPostEntity[];
    images: ImageEntity[];
    stickerId: number;
    snippet: string;
    likeable: LikeableEntity;
    commentsCount: number;
    sharesCount?: number;
    complaintsCount: number;
    sticky: boolean;
    lastMessageTime: string;
    slug: string;
    popularAddedOn: string;
    noindex: boolean;
    isNoAds: boolean;
    deletedAt?: string;
    deletedReason?: string;
    deletedBy?: UserEntity;
    deletedByRole?: DeletedByRole;
    isDisplayingAtFeed: boolean;
    visibility: PostVisibility;
    wasEdited: boolean;
    type: BlogPostType;
    hashtagItems: HashtagItemEntity[];

    // Album and/or Article related data of this blog post
    contentAST?: AST;
    contentASTAdditionalData?: ASTAdditionalDataFromServer;
    viewsCount?: number; // TODO field to be deleted
    viewsCountUnique?: number;
    viewsCountTotal?: number;
    photos?: PhotoEntity[]; // TODO rename to articlePhotos
    countTopicContributors?: { [topicSlug: string]: number };
    isPublished?: boolean;
    isCommercial?: boolean;
    isActiveAmbassador?: boolean;
    isPRArticle?: boolean;
    firstPublishedAt?: string;
    publishTime?: string;
    commentability?: PostCommentability;
    scripts?: string;
    ambassadorBrandId?: number;

    // Shared post related data of this blog post
    sharedPost?: BlogPostEntity;

    // Automatic message post related data of this blog post
    automaticMessageCode?: string;
    automaticMessageTitleMsgid?: string; // TODO move algorithm of choosing title's i18n msgid to frontend
    extraData?: {
        name?: string;
        age?: string;
        gender?: string;
        contentType?: string;
        objectId?: number;
    };
    experience?: ExperienceEntity;
    review?: StrollerUserReviewEntity;
    stroller?: StrollerEntity;
}

export const BlogPostSchema = new schema.Entity('photoblog.post', {});
BlogPostSchema.define({
    likeable: createLikeableSchema('photoblog.post'),
    group: GroupSchema,
    images: [ImageSchema],
    sharedPost: BlogPostSchema,
    hashtagItems: [HashtagItemSchema],
});

export interface UsersByGroupId {
    [groupId: number]: UserEntity[];
}

export interface BlogFeedItemEntity extends Entity {
    itemType: BlogFeedItemType;
    user: UserEntity;
    data: any;
    experience: any;
    groupMember: GroupMemberEntity;
    group: GroupEntity;
    photoblog: any;
    post: BlogPostEntity;
    profile: UserEntity;
    profileUserDomainData: UserDomainDataEntity;
    showAllImages: boolean;
    dateTimeStamp: string;
}

export const BlogFeedItemSchema = new schema.Entity('blogFeedItems', {
    groupMember: GroupMemberSchema,
    group: GroupSchema,
    post: BlogPostSchema,
});

export enum ProfileProductType {
    STROLLER = 'stroller',
}

export interface ProfileStrollerProduct {
    type: ProfileProductType.STROLLER;
    id: number;
    slug: string;
    name: string;
    imageUrl: string;
    imageWidth: number;
    imageHeight: number;
    reviewId?: number;
    reviewerScore?: number;
    reviewStatus?: StrollerUserReviewStatus;
}

export type ProfileProduct = ProfileStrollerProduct;

// konkretna varianta eshop produktu
export interface EshopProductEntity extends Entity {
    variantId?: string;
    name: string;
    slug: string;
    idOriginal: string;
    categoryId?: string;
    productType?: string;
    utmCampaign?: string;
    membersPriceSum?: MoneyAmount;
    price: MoneyAmount;
    media: {
        url: string;
        alt: string;
        sortOrder?: number;
    };
}

export const EshopProductSchema = new schema.Entity('eshopProduct', {});
