/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const footmuffs : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FOOTMUFFS_WINTER", "Zimn\u00ed / tepl\u00fd"],
        ["FOOTMUFFS_ALL_SEASONS", "Celoro\u010dn\u00ed"],
    ],
    p_brand: [
        d("4baby"),
        d("7AM Enfant"),
        d("ABC Design"),
        d("Adbor"),
        d("Altabebe"),
        d("Aesthetic"),
        d("B.O.Z.Z"),
        d("Baby Design"),
        d("Baby Joy"),
        d("Baby Merc"),
        d("BabyGo"),
        d("Babyland"),
        d("Babylux"),
        d("Babymatex"),
        d("BabyStyle"),
        d("Baby\u00b4s Only"),
        d("B\u00e9b\u00e9 Confort"),
        d("Bebetto"),
        d("Belisima"),
        d("Beloff"),
        d("Benetton"),
        d("Beztroska"),
        d("Bj\u00e4llra"),
        d("Bogus"),
        d("bonprix"),
        d("Brio"),
        d("Britax"),
        d("Brendon"),
        d("Bugaboo"),
        d("buggysnuggle"),
        d("Bumbleride"),
        d("ByBoom"),
        d("Cam"),
        d("Cangaroo"),
        d("Casualplay"),
        d("Coccobello"),
        d("Colibro"),
        d("Concord"),
        d("Cottonmoose"),
        d("CuddleCo"),
        d("Cybex"),
        d("Disney"),
        d("Dita"),
        d("Dooky"),
        d("Dorjan"),
        d("EasyGo"),
        d("Easywalker"),
        d("Eckert"),
        d("Elodie Details"),
        d("Emitex"),
        d("Emmaljunga"),
        d("Ergee"),
        d("Ergobaby"),
        d("ESITO"),
        d("Espiro"),
        d("Esprit"),
        d("Fellhof"),
        d("Firkon"),
        d("Fillikid"),
        d("Floo for baby"),
        d("G-Mini"),
        d("Gap"),
        d("GB"),
        d("Gesslein"),
        d("Graco"),
        d("Greentom"),
        d("HappyBee"),
        d("Hartan"),
        d("Hauck"),
        d("H&M"),
        d("Heitmann"),
        d("Chicco"),
        d("iCandy"),
        d("iCoo"),
        d("Infantilo"),
        d("Inglesina"),
        d("IvemaBaby"),
        d("Jan\u00e9"),
        d("Jacadi"),
        d("Joolz"),
        d("JJ Cole"),
        d("Junama"),
        d("Kaarsgaren"),
        d("Kaiser"),
        d("Kees"),
        d("Kerttu"),
        d("Kiddy"),
        d("Knorr Baby"),
        d("Koochi"),
        d("Lady Ann"),
        d("Linda Cube"),
        d("Lindex"),
        d("Little Angel\u00ae"),
        d("LittleEye"),
        d("Lodger"),
        d("LUPILU"),
        d("Maclaren"),
        d("Mamas&Papas"),
        d("Makaszka"),
        d("MamaTato"),
        d("Maxi-Cosi"),
        d("Mayoral"),
        d("Mima"),
        d("MIMI original"),
        d("Minene"),
        d("Moon"),
        d("Mothercare"),
        d("Mountain Buggy"),
        d("Mutsy"),
        d("Nattou"),
        d("Nellys"),
        d("Neonato"),
        d("Nestor"),
        d("NEW BABY"),
        d("Newbie"),
        d("Naf Naf"),
        d("Nellytex"),
        d("Nuna"),
        d("Nunu Nana"),
        d("Nuvita"),
        d("Odenw\u00e4lder BabyNest"),
        d("Okay"),
        d("Osann"),
        d("Peg-P\u00e9rego"),
        d("Petite Ch\u00e9rie"),
        d("Petite&Mars"),
        d("Phil&Teds"),
        d("Pidilidi"),
        d("Pinkie"),
        d("Pr\u00e9maman"),
        d("Quinny"),
        d("Ralph Lauren"),
        d("Recaro"),
        d("Red Castle"),
        d("Red Kite"),
        d("Reima"),
        d("Reserved"),
        d("Richelieu"),
        d("Safety 1st"),
        d("Sensillo"),
        d("Silver Cross"),
        d("Sinsay"),
        d("SL Sportsline"),
        d("SPRINGOS"),
        d("Steggi"),
        d("Stokke"),
        d("Tako"),
        d("Tesoro"),
        d("Teutonia"),
        d("Touragoo"),
        d("The Tiny Universe"),
        d("Voksi"),
        d("Wallaboo"),
        d("Womar Zaffiro"),
        d("X-Lander"),
        d("Zopa"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
