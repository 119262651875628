import cx from 'classnames';
import { Location } from 'history';
import { hasAds } from 'mk/bazaar/common/userUtils';
import { AD_UNITS, Collapse } from 'mk/ox/hbAdUnits';
import { ZoneTargeting } from 'mk2/containers/AdSlot/interfaces';
import { adSlotRefresh, adSlotRegister, adSlotUnregister } from 'mk2/containers/AdSlot/AdSlot.actions';
import { getMaxHeightWithAd } from 'mk2/containers/AdSlot/AdSlot.helpers';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { getRequestDeviceMobile, getRequestUser, AppState } from 'mk2/reducers';
import { UserEntity } from 'mk2/schemas';
import React from 'react';
import { connect } from 'react-redux';
import styles from './AdSlot.mscss';

// TODO: How to sync between server and client?
let dynamicAdCount = 0;
let registeredSlots: { [slotId: string]: { slotId: string, zone: string, targeting: ZoneTargeting }} = {};

const MARGIN_CLASSNAMES = {
    top: {
        3: 'AdSlot--marginTop3',
        10: 'AdSlot--marginTop10',
        20: 'AdSlot--marginTop20',
        30: 'AdSlot--marginTop30',
    },
    bottom: {
        3: 'AdSlot--marginBottom3',
        10: 'AdSlot--marginBottom10',
        20: 'AdSlot--marginBottom20',
        30: 'AdSlot--marginBottom30',
    },
};

export interface OwnProps {
    className?: string;
    style?: any;
    zone: string;
    targeting?: ZoneTargeting;
    prefix?: string;
    forceShowAds?: boolean;
    location: Location;
    children?: React.ReactNode;

    slotRef?: React.RefObject<HTMLDivElement>;

    // TODO: CeMi vyriesit krajsie, tak ze sa refactorne ako sa generuje slotId.
    // Ak budeme mat slotId v OwnProps, mozeme sa ist do reduxu opytat , ci sa nejaka
    // reklama zobrazila a podla toho vygenerovat DOM
    marginTopWithAd?: 3 | 10 | 20 | 30;
    marginBottomWithAd?: 3 | 10 | 20 | 30;
}

interface StateProps {
    requestUser: UserEntity;
    isMobile: boolean;
}

interface DispatchProps {
    onRegister(slotId: string, zone: string, targeting: ZoneTargeting);
    onUnregister(slotId: string, zone: string);
    onRefresh(stotId: string);
}

type Props = OwnProps & StateProps & DispatchProps;

declare const window;

interface State {
    slotId: string;
}

class AdSlotComponent extends React.PureComponent<Props, State> {

    public static defaultProps = {
        prefix: 'mk-slot',
    };

    public constructor(props: Props) {
        super(props);

        const slotId = this.generateSlotId();
        this.state = {
            slotId,
        };

        if (process.env.SERVER) {
            registeredSlots[slotId] = {
                slotId,
                zone: props.zone,
                targeting: props.targeting,
            };
        }
    }

    public componentDidMount() {
        this.register();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        // Zone has changed - unregister
        // NOTE: Should we also test targeting (deep equal)? - currently no
        if (this.props.zone !== nextProps.zone) {
            this.unregister();

            const newSlotId = this.generateSlotId();
            if (this.state.slotId !== newSlotId) {
                this.setState({
                    slotId: newSlotId,
                });
            }
        }
    }

    public componentDidUpdate(prevProps: Props) {
        // Zone has changed - register
        // NOTE: Should we also test targeting (deep equal)? - currently no
        if (prevProps.zone !== this.props.zone) {
            this.register();
        }

        if (!prevProps.location || !this.props.location || prevProps.location.key !== this.props.location.key) {
            this.refresh();
        }
    }

    public componentWillUnmount() {
        this.unregister();
    }

    public render() {
        const { className, forceShowAds, isMobile, requestUser, children, marginTopWithAd, marginBottomWithAd, zone } = this.props;

        if (!this.showOnThisDevice() || (!forceShowAds && !hasAds(requestUser))) {
            return null;
        }
        const adUnit = AD_UNITS[zone];
        const maxHeightWithAd = getMaxHeightWithAd(adUnit, isMobile);
        const displayAdSlotPlaceholder = adUnit.collapse === Collapse.NEVER || adUnit.collapse === Collapse.AFTER_FETCH;

        return (
            <div
                suppressHydrationWarning
                id={this.state.slotId}
                className={cx(
                    styles.AdSlot,
                    className,
                    // apply margins and min height on inner div (if ad filled)
                    marginTopWithAd && styles[MARGIN_CLASSNAMES.top[marginTopWithAd]],
                    marginBottomWithAd && styles[MARGIN_CLASSNAMES.bottom[marginBottomWithAd]],
                )}
                ref={this.props.slotRef}
                style={{
                    // apply min height on current div to show a placeholder gap till ad (inner div) is unfilled
                    minHeight: displayAdSlotPlaceholder ? (
                        (marginTopWithAd || 0) +
                        (maxHeightWithAd || 0) +
                        (marginBottomWithAd || 0)
                    ) : undefined,
                    ...this.props.style,
                }}
            >
                {children}
            </div>
        );
    }

    private refresh() {
        const { onRefresh, forceShowAds, requestUser } = this.props;
        if (this.showOnThisDevice() && (forceShowAds || hasAds(requestUser))) {
            onRefresh(this.state.slotId);
        }
    }

    private showOnThisDevice() {
        const { isMobile, zone } = this.props;

        return isMobile
            ? !zone.endsWith('(Desktop)')  // ignoruj Desktop-only plochy na mobile
            : !zone.endsWith('(Mobile)');  // ignoruj Mobile-only plochy na desktope
    }

    private generateSlotId = () => {
        return `${this.props.prefix}-${dynamicAdCount++}`;
    };

    private register = () => {
        const { zone, targeting, onRegister, forceShowAds, requestUser } = this.props;
        if (this.showOnThisDevice() && (forceShowAds || hasAds(requestUser))) {
            onRegister(this.state.slotId, zone, targeting);
        }
    };

    private unregister = () => {
        const { zone, onUnregister, forceShowAds, requestUser } = this.props;
        if (this.showOnThisDevice() && (forceShowAds || hasAds(requestUser))) {
            onUnregister(this.state.slotId, zone);
        }
    };
}

function mapStateToProps(state: AppState): StateProps {
    return {
        requestUser: getRequestUser(state),
        isMobile: getRequestDeviceMobile(state),
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onRegister: adSlotRegister,
    onUnregister: adSlotUnregister,
    onRefresh: adSlotRefresh,
};

export const AdSlot = connect(mapStateToProps, mapDispatchToProps)(AdSlotComponent);

export function resetAdCount() {
    dynamicAdCount = 0;
}

export function clearRegisteredSlots() {
    registeredSlots = {};
}

export function getRegisteredSlots() {
    return registeredSlots;
}
