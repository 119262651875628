/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp1: Array<[string, string]> = [
    d("<50"),
    d("50"),
    d("56"),
    d("62"),
    d("68"),
    d("74"),
    d("80"),
    d("86"),
    d("92"),
    d("98"),
    d("104"),
    d("110"),
    d("116"),
    d("122"),
    d("128"),
    d("134"),
    d("140"),
    d("146"),
    d("152"),
    d("158"),
    d("164"),
    d("170"),
    d("176"),
    d("182"),
    d("188"),
];
