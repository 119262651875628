import * as Sentry from '@sentry/minimal';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

export function createSentryMiddleware<DispatchExt = {}, S = any, D extends Dispatch = Dispatch>(): Middleware<DispatchExt, S, D> {
    return (api: MiddlewareAPI<D, S>) => {
        // Record the initial state in case we crash before the first action succeeds.
        // Sentry.configureScope((scope) => {
        //     scope.setExtra('state', stateDataForSentry(api.getState()));
        // });

        return (next: Dispatch<AnyAction>) => (action: AnyAction): AnyAction => {
            // record action to Sentry's breadcrumb history so that we have narrative
            // context in our error report.
            Sentry.configureScope((scope) => {
                scope.addBreadcrumb({
                    category: 'redux-action',
                    message: action.type,
                    data: Object.keys(action).reduce((acc, cur) => {
                        // Skip type - used as a message
                        if (cur === 'type') {
                            return acc;
                        }

                        // Copy only primitive values - ignore complex objects
                        if (
                            typeof action[cur] === 'string' ||
                            typeof action[cur] === 'number' ||
                            typeof action[cur] === 'boolean'
                        ) {
                            acc[cur] = action[cur];
                        }
                        return acc;
                    }, {}),
                });

            });

            return next(action);
        };
    };
}
