// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const _500_MESSAGE_2 = "Str\u00e1nka je z technick\u00fdch p\u0159\u00ed\u010din nedostupn\u00e1";
export const _500_REPORT = "Nahl\u00e1sit";
export const CTA_GO_TO_HOMEPAGE = "Jdi na homepage";
export const CTA_RETURN_BACK = "Vra\u0165 se zp\u011bt";
export const LOAD_ERROR_RETRY = "Zkus to znovu";
export const RAVEN_REPORT_ERROR_FORM_ENTRY = "N\u011bkter\u00e9 polo\u017eky jsou neplatn\u00e9. Pros\u00edm, zkontroluj je. ";
export const RAVEN_REPORT_ERROR_GENERIC = "Nastala neo\u010dek\u00e1van\u00e1 chyba p\u0159i odes\u00edl\u00e1n\u00ed tv\u00e9ho reportu. Zkus to pros\u00edm znovu.";
export const RAVEN_REPORT_LABEL_CLOSE = "Zav\u0159\u00edt";
export const RAVEN_REPORT_LABEL_COMMENTS = "Co p\u0159esn\u011b se stalo? Co jsi cht\u011bla ud\u011blat?";
export const RAVEN_REPORT_LABEL_EMAIL = "E-mail";
export const RAVEN_REPORT_LABEL_NAME = "Login (p\u0159ihla\u0161ovac\u00ed jm\u00e9no) ";
export const RAVEN_REPORT_SUBMIT = "Odeslat";
export const RAVEN_REPORT_SUBTITLE = "Modr\u00fd kon\u00edk team byl informovan\u00fd.";
export const RAVEN_REPORT_SUBTITLE2 = "Pokud n\u00e1m chce\u0161 pomoci, napi\u0161 n\u00e1m, co se stalo.";
export const RAVEN_REPORT_SUCCESS_MESSAGE = "Tv\u016fj report byl \u00fasp\u011b\u0161n\u011b odeslan\u00fd. D\u011bkujeme.";
export const RAVEN_REPORT_TITLE = "Vypad\u00e1 to, \u017ee m\u00e1me n\u011bjak\u00fd techick\u00fd probl\u00e9m.";
