import { ComplaintToggleAction, COMPLAINT_TOGGLE } from 'mk2/containers/Complaint/Complaint.actions';
import { complaintKey } from 'mk2/containers/Complaint/Complaint.schemas';
import { tupdate } from 'mk2/helpers/types';

export interface ComplaintsState {
    [entitySlashId: string]: ComplaintState;
}

export const initialComplaintsState: ComplaintsState = {};

interface ComplaintState {
    show: boolean;
}

export const complaintReducer = (state: ComplaintsState = initialComplaintsState, action: ComplaintToggleAction) => {
    switch (action.type) {
        case COMPLAINT_TOGGLE:
            const key = complaintKey(action.entity, action.id);
            const complaintState: ComplaintState = state[key] || { show: false };

            // Just toggle current state if opened is undefined
            complaintState.show = action.opened === undefined ? !complaintState.show : action.opened;

            return tupdate(state, {
                [key]: complaintState,
            });
        default:
            return state;
    }
};
