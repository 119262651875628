/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const occasion_wear : {[key: string]: Array<[string, string]>} = {
    p_occasion: [
        ["BAPTISM", "K\u0159est"],
        ["WEDDING", "Svatba"],
        ["XMAS", "V\u00e1noce"],
        ["BIRTHDAY", "Narozeniny"],
        ["FIRST_HOLY_COMMUNION", "Prvn\u00ed svat\u00e9 p\u0159ij\u00edm\u00e1n\u00ed"],
        ["PHOTOGRAPHY", "Focen\u00ed"],
    ],
    p_type: [
        ["CLOTHING", "Oble\u010den\u00ed"],
        ["BABY_WRAP", "Pe\u0159inka"],
        ["FASHION_ACCESSORIES", "Modn\u00ed dopln\u011bk"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_color: commonProp4,
};
