import { toastAdd } from 'mk2/actions';
import { ServerErrorToast } from 'mk2/components/toasts/ServerErrorToast';
import { Toast } from 'mk2/components/toasts/Toast';
import React from 'react';
import { actions as toastrActions } from 'react-redux-toastr';
import { put } from 'redux-saga/effects';

export function showSuccessToastAction(msg: string | React.ReactNode) {
    return toastAdd('success', {
        component: ({ remove }) => (<Toast type="success" remove={remove}>{msg}</Toast>),
    });
}

export function* showSuccessToast(msg: string | React.ReactNode) {
    yield put(showSuccessToastAction(msg));
}

export function showErrorToastAction(msg: string | React.ReactNode) {
    return toastAdd('error', {
        component: ({ remove }) => (<Toast type="error" remove={remove}>{msg}</Toast>),
    });
}

export function* showErrorToast(msg: string | React.ReactNode) {
    yield put(showErrorToastAction(msg));
}

export function showWarningToastAction(msg: string | React.ReactNode) {
    return toastAdd('warning', {
        component: ({ remove }) => (<Toast type="warning" remove={remove}>{msg}</Toast>),
    });
}

export function* showWarningToast(msg: string | React.ReactNode) {
    yield put(showWarningToastAction(msg));
}

export function* showServerErrorToast() {
    // Display toast message
    yield put(toastAdd('error', {
        component: ServerErrorToast,
    }));
}

export function* cleanErrorToasts() {
    yield put(toastrActions.removeByType('error'));
}
