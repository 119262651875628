import cx from 'classnames';
import { isAuthenticated, isAuthenticatedAs } from 'mk/bazaar/common/userUtils';
import { analyticsStoreEvent } from 'mk2/actions';
import { BrandDaysCampaignEntity } from 'mk2/apps/forum/schemas';
import { Header } from 'mk2/components/Header';
import { NotificationMenu } from 'mk2/components/NotificationMenu';
import { SwipeLeftToClose } from 'mk2/components/SwipeLeftToClose';
import { pageHeaderFetchFlaggedCountsTrigger, pageHeaderLoad } from 'mk2/containers/PageHeader/PageHeader.action';
import { MenuItem } from 'mk2/containers/PageHeader/PageHeader.reducers';
import { DesktopHeader } from 'mk2/containers/SiteMenu/DesktopHeader';
import styles from 'mk2/containers/SiteMenu/SiteMenu.mscss';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import Loadable from 'mk2/helpers/Loadable';
import {
    getPageHeaderState,
    getRequestDeviceMobile,
    getRequestPermissions,
    getRequestUser,
    AppState,
} from 'mk2/reducers';
import { MenuSelection, SubscribedCounsellingsCounts, UserEntity } from 'mk2/schemas';
import { subscribeJam, unsubscribeJam } from 'mk2/services/jam';
import React from 'react';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { Sticky } from 'react-sticky';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

/* UserMenu is displayed on user interaction - so we can code split it safely */
const MobileMenu = Loadable({
    loader: () => import('mk2/components/MobileMenu' /* webpackChunkName: "components.MobileMenu" */),
    modules: ['mk2/components/MobileMenu'],
    webpack: () => [ require.resolveWeak('mk2/components/MobileMenu') ],
});

const CSSTransition = Loadable<CSSTransitionProps, any>({
    loader: () => import('react-transition-group' /* webpackChunkName: "react-transition-group" */).then((mod) => mod.CSSTransition),
    modules: ['react-transition-group'],
    webpack: () => [ require.resolveWeak('react-transition-group') ],
});

interface OwnState {
    mobileMenuOpened: boolean;
    subMenuDropdownOpened: boolean;
}

interface OwnProps {
    menuSelection?: MenuSelection;
    currentUrl: string;  // nemozeme pouzit withRouter(), ten nefunguje v bazari
    isHeaderSticky: boolean;
    dummySticky?: boolean;
    mobileShowLogo?: boolean;
    headerHasBackBtn?: boolean;
    headerTitle?: string;
    headerLeft?: React.ReactNode;
    headerRight?: React.ReactNode;
    headerTabs?: JSX.Element[];
}

interface StateProps {
    requestUser: UserEntity;
    requestUserPermissions: string[];
    isMobile: boolean;
    heartsCount: number;
    unreadMails: number;
    newBazaarEvents: number;
    newBazaarSell: number;
    allBazaarSell: number;
    wallStatusNews: number;
    wishlistUpdates: number;
    newContests: number;
    subscribedCounsellings: SubscribedCounsellingsCounts;
    newTestingCalls: number;
    brandDaysCampaign: BrandDaysCampaignEntity;
    feedMenu: MenuItem[];
}

interface DispatchProps {
    onLoad(username: string);
    onFetchFlaggedCounts();
    onStoreEvent(name: string, props: any);
}

type Props = OwnProps & StateProps & DispatchProps;

class SiteMenu extends React.PureComponent<Props, OwnState> {

    private notificationMenu = React.createRef<NotificationMenu>();

    constructor(props: Props) {
        super(props);

        this.state = {
            mobileMenuOpened: false,
            subMenuDropdownOpened: false,
        };
    }

    public render() {
        const {
            isMobile, currentUrl, isHeaderSticky, dummySticky, headerTabs,
            requestUser, requestUserPermissions, menuSelection,
            heartsCount, unreadMails, newBazaarEvents, newBazaarSell, allBazaarSell,
            wallStatusNews, wishlistUpdates, newContests, subscribedCounsellings,
            feedMenu, newTestingCalls, onStoreEvent,
        } = this.props;

        if (!isMobile) {
            return (
                <React.Fragment> {/* <div className={styles.SiteMenu}> reduce DOM depth */}
                    {this.renderToastr()}
                    <DesktopHeader
                        menuSelection={menuSelection}
                        currentUrl={currentUrl}

                        requestUser={requestUser}
                        requestUserPermissions={requestUserPermissions}
                        isMobile={isMobile}
                        heartsCount={heartsCount}
                        unreadMails={unreadMails}
                        newBazaarEvents={newBazaarEvents}
                        newBazaarSell={newBazaarSell}
                        allBazaarSell={allBazaarSell}
                        wallStatusNews={wallStatusNews}
                        wishlistUpdates={wishlistUpdates}
                        newContests={newContests}
                        subscribedCounsellings={subscribedCounsellings}
                        newTestingCalls={newTestingCalls}
                        notificationMenuRef={this.notificationMenu}
                        onMenuClose={this.menuClose}
                        onStoreEvent={onStoreEvent}
                        feedMenu={feedMenu}
                        subMenuDropdownOpened={this.state.subMenuDropdownOpened}
                        onSubMenuDropdownChange={this.onSubMenuDropdownChange}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <div className={dummySticky ? styles.SiteMenu__mobileDummyStickyContainer : null}>
                    <React.Fragment>
                        <input className={styles.SiteMenu__mobileMenu__trigger} id="mobileMenuTrigger" checked={this.state.mobileMenuOpened} type="checkbox" onChange={this.onMobileMenuChange} />
                        <label htmlFor="mobileMenuTrigger" className={cx(styles.SiteMenu__mobileOverlay, this.state.mobileMenuOpened && styles['SiteMenu__mobileOverlay--visible'])} />
                    </React.Fragment>

                    <div className={cx(styles.SiteMenu__header, dummySticky ? styles.SiteMenu__mobileDummySticky : null)}>
                        {this.renderToastr()}
                        {isHeaderSticky ? (
                            <Sticky
                                /* force re-render if privacy leaflet is closed, or tabs are shown/hidden */
                                key={`render-on-change-of-${headerTabs ? headerTabs.length : 0}`}
                            >
                                {({ style }) => (
                                    // top: 0 je pridane, aby header nebol odskoceny, inak ak sa zmeni urlko
                                    // z predchadzajucej stranke moze ostat nastaveny zly top offset
                                    <div style={{...style, top: 0}}>
                                        {this.renderMobileHeader()}
                                    </div>
                                )}
                            </Sticky>
                        ) : (
                            <React.Fragment>
                                {this.renderMobileHeader()}
                            </React.Fragment>
                        )}
                    </div>

                    {this.renderMobileMenu()}
                </div>
            );
        }
    }

    public renderToastr() {
        return (
            <ReduxToastr
                position="top-center"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
            />
        );
    }

    public renderMobileHeader() {
        const {
            unreadMails, newBazaarEvents, newBazaarSell, wallStatusNews, isMobile, mobileShowLogo,
            headerTitle, headerLeft, headerRight, headerTabs,
        } = this.props;

        return (
            <div className={styles.SiteMenu__header__content}>
                <Header
                    heading={headerTitle}
                    left={headerLeft}
                    right={headerRight}
                    isMobile={isMobile}
                    showNotificationsDot={unreadMails > 0 || newBazaarEvents > 0 || newBazaarSell > 0 || wallStatusNews > 0}
                    showLogo={mobileShowLogo}
                />

                {headerTabs && headerTabs.length && (
                    <div className={styles.SiteMenu__header__tabs}>
                        {headerTabs.map((tab, index) => tab && (
                            <div key={index} className={styles.SiteMenu__header__tab}>{tab}</div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    public renderMobileMenu() {
        const {
            heartsCount, unreadMails, wishlistUpdates, newBazaarEvents, newBazaarSell, allBazaarSell,
            wallStatusNews, currentUrl, brandDaysCampaign, feedMenu, newContests,
            requestUser, requestUserPermissions, menuSelection, subscribedCounsellings, onStoreEvent, newTestingCalls,
        } = this.props;

        let activeSection;
        let activeItem;
        if (menuSelection && menuSelection.activeSection) {
            activeSection = menuSelection.activeSection;
        }
        if (menuSelection && menuSelection.activeItem) {
            activeItem = menuSelection.activeItem;
        }

        return (
            <CSSTransition
                classNames={{
                    enter: styles['SiteMenu__mobileMenu--enter'],
                    enterActive: styles['SiteMenu__mobileMenu--enterActive'],
                    exit: styles['SiteMenu__mobileMenu--exit'],
                    exitActive: styles['SiteMenu__mobileMenu--exitActive'],
                }}
                in={this.state.mobileMenuOpened}
                mountOnEnter
                unmountOnExit
                timeout={300}
            >
                <SwipeLeftToClose className={styles.SiteMenu__mobileMenu__content} onClose={this.menuClose}>
                    <MobileMenu
                        requestUser={requestUser}
                        requestUserPermissions={requestUserPermissions}
                        activeSection={activeSection}
                        activeItem={activeItem}
                        currentUrl={currentUrl}
                        heartsCount={heartsCount}
                        unreadMails={unreadMails}
                        newBazaarEvents={newBazaarEvents}
                        newBazaarSell={newBazaarSell}
                        allBazaarSell={allBazaarSell}
                        wallStatusNews={wallStatusNews}
                        wishlistUpdates={wishlistUpdates}
                        newContests={newContests}
                        subscribedCounsellings={subscribedCounsellings}
                        brandDaysCampaign={brandDaysCampaign}
                        feedMenu={feedMenu}
                        onItemClick={this.menuClose}
                        onStoreEvent={onStoreEvent}
                        newTestingCalls={newTestingCalls}
                    />
                </SwipeLeftToClose>
            </CSSTransition>
        );
    }

    public componentDidMount() {
        const { requestUser, requestUserPermissions, onFetchFlaggedCounts } = this.props;

        // do not need to call onLoad(), initial data are passed from django via html
        // onLoad(requestUser.username);

        // fetch asynch count of flagged posts and groups
        const isBlogsAdmin = requestUserPermissions.indexOf('photoblog.can_manage_blogs') >= 0;
        const isForumAdmin = requestUserPermissions.indexOf('forum.can_manage_forum') >= 0;
        if (isBlogsAdmin || isForumAdmin) {
            onFetchFlaggedCounts();
        }

        if (isAuthenticated(requestUser)) {
            subscribeJam('userbar:' + requestUser.username);
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const shouldUnsubscribe = isAuthenticated(prevProps.requestUser);
        const shouldSubscribe = isAuthenticated(this.props.requestUser);

        // Check if user has changed
        if (!shouldUnsubscribe || !shouldSubscribe || !isAuthenticatedAs(prevProps.requestUser, this.props.requestUser)) {
            // Previous user should be unsubscribed
            if (shouldUnsubscribe) {
                unsubscribeJam('userbar:' + prevProps.requestUser.username);
            }

            // Current user should be subscribed
            if (shouldSubscribe) {
                subscribeJam('userbar:' + this.props.requestUser.username);
            }
        }
    }

    public componentWillUnmount() {
        const { requestUser } = this.props;

        if (isAuthenticated(requestUser)) {
            unsubscribeJam('userbar:' + requestUser.username);
        }
    }

    private menuClose = () => {
        this.setState({
            mobileMenuOpened: false,
        });
        if (this.notificationMenu.current) {
            this.notificationMenu.current.close();
        }
    };

    private onSubMenuDropdownChange = (opened: boolean) => {
        this.setState({
            subMenuDropdownOpened: opened,
        });
    };

    private onMobileMenuChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            mobileMenuOpened: event.currentTarget.checked,
        });
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const pageHeaderState = getPageHeaderState(state);
    const requestUser = getRequestUser(state);
    return {
        requestUser,
        requestUserPermissions: getRequestPermissions(state),
        isMobile: getRequestDeviceMobile(state),
        heartsCount: pageHeaderState.userData.heartsCount,
        unreadMails: pageHeaderState.userData.unreadMails,
        newBazaarEvents: pageHeaderState.userData.newBazaarEvents,
        newBazaarSell: pageHeaderState.userData.newBazaarSell,
        allBazaarSell: pageHeaderState.userData.allBazaarSell,
        wallStatusNews: pageHeaderState.userData.wallStatusNews,
        wishlistUpdates: pageHeaderState.userData.wishlistUpdates,
        newContests: pageHeaderState.userData.newContests,
        subscribedCounsellings: pageHeaderState.userData.subscribedCounsellings,
        newTestingCalls: pageHeaderState.userData.newTestingCalls,
        brandDaysCampaign: pageHeaderState.brandDaysCampaign,
        feedMenu: pageHeaderState.feedMenu,
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onLoad: pageHeaderLoad,
    onFetchFlaggedCounts: pageHeaderFetchFlaggedCountsTrigger,
    onStoreEvent: analyticsStoreEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteMenu);
