import { schema } from 'normalizr';

import { Entity } from 'mk2/schemas';

export interface LikeableEntity extends Entity {
    isLiked: boolean;
    likesCount: number;
    entity?: string;
}

export const likeKey = (entity: string, id: number): string => `${entity}/${id}`;

export const createLikeableSchema = (entity) => new schema.Entity('likeable', {}, {
    idAttribute: (value, parent, key) => likeKey(entity, value.id || parent.id),
    processStrategy: (value, parent, key) => ({ ...value, id: parent.id, entity }),
});
