import { faBell, faEnvelope, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { heartsUrl } from 'mk2/apps/hearts/urls';
import { mailBoxUrl } from 'mk2/apps/mail/urls';
import { wallMentionsUrl } from 'mk2/apps/wall/urls';
import { AvatarImg } from 'mk2/components/AvatarImg';
import { Link } from 'mk2/components/Link';
import { NotificationsBadge, Type } from 'mk2/components/NotificationsBadge';
import { url } from 'mk2/helpers/urls';
import Loadable from 'mk2/helpers/Loadable';
import { UserEntity } from 'mk2/schemas';
import colors from 'mk2/styles/colors.scss';
import React from 'react';
import styles from './NotificationMenu.mscss';

/* UserMenu is displayed on user interaction - so we can code split it safely */
const UserMenu = Loadable({
    loader: () => import('mk2/components/UserMenu' /* webpackChunkName: "components.UserMenu" */),
    modules: ['mk2/components/UserMenu'],
    webpack: () => [ require.resolveWeak('mk2/components/UserMenu') ],
});

interface OwnProps {
    isMobile?: boolean;
    heartsCount: number;
    unreadMails: number;
    wallStatusNews: number;
    requestUser: UserEntity;
    activeSection: string;
    activeItem: string;

    onItemClick();
    onStoreEvent(name: string, props: any);
}

type Props = OwnProps;

interface State {
    userbarOpened: boolean;
}

export class NotificationMenu extends React.PureComponent<Props, State> {

    public static defaultProps: Partial<OwnProps> = {
        isMobile: false,
    };

    public state: State = {
        userbarOpened: false,
    };

    public render() {
        const {
            isMobile, heartsCount, unreadMails, requestUser, wallStatusNews,
            activeItem, activeSection,
            onItemClick,
        } = this.props;

        return (
            <div className={cx(styles.NotificationMenu)}>
                <Link
                    onClick={this.onHeartClick}
                    to={heartsUrl}
                    className={cx(styles.NotificationMenu__userIcon, 'topmenu-userIcon', isMobile && styles['NotificationMenu__userIcon--mobile'], (activeSection === 'hearts') ? cx(styles['NotificationMenu__userIcon--active'], 'topmenu-activeLink') : null)}
                >
                    <FontAwesomeIcon icon={faHeart} className={styles.NotificationMenu__userIcon__icon} />
                    {(heartsCount > 0) ? (
                        <NotificationsBadge className={styles.NotificationMenu__badge} count={heartsCount} backgroundColor={isMobile ? colors.menuHeader : colors.white} color={colors.primaryIcon} limit={false} />
                    ) : null}
                </Link>
                <Link
                    onClick={this.onEnvelopeClick}
                    to={url(mailBoxUrl, {username: requestUser.username, folder: 'inbox'})}
                    className={cx(styles.NotificationMenu__userIcon, 'topmenu-userIcon', isMobile && styles['NotificationMenu__userIcon--mobile'], (activeSection === 'mail') ? cx(styles['NotificationMenu__userIcon--active'], 'topmenu-activeLink') : null)}
                >
                    <FontAwesomeIcon icon={faEnvelope} className={styles.NotificationMenu__userIcon__icon} />
                    {(unreadMails > 0) ? (
                        <NotificationsBadge className={styles.NotificationMenu__badge} count={unreadMails} type={Type.Important} shadow limit={false} />
                    ) : null}
                </Link>
                <Link
                    onClick={this.onBellClick}
                    to={url(wallMentionsUrl, {username: requestUser.username})}
                    className={cx(styles.NotificationMenu__userIcon, 'topmenu-userIcon', isMobile && styles['NotificationMenu__userIcon--mobile'], (activeSection === 'wall') ? cx(styles['NotificationMenu__userIcon--active'], 'topmenu-activeLink') : null)}
                >
                    <FontAwesomeIcon icon={faBell} className={styles.NotificationMenu__userIcon__icon} />
                    {(wallStatusNews > 0) ? (
                        <NotificationsBadge className={styles.NotificationMenu__badge} count={wallStatusNews} type={Type.Important} shadow limit={false} />
                    ) : null}
                </Link>

                <div className={cx(styles.NotificationMenu__userMenu, isMobile && styles['NotificationMenu__userMenu--mobile'])}>
                    <button type="button" className={styles['NotificationMenu__userMenu--avatarCtt']} onClick={this.onUserMenuClick}>
                        <AvatarImg className={styles['NotificationMenu__userMenu--avatar']} size={28} user={requestUser}/>
                    </button>
                    {this.state.userbarOpened && (
                        <UserMenu
                            requestUser={requestUser}
                            activeItem={activeItem}
                            activeSection={activeSection}
                            onItemClick={onItemClick}
                        />
                    )}
                </div>
            </div>
        );
    }

    public close() {
        this.setState({
            userbarOpened: false,
        });
    }

    private onHeartClick = () => {
        const { onItemClick } = this.props;
        onItemClick();
    };

    private onEnvelopeClick = () => {
        const { onItemClick } = this.props;
        onItemClick();
    };

    private onBellClick = () => {
        const { onItemClick } = this.props;
        onItemClick();
    };

    private onUserMenuClick = () => {
        this.setState({
            userbarOpened: !this.state.userbarOpened,
        });
    };

}
