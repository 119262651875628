/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp16 } from './commonProp16';

export const multimedia_coloring : {[key: string]: Array<[string, string]>} = {
    p_for_whom: commonProp16,
};
