import {
    bazaarAllDropoffPointsUrl,
    bazaarCheckoutPaymentFailureUrl,
    bazaarCheckoutPaymentSuccessUrl,
    bazaarCheckoutUrl,
    bazaarCreateProductUrl,
    bazaarEditProductUrl,
    bazaarGridUrl,
    bazaarLastProductNotFoundUrl,
    bazaarMyBazaarUrl,
    bazaarMyReviewsFromBuyersUrl,
    bazaarMyReviewsFromSellersUrl,
    bazaarMyTransactionsUrl,
    bazaarMyWidgetUrl, bazaarOrderProblemUrl, bazaarOrderStateUrl,
    bazaarProductDetailUrl,
    bazaarSellerBazaarUrl,
    bazaarSellerReviewsFromBuyersUrl,
    bazaarSellerReviewsFromSellersUrl,
    bazaarSellerSearchUrl,
    bazaarShoppingUrl,
    bazaarWatchdogSettingsUrl,
    bazaarWishlistInactiveUrl,
    bazaarWishlistUrl,
} from 'mk2/apps/bazaar/urls';
import { Route } from 'mk2/containers/Application/Application';
import { url } from 'mk2/helpers/urls';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [
    {
        app: 'bazaar',
        exact: true,
        path: bazaarLastProductNotFoundUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/MyBazaarLastProductNotFound/MyBazaarLastProductNotFoundPage' /* webpackChunkName: "bazaar.ProductDetail" */);
        },
        modules: ['mk2/apps/bazaar/containers/MyBazaarLastProductNotFound/MyBazaarLastProductNotFoundPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarCheckoutUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/Checkout/CheckoutPage' /* webpackChunkName: "bazaar.Checkout" */);
        },
        modules: ['mk2/apps/bazaar/containers/Checkout/CheckoutPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarCheckoutPaymentSuccessUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/CheckoutPaymentSuccess/CheckoutPaymentSuccessPage' /* webpackChunkName: "bazaar.CheckoutPaymentSuccess" */);
        },
        modules: ['mk2/apps/bazaar/containers/CheckoutPaymentSuccess/CheckoutPaymentSuccessPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarCheckoutPaymentFailureUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/CheckoutPaymentFailure/CheckoutPaymentFailurePage' /* webpackChunkName: "bazaar.CheckoutPaymentFailure" */);
        },
        modules: ['mk2/apps/bazaar/containers/CheckoutPaymentFailure/CheckoutPaymentFailurePage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarOrderProblemUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/OrderProblem/OrderProblemPage' /* webpackChunkName: "bazaar.OrderProblem" */);
        },
        modules: ['mk2/apps/bazaar/containers/OrderProblem/OrderProblemPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarOrderStateUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/BazaarOrder/BazaarOrderPage' /* webpackChunkName: "bazaar.OrderProblem" */);
        },
        modules: ['mk2/apps/bazaar/containers/BazaarOrder/BazaarOrderPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarAllDropoffPointsUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/AllDropoffPoints/AllDropoffPointsPage' /* webpackChunkName: "bazaar.AllDropoffPoints" */);
        },
        modules: ['mk2/apps/bazaar/containers/AllDropoffPoints/AllDropoffPointsPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarSellerSearchUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/SellerSearch/SellerSearchPage' /* webpackChunkName: "bazaar.SellerSearch" */);
        },
        modules: ['mk2/apps/bazaar/containers/SellerSearch/SellerSearchPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarSellerReviewsFromBuyersUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/SellerReviews/SellerReviewsPage' /* webpackChunkName: "bazaar.SellerReviews" */);
        },
        modules: ['mk2/apps/bazaar/containers/SellerReviews/SellerReviewsPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarSellerReviewsFromSellersUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/SellerReviews/SellerReviewsPage' /* webpackChunkName: "bazaar.SellerReviews" */);
        },
        modules: ['mk2/apps/bazaar/containers/SellerReviews/SellerReviewsPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarMyReviewsFromBuyersUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/MyReviews/MyReviewsPage' /* webpackChunkName: "bazaar.MyReviews" */);
        },
        modules: ['mk2/apps/bazaar/containers/MyReviews/MyReviewsPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarMyReviewsFromSellersUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/MyReviews/MyReviewsPage' /* webpackChunkName: "bazaar.MyReviews" */);
        },
        modules: ['mk2/apps/bazaar/containers/MyReviews/MyReviewsPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarMyWidgetUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/MyWidget/MyWidgetPage' /* webpackChunkName: "bazaar.MyWidget" */);
        },
        modules: ['mk2/apps/bazaar/containers/MyWidget/MyWidgetPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarMyTransactionsUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/MyTransactions/MyTransactionsPage' /* webpackChunkName: "bazaar.MyTransactions" */);
        },
        modules: ['mk2/apps/bazaar/containers/MyTransactions/MyTransactionsPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: '/market/my_bazaar/',
        page: new RedirectPage<{ username: string }>({
            to: (props) => url(bazaarMyBazaarUrl, { username: props.requestUser.username }),
            permanent: true,
            authOnly: true,
        }),
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarMyBazaarUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/MyBazaar/MyBazaarPage' /* webpackChunkName: "bazaar.MyBazaar" */);
        },
        modules: ['mk2/apps/bazaar/containers/MyBazaar/MyBazaarPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarSellerBazaarUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/HerBazaar/HerBazaarPage' /* webpackChunkName: "bazaar.HerBazaar" */);
        },
        modules: ['mk2/apps/bazaar/containers/HerBazaar/HerBazaarPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarWishlistInactiveUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/WishlistInactive/WishlistInactivePage' /* webpackChunkName: "bazaar.WishlistInactive" */);
        },
        modules: ['mk2/apps/bazaar/containers/WishlistInactive/WishlistInactivePage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarWishlistUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/Wishlist/WishlistPage' /* webpackChunkName: "bazaar.Wishlist" */);
        },
        modules: ['mk2/apps/bazaar/containers/Wishlist/WishlistPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarShoppingUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/Shopping/ShoppingPage' /* webpackChunkName: "bazaar.Shopping" */);
        },
        modules: ['mk2/apps/bazaar/containers/Shopping/ShoppingPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarCreateProductUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/CreateProduct/CreateProductPage' /* webpackChunkName: "bazaar.CreateProduct" */);
        },
        modules: ['mk2/apps/bazaar/containers/CreateProduct/CreateProductPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarEditProductUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/EditProduct/EditProductPage' /* webpackChunkName: "bazaar.EditProduct" */);
        },
        modules: ['mk2/apps/bazaar/containers/EditProduct/EditProductPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarWatchdogSettingsUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/WatchdogSettings/WatchdogSettingsPage' /* webpackChunkName: "bazaar.WatchdogSettings" */);
        },
        modules: ['mk2/apps/bazaar/containers/WatchdogSettings/WatchdogSettingsPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarGridUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/BazaarGrid/BazaarGridPage' /* webpackChunkName: "bazaar.BazaarGrid" */);
        },
        modules: ['mk2/apps/bazaar/containers/BazaarGrid/BazaarGridPage'],
    },
    {
        app: 'bazaar',
        exact: true,
        path: bazaarProductDetailUrl,
        getPage: () => {
            return import('mk2/apps/bazaar/containers/ProductDetail/ProductDetailPage' /* webpackChunkName: "bazaar.ProductDetail" */);
        },
        modules: ['mk2/apps/bazaar/containers/ProductDetail/ProductDetailPage'],
    },
];
