import isEmpty from 'lodash-es/isEmpty';
import mapValues from 'lodash-es/mapValues';
import { memoizeLRU, LRUCache } from 'mk2/helpers/cache';
import { AppState } from 'mk2/reducers';
import * as fromEntities from 'mk2/reducers/entities';
import { Entity, EntityWithSlug } from 'mk2/schemas';
import { denormalize, schema } from 'normalizr';

// We don't need whole AppState - only object containing 'entities'
interface State {
    entities: AppState['entities'];
}

export const getRoutingLocation = (state: AppState) => state.router.location;

const defaultEntitiesList = [];
const defaultEntitiesMap = {};

const cachedDenormalizeOne: <T>(id: string, schm: schema.Entity<T>, entities: fromEntities.EntitiesState) => T =
    // id je len typu string (callery musia skonvertovat number na string),
    // aby sme vracali ten isty cachovany objekt ked sa pytam s klucom typu string aj typu number
    memoizeLRU(
        1000,
        denormalize,
        // (id, schm, entities) => { console.log(`denormalize ${id} ${schm.key}`); return denormalize(id.toString(), schm, entities); }
    );

const cachedDenormalizeList =
    memoizeLRU(50, <T>(ids: number[] | string[], schm: schema.Entity<T>, entities: fromEntities.EntitiesState): T[] =>
        (ids as Array<string | number> /* Remove "as" when https://github.com/microsoft/TypeScript/pull/31023 will be merged */)
            .map((id: number | string): T => cachedDenormalizeOne(id.toString(), schm, entities)),
    );

const cachedDenormalizeMap =
    memoizeLRU(50, <T>(ids: {[someKey: string]: (string | number)}, schm: schema.Entity<T>, entities: fromEntities.EntitiesState) =>
        mapValues<{[someKey: string]: (string | number)}, T>(ids, (id: string | number) => cachedDenormalizeOne<T>(id.toString(), schm, entities)));

const cachedDenormalizeMapList =
    memoizeLRU(50, <T>(ids: {[someKey: string]: (string[] | number[])}, schm: schema.Entity<T>, entities: fromEntities.EntitiesState) =>
        mapValues<{[someKey: string]: (string[] | number[])}, T[]>(
            ids,
            (valueIds: string[] | number[]) =>
                (valueIds === null || valueIds === undefined)
                    ? defaultEntitiesList
                    : (valueIds as Array<string | number>).map((id: number | string) => cachedDenormalizeOne(id.toString(), schm, entities)),
        ));

export const getDenormalizedEntity: <T extends Entity>(state: State, schm: schema.Entity, id: number | string) => T =
    <T>(state: State, schm: schema.Entity, id: number | string) => {
        if (id === null || id === undefined || !state.entities[schm.key]) {
            return null;
        }
        return cachedDenormalizeOne(id.toString(), schm, state.entities);
    };

export const getDenormalizedEntities: <T extends Entity>(state: State, schm: schema.Entity, ids: number[] | string[]) => T[] =
    (state, schm, ids) => {
        if (ids === null || ids === undefined || ids.length === 0 || !state.entities[schm.key]) {
            return defaultEntitiesList; // konstanta, aby sme nesposobovali re-render
        }

        return cachedDenormalizeList(ids, schm, state.entities);
    };

export function getDenormalizedEntitiesMap<T extends Entity>(state: State, schm: schema.Entity, ids: {[someId: number]: (number | string)}): {[someId: number]: T};
export function getDenormalizedEntitiesMap<T extends Entity>(state: State, schm: schema.Entity, ids: {[someKey: string]: (number | string)}): {[someKey: string]: T} {
    if (ids === null || ids === undefined || isEmpty(ids) || !state.entities[schm.key]) {
        return defaultEntitiesMap; // konstanta, aby sme nesposobovali re-render
    }

    return cachedDenormalizeMap(ids, schm, state.entities) as any;
}

export function getDenormalizedEntitiesMapList<T extends Entity>(state: State, schm: schema.Entity, ids: {[someId: number]: (number[] | string[])}): {[someId: number]: T[]};
export function getDenormalizedEntitiesMapList<T extends Entity>(state: State, schm: schema.Entity, ids: {[someKey: string]: (number[] | string[])}): {[someKey: string]: T[]} {
    if (ids === null || ids === undefined || isEmpty(ids) || !state.entities[schm.key]) {
        return defaultEntitiesMap; // konstanta, aby sme nesposobovali re-render
    }

    return cachedDenormalizeMapList(ids, schm, state.entities) as any;
}

type EntityFilterFunc = (normalizedEntity: any) => boolean;  // we have to type as any, because calling on normalized data

const cachedFilterIdByCond = new LRUCache<string>(50);

export const findDenormalizedEntityByCond: <T extends Entity>(
    state: State,
    schm: schema.Entity,
    // 'predicate' will be most probably an anonymous function. Therefor, we can not use it as cache key,
    // it will change on every function call. Instead, caller has to provide a cacheKey.
    predicateCacheKey: string,
    predicate: EntityFilterFunc,
) => T =
    <T>(state, schm, predicateCacheKey, predicate) => {
        // we have to use type 'any', because entities are normalized
        const normalizedEntities: fromEntities.EntitiesByID<any> = state.entities[schm.key];

        if (!predicate || !predicateCacheKey || !normalizedEntities) {
            return null;
        }

        const key = [normalizedEntities, predicateCacheKey];
        let id: string = cachedFilterIdByCond.get(key);
        if (id === undefined) {
            // if not found with find(), set id to null. Because undefined would be cache-miss.
            id = Object.keys(normalizedEntities).find((eid: string) => predicate(normalizedEntities[eid])) || null;
            cachedFilterIdByCond.put(key, id);
        }

        return id !== null ? cachedDenormalizeOne(id, schm, state.entities) : null;
    };

const cachedFilterIdsByCond = new LRUCache<string[]>(50);

export const findDenormalizedEntitiesByCond: <T extends Entity>(
    state: State,
    schm: schema.Entity,
    // 'predicate' will be most probably an anonymous function. Therefor, we can not use it as cache key,
    // it will change on every function call. Instead, caller has to provide a cacheKey.
    predicateCacheKey: string,
    predicate: EntityFilterFunc,
) => T[] =
    <T>(state, schm, predicateCacheKey, predicate) => {
        // we have to use type 'any', because entities are normalized
        const normalizedEntities: fromEntities.EntitiesByID<any> = state.entities[schm.key];

        if (!predicate || !predicateCacheKey || !normalizedEntities) {
            return null;
        }

        const key = [normalizedEntities, predicateCacheKey];
        let ids: string[] = cachedFilterIdsByCond.get(key);
        if (ids === undefined) {
            ids = Object.keys(normalizedEntities).filter((eid: string) => predicate(normalizedEntities[eid]));
            cachedFilterIdsByCond.put(key, ids);
        }

        return cachedDenormalizeList(ids, schm, state.entities);
    };

export const findDenormalizedEntityBySlug: <T extends EntityWithSlug>(state: State, schm: schema.Entity, slug: string) => T =
    (state, schm, slug) =>
        findDenormalizedEntityByCond(
            state,
            schm,
            `cacheKey-${findDenormalizedEntityBySlug.name}-${slug}`,
            (normalizedEntity) => normalizedEntity.slug === slug,
        );
