import { STROLLER_BRANDS, STROLLER_COUNTRIES } from 'mk/autogenerated/strollersData';
import {
    number_MONTHS,
    FROM,
    NO,
    STROLLER_FEATURE_AVAILABILITY_ALL,
    STROLLER_FEATURE_AVAILABILITY_AVAILABLE,
    STROLLER_FEATURE_AVAILABILITY_ONLY_NEW,
    STROLLER_FEATURE_AVAILABILITY_ONLY_OLD,
    STROLLER_FEATURE_PRICE_RANGE_1,
    STROLLER_FEATURE_PRICE_RANGE_2,
    STROLLER_FEATURE_PRICE_RANGE_3,
    STROLLER_FEATURE_PRICE_RANGE_4,
    STROLLER_FEATURE_PRICE_RANGE_5,
    STROLLER_FEATURE_PRICE_RANGE_6,
    STROLLER_FEATURE_RECOMMENDED_AGE_MORE_MONTHS,
    STROLLERS_SEARCH_FACET_BRAND,
    STROLLERS_SEARCH_FULLTEXT,
    TO,
    YES,
} from 'mk/autogenerated/translations/StrollersSearch.constants.d56ef93f0a91afe5cb5a6171a7c2259b'
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { FEATURES_LIST } from 'mk2/apps/strollers/features';
import { BooleanFeature, CountriesFeature } from 'mk2/apps/strollers/features/basic';
import { FEATURE_LABEL_MESSAGES as FEAT_LABEL } from 'mk2/apps/strollers/features/constants';
import { ListFeature } from 'mk2/apps/strollers/features/options';
import { BooleanPair, LiberalBooleanListFeature } from 'mk2/apps/strollers/features/other';
import { capitalizeFirstLetter, convertStrollerType } from 'mk2/apps/strollers/helpers';
import { interpolate } from 'mk2/services/i18n';

export const STROLLERS_SEARCH_FORM_NAME = 'STROLLERS_SEARCH_FORM_NAME';

export enum PageMode {
    SHOW_SEARCH_FORM = 'edit',
    SHOW_SEARCH_RESULTS_FILTER_BY_QUERY = 'results',
    SHOW_SEARCH_RESULTS_FILTER_BY_TYPE = 'type',
}

export interface ChosenFacetValue {
    facetSetup: FacetSetup;
    chosenRawValues: string[];
}

export interface FacetValue {
    label: string; // displayed on FormPageType.SelectFacetValue
    niceValue: string; // displayed on StrollersSearch__facetsLine
    rawValue: string;
}

export interface FacetSetup {
    name: string;
    label: string;
    values?: FacetValue[];
    type: FacetType;
    getNiceValue?(rawValue: string);
}

export enum FacetType {
    LIST_SINGLESELECT = 0,
    LIST_MULTISELECT = 1,
    TEXT_INPUT = 2,
    TWIN_TEXT_INPUT = 3,
}

export enum NonFeatureFacet {
    POPULAR_BRAND = 'popular_brand',
    OTHER_BRAND = 'other_brand',
    BRAND = 'brand',
    RECOMMENDED_AGE = 'recommended_age',
    PRICE = 'price',
    AVAILABILITY = 'availability',
    COLOR = 'color',
}

const popularBrandSlugs = [
    'cybex',
    'britax',
    'bugaboo',
    'kinderkraft',
    'joolz',
    'thule',
    'easywalker',
    'maxi-cosi',
    'peg-perego',
    'petitemars',
    'abc-design',
    'baby-jogger',
    'hauck',
    'moon',
    'stokke',
    'zopa',
    'babystyle',
    'joie',
    'valco-baby',
    'espiro',
];

export const getValues = (source: object | NonFeatureFacet): FacetValue[] => {
    const facetValues: FacetValue[] = [];

    if (source instanceof BooleanFeature) {
        facetValues.push({
            label: capitalizeFirstLetter(YES.toLowerCase()),
            niceValue: `${FEAT_LABEL[source.featureName]}: ${YES.toLowerCase()}`,
            rawValue: 'true',
        });
        facetValues.push({
            label: capitalizeFirstLetter(NO.toLowerCase()),
            niceValue: `${FEAT_LABEL[source.featureName]}: ${NO.toLowerCase()}`,
            rawValue: 'false',
        });
    } else if (source instanceof ListFeature) {
        for (const key of Object.keys(source.OPTIONS)) {
            facetValues.push({
                label: capitalizeFirstLetter(source.OPTIONS[key]),
                niceValue: source.OPTIONS[key],
                rawValue: key,
            });
        }
    } else if (source instanceof LiberalBooleanListFeature) {
        for (const key of Object.keys(source.BOOLEAN_PAIRS)) {
            const bp: BooleanPair = source.BOOLEAN_PAIRS[key];
            const bpNice = bp[1]
                ? bp[1]
                : bp[0]
                ? capitalizeFirstLetter(YES.toLowerCase())
                : capitalizeFirstLetter(NO.toLowerCase());
            facetValues.push({ label: capitalizeFirstLetter(bpNice), niceValue: bpNice, rawValue: key });
        }
    } else if (source instanceof CountriesFeature) {
        for (const country of STROLLER_COUNTRIES) {
            facetValues.push({ label: country.name, niceValue: country.name, rawValue: country.id.toString() });
        }
    } else if (source === NonFeatureFacet.BRAND) {
        for (const brand of STROLLER_BRANDS) {
            facetValues.push({ label: capitalizeFirstLetter(brand.name), niceValue: brand.name, rawValue: brand.slug });
        }
    } else if (source === NonFeatureFacet.POPULAR_BRAND) {
        for (const slug of popularBrandSlugs) {
            const brand = STROLLER_BRANDS.find((b) => b.slug === slug);
            facetValues.push({ label: capitalizeFirstLetter(brand.name), niceValue: brand.name, rawValue: brand.slug });
        }
    } else if (source === NonFeatureFacet.OTHER_BRAND) {
        for (const brand of STROLLER_BRANDS) {
            if (!popularBrandSlugs.includes(brand.slug)) {
                facetValues.push({
                    label: capitalizeFirstLetter(brand.name),
                    niceValue: brand.name,
                    rawValue: brand.slug,
                });
            }
        }
    } else if (source === NonFeatureFacet.RECOMMENDED_AGE) {
        const months = (m) => interpolate(number_MONTHS, { count: m, number: m });
        const more = STROLLER_FEATURE_RECOMMENDED_AGE_MORE_MONTHS;
        facetValues.push(
            { label: '0 - 6', niceValue: `0 - ${months(6)}`, rawValue: '0_6' },
            { label: '6 - 12', niceValue: `6 - ${months(12)}`, rawValue: '6_12' },
            { label: '12 - 18', niceValue: `12 - ${months(18)}`, rawValue: '12_18' },
            { label: '18 - 24', niceValue: `18 - ${months(24)}`, rawValue: '18_24' },
            { label: '24 - 30', niceValue: `24 - ${months(30)}`, rawValue: '24_30' },
            { label: '30 - 36', niceValue: `30 - ${months(36)}`, rawValue: '30_36' },
            { label: '36 - 42', niceValue: `36 - ${months(42)}`, rawValue: '36_42' },
            { label: '42 - 48', niceValue: `42 - ${months(48)}`, rawValue: '42_48' },
            { label: '48 - 54', niceValue: `48 - ${months(54)}`, rawValue: '48_54' },
            { label: '54 - 60', niceValue: `54 - ${months(60)}`, rawValue: '54_60' },
            { label: `60 ${more}`, niceValue: `${months(60)} ${more}`, rawValue: '60_9999' },
        );
    } else if (source === NonFeatureFacet.PRICE) {
        facetValues.push(
            {
                label: STROLLER_FEATURE_PRICE_RANGE_1,
                niceValue: STROLLER_FEATURE_PRICE_RANGE_1.toLowerCase(),
                rawValue: ACTIVE_SERVER_ID === 201 ? '0-300' : '0-7500',
            },
            {
                label: STROLLER_FEATURE_PRICE_RANGE_2,
                niceValue: STROLLER_FEATURE_PRICE_RANGE_2,
                rawValue: ACTIVE_SERVER_ID === 201 ? '300-400' : '7500-10000',
            },
            {
                label: STROLLER_FEATURE_PRICE_RANGE_3,
                niceValue: STROLLER_FEATURE_PRICE_RANGE_3,
                rawValue: ACTIVE_SERVER_ID === 201 ? '400-500' : '10000-12500',
            },
            {
                label: STROLLER_FEATURE_PRICE_RANGE_4,
                niceValue: STROLLER_FEATURE_PRICE_RANGE_4,
                rawValue: ACTIVE_SERVER_ID === 201 ? '500-700' : '12500-175000',
            },
            {
                label: STROLLER_FEATURE_PRICE_RANGE_5,
                niceValue: STROLLER_FEATURE_PRICE_RANGE_5,
                rawValue: ACTIVE_SERVER_ID === 201 ? '700-1000' : '175000-25000',
            },
            {
                label: STROLLER_FEATURE_PRICE_RANGE_6,
                niceValue: STROLLER_FEATURE_PRICE_RANGE_6,
                rawValue: ACTIVE_SERVER_ID === 201 ? '1000-999999' : '25000-999999',
            },
        );
    } else if (source === NonFeatureFacet.AVAILABILITY) {
        facetValues.push(
            {
                label: STROLLER_FEATURE_AVAILABILITY_AVAILABLE,
                niceValue: STROLLER_FEATURE_AVAILABILITY_AVAILABLE,
                rawValue: 'available',
            },
            {
                label: STROLLER_FEATURE_AVAILABILITY_ONLY_NEW,
                niceValue: STROLLER_FEATURE_AVAILABILITY_ONLY_NEW,
                rawValue: 'new',
            },
            {
                label: STROLLER_FEATURE_AVAILABILITY_ONLY_OLD,
                niceValue: STROLLER_FEATURE_AVAILABILITY_ONLY_OLD,
                rawValue: 'old',
            },
            {
                label: STROLLER_FEATURE_AVAILABILITY_ALL,
                niceValue: STROLLER_FEATURE_AVAILABILITY_ALL,
                rawValue: 'all',
            },
        );
    }

    return facetValues;
};

export const getFacetSetup = (facetName: string): FacetSetup => {
    return FACET_SETUPS.filter((fs) => fs.name === facetName)[0];
};

// TODO: Refactor to use similar logic as bazaar filter
//  - with serialization to query string
//  - with deserialization from query string
export const FACET_SETUPS: FacetSetup[] = [
    { name: 'strollers_query', label: STROLLERS_SEARCH_FULLTEXT, getNiceValue: (v) => v, type: FacetType.TEXT_INPUT },
    { name: 'type', label: FEAT_LABEL.type, values: getValues(FEATURES_LIST.type), type: FacetType.LIST_MULTISELECT },
    {
        name: 'type_2kombi',
        label: FEAT_LABEL.type_2kombi,
        values: getValues(FEATURES_LIST.type_2kombi),
        type: FacetType.LIST_MULTISELECT,
    }, {
        name: 'color',
        label: FEAT_LABEL.color,
        values: getValues(NonFeatureFacet.COLOR),
        type: FacetType.LIST_MULTISELECT,
    }, {
        name: 'number_of_kids',
        label: FEAT_LABEL.number_of_kids,
        values: getValues(FEATURES_LIST.number_of_kids),
        type: FacetType.LIST_SINGLESELECT,
    },
    {
        name: 'siblings_type',
        label: FEAT_LABEL.siblings_type,
        values: getValues(FEATURES_LIST.siblings_type),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'price',
        label: FEAT_LABEL.price,
        values: getValues(NonFeatureFacet.PRICE),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'brand',
        label: STROLLERS_SEARCH_FACET_BRAND,
        values: getValues(NonFeatureFacet.BRAND),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'availability',
        label: FEAT_LABEL.availability,
        values: getValues(NonFeatureFacet.AVAILABILITY),
        type: FacetType.LIST_SINGLESELECT,
    },
    {
        name: 'rotating_fixation_wheels',
        label: FEAT_LABEL.rotating_fixation_wheels,
        values: getValues(FEATURES_LIST.rotating_fixation_wheels),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'stroller_for_parent_by_height',
        label: FEAT_LABEL.stroller_for_parent_by_height,
        values: getValues(FEATURES_LIST.stroller_for_parent_by_height),
        type: FacetType.LIST_MULTISELECT,
    },
        {
        name: 'back_support_can_be_in_vertical_seat_position',
        label: FEAT_LABEL.back_support_can_be_in_vertical_seat_position,
        values: getValues(FEATURES_LIST.back_support_can_be_in_vertical_seat_position),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'rotating_seat',
        label: FEAT_LABEL.rotating_seat,
        values: getValues(FEATURES_LIST.rotating_seat),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'swinging',
        label: FEAT_LABEL.swinging,
        values: getValues(FEATURES_LIST.swinging),
        type: FacetType.LIST_MULTISELECT,
    },

    // TODO: This will be rewritten
    {
        name: 'recommended_age',
        label: FEAT_LABEL.recommended_age,
        values: getValues(NonFeatureFacet.RECOMMENDED_AGE),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'weight_in_kg_min',
        label: `${FEAT_LABEL.weight_in_kg} ${FROM.toLowerCase()}`,
        getNiceValue: (v) => `${FROM.toLowerCase()} ${v} kg`,
        type: FacetType.TWIN_TEXT_INPUT,
    },
    {
        name: 'weight_in_kg_max',
        label: `${FEAT_LABEL.weight_in_kg} ${TO.toLowerCase()}`,
        getNiceValue: (v) => `${TO.toLowerCase()} ${v} kg`,
        type: FacetType.TWIN_TEXT_INPUT,
    },
    {
        name: 'bucket_seat',
        label: FEAT_LABEL.bucket_seat,
        values: getValues(FEATURES_LIST.bucket_seat),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'origin_countries',
        label: FEAT_LABEL.origin_countries,
        values: getValues(FEATURES_LIST.origin_countries),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'suspension',
        label: FEAT_LABEL.suspension,
        values: getValues(FEATURES_LIST.suspension),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'extendable_hood',
        label: FEAT_LABEL.extendable_hood,
        values: getValues(FEATURES_LIST.extendable_hood),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'big_shopping_basket',
        label: FEAT_LABEL.big_shopping_basket,
        values: getValues(FEATURES_LIST.big_shopping_basket),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'one_hand_folding',
        label: FEAT_LABEL.one_hand_folding,
        values: getValues(FEATURES_LIST.one_hand_folding),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'car_seat_can_be_attached',
        label: FEAT_LABEL.car_seat_can_be_attached,
        values: getValues(FEATURES_LIST.car_seat_can_be_attached),
        type: FacetType.LIST_MULTISELECT,
    },
    {
        name: 'carrycot_can_be_attached',
        label: FEAT_LABEL.carrycot_can_be_attached,
        values: getValues(FEATURES_LIST.carrycot_can_be_attached),
        type: FacetType.LIST_MULTISELECT,
    },
];

export const getQueryString = (pageMode, search, strollerType) => {
    let q = search;
    if (pageMode === PageMode.SHOW_SEARCH_RESULTS_FILTER_BY_TYPE) {
        q = `?type=${convertStrollerType(strollerType, '-to_')}`;
    }
    return q;
};
