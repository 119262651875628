/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const garden : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["GARDEN_DECORATION_LIGHTING", "Zahradn\u00ed dekorace, osv\u011btlen\u00ed"],
        ["GARDEN_TOOLS_HOSES", "Zahradn\u00ed n\u00e1\u0159ad\u00ed, hadice"],
        ["LAWN_MOWERS_SCARIFIERS", "Seka\u010dky, vertikut\u00e1tory"],
        ["SAWS_AXES", "Pily, sekery"],
        ["TO_THE_WORKSHOP", "Do d\u00edlny"],
        ["GARDEN_HOUSES_AND_GAZEBOS", "Zahradn\u00ed domky a alt\u00e1ny"],
        ["GARDEN_OUTDOOR_FURNITURE", "Zahradn\u00ed n\u00e1bytek"],
        ["PLANTS_TREES", "Rostliny, stromy"],
        ["POOLS_PONDS", "Baz\u00e9ny, jez\u00edrka"],
        ["GARDEN_GRILLS_AND_FIREPLACES", "Zahradn\u00ed grily a ohni\u0161t\u011b"],
        ["GATES_AND_FENCES", "Br\u00e1ny a ploty"],
        ["PAVING_TREADS", "Dla\u017eba, n\u00e1\u0161lapy"],
        ["UMBRELLAS_AND_SHADING", "Slune\u010dn\u00edky a st\u00edn\u011bn\u00ed"],
        ["IRRIGATION", "Zavla\u017eov\u00e1n\u00ed"],
        ["PLAYGROUNDS", "D\u011btsk\u00e1 h\u0159i\u0161t\u011b"],
        ["OTHER", "Jin\u00e9"],
    ],
};
