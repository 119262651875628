import {
    counsellingsHomeUrl,
    counsellingsNewUrl,
    counsellingsPostDetailUrl,
    counsellingsReplyUrl,
    counsellingsTopicUrl,
} from 'mk2/apps/counsellings/urls';
import { DynamicRoute } from 'mk2/containers/Application/Application';

export const routes: DynamicRoute[] = [{
    app: 'counsellings',
    exact: true,
    path: counsellingsHomeUrl,
    getPage: () => {
        return import('mk2/apps/counsellings/containers/CounsellingsHome/CounsellingsHomePage' /* webpackChunkName: "counsellings.CounsellingsHome" */);
    },
    modules: [ 'mk2/apps/counsellings/containers/CounsellingsHome/CounsellingsHomePage' ],
}, {
    app: 'counsellings',
    exact: true,
    path: counsellingsNewUrl,
    getPage: () => {
        return import('mk2/apps/counsellings/containers/CounsellingQuestionCreate/CounsellingQuestionCreatePage' /* webpackChunkName: "counsellings.CounsellingQuestionCreate" */);
    },
    modules: [ 'mk2/apps/forum/containers/CounsellingQuestionCreate/CounsellingQuestionCreatePage' ],
}, {
    app: 'counsellings',
    exact: true,
    path: counsellingsReplyUrl,
    getPage: () => {
        return import('mk2/apps/counsellings/containers/CounsellingReply/CounsellingReplyPage' /* webpackChunkName: "forum.CounsellingReply" */);
    },
    modules: [ 'mk2/apps/forum/containers/CounsellingReply/CounsellingReplyPage' ],
}, {
    app: 'counsellings',
    exact: true,
    path: counsellingsTopicUrl,
    getPage: () => {
        return import('mk2/apps/counsellings/containers/CounsellingsTopic/CounsellingsTopicPage' /* webpackChunkName: "counsellings.CounsellingsTopic" */);
    },
    modules: [ 'mk2/apps/counsellings/containers/CounsellingsTopic/CounsellingsTopicPage' ],
}, {
    app: 'counsellings',
    exact: true,
    path: counsellingsPostDetailUrl,
    getPage: () => {
        return import('mk2/apps/counsellings/containers/CounsellingsPostDetail/CounsellingsPostDetailPage' /* webpackChunkName: "counsellings.CounsellingsPostDetail" */);
    },
    modules: [ 'mk2/apps/counsellings/containers/CounsellingsPostDetail/CounsellingsPostDetailPage' ],
}];
