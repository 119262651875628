import { homepageUrl } from 'mk2/apps/home/urls';
import {
    wikiAddExperienceToUndefinedArticleUrl,
    wikiAddExperienceUrl,
    wikiArticleCreateUrl,
    wikiArticleEditUrl,
    wikiArticleExperiencePhotoUrl,
    wikiArticlePhotoUrl,
    wikiArticleSearch,
    wikiArticleUrl,
    wikiReviewArticleUrl,
    wikiReviewHomepageArticleUrl,
    wikiUrl,
} from 'mk2/apps/wiki/urls';
import { Route } from 'mk2/containers/Application/Application';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [{
    app: 'wiki',
    exact: true,
    path: wikiArticleCreateUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/ArticleCreate/ArticleCreatePage' /* webpackChunkName: "wiki.ArticleCreate" */);
    },
    modules: [ 'mk2/apps/wiki/containers/ArticleCreate/ArticleCreatePage' ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiUrl,
    page: new RedirectPage({to: homepageUrl, permanent: true}),
}, {
    app: 'wiki',
    exact: true,
    path: wikiArticleEditUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/ArticleEdit/ArticleEditPage' /* webpackChunkName: "wiki.ArticleEdit" */);
    },
    modules: [ 'mk2/apps/wiki/containers/ArticleEdit/ArticleEditPage' ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiReviewHomepageArticleUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/Article/ReviewHomepageArticlePage' /* webpackChunkName: "wiki.ReviewHomepageArticlePage" */);
    },
    modules: [ 'mk2/apps/wiki/containers/Article/ReviewArticlePage' ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiReviewArticleUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/Article/ReviewArticlePage' /* webpackChunkName: "wiki.ReviewArticlePage" */);
    },
    modules: [ 'mk2/apps/wiki/containers/Article/ReviewArticlePage' ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiArticleUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/Article/ArticlePage' /* webpackChunkName: "wiki.Article" */);
    },
    modules: [ 'mk2/apps/wiki/containers/Article/ArticlePage' ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiArticleSearch,
    getPage: () => {
        return import('mk2/apps/wiki/containers/ArticleSearch/ArticleSearchPage' /* webpackChunkName: "wiki.ArticleSearch" */);
    },
    modules: [ 'mk2/apps/wiki/containers/ArticleSearch/ArticleSearchPage' ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiAddExperienceUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/ArticleAddExperience/ArticleAddExperiencePage' /* webpackChunkName: "wiki.ArticleAddExperience" */);
    },
    modules: [
        'mk2/apps/wiki/containers/ArticleAddExperience/ArticleAddExperiencePage',
        'mk2/apps/wiki/containers/Article/ArticlePage',
    ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiAddExperienceToUndefinedArticleUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/ArticleAddExperience/ArticleAddExperiencePage' /* webpackChunkName: "wiki.ArticleAddExperience" */);
    },
    modules: [
        'mk2/apps/wiki/containers/ArticleAddExperience/ArticleAddExperiencePage',
        'mk2/apps/wiki/containers/Article/ArticlePage',
    ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiArticleExperiencePhotoUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/ArticleExperiencePhoto/ArticleExperiencePhotoPage' /* webpackChunkName: "wiki.ArticleExperiencePhoto" */);
    },
    modules: [
        'mk2/apps/wiki/containers/ArticleExperiencePhoto/ArticleExperiencePhotoPage',
        'mk2/apps/wiki/containers/Article/ArticlePage',
    ],
}, {
    app: 'wiki',
    exact: true,
    path: wikiArticlePhotoUrl,
    getPage: () => {
        return import('mk2/apps/wiki/containers/ArticlePhoto/ArticlePhotoPage' /* webpackChunkName: "wiki.ArticlePhoto" */);
    },
    modules: [
        'mk2/apps/wiki/containers/ArticlePhoto/ArticlePhotoPage',
        'mk2/apps/wiki/containers/Article/ArticlePage',
    ],
}];
