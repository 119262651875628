import { ToolbarToggleAction, TOOLBAR_TOGGLE } from 'mk2/containers/Toolbar/Toolbar.actions';
import { ToolbarAction } from 'mk2/containers/Toolbar/Toolbar.enums';
import { tupdate } from 'mk2/helpers/types';
import { Reducer } from 'redux';

export interface ToolbarState {
    opened: ToolbarAction;
}

export const initialToolbarState: ToolbarState = {
    opened: ToolbarAction.NONE,
};

export interface ToolbarsState {
    [formName: string]: ToolbarState;
}

const initialToolbarsState: ToolbarsState = {};

declare type ToolbarReducerAction =
    ToolbarToggleAction;

export const toolbarsReducer: Reducer<ToolbarsState> = (state = initialToolbarsState, action: ToolbarReducerAction) => {
    switch (action.type) {
        case TOOLBAR_TOGGLE:
            const formName = action.formName;
            const toolbarState = state[formName] || initialToolbarState;

            return tupdate(state, {
                [formName]: tupdate(toolbarState, {
                    // Close if opened
                    opened: toolbarState.opened === action.opened ? ToolbarAction.NONE : action.opened,
                }),
            });
        default:
            return state;
    }
};
