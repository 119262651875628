import { APIAction } from 'mk2/helpers/action';

export enum LoadingState {
    INIT,
    LOADING,
    SUCCESS,
    SUCCESS_ON_SERVER,
    FAILURE,
    FAILURE_ON_SERVER,
}

export const loadingStateFiltered = (actionTypePrefix: string) => (state = LoadingState.INIT, action: APIAction): LoadingState => {
    if (!action.type.startsWith(actionTypePrefix)) {
        // Loading state of something else changed
        return state;
    }

    return loadingState(state, action);
};

export const loadingState = (state = LoadingState.INIT, action: APIAction): LoadingState => {
    if (action.type.endsWith('REQUEST')) {
        return LoadingState.LOADING;
    } else if (action.type.endsWith('SUCCESS')) {
        return process.env.SERVER ? LoadingState.SUCCESS_ON_SERVER : LoadingState.SUCCESS;
    } else if (action.type.endsWith('FAILURE')) {
        return process.env.SERVER ? LoadingState.FAILURE_ON_SERVER : LoadingState.FAILURE;
    } else {
        return state;
    }
};

export const isLoading = (state: LoadingState) => state === LoadingState.INIT || state === LoadingState.LOADING;
export const isSuccess = (state: LoadingState) => state === LoadingState.SUCCESS || state === LoadingState.SUCCESS_ON_SERVER;
export const isFailure = (state: LoadingState) => state === LoadingState.FAILURE || state === LoadingState.FAILURE_ON_SERVER;
