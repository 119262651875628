import { Action } from 'redux';

export const INSTALL_PROMPT_SET_READY = 'INSTALL_PROMPT_SET_READY';

export interface InstallPromptSetReadyAction extends Action {
    readonly type: typeof INSTALL_PROMPT_SET_READY;
    readonly ready: boolean;
}

export const installPromptSetReady = (ready: boolean): InstallPromptSetReadyAction => ({
    type: INSTALL_PROMPT_SET_READY,
    ready,
});

export const INSTALL_PROMPT_INSTALL = 'INSTALL_PROMPT_INSTALL';

export interface InstallPromptInstallAction extends Action {
    readonly type: typeof INSTALL_PROMPT_INSTALL;
}

export const installPromptInstall = (): InstallPromptInstallAction => ({ type: INSTALL_PROMPT_INSTALL });
