/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const tutoring : {[key: string]: Array<[string, string]>} = {
    p_school_subject: [
        ["CZECH_LANGUAGE", "\u010cesk\u00fd jazyk"],
        ["SLOVAK_LANGUAGE", "Slovensk\u00fd jazyk"],
        ["ENGLISH_LANGUAGE", "Anglick\u00fd jazyk"],
        ["GERMAN_LANGUAGE", "N\u011bmeck\u00fd jazyk"],
        ["ITALIAN_LANGUAGE", "Italsk\u00fd jazyk"],
        ["RUSSIAN_LANGUAGE", "Rusk\u00fd jazyk"],
        ["FRENCH_LANGUAGE", "Francouzsk\u00fd jazyk"],
        ["SPANISH_LANGUAGE", "\u0160pan\u011blsk\u00fd jazyk"],
        ["SUBJECT_OTHER_LANGUAGE", "Jin\u00fd jazyk"],
        ["MATHEMATICS", "Matematika"],
        ["CHEMISTRY", "Chemie"],
        ["PHYSICS", "Fyzika"],
        ["BIOLOGY", "Biologie"],
        ["GEOGRAPHY", "Zem\u011bpis"],
        ["HISTORY", "D\u011bjepis"],
    ],
    p_level: [
        ["ELEMENTARY_SCHOOL", "1. stupe\u0148 Z\u0160"],
        ["MIDDLE_SCHOOL", "2. stupe\u0148 Z\u0160"],
        ["HIGH_SCHOOL", "S\u0160"],
        ["COLLEGE", "V\u0160"],
    ],
    p_frequency: [
        ["REGULARLY", "Pravideln\u011b"],
        ["IRREGULARLY", "Nepravideln\u011b"],
    ],
    p_place: [
        ["PUPIL_RESIDENCE", "V bydli\u0161ti \u017e\u00e1ka"],
        ["TEACHER_RESIDENCE", "V bydli\u0161ti u\u010ditele"],
    ],
    p_practice_length: commonProp22,
};
