import {
    AB_FETCH_API_FAILURE,
    AB_FETCH_API_REQUEST,
    AB_FETCH_API_SUCCESS,
    ABFetchApiFailureAction,
    ABFetchApiRequestAction,
    ABFetchApiSuccessAction,
} from 'mk2/containers/AB/AB.actions';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';

export interface ABState {
    loadingState: LoadingState;
    variant: string;
}

export interface ABsState {
    [ab: string]: ABState;
}

type ABFetchActions =
    ABFetchApiRequestAction
    | ABFetchApiSuccessAction
    | ABFetchApiFailureAction;

export const abReducer = (state: ABsState = {}, action: ABFetchActions) => {
    let actions;
    switch (action.type) {
        case AB_FETCH_API_REQUEST:
        case AB_FETCH_API_FAILURE:
            actions = action.names.split(',');
            return {
                ...state,
                ...actions.reduce((acc, cur) => {
                    acc[cur] = {
                        loadingState: loadingState(state[cur] && state[cur].loadingState, action),
                        variant: null,
                    };
                    return acc;
                }, {}),
            };
        case AB_FETCH_API_SUCCESS:
            actions = action.names.split(',');
            return {
                ...state,
                ...actions.reduce((acc, cur) => {
                    acc[cur] = {
                        loadingState: loadingState(state[cur] && state[cur].loadingState, action),
                        variant: action.response[cur],
                    };
                    return acc;
                }, {}),
            };
        default:
            return state;
    }
};
