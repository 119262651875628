import { ComplaintsStatisticsPerPost, CounsellingEntity } from 'mk2/apps/forum/schemas';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { Action } from 'redux';

export const FORUM_READ_FEED_TRIGGER = 'FORUM_READ_FEED_TRIGGER';
export interface ForumReadFeedTriggerAction {
    readonly type: typeof FORUM_READ_FEED_TRIGGER;
    readonly feed: string;
}
export const FORUM_READ_FEED_REQUEST = 'FORUM_READ_FEED_REQUEST';
export interface ForumReadFeedSaveRequestAction extends APIRequestAction {
    readonly type: typeof FORUM_READ_FEED_REQUEST;
    readonly feed: string;
}
export const FORUM_READ_FEED_SUCCESS = 'FORUM_READ_FEED_SUCCESS';
export interface ForumReadFeedSaveSuccessAction extends APISuccessAction<NormalizedResponse> {
    readonly type: typeof FORUM_READ_FEED_SUCCESS;
    readonly feed: string;
}
export const FORUM_READ_FEED_FAILURE = 'FORUM_READ_FEED_FAILURE';
export interface ForumReadFeedSaveFailureAction extends APIFailureAction {
    readonly type: typeof FORUM_READ_FEED_FAILURE;
    readonly feed: string;
}

export const forumReadFeedTrigger = (feed: string): ForumReadFeedTriggerAction => ({
    type: FORUM_READ_FEED_TRIGGER, feed,
});
export const forumReadFeedSave = {
    request: (feed: string): ForumReadFeedSaveRequestAction => ({
        type: FORUM_READ_FEED_REQUEST, feed,
    }),
    success: (feed: string): ForumReadFeedSaveSuccessAction => ({
        type: FORUM_READ_FEED_SUCCESS, feed, response: null,
    }),
    failure: (feed: string, error: any): ForumReadFeedSaveFailureAction => ({
        type: FORUM_READ_FEED_FAILURE, error, feed,
    }),
};

export const FORUM_POST_SEND_HEART = 'FORUM_POST_SEND_HEART';

export interface PostSendHeartAction extends Action {
    readonly type: typeof FORUM_POST_SEND_HEART;
}

export const postSendHeart = (): PostSendHeartAction => ({
    type: FORUM_POST_SEND_HEART,
});

export const FORUM_UPDATE_COMPLAINTS_STATISTICS = 'FORUM_UPDATE_COMPLAINTS_STATISTICS';

export interface ForumUpdateComplaintsStatisticsAction extends Action {
    readonly type: typeof FORUM_UPDATE_COMPLAINTS_STATISTICS;
    readonly complaintsStatistics: ComplaintsStatisticsPerPost;
}

export const forumUpdateComplaintsStatistics = (complaintsStatistics: ComplaintsStatisticsPerPost): ForumUpdateComplaintsStatisticsAction => ({
    type: FORUM_UPDATE_COMPLAINTS_STATISTICS,
    complaintsStatistics,
});

export interface LastVisitCounts {
    readonly newest: number;
    readonly popular: number;
    readonly updated: number;
    readonly subscribed: number;
}

export const FORUM_HEADER_UPDATE = 'FORUM_HEADER_UPDATE';

export interface ForumHeaderUpdateAction extends Action {
    readonly type: typeof FORUM_HEADER_UPDATE;
    readonly lastVisitCounts: LastVisitCounts;
}

export const forumHeaderUpdate = (lastVisitCounts: LastVisitCounts): ForumHeaderUpdateAction => ({ type: FORUM_HEADER_UPDATE, lastVisitCounts });

// Action for locked counsellings
export const FORUM_ADD_COUNSELLING_QUESTION = 'FORUM_ADD_COUNSELLING_QUESTION';

export interface AddCounsellingQuestionAction extends Action {
    readonly type: typeof FORUM_ADD_COUNSELLING_QUESTION;
    readonly counselling: CounsellingEntity;
}

export const addCounsellingQuestion = (counselling: CounsellingEntity): AddCounsellingQuestionAction => ({
    type: FORUM_ADD_COUNSELLING_QUESTION, counselling,
});
