// we intentionally try to be very "old style js"
// here, so that not much transpiling happens,
// for 2 reasons:
// A. IE10 + classes + super does not work
// B. to be able to keep the stack-traces we need
//   to strictly know how these objects will get created
//   which is harder to do with ES6-classes-polyfilled

function fixStack(obj) {
    // for now only V8 has this method
    if (Error.captureStackTrace) {
        Error.captureStackTrace(obj, obj.constructor);
    }
}

// based on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types
function makeError(constr) {
    constr.prototype = Object.create(Error.prototype);
    constr.prototype.constructor = constr;
}

export function HttpNotFoundError(message, rendered?: boolean) {
    fixStack(this);
    this.name = 'HttpNotFoundError';
    this.message = message;
    // Set this true, to tell rendered, not to render default 404 page
    this.rendered = rendered;
    this.statusCode = 404;
}
makeError(HttpNotFoundError);
