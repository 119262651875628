import { ID_RE, SLUG_LEGACY_RE, USERNAME } from 'mk2/helpers/urls';
import { BlogPostEntity, BlogPostType } from 'mk2/schemas';

const POST_ID = `:postId(${ID_RE})`;
const COMMENT_ID = `:commentId(${ID_RE})`;
const IMAGE_ID = `:imageId(${ID_RE})`;
const ENTITY_ID = `:entityId(${ID_RE})`;
const ARTICLE_SLUG = `:articleSlug(${SLUG_LEGACY_RE})`;
const POST_SLUG = `:postSlug(${SLUG_LEGACY_RE})`;

export const blogsUrl = `/blogs/`;
export const blogsFeedWithCategoryUrl = `/blogs/:category(all|my-activity|pr-articles|flagged|flagged-groups)/`;
export const blogsFriendsFeedUrl = `/blogs/friends/`;
export const blogsPopularFeedUrl = `/blogs/popular/`;

export const blogsProfileHomeUrl = '/blog/';
export const blogsProfileUrl = `/blog/${USERNAME}/`;
export const blogsProfileMoreUrl = `/blog/${USERNAME}/more/`;
export const blogsProfileProductsUrl = `/blog/${USERNAME}/products/`;
export const blogsProfileEditProUrl = `/blog/${USERNAME}/pro/edit/`;

export const blogsProfileRedirectUrl = `/blog/`;
export const blogsCommentEditUrl = `/blog/comment/${COMMENT_ID}/edit/`;
export const blogsPostUrl = `/blog/${USERNAME}/post/${POST_ID}/`;
export const blogsShortMessageUrl = `/blog/${USERNAME}/message/${POST_SLUG}/`;
export const blogsAutoMessageUrl = `/blog/${USERNAME}/automsg/${POST_SLUG}/`;
export const blogsShareMessageUrl = `/blog/${USERNAME}/share/${POST_SLUG}/`;
export const blogsShortMessageCreateUrl = `/blog/message/${USERNAME}/create/`;
export const blogsShortMessageEditUrl = `/blog/message/${POST_ID}/edit/`;
export const blogsShortMessagePhotoUrl = `/blog/${USERNAME}/message/${POST_SLUG}/${IMAGE_ID}/`;
export const blogsAutoMessagePhotoUrl = `/blog/${USERNAME}/automsg/${POST_SLUG}/${IMAGE_ID}/`;
export const blogsEntityLikersUrl = `/blog/likers/:type(short-message|album|article|comment)/${ENTITY_ID}/`;
export const blogsPostShareUrl = `/blog/post/${POST_ID}/share/`;

export const blogsArticleCreateUrl = `/blog/${USERNAME}/article/create/`;
export const blogsArticleEditUrl = `/blog/${USERNAME}/article/${ARTICLE_SLUG}/edit/`;
export const blogsArticleUrl = `/blog/${USERNAME}/article/${ARTICLE_SLUG}/`;
export const blogsArticleDraftsUrl = `/blog/${USERNAME}/article/drafts/`;

export const blogsAlbumDetailUrl = `/blog/${USERNAME}/album/${POST_SLUG}/`;
export const blogsAlbumPhotoUrl = `/blog/${USERNAME}/album/${POST_SLUG}/${IMAGE_ID}/`;
export const blogsAlbumReorderUrl = `/blog/${USERNAME}/album/${POST_ID}/reorder/`;
export const blogsAlbumCreateUrl = `/blog/${USERNAME}/album/create/`;
export const blogsAlbumEditUrl = `/blog/${USERNAME}/album/${POST_ID}/edit/`;
export const blogsAlbumEditPhotosUrl = `/blog/${USERNAME}/album/${POST_ID}/add/`;

export const blogsPostDetailUrl = (postType: BlogPostType, imageId?: number) => {
    /*  */ if (postType === BlogPostType.ALBUM && imageId) {
        return blogsAlbumPhotoUrl;
    } else if (postType === BlogPostType.ALBUM && !imageId) {
        return blogsAlbumDetailUrl;
    } else if (postType === BlogPostType.ARTICLE) {
        return blogsArticleUrl.replace(ARTICLE_SLUG, POST_SLUG);
    } else if (postType === BlogPostType.AUTOMATIC_MESSAGE && imageId) {
        return blogsAutoMessagePhotoUrl;
    } else if (postType === BlogPostType.AUTOMATIC_MESSAGE && !imageId) {
        return blogsAutoMessageUrl;
    } else if (postType === BlogPostType.SHARE) {
        return blogsShareMessageUrl;
    } else if (imageId) {
        return blogsShortMessagePhotoUrl;
    } else {
        return blogsShortMessageUrl;
    }
};

export const blogsPostEditUrl = (post: BlogPostEntity) => {
    if (post.type === BlogPostType.ALBUM) {
        return blogsAlbumEditUrl;
    } else if (post.type === BlogPostType.ARTICLE) {
        return blogsArticleEditUrl.replace(ARTICLE_SLUG, POST_SLUG);
    } else if (post.type === BlogPostType.SHORT_MESSAGE) {
        return blogsShortMessageEditUrl;
    } else {
        return null; // other types are actually not allowed to be edited
    }
};
