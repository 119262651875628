import * as Sentry from '@sentry/minimal';
import { Event, EventHint } from '@sentry/types';
import { CURRENT_REVISION } from 'mk/autogenerated/gitData';
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import assetUrl from 'mk/common/assetUrl';
import filesUrl from 'mk/common/filesUrl';
import { ACTIVE_DOMAIN_NAME } from 'mk2/helpers/urls';
import { connectSentryToLogger } from 'mk2/logger';
import { UserEntity } from 'mk2/schemas';

// Recommended setup for Sentry - mk-ssr
//
// // Import with `import * as Sentry from "@sentry/node"` if you are using ESM
// const Sentry = require("@sentry/node");
//
// Sentry.init({
//   dsn: "https://43766ae21bc8990c70fb750601bfd935@sentry.modrykonik.com/3",
//
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
// });

// Recommended setup for Sentry - mk-react
//
// import * as Sentry from "@sentry/react";
//
// Sentry.init({
//   dsn: "https://a68acd73eee0b5c4db0b110997faaa0b@sentry.modrykonik.com/4",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const DSN_SSR = 'https://43766ae21bc8990c70fb750601bfd935@sentry.modrykonik.com/3';
const DSN_REACT = 'https://a68acd73eee0b5c4db0b110997faaa0b@sentry.modrykonik.com/4';
const DSN_LEGACY_JS = 'https://58946c4e1ecfbe5c3d57308c5652950e@sentry.modrykonik.com/5';

export function initSentry(sentryIml, forLegacyCode?: boolean) {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    sentryIml.init({
        dsn: process.env.SERVER
            ? DSN_SSR
            : forLegacyCode ? DSN_LEGACY_JS : DSN_REACT,
        release: CURRENT_REVISION,
        environment: 'production',
        whitelistUrls: process.env.SERVER
            ? undefined
            : [
                /modrykonik\.sk/,
                /modrykonik\.cz/,
                /^webpack:\//,
                '<anonymous>',
                assetUrl,
                filesUrl,
            ],
        beforeSend: (event: Event, hint?: EventHint) => (
            shouldReportError(event, hint)
                ? event
                : null
        ),
        // autoSessionTracking: false,
    });

    sentryIml.configureScope((scope) => {
       scope.setTag('site', ACTIVE_DOMAIN_NAME);
       if (!process.env.SERVER) {
           const ssrRequestIdMeta = document.querySelector<HTMLMetaElement>('meta[name="mk:ssr-request-id"]');
           scope.setTag('ssrRequestId', ssrRequestIdMeta ? ssrRequestIdMeta.content : 'unknown');
       }
    });

    // configure our loggers to log via sentry
    connectSentryToLogger();
}

export function sentrySetRequestUser(requestUser: UserEntity) {
    if (!isAuthenticated(requestUser)) {
        return;
    }

    Sentry.configureScope((scope) => {
        scope.setUser({
            id: requestUser.id.toString(),
            username: requestUser.username,
        });
    });
}

function shouldReportError(event: Event, hint?: EventHint): boolean {
    // report all errors on server
    if (!!process.env.SERVER) {
        return true;
    }

    // There was an error during loading some of our script element
    // Do not report errors to sentry, because there is nothing we can do with that
    if (typeof window !== 'undefined' && !!(window as any).scriptTagError) {
        return false;
    }

     // Drop all events if query string includes `fbclid` string
    if (location.search.indexOf('fbclid') !== -1) return null;

    const userAgent = event && event.request && event.request.headers
        ? event.request.headers['User-Agent']
        : undefined;
    if (!userAgent) {
        return true;
    }

    // for IE, we simply dont support things lower than 10.
    // for Chrome and FF we chose the popular ones. we theoretically support the lower ones too,
    // but for now we dont record their errors
    const reIE = /MSIE (\d+)\./;
    const reAndroid  = /Android 2./;
    const reOldOpera = /^Opera\/.+ Presto\//;
    const reChrome = / Chrome\/(\d+)/;
    const reFF = /^Mozilla.+ Firefox\/(\d+)/;
    const reSeznam = /Seznam screenshot\-generator/;

    const MIN_IE = 11;
    const MIN_FF = 40;
    const MIN_CHROME = 40;

    const result = reIE.exec(userAgent);
    if (result) {
        const ieVersion = parseInt(result[1], 10);
        return ieVersion >= MIN_IE;
    }

    if (reAndroid.test(userAgent)) {
        return false;
    }

    if (reOldOpera.test(userAgent)) {
        return false;
    }

    // Seznam screenshot-generator is generating too much invalid errors.
    // Without their code there is nothing to do.
    if (reSeznam.test(userAgent)) {
        return false;
    }

    const chromeAgent = reChrome.exec(userAgent);
    if (chromeAgent !== null) {
        return parseFloat(chromeAgent[1]) >= MIN_CHROME;
    }

    const ffAgent = reFF.exec(userAgent);
    if (ffAgent !== null) {
        return parseFloat(ffAgent[1]) >= MIN_FF;
    }

    // Ignore gemius errors
    const reGemiusError = /Blocked a frame with origin "https:\/\/www\.modrykonik\.(sk|cz)" from accessing a frame with origin "https?:\/\/(ls\.hit\.gemius\.pl|vars\.hotjar\.com|tpc\.googlesyndication\.com|www\.youtube(-nocookie)?\.com)"\. Protocols, domains, and ports must match\./;
    const error = hint.originalException;
    if (error && (error as Error).message && (error as Error).message.match(reGemiusError)) {
        return false;
    }

    return true;
}
