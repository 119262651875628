import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { LocationDescriptor } from 'history';
import assetUrl from 'mk/common/assetUrl';
import { CountBadge } from 'mk2/components/CountBadge';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import Sticker, { StickerSize } from 'mk2/components/Sticker';
import Loadable from 'mk2/helpers/Loadable';
import React from 'react';
import { CSSTransition as RTGCSSTransition } from 'react-transition-group';
import styles from './Btn.mscss';

const CSSTransition = Loadable<RTGCSSTransition<HTMLSpanElement>['props'], any>({
    loader: () => import('react-transition-group' /* webpackChunkName: "react-transition-group" */).then((mod) => mod.CSSTransition),
    modules: ['react-transition-group'],
    webpack: () => [ require.resolveWeak('react-transition-group') ],
});

export enum BtnType {
    BlueTextLight,
    AdminTextLight,
    ModeratorTextLight,
    Blue,
    Admin,
    Moderator,
    BlueOutline,
    BlueText,
    AdminOutline,
    ModeratorOutline,
    GreyOutline,
    GreyText,
    Red,
    BzGreen,
    LightGreyOutline,
    Link,
    Menu,
    MenuAdmin,
    Tab,
    SubTab,
    BlueWithoutHeight,
    Confirm,
    Decline,
    Black,
    BlackText,
    White,
    BlackTextWithWhiteBackground,
    RedText,
    BlueBig,
    RedOutline,
    BlueOutlineWBackground,
}

export enum BtnLayout {
    IconLabelCount,
    Icon,
    PageHeaderRightIcon,
    LabelIcon,
    CountIconLabel,
    IconLabel,
}

export enum BtnCountType {
    Badge,
    Label,
    Circle,
}

export enum BtnSidePadding {
    None,
    Left,
    Right,
    LeftRight,
}

export interface OwnProps {
    className?: string;
    classNames?: {  // classNames for inner components
        icon?: string;
        label?: string;
    };
    type?: BtnType;
    layout?: BtnLayout;
    label?: React.ReactNode;
    icon?: string | IconLookup | FontAwesomeIconProps;
    isAction?: boolean;
    img?: React.ReactNode;
    count?: number;
    countType?: BtnCountType;
    link?: LocationDescriptor;
    linkTarget?: null | '_blank';
    linkRel?: 'prev' | 'next' | 'nofollow' | 'noopener';
    disabled?: boolean;
    canBeVisuallyDisabled?: boolean;
    active?: boolean;
    hasSpinner?: boolean;
    asAnchor?: boolean;
    bullet?: boolean;
    bulletClassName?: string;
    sidePadding?: BtnSidePadding;
    submitFormBtn?: boolean;
    'data-cy'?: string;

    onClick?(event);
    onMouseDown?(event);
    onMouseUp?(event);
}

type Props = OwnProps;

interface State {
    lastCount?: number;
}

export class Btn extends React.PureComponent<Props, State> {

    public static defaultProps = {
        asAnchor: false,
        type: BtnType.Blue,
        sidePadding: BtnSidePadding.LeftRight,
        canBeVisuallyDisabled: true,
        count: 0,
        countType: BtnCountType.Badge,
        layout: BtnLayout.IconLabelCount,
        bullet: false,
        bulletClassName: styles['Btn__bullet--red'],
        hasSpinner: false,
    };

    public static getDerivedStateFromProps(props: Props, state: State) {
        if (props.count) {
            return {
                // Remember last non-zero count value
                lastCount: props.count,
            };
        }

        return null;
    }

    public state = {
        lastCount: this.props.count,
    };

    public render() {
        const {
            className, type, label, icon, count, countType, link, linkTarget, linkRel, asAnchor, bullet,
            disabled, canBeVisuallyDisabled, active, hasSpinner, onClick, onMouseDown, onMouseUp, sidePadding,
            img, layout, submitFormBtn, 'data-cy': dataCy,
        } = this.props;

        if (asAnchor && typeof link !== 'string') {
            throw new Error('Btn "asAnchor" can be used only with string "link".');
        }

        if (layout === BtnLayout.Icon && (label || bullet || (count && countType !== BtnCountType.Circle))) {
            throw new Error('Btn layout Icon does not use "label" or "bullet" and "count" is shown as circle only');
        } else if (layout === BtnLayout.LabelIcon && (count || bullet)) {
            throw new Error('Btn layout LabelIcon does not use "count" or "bullet"');
        } else if (layout === BtnLayout.IconLabel && (count || bullet)) {
            throw new Error('Btn layout IconLabel does not use "count" or "bullet"');
        } else if (layout === BtnLayout.CountIconLabel && countType === BtnCountType.Circle) {
            throw new Error('Btn layout CountIconLabel does not have "count" as circle');
        }

        if (icon && img) {
            throw new Error('Btn either provide "icon" or "img".');
        }

        if (linkTarget && !asAnchor) {
            throw new Error('Btn "linkTarget" can be used only when "asAnchor" is true.');
        }

        const noAction = disabled || hasSpinner;

        const spinnerElem = this.renderSpinner();
        const iconElem = this.renderIcon();
        const labelElem = this.renderLabel();
        const countElem = this.renderCount();
        const bulletElem = this.renderBullet();

        const content = (
            <React.Fragment>
                {spinnerElem}

                {layout === BtnLayout.IconLabelCount && (
                    <React.Fragment>
                        {iconElem}
                        {labelElem}
                        {countElem}
                        {bulletElem}
                    </React.Fragment>
                )}
                {layout === BtnLayout.Icon && (
                    <React.Fragment>
                        {iconElem}
                        {countElem}
                    </React.Fragment>
                )}
                {layout === BtnLayout.LabelIcon && (
                    <React.Fragment>
                        {labelElem}
                        {iconElem}
                        {bulletElem}
                    </React.Fragment>
                )}
                {layout === BtnLayout.CountIconLabel && (
                    <React.Fragment>
                        {countElem}
                        {iconElem}
                        {labelElem}
                        {bulletElem}
                    </React.Fragment>
                )}
                {layout === BtnLayout.PageHeaderRightIcon && (
                    <div className={styles[`Btn--layout-${BtnLayout[layout]}-base`]}>
                        <div className={styles[`Btn--layout-${BtnLayout[layout]}-expanded`]}>
                            {iconElem}
                        </div>
                    </div>
                )}
                {layout === BtnLayout.IconLabel && (
                    <React.Fragment>
                        {iconElem}
                        {labelElem}
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        const classes = cx(
            styles.Btn,
            styles[`Btn--layout-${BtnLayout[layout]}`],
            styles[`Btn--layout-${BtnLayout[layout]}-type-${BtnType[type]}`],
            styles[`Btn--sidePadding-${BtnSidePadding[sidePadding]}`],
            canBeVisuallyDisabled && disabled && styles[`Btn--disabled`],
            canBeVisuallyDisabled && disabled && styles[`Btn--disabled-layout-${BtnLayout[layout]}-type-${BtnType[type]}`],
            active && styles[`Btn--active--type-${BtnType[type]}`],
            bullet && styles['Btn--hasBullet'],
            hasSpinner && styles['Btn--hasSpinner'],
            count && count > 0 && countType === BtnCountType.Circle && styles['Btn--hasCircleCount'],
            className,
        );

        if (submitFormBtn) {
            return (
                <button
                    className={classes}
                    onClick={noAction ? undefined : onClick}
                    onMouseDown={noAction ? undefined : onMouseDown}
                    onMouseUp={noAction ? undefined : onMouseUp}
                    disabled={noAction}
                    data-cy={dataCy}
                >
                    {content}
                </button>
            );
        } else if (noAction || !link) {
            return (
                <a
                    className={classes}
                    onClick={noAction ? undefined : onClick}
                    onMouseDown={noAction ? undefined : onMouseDown}
                    onMouseUp={noAction ? undefined : onMouseUp}
                    data-cy={dataCy}
                >
                    {content}
                </a>
            );
        } else if (asAnchor && typeof link === 'string') {
            return (
                <a
                    href={noAction ? undefined : link}
                    target={noAction ? undefined : linkTarget}
                    className={classes}
                    onClick={noAction ? undefined : onClick}
                    onMouseDown={noAction ? undefined : onMouseDown}
                    onMouseUp={noAction ? undefined : onMouseUp}
                    rel={noAction ? undefined : linkRel}
                    data-cy={dataCy}
                >
                    {content}
                </a>
            );
        } else {
            return (
                <Link
                    to={noAction ? undefined : link}
                    className={classes}
                    onClick={noAction ? undefined : onClick}
                    onMouseDown={noAction ? undefined : onMouseDown}
                    onMouseUp={noAction ? undefined : onMouseUp}
                    rel={noAction ? undefined : linkRel}
                    data-cy={dataCy}
                >
                    {content}
                </Link>
            );
        }
    }

    private renderFontAwesomeIcon(icon, sharedClasses, classNames) {
        if ((icon as IconLookup).prefix) {
            return (
                <FontAwesomeIcon icon={icon as IconLookup} className={cx(...sharedClasses, styles['Btn__icon-fa'], classNames && classNames.icon)} />
            );
        } else {
            const iconProps = icon as FontAwesomeIconProps;
            return (
                <FontAwesomeIcon {...iconProps} className={cx(...sharedClasses, styles['Btn__icon-fa'], classNames && classNames.icon)} />
            );
        }
    }

    private renderIcon() {
        const { icon, img, isAction, layout, classNames, label, count, hasSpinner, type } = this.props;

        const spaceBefore = layout === BtnLayout.LabelIcon && label;
        const spaceBefore2x = layout === BtnLayout.CountIconLabel && count;
        const sharedClasses = [
            styles.Btn__icon,
            styles[`Btn__icon--layout-${BtnLayout[layout]}`],
            styles[`Btn__icon--type-${BtnType[type]}`],
            spaceBefore && styles['Btn__icon--spaced'],
            spaceBefore2x && styles['Btn__icon--spaced2x'],
            hasSpinner && styles['Btn__icon--hidden'],
        ];

        if (!icon) {
            return (
                img ? (
                    <div className={cx(...sharedClasses, styles['Btn__icon-image'])}>
                        {img}
                    </div>
                ) : null
            );
        }

        if (typeof icon === 'object') {
            return isAction
                ? (
                    <div className={cx(...sharedClasses, styles['Btn__icon-action'])}>
                        {this.renderFontAwesomeIcon(icon, [], classNames)}
                    </div>
                ) : this.renderFontAwesomeIcon(icon, sharedClasses, classNames);
        }

        const iconString = icon as string;
        return (
            iconString.startsWith('sticker-') ? (
                <Sticker
                    className={cx(
                        ...sharedClasses,
                        styles['Btn__icon-sticker'],
                    )}
                    id={parseInt(iconString.substring(8), 10)}
                    size={StickerSize.Icon}
                />
            ) : iconString.startsWith('mkicon-') ? (
                <Img
                    className={cx(...sharedClasses, styles['Btn__icon-mkicon'])}
                    src={assetUrl + 'img/icons/' + icon}
                    width={20}
                    height={20}
                />
            ) : null
        );
    }

    private renderLabel() {
        const { layout, classNames, icon, label, hasSpinner } = this.props;

        const spaceBefore = (layout === BtnLayout.CountIconLabel
            || layout === BtnLayout.IconLabelCount
            || layout === BtnLayout.IconLabel) && icon;
        const sharedClasses = [
            styles.Btn__label,
            spaceBefore && styles['Btn__label--spaced'],
            classNames && classNames.label,
            hasSpinner && styles['Btn__label--hidden'],
        ];

        return layout !== BtnLayout.Icon && label ? (
            <span className={cx(...sharedClasses)}>
                {label}
            </span>
        ) : null;
    }

    private renderCount() {
        const { layout, type, count, countType, icon, label, hasSpinner } = this.props;
        const { lastCount } = this.state;

        // Count is used as animation controller and as displayed value
        // So transition to count === 0 will first display zero then fade it away -> which is not good
        // Because of that we have to display last non-zero value during animation
        const displayCount = count || lastCount;
        const spaceBefore = layout !== BtnLayout.CountIconLabel && (icon || label);
        const sharedClasses = [
            styles.Btn__count,
            styles[`Btn__count--countType-${BtnCountType[countType]}`],
            styles[`Btn__count--countType-${BtnCountType[countType]}-btnType-${BtnType[type]}`],
            spaceBefore && styles['Btn__count--spaced'],
            hasSpinner && styles['Btn__count--hidden'],
        ];

        return count !== undefined && layout !== BtnLayout.LabelIcon ? (
            <CSSTransition classNames="fade" timeout={300} in={count > 0} mountOnEnter unmountOnExit>
                {countType === BtnCountType.Circle
                    ? <CountBadge className={cx(...sharedClasses)} count={displayCount} />
                    : (
                        <span className={cx(...sharedClasses)}>
                            {(type === BtnType.Tab || type === BtnType.SubTab) && displayCount > 20
                                ? '20+'
                                : displayCount
                            }
                        </span>
                    )
                }
            </CSSTransition>
        ) : null;
    }

    private renderBullet() {
        const { layout, bullet, bulletClassName, hasSpinner } = this.props;

        const sharedClasses = [
            styles.Btn__bullet,
            bulletClassName,
            hasSpinner && styles['Btn__bullet--hidden'],
        ];

        return bullet && layout !== BtnLayout.Icon ? (
            <CSSTransition classNames="fade" timeout={300} in={bullet} mountOnEnter unmountOnExit>
                <span className={cx(...sharedClasses)} />
            </CSSTransition>
        ) : null;
    }

    private renderSpinner() {
        const { hasSpinner } = this.props;

        return (
            <CSSTransition classNames="fade" timeout={300} in={hasSpinner} mountOnEnter unmountOnExit>
                <div className={cx(styles.Btn__spinnerWrapper)}>
                    <div><FontAwesomeIcon icon={faSpinner} spin /></div>
                </div>
            </CSSTransition>
        );
    }
}
