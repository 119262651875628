/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const bicycle : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: [
        d("12"),
        d("14"),
        d("16"),
        d("18"),
        d("20"),
        d("24"),
        d("26"),
        d("28"),
    ],
    p_brand: [
        d("Alpina"),
        d("Amulet"),
        d("Arcore"),
        d("Author"),
        d("BMC"),
        d("BMX"),
        d("Bolt"),
        d("Coppi"),
        d("CTM"),
        d("DEMA"),
        d("Dino bikes"),
        d("Galaxy"),
        d("Ghost"),
        d("Giant"),
        d("Haibike"),
        d("Harry"),
        d("Head"),
        d("Kellys"),
        d("Kenzel"),
        d("Kenzo"),
        d("Kona"),
        d("Kross"),
        d("Merida"),
        d("Mondraker"),
        d("MUDDYFOX"),
        d("Norco"),
        d("Olpran"),
        d("Petromila"),
        d("PUKY"),
        d("SCOTT"),
        d("Shimano"),
        d("Shumee"),
        d("SPECIALIZED"),
        d("Tectake"),
        d("Volare"),
        d("Wista"),
        d("Woom"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
