import {
    emailChangeUrl,
    formsGalleryUrl,
    loginUrl,
    oldBrowserUrl,
    phoneVerificationUrl,
    signupUrl,
    usersDirectoryUrl,
    usersFeedbackPollUrl,
    usersPampersCouponForUserUrl,
    usersPampersCouponUrl,
    usersPasswordResetEmailUrl,
    usersPasswordResetPhoneUrl,
    usersPasswordResetSetNewUrl,
    usersPasswordResetUrl,
    usersTicketHelpMessageUrl,
    usersTicketHelpUrl,
} from 'mk2/apps/users/urls';
import { Route } from 'mk2/containers/Application/Application';
import { url } from 'mk2/helpers/urls';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [ {
    app: 'users',
    exact: true,
    path: phoneVerificationUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/PhoneVerification/PhoneVerificationPage' /* webpackChunkName: "users.PhoneVerification" */);
    },
    modules: [ 'mk2/apps/users/containers/PhoneVerification/PhoneVerificationPage' ],
}, {
    app: 'users',
    exact: true,
    path: emailChangeUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/EmailChange/EmailChangePage' /* webpackChunkName: "users.EmailChange" */);
    },
    modules: [ 'mk2/apps/users/containers/EmailChange/EmailChangePage' ],
}, {
    app: 'users',
    exact: true,
    path: usersDirectoryUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/UsersDirectory/UsersDirectoryPage' /* webpackChunkName: "users.UsersDirectory" */);
    },
    modules: [ 'mk2/apps/users/containers/UsersDirectory/UsersDirectoryPage' ],
}, {
    app: 'users',
    exact: true,
    path: signupUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/Signup/SignupPage' /* webpackChunkName: "users.Signup" */);
    },
    modules: [ 'mk2/apps/users/containers/Signup/SignupPage' ],
}, {
    app: 'users',
    exact: true,
    path: loginUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/Login/LoginPage' /* webpackChunkName: "users.Login" */);
    },
    modules: [ 'mk2/apps/users/containers/Login/LoginPage' ],
}, {
    app: 'users',
    exact: true,
    path: oldBrowserUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/OldBrowser/OldBrowserPage' /* webpackChunkName: "users.OldBrowser" */);
    },
    modules: [ 'mk2/apps/users/containers/OldBrowser/OldBrowserPage' ],
}, {
    app: 'users',
    exact: true,
    path: formsGalleryUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/FormsGallery/FormsGalleryPage' /* webpackChunkName: "users.FormsGallery" */);
    },
    modules: [ 'mk2/apps/users/containers/FormsGallery/FormsGalleryPage' ],
}, {
    app: 'users',
    exact: true,
    path: usersPasswordResetUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/PasswordResetChoose/PasswordResetChoosePage' /* webpackChunkName: "users.PasswordResetChoose" */);
    },
    modules: [ 'mk2/apps/users/containers/PasswordResetChoose/PasswordResetChoosePage' ],
}, {
    app: 'users',
    exact: true,
    path: usersPasswordResetPhoneUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/PasswordResetPhone/PasswordResetPhonePage' /* webpackChunkName: "users.PasswordResetPhone" */);
    },
    modules: [ 'mk2/apps/users/containers/PasswordResetPhone/PasswordResetPhonePage' ],
}, {
    app: 'users',
    exact: true,
    path: usersPasswordResetEmailUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/PasswordResetEmail/PasswordResetEmailPage' /* webpackChunkName: "users.PasswordResetEmail" */);
    },
    modules: [ 'mk2/apps/users/containers/PasswordResetEmail/PasswordResetEmailPage' ],
}, {
    app: 'users',
    exact: true,
    path: usersPasswordResetSetNewUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/PasswordResetSetNew/PasswordResetSetNewPage' /* webpackChunkName: "users.PasswordResetSetNew" */);
    },
    modules: [ 'mk2/apps/users/containers/PasswordResetSetNew/PasswordResetSetNewPage' ],
}, {
    app: 'users',
    exact: true,
    path: usersFeedbackPollUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/FeedbackPoll/FeedbackPollPage' /* webpackChunkName: "users.FeedbackPoll" */);
    },
    modules: [ 'mk2/apps/users/containers/FeedbackPoll/FeedbackPollPage' ],
}, {
    app: 'users',
    exact: true,
    path: usersPampersCouponUrl,
    page: new RedirectPage({
        to: (props) => url(usersPampersCouponForUserUrl, {username: props.requestUser.username}),
        permanent: true,
        authOnly: true,
    }),
}, {
    app: 'users',
    exact: true,
    path: usersPampersCouponForUserUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/PampersCoupon/PampersCouponPage' /* webpackChunkName: "users.PampersCoupon" */);
    },
    modules: [ 'mk2/apps/users/containers/PampersCoupon/PampersCouponPage' ],
}, {
    app: 'users',
    exact: true,
    path: usersTicketHelpUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/TicketHelp/TicketHelpPage' /* webpackChunkName: "users.TicketHelp" */);
    },
    modules: [ 'mk2/apps/users/containers/TicketHelp/TicketHelpPage' ],
}, {
    app: 'users',
    exact: true,
    path: usersTicketHelpMessageUrl,
    getPage: () => {
        return import('mk2/apps/users/containers/TicketHelpMessage/TicketHelpMessagePage' /* webpackChunkName: "users.TicketHelpMessageForm" */);
    },
    modules: [ 'mk2/apps/users/containers/TicketHelp/TicketHelpMessagePage' ],
}];
