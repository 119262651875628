/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp2 } from './commonProp2';

export const nursery : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["NURSERY_DESK", "Psac\u00ed st\u016fl"],
        ["NURSERY_CHAIR_SEAT_CUSHION", "\u017didle, podsed\u00e1k"],
        ["NURSERY_ARMCHAIR", "K\u0159es\u00edlko"],
        ["NURSERY_WARDROBE", "Sk\u0159\u00ed\u0148, komoda"],
        ["NURSERY_SHELF", "Police"],
        ["NURSERY_HANGERS", "V\u011b\u0161\u00e1k, ram\u00ednka"],
        ["NURSERY_OTHER_FURNITURE", "Jin\u00fd n\u00e1bytek"],
        ["NURSERY_CARPET", "Koberec"],
        ["NURSERY_CURTAIN_DRAPE", "Z\u00e1clona, z\u00e1v\u011bs"],
        ["NURSERY_LAMP", "Lustr, lampa"],
        ["NURSERY_WALLPAPER_STICKER", "Tapeta, n\u00e1lepka na ze\u010f"],
        ["NURSERY_PICTURE_POSTER_FRAME", "Obr\u00e1zek, plak\u00e1t, r\u00e1me\u010dek"],
        ["NURSERY_BABY_METER", "D\u011btsk\u00fd metr"],
        ["NURSERY_WALL_ORGANIZER", "Kaps\u00e1\u0159"],
        ["NURSERY_SEAT_BAG_STOOL", "Sedac\u00ed vak, taburet"],
        ["NURSERY_CUSHION_CUSHION_COVER", "Pol\u0161t\u00e1\u0159ek, povlak na pol\u0161t\u00e1\u0159ek"],
        ["NURSERY_STORAGE_BOX_ORGANIZER", "\u00dalo\u017en\u00fd box, organiz\u00e9r"],
        ["NURSERY_CLOCK_ALARM_CLOCK", "Hodiny, bud\u00edk"],
        ["NURSERY_TRASH_CAN", "Odpadkov\u00fd ko\u0161"],
        ["NURSERY_MONEYBOX", "Pokladni\u010dka"],
        ["NURSERY_DECOR", "Dekorace"],
        ["NURSERY_OTHER", "Jin\u00e9"],
    ],
    p_sex: commonProp2,
};
