import { Action } from 'redux';
import { DeletionFormData } from './DeletionForm';

interface DeletionAction extends Action {
    readonly entity: string;
    readonly id: number;
}

// Deletion Show Form

export const DELETION_SHOW_FORM = 'DELETION_SHOW_FORM';

export type CreateDeleteActionFunction = (deletion: DeletionFormData) => Action;

export interface DeletionShowFormAction extends DeletionAction {
    readonly type: typeof DELETION_SHOW_FORM;
    createDeleteAction: CreateDeleteActionFunction;
}

export const deletionShowForm = (entity: string, id: number, createDeleteAction: CreateDeleteActionFunction): DeletionShowFormAction => ({
    type: DELETION_SHOW_FORM,
    entity, id, createDeleteAction,
});

// Deletion Hide Form

export const DELETION_HIDE_FORM = 'DELETION_HIDE_FORM';

export interface DeletionHideFormAction extends DeletionAction {
    readonly type: typeof DELETION_HIDE_FORM;
}

export const deletionHideForm = (entity: string, id: number): DeletionHideFormAction => ({
    type: DELETION_HIDE_FORM,
    entity, id,
});

// Deletion Stop Submit

export const DELETION_STOP_SUBMIT = 'DELETION_STOP_SUBMIT';

export interface DeletionStopSubmitAction extends DeletionAction {
    readonly type: typeof DELETION_STOP_SUBMIT;
}

export const deletionStopSubmit = (entity: string, id: number): DeletionStopSubmitAction => ({
    type: DELETION_STOP_SUBMIT,
    entity, id,
});

// Deletion Cleanup

export const DELETION_CLEANUP = 'DELETION_CLEANUP';

export interface DeletionCleanupAction extends DeletionAction {
    readonly type: typeof DELETION_CLEANUP;
}

export const deletionCleanup = (entity: string, id: number): DeletionCleanupAction => ({
    type: DELETION_CLEANUP,
    entity, id,
});

// Deletion Execute

export const DELETION_EXECUTE = 'DELETION_EXECUTE';

export interface DeletionExecuteAction extends DeletionAction {
    readonly type: typeof DELETION_EXECUTE;
    readonly deletion: DeletionFormData;
}

export const deletionExecute = (entity: string, id: number, deletion: DeletionFormData): DeletionExecuteAction => ({
    type: DELETION_EXECUTE,
    entity, id, deletion,
});
