/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const blankets_wrappers : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BLANKETS", "Deky"],
        ["WRAPPER", "Zavinova\u010dky"],
    ],
    p_color: commonProp4,
};
