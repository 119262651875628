/* jshint esnext:true */

import {csrfToken} from 'mk/common/csrfToken';
import { CURRENT_REVISION } from 'mk/autogenerated/gitData';

let apiAuthCsrfToken = csrfToken();

const apiAuthVerifier = () => {
    // we check if the csrf-token has changed
    // since page-load. this can happen
    // for example when the user re-logins
    // in an another browser-tab
    // and then returns to this tab
    if (typeof navigator !== 'undefined' && typeof document !== 'undefined') {
        const currentCsrfToken = csrfToken();
        if (currentCsrfToken !== apiAuthCsrfToken) {
            const err = new Error('apiStatus verification failed');
            err.errorCode = 'CSRF_CHANGED_ERROR';
            throw err;
        }
    }
};

export function makeClientApiAuth() {
    if (typeof navigator !== 'undefined' && typeof document !== 'undefined') {
        const ssrRequestIdMeta = document.querySelector('meta[name="mk:ssr-request-id"]');
        return {
            verifier: apiAuthVerifier,
            baseUrl: '', // we could use also http://www.modrykonik.sk
            cookies: [],
            headers: [
                ['X-CSRFToken', apiAuthCsrfToken],
                ['X-SSR-Request-Id', ssrRequestIdMeta ? ssrRequestIdMeta.content : 'unknown'],
                ['X-Release', CURRENT_REVISION],
            ],
        };
    }
}

export function refreshApiAuthCsrfToken() {
    apiAuthCsrfToken = csrfToken();
}
