/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp13: Array<[string, string]> = [
    d("4Baby"),
    d("Abc Design"),
    d("Adbor"),
    d("Antony"),
    d("Avionaut"),
    d("Axkid"),
    d("Babideal"),
    d("Baby Design"),
    d("Baby Merc"),
    d("Babypoint"),
    d("Baby Smile"),
    d("Babystyle"),
    d("Bambi"),
    d("Bambino"),
    d("Beb\u00e9 Beni"),
    d("B\u00e9be Confort"),
    d("B\u00e9b\u00e9car"),
    d("Bebetto"),
    d("Bellelli"),
    d("Berber"),
    d("Bertoni"),
    d("BeSafe"),
    d("Brio"),
    d("Britax"),
    d("Cam"),
    d("Camarelo"),
    d("Caretero"),
    d("Casualplay"),
    d("Chicco"),
    d("Chipolino"),
    d("Coletto"),
    d("Concord"),
    d("Coneco"),
    d("Cosatto"),
    d("Coto Baby"),
    d("Cybex"),
    d("Deltim"),
    d("Depemo"),
    d("Disney"),
    d("DorJan"),
    d("Emitex"),
    d("Emmaljunga"),
    d("Espiro"),
    d("Esprit"),
    d("Euro Cart"),
    d("Ferrari"),
    d("Firkon"),
    d("Fisher Price"),
    d("Freeline"),
    d("Gesslein"),
    d("Graco"),
    d("Hauck"),
    d("I\u00b4coo"),
    d("Implast"),
    d("Inglesina"),
    d("Jan\u00e9"),
    d("Joie"),
    d("Juniors"),
    d("Kaarsgaren"),
    d("Kaiser"),
    d("Kajtex"),
    d("Kaufmann"),
    d("Kiddy"),
    d("Klippan"),
    d("Kunert"),
    d("Lodger"),
    d("Maxi Cosi"),
    d("Milly Mally"),
    d("Mothercare"),
    d("Nania"),
    d("Neonato"),
    d("Newbuddy"),
    d("Nowalex"),
    d("Nuna"),
    d("Nurse"),
    d("Odenw\u00e4lder BabyNest"),
    d("Orion"),
    d("Osann"),
    d("Patron"),
    d("Peg-P\u00e9rego"),
    d("Petite&Mars"),
    d("Phil&Teds"),
    d("Pierre Cardin"),
    d("Pinkie"),
    d("Quatro"),
    d("Ramatti"),
    d("Recaro"),
    d("Red Castle"),
    d("Renolux"),
    d("Riko"),
    d("Roan"),
    d("R\u00f6mer"),
    d("Safety Baby"),
    d("Scout"),
    d("Silver Cross"),
    d("Stokke"),
    d("Storchenm\u00fchle"),
    d("Sunshine Kids"),
    d("Tako"),
    d("Teutonia"),
    d("Touragoo"),
    d("Vydeko"),
    d("X-lander"),
    d("Zagma"),
    d("Zooty"),
    d("Zopa"),
    ["HANDMADE", "Handmade"],
    ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
];
