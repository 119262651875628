import { installPromptSetReady, INSTALL_PROMPT_INSTALL } from 'mk2/containers/InstallPrompt/InstallPrompt.actions';
import { getLogger } from 'mk2/logger';
import { eventChannel } from 'redux-saga';
import { all, call, fork, put, take } from 'redux-saga/effects';

const logger = getLogger('containers/InstallPrompt/InstallPrompt.sagas');

function watchBeforeInstall() {
    return eventChannel((emitter) => {
        const listener = (event) => {
            emitter(event);
        };

        window.addEventListener('beforeinstallprompt', listener);

        return () => {
            window.removeEventListener('beforeinstallprompt', listener);
        };
    });
}

function* watch() {
    // Watch only in browser
    if (typeof window === 'undefined') {
        return;
    }

    const channel = yield call(watchBeforeInstall);
    // Store prompt event
    const event = yield take(channel);
    yield put(installPromptSetReady(true));

    // Wait for action from user
    const install = yield take(INSTALL_PROMPT_INSTALL);
    event.prompt();
    const result = yield call(() => event.userChoice);
    // TODO: Display some toast with result?
    // Hide prompts - beforeinstallprompt will not happen again and current event cannot be used anymore
    yield put(installPromptSetReady(false));
}

export default function* root() {
    yield all([fork(watch)]);
}
