import { contestsHomeUrl, contestsPostDetailUrl } from 'mk2/apps/contests/urls';
import { DynamicRoute } from 'mk2/containers/Application/Application';

export const routes: DynamicRoute[] = [{
    app: 'contests',
    exact: true,
    path: contestsHomeUrl,
    getPage: () => {
        return import('mk2/apps/contests/containers/ContestsHome/ContestsHomePage' /* webpackChunkName: "contests.ContestsHome" */);
    },
    modules: [ 'mk2/apps/contests/containers/ContestsHome/ContestsHomePage' ],
}, {
    app: 'contests',
    exact: true,
    path: contestsPostDetailUrl,
    getPage: () => {
        return import('mk2/apps/contests/containers/ContestPostDetail/ContestPostDetailPage' /* webpackChunkName: "contests.ContestPostDetail" */);
    },
    modules: [ 'mk2/apps/contests/containers/ContestPostDetail/ContestPostDetailPage' ],
}];
