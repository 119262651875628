import { go, goBack, push, replace } from 'connected-react-router';
import {
    createBrowserHistory as createHistory,
    createPath,
    parsePath,
    History,
    Location,
    LocationDescriptor,
    LocationDescriptorObject,
    LocationState,
    Path,
} from 'history';
import { BASE_URL } from 'mk/settings';
import { FormPageType } from 'mk2/constants/enums';
import { hasRoute } from 'mk2/helpers/router';
import { tmerge, tupdate } from 'mk2/helpers/types';
import { routes } from 'mk2/router';
import { getRoutingLocation } from 'mk2/selectors';
import { put, select } from 'redux-saga/effects';

export const HISTORY_KEY_LENGTH = 12;

// options in window.history.state that control scroll restoration and BackBtn behavior
export interface HistoryLocationState extends LocationState {
    // if true, do not restore previous scroll position (don't touch)
    dontScrollOnPush?: boolean;
    // if true, skip this location/url on go back
    skipOnBack?: boolean;
    // flag to specially handle fallback case when going back via BackBtn in header.
    // From UX perspective we are going back, but technically we have to call history.push(),
    // because there is nothing in the location history.
    backFallback?: boolean;

    // FormPage state
    // Ignore prompt during transition between form pages
    formIgnorePrompt?: boolean;
    // Current form name
    formName?: string;
    // Actual form page
    currentPage?: FormPageType;

    // variables maintained by this browserHistory, other code can only read it:
    //
    // number of previous locations/urls in history that belong to our singlepage app
    readonly mkGoBackDepth?: number;
    // number of locations/urls in history to be skipped on 'go back'
    readonly mkSkipOnBackDepth?: number;

    // last locations/url. Warning: it IGNORES NATIVE BACK BUTTON. Use it, if you accept this defect.
    readonly UNSAFE_mkGoBackUrl?: string;
}

type WriteableHistoryLocationState = {
    -readonly [P in keyof HistoryLocationState]: HistoryLocationState[P];
};

export function initBrowserHistory(): History {
    // Read basename from <base href="" />
    const base = document.querySelector('base');
    const baseHref = base ? base.getAttribute('href') : '/';

    // We use a library called 'history' from https://github.com/ReactTraining/history
    // The library wraps the window.history object from browser. Its api is SIMILAR to html5 history api
    //
    // It's most important functions are:
    //   history.push(path, [state])
    //   history.replace(path, [state])
    //
    // On every call of push() or replace(), the library injects into browser's window.history.state
    // an object containing {key: 'some random string', state: stateObjectPassedAsParamToPushOrReplace}
    //
    // The library does not set {key: ..., state: ...} to window.history.state on initialization.
    // We need to fix that, because otherwise ScrollToTop does not work properly for first
    // url opened in browser tab
    //
    // Also, from existence of {key: ..., state: ...} in window.history.state we can always decide if
    // current browser URL belongs to our single-page app or not.
    //
    // Additionally, we inject into state object a key called 'mkGoBackDepth'. We use it to track
    // if PREVIOUS url in browser history also belongs to our single-page app.
    const hstate = window.history.state || {};
    const initState: HistoryLocationState = {mkGoBackDepth: 0, mkSkipOnBackDepth: 0, UNSAFE_mkGoBackUrl: window.document.URL};
    window.history.replaceState({
        key: hstate.key || Math.random().toString(36).substr(2, HISTORY_KEY_LENGTH),
        state: initState ,
    }, window.document.title);

    const history = createHistory<HistoryLocationState>({
        basename: baseHref.replace(/\/$/, ''),
        keyLength: HISTORY_KEY_LENGTH,
    });

    const origLibraryPush = history.push;
    history.push = function mkPush(path: Path | LocationDescriptorObject<HistoryLocationState>, nextPushState?: HistoryLocationState): void {
        const prevState: HistoryLocationState = history.location.state;

        // this should be always true, but fails on Safari 8.0 (it has broken history api)
        const stateAssert = prevState &&  prevState.mkGoBackDepth !== undefined && prevState.mkSkipOnBackDepth !== undefined;

        if (!stateAssert) {
            // recover from broken history api, otherwise navigation on site does not work at all
            return origLibraryPush.call(this, path, nextPushState);
        } else if (typeof path === 'string') { // call signature: push(path: Path, state?: LocationState): void;
            const nextState = nextPushState || {} as any;

            // rather modify existing state object, do not replace it
            if (nextState.backFallback && prevState.mkGoBackDepth === 0) {
                nextState.mkGoBackDepth = 0;
                nextState.mkSkipOnBackDepth = 0;
                nextState.UNSAFE_mkGoBackUrl = null;
            } else {
                nextState.mkGoBackDepth = prevState.mkGoBackDepth + 1;
                nextState.mkSkipOnBackDepth = nextState.skipOnBack ? prevState.mkSkipOnBackDepth + 1 : 0;
                nextState.UNSAFE_mkGoBackUrl = window.document.URL;
            }

            return origLibraryPush.call(this, path, nextState);
        } else { // call signature: push(location: LocationDescriptorObject): void;
            const nextState: WriteableHistoryLocationState = path.state = path.state || {
                mkGoBackDepth: 0,
                mkSkipOnBackDepth: 0,
                UNSAFE_mkGoBackUrl: null,
            }; // set {} back to path.state if it was undefined

            // rather modify existing state object, do not replace it
            if (nextState.backFallback && prevState.mkGoBackDepth === 0) {
                nextState.mkGoBackDepth = 0;
                nextState.mkSkipOnBackDepth = 0;
                nextState.UNSAFE_mkGoBackUrl = null;
            } else {
                nextState.mkGoBackDepth = prevState.mkGoBackDepth + 1;
                nextState.mkSkipOnBackDepth = nextState.skipOnBack ? prevState.mkSkipOnBackDepth + 1 : 0;
                nextState.UNSAFE_mkGoBackUrl = window.document.URL;
            }

            return origLibraryPush.call(this, path);
        }
    };

    const origLibraryReplace = history.replace;
    history.replace = function mkReplace(path: Path | LocationDescriptorObject<HistoryLocationState>, newReplaceState?: HistoryLocationState): void {
        const currentState: HistoryLocationState = history.location.state;

        // this should be always true, but fails on Safari 8.0 (it has broken history api)
        const stateAssert = currentState &&  currentState.mkGoBackDepth !== undefined && currentState.mkSkipOnBackDepth !== undefined;

        if (!stateAssert) {
            // recover from broken history api, otherwise navigation on site does not work at all
            return origLibraryReplace.call(this, path, newReplaceState);
        } else if (typeof path === 'string') { // call signature: push(path: Path, state?: LocationState): void;
            const newState: WriteableHistoryLocationState = newReplaceState || {
                mkSkipOnBackDepth: 0,
                mkGoBackDepth: 0,
                UNSAFE_mkGoBackUrl: null,
            };

            // rather modify existing state object, do not replace it
            newState.mkGoBackDepth = currentState.mkGoBackDepth;
            newState.mkSkipOnBackDepth = newState.skipOnBack ? currentState.mkSkipOnBackDepth : 0;

            return origLibraryReplace.call(this, path, newState);
        } else { // call signature: push(location: LocationDescriptorObject): void;
            const newState: WriteableHistoryLocationState = path.state = path.state || {
                mkSkipOnBackDepth: 0,
                mkGoBackDepth: 0,
                UNSAFE_mkGoBackUrl: null,
            }; // set {} back to path.state if it was undefined

            // rather modify existing state object, do not replace it
            newState.mkGoBackDepth = currentState.mkGoBackDepth;
            newState.mkSkipOnBackDepth = newState.skipOnBack ? currentState.mkSkipOnBackDepth : 0;
            newState.UNSAFE_mkGoBackUrl = window.document.URL;

            return origLibraryReplace.call(this, path);
        }
    };

    return history;
}

/**
 * Goto next URL inside PWA, adds new entry into browser history.
 * (use in React components)
 *
 * If you need to redirect out of PWA, pass gotoLink as absolute url (with http prefix)
 */
export function pushInPWA(history: History, gotoLink: LocationDescriptor) {
    pushInPWAHelper(gotoLink, history, false).next();
}

/**
 * Goto next URL inside PWA, adds new entry into browser history.
 * (use in Redux sagas)
 *
 * If you need to redirect out of PWA, pass gotoLink as absolute url (with http prefix)
 */
export function* pushInPWASaga(gotoLink: LocationDescriptor) {
    yield pushInPWAHelper(gotoLink, null, true);
}

/*
 * helper trying to share code between pushInPWA() and pushInPWASaga()
 */
function pushInPWAHelper(gotoLink: LocationDescriptor, history: null, inSaga: true);
function pushInPWAHelper(gotoLink: LocationDescriptor, history: History, inSaga: false);
function* pushInPWAHelper(gotoLink: LocationDescriptor, history: History, inSaga: boolean) {
    if (typeof gotoLink === 'string') {  // gotoUrl is Path
        if (gotoLink.startsWith(BASE_URL)) {
            // We have full URL starting with BASE_URL - change to local pathname
            gotoLink = gotoLink.replace(BASE_URL, '');
        }

        if (typeof window !== 'undefined' && !hasRoute(gotoLink, routes)) {
            // ideme mimo nasej appky, na klientovi musime redictnut cez .href=, put(replace()) nezafunguje
            window.location.href = gotoLink;
        } else {
            history
                ? history.push({...parsePath(gotoLink)})
                : yield put(push({...parsePath(gotoLink)}));
        }
    } else { // gotoUrl is LocationDescriptorObject
        let path = createPath(gotoLink);
        if (path.startsWith(BASE_URL)) {
            // We have full URL starting with BASE_URL - change to local pathname
            path = path.replace(BASE_URL, '');
        }

        if (typeof window !== 'undefined' && !hasRoute(path, routes)) {
            // ideme mimo nasej appky, na klientovi musime redictnut cez .href=, put(replace()) nezafunguje
            window.location.href = path;
        } else {
            history
                ? history.push(gotoLink)
                : yield put(push(gotoLink));
        }
    }
}

/**
 * Go back in browser history, but stay inside PWA. If we should leave PWA, the fallback url is used instead.
 * (use in React components)
 *
 * If you need to fallback (redirect) out of PWA, pass fallbackBackLink as absolute url (with http prefix)
 */
export function goBackInPWA(history: History<any>, fallbackBackLink: LocationDescriptor) {
    goBackInPWAHelper(history.location, fallbackBackLink, history, false).next();
}

/**
 * Go back in browser history, but stay inside PWA. If we should leave PWA, the fallback url is used instead.
 * (use in Redux sagas)
 *
 * If you need to fallback (redirect) out of PWA, pass fallbackBackLink as absolute url (with http prefix)
 */
export function* goBackInPWASaga(fallbackBackLink: LocationDescriptor) {
    const location = yield select(getRoutingLocation);
    yield goBackInPWAHelper(location, fallbackBackLink, null, true);
}

/*
 * helper trying to share code between goBackInPWA() and goBackInPWASaga()
 */
function goBackInPWAHelper(currentLocation: Location<any>, fallbackBackLink: LocationDescriptor,
                           history: History, inSaga: false,
);
function goBackInPWAHelper(currentLocation: Location<any>, fallbackBackLink: LocationDescriptor,
                           history: null, inSaga: true,
);
function* goBackInPWAHelper(currentLocation: Location<any>, fallbackBackLink: LocationDescriptor,
                            history: History, inSaga: boolean,
) {
    // locationState might be null in old browsers (android 4.0)
    const locationState: HistoryLocationState = currentLocation.state;
    if (!locationState || locationState.mkGoBackDepth === undefined) {
        history
            ? pushInPWA(history, fallbackBackLink)
            : yield pushInPWASaga(fallbackBackLink);

        // ensure we stop here
        return;
    }

    // check if we can go back
    if (locationState.mkGoBackDepth > 0) {
        if (locationState.mkSkipOnBackDepth === 0) {
            // should skip to previous location in history
            history
                ? history.goBack()
                : yield put(goBack());
        } else {
            // should skip several previous locations in history
            //
            // but do not go outside of our singlepage app (that could happen e.g. if first url
            // in history is PostDetail)
            const step = Math.min(locationState.mkGoBackDepth, locationState.mkSkipOnBackDepth + 1);

            history
                ? history.go(-step)
                : yield put(go(-step));
        }
    } else {
        const updatedLink: LocationDescriptorObject = (typeof fallbackBackLink === 'string')
            ? tupdate(// link is Path
                parsePath(fallbackBackLink),
                {state : {backFallback: true}},
            )
            : tupdate(// link is LocationDescriptorObject
                fallbackBackLink,
                {state: tmerge(fallbackBackLink.state, {backFallback: true})},
            );

        history
            ? pushInPWA(history, updatedLink)
            : yield pushInPWASaga(updatedLink);
    }
}

/**
 * Redirect to new URL inside PWA. Handles correctly redirect both on client and in ssr.
 * (use in React components)
 *
 * If you need to redirect out of PWA, pass gotoUrl as absolute url (with http prefix)
 */
export function redirectInPWA(history: History, gotoUrl: string, httpStatus?: number) {
    redirectInPWAHelper(gotoUrl, httpStatus, history, false).next();
}

/**
 * Redirect to new URL inside PWA. Handles correctly redirect both on client and in ssr.
 * (use in Redux sagas)
 *
 * If you need to redirect out of PWA, pass gotoUrl as absolute url (with http prefix)
 */
export function* redirectInPWASaga(gotoUrl: string, httpStatus?: number) {
    yield redirectInPWAHelper(gotoUrl, httpStatus, null, true);
}

/*
 * helper trying to share code between redirectInPWA() and redirectInPWASaga()
 */
function redirectInPWAHelper(gotoUrl: string, httpStatus: number, history: History, inSaga: false);
function redirectInPWAHelper(gotoUrl: string, httpStatus: number, history: null, inSaga: true);
function* redirectInPWAHelper(gotoUrl: string, httpStatus: number, history: History, inSaga: boolean) {
    if (gotoUrl.startsWith(BASE_URL)) {
        // We have full URL starting with BASE_URL - change to local pathname
        gotoUrl = gotoUrl.replace(BASE_URL, '');
    }

    if (typeof window !== 'undefined' && !hasRoute(gotoUrl, routes)) {
        // ideme mimo nasej appky, na klientovi musime redictnut cez .href=, put(replace()) nezafunguje
        window.location.href = gotoUrl;
    } else {
        history
            ? history.replace({
                ...parsePath(gotoUrl),
                state: httpStatus ? {status: httpStatus} : undefined,
            })
            : yield put(replace({
                ...parsePath(gotoUrl),
                state: httpStatus ? {status: httpStatus} : undefined,
            }));
    }
}
