/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp18 } from './commonProp18';
import { commonProp4 } from './commonProp4';

export const other_electronics : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["DIGITAL_PHOTO_FRAME", "Digit\u00e1ln\u00ed fotor\u00e1me\u010dek"],
        ["RECHARGEABLE_BATTERIES_CHARGERS", "Baterie a nab\u00edjen\u00ed"],
        ["HEADPHONES", "Sluch\u00e1tka"],
        ["WEATHER_STATIONS", "Meteostanice"],
        ["SMART_WATCHES", "Chytr\u00e9 hodinky"],
        ["OTHER_TYPE_ELECTRONICS", "Jin\u00e1 elektronika"],
    ],
    p_brand: commonProp18,
    p_color: commonProp4,
};
