// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const DATE_ABBR_MONTH_1 = "led";
export const DATE_ABBR_MONTH_10 = "\u0159\u00edj";
export const DATE_ABBR_MONTH_11 = "lis";
export const DATE_ABBR_MONTH_12 = "pro";
export const DATE_ABBR_MONTH_2 = "\u00fanor";
export const DATE_ABBR_MONTH_3 = "b\u0159e";
export const DATE_ABBR_MONTH_4 = "dub";
export const DATE_ABBR_MONTH_5 = "kv\u011b";
export const DATE_ABBR_MONTH_6 = "\u010der";
export const DATE_ABBR_MONTH_7 = "\u010drc";
export const DATE_ABBR_MONTH_8 = "srp";
export const DATE_ABBR_MONTH_9 = "z\u00e1\u0159";
export const MONTH_NAME_1 = "leden";
export const MONTH_NAME_10 = "\u0159\u00edjen";
export const MONTH_NAME_11 = "listopad";
export const MONTH_NAME_12 = "prosinec";
export const MONTH_NAME_2 = "\u00fanor";
export const MONTH_NAME_3 = "b\u0159ezen";
export const MONTH_NAME_4 = "duben";
export const MONTH_NAME_5 = "kv\u011bten";
export const MONTH_NAME_6 = "\u010derven";
export const MONTH_NAME_7 = "\u010dervenec";
export const MONTH_NAME_8 = "srpen";
export const MONTH_NAME_9 = "z\u00e1\u0159\u00ed";
export const number_DAYS = ["%(number)s den", "%(number)s dny", "%(number)s dn\u016f"];
export const number_DAYS_AGO = ["P\u0159ed %(number)s dnem", "P\u0159ed %(number)s dny", "P\u0159ed %(number)s dny"];
export const number_HOURS = ["%(number)s hodina", "%(number)s hodiny", "%(number)s hodin"];
export const number_HOURS_AGO = ["P\u0159ed %(number)s hodinou", "P\u0159ed %(number)s hodinami", "P\u0159ed %(number)s hodinami"];
export const number_MINUTES = ["%(number)s minuta", "%(number)s minuty", "%(number)s minut"];
export const number_MINUTES_AGO = ["P\u0159ed %(number)s minutou", "P\u0159ed %(number)s minutami", "P\u0159ed %(number)s minutami"];
export const number_MONTHS = ["%(number)s m\u011bs\u00edc", "%(number)s m\u011bs\u00edce", "%(number)s m\u011bs\u00edc\u016f"];
export const number_MONTHS_AGO = ["P\u0159ed %(number)s m\u011bs\u00edcem", "P\u0159ed %(number)s m\u011bs\u00edci", "P\u0159ed %(number)s m\u011bs\u00edci"];
export const number_SECONDS = ["%(number)s sekunda", "%(number)s sekundy", "%(number)s sekund"];
export const number_SECONDS_AGO = ["P\u0159ed %(number)s vte\u0159inou", "P\u0159ed %(number)s vte\u0159inami", "P\u0159ed %(number)s vte\u0159inami"];
export const number_WEEKS = ["%(number)s t\u00fdden", "%(number)s t\u00fddny", "%(number)s t\u00fddn\u016f"];
export const number_WEEKS_AGO = ["P\u0159ed %(number)s t\u00fddnem", "P\u0159ed %(number)s t\u00fddny", "P\u0159ed %(number)s t\u00fddny"];
export const number_YEARS = ["%(number)s rok", "%(number)s roky", "%(number)s let"];
export const number_YEARS_AGO = ["P\u0159ed %(number)s rokem", "P\u0159ed %(number)s lety", "P\u0159ed %(number)s lety"];
export const TIMEFORMAT_AT_time = ["v %(time)s", "ve %(time)s", "v %(time)s"];
export const TIMEFORMAT_TODAY = "Dnes";
export const TIMEFORMAT_YESTERDAY = "V\u010dera";
export const YESTERDAY = "V\u010dera";
