import { Action } from 'redux';

import {
    APIFailureAction,
    APIRequestAction,
    APISuccessAction,
} from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';

// API
export interface LikeSaveAction extends Action {
    readonly entity: string;
    readonly id: number;
}

export const LIKE_SAVE_REQUEST = 'LIKE_SAVE_REQUEST';
export interface LikeSaveRequestAction extends LikeSaveAction, APIRequestAction {
    readonly type: typeof LIKE_SAVE_REQUEST;
    readonly response: NormalizedResponse;
}

export const LIKE_SAVE_SUCCESS = 'LIKE_SAVE_SUCCESS';
export interface LikeSaveSuccessAction extends LikeSaveAction, APISuccessAction {
    readonly type: typeof LIKE_SAVE_SUCCESS;
}

export const LIKE_SAVE_FAILURE = 'LIKE_SAVE_FAILURE';
export interface LikeSaveFailureAction extends LikeSaveAction, APIFailureAction {
    readonly type: typeof LIKE_SAVE_FAILURE;
}

export const likeSave = {
    // Note: We are sending fake response in the request, so that entities reducer will optimistic update data
    request: (entity: string, id: number, response: any): LikeSaveRequestAction => ({ type: LIKE_SAVE_REQUEST, entity, id, response }),
    success: (entity: string, id: number, response: any): LikeSaveSuccessAction => ({ type: LIKE_SAVE_SUCCESS, entity, id, response }),
    failure: (entity: string, id: number, error: any): LikeSaveFailureAction => ({ type: LIKE_SAVE_FAILURE, entity, id, error }),
};

// Triggers
export const LIKE_TOGGLE = 'LIKE_TOGGLE';

export interface LikeToggleAction extends Action {
    readonly type: typeof LIKE_TOGGLE;
    readonly id: number;
    readonly entity: string;
}

export const likeToggle = (entity: string, id: number): LikeToggleAction => ({ type: LIKE_TOGGLE, entity, id });
