/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const home_lighting : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["WORK_LAMPS", "Pracovn\u00ed lampy"],
        ["TABLE_LAMPS", "Stoln\u00ed lampy"],
        ["FLOOR_LAMPS", "Stojac\u00ed lampy (na zem)"],
        ["CEILING_LIGHTS", "Lustry, sv\u00edtidla na strop a st\u011bnu"],
        ["BATHROOM_LIGHTING", "Koupelnov\u00e1 sv\u00edtidla"],
        ["KITCHEN_LIGHTING", "Kuchy\u0148sk\u00e9 osv\u011btlen\u00ed"],
        ["OUTDOOR_LIGHTING", "Venkovn\u00ed osv\u011btlen\u00ed"],
        ["BULBS", "\u017d\u00e1rovky, z\u00e1\u0159ivky, LED zdroje"],
        ["OTHER_LIGHTNING", "Jin\u00e9"],
    ],
};
