import cx from 'classnames';
import { Features } from 'mk2/apps/strollers/schemas';
import { niceNumber } from 'mk2/apps/strollers/utils';
import { hasVal } from 'mk2/helpers/sanitize';
import React from 'react';
import styles from './NumericRangeFeature.mscss';

interface OwnProps {
    className?: string;
    features: Features;
    feature: any;
}

const renderValue = (value) => {
    return hasVal(value) ? niceNumber(value) : '?';
};

const renderRange = (minValue, maxValue) => {
    if (minValue === maxValue) {
        return renderValue(minValue);
    }
    if (hasVal(minValue) || hasVal(maxValue)) {
        return `${renderValue(minValue)}\u00A0\u2013\u00A0${renderValue(maxValue)}`;   // beware: nbsp + ndash used
    }
    const value = maxValue || minValue;
    return renderValue(value);
};

export class NumericRangeFeature extends React.Component<OwnProps> {
    public render() {
        const { feature, features, className } = this.props;

        if (!feature.hasWorthyValue(features)) {
            return null;
        }

        const valueMax = features[feature.featureName].max;
        const valueMin = features[feature.featureName].min;

        return (
            <div className={cx(styles.NumericRangeFeature, className)}>
                {renderRange(valueMin, valueMax)}
            </div>
        );
    }
}
