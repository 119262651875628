import {
    blogsAlbumCreateUrl,
    blogsAlbumDetailUrl,
    blogsAlbumEditPhotosUrl,
    blogsAlbumEditUrl,
    blogsAlbumPhotoUrl,
    blogsAlbumReorderUrl,
    blogsArticleCreateUrl,
    blogsArticleDraftsUrl,
    blogsArticleEditUrl,
    blogsArticleUrl,
    blogsAutoMessagePhotoUrl,
    blogsAutoMessageUrl,
    blogsCommentEditUrl,
    blogsEntityLikersUrl,
    blogsFeedWithCategoryUrl,
    blogsFriendsFeedUrl,
    blogsPopularFeedUrl,
    blogsPostShareUrl,
    blogsPostUrl,
    blogsProfileEditProUrl,
    blogsProfileHomeUrl,
    blogsProfileMoreUrl,
    blogsProfileProductsUrl,
    blogsProfileUrl,
    blogsShareMessageUrl,
    blogsShortMessageCreateUrl,
    blogsShortMessageEditUrl,
    blogsShortMessagePhotoUrl,
    blogsShortMessageUrl,
    blogsUrl,
} from 'mk2/apps/blogs/urls';
import { Route } from 'mk2/containers/Application/Application';
import { url } from 'mk2/helpers/urls';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [{
    app: 'blogs',
    exact: true,
    path: blogsProfileHomeUrl,
    page: new RedirectPage({
        to: (props) => url(blogsProfileUrl, {username: props.requestUser.username}),
        permanent: true,
        authOnly: true,
    }),
}, {
    app: 'blogs',
    exact: true,
    path: blogsProfileUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/ProfileDetail/ProfileDetailPage' /* webpackChunkName: "blogs.ProfileDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/ProfileDetail/ProfileDetailPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsProfileMoreUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/ProfileDetail/ProfileDetailPage' /* webpackChunkName: "blogs.ProfileDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/ProfileDetail/ProfileDetailPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsProfileProductsUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/ProfileProducts/ProfileProductsPage' /* webpackChunkName: "blogs.ProfileProducts" */);
    },
    modules: [ 'mk2/apps/blogs/containers/ProfileProducts/ProfileProductsPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsProfileEditProUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/VendorEdit/VendorEditPage' /* webpackChunkName: "blogs.VendorEdit" */);
    },
    modules: [ 'mk2/apps/blogs/containers/VendorEdit/VendorEditPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsFeed/BlogsFeedPage' /* webpackChunkName: "blogs.BlogsFeed" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsFeed/BlogsFeedPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsFeedWithCategoryUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsFeed/BlogsFeedPage' /* webpackChunkName: "blogs.BlogsFeed" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsFeed/BlogsFeedPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsFriendsFeedUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsFriendsFeed/BlogsFriendsFeedPage' /* webpackChunkName: "blogs.BlogsFriendsFeed" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsFriendsFeed/BlogsFriendsFeedPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsPopularFeedUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsPopularFeed/BlogsPopularFeedPage' /* webpackChunkName: "blogs.BlogsPopularFeed" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsPopularFeed/BlogsPopularFeedPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsEntityLikersUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsEntityLikers/BlogsEntityLikersPage' /* webpackChunkName: "blogs.BlogsEntityLikers" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsEntityLikers/BlogsEntityLikersPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsPostUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsPostSplitter/BlogsPostSplitterPage' /* webpackChunkName: "blogs.BlogsPostSplitter" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsPostSplitter/BlogsPostSplitterPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsArticleCreateUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/ArticleCreate/ArticleCreatePage' /* webpackChunkName: "blogs.ArticleCreate" */);
    },
    modules: [ 'mk2/apps/blogs/containers/ArticleEdit/ArticleCreatePage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsArticleEditUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/ArticleEdit/ArticleEditPage' /* webpackChunkName: "blogs.ArticleEdit" */);
    },
    modules: [ 'mk2/apps/blogs/containers/ArticleEdit/ArticleEditPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsArticleDraftsUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/ArticleDrafts/ArticleDraftsPage' /* webpackChunkName: "blogs.ArticleDrafts" */);
    },
    modules: [ 'mk2/apps/blogs/containers/ArticleDrafts/ArticleDraftsPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsArticleUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/Article/ArticlePage' /* webpackChunkName: "blogs.Article" */);
    },
    modules: [ 'mk2/apps/blogs/containers/Article/ArticlePage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsCommentEditUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/CommentEdit/CommentEditPage' /* webpackChunkName: "blogs.CommentEditPage" */);
    },
    modules: [ 'mk2/apps/blogs/containers/CommentEdit/CommentEditPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsShortMessageUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsShortMessageDetail/BlogsShortMessageDetailPage' /* webpackChunkName: "blogs.BlogsShortMessageDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsShortMessageDetail/BlogsShortMessageDetailPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsAutoMessageUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsShortMessageDetail/BlogsShortMessageDetailPage' /* webpackChunkName: "blogs.BlogsShortMessageDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsShortMessageDetail/BlogsShortMessageDetailPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsShareMessageUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsShortMessageDetail/BlogsShortMessageDetailPage' /* webpackChunkName: "blogs.BlogsShortMessageDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsShortMessageDetail/BlogsShortMessageDetailPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsShortMessageCreateUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsShortMessageCreate/BlogsShortMessageCreatePage' /* webpackChunkName: "blogs.BlogsShortMessageCreatePage" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsShortMessageEdit/BlogsShortMessageCreatePage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsShortMessageEditUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsShortMessageEdit/BlogsShortMessageEditPage' /* webpackChunkName: "blogs.BlogsShortMessageEdit" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsShortMessageEdit/BlogsShortMessageEditPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsShortMessagePhotoUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsShortMessageImageDetail/BlogsShortMessageImageDetailPage' /* webpackChunkName: "blogs.BlogsShortMessageImageDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsShortMessageImageDetail/BlogsShortMessageImageDetail' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsAutoMessagePhotoUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsShortMessageImageDetail/BlogsShortMessageImageDetailPage' /* webpackChunkName: "blogs.BlogsShortMessageImageDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsShortMessageImageDetail/BlogsShortMessageImageDetail' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsAlbumCreateUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsAlbumCreate/BlogsAlbumCreatePage' /* webpackChunkName: "blogs.BlogsAlbumCreate" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsAlbumCreate/BlogsAlbumCreatePage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsAlbumDetailUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsAlbumDetail/BlogsAlbumDetailPage' /* webpackChunkName: "blogs.BlogsAlbumDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsAlbumDetail/BlogsAlbumDetailPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsAlbumReorderUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsAlbumReorder/BlogsAlbumReorderPage' /* webpackChunkName: "blogs.BlogsAlbumReorder" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsAlbumReorder/BlogsAlbumReorderPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsAlbumEditUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsAlbumEdit/BlogsAlbumEditPage' /* webpackChunkName: "blogs.BlogsAlbumEdit" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsAlbumEdit/BlogsAlbumEditPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsAlbumEditPhotosUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsAlbumEditPhotos/BlogsAlbumEditPhotosPage' /* webpackChunkName: "blogs.BlogsAlbumEditPhotos" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsAlbumEditPhotos/BlogsAlbumEditPhotos' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsAlbumPhotoUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogsAlbumImageDetail/BlogsAlbumImageDetailPage' /* webpackChunkName: "blogs.BlogsAlbumImageDetail" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogsAlbumImageDetail/BlogsAlbumImageDetailPage' ],
}, {
    app: 'blogs',
    exact: true,
    path: blogsPostShareUrl,
    getPage: () => {
        return import('mk2/apps/blogs/containers/BlogPostShare/BlogPostSharePage' /* webpackChunkName: "blogs.BlogPostShare" */);
    },
    modules: [ 'mk2/apps/blogs/containers/BlogPostShare/BlogPostSharePage' ],
}];
