/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp21 } from './commonProp21';
import { commonProp4 } from './commonProp4';

export const home_textiles : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BEDDING", "Lo\u017en\u00ed pr\u00e1dlo"],
        ["CHAIR_COUCH_COVERS", "N\u00e1vleky na \u017eidle, gau\u010de"],
        ["CUSHION_COVERS", "Dekora\u010dn\u00ed pol\u0161t\u00e1\u0159e"],
        ["DUVETS_PILLOWS", "Pol\u0161t\u00e1\u0159e, pe\u0159iny"],
        ["QUILTS_ADN_THROWS", "Deky, p\u0159ikr\u00fdvky a p\u0159ehozy"],
        ["CURTAINS", "Z\u00e1clony, z\u00e1v\u011bsy a garn\u00fd\u017ee"],
        ["KITCHEN_TEXTILES", "Ut\u011brky, ch\u0148apky, z\u00e1st\u011bry"],
        ["TABLE_CLOTHS_ADN_RUNNERS", "Ubrusy a \u0161t\u00f3ly"],
        ["PLACE_MATS_COASTERS", "Prost\u00edr\u00e1n\u00ed a podlo\u017eky pod sklenice"],
        ["CHAIR_PADS", "Podsed\u00e1ky a pol\u0161t\u00e1\u0159e na \u017eidle"],
        ["TOWELS", "Ru\u010dn\u00edky a osu\u0161ky"],
        ["GARDEN_TEXTILES", "Text\u00edlie na balk\u00f3n, terasu"],
        ["OTHER_TEXTILES", "Jin\u00e9"],
    ],
    p_thema: commonProp21,
    p_color: commonProp4,
};
