import { WIKI_CATEGORIES } from 'mk/autogenerated/wikiCategories';
import { PhotoEntity } from 'mk2/schemas';

export const UNLISTED_WIKI_CATEGORIES = [11, 12, 13, 14]; // 'Ine', 'FAQ', 'Centrum recenzii', 'Kocikopedia '
export const WIKI_REVIEWS_ARTICLE_SLUG = WIKI_CATEGORIES.filter((wc) => wc.id === 13)[0].slug;
export const WIKI_OTHERS_ARTICLE_SLUG = WIKI_CATEGORIES.filter((wc) => wc.id === 11)[0].slug;
export const WIKI_CALCULATORS_ARTICLE_SLUG = WIKI_CATEGORIES.filter((wc) => wc.id === 15)[0].slug;

export const WIKI_EXPERIENCE_MENU_MORE = (id: number) => `wiki-experience-menu-more-${id}`;
export const isFaq = (categorySlug: string) => categorySlug === 'faq';
export const isNoAdsCategory = (categorySlug: string) => isFaq(categorySlug) || categorySlug === WIKI_OTHERS_ARTICLE_SLUG;
export const isArticleOfReviewsCategory = (categorySlug: string) => categorySlug === WIKI_REVIEWS_ARTICLE_SLUG;
export const isReviewHomepageArticle = (categorySlug: string, articleSlug: string) =>
    categorySlug === WIKI_REVIEWS_ARTICLE_SLUG &&
    articleSlug === WIKI_REVIEWS_ARTICLE_SLUG;

export const getBestVersionForThumb = (photo: PhotoEntity) => {
    if (photo.versions.includes('snx100')) {
        return 'snx100';
    }
    if (photo.versions.includes('s246x246')) {
        return 's246x246';
    }
    if (photo.versions.includes('s150x150')) {
        return 's150x150';
    }
    if (photo.versions.includes('s560x560')) {
        return 's560x560';
    }

    return 's1600x1600';
};
