import { FormPageType } from 'mk2/constants/enums';
import { Action } from 'redux';

export const MK_REDUX_FORM_OPEN_PAGE = '@@mk-redux-form/OPEN_PAGE';
export interface MKReduxFormOpenPage extends Action {
    readonly type: typeof MK_REDUX_FORM_OPEN_PAGE;
    readonly formName: string;
    readonly formPage: FormPageType;
}
export const mkReduxFormOpenPage = (formName: string, formPage: FormPageType): MKReduxFormOpenPage => ({
    type: MK_REDUX_FORM_OPEN_PAGE, formName, formPage,
});

export const MK_REDUX_FORM_CLOSE_PAGE = '@@mk-redux-form/CLOSE_PAGE';
export interface MKReduxFormClosePage extends Action {
    readonly type: typeof MK_REDUX_FORM_CLOSE_PAGE;
    readonly formName: string;
}
export const mkReduxFormClosePage = (formName: string): MKReduxFormClosePage => ({
    type: MK_REDUX_FORM_CLOSE_PAGE, formName,
});

export const MK_REDUX_FORM_CANCEL_PAGE = '@@mk-redux-form/CANCEL_PAGE';
export interface MKReduxFormCancelPage extends Action {
    readonly type: typeof MK_REDUX_FORM_CANCEL_PAGE;
    readonly formName: string;
}
export const mkReduxFormCancelPage = (formName: string): MKReduxFormCancelPage => ({
    type: MK_REDUX_FORM_CANCEL_PAGE, formName,
});
