/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const finance : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BOOKKEEPING", "\u00da\u010detnictv\u00ed"],
        ["FINANCIAL_ADVICE", "Finan\u010dn\u00ed poradenstv\u00ed"],
        ["MORTGAGE_ADVICE", "Hypot\u00e9\u010dn\u00ed poradenstv\u00ed"],
        ["INSURANCE", "Poji\u0161t\u011bn\u00ed"],
    ],
};
