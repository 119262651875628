/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp11: Array<[string, string]> = [
    ["MINI", "Mini"],
    ["MIDI", "Midi"],
    ["MAXI", "Maxi"],
];
