import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCircle, faPlusCircle, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {
    ASK_AN_EXPERT,
    BAZAAR, BAZAAR_WATCHDOG_SETTINGS_TITLE,
    BLOGS_MENU_FEEDS,
    BLOGS_WALL,
    BZ2_ADD_PRODUCT,
    BZ2_FAQ,
    BZ2_WISHLIST,
    CONTESTS,
    ESHOP_MENU,
    FORUMS_AND_GROUPS,
    HEADING_SELLERS,
    LOG_IN,
    MAIN_MENU_REVIEWS,
    MORE_WIKI_CATEGORIES,
    MY_ENTRIES,
    MY_SHOPPING,
    PB_MENU_FIND_MOM,
    PB_MENU_MY_ACTIVITY,
    REGISTER_HERE,
    SERVER_FULL_NAME,
    SERVER_SLOGAN,
    STROLLER_STROLLEROPEDIA,
    STROLLERS_SUBMENU_ARTICLES_AND_REVIEWS,
    STROLLERS_SUBMENU_COUNSELLING,
    STROLLERS_SUBMENU_FORUM,
    STROLLERS_SUBMENU_HOW_TO_PICK,
    YOUTUBE_MK_TITLE,
} from 'mk/autogenerated/translations/DesktopHeader.2dedc027372164883db2b400851719f7'
import { WIKI_CATEGORIES } from 'mk/autogenerated/wikiCategories';
import { canManageBazaar, isAuthenticated } from 'mk/bazaar/common/userUtils';
import assetUrl from 'mk/common/assetUrl';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import {
    bazaarCreateProductUrl,
    bazaarMyBazaarUrl,
    bazaarSellerSearchUrl,
    bazaarShoppingUrl,
    bazaarUrl, bazaarWatchdogSettingsUrl,
    bazaarWishlistUrl,
} from 'mk2/apps/bazaar/urls';
import { blogsArticleUrl, blogsFeedWithCategoryUrl, blogsUrl } from 'mk2/apps/blogs/urls';
import { contestsHomeUrl } from 'mk2/apps/contests/urls';
import { counsellingsHomeUrl, counsellingsTopicUrl } from 'mk2/apps/counsellings/urls';
import { faqIndexUrl } from 'mk2/apps/faq/urls';
import { forumTopicUrl, forumUrl } from 'mk2/apps/forum/urls';
import { eshopWithChannelUrl, homepageUrl } from 'mk2/apps/home/urls';
import { strollersArticlesUrl, strollersUrl } from 'mk2/apps/strollers/urls';
import { loginUrl, signupUrl, usersDirectoryUrl } from 'mk2/apps/users/urls';
import { UNLISTED_WIKI_CATEGORIES, WIKI_REVIEWS_ARTICLE_SLUG } from 'mk2/apps/wiki/helpers';
import { getWikiArticleUrl } from 'mk2/apps/wiki/urls';
import { wikiCategoryUrl } from 'mk2/apps/wiki/utils';
import { Img } from 'mk2/components/Img';
import { Interpolate } from 'mk2/components/Interpolate';
import { Link } from 'mk2/components/Link';
import { Count, NotificationsBadge, Type } from 'mk2/components/NotificationsBadge';
import { NotificationMenu } from 'mk2/components/NotificationMenu';
import { MenuItem } from 'mk2/containers/PageHeader/PageHeader.reducers';
import styles from 'mk2/containers/SiteMenu/DesktopHeader.mscss';
import { modrykonikLogoUrl, url } from 'mk2/helpers/urls';
import Loadable from 'mk2/helpers/Loadable';
import { HeaderMenuItem, MenuSelection, SubscribedCounsellingsCounts, UserEntity } from 'mk2/schemas';
import React from 'react';

/* AdminclubHeader is used only in adminclub :) - do not load it in normal pages */
const AdminclubHeader = Loadable({
    loader: () => import('mk2/apps/adminclub/components/AdminclubHeader' /* webpackChunkName: "adminclub.components.AdminclubHeader" */),
    modules: ['mk2/apps/adminclub/components/AdminclubHeader'],
    webpack: () => [require.resolveWeak('mk2/apps/adminclub/components/AdminclubHeader')],
});

const ESHOP_URL_WITH_UTM = `${eshopWithChannelUrl}/?utm_source=modrykonik&utm_medium=header&utm_content=menu`;

interface OwnProps {
    menuSelection?: MenuSelection;
    currentUrl: string; // nemozeme pouzit withRouter(), ten nefunguje v bazari

    requestUser: UserEntity;
    requestUserPermissions: string[];
    isMobile: boolean;
    heartsCount: number;
    unreadMails: number;
    newBazaarEvents: number;
    newBazaarSell: number;
    allBazaarSell: number;
    wallStatusNews: number;
    wishlistUpdates: number;
    newContests: number;
    subscribedCounsellings: SubscribedCounsellingsCounts;
    newTestingCalls: number; // TODO refactor to other badge count, will be defined
    feedMenu: MenuItem[];
    subMenuDropdownOpened: boolean;

    notificationMenuRef: React.RefObject<NotificationMenu>;
    onMenuClose();
    onStoreEvent(name: string, props: any);
    onSubMenuDropdownChange(opened: boolean);
}

interface SectionTabItem {
    section: string;
    content: React.ReactNode;
}

interface SubMenuItem {
    menuItem: string;
    className?: string;
    content: React.ReactNode;
    groupped?: boolean;
}

type Props = OwnProps;

export class DesktopHeader extends React.PureComponent<Props> {
    private subMenuDropdownRef = null;

    public componentDidMount() {
        window.addEventListener('click', this.onClickOutside);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutside);
    }

    public render() {
        const { menuSelection, requestUserPermissions } = this.props;

        let activeSection;
        let activeItem;
        if (menuSelection && menuSelection.activeSection) {
            activeSection = menuSelection.activeSection;
            if (activeSection === 'forum' || activeSection === 'groups') {
                activeSection = 'forum_and_groups';
            }
        }
        if (menuSelection && menuSelection.activeItem) {
            activeItem = menuSelection.activeItem;
        }

        const isAdmin = canManageBazaar(requestUserPermissions);

        return (
            <div className={styles.DesktopHeader}>
                <div className={styles.DesktopHeader__mainWrapper}>
                    <div className={styles.DesktopHeader__main}>
                        <div className={styles.DesktopHeader__firstLineWrapper}>
                            {/* Hearts, IP, Wall, Settings */}
                            {this.renderHeartsWallSettingsStripe(activeSection, activeItem)}
                        </div>
                        <div className={styles.DesktopHeader__secondLineWrapper}>
                            {this.renderLogo(activeSection, activeItem)}

                            {/* Bazar, Forum, Stena, Poradne */}
                            {this.renderSectionTabs(activeSection, activeItem, isAdmin)}
                        </div>
                    </div>
                </div>

                {['photoblog', 'bazaar', 'home', 'wiki', 'adminclub', 'strollers'].indexOf(activeSection) >= 0 && (
                    <div className={styles.DesktopHeader__subMenuWrapper}>
                        {activeSection === 'photoblog'
                            ? this.renderBlogsSubMenu(activeItem)
                            : activeSection === 'bazaar'
                            ? this.renderMarketSubMenu(activeItem)
                            : activeSection === 'home' || activeSection === 'wiki'
                            ? this.renderHomeSubMenu(activeItem, isAdmin)
                            : activeSection === 'adminclub'
                            ? this.renderAdminclubSubMenu(activeItem)
                            : activeSection === 'strollers'
                            ? this.renderStrollersSubMenu(activeItem)
                            : null}
                    </div>
                )}
            </div>
        );
    }

    private renderLogo(activeSection: string, activeItem: string) {
        const { onMenuClose } = this.props;

        const logo = (
            <Img
                lazy={false}
                height={34}
                width={150}
                alt={SERVER_FULL_NAME + ' - ' + SERVER_SLOGAN}
                src={modrykonikLogoUrl()}
            />
        );

        return (
            <div
                onClick={onMenuClose}
                className={cx(
                    styles.DesktopHeader__logo,
                    (activeSection === 'home' || activeSection === 'wiki') && styles['DesktopHeader__tabItem--active'],
                )}
            >
                <Link title={SERVER_FULL_NAME + ' - ' + SERVER_SLOGAN} to={homepageUrl}>
                    {logo}
                </Link>
            </div>
        );
    }

    private renderSectionTabs(activeSection: string, activeItem: string, isAdmin: boolean) {
        const { newBazaarEvents, newBazaarSell, newContests, subscribedCounsellings, onMenuClose } = this.props;

        const totalSubscribedCounsellings = Object.keys(subscribedCounsellings || {}).reduce(
            (acc, cur) => acc + subscribedCounsellings[cur],
            0,
        );

        const items: SectionTabItem[] = [
            {
                section: 'bazaar',
                content: (
                    <Link className={styles.DesktopHeader__tabItemLink} to={bazaarUrl}>
                        {BAZAAR}
                        <NotificationsBadge
                            className={styles.DesktopHeader__tabItemNotificationsBadge}
                            count={newBazaarEvents + newBazaarSell}
                            type={Type.Important}
                        />
                    </Link>
                ),
            },
            {
                section: 'forum_and_groups',
                content: (
                    <Link className={styles.DesktopHeader__tabItemLink} to={forumUrl}>
                        {FORUMS_AND_GROUPS}
                    </Link>
                ),
            },
            {
                section: 'photoblog',
                content: (
                    <Link className={styles.DesktopHeader__tabItemLink} to={blogsUrl}>
                        {BLOGS_WALL}
                    </Link>
                ),
            },
            {
                section: 'strollers',
                content: (
                    <Link className={styles.DesktopHeader__tabItemLink} to={strollersUrl}>
                        {STROLLER_STROLLEROPEDIA}
                    </Link>
                ),
            },
            {
                section: 'counsellings',
                content: (
                    <Link className={styles.DesktopHeader__tabItemLink} to={counsellingsHomeUrl}>
                        {ASK_AN_EXPERT}
                        <NotificationsBadge
                            className={styles.DesktopHeader__tabItemNotificationsBadge}
                            count={totalSubscribedCounsellings}
                        />
                    </Link>
                ),
            },
            {
                section: 'reviews',
                content: (
                    <Link
                        className={styles.DesktopHeader__tabItemLink}
                        to={getWikiArticleUrl(WIKI_REVIEWS_ARTICLE_SLUG, WIKI_REVIEWS_ARTICLE_SLUG)}
                    >
                        {MAIN_MENU_REVIEWS}
                    </Link>
                ),
            },
            {
                section: 'eshop',
                content: (
                    <Link
                        className={styles.DesktopHeader__tabItemLink}
                        to={ESHOP_URL_WITH_UTM}
                    >
                        {ESHOP_MENU}
                    </Link>
                ),
            },
        ];

        return (
            <ul className={styles.DesktopHeader__tabs}>
                {items.map((item) => (
                    <li
                        key={item.section}
                        onClick={onMenuClose}
                        className={cx(
                            styles.DesktopHeader__tabItem,
                            activeSection === item.section && styles['DesktopHeader__tabItem--active'],
                        )}
                    >
                        {item.content}
                    </li>
                ))}
            </ul>
        );
    }

    private renderHomeSubMenu(activeItem: string, isAdmin: boolean) {
        const {newContests} = this.props;
        const items: SubMenuItem[] = WIKI_CATEGORIES.filter(
            (wikiCategory) => !UNLISTED_WIKI_CATEGORIES.includes(wikiCategory.id),
        ).map((wikiCategory, i) => ({
            menuItem: wikiCategory.slug,
            content: (
                <Link
                    className={cx(
                        styles.DesktopHeader__subMenuItemLink,
                        i === 0 && styles['DesktopHeader__subMenuItemLink--first'],
                    )}
                    to={wikiCategoryUrl(wikiCategory)}
                >
                    {wikiCategory.name}
                </Link>
            ),
            groupped: ![1, 2, 5].includes(wikiCategory.id),
        }));

        const trailingItems = [
            {
                menuItem: 'youtube',
                className: styles['DesktopHeader__subMenuItem--right'],
                content: (
                    <Link className={styles.DesktopHeader__subMenuItemLink} to="https://www.youtube.com/Modrykonik">
                        <FontAwesomeIcon icon={faYoutube} className={styles.DesktopHeader__subMenuItemLink__icon} />
                        {YOUTUBE_MK_TITLE}
                    </Link>
                ),
            },
            {
                menuItem: 'strollersbase',
                content: (
                    <Link
                        className={cx(
                            styles.DesktopHeader__subMenuItemLink,
                        )}
                        to={strollersUrl}
                    >
                        <Img
                            width={18}
                            height={18}
                            alt={SERVER_FULL_NAME + ' - ' + SERVER_SLOGAN}
                            src={assetUrl + 'img/logos/ver-4/stroller@2x.png'}
                            lazy={false}
                            className={styles.DesktopHeader__subMenuItemLink__logo}
                        />
                        {STROLLER_STROLLEROPEDIA}
                    </Link>
                ),
            },
            {
                menuItem: 'contests',
                content: (
                    <Link className={cx(
                            styles.DesktopHeader__subMenuItemLink,
                            styles['DesktopHeader__subMenuItemLink--last'],
                        )} to={contestsHomeUrl}>
                        {CONTESTS}
                        <NotificationsBadge
                            className={styles.DesktopHeader__tabItemNotificationsBadge}
                            backgroundColor={'#fff'}
                            color={'#54b4ff'}
                            count={newContests}
                        />
                    </Link>
                ),
            },
        ];



        return this.renderGenericSubMenu(items, trailingItems, activeItem);
    }

    private renderBlogsSubMenu(activeItem: string) {
        const { feedMenu, requestUser, requestUserPermissions } = this.props;

        const isBlogsAdmin = requestUserPermissions.indexOf('photoblog.can_manage_blogs') >= 0;
        const feedsCount =
            feedMenu !== null &&
            feedMenu.length > 0 &&
            feedMenu.filter((item) => item.name === 'feeds').reduce((acc: Count, val) => val.lastVisitCount, null);

        const items: SubMenuItem[] = isAuthenticated(requestUser)
            ? [
                  {
                      menuItem: HeaderMenuItem.BlogFeeds,
                      content: (
                          <Link className={styles.DesktopHeader__subMenuItemLink} to={blogsUrl}>
                              {BLOGS_MENU_FEEDS}&nbsp;{feedsCount ? <i>{feedsCount}</i> : null}
                          </Link>
                      ),
                  },
                  {
                      menuItem: HeaderMenuItem.BlogsMyActivity,
                      content: (
                          <Link
                              className={styles.DesktopHeader__subMenuItemLink}
                              to={url(blogsFeedWithCategoryUrl, { category: 'my-activity' })}
                          >
                              {PB_MENU_MY_ACTIVITY}
                          </Link>
                      ),
                  },
                  {
                      menuItem: HeaderMenuItem.BlogsUsersDirectory,
                      content: (
                          <Link className={styles.DesktopHeader__subMenuItemLink} to={usersDirectoryUrl}>
                              {PB_MENU_FIND_MOM}
                          </Link>
                      ),
                  },
              ]
            : [];

        if (isBlogsAdmin) {
            const flaggedCount =
                feedMenu !== null &&
                feedMenu.length > 0 &&
                feedMenu
                    .filter((item) => item.name === 'flagged')
                    .reduce((acc: Count, val) => val.lastVisitCount, null);
            items.push({
                menuItem: HeaderMenuItem.BlogsFlagged,
                content: (
                    <Link
                        className={styles.DesktopHeader__subMenuItemLink}
                        to={url(blogsFeedWithCategoryUrl, { category: 'flagged' })}
                    >
                        Flagged&nbsp;{flaggedCount ? <i>{flaggedCount}</i> : null}
                    </Link>
                ),
            });
        }

        return this.renderGenericSubMenu(items, [], activeItem);
    }

    private renderMarketSubMenu(activeItem: string) {
        const { requestUser, newBazaarSell, allBazaarSell, wishlistUpdates, newBazaarEvents } = this.props;

        let items: SubMenuItem[] = isAuthenticated(requestUser)
            ? [
                  {
                      menuItem: HeaderMenuItem.BazaarMyBazaar,
                      content: (
                          <Link
                              className={cx(
                                  styles.DesktopHeader__subMenuItemLink,
                                  styles['DesktopHeader__subMenuItemLink--first'],
                              )}
                              to={url(bazaarMyBazaarUrl, { username: requestUser.username })}
                          >
                              {MY_ENTRIES}
                              {newBazaarSell > 0 ? (
                                  <NotificationsBadge
                                      className={styles.DesktopHeader__tabItemNotificationsBadge}
                                      count={newBazaarSell}
                                      type={Type.Important}
                                  />
                              ) : allBazaarSell > 0 ? (
                                  <i>{allBazaarSell}</i>
                              ) : null}
                          </Link>
                      ),
                  },
                  {
                      menuItem: HeaderMenuItem.BazaarWishlist,
                      content: (
                          <Link
                              className={styles.DesktopHeader__subMenuItemLink}
                              to={url(bazaarWishlistUrl, { username: requestUser.username })}
                          >
                              {BZ2_WISHLIST}
                              {wishlistUpdates > 0 && <i>{wishlistUpdates}</i>}
                          </Link>
                      ),
                  },
                  {
                      menuItem: HeaderMenuItem.BazaarShopping,
                      content: (
                          <Link
                              className={styles.DesktopHeader__subMenuItemLink}
                              to={url(bazaarShoppingUrl, { username: requestUser.username })}
                          >
                              {MY_SHOPPING}
                              <NotificationsBadge
                                  className={styles.DesktopHeader__tabItemNotificationsBadge}
                                  count={newBazaarEvents}
                                  type={Type.Important}
                              />
                          </Link>
                      ),
                  },
                {
                      menuItem: HeaderMenuItem.BazaarMyWatchdogs,
                      content: (
                          <Link className={styles.DesktopHeader__subMenuItemLink} to={url(bazaarWatchdogSettingsUrl, {})}>
                              {BAZAAR_WATCHDOG_SETTINGS_TITLE}
                          </Link>
                      ),
                  },
                  {
                      menuItem: HeaderMenuItem.BazaarSellerSearch,
                      content: (
                          <Link className={styles.DesktopHeader__subMenuItemLink} to={bazaarSellerSearchUrl}>
                              {HEADING_SELLERS}
                          </Link>
                      ),
                  },
              ]
            : [];

        // Always add FAQ and Add Product links
        items = items.concat([
            {
                menuItem: HeaderMenuItem.BazaarFaq,
                content: (
                    <Link
                        className={styles.DesktopHeader__subMenuItemLink}
                        to={url(faqIndexUrl, { articleSlug: 'faq-index-bazar' })}
                    >
                        {BZ2_FAQ}
                    </Link>
                ),
            },
            {
                menuItem: HeaderMenuItem.BazaarAddItem,
                className: styles['DesktopHeader__subMenuItem--right'],
                content: (
                    <Link
                        className={cx(
                            styles.DesktopHeader__subMenuItemLink,
                            styles['DesktopHeader__subMenuItemLink--add'],
                        )}
                        to={bazaarCreateProductUrl}
                    >
                        <span className={cx('fa-layers fa-fw', styles.DesktopHeader__subMenuItemLinkAddIcon)}>
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={styles.DesktopHeader__subMenuItemLinkAddIcon__background}
                            />
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                className={styles.DesktopHeader__subMenuItemLinkAddIcon__icon}
                                transform="shrink-3"
                            />
                        </span>{' '}
                        {BZ2_ADD_PRODUCT}
                    </Link>
                ),
            },
        ]);

        return this.renderGenericSubMenu(items, [], activeItem);
    }

    private renderStrollersSubMenu(activeItem: string) {
        const items: SubMenuItem[] = [
            {
                menuItem: HeaderMenuItem.StrollersHowToPick,
                content: (
                    <Link
                        className={styles.DesktopHeader__subMenuItemLink}
                        to={url(
                            blogsArticleUrl,
                            ACTIVE_SERVER_ID === 201
                                ? { username: 'kocikopedia', articleSlug: 'ako-vybrat-kocik-8lxd28' }
                                : { username: 'kocarkopedie', articleSlug: 'jak-vybrat-kocarek-14dzxl' },
                        )}
                    >
                        {STROLLERS_SUBMENU_HOW_TO_PICK}
                    </Link>
                ),
            },
            {
                menuItem: HeaderMenuItem.StrollersArticlesAndReviews,
                content: (
                    <Link className={styles.DesktopHeader__subMenuItemLink} to={strollersArticlesUrl}>
                        {STROLLERS_SUBMENU_ARTICLES_AND_REVIEWS}
                    </Link>
                ),
            },
            {
                menuItem: HeaderMenuItem.StrollersCounselling,
                content: (
                    <Link
                        className={styles.DesktopHeader__subMenuItemLink}
                        to={url(counsellingsTopicUrl, {
                            topicSlug: ACTIVE_SERVER_ID === 201 ? 'kocikova-poradna' : 'kocarkova-poradna',
                        })}
                    >
                        {STROLLERS_SUBMENU_COUNSELLING}
                    </Link>
                ),
            },
            {
                menuItem: HeaderMenuItem.StrollersForum,
                content: (
                    <Link
                        className={styles.DesktopHeader__subMenuItemLink}
                        to={url(forumTopicUrl, {
                            topicSlug: ACTIVE_SERVER_ID === 201 ? 'na-kocikovanie' : 'na-kocarkovani',
                        })}
                    >
                        {STROLLERS_SUBMENU_FORUM}
                    </Link>
                ),
            },
            {
                menuItem: 'youtube',
                className: styles['DesktopHeader__subMenuItem--right'],
                content: (
                    <Link className={styles.DesktopHeader__subMenuItemLink} to="https://www.youtube.com/Modrykonik">
                        <FontAwesomeIcon icon={faYoutube} className={styles.DesktopHeader__subMenuItemLink__icon} />
                        {YOUTUBE_MK_TITLE}
                    </Link>
                ),
            },
        ];

        return this.renderGenericSubMenu(items, [], activeItem);
    }

    private renderAdminclubSubMenu(activeItem: string) {
        // CeMi: TODO skonvertovat na dizajn ako su ostatne submenu
        const { requestUserPermissions } = this.props;
        return <AdminclubHeader activeItem={activeItem} requestUserPermissions={requestUserPermissions} />;
    }

    private renderGenericSubMenu(groupableItems: SubMenuItem[], trailingItems: SubMenuItem[], activeItem: string) {
        const isGrouppedItems = groupableItems.filter((item: SubMenuItem) => item.groupped).length > 0;
        return (
            <ul className={styles.DesktopHeader__subMenu}>
                {groupableItems.map((item: SubMenuItem) => {
                    return !item.groupped ? (
                        <li
                            key={item.menuItem}
                            className={cx(
                                styles.DesktopHeader__subMenuItem,
                                item.className,
                                activeItem === item.menuItem && styles['DesktopHeader__subMenuItem--active'],
                                activeItem === item.menuItem && 'topmenu-activeLink',
                            )}
                        >
                            {item.content}
                        </li>
                    ) : null;
                })}
                {isGrouppedItems && (
                    <li key={'sub-menu-groupped-others-closed'} className={cx(styles.DesktopHeader__subMenuItem)}>
                        <a
                            className={cx(
                                styles.DesktopHeader__subMenuItemLink,
                                styles['DesktopHeader__subMenuItemLink--subMenudropdown'],
                            )}
                            onClick={this.handleToggleSubMenuDropdown}
                        >
                            {MORE_WIKI_CATEGORIES}{' '}
                            <FontAwesomeIcon
                                icon={faSortDown}
                                className={styles.DesktopHeader__subMenuItem__group__fa}
                            />
                        </a>
                        {this.props.subMenuDropdownOpened && (
                            <div
                                className={styles.DesktopHeader__subMenuItem__groupWrapper}
                                ref={this.setSubMenuDropdownRef}
                            >
                                <ul className={styles.DesktopHeader__subMenuItem__group}>
                                    <li
                                        key={'sub-menu-groupped-others-opened'}
                                        className={cx(
                                            styles.DesktopHeader__subMenuItem,
                                            styles['DesktopHeader__subMenuItem--active'],
                                        )}
                                    >
                                        <a
                                            className={cx(
                                                styles.DesktopHeader__subMenuItemLink,
                                                styles['DesktopHeader__subMenuItemLink--subMenudropdown'],
                                            )}
                                        >
                                            {MORE_WIKI_CATEGORIES}{' '}
                                            <FontAwesomeIcon
                                                icon={faSortDown}
                                                className={styles.DesktopHeader__subMenuItem__group__fa}
                                            />
                                        </a>
                                    </li>
                                    {groupableItems.map((item: SubMenuItem) => {
                                        return item.groupped ? (
                                            <li
                                                key={item.menuItem}
                                                className={cx(
                                                    styles.DesktopHeader__subMenuItem,
                                                    item.className,
                                                    activeItem === item.menuItem &&
                                                        styles['DesktopHeader__subMenuItem--active'],
                                                    activeItem === item.menuItem && 'topmenu-activeLink',
                                                )}
                                            >
                                                {item.content}
                                            </li>
                                        ) : null;
                                    })}
                                </ul>
                            </div>
                        )}
                    </li>
                )}
                {trailingItems.map((item: SubMenuItem) => {
                    return !item.groupped ? (
                        <li
                            key={item.menuItem}
                            className={cx(
                                styles.DesktopHeader__subMenuItem,
                                item.className,
                                activeItem === item.menuItem && styles['DesktopHeader__subMenuItem--active'],
                                activeItem === item.menuItem && 'topmenu-activeLink',
                            )}
                        >
                            {item.content}
                        </li>
                    ) : null;
                })}
            </ul>
        );
    }

    private renderHeartsWallSettingsStripe(activeSection: string, activeItem: string) {
        const {
            heartsCount,
            unreadMails,
            wallStatusNews,
            requestUser,
            onMenuClose,
            notificationMenuRef,
            onStoreEvent,
            currentUrl,
        } = this.props;

        return requestUser && isAuthenticated(requestUser) ? (
            <NotificationMenu
                ref={notificationMenuRef}
                heartsCount={heartsCount}
                unreadMails={unreadMails}
                wallStatusNews={wallStatusNews}
                requestUser={requestUser}
                activeSection={activeSection}
                activeItem={activeItem}
                onItemClick={onMenuClose}
                onStoreEvent={onStoreEvent}
            />
        ) : (
            <div className={styles.DesktopHeader__user}>
                <Link
                    className={styles['DesktopHeader__user--loginLink']}
                    to={url(loginUrl, {}, { next: currentUrl })}
                    rel="nofollow"
                >
                    <Interpolate i18nKey={LOG_IN} />
                </Link>
                <Link
                    className={cx(styles['DesktopHeader__user--registerLink'], 'topmenu-registerLink')}
                    to={url(
                        signupUrl,
                        {},
                        { mkutm_campaign: 'menu_registration', mkutm_source: 'modrykonik', next: currentUrl },
                    )}
                    rel="nofollow"
                >
                    {REGISTER_HERE}
                </Link>
            </div>
        );
    }

    private setSubMenuDropdownRef = (element) => {
        this.subMenuDropdownRef = element;
    };

    private onClickOutside = () => {
        if (this.props.subMenuDropdownOpened) {
            this.props.onSubMenuDropdownChange(false);
        }
    };

    private handleToggleSubMenuDropdown = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const { subMenuDropdownOpened, onSubMenuDropdownChange } = this.props;
        onSubMenuDropdownChange(!subMenuDropdownOpened);
    };
}
