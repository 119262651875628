/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp18 } from './commonProp18';
import { commonProp4 } from './commonProp4';

export const phones_tablets_ereaders : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SMARTPHONE", "Smartphone/ smartf\u00f3n"],
        ["MOBILE_PHONE", "Klasick\u00fd mobiln\u00ed telefon"],
        ["HOME_PHONE", "Telefon pro pevnou linku, fax"],
        ["TABLET", "Tablet"],
        ["EREADER", "\u010cte\u010dky e-knih"],
        ["PHONE_TABLET_CHARGERS", "Nab\u00edje\u010dky"],
        ["PHONE_TABLET_SCREEN_PROTECTORS", "F\u00f3lie a ochrann\u00e1 skla na obrazovku"],
        ["PHONE_TABLET_CASES_COVERS", "Obaly, pouzdra a kapsy"],
        ["PHONE_TABLET_DECORATIONS", "P\u0159\u00edv\u011bsky a ozdoby"],
        ["PHONE_TABLET_ACCESSORIES", "P\u0159\u00edslu\u0161enstv\u00ed a dopl\u0148ky"],
    ],
    p_screen_size: [
        ["NA", "Neuveden\u00e9"],
        d("1\" - 1.9\""),
        d("2\" - 2.9\""),
        d("3\" - 3.9\""),
        d("4\" - 4.9\""),
        d("5\" -  5.9\""),
        d("6\" - 6.9\""),
        d("7\" - 7.9\""),
        d("8\" - 9.9\""),
        d("10\"+"),
    ],
    p_brand: commonProp18,
    p_color: commonProp4,
};
