/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const automoto_spare_parts : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CAR_AUDIO", "Autor\u00e1dia"],
        ["CAR_GLASS", "Autoskla"],
        ["CAR_BRAKES", "Brzdy"],
        ["CAR_CHASSIS_PARTS", "\u010c\u00e1sti karoserie"],
        ["CAR_ELECTRICAL_WIRING", "Elektroinstalace"],
        ["CAR_WHEELS_AND_RIMS", "Kola a disky"],
        ["CAR_SPARE_PARTS", "N\u00e1hradn\u00ed d\u00edly a p\u0159\u00edslu\u0161enstv\u00ed"],
        ["CAR_SUMMER_TIRES", "Pneumatiky letn\u00ed"],
        ["CAR_WINTER_TIRES", "Pneumatiky zimn\u00ed"],
        ["CAR_MANDATORY_EQUIPMENT", "Povinn\u00e1 v\u00fdbava"],
        ["CAR_LIGHTS", "Sv\u011btla a blinkry"],
        ["CAR_INTERIOR_EQUIPMENT", "Vybaven\u00ed interi\u00e9ru"],
        ["CAR_COSMETICS", "Autokosmetika, v\u016fn\u011b do auta"],
    ],
};
