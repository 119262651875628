/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const rental : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CAR_RENTAL", "Auta"],
        ["TOOLS_RENTAL", "Stavebn\u00ed stroje a n\u00e1\u0159ad\u00ed"],
        ["RENTAL_FOR_CHILDREN", "Pro d\u011bti"],
        ["SPORT_RENTAL", "Sportovn\u00ed vybaven\u00ed"],
        ["OTHER_RENTAL", "Jin\u00e9"],
    ],
};
