import fromPairs from 'lodash-es/fromPairs';
import { COUNTIES } from 'mk/autogenerated/geoData';
import { COUNTRIES_TO_PLACES, PLACES } from 'mk/autogenerated/geoDataPlaces';
import { ALL_COUNTIES, SETTINGS_I_LIVE_ABROAD, USERS_DIRECTORY_SHE_LIVES_ABROAD } from 'mk/autogenerated/translations/geo.1081f0dbb4906e1704899ea6e2c1f0be'
import { slugify } from 'mk/common/utils';
import { Option } from 'mk2/components/forms/SelectField';
import { localeCompare } from 'mk2/helpers/sorting';

const countyIdToName = fromPairs(COUNTIES);
const countyNameToId = fromPairs(COUNTIES.map((x) => [x[1], x[0]]));
const countySlugToId = fromPairs(COUNTIES.map((x) => [slugify(x[1]), x[0]]));

export function getCountyId(countyName: string): number {
    return countyNameToId[countyName];
}

export function getCountyIdForSlug(countySlug: string): number {
    return countySlugToId[countySlug];
}

export function getCountyName(countyId: number): string {
    return countyIdToName[countyId];
}

export function getPlaceIdForCountryId(countryId: number): number {
    return COUNTRIES_TO_PLACES[countryId.toString()];
}

export const localCountiesSelectOptions: Option[] = [
    { value: '', label: '', isPlaceholder: true },
    ...COUNTIES
        .sort((a, b) => localeCompare(a[1], b[1]))
        .map((county) => ({
            value: county[0].toString(),
            label: county[1],
        })),
];

export const countiesSelectOptions: Option[] = [
    ...localCountiesSelectOptions,
    { value: '0', label: SETTINGS_I_LIVE_ABROAD },
];

export const countiesUsersDirectorySelectOptions: Option[] = [
    { value: '', label: ALL_COUNTIES },
    ...COUNTIES
        .sort((a, b) => localeCompare(a[1], b[1]))
        .map((county) => ({
            value: county[0].toString(),
            label: county[1],
        })),
    { value: '0', label: USERS_DIRECTORY_SHE_LIVES_ABROAD },
];

export function getPlacesSelectOptionsForCounty(countyId: string): Option[] {
    return [{ value: '', label: '', isPlaceholder: true }].concat(
        PLACES.filter((place) => place[2].toString() === countyId)
        .sort((a, b) => localeCompare(a[1], b[1]))
            .map((p) => {
                return { value: p[0].toString(), label: p[1], isPlaceholder: false };
            }),
    );
}
