// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ASK_AN_EXPERT = "Poradny";
export const BAZAAR = "Bazar";
export const BAZAAR_WATCHDOG_SETTINGS_TITLE = "Ulo\u017een\u00e1 hled\u00e1n\u00ed";
export const BLOGS_MENU_FEEDS = "Novinky";
export const BLOGS_WALL = "Ze\u010f";
export const BZ2_ADD_PRODUCT = "P\u0159idej inzer\u00e1t";
export const BZ2_FAQ = "Jak funguje bazar?";
export const BZ2_WISHLIST = "Ulo\u017een\u00e9 inzer\u00e1ty";
export const CONTESTS = "Sout\u011b\u017ee";
export const ESHOP_MENU = "E-shop";
export const FORUMS_AND_GROUPS = "Skupiny a f\u00f3ra";
export const HEADING_SELLERS = "Moji obl\u00edben\u00ed";
export const LOG_IN = "P\u0159ihlas se";
export const MAIN_MENU_REVIEWS = "Recenze";
export const MORE_WIKI_CATEGORIES = "Dal\u0161\u00ed t\u00e9mata";
export const MY_ENTRIES = "M\u016fj bazar";
export const MY_SHOPPING = "M\u00e9 n\u00e1kupy";
export const PB_MENU_FIND_MOM = "Najdi maminku";
export const PB_MENU_MY_ACTIVITY = "Zapojila jsem se";
export const REGISTER_HERE = "Zaregistruj se zde!";
export const SERVER_FULL_NAME = "Modr\u00fd kon\u00edk";
export const SERVER_SLOGAN = "Str\u00e1nka pro maminky a budouc\u00ed maminky";
export const STROLLERS_SUBMENU_ARTICLES_AND_REVIEWS = "\u010cl\u00e1nky a recenze";
export const STROLLERS_SUBMENU_COUNSELLING = "Ko\u010d\u00e1rkov\u00e1 poradna";
export const STROLLERS_SUBMENU_FORUM = "F\u00f3rum o ko\u010d\u00e1rc\u00edch";
export const STROLLERS_SUBMENU_HOW_TO_PICK = "Jak vybrat ko\u010d\u00e1rek?";
export const STROLLER_STROLLEROPEDIA = "Ko\u010d\u00e1rkopedie";
export const YOUTUBE_MK_TITLE = "YouTube Modr\u00fd kon\u00edk";
