import {
    STROLLER_FEATURE_ADAPTERS,
    STROLLER_FEATURE_ADAPTERS_CARRYCOT,
    STROLLER_FEATURE_ADJUSTABLE_HANDLE,
    STROLLER_FEATURE_ADJUSTABLE_SUSPENSION,
    STROLLER_FEATURE_ALL_WHEELS_ARE_DOUBLE,
    STROLLER_FEATURE_AUTOLOCK,
    STROLLER_FEATURE_AVAILABILITY, STROLLER_FEATURE_BABY_BLANKET,
    STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_EXTENDED,
    STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_IN_LYING_POSITION,
    STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_POSITIONED,
    STROLLER_FEATURE_BACK_SUPPORT_LENGTH_IN_CM,
    STROLLER_FEATURE_BACK_SUPPORT_POSITIONING_TYPE,
    STROLLER_FEATURE_BACK_SUPPORT_POSITIONS,
    STROLLER_FEATURE_BASE_COLOR, STROLLER_FEATURE_BASE_FOR_THE_CAR_SEAT,
    STROLLER_FEATURE_BIG_SHOPPING_BASKET,
    STROLLER_FEATURE_BOARD,
    STROLLER_FEATURE_BUCKET_SEAT,
    STROLLER_FEATURE_BUCKET_SECOND_SEAT, STROLLER_FEATURE_BUGGY_LOCK,
    STROLLER_FEATURE_BUMPER_BAR,
    STROLLER_FEATURE_BUMPER_BAR_CAN_BE_BOUGHT_SEPARATELY,
    STROLLER_FEATURE_BUMPER_BAR_CAN_BE_POSITIONED,
    STROLLER_FEATURE_BUMPER_BAR_CAN_BE_REMOVED,
    STROLLER_FEATURE_BUMPER_BAR_EQUIPPED_WITH_A_REMOVABLE_COVER,
    STROLLER_FEATURE_BUMPER_BAR_TYPE,
    STROLLER_FEATURE_CAN_BE_CONVERTED_TO_A_DOUBLE,
    STROLLER_FEATURE_CAN_BE_CONVERTED_TO_A_SINGLE,
    STROLLER_FEATURE_CAN_BE_PULLED_BEHIND,
    STROLLER_FEATURE_CAR_SEAT,
    STROLLER_FEATURE_CAR_SEAT_CAN_BE_ATTACHED, STROLLER_FEATURE_CAR_SEAT_COLOR,
    STROLLER_FEATURE_CAR_SEAT_COMPATIBILITY_MOST_BRANDS,
    STROLLER_FEATURE_CAR_SEAT_COMPATIBILITY_ROEMER,
    STROLLER_FEATURE_CAR_SEAT_HAS_TO_BE_BOUGHT,
    STROLLER_FEATURE_CARRYCOT,
    STROLLER_FEATURE_CARRYCOT_CAN_BE_ATTACHED,
    STROLLER_FEATURE_CARRYCOT_DIMS_IN_CM,
    STROLLER_FEATURE_CARRYCOT_HAS_HANDLE,
    STROLLER_FEATURE_CARRYCOT_HAS_VENTILATION,
    STROLLER_FEATURE_CARRYCOT_USABLE_IN_CAR,
    STROLLER_FEATURE_CARRYCOT_WITH_CRADLE,
    STROLLER_FEATURE_CARRYCOT_WITH_PANORAMIC_WINDOW_ON_SIDE,
    STROLLER_FEATURE_CARRYCOT_WITH_VENTILATION_WINDOW_ON_ROOF,
    STROLLER_FEATURE_CENTRAL_BRAKE_PRESENT,
    STROLLER_FEATURE_CENTRAL_BRAKE_SYSTEM,
    STROLLER_FEATURE_CHECKED_BAGGAGE,
    STROLLER_FEATURE_CLEANING_THE_STROLLER_FABRICS,
    STROLLER_FEATURE_CLOSABLE_PEEK_A_BOO_WINDOW, STROLLER_FEATURE_COCOON,
    STROLLER_FEATURE_COLOR,
    STROLLER_FEATURE_CONSTRUCTION, STROLLER_FEATURE_CONSTRUCTION_TYPE, STROLLER_FEATURE_CUPHOLDER,
    STROLLER_FEATURE_CUPHOLDER_POSSIBILITY,
    STROLLER_FEATURE_DISCONTINUED_YEAR,
    STROLLER_FEATURE_DUO_CARRYCOT,
    STROLLER_FEATURE_DUO_SEAT, STROLLER_FEATURE_EDITION,
    STROLLER_FEATURE_EXTENDABLE_HOOD,
    STROLLER_FEATURE_EXTENSION_SET,
    STROLLER_FEATURE_FOLDABLE_CARRYCOT,
    STROLLER_FEATURE_FOLDABLE_HANDRAIL,
    STROLLER_FEATURE_FOLDED_DIMENSIONS_IN_CM,
    STROLLER_FEATURE_FOLDING_SYSTEM,
    STROLLER_FEATURE_FOLDS_BY_ITSELF,
    STROLLER_FEATURE_FOLDS_WITH_BOTH_SEATS_ATTACHED,
    STROLLER_FEATURE_FOLDS_WITH_SEAT_OUTWARDS,
    STROLLER_FEATURE_FOLDS_WITH_THE_SEAT_ATTACHED,
    STROLLER_FEATURE_FOLDS_WITH_THE_SEAT_IN_REVERSE,
    STROLLER_FEATURE_FOLLOW_THE_SUN_HOOD,
    STROLLER_FEATURE_FOOTCOVER_COLOR,
    STROLLER_FEATURE_FOOTMUFF,
    STROLLER_FEATURE_FOOTSTOOL,
    STROLLER_FEATURE_FRONT_WHEEL_TYPE,
    STROLLER_FEATURE_FRONT_WHEELS_DIAMETER_IN_CM,
    STROLLER_FEATURE_FULLY_FLEDGED_SECOND_SEAT, STROLLER_FEATURE_HAND_BRAKE,
    STROLLER_FEATURE_HANDLE_COLOR,
    STROLLER_FEATURE_HANDLE_HEIGHT_IN_CM,
    STROLLER_FEATURE_HANDLE_MATERIAL,
    STROLLER_FEATURE_HARNESS_PADDING,
    STROLLER_FEATURE_HARNESS_TYPE,
    STROLLER_FEATURE_HAS_CARRY_HANDLE,
    STROLLER_FEATURE_HEIGHT_ADAPTER,
    STROLLER_FEATURE_HEIGHT_ADJUSTABLE_HARNESS,
    STROLLER_FEATURE_HEIGHT_ADJUSTABLE_HOOD,
    STROLLER_FEATURE_HEIGHT_ADJUSTABLE_SEAT,
    STROLLER_FEATURE_HIGHER_POSITIONED_CARRYCOT,
    STROLLER_FEATURE_HIGHER_SEAT_POSITION,
    STROLLER_FEATURE_HOOD_EQUIPPED_WITH_A_POCKET,
    STROLLER_FEATURE_HOOD_EQUIPPED_WITH_UV_PROTECTION,
    STROLLER_FEATURE_HOOD_EQUIPPED_WITH_VENTILATION_PANEL,
    STROLLER_FEATURE_HOOD_FEATURES_A_SUN_VISOR,
    STROLLER_FEATURE_INCLINABLE_CARRYCOT,
    STROLLER_FEATURE_LED_LIGHT,
    STROLLER_FEATURE_LED_LIGHTING,
    STROLLER_FEATURE_LEG_REST_CAN_BE_EXTENDED,
    STROLLER_FEATURE_LEG_REST_CAN_BE_POSITIONED,
    STROLLER_FEATURE_LEG_REST_POSITIONS, STROLLER_FEATURE_LINER, STROLLER_FEATURE_LINER_COLOR,
    STROLLER_FEATURE_MANUAL_BRAKE_PRESENT, STROLLER_FEATURE_MATTRESS,
    STROLLER_FEATURE_MAXIMUM_LOAD_PER_1ST_CHILD_IN_KG,
    STROLLER_FEATURE_MAXIMUM_LOAD_PER_2ND_CHILD_IN_KG,
    STROLLER_FEATURE_MAXIMUM_LOAD_PER_CHILD_IN_KG,
    STROLLER_FEATURE_MEMORY_BUTTON,
    STROLLER_FEATURE_MOSQUITO_NET,
    STROLLER_FEATURE_MOTORIZED,
    STROLLER_FEATURE_NUMBER_OF_DOUBLE_WHEELS,
    STROLLER_FEATURE_NUMBER_OF_KIDS,
    STROLLER_FEATURE_NUMBER_OF_WHEELS,
    STROLLER_FEATURE_ONE_HAND_FOLDING,
    STROLLER_FEATURE_ONE_HAND_POSITIONING,
    STROLLER_FEATURE_ORGANIZER,
    STROLLER_FEATURE_ORIGIN_COUNTRIES, STROLLER_FEATURE_PARASOL,
    STROLLER_FEATURE_PARENT_TRAY_ORGANIZER,
    STROLLER_FEATURE_PEEK_A_BOO_WINDOW_IN_THE_HOOD,
    STROLLER_FEATURE_PEEK_A_BOO_WINDOW_WITH_SILENT_CLOSURE, STROLLER_FEATURE_PHONE_HOLDER,
    STROLLER_FEATURE_PLATFORM_MATERIAL,
    STROLLER_FEATURE_POSSIBILITY_OF_TERRAIN_WHEELS,
    STROLLER_FEATURE_PRICE,
    STROLLER_FEATURE_RAINCOAT,
    STROLLER_FEATURE_REAR_WHEEL_TYPE,
    STROLLER_FEATURE_REAR_WHEELS_DIAMETER_IN_CM,
    STROLLER_FEATURE_RECOMMENDED_AGE,
    STROLLER_FEATURE_REMOVABLE_HOOD,
    STROLLER_FEATURE_RETROREFLECTOR,
    STROLLER_FEATURE_ROOF,
    STROLLER_FEATURE_ROOF_PRESENT,
    STROLLER_FEATURE_ROTATING_FIXATION_WHEELS,
    STROLLER_FEATURE_ROTATING_SEAT,
    STROLLER_FEATURE_ROTATING_SECOND_SEAT,
    STROLLER_FEATURE_SEAT_DIMS_IN_CM,
    STROLLER_FEATURE_SEAT_FABRICS_CAN_BE_TAKEN_OFF,
    STROLLER_FEATURE_SEAT_SURFACE_TOTAL_LENGTH_IN_CM,
    STROLLER_FEATURE_SEAT_UNIT_STROLLER_BODY_EQUIPPED_WITH_VENTILATION_SYSTEM,
    STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_CAN_BE_POSITIONED,
    STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_LENGTH_IN_CM,
    STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONING_TYPE,
    STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONS,
    STROLLER_FEATURE_SECOND_SEAT_BUMPER_BAR,
    STROLLER_FEATURE_SECOND_SEAT_DIMS_IN_CM,
    STROLLER_FEATURE_SECOND_SEAT_LEG_REST,
    STROLLER_FEATURE_SECOND_SEAT_ONE_HAND_POSITIONING,
    STROLLER_FEATURE_SECOND_SEAT_RECOMMENDED_AGE,
    STROLLER_FEATURE_SECOND_SEAT_ROOF_PRESENT,
    STROLLER_FEATURE_SECOND_SEAT_SURFACE_TOTAL_LENGTH_IN_CM, STROLLER_FEATURE_SETS,
    STROLLER_FEATURE_SHOPPING_BASKET,
    STROLLER_FEATURE_SHOPPING_BASKET_CAPACITY_IN_KG,
    STROLLER_FEATURE_SHOPPING_BASKET_CLOSABLE,
    STROLLER_FEATURE_SIBLINGS_TYPE, STROLLER_FEATURE_SNACK_TRAY,
    STROLLER_FEATURE_SPORTWHEELS,
    STROLLER_FEATURE_STANDING_FOLD, STROLLER_FEATURE_STANDUP_SUPPORT, STROLLER_FEATURE_STEERING_WHEEL,
    STROLLER_FEATURE_STROLLER_BABY_BAG,
    STROLLER_FEATURE_STROLLER_BAG,
    STROLLER_FEATURE_STROLLER_BODY_EQUIPPED_WITH_A_POCKET,
    STROLLER_FEATURE_STROLLER_FOOT_BAG,
    STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT,
    STROLLER_FEATURE_STROLLER_SEAT, STROLLER_FEATURE_SUN_CANOPY_COLOR,
    STROLLER_FEATURE_SUN_VISOR,
    STROLLER_FEATURE_SUPPLIED_CAPE,
    STROLLER_FEATURE_SUPPLIED_CHANGING_BAG,
    STROLLER_FEATURE_SUPPLIED_FOOTMUFF,
    STROLLER_FEATURE_SUPPLIED_MOSQUITO_NET,
    STROLLER_FEATURE_SUPPLIED_PAD,
    STROLLER_FEATURE_SUPPLIED_TRANSPORT_BAG,
    STROLLER_FEATURE_SUSPENDED_WHEELS,
    STROLLER_FEATURE_SUSPENSION,
    STROLLER_FEATURE_SUSPENSION_TYPE,
    STROLLER_FEATURE_SWING_AWAY_BUMPER_BAR,
    STROLLER_FEATURE_SWINGING, STROLLER_FEATURE_TOY,
    STROLLER_FEATURE_TWIN_CARRYCOT,
    STROLLER_FEATURE_TWO_CAR_SEATS_CAN_BE_ATTACHED, STROLLER_FEATURE_TWO_WHEEL_MODE,
    STROLLER_FEATURE_TYPE,
    STROLLER_FEATURE_TYPE_2IN1_SEAT,
    STROLLER_FEATURE_TYPE_2KOMBI,
    STROLLER_FEATURE_TYPE_OF_HANDLE_ADJUSTMENT,
    STROLLER_FEATURE_UNFOLDED_DIMENSIONS_IN_CM,
    STROLLER_FEATURE_VERTICAL_SEAT,
    STROLLER_FEATURE_WATERPROOF_HOOD,
    STROLLER_FEATURE_WEIGHT_IN_KG,
    STROLLER_FEATURE_WHEEL_COLOR,
    STROLLER_FEATURE_WHEEL_DESCRIPTION_WEB, STROLLER_FEATURE_WHEEL_MATERIAL, STROLLER_FEATURE_WHEEL_SIZE,
    STROLLER_FEATURE_WHEELS_CAN_BE_REMOVED,
    STROLLER_FEATURE_WRIST_SAFETY_STRAP,
    STROLLER_FEATURE_YOUNG_REDUCTION,
} from 'mk/autogenerated/translations/constants.882f76fd018bf824b502edaa386c03b1'

export const FEATURE_LABEL_MESSAGES = {
    adjustable_handle: STROLLER_FEATURE_ADJUSTABLE_HANDLE,
    adjustable_suspension: STROLLER_FEATURE_ADJUSTABLE_SUSPENSION,
    all_wheels_are_double: STROLLER_FEATURE_ALL_WHEELS_ARE_DOUBLE,
    autolock: STROLLER_FEATURE_AUTOLOCK,
    availability: STROLLER_FEATURE_AVAILABILITY,
    back_support_can_be_extended: STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_EXTENDED,
    back_support_can_be_in_lying_position: STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_IN_LYING_POSITION,
    back_support_can_be_in_vertical_seat_position: STROLLER_FEATURE_VERTICAL_SEAT,
    back_support_can_be_positioned: STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_POSITIONED,
    back_support_length_in_cm: STROLLER_FEATURE_BACK_SUPPORT_LENGTH_IN_CM,
    back_support_positioning_type: STROLLER_FEATURE_BACK_SUPPORT_POSITIONING_TYPE,
    back_support_positions: STROLLER_FEATURE_BACK_SUPPORT_POSITIONS, // deprecated
    back_support_positions_l: STROLLER_FEATURE_BACK_SUPPORT_POSITIONS,
    big_shopping_basket: STROLLER_FEATURE_BIG_SHOPPING_BASKET,
    bucket_seat: STROLLER_FEATURE_BUCKET_SEAT,
    bucket_second_seat: STROLLER_FEATURE_BUCKET_SECOND_SEAT,
    bumper_bar: STROLLER_FEATURE_BUMPER_BAR,
    bumper_bar_can_be_bought_separately: STROLLER_FEATURE_BUMPER_BAR_CAN_BE_BOUGHT_SEPARATELY,
    bumper_bar_can_be_positioned: STROLLER_FEATURE_BUMPER_BAR_CAN_BE_POSITIONED,
    bumper_bar_can_be_removed: STROLLER_FEATURE_BUMPER_BAR_CAN_BE_REMOVED,
    bumper_bar_equipped_with_a_removable_cover: STROLLER_FEATURE_BUMPER_BAR_EQUIPPED_WITH_A_REMOVABLE_COVER,
    bumper_bar_type: STROLLER_FEATURE_BUMPER_BAR_TYPE,
    can_be_converted_to_a_double: STROLLER_FEATURE_CAN_BE_CONVERTED_TO_A_DOUBLE,
    can_be_converted_to_a_single: STROLLER_FEATURE_CAN_BE_CONVERTED_TO_A_SINGLE,
    can_be_pulled_behind: STROLLER_FEATURE_CAN_BE_PULLED_BEHIND,
    car_seat_can_be_attached: STROLLER_FEATURE_CAR_SEAT_CAN_BE_ATTACHED,
    car_seat_compatibility_most_brands: STROLLER_FEATURE_CAR_SEAT_COMPATIBILITY_MOST_BRANDS,
    car_seat_compatibility_roemer: STROLLER_FEATURE_CAR_SEAT_COMPATIBILITY_ROEMER,
    car_seat_has_to_be_bought: STROLLER_FEATURE_CAR_SEAT_HAS_TO_BE_BOUGHT,
    carrycot_can_be_attached: STROLLER_FEATURE_CARRYCOT_CAN_BE_ATTACHED,
    carrycot_dims_in_cm: STROLLER_FEATURE_CARRYCOT_DIMS_IN_CM,
    carrycot_has_handle: STROLLER_FEATURE_CARRYCOT_HAS_HANDLE,
    carrycot_has_ventilation: STROLLER_FEATURE_CARRYCOT_HAS_VENTILATION,
    carrycot_usable_in_car: STROLLER_FEATURE_CARRYCOT_USABLE_IN_CAR,
    carrycot_with_cradle: STROLLER_FEATURE_CARRYCOT_WITH_CRADLE,
    carrycot_with_ventilation_window_on_roof: STROLLER_FEATURE_CARRYCOT_WITH_VENTILATION_WINDOW_ON_ROOF,
    carrycot_with_panoramic_window_on_side: STROLLER_FEATURE_CARRYCOT_WITH_PANORAMIC_WINDOW_ON_SIDE,
    central_brake_present: STROLLER_FEATURE_CENTRAL_BRAKE_PRESENT,
    central_brake_system: STROLLER_FEATURE_CENTRAL_BRAKE_SYSTEM,
    checked_baggage: STROLLER_FEATURE_CHECKED_BAGGAGE,
    cleaning_the_stroller_fabrics: STROLLER_FEATURE_CLEANING_THE_STROLLER_FABRICS,
    closable_peek_a_boo_window: STROLLER_FEATURE_CLOSABLE_PEEK_A_BOO_WINDOW,
    cupholder_possibility: STROLLER_FEATURE_CUPHOLDER_POSSIBILITY,
    discontinued_year: STROLLER_FEATURE_DISCONTINUED_YEAR,
    extendable_hood: STROLLER_FEATURE_EXTENDABLE_HOOD,
    foldable_carrycot: STROLLER_FEATURE_FOLDABLE_CARRYCOT,
    foldable_handrail: STROLLER_FEATURE_FOLDABLE_HANDRAIL,
    folded_dimensions_in_cm: STROLLER_FEATURE_FOLDED_DIMENSIONS_IN_CM,
    folding_system: STROLLER_FEATURE_FOLDING_SYSTEM,
    folds_by_itself: STROLLER_FEATURE_FOLDS_BY_ITSELF,
    folds_with_both_seats_attached: STROLLER_FEATURE_FOLDS_WITH_BOTH_SEATS_ATTACHED,
    folds_with_seat_outwards: STROLLER_FEATURE_FOLDS_WITH_SEAT_OUTWARDS,
    folds_with_the_seat_attached: STROLLER_FEATURE_FOLDS_WITH_THE_SEAT_ATTACHED,
    folds_with_the_seat_in_reverse: STROLLER_FEATURE_FOLDS_WITH_THE_SEAT_IN_REVERSE,
    follow_the_sun_hood: STROLLER_FEATURE_FOLLOW_THE_SUN_HOOD,
    front_wheels_diameter_in_cm: STROLLER_FEATURE_FRONT_WHEELS_DIAMETER_IN_CM,
    fully_fledged_second_seat: STROLLER_FEATURE_FULLY_FLEDGED_SECOND_SEAT,
    handle_height_in_cm: STROLLER_FEATURE_HANDLE_HEIGHT_IN_CM,
    handle_material: STROLLER_FEATURE_HANDLE_MATERIAL,
    harness_padding: STROLLER_FEATURE_HARNESS_PADDING,
    harness_type: STROLLER_FEATURE_HARNESS_TYPE,
    has_carry_handle: STROLLER_FEATURE_HAS_CARRY_HANDLE,
    height_adjustable_harness: STROLLER_FEATURE_HEIGHT_ADJUSTABLE_HARNESS,
    height_adjustable_hood: STROLLER_FEATURE_HEIGHT_ADJUSTABLE_HOOD,
    height_adjustable_seat: STROLLER_FEATURE_HEIGHT_ADJUSTABLE_SEAT,
    higher_positioned_carrycot: STROLLER_FEATURE_HIGHER_POSITIONED_CARRYCOT,
    higher_seat_position: STROLLER_FEATURE_HIGHER_SEAT_POSITION,
    hood_equipped_with_a_pocket: STROLLER_FEATURE_HOOD_EQUIPPED_WITH_A_POCKET,
    hood_equipped_with_uv_protection: STROLLER_FEATURE_HOOD_EQUIPPED_WITH_UV_PROTECTION,
    hood_equipped_with_ventilation_panel: STROLLER_FEATURE_HOOD_EQUIPPED_WITH_VENTILATION_PANEL,
    hood_features_a_sun_visor: STROLLER_FEATURE_HOOD_FEATURES_A_SUN_VISOR,
    inclinable_carrycot: STROLLER_FEATURE_INCLINABLE_CARRYCOT,
    led_lighting: STROLLER_FEATURE_LED_LIGHTING,
    leg_rest_can_be_extended: STROLLER_FEATURE_LEG_REST_CAN_BE_EXTENDED,
    leg_rest_can_be_positioned: STROLLER_FEATURE_LEG_REST_CAN_BE_POSITIONED,
    leg_rest_positions: STROLLER_FEATURE_LEG_REST_POSITIONS,
    manual_brake_present: STROLLER_FEATURE_MANUAL_BRAKE_PRESENT,
    maximum_load_per_1st_child_in_kg: STROLLER_FEATURE_MAXIMUM_LOAD_PER_1ST_CHILD_IN_KG,
    maximum_load_per_2nd_child_in_kg: STROLLER_FEATURE_MAXIMUM_LOAD_PER_2ND_CHILD_IN_KG,
    maximum_load_per_child_in_kg: STROLLER_FEATURE_MAXIMUM_LOAD_PER_CHILD_IN_KG,
    memory_button: STROLLER_FEATURE_MEMORY_BUTTON,
    motorized: STROLLER_FEATURE_MOTORIZED,
    number_of_double_wheels: STROLLER_FEATURE_NUMBER_OF_DOUBLE_WHEELS,
    number_of_kids: STROLLER_FEATURE_NUMBER_OF_KIDS,
    number_of_wheels: STROLLER_FEATURE_NUMBER_OF_WHEELS,
    number_of_wheels_l: STROLLER_FEATURE_NUMBER_OF_WHEELS,
    one_hand_folding: STROLLER_FEATURE_ONE_HAND_FOLDING,
    one_hand_positioning: STROLLER_FEATURE_ONE_HAND_POSITIONING,
    origin_countries: STROLLER_FEATURE_ORIGIN_COUNTRIES,
    parent_tray_organizer: STROLLER_FEATURE_PARENT_TRAY_ORGANIZER,
    peek_a_boo_window_in_the_hood: STROLLER_FEATURE_PEEK_A_BOO_WINDOW_IN_THE_HOOD,
    peek_a_boo_window_with_silent_closure: STROLLER_FEATURE_PEEK_A_BOO_WINDOW_WITH_SILENT_CLOSURE,
    platform_material: STROLLER_FEATURE_PLATFORM_MATERIAL,
    possibility_of_terrain_wheels: STROLLER_FEATURE_POSSIBILITY_OF_TERRAIN_WHEELS,
    price: STROLLER_FEATURE_PRICE,
    type_2in1_seat: STROLLER_FEATURE_TYPE_2IN1_SEAT,
    variant_rear_wheel_type: STROLLER_FEATURE_REAR_WHEEL_TYPE,
    variant_front_wheel_type: STROLLER_FEATURE_FRONT_WHEEL_TYPE,
    wheel_description_sk: STROLLER_FEATURE_WHEEL_DESCRIPTION_WEB,
    wheel_description_cz: STROLLER_FEATURE_WHEEL_DESCRIPTION_WEB,
    rear_wheels_diameter_in_cm: STROLLER_FEATURE_REAR_WHEELS_DIAMETER_IN_CM,
    recommended_age: STROLLER_FEATURE_RECOMMENDED_AGE,
    removable_hood: STROLLER_FEATURE_REMOVABLE_HOOD,
    retroreflector: STROLLER_FEATURE_RETROREFLECTOR,
    roof_present: STROLLER_FEATURE_ROOF_PRESENT,
    rotating_fixation_wheels: STROLLER_FEATURE_ROTATING_FIXATION_WHEELS,
    rotating_seat: STROLLER_FEATURE_ROTATING_SEAT,
    rotating_second_seat: STROLLER_FEATURE_ROTATING_SECOND_SEAT,
    seat_dims_in_cm: STROLLER_FEATURE_SEAT_DIMS_IN_CM,
    seat_fabrics_can_be_taken_off: STROLLER_FEATURE_SEAT_FABRICS_CAN_BE_TAKEN_OFF,
    seat_surface_total_length_in_cm: STROLLER_FEATURE_SEAT_SURFACE_TOTAL_LENGTH_IN_CM,
    seat_unit_stroller_body_equipped_with_ventilation_system: STROLLER_FEATURE_SEAT_UNIT_STROLLER_BODY_EQUIPPED_WITH_VENTILATION_SYSTEM,
    second_seat_back_support_can_be_positioned: STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_CAN_BE_POSITIONED,
    second_seat_back_support_length_in_cm: STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_LENGTH_IN_CM,
    second_seat_back_support_positioning_type: STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONING_TYPE,
    second_seat_back_support_positions: STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONS, // deprecated
    second_seat_back_support_positions_l: STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONS,
    second_seat_bumper_bar: STROLLER_FEATURE_SECOND_SEAT_BUMPER_BAR,
    second_seat_dims_in_cm: STROLLER_FEATURE_SECOND_SEAT_DIMS_IN_CM,
    second_seat_leg_rest: STROLLER_FEATURE_SECOND_SEAT_LEG_REST,
    second_seat_one_hand_positioning: STROLLER_FEATURE_SECOND_SEAT_ONE_HAND_POSITIONING,
    second_seat_recommended_age: STROLLER_FEATURE_SECOND_SEAT_RECOMMENDED_AGE,
    second_seat_roof_present: STROLLER_FEATURE_SECOND_SEAT_ROOF_PRESENT,
    second_seat_surface_total_length_in_cm: STROLLER_FEATURE_SECOND_SEAT_SURFACE_TOTAL_LENGTH_IN_CM,
    shopping_basket: STROLLER_FEATURE_SHOPPING_BASKET,
    shopping_basket_capacity_in_kg: STROLLER_FEATURE_SHOPPING_BASKET_CAPACITY_IN_KG,
    shopping_basket_closable: STROLLER_FEATURE_SHOPPING_BASKET_CLOSABLE,
    siblings_type: STROLLER_FEATURE_SIBLINGS_TYPE,
    snack_tray: STROLLER_FEATURE_SNACK_TRAY,
    standing_fold: STROLLER_FEATURE_STANDING_FOLD,
    standup_support: STROLLER_FEATURE_STANDUP_SUPPORT,
    stroller_for_parent_by_height: STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT,
    stroller_body_equipped_with_a_pocket: STROLLER_FEATURE_STROLLER_BODY_EQUIPPED_WITH_A_POCKET,
    supplied_cape: STROLLER_FEATURE_SUPPLIED_CAPE,
    supplied_changing_bag: STROLLER_FEATURE_SUPPLIED_CHANGING_BAG,
    supplied_footmuff: STROLLER_FEATURE_SUPPLIED_FOOTMUFF,
    supplied_mosquito_net: STROLLER_FEATURE_SUPPLIED_MOSQUITO_NET,
    supplied_pad: STROLLER_FEATURE_SUPPLIED_PAD,
    supplied_transport_bag: STROLLER_FEATURE_SUPPLIED_TRANSPORT_BAG,
    suspended_wheels: STROLLER_FEATURE_SUSPENDED_WHEELS,
    suspension: STROLLER_FEATURE_SUSPENSION,
    suspension_type: STROLLER_FEATURE_SUSPENSION_TYPE,
    swing_away_bumper_bar: STROLLER_FEATURE_SWING_AWAY_BUMPER_BAR,
    swinging: STROLLER_FEATURE_SWINGING,
    two_car_seats_can_be_attached: STROLLER_FEATURE_TWO_CAR_SEATS_CAN_BE_ATTACHED,
    two_wheel_mode: STROLLER_FEATURE_TWO_WHEEL_MODE,
    type: STROLLER_FEATURE_TYPE,
    type_2kombi: STROLLER_FEATURE_TYPE_2KOMBI,
    type_of_handle_adjustment: STROLLER_FEATURE_TYPE_OF_HANDLE_ADJUSTMENT,
    unfolded_dimensions_in_cm: STROLLER_FEATURE_UNFOLDED_DIMENSIONS_IN_CM,
    waterproof_hood: STROLLER_FEATURE_WATERPROOF_HOOD,
    weight_in_kg: STROLLER_FEATURE_WEIGHT_IN_KG,
    wheels_can_be_removed: STROLLER_FEATURE_WHEELS_CAN_BE_REMOVED,
    wrist_safety_strap: STROLLER_FEATURE_WRIST_SAFETY_STRAP,
    adapters: STROLLER_FEATURE_ADAPTERS,
    adapters_carrycot: STROLLER_FEATURE_ADAPTERS_CARRYCOT,

    // CONFIGURABLE FEATURES
    baby_blanket: STROLLER_FEATURE_BABY_BLANKET,
    base_color: STROLLER_FEATURE_BASE_COLOR,
    base_for_the_car_seat: STROLLER_FEATURE_BASE_FOR_THE_CAR_SEAT,
    board: STROLLER_FEATURE_BOARD,
    buggy_lock: STROLLER_FEATURE_BUGGY_LOCK,
    car_seat: STROLLER_FEATURE_CAR_SEAT,
    car_seat_color: STROLLER_FEATURE_CAR_SEAT_COLOR,
    // bumper_bar (listed above)
    carrycot: STROLLER_FEATURE_CARRYCOT,
    cocoon: STROLLER_FEATURE_COCOON,
    color: STROLLER_FEATURE_COLOR,
    construction: STROLLER_FEATURE_CONSTRUCTION,
    construction_type: STROLLER_FEATURE_CONSTRUCTION_TYPE,
    cupholder: STROLLER_FEATURE_CUPHOLDER,
    duo_carrycot: STROLLER_FEATURE_DUO_CARRYCOT,
    duo_seat: STROLLER_FEATURE_DUO_SEAT,
    edition: STROLLER_FEATURE_EDITION,
    extension_set: STROLLER_FEATURE_EXTENSION_SET,
    footcover_color: STROLLER_FEATURE_FOOTCOVER_COLOR,
    footmuff: STROLLER_FEATURE_FOOTMUFF,
    footstool: STROLLER_FEATURE_FOOTSTOOL,
    hand_brake: STROLLER_FEATURE_HAND_BRAKE,
    handle_color: STROLLER_FEATURE_HANDLE_COLOR,
    height_adapter: STROLLER_FEATURE_HEIGHT_ADAPTER,
    led_light: STROLLER_FEATURE_LED_LIGHT,
    liner: STROLLER_FEATURE_LINER,
    liner_color: STROLLER_FEATURE_LINER_COLOR,
    mattress: STROLLER_FEATURE_MATTRESS,
    mosquito_net: STROLLER_FEATURE_MOSQUITO_NET,
    organizer: STROLLER_FEATURE_ORGANIZER,
    parasol: STROLLER_FEATURE_PARASOL,
    phone_holder: STROLLER_FEATURE_PHONE_HOLDER,
    raincoat: STROLLER_FEATURE_RAINCOAT,
    roof: STROLLER_FEATURE_ROOF,
    sets: STROLLER_FEATURE_SETS,
    sportwheels: STROLLER_FEATURE_SPORTWHEELS,
    steering_wheel: STROLLER_FEATURE_STEERING_WHEEL,
    stroller_baby_bag: STROLLER_FEATURE_STROLLER_BABY_BAG,
    stroller_bag: STROLLER_FEATURE_STROLLER_BAG,
    stroller_foot_bag: STROLLER_FEATURE_STROLLER_FOOT_BAG,
    stroller_seat: STROLLER_FEATURE_STROLLER_SEAT,
    sun_canopy_color: STROLLER_FEATURE_SUN_CANOPY_COLOR,
    sun_visor: STROLLER_FEATURE_SUN_VISOR,
    toy: STROLLER_FEATURE_TOY,
    twin_carrycot: STROLLER_FEATURE_TWIN_CARRYCOT,
    wheel_color: STROLLER_FEATURE_WHEEL_COLOR,
    wheel_material: STROLLER_FEATURE_WHEEL_MATERIAL,
    wheel_size: STROLLER_FEATURE_WHEEL_SIZE,
    young_reduction: STROLLER_FEATURE_YOUNG_REDUCTION,
};
