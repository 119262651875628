import { ERROR_COULD_NOT_PERFORM_ACTION } from 'mk/autogenerated/translations/ServerErrorToast.dfea8d3b5b9c038dbe8763abc4cf627a'
import { Toast } from 'mk2/components/toasts/Toast';
import React from 'react';

export interface OwnProps {
    remove();
}

type Props = OwnProps;

export const ServerErrorToast: React.StatelessComponent<Props> = ({ remove }) => (
    <Toast type="error" remove={remove}>
        {ERROR_COULD_NOT_PERFORM_ACTION}
    </Toast>
);
