import {
    WEBPUSH_NOTIFICATIONS_SUBSCRIBED_DESKTOP,
    WEBPUSH_NOTIFICATIONS_SUBSCRIBED_MOBILE,
} from 'mk/autogenerated/translations/WebpushNotifications.sagas.c7126bd84b8baa50ddebcfac213aad88'
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { isMqMobile } from 'mk/common/responsive';
import { WEBPUSH_PUBLIC_KEY } from 'mk/settings';
import { users_api_webpush_disable_url, users_api_webpush_subscribe_url } from 'mk/urls/functions';
import { LOGIN } from 'mk2/actions';
import {
    webpushNotificationsDisable,
    webpushNotificationsSubscribe,
    webpushNotificationsSubscribed,
    WebpushNotificationsDisableAction,
    WebpushNotificationsSubscribeAction,
    WEBPUSH_NOTIFICATIONS_DISABLE,
    WEBPUSH_NOTIFICATIONS_SUBSCRIBE,
} from 'mk2/containers/WebpushNotifications/WebpushNotifications.actions';
import { XHRAction } from 'mk2/helpers/api';
import { showSuccessToast } from 'mk2/helpers/toasts';
import { getLogger } from 'mk2/logger';
import { getRequestUser } from 'mk2/reducers';
import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

const logger = getLogger('containers/WebpushNotifications/WebpushNotifications.sagas');

function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function areKeysEqual(a: Uint8Array, b: Uint8Array) {
    if (a.byteLength !== b.byteLength) {
        return false;
    }

    for (let i = 0; i !== a.byteLength; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

function* register() {
    // Only for browsers
    if (typeof window === 'undefined') {
        // ignore
        return;
    }

    const requestUser = yield select(getRequestUser);
    if (!isAuthenticated(requestUser)) {
        // Wait for login
        yield take(LOGIN);
    }

    // Feature detection
    if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        yield put(webpushNotificationsDisable(true));
        return;
    }

    if (!('PushManager' in window)) {
        // Push isn't supported on this browser, disable or hide UI.
        yield put(webpushNotificationsDisable(true));
        return;
    }

    if (Notification.permission === 'granted') {
        // User already allowed notifications
        yield put(webpushNotificationsSubscribe(true));
    } else if (Notification.permission === 'denied') {
        yield put(webpushNotificationsDisable(true));
    }
}

interface SubscribedApiResponse {
    body: {
        token: string;
    };
}

function* subscribe({ xhr, automatic }: WebpushNotificationsSubscribeAction & XHRAction) {
    try {
        if (Notification.permission === 'denied') {
            // User already denied notifications
            return;
        }

        // Display prompt
        if (Notification.permission === 'default') {
            const permissionResult = yield call(() => Notification.requestPermission());
            if (permissionResult !== 'granted') {
                yield put(webpushNotificationsDisable(true));
                return;
            }
        }

        // Subscribe user
        const swRegistration = yield call(() => navigator.serviceWorker.getRegistration());
        if (!swRegistration) {
            // SW is not registered :(
            return;
        }

        let subscription = yield call(() => swRegistration.pushManager.getSubscription());
        const applicationServerKey = urlBase64ToUint8Array(WEBPUSH_PUBLIC_KEY);
        // In case we changed the webpush key - unsubscribe and then subscribe with new key
        if (subscription && subscription.options && subscription.options.applicationServerKey) {
            if (!areKeysEqual(new Uint8Array(subscription.options.applicationServerKey), applicationServerKey)) {
                yield call(() => subscription.unsubscribe());
                subscription = null;
            }
        }

        if (!subscription) {
            const options = {
                userVisibleOnly: true,
                applicationServerKey,
            };

            subscription = yield call(() => swRegistration.pushManager.subscribe(options));
        }

        const response: SubscribedApiResponse = yield call(() =>
            xhr.post(users_api_webpush_subscribe_url(), {
                vapid: subscription.toJSON(),
            }),
        );

        yield put(webpushNotificationsSubscribed(response.body.token));

        if (!automatic) {
            yield showSuccessToast(
                isMqMobile() ? WEBPUSH_NOTIFICATIONS_SUBSCRIBED_MOBILE : WEBPUSH_NOTIFICATIONS_SUBSCRIBED_DESKTOP,
            );
        }
    } catch (err) {
        // TODO: Display error toast or some error UI
        logger.error(err);
    }
}

function* disable({ xhr, automatic }: WebpushNotificationsDisableAction & XHRAction) {
    try {
        if (!automatic) {
            // User manually disable notifications - send request to server
            yield call(() => xhr.post(users_api_webpush_disable_url(), {}));
        }
    } catch (err) {
        // TODO: Display error toast or some error UI
        logger.error(err);
    }
}

export default function* root() {
    yield all([
        takeEvery(WEBPUSH_NOTIFICATIONS_SUBSCRIBE, subscribe),
        takeEvery(WEBPUSH_NOTIFICATIONS_DISABLE, disable),
        fork(register),
    ]);
}
