/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp12 } from './commonProp12';
import { commonProp4 } from './commonProp4';

export const fashion_accessories_handbags : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BIG_HANDBAGS", "Velk\u00e9 kabelky a ta\u0161ky, shopper"],
        ["SHOULDER_HANDBAGS", "Kabelky na rameno, crossbody"],
        ["HANDBAGS", "Kabelky do ruky"],
        ["SMALL_HANDBAGS", "Psan\u00ed\u010dka a mal\u00e9 kabelky"],
        ["TEXTILE_BAGS", "Textiln\u00ed ta\u0161ky"],
        ["HANDBAG_ACCESSORIES", "P\u0159\u00edslu\u0161enstv\u00ed"],
        ["FANNY_PACK", "Ledvinka"],
        ["BABY_HANDYBAG", "D\u011btsk\u00e1 ta\u0161ti\u010dka, kabelka"],
        ["BABY_SUITCASE", "Kuf\u0159\u00edk"],
        ["SUITCASE", "Cestovn\u00ed kufry, ta\u0161ky"],
        ["RUCKSACK", "Batoh"],
        ["SETS", "Kompletn\u00ed sady"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp12,
    p_color: commonProp4,
};
