/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const gifts : {[key: string]: Array<[string, string]>} = {
    p_occasion: [
        ["CHILDBIRTH", "Narozen\u00ed d\u00edt\u011bte"],
        ["ALBUM", "Alba"],
        ["BAPTISM", "K\u0159est"],
        ["COMMUNION", "P\u0159ij\u00edm\u00e1n\u00ed, konfirmace"],
        ["BIRTHDAY", "Narozeniny"],
        ["WEDDING", "Svatba"],
        ["WEDDING_ANNIVERSARY", "V\u00fdro\u010d\u00ed svatby"],
        ["IN_LOVE", "Pro zamilovan\u00e9"],
        ["GIFT_WRAP", "Obaly na d\u00e1rky"],
        ["GIFTS_OTHER", "Jin\u00e9"],
    ],
    p_sex: [
        ["GIRLS", "Pro holky"],
        ["BOYS", "Pro kluky"],
        ["KIDS", "Pro d\u00edt\u011b (unisex)"],
        ["FAMILY", "Pro rodinu"],
        ["COUPLE", "Pro p\u00e1r"],
        ["WOMENS", "Pro \u017eeny"],
        ["MENS", "Pro mu\u017ee"],
        ["ADULTS", "Pro dosp\u011bl\u00e9"],
    ],
};
