/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const floorball_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("adidas"),
        d("arcore"),
        d("aress"),
        d("axis"),
        d("boma"),
        d("compressport"),
        d("craft"),
        d("etape"),
        d("finmark"),
        d("kama"),
        d("kensis"),
        d("klimatex"),
        d("lewro"),
        d("mizuno"),
        d("nike"),
        d("northfinder"),
        d("oneil"),
        d("puma"),
        d("reebok"),
        d("runto"),
        d("scott"),
        d("Sedco"),
        d("swix"),
        d("ulvang"),
        d("umbro"),
        d("under armour"),
        d("willard"),
        d("wilson"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
