/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp16 } from './commonProp16';
import { commonProp4 } from './commonProp4';

export const respirators : {[key: string]: Array<[string, string]>} = {
    p_for_whom: commonProp16,
    p_color: commonProp4,
};
