/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const twins : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["UNDERWEAR", "Spodn\u00ed pr\u00e1dlo"],
        ["SWIMWEAR_DRESSING_GOWNS", "Plavky, \u017eupany"],
        ["SOCKS_TIGHTS", "Pono\u017eky, pun\u010doch\u00e1\u010de, n\u00e1vleky"],
        ["BODYSUITS_SLEEPSUITS_ROMPERS", "Dupa\u010dky, body"],
        ["TSHIRTS_TOPS", "Tri\u010dka, t\u00edlka (ne spodn\u00ed pr\u00e1dlo)"],
        ["BLOUSE_SHIRTS", "Ko\u0161ile, bl\u016fzky"],
        ["DRESSES_SKIRTS", "Sukni\u010dky, \u0161ati\u010dky"],
        ["TROUSERS_JEANS_JOGGERS", "Kalhoty, d\u017e\u00edny, tepl\u00e1ky"],
        ["SHORTS_CROPS", "\u0160ortky, t\u0159\u00ed\u010dtvr\u0165\u00e1ky"],
        ["OUTFITS", "Komplety, soupravy (ne zimn\u00ed)"],
        ["SWEATSHIRTS_JUMPERS_VESTS", "Mikiny, svetry, bolerka"],
        ["JACKETS_COATS", "Bundy, kab\u00e1ty, saka"],
        ["SNOWSUITS", "Zimn\u00ed kombin\u00e9zy a oteplov\u00e1ky"],
        ["HATS_MITTENS_SCARVES", "\u010cepice, rukavice, \u0161\u00e1ly"],
        ["SLEEPWEAR", "Py\u017eama"],
        ["SLEEPING_BAGS", "Spac\u00ed pytle"],
        ["FOOTWEAR", "Obuv"],
        ["MEGAPACKS", "Bal\u00edk oble\u010den\u00ed"],
    ],
    p_size: [
        d("<50"),
        d("50"),
        d("56"),
        d("62"),
        d("68"),
        d("74"),
        d("80"),
        d("86"),
        d("92"),
        d("98"),
        d("104"),
        d("110"),
        d("116"),
        d("122"),
        d("128"),
        d("134"),
        d("140"),
        d("146"),
        d("152"),
        d("158"),
        d("164"),
        d("170"),
        d("176"),
        d("182"),
        d("188"),
        d("17"),
        d("18"),
        d("19"),
        d("20"),
        d("21"),
        d("22"),
        d("23"),
        d("24"),
        d("25"),
        d("26"),
        d("27"),
        d("28"),
        d("29"),
        d("30"),
        d("31"),
        d("32"),
        d("33"),
        d("34"),
        d("35"),
        d("36"),
        d("37"),
        d("38"),
        d("39"),
        d("40"),
    ],
    p_sex_twin: [
        ["GIRLS", "Pro holky"],
        ["BOYS", "Pro kluky"],
        ["GIRLBOY", "Chlapec a d\u011bv\u010de"],
    ],
    p_color: commonProp4,
};
