import { mailBoxUrl, mailComposeUrl, mailDetailInFolderUrl, mailDetailUrl, mailUrl } from 'mk2/apps/mail/urls';
import { Route } from 'mk2/containers/Application/Application';
import { url } from 'mk2/helpers/urls';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [{
    app: 'mail',
    exact: true,
    path: mailUrl,
    page: new RedirectPage<{folder: string}>({
        to: (props) => url(mailBoxUrl, {username: props.requestUser.username, folder: 'inbox'}),
        permanent: true,
        authOnly: true,
    }),
}, {
    app: 'mail',
    exact: true,
    path: '/mail/:folder(inbox|outbox|starred)/',
    page: new RedirectPage<{folder: string}>({
        to: (props) => url(mailBoxUrl, {username: props.requestUser.username, folder: props.match.params.folder}),
        permanent: true,
        authOnly: true,
    }),
}, {
    app: 'mail',
    exact: true,
    path: mailComposeUrl,
    getPage: () => {
        return import('mk2/apps/mail/containers/MailCompose/MailComposePage' /* webpackChunkName: "mail.MailCompose" */);
    },
    modules: [ 'mk2/apps/mail/containers/MailCompose/MailComposePage' ],
}, {
    app: 'mail',
    exact: true,
    path: mailBoxUrl,
    getPage: () => {
        return import('mk2/apps/mail/containers/MailBox/MailBoxPage' /* webpackChunkName: "mail.MailBox" */);
    },
    modules: [ 'mk2/apps/mail/containers/MailBox/MailBoxPage' ],
}, {
    app: 'mail',
    exact: true,
    path: mailDetailInFolderUrl,
    getPage: () => {
        return import('mk2/apps/mail/containers/MailDetail/MailDetailPage' /* webpackChunkName: "mail.MailDetail" */);
    },
    modules: [
        'mk2/apps/mail/containers/MailDetail/MailDetailPage',
        'mk2/apps/mail/containers/MailCompose/MailComposePage',
    ],
}, {
    app: 'mail',
    exact: true,
    path: mailDetailUrl,
    getPage: () => {
        return import('mk2/apps/mail/containers/MailDetail/MailDetailPage' /* webpackChunkName: "mail.MailDetail" */);
    },
    modules: [
        'mk2/apps/mail/containers/MailDetail/MailDetailPage',
        'mk2/apps/mail/containers/MailCompose/MailComposePage',
    ],
}];
