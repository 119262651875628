import { DeletionTargetType } from 'mk2/containers/Deletion/Deletion.schemas';
import { getDeletionCreateDeleteAction } from 'mk2/containers/Deletion/Deletion.selectors';
import { getLogger } from 'mk2/logger';
import React from 'react';
import { reset, stopSubmit } from 'redux-form';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
    deletionHideForm,
    CreateDeleteActionFunction,
    DeletionExecuteAction,
    DeletionStopSubmitAction,
    DELETION_CLEANUP,
    DELETION_EXECUTE,
    DELETION_STOP_SUBMIT,
} from './Deletion.actions';
import { generateFormName } from './Deletion.helpers';

const logger = getLogger('Deletion.sagas');

export function* stopSubmitDeletion({ entity, id }: DeletionStopSubmitAction) {
    const formName = generateFormName(entity, id);

    // Stop submit
    yield put(stopSubmit(formName));
}

export function* cleanupDeletion({ entity, id }: DeletionExecuteAction) {
    const formName = generateFormName(entity, id);

    // Hide the deletion form
    yield put(deletionHideForm(entity, id));

    // Stop submit
    yield put(stopSubmit(formName));

    // Reset form
    yield put(reset(formName));
}

export function* executeDeletion({ entity, id, deletion }: DeletionExecuteAction) {
    const createDeleteAction: CreateDeleteActionFunction = yield select(getDeletionCreateDeleteAction, entity as DeletionTargetType, id);
    const deleteAction = createDeleteAction(deletion);
    yield put(deleteAction);
}

export default function* root() {
    yield all([
        takeEvery(DELETION_CLEANUP, cleanupDeletion),
        takeEvery(DELETION_EXECUTE, executeDeletion),
        takeEvery(DELETION_STOP_SUBMIT, stopSubmitDeletion),
    ]);
}
