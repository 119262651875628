/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const horseback_riding_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("AA"),
        d("Animo"),
        d("Cavalliero"),
        d("Cavallo"),
        d("Dainesse"),
        d("Daslo"),
        d("Easy Rider"),
        d("Eqode"),
        d("Equi-Th\u00e9me"),
        d("Euro-Star"),
        d("Harcour"),
        d("HKM"),
        d("Horse Pilot"),
        d("Horseware"),
        d("HV Polo"),
        d("Imperial Riding"),
        d("Kingsland"),
        d("Kingston"),
        d("Samshield"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
