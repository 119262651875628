/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp12 } from './commonProp12';
import { commonProp4 } from './commonProp4';

export const fashion_accessories_accessories : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["WATCHES", "Hodinky"],
        ["WALLET", "Pen\u011b\u017eenka"],
        ["BELT", "Opasek"],
        ["UMBRELLA", "De\u0161tn\u00edk"],
        ["CUFFLINKS", "Man\u017eetov\u00e9 knofl\u00ed\u010dky"],
        ["BOW_TIE", "Mot\u00fdlek"],
        ["NECKTIE", "Kravata"],
        ["SUSPENDER", "K\u0161andy"],
        ["HANDKERCHIEF", "Kapesn\u00edk"],
        ["EYEGLASSES", "Br\u00fdle- dioptrick\u00e9"],
        ["SUNGLASSES", "Br\u00fdle- slune\u010dn\u00ed"],
        ["GLASSES_CASE", "Pouzdro na br\u00fdle"],
        ["COVER_FOR_DOCUMENTS", "Obal na doklady"],
        ["KEYCHAIN_PENDANT", "Kl\u00ed\u010denka, p\u0159\u00edv\u011bsek"],
        ["BADGE", "Odznak"],
        ["ADULTS_FANS", "V\u011bj\u00ed\u0159"],
        ["HEADBAND", "\u010celenka"],
        ["HAIR_CLIP", "Sponka"],
        ["RUBBER_BAND", "Gumi\u010dka do vlas\u016f"],
        ["GRIPPER", "Sk\u0159ipec do vlas\u016f"],
        ["SETS", "Kompletn\u00ed sady"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp12,
    p_color: commonProp4,
};
