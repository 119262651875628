/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp17: Array<[string, string]> = [
    ["CZECH", "\u010desk\u00fd"],
    ["SLOVAK", "slovensk\u00fd"],
    ["ENGLISH", "anglick\u00fd"],
    ["FRENCH", "francouzsk\u00fd"],
    ["ITALIAN", "italsk\u00fd"],
    ["HUNGARIAN", "ma\u010farsk\u00fd"],
    ["GERMAN", "n\u011bmeck\u00fd"],
    ["POLISH", "polsk\u00fd"],
    ["RUSSIAN", "rusk\u00fd"],
    ["SPANISH", "\u0161pan\u011blsk\u00fd"],
    ["OTHER_LANGUAGE", "jin\u00fd jazyk"],
    ["NO_LANGUAGE", "beze slov"],
];
