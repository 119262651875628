import { ID_RE, USERNAME } from 'mk2/helpers/urls';

const PAYMENT_ID = `:paymentId(${ID_RE})`;
const PAYMENT_TYPE = `:paymentType(card|sms)`;

export const planGetPlusUrl = '/get-plus/';
export const planGetProUrl = '/get-pro/';
export const planChoosePlanUrl = '/plan/choose/';
export const planPlanForUrl = `/plan/for/${USERNAME}/`;
export const cardPaymentUrl = `/payment/card/`;
export const paymentSuccessUrl =  `/payment/success/${PAYMENT_TYPE}/${PAYMENT_ID}/`;
export const paymentFailureUrl =  `/payment/failure/${PAYMENT_TYPE}/${PAYMENT_ID}/`;
export const smsPaymentUrl = `/payment/sms/`;
