/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp8 } from './commonProp8';
import { commonProp0 } from './commonProp0';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const overalls : {[key: string]: Array<[string, string]>} = {
    p_usage: commonProp8,
    p_sleeve: commonProp0,
    p_size: [
        d("86"),
        d("92"),
        d("98"),
        d("104"),
        d("110"),
        d("116"),
        d("122"),
        d("128"),
        d("134"),
        d("140"),
        d("146"),
        d("152"),
        d("158"),
        d("164"),
        d("170"),
        d("176"),
        d("182"),
        d("188"),
        d("XS"),
        d("S"),
        d("M"),
        d("L"),
        d("XL"),
        d("XXL"),
        d("XXXL"),
        d("4XL"),
        d("5XL"),
        d("24"),
        d("26"),
        d("28"),
        d("30"),
        d("32"),
        d("34"),
        d("35"),
        d("36"),
        d("37"),
        d("38"),
        d("39"),
        d("40"),
        d("41"),
        d("42"),
        d("43"),
        d("44"),
        d("45"),
        d("46"),
        d("47"),
        d("48"),
        d("49"),
        d("50"),
        d("52"),
        d("54"),
        d("56"),
        d("58"),
        d("60"),
        d("62"),
        ["ONE_SIZE_FIT_ALL", "Univerz\u00e1ln\u00ed velikost"],
    ],
    p_length: [
        ["SHORT_LEG_LENGTH", "Kr\u00e1tk\u00e9"],
        ["BELOW_KNEES_LENGTH", "Pod kolena"],
        ["LONG_LEG_LENGTH", "Dlouh\u00e9"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
