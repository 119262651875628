/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp20: Array<[string, string]> = [
    ["GASOLINE", "benz\u00edn"],
    ["DIESEL", "nafta"],
    ["HYBRID_VEHICLE", "hybrid"],
    ["ELECTRIC_VEHICLE", "elektromobil"],
    d("LPG"),
    d("CNG"),
    ["HYDROGEN", "vod\u00edk"],
];
