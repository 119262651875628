import cx from 'classnames';
import { Location } from 'history';
import { hasAds } from 'mk/bazaar/common/userUtils';
import { AdSlot } from 'mk2/containers/AdSlot/AdSlot';
import { supportsPassive } from 'mk2/helpers/detects';
import { HalfpageProps } from 'mk2/pages/Page';
import { UserEntity } from 'mk2/schemas';
import React from 'react';
import styles from './AdSlotSticky.mscss';

export interface OwnProps extends HalfpageProps {
    requestUser: UserEntity;
    location: Location;

    // how many pixel bellow top edge of browser window should be the AdSlot
    topFixedPos?: number;

    placement?: 'left' | 'right';

    // signals that change to this prop might cause reflow (resize)
    // of bounding box. This component has to adapt by recomputing
    // its sticky class with updatePosition()
    reflowTrigger?();
}

interface OwnState {
    adSlotStyle: {
        position: 'absolute' | 'fixed';
        visibility?: string;
        top?: number;
        bottom?: number;
    };
}

type Props = OwnProps;

/**
 * Ad slot, where banner is floating (sticky on screen) and INSIDE of main page content.
 *
 * Banner position is either 'fixed' (attached to browser top) or 'absolute' (attached to
 * parent's container top or bottom).
 *
 * Absolute positioning recovers from page-reflows better than algorithm in AdSlotOffsite.
 * When other elements on page are resized, e.g. on page-load the 'fixed' positioning has
 * to be recalculated, but AdSlotOffsite does not always receive event about that. And then
 * the banner is positioned incorrectly.
 */
export class AdSlotSticky extends React.PureComponent<Props, OwnState> {

    public static defaultProps: Partial<OwnProps> = {
        topFixedPos: 10,
        placement: 'right',
    };

    private static scrollTop() {
        return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    }

    // get Top/Bottom position of element according to browser viewport
    private static positionInViewport(elem) {
        const box = elem.getBoundingClientRect();
        const body = document.body;
        const docElem = document.documentElement;

        const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
        const clientTop = docElem.clientTop || body.clientTop || 0;

        const top = box.top + scrollTop - clientTop;
        const bottom = box.bottom + scrollTop - clientTop;

        return {
            top: Math.round(top),
            bottom: Math.round(bottom),
        };
    }

    private adSlot = React.createRef<HTMLDivElement>();
    private container = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
        this.state = {adSlotStyle: {
            // 11.1.2017
            // CSS3 uz ma position:'sticky', ktore riesi ten isty usecase
            // ako AdSlotSticky.
            // Browsery ho ale este nepodporuju, preto to iste simulujeme cez
            // positin:'fixed' a javascript
            position: 'absolute',
            top: 0,

            // aby nebolo vidno ako element skoci hore,
            // ak je stranka pri page-reload odscrollovana
            visibility: 'hidden',
        }};
    }

    public render() {
        const { requestUser, placement, zone, targeting, location } = this.props;

        if (!hasAds(requestUser)) {
            return null;
        }

        return (
            <div className={styles.AdSlotSticky} ref={this.container}>
                <AdSlot
                    className={cx(styles.AdSlotSticky__slot, styles[`AdSlotSticky__slot--${placement}`])}
                    slotRef={this.adSlot}
                    zone={zone}
                    targeting={targeting}
                    style={this.state.adSlotStyle}
                    location={location}
                />
            </div>
        );
    }

    public componentDidMount() {
        this.updatePosition();

        // Update position of AdSlot if this container has been scrolled
        // or browser window has been resized
        window.addEventListener('scroll', this.updatePosition, (supportsPassive() ? { passive: true, capture: false } : false) as any);
        window.addEventListener('resize', this.updatePosition, (supportsPassive() ? { passive: true, capture: false } : false) as any);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.updatePosition, false);
        window.removeEventListener('resize', this.updatePosition, false);
    }

    public UNSAFE_componentWillReceiveProps() {
        // Recompute position of AdSlot. Called if reflowTrigger has changed.
        this.updatePosition();
    }

    private updatePosition = () => {
        if (!this.container.current || !this.adSlot.current) {
            return;
        }

        const cbox = AdSlotSticky.positionInViewport(this.container.current);
        const zbox = AdSlotSticky.positionInViewport(this.adSlot.current);

        const scrollTop = AdSlotSticky.scrollTop();
        // position of the element if it would be set to 'position: fixed' now
        const fixedTop = scrollTop + this.props.topFixedPos;

        let ztop;
        if (fixedTop <= cbox.top) {
            // top of zbox would be above cbox, so attach to top
            ztop = cbox.top - scrollTop;
            this.setState({
                adSlotStyle: {
                    position: 'absolute',
                    top: 0,
                    bottom: null,
                },
            });
        } else if (fixedTop + (zbox.bottom - zbox.top) >= cbox.bottom) {
            // bottom of zbox would be below cbox, so attach to bottom
            // ztop = cbox.bottom - (zbox.bottom - zbox.top) - scrollTop;
            this.setState({
                adSlotStyle: {
                    position: 'absolute',
                    top: null,
                    bottom: 0,
                },
            });
        } else {
            // keep zbox inside of cbox, 10px (topFixedPos px) below top
            // of browser window
            ztop = this.props.topFixedPos;
            this.setState({
                adSlotStyle: {
                    position: 'fixed',
                    top: ztop,
                    bottom: null,
                },
            });
        }
    };
}
