import { ConnectedRouter } from 'connected-react-router';
import { isMqMobile } from 'mk/common/responsive';
import {
    getIgnoreOldBrowserWarningCookie,
    getLastKnownUsernameCookie,
    getNewGroupsCookie,
} from 'mk2/apps/users/utils';
import { ErrorBoundary } from 'mk2/components/ErrorBoundary';
import { Route } from 'mk2/containers/Application/Application';
import { deprecatedParseUserAgent } from 'mk2/helpers/deprecated';
import { getLogger } from 'mk2/logger';
import { AppState } from 'mk2/reducers';
import { sentrySetRequestUser } from 'mk2/sentry';
import { initBrowserHistory } from 'mk2/services/browserHistory';
import { initJam } from 'mk2/services/jam';
import { initStore } from 'mk2/store';
import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router';

const logger = getLogger('helpers/init');

export async function appInit(
    AppComponent: React.ComponentType<any>,
    routes: Route[],
    rootSagas: any,
) {
    const initialState: Partial<AppState> = typeof window !== 'undefined' ? (window as any).INITIAL_STATE : {};

    if (initialState.request && initialState.request.user) {
        sentrySetRequestUser(initialState.request.user);
    }

    // revert eventual replaced /<\/?script>/ within the INITIAL_STATE (eg. blog article's scripts field)
    revertReplacedScriptTagOfASafeMKScript(initialState);

    const mobile = isMqMobile();

    if (!initialState.request || !initialState.request.userAgent) {
        logger.warn('Missing user agent data');
    }

    if (initialState.request && initialState.request.device
        && (mobile !== initialState.request.device.mobile)) {
        logger.warn('Device detection from server is different from client', { server: initialState.request.device, client: { mobile } });
    }

    // Detect mobile / desktop
    initialState.request = {
        ...initialState.request,
        userAgent: deprecatedParseUserAgent(navigator.userAgent), // iba pre safety, moze sa zrusit ak nebudu ziadne 'Missing user agent data' warnings
        device: {
            ...(initialState.request && initialState.request.device),
            mobile,
        },
        lastKnownUsername: getLastKnownUsernameCookie(),
        newGroupsEnabled: getNewGroupsCookie(),
        hasIgnoredOldBrowserWarning: getIgnoreOldBrowserWarningCookie(),
    };

    // Set cookie, so that we can use it during server side rendering
    document.cookie = `ssr_device=${mobile ? '10' : '01'}; path=/`;

    const history = initBrowserHistory();

    const store = initStore(history, rootSagas, initialState);

    initJam(store.dispatch);

    const render = (Component) =>
        ReactDOM.hydrate(
            <Provider store={store}>
                {/* provider needs the unbound version of i18next (see exports in i18next/ws/index.js) */}
                <ConnectedRouter history={history}>
                    <ErrorBoundary>
                        <Component />
                    </ErrorBoundary>
                </ConnectedRouter>
            </Provider>,
            document.getElementById('app'),
    );

    // Wait for current route to load
    // 1. Find current page
    let current: Route;
    for (const route of routes) {
        const { path, exact, strict } = route;
        const match = matchPath(history.location.pathname, { path, strict, exact });
        if (match) {
            current = route;
            break;
        }
    }
    // 2. Load reducers and sagas to store
    if (current) {
        if (!current.page && 'modules' in current) {
            const { default: Page } = await current.getPage();
            current.page = new Page();
        }
        await current.page.registerInStore(store);
    }

    // Wait for components load
    await Loadable.preloadReady();

    if (process.env.NODE_ENV === 'development') {
        const whyDidYouRender = require('@welldone-software/why-did-you-render');
        whyDidYouRender(React);
    }

    render(AppComponent);

    return render;
}

export function revertReplacedScriptTagOfASafeMKScript(jsonNode) {
    if (Array.isArray(jsonNode)) {
        for (let idx = 0; idx < jsonNode.length; idx++) {
            const retVal = revertReplacedScriptTagOfASafeMKScript(jsonNode[idx]);
            if (retVal) {
                jsonNode[idx] = retVal;
            }
        }
    } else if (typeof jsonNode === 'object') {
        if (jsonNode) {
            Object.keys(jsonNode).forEach((idx) => {
                const retVal = revertReplacedScriptTagOfASafeMKScript(jsonNode[idx]);
                if (retVal) {
                    jsonNode[idx] = retVal;
                }
            });
        }
    } else {
        if (typeof jsonNode === 'string') {
            if (jsonNode.indexOf('replaced_script_tag_of_a_safe_mk_script') >= 0) {
                return jsonNode.replace(/(<\/?)(replaced_script_tag_of_a_safe_mk_script>)/ig, '$1script>');
            }
        }
    }
    return null;
}
