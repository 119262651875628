import uniq from 'lodash-es/uniq';
import {
    ForumHeaderUpdateAction,
    ForumReadFeedSaveRequestAction,
    ForumUpdateComplaintsStatisticsAction,
    FORUM_HEADER_UPDATE,
    FORUM_READ_FEED_REQUEST,
    FORUM_UPDATE_COMPLAINTS_STATISTICS,
} from 'mk2/apps/forum/actions';
import { ArchivedCampaignsState } from 'mk2/apps/forum/containers/ArchivedCampaigns/ArchivedCampaigns.reducers';
import { CommentEditsState } from 'mk2/apps/forum/containers/CommentEdit/CommentEdit.reducers';
import { CommonPlaceTopicsPageState } from 'mk2/apps/forum/containers/CommonPlaceTopics/CommonPlaceTopics.reducers';
import { ContestEditsState } from 'mk2/apps/forum/containers/ContestEdit/ContestEdit.reducers';
import { FeedReportedPagesState } from 'mk2/apps/forum/containers/FeedReported/FeedReported.reducers';
import { State as FeedUntrustworthyUserState } from 'mk2/apps/forum/containers/FeedUntrustworthyUser/FeedUntrustworthyUser.reducers';
import { ForumCategoriesPageState } from 'mk2/apps/forum/containers/ForumCategories/ForumCategories.reducers';
import { ForumFeedPageState } from 'mk2/apps/forum/containers/ForumFeed/ForumFeed.reducers';
import { ForumMyActivityFeedPageState } from 'mk2/apps/forum/containers/ForumMyActivityFeed/ForumMyActivityFeed.reducers';
import { ForumSearchState } from 'mk2/apps/forum/containers/ForumSearch/ForumSearch.reducers';
import { ForumTopicsPageState } from 'mk2/apps/forum/containers/ForumTopics/ForumTopics.reducers';
import { ListOfForumsPageState } from 'mk2/apps/forum/containers/ListOfForums/ListOfForums.reducers';
import { PostCommentsLikersState } from 'mk2/apps/forum/containers/PostCommentLikers/PostCommentLikers.reducers';
import { PostCreateSuccessAction } from 'mk2/apps/forum/containers/PostCreate/PostCreate.actions';
import { PostCreateState } from 'mk2/apps/forum/containers/PostCreate/PostCreate.reducers';
import {
    PostDetailFetchSuccessAction,
    PostDetailToggleNotificationsApiSuccessAction,
} from 'mk2/apps/forum/containers/PostDetail/PostDetail.actions';
import { PostsDetailState } from 'mk2/apps/forum/containers/PostDetail/PostDetail.reducers';
import { PostEditsState } from 'mk2/apps/forum/containers/PostEdit/PostEdit.reducers';
import { PostsLikersState } from 'mk2/apps/forum/containers/PostLikers/PostLikers.reducers';
import { TopicFetchSuccessAction } from 'mk2/apps/forum/containers/Topic/Topic.actions';
import { TopicsState } from 'mk2/apps/forum/containers/Topic/Topic.reducers';
import { TopicCreateState } from 'mk2/apps/forum/containers/TopicCreate/TopicCreate.reducers';
import { TopicEditsState } from 'mk2/apps/forum/containers/TopicEdit/TopicEdit.reducers';
import { TopicInfosState } from 'mk2/apps/forum/containers/TopicInfo/TopicInfo.reducers';
import { TopicModeratorInvitationStates } from 'mk2/apps/forum/containers/TopicModeratorInvitation/TopicModeratorInvitation.reducers';
import { ComplaintsStatisticsPerPost } from 'mk2/apps/forum/schemas';
import { tupdate } from 'mk2/helpers/types';
import { AppState } from 'mk2/reducers';
import { Reducer } from 'redux';

export interface ForumSharedState {
    notificationsOn: number[];
    complaintsStatistics: ComplaintsStatisticsPerPost;
    newestCount: number;
    popularCount: number;
    updatedCount: number;
    subscribedCount: number;
}

const initialForumState: ForumSharedState = {
    notificationsOn: [],
    complaintsStatistics: {},
    newestCount: 0,
    popularCount: 0,
    updatedCount: 0,
    subscribedCount: 0,
};

declare type ForumSharedReducerAction =
    | ForumUpdateComplaintsStatisticsAction
    | TopicFetchSuccessAction
    | PostDetailFetchSuccessAction
    | PostDetailToggleNotificationsApiSuccessAction
    | PostCreateSuccessAction
    | ForumHeaderUpdateAction
    | ForumReadFeedSaveRequestAction;

const forumSharedReducer: Reducer<ForumSharedState> =
    (state = initialForumState, action: ForumSharedReducerAction) => {
    switch (action.type) {
        case 'TOPIC_FETCH_SUCCESS': /* Keep as a string so that we reduce bundle size */
        case 'POST_DETAIL_FETCH_SUCCESS': /* Keep as a string so that we reduce bundle size */
        case 'POST_CREATE_SUCCESS': /* Keep as a string so that we reduce bundle size */
            return tupdate(state, {
                notificationsOn: uniq([...state.notificationsOn, ...action.notificationsOn]),
            });

        case 'POST_DETAIL_TOGGLE_NOTIFICATIONS_API_SUCCESS': /* Keep as a string so that we reduce bundle size */
            return tupdate(state, {
                notificationsOn: action.follow ?
                    [action.channelId, ...state.notificationsOn] :
                    state.notificationsOn.filter((id) => id !== action.channelId),
            });
        case FORUM_UPDATE_COMPLAINTS_STATISTICS:
            return tupdate(state, {
                ...state,
                complaintsStatistics: action.complaintsStatistics,
            });
        case FORUM_HEADER_UPDATE:
            return {
                ...state,
                newestCount: action.lastVisitCounts.newest,
                popularCount: action.lastVisitCounts.popular,
                updatedCount: action.lastVisitCounts.updated,
                subscribedCount: action.lastVisitCounts.subscribed,
            };
        case FORUM_READ_FEED_REQUEST:
            const key = {
                newest: 'newestCount',
                popular: 'popularCount',
                updated: 'updatedCount',
                subscribed: 'subscribedCount',
            }[action.feed];

            return {
                ...state,
                [key]: 0,
            };
        default:
            return state;
    }
};

export interface ForumAppState {
    shared: ForumSharedState;
    commonPlaceTopics: CommonPlaceTopicsPageState;
    feedReported: FeedReportedPagesState;
    forumCategories: ForumCategoriesPageState;
    forumFeed: ForumFeedPageState;
    forumMyActivityFeed: ForumMyActivityFeedPageState;
    forumPostCreate: PostCreateState;
    forumPostEdits: PostEditsState;
    forumCommentEdits: CommentEditsState;
    forumSearch: ForumSearchState;
    forumTopics: ForumTopicsPageState;
    forumTopic: TopicsState;
    forumTopicCreate: TopicCreateState;
    forumTopicEdits: TopicEditsState;
    forumTopicInfos: TopicInfosState;
    forumTopicModeratorInvitations: TopicModeratorInvitationStates;
    forumPostLikers: PostsLikersState;
    forumPostCommentLikers: PostCommentsLikersState;
    forumArchivedCampaigns: ArchivedCampaignsState;
    forumPostDetail: PostsDetailState;
    forumContestEdits: ContestEditsState;
    forumFeedUntrustworthyUser: FeedUntrustworthyUserState;
    listOfForums: ListOfForumsPageState;
}

export const forumReducer = {
    shared: forumSharedReducer,
};

// Export selectors for forum
export const getForumNotificationsOn = (state: AppState) => state.containers.forum.shared.notificationsOn;
export const getForumComplaintsStatistics = (state: AppState) => state.containers.forum.shared.complaintsStatistics;

export const getForumNewestCount = (state: AppState) => state.containers.forum.shared.newestCount;
export const getForumSubscribedCount = (state: AppState) => state.containers.forum.shared.subscribedCount;
