/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const children_camps : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["RESIDENTIAL_CAMPS", "Pobytov\u00e9"],
        ["SUBURBAN_CAMPS", "P\u0159\u00edm\u011bstsk\u00e9"],
    ],
};
