import React, { useEffect, useRef, PropsWithChildren } from 'react';
import styles from './SwipeLeftToClose.mscss';

const THRESHOLD = 10;

interface OwnProps {
    className?: string;
    onClose();
}

type Props = OwnProps;

export const SwipeLeftToClose: React.FunctionComponent<Props> = React.memo(({
    className, children, onClose,
}) => {
    const containerRef = useRef<HTMLDivElement>();
    const contentRef = useRef<HTMLDivElement>();

    useEffect(() => {
        let startX: number;
        let startY: number;
        let startTime: number;
        let passThresholdX: boolean = false;
        let passThresholdY: boolean = false;
        let lastTouch: Touch;
        let animationDispose: () => void;

        const velocity = 0.3;

        const addAnimation = () => {
            if (animationDispose) {
                animationDispose();
            }

            contentRef.current.classList.add(styles['SwipeLeftToClose--animating']);

            const remove = () => {
                contentRef.current.classList.remove(styles['SwipeLeftToClose--animating']);
                if (animationDispose) {
                    animationDispose();
                }
            };

            animationDispose = () => {
                contentRef.current.removeEventListener('transitionend', remove, false);
                animationDispose = null;
            };

            contentRef.current.addEventListener('transitionend', remove, false);
        };

        const onPanStart = (event: TouchEvent) => {
            const touch = event.touches[0];
            startX = touch.clientX;
            startY = touch.clientY;
            startTime = Date.now();
            lastTouch = touch;
        };

        const getTouch = (event: TouchEvent): Touch => {
            for (let i = 0; i < event.touches.length; i++) {
                if (event.touches[i].identifier === lastTouch.identifier) {
                    return event.touches[i];
                }
            }

            for (let i = 0; i < event.changedTouches.length; i++) {
                if (event.changedTouches[i].identifier === lastTouch.identifier) {
                    return event.changedTouches[i];
                }
            }

            return null;
        };

        const onPan = (event: TouchEvent) => {
            const touch = (lastTouch = getTouch(event) ?? lastTouch);
            const distanceX = startX - touch.clientX;
            const distanceY = startY - touch.clientY;

            // User has to move at least THRESHOLD px to activate slide
            if (!passThresholdX && !passThresholdY) {
                if (Math.abs(distanceY) > THRESHOLD) {
                    passThresholdY = true;
                } else if (Math.abs(distanceX) > THRESHOLD) {
                    passThresholdX = true;
                }
            }

            if (passThresholdY) {
                return;
            }

            if (passThresholdX) {
                if (distanceX > 0) {
                    contentRef.current.style.transform = `translate3d(-${distanceX}px, 0, 0)`;
                }
                if (event.cancelable) {
                    event.preventDefault();
                }
            }
        };

        const onPanEnd = (event: TouchEvent) => {
            if (passThresholdX) {
                if (event.cancelable) {
                    event.preventDefault();
                }

                const touch = (lastTouch = getTouch(event) ?? lastTouch);
                const distance = startX - touch.clientX;
                const contentWidth = contentRef.current.offsetWidth;
                if (
                    distance > contentWidth * 0.5 || // We swipe past 50%
                    distance / (Date.now() - startTime) > velocity // Fast swipe
                ) {
                    onClose();
                } else {
                    addAnimation();
                    contentRef.current.style.transform = `translate3d(0, 0, 0)`;
                }
            }

            // Cleanup
            startX = null;
            startY = null;
            startTime = null;
            passThresholdX = false;
            passThresholdY = false;
        };

        containerRef.current.addEventListener('touchmove', onPan);
        containerRef.current.addEventListener('touchstart', onPanStart);
        containerRef.current.addEventListener('touchend', onPanEnd);

        return () => {
            if (animationDispose) {
                animationDispose();
            }

            containerRef.current.removeEventListener('touchmove', onPan);
            containerRef.current.removeEventListener('touchstart', onPanStart);
            containerRef.current.removeEventListener('touchend', onPanEnd);
        };
    }, [contentRef, onClose]);

    return (
        <div className={styles.SwipeLeftToClose} ref={containerRef}>
            <div className={className} ref={contentRef}>
                {children}
            </div>
        </div>
    );
});

SwipeLeftToClose.displayName = 'SwipeLeftToClose';
