import {
    boCommentsSnapUrl,
    boOrdersAgencyBonusesUrl,
    boOrdersChartUrl,
    boOrdersDetailUrl,
    boOrdersEditUrl,
    boOrdersHistoryUrl,
    boOrdersInvoicingUrl,
    boOrdersProductionUrl,
    boOrdersReportAmbassadorUrl,
    boOrdersReportAskAnExpertUrl,
    boOrdersReportTestingUrl,
    boOrdersReportUrl,
    boOrdersSignedUrl,
    boOrdersUrl,
    boOrderCreateUrl,
    boPaymentOrdersUrl,
    boTestingCreateUrl,
    boTestingEditUrl,
} from 'mk2/apps/bo/urls';
import { DynamicRoute } from 'mk2/containers/Application/Application';

export const routes: DynamicRoute[] = [{
    app: 'bo',
    exact: true,
    path: boOrdersReportTestingUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrdersReportTesting/OrdersReportTestingPage' /* webpackChunkName: "bo.OrdersReportTesting" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrdersReportTesting/OrdersReportTestingPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersReportAmbassadorUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrdersReportAmbassador/OrdersReportAmbassadorPage' /* webpackChunkName: "bo.OrdersReportAmbassador" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrdersReportAmbassador/OrdersReportAmbassadorPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersReportAskAnExpertUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrdersReportAskAnExpert/OrdersReportAskAnExpertPage' /* webpackChunkName: "bo.OrdersReportAskAnExpert" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrdersReportAskAnExpert/OrdersReportAskAnExpertPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersReportUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrdersReport/OrdersReportPage' /* webpackChunkName: "bo.OrdersReport" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrdersReport/OrdersReportPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersProductionUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrderProduction/OrderProductionPage' /* webpackChunkName: "bo.OrderProduction" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrderProduction/OrderProductionPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boPaymentOrdersUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/PaymentOrders/PaymentOrdersPage' /* webpackChunkName: "bo.PaymentOrders" */);
    },
    modules: [ 'mk2/apps/bo/containers/PaymentOrders/PaymentOrdersPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/Orders/OrdersPage' /* webpackChunkName: "bo.Orders" */);
    },
    modules: [ 'mk2/apps/bo/containers/Orders/OrdersPage' ],
}, {
    app: 'bo',
    path: boCommentsSnapUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/CommentsSnap/CommentsSnapPage' /* webpackChunkName: "bo.CommentsSnap" */);
    },
    modules: [ 'mk2/apps/bo/containers/CommentsSnap/CommentsSnapPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersChartUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrdersChart/OrdersChartPage' /* webpackChunkName: "bo.OrdersChart" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrdersChart/OrdersChartPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersInvoicingUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrdersInvoicing/OrdersInvoicingPage' /* webpackChunkName: "bo.OrdersInvoicing" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrdersInvoicing/OrdersInvoicingPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersSignedUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrdersSigned/OrdersSignedPage' /* webpackChunkName: "bo.OrdersSigned" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrdersSigned/OrdersSignedPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersAgencyBonusesUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrdersAgencyBonuses/OrdersAgencyBonusesPage' /* webpackChunkName: "bo.OrdersAgencyBonuses" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrdersAgencyBonuses/OrdersAgencyBonusesPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrderCreateUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrderCreate/OrderCreatePage' /* webpackChunkName: "bo.OrderCreate" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrderCreate/OrderCreatePage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersEditUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrderEdit/OrderEditPage' /* webpackChunkName: "bo.OrderEdit" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrderEdit/OrderEditPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersHistoryUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrderHistory/OrderHistoryPage' /* webpackChunkName: "bo.OrderHistory" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrderHistory/OrderHistoryPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boOrdersDetailUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/OrderDetail/OrderDetailPage' /* webpackChunkName: "bo.OrderDetail" */);
    },
    modules: [ 'mk2/apps/bo/containers/OrderDetail/OrderDetailPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boTestingEditUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/TestingEditBo/TestingEditBoPage' /* webpackChunkName: "bo.TestingEditBo" */);
    },
    modules: [ 'mk2/apps/bo/containers/TestingEditBo/TestingEditBoPage' ],
}, {
    app: 'bo',
    exact: true,
    path: boTestingCreateUrl,
    getPage: () => {
        return import('mk2/apps/bo/containers/TestingCreateBo/TestingCreateBoPage' /* webpackChunkName: "bo.TestingCreateBo" */);
    },
    modules: [ 'mk2/apps/bo/containers/TestingCreateBo/TestingCreateBoPage' ],
}];
