/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp2 } from './commonProp2';

export const kindergarden_school : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SCHOOL_BAG", "Aktovka, \u0161koln\u00ed batoh"],
        ["SCHOOL_PEN", "Psac\u00ed pot\u0159eby, geometrie"],
        ["SCHOOL_CASE", "Pen\u00e1l"],
        ["SCHOOL_CREATIVE", "V\u00fdtvarn\u00e9 pot\u0159eby"],
        ["SCHOOL_MUSIC", "Hudebn\u00ed n\u00e1stroje + p\u0159\u00edslu\u0161enstv\u00ed"],
        ["SCHOOL_FOODBOX", "Box na sva\u010dinu, l\u00e1hev"],
        ["SCHOOL_OTHER", "Jin\u00e9 pom\u016fcky pro \u0161kol\u00e1ka"],
    ],
    p_sex: commonProp2,
    p_age_group: [
        ["NURSERIES", "\u0160k\u00f4lkari"],
        ["FIRST_AND_SECOND_CLASS", "1. a 2. ro\u010dn\u00edk Z\u0160"],
        ["THIRD_AND_FOURTH_CLASS", "3. a 4. ro\u010dn\u00edk Z\u0160"],
        ["SECOND_LEVEL_OF_SCHOOL", "2. stupe\u0148 Z\u0160"],
        ["OTHER_AGE", "Neuveden\u00fd"],
    ],
};
