/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const craftsmen : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["ELECTRICIAN", "Elektrik\u00e1\u0159"],
        ["PLUMBER", "Instalat\u00e9r"],
        ["CABINET_MAKER", "Truhl\u00e1\u0159"],
        ["BRICKLAYER", "Zedn\u00edk"],
        ["PAINTER", "Mal\u00ed\u0159"],
        ["MOVER", "St\u011bhov\u00e1n\u00ed"],
        ["ROOFER", "Pokr\u00fdva\u010d"],
        ["EXCAVATOR", "Bagrista"],
        ["LOCKSMITH", "Z\u00e1me\u010dn\u00edk"],
        ["DRILLER", "Vrt\u00e1n\u00ed"],
        ["ASSEMBLY", "Montov\u00e1n\u00ed"],
        ["CARPET_CLEANING", "\u010ci\u0161t\u011bn\u00ed koberc\u016f"],
        ["CHIMNEY_SWEEP", "Komin\u00edk"],
        ["CAR_SERVICE", "Servis a oprava aut"],
        ["BICYCLE_SERVICE", "Cykloservis"],
        ["WASHING_MACHINE_DRYER_DISHWASHER_SERVICE", "Servis a oprava pra\u010dek, su\u0161i\u010dek, my\u010dek"],
        ["STOVE_SERVICE", "Servis a oprava spor\u00e1k\u016f"],
        ["REFRIGERATOR_AND_FREEZER_SERVICE", "Servis a oprava ledni\u010dek a mrazak\u016f"],
        ["PC_AND_MOBILE_PHONE_SERVICE", "Servis a oprava PC a mobil\u016f"],
        ["TV_SERVICE", "Servis a oprava TV"],
        ["GARDEN_SERVICE", "Zahrada"],
        ["OTHER_CRAFTSMEN", "Jin\u00e9"],
    ],
    p_practice_length: commonProp22,
};
