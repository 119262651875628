import cx from 'classnames';
import { Features } from 'mk2/apps/strollers/schemas';
import React from 'react';
import styles from './PlainFeature.mscss';

interface OwnProps {
    className?: string;
    features: Features;
    feature: any;
    searchData?: any;
}

type Props = OwnProps;

export class PlainFeature extends React.Component<Props> {
    public render() {
        const { feature, features, className, searchData } = this.props;

        const value = feature.niceValue(features, searchData);

        return (
            <div className={cx(styles.PlainFeature, className)}>
                {value}
            </div>
        );
    }
}
