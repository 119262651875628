/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const home_dining : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PLATES_BOWLS", "Tal\u00ed\u0159e, misky"],
        ["DINNERWARE_SETS", "J\u00eddeln\u00ed soupravy"],
        ["MUGS_CUPS", "Hrne\u010dky a \u0161\u00e1lky"],
        ["GLASSES", "Sklenice"],
        ["TEA_COFFEE_SETS", "\u010cajov\u00e9 a k\u00e1vov\u00e9 soupravy"],
        ["TEA_POTS_JUGS_CARAFES", "Konvice, d\u017eb\u00e1ny, karafy"],
        ["CUTLERY", "P\u0159\u00edbory"],
        ["SERVEWARE", "Serv\u00edrovac\u00ed tal\u00ed\u0159e, podnosy a m\u00edsy"],
        ["TEA_COFFEE_ACCESSOIRES", "Dopl\u0148ky na \u010daj a k\u00e1vu"],
        ["WINE_ACCESSOIRES", "Dopl\u0148ky k v\u00ednu"],
        ["BAR_ACCESSOIRES", "Barov\u00e9 dopl\u0148ky"],
        ["PICNICK", "Zahradn\u00ed p\u00e1rty, grilov\u00e1n\u00ed"],
        ["SALT_AND_PEPPER_SHAKERS", "Sl\u00e1nka, ko\u0159enka"],
        ["OTHER_DINING", "Jin\u00e9"],
    ],
    p_color: commonProp4,
};
