import { photosUploadTrigger } from 'mk2/containers/PhotosUpload/PhotosUpload.actions';
import {
    preparePhotosUploadApi,
    ToolbarPreparePhotosUploadNormalizedResponse,
    ToolbarPreparePhotosUploadTriggerAction,
    TOOLBAR_PREPARE_PHOTOS_UPLOAD_TRIGGER,
} from 'mk2/containers/Toolbar/Toolbar.actions';
import { XHRAction } from 'mk2/helpers/api';
import { getPhotoPreview } from 'mk2/helpers/photos';
import { PhotoPreviewSchema } from 'mk2/schemas';
import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* preparePhotosUpload({ formName, config, files, processPhotoUploadOnFormPage, accessKey, xhr }: ToolbarPreparePhotosUploadTriggerAction & XHRAction) {
    try {
        yield put(preparePhotosUploadApi.request(formName, config, files));
        yield put(photosUploadTrigger(formName, config, files, processPhotoUploadOnFormPage, accessKey));
        const photoPreview = [];
        for (let i = 0; i < files.length; i++) {
            photoPreview.push(yield call(() => getPhotoPreview(files[i])));
        }
        const normalizedResponse: ToolbarPreparePhotosUploadNormalizedResponse = normalize(
            {photoPreview},
            {photoPreview: [PhotoPreviewSchema]},
        );
        yield put(preparePhotosUploadApi.success(formName, config, files, normalizedResponse));
    } catch (error) {
        yield put(preparePhotosUploadApi.failure(formName, config, files, error));
    }
}

export default function* root() {
    yield all([
        takeLatest(TOOLBAR_PREPARE_PHOTOS_UPLOAD_TRIGGER, preparePhotosUpload),
    ]);
}
