/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const twins_misc : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CAR_SEATS", "Autoseda\u010dky"],
        ["COTS", "Postele, ohr\u00e1dky a p\u0159\u00edslu\u0161enstv\u00ed"],
        ["BABY_FEEDING", "Kojeneck\u00e9 pot\u0159eby"],
        ["TOYS", "Hra\u010dky"],
        ["BABY_MISC", "Jin\u00e9 d\u011btsk\u00e9"],
    ],
    p_color: commonProp4,
};
