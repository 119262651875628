/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp2: Array<[string, string]> = [
    ["GIRLS", "Pro holky"],
    ["BOYS", "Pro kluky"],
];
