import {
    AdSlotRenderEndedAction,
    AdSlotUnregisterAction,
    AD_SLOT_RENDER_ENDED,
    AD_SLOT_UNREGISTER,
} from 'mk2/containers/AdSlot/AdSlot.actions';
import React from 'react';
import { all, takeLatest } from 'redux-saga/effects';

function* brandingRendered({ slotId, event }: AdSlotRenderEndedAction) {
    const slot = document.getElementById(slotId);
    const body = document.body;

    if (!event.isEmpty && event.size && Array.isArray(event.size)) {
        // set page to layout according to the size of creative
        // size '1x1' is returned from prebid line item.
        const creativeWidth = event.size[0];
        let layout;
        if (creativeWidth === 2000) {
            layout = 'branding';
        } else if (creativeWidth === 970) {
            layout = 'billboard';
        } else if (creativeWidth === 1) {
            // Prisla kreativa z Prebidu a nemame informaciu o jej skutocnej
            // velkosti.
            // CeMi: 29.1.2021 skusam heuristiku, co sa pozrieme na velkost
            // slotu. Sme v handleri eventu 'SlotRenderEndedEvent', tak by
            // slot uz mohol byt resiznuty na spravnu velkost.
            if (slot.clientHeight === 250) {
                layout = 'billboard';
            } else if (slot.clientHeight === 1400) {
                layout = 'branding';
            } else {
                // neznama vyska, tak pouzi ako default layout billboard
                // (viac sa ich predava, vacsia sanca ze sa trafime)
                layout = 'billboard';
            }
        }

        // Cleanup layouts
        if (layout === 'branding') {
            body.classList.add('body--branding');
            body.classList.remove('body--billboard');
        } else if (layout === 'billboard') {
            body.classList.remove('body--branding');
            body.classList.add('body--billboard');
        }

        if (slot) {
            if (layout === 'branding') {
                slot.classList.add('slot--branding');
            } else {
                slot.classList.remove('slot--branding');
            }
        }
    } else {
        // reset to normal page layout to normal, we do not have branding
        if (slot) {
            slot.classList.remove('slot--branding');
        }
        body.classList.remove('body--branding');
        body.classList.remove('body--billboard');
    }
}

function* brandingUnregistered({ slotId }: AdSlotUnregisterAction) {
    const body = document.body;
    const slot = document.getElementById(slotId);

    if (slot) {
        slot.classList.remove('slot--branding');
    }
    body.classList.remove('body--branding');
    body.classList.remove('body--billboard');
}

export default function* root() {
    yield all([
        takeLatest((action) => action.type === AD_SLOT_RENDER_ENDED && action.slotId.startsWith('mk-branding'), brandingRendered),
        takeLatest((action) => action.type === AD_SLOT_UNREGISTER && action.slotId.startsWith('mk-branding'), brandingUnregistered),
    ]);
}
