import { homepageUrl } from 'mk2/apps/home/urls';
import { Route } from 'mk2/containers/Application/Application';

export const routes: Route[] = [{
    app: 'home',
    exact: true,
    path: homepageUrl,
    getPage: () => {
        return import('mk2/apps/home/containers/Home/HomePage' /* webpackChunkName: "home.Home" */);
    },
    modules: [ 'mk2/apps/home/containers/Home/HomePage' ],
}];
