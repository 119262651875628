/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const food : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FRUITS", "Ovoce"],
        ["VEGETABLE", "Zelenina"],
        ["MUSHROOMS", "Houby"],
        ["BAKERY", "Chl\u00e9b, pe\u010divo"],
        ["CAKE", "Kol\u00e1\u010de, dorty"],
        ["FLOUR", "Mouka"],
        ["PASTA", "T\u011bstoviny"],
        ["RICE", "Obiloviny a r\u00fd\u017ee"],
        ["MUSLI", "M\u00fcsli, vlo\u010dky a ka\u0161e"],
        ["SEEDS", "O\u0159echy, sem\u00ednka, su\u0161en\u00e9 plody"],
        ["LEGUMINOUS", "Lu\u0161t\u011bniny"],
        ["DIARY", "Ml\u00e9ko a ml\u00e9\u010dn\u00e9 v\u00fdrobky"],
        ["SOY_MILK", "Rostlinn\u00e1 ml\u00e9ka a v\u00fdrobky"],
        ["EGGS", "Vejce"],
        ["MEAT", "Maso a masn\u00e9 v\u00fdrobky"],
        ["TOFU", "Tofu a alternativy masa"],
        ["SEAFOOD", "Ryby a mo\u0159sk\u00e9 plody"],
        ["COFFE_TEA", "K\u00e1va, \u010daj, k\u00e1voviny"],
        ["BEVERAGE", "N\u00e1poje, \u0161\u0165\u00e1vy, sirupy"],
        ["SWEETENER", "Med, cukr, sladidla"],
        ["JAM", "D\u017eemy a nutella"],
        ["PATE", "Pa\u0161tiky a pomaz\u00e1nky"],
        ["FET", "Oleje, tuky"],
        ["SEASONING", "S\u016fl, ko\u0159en\u00ed, ocet"],
        ["SAUCE", "Ke\u010dupy, ho\u0159\u010dice a om\u00e1\u010dky"],
        ["CRACKERS", "Slan\u00e9 keksy, krekry"],
        ["SWEETS", "Sladkosti"],
        ["PROCESSED", "Hotov\u00e1 j\u00eddla a polotovary"],
        ["BAKING", "Na pe\u010den\u00ed"],
        ["FOOD_OTHER", "Jin\u00e9"],
    ],
};
