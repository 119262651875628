import { PHOTO_UPLOAD_WRONG_IMAGE_FORMAT } from 'mk/autogenerated/translations/resize.9642a1204ee93201c11d2c522531579d'
import { getExifOrientation } from 'mk2/helpers/photos';

const MIN_PREPROCESS_FILES_SIZE = 300 * 1024;
const MAX_IMAGE_SIZE = 1600;

function needsHalving(content: HTMLCanvasElement | HTMLImageElement): boolean {
    return Math.max(content.width, content.height) > (MAX_IMAGE_SIZE * 2);
}

function halve(content: HTMLCanvasElement | HTMLImageElement): HTMLCanvasElement {
    const newW = Math.floor(content.width / 2);
    const newH = Math.floor(content.height / 2);

    // console.log('halving from:', content.width, content.height, 'to:', newW, newH, 'stamp:', new Date().toISOString());

    const canvas = document.createElement('canvas');
    canvas.width = newW;
    canvas.height = newH;

    canvas.getContext('2d').drawImage(content, 0, 0, newW, newH);

    // console.log('halving done,stamp:', new Date().toISOString());

    return canvas;
}

const DATAURL_CHECKER = /^data:image\/jpeg;base64,/;
const DATAURL_DATA_INDEX = 23;

function dataURLToBlob(dataURL: string): Blob {
    // data:[<MIME-type>][;charset=<encoding>][;base64],<data>

    if (!DATAURL_CHECKER.test(dataURL)) {
        throw new Error('Wrong dataURL format:' + dataURL.substring(0, DATAURL_DATA_INDEX));
    }

    const base64Data = dataURL.substr(DATAURL_DATA_INDEX);

    // this will contain a string if characters, every char is the byte-value
    const bytesString = atob(base64Data);

    // this is an array of bytes as numbers
    const bytesNumbers = Array.prototype.map.call(bytesString, (c) => {
        return c.charCodeAt(0);
    });

    // this is the real thing
    const bytesArray = new Uint8Array(bytesNumbers);

    return new Blob([bytesArray]);
}

function canvasToBlob(canvas: HTMLCanvasElement, mime: string, quality: number): Promise<Blob> {
    return new Promise((resolve) => {
        if (HTMLCanvasElement.prototype.toBlob) {
            canvas.toBlob((blob) => { resolve(blob); }, mime, quality);
        } else {
            const dataURL = canvas.toDataURL(mime, quality);
            const blob = dataURLToBlob(dataURL);
            resolve(blob);
        }
    });
}

function getImageOrientation(f): Promise<number> {
    return new Promise((resolve, reject) => {
        // we do not take the whole file, it might take very long to load
        const slice = f.slice(0, 128 * 1024);
        const reader = new FileReader();

        reader.onload = () => {
            const orientation = getExifOrientation(reader.result as ArrayBuffer);
            resolve(orientation > 0 ? orientation : 1); // Fallback to 1
        };
        reader.onerror = (err) => {
            reject(err);
        };
        reader.readAsArrayBuffer(slice);
    });
}

// returns a promise that resolves to true  if the browser automatically
// rotates images based on exif data and false otherwise
function browserAutoRotates () {
    return new Promise((resolve, reject) => {
        // load an image with exif rotation and see if the browser rotates it
        const image = new Image();
        image.onload = () => {
            resolve(image.naturalWidth === 1);
        };
        image.onerror = reject;
        // this jpeg is 2x1 with orientation=6 so it should rotate to 1x2
        image.src = 'data:image/jpeg;base64,/9j/4QBiRXhpZgAATU0AKgAAAAgABQESAAMAAAABAAYAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAAITAAMAAAABAAEAAAAAAAAAAABIAAAAAQAAAEgAAAAB/9sAQwAEAwMEAwMEBAMEBQQEBQYKBwYGBgYNCQoICg8NEBAPDQ8OERMYFBESFxIODxUcFRcZGRsbGxAUHR8dGh8YGhsa/9sAQwEEBQUGBQYMBwcMGhEPERoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoa/8IAEQgAAQACAwERAAIRAQMRAf/EABQAAQAAAAAAAAAAAAAAAAAAAAf/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAF/P//EABQQAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQEAAQUCf//EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMBAT8Bf//EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQIBAT8Bf//EABQQAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQEABj8Cf//EABQQAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQEAAT8hf//aAAwDAQACAAMAAAAQH//EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMBAT8Qf//EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQIBAT8Qf//EABQQAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQEAAT8Qf//Z';
    });
}

function rotateImage(canvas: HTMLCanvasElement, orientation: number): HTMLCanvasElement {
    const cw = canvas.width;
    const ch = canvas.height;

    const d = {
        3: [Math.PI, [cw, ch], [-cw, -ch]],
        6: [Math.PI / 2, [ch, cw], [0, -ch]],
        8: [-Math.PI / 2, [ch, cw], [-cw, 0]],
    }[orientation];

    if (
        d === undefined ||  // either unsuppored orientation, or the "no rotation needed" orientation
        browserAutoRotates()  // if browser support auto rotating (mozilla >= 26, chrome >= 81, ...)
    ) {
        return canvas;
    } else {
        // console.log('rotateImage: rotating for orientation', orientation);
        const rotatedCanvas = document.createElement('canvas');

        let ctx = rotatedCanvas.getContext('2d');

        rotatedCanvas.width = d[1][0];
        rotatedCanvas.height = d[1][1];

        ctx = rotatedCanvas.getContext('2d');
        ctx.rotate(d[0] as number);
        ctx.translate(d[2][0], d[2][1]);

        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height);

        return rotatedCanvas;
    }
}

async function scaleImage(img: HTMLImageElement, orientation: number): Promise<Blob> {
    let content: HTMLCanvasElement | HTMLImageElement = img;

    while (needsHalving(content)) {
        content = halve(content);
    }

    const maxSize = Math.max(content.width, content.height);
    let w = null;
    let h = null;

    if (maxSize > MAX_IMAGE_SIZE) {
        const ratio = MAX_IMAGE_SIZE / maxSize;
        w = Math.floor(content.width * ratio);
        h = Math.floor(content.height * ratio);
    } else {
        w = content.width;
        h = content.height;
    }

    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, w, h);

    const finalCanvas = rotateImage(canvas, orientation);

    return canvasToBlob(finalCanvas, 'image/jpeg', 0.85);
}

export async function resizeImage(f: Blob): Promise<Blob> {
    // if it's small enough, we read it in as blob and "return"
    if (f.size < MIN_PREPROCESS_FILES_SIZE) {
        // console.log('fileToBlob: no scaling, uploading directly');
        return f;
    }

    // otherwise we read it in as dataURL,
    // create an image, and "return" using scaleImage
    // console.log('fileToBlob: file is too big, scaling');

    return new Promise<Blob>((resolve, reject) => {
        const url = URL.createObjectURL(f);
        const img: HTMLImageElement = document.createElement('img');
        img.addEventListener('load', async () => {
            URL.revokeObjectURL(url);

            try {
                // we also need the EXIF orientation data
                const orientation = await getImageOrientation(f);

                const imgBlob = await scaleImage(img, orientation);
                resolve(imgBlob);
            } catch (error) {
                reject(new Error(PHOTO_UPLOAD_WRONG_IMAGE_FORMAT));
            }
        });
        img.addEventListener('error', () => {
            URL.revokeObjectURL(url);

            reject(new Error(PHOTO_UPLOAD_WRONG_IMAGE_FORMAT));
        });
        img.src = url;
    });
}
