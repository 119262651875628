/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const adults_health_beauty : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HAIR_COLOR", "Barva na vlasy"],
        ["HAIR_SHAMPOO", "\u0160amp\u00f3ny, vlasov\u00e1 kosmetika"],
        ["HAIR_STYLING", "Vlasov\u00fd styling"],
        ["MANICURE", "Manik\u00fara, pedik\u00fara"],
        ["MAKE_UP_DECORATIVE", "Dekorativn\u00ed kosmetika, make-up"],
        ["BODY_CARE", "P\u00e9\u010de o t\u011blo"],
        ["FACE_CARE", "P\u00e9\u010de o tv\u00e1\u0159"],
        ["PARFUME", "Parf\u00e9m"],
        ["DEPILATION", "Depilace a holen\u00ed"],
        ["HYGIENE", "Hygiena"],
        ["COSMETIC_BAG", "Kuf\u0159\u00edk/kosmetick\u00e1 ta\u0161ti\u010dka"],
        ["MEDICAL_NEEDS", "Zdravotn\u00ed pot\u0159eby"],
        ["NUTRITIONAL_SUPPLEMENTS", "Vitam\u00edny, v\u00fd\u017eivov\u00e9 dopl\u0148ky"],
        ["WIGS_AND_HAIRPIECES", "Paruky a p\u0159\u00ed\u010desky"],
        ["PERSONAL_SCALES", "Osobn\u00ed v\u00e1hy"],
        ["MASSAGES", "Mas\u00e1\u017ee"],
    ],
};
