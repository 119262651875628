/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const home_carpets_rugs : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CARPETS_RUNNERS", "Koberce a b\u011bhouny"],
        ["SHEEPSKIN_COW_HIDE_RUGS", "K\u016f\u017ee a ko\u017ee\u0161iny"],
        ["DOOR_MATS", "Roho\u017eky"],
        ["BATH_MATS", "P\u0159edlo\u017eky do koupelny a WC"],
        ["ANTISLIP_MATS", "Protismykov\u00e9 podlo\u017eky"],
    ],
    p_color: commonProp4,
};
