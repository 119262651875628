import { TestingStatus } from 'mk2/apps/bo/schemas';
import { createLikeableSchema, LikeableEntity } from 'mk2/containers/Like/Like.schemas';
import { DeletedByRole } from 'mk2/helpers/deleteable';
import {
    AST,
    ASTAdditionalDataFromServer,
    BlogPostEntity,
    CommentEntity,
    Entity,
    PhotoEntity,
    StrollerEntity,
    UserEntity,
    WikiArticleEntity,
} from 'mk2/schemas';
import { schema } from 'normalizr';

export type PageRef = number | 'last';

export interface CategoryEntity extends Entity {
    orderIndex: number;
    name: string;
    slug: string;
    coverPhoto: PhotoEntity;
    id: number;
}

export interface TopicModeratorEntity extends Entity {
    user?: UserEntity;
    createdTime: string;
}

export interface TopicEntity extends Entity {
    slug: string;
    category: CategoryEntity;
    name: string;
    descriptionAST: AST;
    descriptionASTAdditionalData: ASTAdditionalDataFromServer;
    // Deprecated - use topicModerators.map((tm) => tm.user)
    moderators?: UserEntity[];
    topicModerators: TopicModeratorEntity[];
    coverPhoto: PhotoEntity;
    isCommercial: boolean;
    isBrandDays: boolean;
    deletedAt: string;
    deletedReason: string;
    deletedBy: UserEntity;
    numRecentContributors: number;
    numThreads: number;
    visibility: number;
    created: string;
    eshopCollections: string[];
    eshopCategories: string[];
    eshopProducts: string[];
}

export interface TopicModeratorInvitationEntity extends Entity {
    invitee: UserEntity;
    inviter: UserEntity;
    createdAt: string;
    deleteAt: string;
    acceptedAt: string;
}

export interface TopicModeratorsDetailsEntity extends Entity {
    allModerators: Array<{
        user: UserEntity;
        createdAt: string;
        deletedAt: string;
    }>;
    pendingInvitations: TopicModeratorInvitationEntity[];
}

export interface ProductTestingEntity extends Entity {
    name: string;
    isActive: boolean;
    trackedHashtag: string;
    status: TestingStatus;
    teaserTitle: string;
    teaserText: AST;
    teaserLink: string;
    teaserASTAdditionalData: ASTAdditionalDataFromServer;
    callTitle: string;
    callText: AST;
    callLink: string;
    callEndDate: string;
    callASTAdditionalData: ASTAdditionalDataFromServer;
    inProgressTitle: string;
    inProgressText: AST;
    inProgressASTAdditionalData: ASTAdditionalDataFromServer;
    finishedTitle: string;
    finishedText: AST;
    finishedTesters: string;
    finishedASTAdditionalData: ASTAdditionalDataFromServer;
    photos: PhotoEntity[];
    finishedTextProductTestingPhotos: ProductTestingPhotoEntity[];
    updatedTime: string;
    user: UserEntity;
    testersPostsCount?: number;
}

export interface ForumMessagePhotoEntity extends Entity {
    photofile: PhotoEntity;
    message?: CommentEntity;
    position: number;
}

export interface PostEntity extends Entity {
    topic: TopicEntity;
    author: UserEntity;
    created: string;
    heading: string;
    perexAST: AST;
    perexASTAdditionalData: ASTAdditionalDataFromServer;
    extraEntitiesOfArticle: WikiArticleEntity[];
    extraEntitiesOfStroller: StrollerEntity[];
    extraEntitiesOfPost: BlogPostEntity[];
    // Deprecated - use messagePhotos
    photos: PhotoEntity[];
    messagePhotos: ForumMessagePhotoEntity[];
    stickerId: number;
    likeable: LikeableEntity;
    comments: number;
    sticky: boolean;
    locked: boolean;
    lastMessageTime: string;
    slug: string;
    popularAddedOn: string;
    noindex: boolean;
    isNoAds: boolean;
    isHoax: boolean;
    canBeDeleted: boolean;
    deletedAt?: string;
    deletedByRole?: DeletedByRole;
    deletedReason?: string;
    deletedBy?: UserEntity;
    counsellingAdminApprovedTime: string;
    counsellingCounsellor: CounsellingCounsellorEntity;
    testing?: ProductTestingEntity;
    contestEnded?: boolean;
    eshopCollections: string[];
    eshopCategories: string[];
    eshopProducts: string[];
}

export interface MyAnonymousPostEntity extends Entity {}

export enum ContestType {
    Article = 'article',
    Forum = 'forum',
    ShortMessage = 'message',
}

export interface ContestEntity extends Entity {
    name: string;
    isActive: boolean;
    isDraft: boolean;
    endDate: string;
    seeAnswers: boolean;
    postId: number;
    blogPostId: number;
    photo: PhotoEntity;
    replyCount: number;
    title: string;
    type: ContestType;
    url: string;
}

export interface BrandDaysCampaignEntity extends Entity {
    name: string;
    title: string;
    user: UserEntity;
    logoUrl: string; // kam ma smerovat preklik z loga
    logoSrc: string; // url kde je obrazok
    logoWidth: number; // rozmery loga (dlzka), zobrazi sa do obdlznika 80x30
    logoHeight: number; // rozmery loga (sirka), zobrazi sa do obdlznika 80x30
    logoPrefix: string; // text co sa zobrazi pred logom
    forumTopicSlug: string;
}

export interface CounsellingCounsellorEntity extends Entity {
    user: UserEntity;
    avatarPhoto: PhotoEntity;
    name: string;
    signatureAST: AST;
    counselling: CounsellingEntity;
    isActive: boolean;
}

export interface CounsellingEntity extends Entity {
    // @deprecated
    counsellor: UserEntity;
    // @deprecated
    counsellorAvatar: string;
    // @deprecated
    counsellorAvatarPhoto: PhotoEntity;
    // @deprecated
    counsellorName: string;
    // @deprecated
    counsellorSignatureAST: AST;
    isLocked: boolean;
    isArchived: boolean;
    lockedMessage: string;
    topic: TopicEntity;
    counsellors: CounsellingCounsellorEntity[];
}

export interface ArchivedCampaignsEntity extends Entity {
    topic: TopicEntity;
    date: Date;
    isActive: boolean;
}

export interface ProductTestingPhotoEntity extends Entity {
    photofile: PhotoEntity;
    position: number;
    deletedAt: string;
}

export const CategorySchema = new schema.Entity('forum.category', {
});

export const TopicSchema = new schema.Entity('forum.topic', {});

export const TopicModeratorSchema = new schema.Entity('forum.topicmoderator', {
    topic: TopicSchema,
});

TopicSchema.define({
    category: CategorySchema,
    topicModerators: [TopicModeratorSchema],
});

export const TopicModeratorInvitationSchema = new schema.Entity('forum.topicmoderatorinvitation', {
});

export const TopicModeratorsDetailsSchema = new schema.Entity('topicModeratorsDetails', {
    pendingInvitations: [TopicModeratorInvitationSchema],
});

export const ProductTestingPhotoSchema = new schema.Entity('photoblog.producttestingphoto', {
});

export const ProductTestingSchema = new schema.Entity('photoblog.producttesting', {
    finishedTextProductTestingPhotos: [ProductTestingPhotoSchema],
});

export const ForumMessagePhotoSchema = new schema.Entity('forum.messagephoto', {});

export const CounsellingCounsellorSchema = new schema.Entity('forum.counsellingcounsellor');

export const CounsellingSchema = new schema.Entity('forum.counselling', {
    topic: TopicSchema,
    counsellors: [CounsellingCounsellorSchema],
});

export const PostSchema = new schema.Entity('forum.thread', {
    topic: TopicSchema,
    messagePhotos: [ForumMessagePhotoSchema],
    likeable: createLikeableSchema('thread'),
    testing: ProductTestingSchema,
    counsellingCounsellor: CounsellingCounsellorSchema,
});

export const MyAnonymousPostSchema = new schema.Entity('myAnonymousPosts');

export const ContestSchema = new schema.Entity('forum.contestthread', {
});

export const BrandDaysCampaignSchema = new schema.Entity('brandDaysCampaigns', {
});

export const ArchivedCampaignsSchema = new schema.Entity('archivedCampaigns', {
    topic: TopicSchema,
});

export interface ForumMessageEntity extends CommentEntity {
    messagePhotos: ForumMessagePhotoEntity[];
}

// TODO Refactor, because it's confusing for most of us, it's like Comment Schema
// TODO but because Like needs entity matched with DB, we need to write new schema
export const ForumMessageSchema = new schema.Entity('forum.message', {
    messagePhotos: [ForumMessagePhotoSchema],
    likeable: createLikeableSchema('forum-message'),
});

export interface ComplaintsStatistics {
    count: number;
    topicId: number;
}
export interface ComplaintsStatisticsPerPost {
    [postId: number]: ComplaintsStatistics;
}
export interface ComplaintsCountPerTopic {
    [topicId: number]: number;
}

ForumMessagePhotoSchema.define({
    message: ForumMessageSchema,
});

CounsellingCounsellorSchema.define({
    counselling: CounsellingSchema,
});
