/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const face_painting : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FACE_PAINTS", "Barvy na tv\u00e1\u0159"],
        ["FACE_CRAYONS", "Tu\u017eky na tv\u00e1\u0159"],
        ["FACE_BRUSHES_SPONGES", "\u0160t\u011btce a houbi\u010dky"],
        ["FACE_PAINTING_GUIDES", "\u0160ablony a n\u00e1vody"],
        ["FACE_PAINTING_KIT", "Sady a sety"],
    ],
};
