import {
    BY_CREATION_TIME_NEWEST,
    BY_CREATION_TIME_OLDEST,
    BY_NEW_MESSAGES,
    BY_PRICE_ASC,
    BY_PRICE_DESC,
    BY_REPOST_TIME_NEWEST,
    BY_REPOST_TIME_OLDEST,
    BY_TITLE,
    BY_TITLE_REVERSED,
    BZ2_CAT_ACCOMMODATION,
    BZ2_CAT_ACCOMMODATION_RENTAL,
    BZ2_CAT_ADULTS_HANDICRAFTS,
    BZ2_CAT_ADULTS_HEALTH_BEAUTY,
    BZ2_CAT_ADULTS_HOMEWARE,
    BZ2_CAT_ADULTS_MISC,
    BZ2_CAT_ANIMALS,
    BZ2_CAT_ANTIQUE_ART_OBJECTS,
    BZ2_CAT_ANTIQUE_BADGES,
    BZ2_CAT_ANTIQUE_BOOKS,
    BZ2_CAT_ANTIQUE_CERAMICS,
    BZ2_CAT_ANTIQUE_CLOCKS,
    BZ2_CAT_ANTIQUE_FURNITURE,
    BZ2_CAT_ANTIQUE_JEWELRY,
    BZ2_CAT_ANTIQUE_MODELS,
    BZ2_CAT_ANTIQUE_MONEY,
    BZ2_CAT_ANTIQUE_MUSICAL_INSTRUMENTS,
    BZ2_CAT_ANTIQUE_OTHERS,
    BZ2_CAT_ANTIQUE_PICTURES,
    BZ2_CAT_ANTIQUE_POSTERS,
    BZ2_CAT_ANTIQUE_STAMPS,
    BZ2_CAT_ANTIQUE_TECHNOLOGY,
    BZ2_CAT_ANTIQUE_TOYS,
    BZ2_CAT_ANTIQUES,
    BZ2_CAT_ARCHERY,
    BZ2_CAT_ATVS,
    BZ2_CAT_AUTOMOTO,
    BZ2_CAT_AUTOMOTO_SPARE_PARTS,
    BZ2_CAT_BABY_CARRIERS,
    BZ2_CAT_BABY_FEEDING,
    BZ2_CAT_BABY_MISC,
    BZ2_CAT_BABYSITTING,
    BZ2_CAT_BALANCE_BIKE,
    BZ2_CAT_BALL_GAMES,
    BZ2_CAT_BALL_GAMES_BALLS,
    BZ2_CAT_BALL_GAMES_OTHER,
    BZ2_CAT_BASEBALL,
    BZ2_CAT_BASEBALL_BATS,
    BZ2_CAT_BASEBALL_OTHER,
    BZ2_CAT_BASKETBALL,
    BZ2_CAT_BASKETBALL_BALLS,
    BZ2_CAT_BASKETBALL_CLOTHING,
    BZ2_CAT_BASKETBALL_FOOTWEAR,
    BZ2_CAT_BASKETBALL_OTHER,
    BZ2_CAT_BEACH_TENTS_CHAIRS,
    BZ2_CAT_BICYCLE,
    BZ2_CAT_BICYCLE_SEAT,
    BZ2_CAT_BICYCLE_TRAILER,
    BZ2_CAT_BIRTH,
    BZ2_CAT_BLANKETS_WRAPPERS,
    BZ2_CAT_BLOUSE_SHIRTS,
    BZ2_CAT_BOATING,
    BZ2_CAT_BOATING_BARRELS,
    BZ2_CAT_BOATING_BOATS,
    BZ2_CAT_BOATING_LIFE_JACKETS,
    BZ2_CAT_BOATING_OTHER,
    BZ2_CAT_BOATING_PADDLES,
    BZ2_CAT_BODYSUITS,
    BZ2_CAT_BODYSUITS_SLEEPSUITS_ROMPERS,
    BZ2_CAT_BOUNCERS,
    BZ2_CAT_BREASTFEEDING_ACCESSORIES,
    BZ2_CAT_BUILDING_AND_CONSTRUCTION_MATERIAL,
    BZ2_CAT_CAMERA_PHOTO_VIDEO,
    BZ2_CAT_CAR_SEATS,
    BZ2_CAT_CAR_SEATS_ACCESSORIES,
    BZ2_CAT_CAR_SEATS_AND_ACCESSORIES,
    BZ2_CAT_CARETAKING,
    BZ2_CAT_CARS,
    BZ2_CAT_CELEBRATIONS,
    BZ2_CAT_CHANGING_BAG,
    BZ2_CAT_CHILDREN_CAMPS,
    BZ2_CAT_CHILDREN_CARE,
    BZ2_CAT_CLOTHING,
    BZ2_CAT_CLOTHING_MEGAPACKS,
    BZ2_CAT_COSMETICS_BATHING,
    BZ2_CAT_COTS,
    BZ2_CAT_CRAFTSMEN,
    BZ2_CAT_CROSSCOUNTRY_SKIING,
    BZ2_CAT_CROSSCOUNTRY_SKIING_CLOTHING,
    BZ2_CAT_CROSSCOUNTRY_SKIING_FOOTWEAR,
    BZ2_CAT_CROSSCOUNTRY_SKIING_OTHER,
    BZ2_CAT_CROSSCOUNTRY_SKIS,
    BZ2_CAT_CYCLING,
    BZ2_CAT_CYCLING_ACCESSORIES,
    BZ2_CAT_CYCLING_CLOTHING,
    BZ2_CAT_CYCLING_FOOTWEAR,
    BZ2_CAT_CYCLING_HELMETS,
    BZ2_CAT_CYCLING_OTHER,
    BZ2_CAT_DANCING,
    BZ2_CAT_DANCING_CLOTHING,
    BZ2_CAT_DANCING_FOOTWEAR,
    BZ2_CAT_DANCING_OTHER,
    BZ2_CAT_DARTS,
    BZ2_CAT_DINING,
    BZ2_CAT_DINING_OTHER,
    BZ2_CAT_DISINFECTION,
    BZ2_CAT_DRESSES,
    BZ2_CAT_ELECTRIC_BICYCLE,
    BZ2_CAT_ELECTRONICS,
    BZ2_CAT_EMPLOYMENT,
    BZ2_CAT_EXERCISE_MATS,
    BZ2_CAT_FACE_MASKS,
    BZ2_CAT_FACE_PAINTING,
    BZ2_CAT_FACE_SHIELDS,
    BZ2_CAT_FANCY_DRESS,
    BZ2_CAT_FASHION_ACCESSORIES,
    BZ2_CAT_FASHION_ACCESSORIES_ACCESSORIES,
    BZ2_CAT_FASHION_ACCESSORIES_HANDBAGS,
    BZ2_CAT_FASHION_ACCESSORIES_JEWELRY,
    BZ2_CAT_FEET_TROUSERS,
    BZ2_CAT_FIGHTING,
    BZ2_CAT_FIGHTING_ACCESSORIES,
    BZ2_CAT_FIGHTING_CLOTHING,
    BZ2_CAT_FIGHTING_OTHER,
    BZ2_CAT_FINANCE,
    BZ2_CAT_FISHING,
    BZ2_CAT_FISHING_BOATS,
    BZ2_CAT_FISHING_CHAIRS,
    BZ2_CAT_FISHING_OTHER,
    BZ2_CAT_FISHING_RODS,
    BZ2_CAT_FISHING_SHELTERS,
    BZ2_CAT_FITNESS_MACHINES,
    BZ2_CAT_FLOORBALL,
    BZ2_CAT_FLOORBALL_CLOTHING,
    BZ2_CAT_FLOORBALL_OTHER,
    BZ2_CAT_FLOORBALL_STICKS,
    BZ2_CAT_FOOD,
    BZ2_CAT_FOOTBALL,
    BZ2_CAT_FOOTBALL_BALLS,
    BZ2_CAT_FOOTBALL_CLOTHING,
    BZ2_CAT_FOOTBALL_FOOTWEAR,
    BZ2_CAT_FOOTBALL_OTHER,
    BZ2_CAT_FOOTMUFFS,
    BZ2_CAT_FOOTWEAR,
    BZ2_CAT_GARDEN,
    BZ2_CAT_GIFTS,
    BZ2_CAT_GOLF,
    BZ2_CAT_GOLF_ACCESSORIES,
    BZ2_CAT_GOLF_CLOTHING,
    BZ2_CAT_GOLF_CLUBS,
    BZ2_CAT_GOLF_FOOTWEAR,
    BZ2_CAT_GOLF_OTHER,
    BZ2_CAT_GUIDE_BAR,
    BZ2_CAT_GYMNASTICS_BALLET,
    BZ2_CAT_GYMNASTICS_BALLET_ACCESSORIES,
    BZ2_CAT_GYMNASTICS_BALLET_CLOTHING,
    BZ2_CAT_GYMNASTICS_BALLET_FOOTWEAR,
    BZ2_CAT_GYMNASTICS_BALLET_OTHER,
    BZ2_CAT_HATS_MITTENS_SCARVES,
    BZ2_CAT_HEALTH_AND_BEAUTY,
    BZ2_CAT_HEAVY_MACHINERY,
    BZ2_CAT_HIGH_CHAIRS,
    BZ2_CAT_HIKING,
    BZ2_CAT_HIKING_BABY_CARRIES,
    BZ2_CAT_HIKING_BACKPACK,
    BZ2_CAT_HIKING_CLOTHING,
    BZ2_CAT_HIKING_FOOTWEAR,
    BZ2_CAT_HIKING_MATS,
    BZ2_CAT_HIKING_OTHER,
    BZ2_CAT_HIKING_POLES,
    BZ2_CAT_HIKING_SLEEPING_BAGS,
    BZ2_CAT_HIKING_TENTS,
    BZ2_CAT_HOBBY_CLASSES,
    BZ2_CAT_HOCKEY_STICKS_PUCKS,
    BZ2_CAT_HOME_ACCESSORIES_DECORATIONS,
    BZ2_CAT_HOME_APPLIANCES,
    BZ2_CAT_HOME_CARE_CLEANING,
    BZ2_CAT_HOME_CARPETS_RUGS,
    BZ2_CAT_HOME_COOKING_BAKING_WARE,
    BZ2_CAT_HOME_DINING,
    BZ2_CAT_HOME_FOOD_STORAGE,
    BZ2_CAT_HOME_FURNITURE,
    BZ2_CAT_HOME_LIGHTING,
    BZ2_CAT_HOME_LIVING,
    BZ2_CAT_HOME_TEXTILES,
    BZ2_CAT_HORSEBACK_RIDING,
    BZ2_CAT_HORSEBACK_RIDING_CLOTHING,
    BZ2_CAT_HORSEBACK_RIDING_FOOTWEAR,
    BZ2_CAT_HORSEBACK_RIDING_HELMETS,
    BZ2_CAT_HORSEBACK_RIDING_OTHER,
    BZ2_CAT_HOUSE_AND_GARDEN,
    BZ2_CAT_HOUSEHOLD_HELP,
    BZ2_CAT_HUNTING,
    BZ2_CAT_ICE_HOCKEY,
    BZ2_CAT_ICE_HOCKEY_CLOTHING,
    BZ2_CAT_ICE_HOCKEY_FOOTWEAR,
    BZ2_CAT_ICE_HOCKEY_HELMETS,
    BZ2_CAT_ICE_HOCKEY_OTHER,
    BZ2_CAT_INLINE_SKATES,
    BZ2_CAT_INTERNSHIPS,
    BZ2_CAT_JACKETS_COATS,
    BZ2_CAT_JOBS,
    BZ2_CAT_KIDS_PARTY,
    BZ2_CAT_KINDERGARDEN_SCHOOL,
    BZ2_CAT_LEGGINGS_JEGGINGS,
    BZ2_CAT_MAJORETTES,
    BZ2_CAT_MAJORETTES_CLOTHING,
    BZ2_CAT_MAJORETTES_OTHER,
    BZ2_CAT_MEDICAL_TESTS,
    BZ2_CAT_MISC,
    BZ2_CAT_MISC_MOTHERHOOD,
    BZ2_CAT_MOTHERS_NOURISHMENT,
    BZ2_CAT_MOTOCROSS,
    BZ2_CAT_MOTOCROSS_CLOTHING,
    BZ2_CAT_MOTOCROSS_HELMETS,
    BZ2_CAT_MOTOCROSS_MACHINES,
    BZ2_CAT_MOTOCROSS_OTHER,
    BZ2_CAT_MOTORCYCLES,
    BZ2_CAT_MOTORHOMES,
    BZ2_CAT_MOUNTAINEERING,
    BZ2_CAT_MOUNTAINEERING_CLIPS,
    BZ2_CAT_MOUNTAINEERING_CLOTHING,
    BZ2_CAT_MOUNTAINEERING_FOOTWEAR,
    BZ2_CAT_MOUNTAINEERING_HELMETS,
    BZ2_CAT_MOUNTAINEERING_OTHER,
    BZ2_CAT_MULTIMEDIA,
    BZ2_CAT_MULTIMEDIA_AUDIO,
    BZ2_CAT_MULTIMEDIA_BOOKS,
    BZ2_CAT_MULTIMEDIA_CALENDARS,
    BZ2_CAT_MULTIMEDIA_COLORING,
    BZ2_CAT_MULTIMEDIA_MAGAZINES,
    BZ2_CAT_MULTIMEDIA_VIDEO,
    BZ2_CAT_NAPPIES,
    BZ2_CAT_NOURISHMENT,
    BZ2_CAT_NURSERY,
    BZ2_CAT_NURSING_BRA,
    BZ2_CAT_OCCASION_WEAR,
    BZ2_CAT_OTHER_ELECTRONICS,
    BZ2_CAT_OTHER_SERVICES,
    BZ2_CAT_OUTFITS,
    BZ2_CAT_OVERALLS,
    BZ2_CAT_PC_GAMING,
    BZ2_CAT_PC_NOTEBOOKS,
    BZ2_CAT_PHONES_TABLETS_EREADERS,
    BZ2_CAT_PHOTOGRAPHY,
    BZ2_CAT_PING_PONG_BEDMINTON,
    BZ2_CAT_PING_PONG_BEDMINTON_BALLS,
    BZ2_CAT_PING_PONG_BEDMINTON_OTHER,
    BZ2_CAT_PING_PONG_BEDMINTON_RACKETS,
    BZ2_CAT_PRAMS,
    BZ2_CAT_PRAMS_ACCESSORIES,
    BZ2_CAT_PRAMS_AND_ACCESSORIES,
    BZ2_CAT_PREGNANCY_CLOTHING,
    BZ2_CAT_PREGNANCY_MOTHERS,
    BZ2_CAT_PROTECTIVE_EQUIPMENT,
    BZ2_CAT_PROTECTIVE_EQUIPMENT_OTHER,
    BZ2_CAT_RAINWEAR,
    BZ2_CAT_RENTAL,
    BZ2_CAT_RESPIRATORS,
    BZ2_CAT_RETRAINING,
    BZ2_CAT_ROLLER_SKATES,
    BZ2_CAT_ROLLER_SKATING,
    BZ2_CAT_ROLLER_SKATING_HELMETS,
    BZ2_CAT_ROLLER_SKATING_OTHER,
    BZ2_CAT_ROMPERS,
    BZ2_CAT_RUNNING,
    BZ2_CAT_RUNNING_ACCESSORIES,
    BZ2_CAT_RUNNING_CLOTHING,
    BZ2_CAT_RUNNING_FOOTWEAR,
    BZ2_CAT_RUNNING_OTHER,
    BZ2_CAT_SAFETY,
    BZ2_CAT_SAFETY_GLASSES,
    BZ2_CAT_SAFETY_GLOVES,
    BZ2_CAT_SCOOTER,
    BZ2_CAT_SEO_BABY_ACCESSORIES,
    BZ2_CAT_SEO_BLOUSE_SHIRTS,
    BZ2_CAT_SEO_BODYSUITS_SLEEPSUITS_ROMPERS,
    BZ2_CAT_SEO_CLOTHING_MEGAPACKS,
    BZ2_CAT_SEO_DRESSES,
    BZ2_CAT_SEO_DRESSES_SKIRTS,
    BZ2_CAT_SEO_HATS_MITTENS_SCARVES,
    BZ2_CAT_SEO_JACKETS_COATS,
    BZ2_CAT_SEO_OCCASION_WEAR,
    BZ2_CAT_SEO_OUTFITS,
    BZ2_CAT_SEO_SHORTS_CROPS,
    BZ2_CAT_SEO_SKIRTS,
    BZ2_CAT_SEO_SLEEPING_BAGS,
    BZ2_CAT_SEO_SLEEPWEAR,
    BZ2_CAT_SEO_SNOWSUITS,
    BZ2_CAT_SEO_SOCKS_TIGHTS,
    BZ2_CAT_SEO_SWEATSHIRTS_JUMPERS_VESTS,
    BZ2_CAT_SEO_SWIMWEAR_DRESSING_GOWNS,
    BZ2_CAT_SEO_TROUSERS_JEANS_JOGGERS,
    BZ2_CAT_SEO_TSHIRTS_TOPS,
    BZ2_CAT_SEO_TWINS,
    BZ2_CAT_SEO_TWINS_MISC,
    BZ2_CAT_SEO_UNDERWEAR,
    BZ2_CAT_SERVICES,
    BZ2_CAT_SHORTS_CROPS,
    BZ2_CAT_SKATEBOARDS,
    BZ2_CAT_SKATEBOARDS_BOARDS,
    BZ2_CAT_SKATEBOARDS_HELMETS,
    BZ2_CAT_SKATEBOARDS_OTHER,
    BZ2_CAT_SKI_FOOTWEAR,
    BZ2_CAT_SKI_GOGGLES,
    BZ2_CAT_SKI_HELMETS,
    BZ2_CAT_SKI_POLES,
    BZ2_CAT_SKIRTS,
    BZ2_CAT_SKIS,
    BZ2_CAT_SKIS_SNOWBOARDS,
    BZ2_CAT_SKIS_SNOWBOARDS_OTHER,
    BZ2_CAT_SLEDGES_BOBSLEIGHS,
    BZ2_CAT_SLEEPING_BAGS,
    BZ2_CAT_SLEEPSUITS,
    BZ2_CAT_SLEEPWEAR,
    BZ2_CAT_SNORKELS_AND_FINS,
    BZ2_CAT_SNOWBOARD_FOOTWEAR,
    BZ2_CAT_SNOWBOARDS,
    BZ2_CAT_SNOWSUITS,
    BZ2_CAT_SOCKS_TIGHTS,
    BZ2_CAT_SPORT,
    BZ2_CAT_SPORT_BOTTLES,
    BZ2_CAT_SPORT_BOTTLES_BOTTLES,
    BZ2_CAT_SPORT_BOTTLES_OTHER,
    BZ2_CAT_SPORT_MASSAGE,
    BZ2_CAT_SPORT_OTHERS,
    BZ2_CAT_SPORT_OTHERS_ACCESSORIES,
    BZ2_CAT_SPORT_OTHERS_CLOTHING,
    BZ2_CAT_SPORT_OTHERS_FOOTWEAR,
    BZ2_CAT_SPORT_OTHERS_OTHER,
    BZ2_CAT_SPORT_VITAMINS,
    BZ2_CAT_SPORT_WATCHES,
    BZ2_CAT_SPORTS_BAGS,
    BZ2_CAT_STRENGHTENING_AIDS,
    BZ2_CAT_SWEATSHIRTS_JUMPERS_VESTS,
    BZ2_CAT_SWIMMING_CAPS,
    BZ2_CAT_SWIMMING_CIRCLES_SLEEVES,
    BZ2_CAT_SWIMMING_DIVING,
    BZ2_CAT_SWIMMING_GOGGLES,
    BZ2_CAT_SWIMMING_NEOPRENES,
    BZ2_CAT_SWIMMING_OTHER,
    BZ2_CAT_SWIMWEAR_DRESSING_GOWNS,
    BZ2_CAT_TENNIS_SQUASH,
    BZ2_CAT_TENNIS_SQUASH_BALLS,
    BZ2_CAT_TENNIS_SQUASH_CLOTHING,
    BZ2_CAT_TENNIS_SQUASH_FOOTWEAR,
    BZ2_CAT_TENNIS_SQUASH_OTHER,
    BZ2_CAT_TENNIS_SQUASH_RACKETS,
    BZ2_CAT_THERMOMETERS,
    BZ2_CAT_TICKETS_VOUCHERS,
    BZ2_CAT_TO_THE_GARAGE,
    BZ2_CAT_TOYS,
    BZ2_CAT_TRAMPOLINES,
    BZ2_CAT_TRAMPOLINES_SPARE_PARTS,
    BZ2_CAT_TRAMPOLINES_STAIRS,
    BZ2_CAT_TRAMPOLINES_TRAMPOLINES,
    BZ2_CAT_TRIKE,
    BZ2_CAT_TROLLEYS_TRAILERS,
    BZ2_CAT_TROUSERS_JEANS_JOGGERS,
    BZ2_CAT_TRUCKS,
    BZ2_CAT_TSHIRTS_TOPS,
    BZ2_CAT_TUTORING,
    BZ2_CAT_TWINS,
    BZ2_CAT_TWINS_GROUP,
    BZ2_CAT_TWINS_MISC,
    BZ2_CAT_UNDERWEAR,
    BZ2_CAT_VETERAN_VEHICLES,
    BZ2_CAT_VIDEO_AND_PHOTOGRAPHY,
    BZ2_CAT_WEIGHT_TRAINING,
    BZ2_CAT_WEIGHT_TRAINING_CLOTHING,
    BZ2_CAT_WEIGHT_TRAINING_OTHER,
    BZ2_CAT_WORK_ABROAD,
    BZ2_CAT_WORK_CLOTHES_FOOTWEAR,
    BZ2_ESHOP_PRODUCT_HOVER,
    BZ2_FACET_CATEGORY,
    BZ2_FACET_CATS,
    BZ2_FACET_GROUPS,
    BZ2_FACET_LOCATION,
    BZ2_FACET_LOCATIONS,
    BZ2_FACET_P_AGE_GROUP,
    BZ2_FACET_P_AGE_MONTHS,
    BZ2_FACET_P_AGE_YEARS,
    BZ2_FACET_P_ANIMAL,
    BZ2_FACET_P_BRACES,
    BZ2_FACET_P_BRAND,
    BZ2_FACET_P_BRAND_MODEL,
    BZ2_FACET_P_BRAND_MODELS,
    BZ2_FACET_P_COLOR,
    BZ2_FACET_P_CUP_SIZE,
    BZ2_FACET_P_DATA_CARRIER,
    BZ2_FACET_P_DRESSING,
    BZ2_FACET_P_EDUCATION,
    BZ2_FACET_P_FIT,
    BZ2_FACET_P_FITS_ON_MODEL,
    BZ2_FACET_P_FOR_WHOM,
    BZ2_FACET_P_FREQUENCY,
    BZ2_FACET_P_FUEL,
    BZ2_FACET_P_GAMING_PLATFORM,
    BZ2_FACET_P_HEEL,
    BZ2_FACET_P_HOMEOFFICE,
    BZ2_FACET_P_HOOD,
    BZ2_FACET_P_ISOFIX,
    BZ2_FACET_P_LANGUAGE,
    BZ2_FACET_P_LAYERS,
    BZ2_FACET_P_LENGTH,
    BZ2_FACET_P_LENGTH_ORDINAL,
    BZ2_FACET_P_LEVEL,
    BZ2_FACET_P_MANUFACTURER,
    BZ2_FACET_P_MATERIAL,
    BZ2_FACET_P_MATERNITY_LEAVE_SUITABLE,
    BZ2_FACET_P_NECKLINE,
    BZ2_FACET_P_NUMBER_OF_DOORS,
    BZ2_FACET_P_NUMBER_OF_SEATS,
    BZ2_FACET_P_OCCASION,
    BZ2_FACET_P_PLACE,
    BZ2_FACET_P_PLACEMENT,
    BZ2_FACET_P_PRACTICE_LENGTH,
    BZ2_FACET_P_PRICE,
    BZ2_FACET_P_QUALITY,
    BZ2_FACET_P_SCHOOL_SUBJECT,
    BZ2_FACET_P_SCREEN_SIZE,
    BZ2_FACET_P_SEASON,
    BZ2_FACET_P_SEAT_FACING,
    BZ2_FACET_P_SEX,
    BZ2_FACET_P_SEX_TWIN,
    BZ2_FACET_P_SHAPE,
    BZ2_FACET_P_SIZE,
    BZ2_FACET_P_SIZE_INTERVAL,
    BZ2_FACET_P_SIZE_WEIGHT,
    BZ2_FACET_P_SLEEPING_PLACES,
    BZ2_FACET_P_SLEEVE,
    BZ2_FACET_P_SPACE_TYPE,
    BZ2_FACET_P_STYLE,
    BZ2_FACET_P_SUBJECT,
    BZ2_FACET_P_THEMA,
    BZ2_FACET_P_TRANSMISSION,
    BZ2_FACET_P_TYPE,
    BZ2_FACET_P_USAGE,
    BZ2_FACET_P_WEIGHT,
    BZ2_FACET_P_WIDTH_ORDINAL,
    BZ2_FACET_P_WORKING_TIME,
    BZ2_FACET_P_YEAR_OF_MANUFACTURE,
    BZ2_FACET_PLACES,
    BZ2_FACET_QUALITY,
    BZ2_FACET_SCREEN_SIZE,
    BZ2_HANDMADE_PRODUCT_HOVER,
    BZ2_JOBS_PRODUCT_HOVER,
    BZ2_NEW_PRODUCT_HOVER,
    BZ2_OLD_PRODUCT_HOVER,
    BZ2_SERVICES_PRODUCT_HOVER,
    BZ2_SORT_COMPLAINT_TIME,
    BZ2_SORT_COMPLAINT_TIME_ASC,
    BZ2_SORT_COMPLAINTS,
    BZ2_SORT_COMPLAINTS_ASC,
    BZ2_SORT_PRICE,
    BZ2_SORT_PRICE_DESC,
    BZ2_SORT_RECENT_ADDED,
    BZ2_SORT_RECENT_UPDATED,
    BZ2_SORT_SCORE,
    BZ2_SORT_SCORE_NEW,
    BZ2_SORT_WATCHDOG,
    BZ2_SORT_WISHLIST_ADDED_ASC,
    BZ2_SORT_WISHLIST_ADDED_DESC,
    PRODUCT_FILTER_ACTUALIZABLE,
    PRODUCT_FILTER_ALL,
    PRODUCT_FILTER_DELETED,
    PRODUCT_FILTER_DISPLAYING,
    PRODUCT_FILTER_EXPIRED,
    PRODUCT_FILTER_NONDISPLAYING,
    PRODUCT_FILTER_NOTANSWERED,
} from 'mk/autogenerated/translations/enums.529348059c865ac48d46749de662f6ae'

// order of this is kinda important as this goes directly to the selector widget
// and it renders the options by order they are in this map (js does not have
// guaranteed map order but seems consistent in all implementations)
export const SORTING_VALUES = {
    update: BZ2_SORT_RECENT_UPDATED,
    creation: BZ2_SORT_RECENT_ADDED,
    price: BZ2_SORT_PRICE,
    price_desc: BZ2_SORT_PRICE_DESC,
    score_new: BZ2_SORT_SCORE_NEW,
    complaints: BZ2_SORT_COMPLAINTS,
    complaints_asc: BZ2_SORT_COMPLAINTS_ASC,
    complaint_time: BZ2_SORT_COMPLAINT_TIME,
    complaint_time_asc: BZ2_SORT_COMPLAINT_TIME_ASC,
    watchdog: BZ2_SORT_WATCHDOG,
    score: BZ2_SORT_SCORE,
};

export const WISHLIST_SORTING_VALUES = {
    wishlist: BZ2_SORT_WISHLIST_ADDED_DESC,
    wishlist_asc: BZ2_SORT_WISHLIST_ADDED_ASC,
};

// keep in sync with python code in bazaar.api.v2.utils.filter.MY_BAZAAR_ORDER_BY
export const MY_SORTING_VALUES = {
    new_messages: BY_NEW_MESSAGES,
    title: BY_TITLE,
    title_reversed: BY_TITLE_REVERSED,
    updated_latest: BY_REPOST_TIME_NEWEST,
    updated_oldest: BY_REPOST_TIME_OLDEST,
    created_newest: BY_CREATION_TIME_NEWEST,
    created_oldest: BY_CREATION_TIME_OLDEST,
    price_asc: BY_PRICE_ASC,
    price_desc: BY_PRICE_DESC,
};

export const MY_FILTER_VALUES = {
    all: PRODUCT_FILTER_ALL,
    actualizable: PRODUCT_FILTER_ACTUALIZABLE,
    displaying: PRODUCT_FILTER_DISPLAYING,
    nondisplaying: PRODUCT_FILTER_NONDISPLAYING,
    expired: PRODUCT_FILTER_EXPIRED,
    deleted: PRODUCT_FILTER_DELETED,
    notanswered: PRODUCT_FILTER_NOTANSWERED,
};

export const QUALITY_VALUES = {
    old: {
        label: BZ2_OLD_PRODUCT_HOVER,
        max: -1,
        max_plus: -1,
        max_pro: -1,
    },
    new: {
        label: BZ2_NEW_PRODUCT_HOVER,
        max: 20,
        max_plus: 20,
        max_pro: -1,
    },
    handmade: {
        label: BZ2_HANDMADE_PRODUCT_HOVER,
        max: 75,
        max_plus: 75,
        max_pro: -1,
    },
    services: {
        label: BZ2_SERVICES_PRODUCT_HOVER,
        max: -1,
        max_plus: -1,
        max_pro: -1,
    },
    jobs: {
        label: BZ2_JOBS_PRODUCT_HOVER,
        max: 0,
        max_plus: 0,
        max_pro: 10,
    },
    eshop: {
        label: BZ2_ESHOP_PRODUCT_HOVER,
        max: 0,
        max_plus: 0,
        max_pro: 0,
    },
};

// let COUNTY_SK = {
//    1:'Bánovce nad Bebravou',
//    2:'Banská Bystrica',
//    3:'Banská Štiavnica',
//    4:'Bardejov',
//    5:'Bratislava',
//    6:'Brezno',
//    7:'Bytča',
//    8:'Čadca',
//    9:'Detva',
//    10:'Dolný Kubín',
//    11:'Dunajská Streda',
//    12:'Galanta',
//    13:'Gelnica',
//    14:'Hlohovec',
//    15:'Humenné',
//    16:'Ilava',
//    17:'Kežmarok',
//    18:'Komárno',
//    19:'Košice',
//    20:'Košice-okolie',
//    21:'Krupina',
//    22:'Kysucké Nové Mesto',
//    23:'Levice',
//    24:'Levoča',
//    25:'Liptovský Mikuláš',
//    26:'Lučenec',
//    27:'Malacky',
//    28:'Martin',
//    29:'Medzilaborce',
//    30:'Michalovce',
//    31:'Myjava',
//    32:'Námestovo',
//    33:'Nitra',
//    34:'Nové Mesto nad Váhom',
//    35:'Nové Zámky',
//    36:'Partizánske',
//    37:'Pezinok',
//    38:'Piešťany',
//    39:'Poltár',
//    40:'Poprad',
//    41:'Považská Bystrica',
//    42:'Prešov',
//    43:'Prievidza',
//    44:'Revúca',
//    45:'Rimavská Sobota',
//    46:'Rožňava',
//    47:'Ružomberok',
//    48:'Sabinov',
//    49:'Senec',
//    50:'Senica',
//    51:'Skalica',
//    52:'Snina',
//    53:'Sobrance',
//    54:'Spišská Nová Ves',
//    55:'Stará Ľubovňa',
//    56:'Stropkov',
//    57:'Svidník',
//    58:'Šaľa',
//    59:'Topoľčany',
//    60:'Trebišov',
//    61:'Trenčín',
//    62:'Trnava',
//    63:'Turčianske Teplice',
//    64:'Tvrdošín',
//    65:'Veľký Krtíš',
//    66:'Vranov nad Topľou',
//    67:'Zlaté Moravce',
//    68:'Zvolen',
//    69:'Žarnovica',
//    70:'Žiar nad Hronom',
//    71:'Žilina',
//    72:'Púchov'
// };

// index within categories where adults cats starts
export const ADULT_INDEX = 14;

// keep in sync with Product stock statuses in bazaar/models.py
export const STOCK_STATUS = {
    NOT_IN_STOCK: 0,
    IN_STOCK: 1,
};

export const FACET_NAMES = {
    BZ2_FACET_CATEGORY,
    BZ2_FACET_CATS,
    BZ2_FACET_GROUPS,
    BZ2_FACET_LOCATION,
    BZ2_FACET_LOCATIONS,
    BZ2_FACET_P_AGE_GROUP,
    BZ2_FACET_P_AGE_MONTHS,
    BZ2_FACET_P_AGE_YEARS,
    BZ2_FACET_P_ANIMAL,
    BZ2_FACET_P_BRACES,
    BZ2_FACET_P_BRAND,
    BZ2_FACET_P_BRAND_MODEL,
    BZ2_FACET_P_BRAND_MODELS,
    BZ2_FACET_P_COLOR,
    BZ2_FACET_P_CUP_SIZE,
    BZ2_FACET_P_DATA_CARRIER,
    BZ2_FACET_P_DRESSING,
    BZ2_FACET_P_EDUCATION,
    BZ2_FACET_P_FIT,
    BZ2_FACET_P_FOR_WHOM,
    BZ2_FACET_P_FREQUENCY,
    BZ2_FACET_P_FUEL,
    BZ2_FACET_P_GAMING_PLATFORM,
    BZ2_FACET_P_HEEL,
    BZ2_FACET_P_HOMEOFFICE,
    BZ2_FACET_P_HOOD,
    BZ2_FACET_P_ISOFIX,
    BZ2_FACET_P_LANGUAGE,
    BZ2_FACET_P_LAYERS,
    BZ2_FACET_P_LENGTH,
    BZ2_FACET_P_LENGTH_ORDINAL,
    BZ2_FACET_P_LEVEL,
    BZ2_FACET_P_MATERIAL,
    BZ2_FACET_P_MATERNITY_LEAVE_SUITABLE,
    BZ2_FACET_P_NECKLINE,
    BZ2_FACET_P_NUMBER_OF_DOORS,
    BZ2_FACET_P_NUMBER_OF_SEATS,
    BZ2_FACET_P_OCCASION,
    BZ2_FACET_P_PLACE,
    BZ2_FACET_P_PLACEMENT,
    BZ2_FACET_P_PRACTICE_LENGTH,
    BZ2_FACET_P_PRICE,
    BZ2_FACET_P_QUALITY,
    BZ2_FACET_P_SCHOOL_SUBJECT,
    BZ2_FACET_P_SCREEN_SIZE,
    BZ2_FACET_P_SEASON,
    BZ2_FACET_P_SEAT_FACING,
    BZ2_FACET_P_SEX,
    BZ2_FACET_P_SEX_TWIN,
    BZ2_FACET_P_SHAPE,
    BZ2_FACET_P_SIZE,
    BZ2_FACET_P_SIZE_INTERVAL,
    BZ2_FACET_P_SIZE_WEIGHT,
    BZ2_FACET_P_SLEEPING_PLACES,
    BZ2_FACET_P_SLEEVE,
    BZ2_FACET_P_SPACE_TYPE,
    BZ2_FACET_P_STYLE,
    BZ2_FACET_P_SUBJECT,
    BZ2_FACET_P_THEMA,
    BZ2_FACET_P_TRANSMISSION,
    BZ2_FACET_P_TYPE,
    BZ2_FACET_P_USAGE,
    BZ2_FACET_P_WEIGHT,
    BZ2_FACET_P_WIDTH_ORDINAL,
    BZ2_FACET_P_WORKING_TIME,
    BZ2_FACET_P_YEAR_OF_MANUFACTURE,
    BZ2_FACET_PLACES,
    BZ2_FACET_QUALITY,
    BZ2_FACET_SCREEN_SIZE,
    BZ2_FACET_P_MANUFACTURER,
    BZ2_FACET_P_FITS_ON_MODEL,
};

export const CATEGORY_SEO_NAMES = {
    BZ2_CAT_SEO_UNDERWEAR,
    BZ2_CAT_SEO_BLOUSE_SHIRTS,
    BZ2_CAT_SEO_SWEATSHIRTS_JUMPERS_VESTS,
    BZ2_CAT_SEO_SLEEPING_BAGS,
    BZ2_CAT_SEO_SWIMWEAR_DRESSING_GOWNS,
    BZ2_CAT_SEO_DRESSES,
    BZ2_CAT_SEO_DRESSES_SKIRTS,
    BZ2_CAT_SEO_JACKETS_COATS,
    BZ2_CAT_SEO_CLOTHING_MEGAPACKS,
    BZ2_CAT_SEO_SOCKS_TIGHTS,
    BZ2_CAT_SEO_TROUSERS_JEANS_JOGGERS,
    BZ2_CAT_SEO_SNOWSUITS,
    BZ2_CAT_SEO_BABY_ACCESSORIES,
    BZ2_CAT_SEO_BODYSUITS_SLEEPSUITS_ROMPERS,
    BZ2_CAT_SEO_SHORTS_CROPS,
    BZ2_CAT_SEO_HATS_MITTENS_SCARVES,
    BZ2_CAT_SEO_TSHIRTS_TOPS,
    BZ2_CAT_SEO_OUTFITS,
    BZ2_CAT_SEO_SKIRTS,
    BZ2_CAT_SEO_SLEEPWEAR,
    BZ2_CAT_SEO_OCCASION_WEAR,
    BZ2_CAT_SEO_TWINS,
    BZ2_CAT_SEO_TWINS_MISC,
};

export const CATEGORY_NAMES = {
    BZ2_CAT_ACCOMMODATION,
    BZ2_CAT_ACCOMMODATION_RENTAL,
    BZ2_CAT_ADULTS_HANDICRAFTS,
    BZ2_CAT_ADULTS_HEALTH_BEAUTY,
    BZ2_CAT_ADULTS_HOMEWARE,
    BZ2_CAT_ADULTS_MISC,
    BZ2_CAT_ANIMALS,
    BZ2_CAT_ANTIQUE_ART_OBJECTS,
    BZ2_CAT_ANTIQUE_BADGES,
    BZ2_CAT_ANTIQUE_BOOKS,
    BZ2_CAT_ANTIQUE_CERAMICS,
    BZ2_CAT_ANTIQUE_CLOCKS,
    BZ2_CAT_ANTIQUE_FURNITURE,
    BZ2_CAT_ANTIQUE_JEWELRY,
    BZ2_CAT_ANTIQUE_MODELS,
    BZ2_CAT_ANTIQUE_MONEY,
    BZ2_CAT_ANTIQUE_MUSICAL_INSTRUMENTS,
    BZ2_CAT_ANTIQUE_OTHERS,
    BZ2_CAT_ANTIQUE_PICTURES,
    BZ2_CAT_ANTIQUE_POSTERS,
    BZ2_CAT_ANTIQUE_STAMPS,
    BZ2_CAT_ANTIQUE_TECHNOLOGY,
    BZ2_CAT_ANTIQUE_TOYS,
    BZ2_CAT_ANTIQUES,
    BZ2_CAT_ARCHERY,
    BZ2_CAT_ATVS,
    BZ2_CAT_AUTOMOTO,
    BZ2_CAT_AUTOMOTO_SPARE_PARTS,
    BZ2_CAT_BABY_CARRIERS,
    BZ2_CAT_BABY_FEEDING,
    BZ2_CAT_BABY_MISC,
    BZ2_CAT_BABYSITTING,
    BZ2_CAT_BALANCE_BIKE,
    BZ2_CAT_BALL_GAMES,
    BZ2_CAT_BALL_GAMES_BALLS,
    BZ2_CAT_BALL_GAMES_OTHER,
    BZ2_CAT_BASEBALL,
    BZ2_CAT_BASEBALL_BATS,
    BZ2_CAT_BASEBALL_OTHER,
    BZ2_CAT_BASKETBALL,
    BZ2_CAT_BASKETBALL_BALLS,
    BZ2_CAT_BASKETBALL_CLOTHING,
    BZ2_CAT_BASKETBALL_FOOTWEAR,
    BZ2_CAT_BASKETBALL_OTHER,
    BZ2_CAT_BEACH_TENTS_CHAIRS,
    BZ2_CAT_BICYCLE,
    BZ2_CAT_BICYCLE_SEAT,
    BZ2_CAT_BICYCLE_TRAILER,
    BZ2_CAT_BIRTH,
    BZ2_CAT_BLANKETS_WRAPPERS,
    BZ2_CAT_BLOUSE_SHIRTS,
    BZ2_CAT_BOATING,
    BZ2_CAT_BOATING_BARRELS,
    BZ2_CAT_BOATING_BOATS,
    BZ2_CAT_BOATING_LIFE_JACKETS,
    BZ2_CAT_BOATING_OTHER,
    BZ2_CAT_BOATING_PADDLES,
    BZ2_CAT_BODYSUITS,
    BZ2_CAT_BODYSUITS_SLEEPSUITS_ROMPERS,
    BZ2_CAT_BOUNCERS,
    BZ2_CAT_BREASTFEEDING_ACCESSORIES,
    BZ2_CAT_BUILDING_AND_CONSTRUCTION_MATERIAL,
    BZ2_CAT_CAMERA_PHOTO_VIDEO,
    BZ2_CAT_CAR_SEATS,
    BZ2_CAT_CAR_SEATS_ACCESSORIES,
    BZ2_CAT_CAR_SEATS_AND_ACCESSORIES,
    BZ2_CAT_CARETAKING,
    BZ2_CAT_CARS,
    BZ2_CAT_CELEBRATIONS,
    BZ2_CAT_CHANGING_BAG,
    BZ2_CAT_CHILDREN_CAMPS,
    BZ2_CAT_CHILDREN_CARE,
    BZ2_CAT_CLOTHING,
    BZ2_CAT_CLOTHING_MEGAPACKS,
    BZ2_CAT_COSMETICS_BATHING,
    BZ2_CAT_COTS,
    BZ2_CAT_CRAFTSMEN,
    BZ2_CAT_CROSSCOUNTRY_SKIING,
    BZ2_CAT_CROSSCOUNTRY_SKIING_CLOTHING,
    BZ2_CAT_CROSSCOUNTRY_SKIING_FOOTWEAR,
    BZ2_CAT_CROSSCOUNTRY_SKIING_OTHER,
    BZ2_CAT_CROSSCOUNTRY_SKIS,
    BZ2_CAT_CYCLING,
    BZ2_CAT_CYCLING_ACCESSORIES,
    BZ2_CAT_CYCLING_CLOTHING,
    BZ2_CAT_CYCLING_FOOTWEAR,
    BZ2_CAT_CYCLING_HELMETS,
    BZ2_CAT_CYCLING_OTHER,
    BZ2_CAT_DANCING,
    BZ2_CAT_DANCING_CLOTHING,
    BZ2_CAT_DANCING_FOOTWEAR,
    BZ2_CAT_DANCING_OTHER,
    BZ2_CAT_DARTS,
    BZ2_CAT_DINING,
    BZ2_CAT_DINING_OTHER,
    BZ2_CAT_DISINFECTION,
    BZ2_CAT_DRESSES,
    BZ2_CAT_ELECTRIC_BICYCLE,
    BZ2_CAT_ELECTRONICS,
    BZ2_CAT_EMPLOYMENT,
    BZ2_CAT_EXERCISE_MATS,
    BZ2_CAT_FACE_MASKS,
    BZ2_CAT_FACE_PAINTING,
    BZ2_CAT_FACE_SHIELDS,
    BZ2_CAT_FANCY_DRESS,
    BZ2_CAT_FASHION_ACCESSORIES,
    BZ2_CAT_FASHION_ACCESSORIES_ACCESSORIES,
    BZ2_CAT_FASHION_ACCESSORIES_HANDBAGS,
    BZ2_CAT_FASHION_ACCESSORIES_JEWELRY,
    BZ2_CAT_FEET_TROUSERS,
    BZ2_CAT_FIGHTING,
    BZ2_CAT_FIGHTING_ACCESSORIES,
    BZ2_CAT_FIGHTING_CLOTHING,
    BZ2_CAT_FIGHTING_OTHER,
    BZ2_CAT_FINANCE,
    BZ2_CAT_FISHING,
    BZ2_CAT_FISHING_BOATS,
    BZ2_CAT_FISHING_CHAIRS,
    BZ2_CAT_FISHING_OTHER,
    BZ2_CAT_FISHING_RODS,
    BZ2_CAT_FISHING_SHELTERS,
    BZ2_CAT_FITNESS_MACHINES,
    BZ2_CAT_FLOORBALL,
    BZ2_CAT_FLOORBALL_CLOTHING,
    BZ2_CAT_FLOORBALL_OTHER,
    BZ2_CAT_FLOORBALL_STICKS,
    BZ2_CAT_FOOD,
    BZ2_CAT_FOOTBALL,
    BZ2_CAT_FOOTBALL_BALLS,
    BZ2_CAT_FOOTBALL_CLOTHING,
    BZ2_CAT_FOOTBALL_FOOTWEAR,
    BZ2_CAT_FOOTBALL_OTHER,
    BZ2_CAT_FOOTMUFFS,
    BZ2_CAT_FOOTWEAR,
    BZ2_CAT_GARDEN,
    BZ2_CAT_GIFTS,
    BZ2_CAT_GOLF,
    BZ2_CAT_GOLF_ACCESSORIES,
    BZ2_CAT_GOLF_CLOTHING,
    BZ2_CAT_GOLF_CLUBS,
    BZ2_CAT_GOLF_FOOTWEAR,
    BZ2_CAT_GOLF_OTHER,
    BZ2_CAT_GUIDE_BAR,
    BZ2_CAT_GYMNASTICS_BALLET,
    BZ2_CAT_GYMNASTICS_BALLET_ACCESSORIES,
    BZ2_CAT_GYMNASTICS_BALLET_CLOTHING,
    BZ2_CAT_GYMNASTICS_BALLET_FOOTWEAR,
    BZ2_CAT_GYMNASTICS_BALLET_OTHER,
    BZ2_CAT_HATS_MITTENS_SCARVES,
    BZ2_CAT_HEALTH_AND_BEAUTY,
    BZ2_CAT_HEAVY_MACHINERY,
    BZ2_CAT_HIGH_CHAIRS,
    BZ2_CAT_HIKING,
    BZ2_CAT_HIKING_BABY_CARRIES,
    BZ2_CAT_HIKING_BACKPACK,
    BZ2_CAT_HIKING_CLOTHING,
    BZ2_CAT_HIKING_FOOTWEAR,
    BZ2_CAT_HIKING_MATS,
    BZ2_CAT_HIKING_OTHER,
    BZ2_CAT_HIKING_POLES,
    BZ2_CAT_HIKING_SLEEPING_BAGS,
    BZ2_CAT_HIKING_TENTS,
    BZ2_CAT_HOBBY_CLASSES,
    BZ2_CAT_HOCKEY_STICKS_PUCKS,
    BZ2_CAT_HOME_ACCESSORIES_DECORATIONS,
    BZ2_CAT_HOME_APPLIANCES,
    BZ2_CAT_HOME_CARE_CLEANING,
    BZ2_CAT_HOME_CARPETS_RUGS,
    BZ2_CAT_HOME_COOKING_BAKING_WARE,
    BZ2_CAT_HOME_DINING,
    BZ2_CAT_HOME_FOOD_STORAGE,
    BZ2_CAT_HOME_FURNITURE,
    BZ2_CAT_HOME_LIGHTING,
    BZ2_CAT_HOME_LIVING,
    BZ2_CAT_HOME_TEXTILES,
    BZ2_CAT_HORSEBACK_RIDING,
    BZ2_CAT_HORSEBACK_RIDING_CLOTHING,
    BZ2_CAT_HORSEBACK_RIDING_FOOTWEAR,
    BZ2_CAT_HORSEBACK_RIDING_HELMETS,
    BZ2_CAT_HORSEBACK_RIDING_OTHER,
    BZ2_CAT_HOUSE_AND_GARDEN,
    BZ2_CAT_HOUSEHOLD_HELP,
    BZ2_CAT_HUNTING,
    BZ2_CAT_ICE_HOCKEY,
    BZ2_CAT_ICE_HOCKEY_CLOTHING,
    BZ2_CAT_ICE_HOCKEY_FOOTWEAR,
    BZ2_CAT_ICE_HOCKEY_HELMETS,
    BZ2_CAT_ICE_HOCKEY_OTHER,
    BZ2_CAT_INLINE_SKATES,
    BZ2_CAT_INTERNSHIPS,
    BZ2_CAT_JACKETS_COATS,
    BZ2_CAT_JOBS,
    BZ2_CAT_KIDS_PARTY,
    BZ2_CAT_KINDERGARDEN_SCHOOL,
    BZ2_CAT_LEGGINGS_JEGGINGS,
    BZ2_CAT_MAJORETTES,
    BZ2_CAT_MAJORETTES_CLOTHING,
    BZ2_CAT_MAJORETTES_OTHER,
    BZ2_CAT_MEDICAL_TESTS,
    BZ2_CAT_MISC,
    BZ2_CAT_MISC_MOTHERHOOD,
    BZ2_CAT_MOTHERS_NOURISHMENT,
    BZ2_CAT_MOTOCROSS,
    BZ2_CAT_MOTOCROSS_CLOTHING,
    BZ2_CAT_MOTOCROSS_HELMETS,
    BZ2_CAT_MOTOCROSS_MACHINES,
    BZ2_CAT_MOTOCROSS_OTHER,
    BZ2_CAT_MOTORCYCLES,
    BZ2_CAT_MOTORHOMES,
    BZ2_CAT_MOUNTAINEERING,
    BZ2_CAT_MOUNTAINEERING_CLIPS,
    BZ2_CAT_MOUNTAINEERING_CLOTHING,
    BZ2_CAT_MOUNTAINEERING_FOOTWEAR,
    BZ2_CAT_MOUNTAINEERING_HELMETS,
    BZ2_CAT_MOUNTAINEERING_OTHER,
    BZ2_CAT_MULTIMEDIA,
    BZ2_CAT_MULTIMEDIA_AUDIO,
    BZ2_CAT_MULTIMEDIA_BOOKS,
    BZ2_CAT_MULTIMEDIA_CALENDARS,
    BZ2_CAT_MULTIMEDIA_COLORING,
    BZ2_CAT_MULTIMEDIA_MAGAZINES,
    BZ2_CAT_MULTIMEDIA_VIDEO,
    BZ2_CAT_NAPPIES,
    BZ2_CAT_NOURISHMENT,
    BZ2_CAT_NURSERY,
    BZ2_CAT_NURSING_BRA,
    BZ2_CAT_OCCASION_WEAR,
    BZ2_CAT_OTHER_ELECTRONICS,
    BZ2_CAT_OTHER_SERVICES,
    BZ2_CAT_OUTFITS,
    BZ2_CAT_OVERALLS,
    BZ2_CAT_PC_GAMING,
    BZ2_CAT_PC_NOTEBOOKS,
    BZ2_CAT_PHONES_TABLETS_EREADERS,
    BZ2_CAT_PHOTOGRAPHY,
    BZ2_CAT_PING_PONG_BEDMINTON,
    BZ2_CAT_PING_PONG_BEDMINTON_BALLS,
    BZ2_CAT_PING_PONG_BEDMINTON_OTHER,
    BZ2_CAT_PING_PONG_BEDMINTON_RACKETS,
    BZ2_CAT_PRAMS,
    BZ2_CAT_PRAMS_ACCESSORIES,
    BZ2_CAT_PRAMS_AND_ACCESSORIES,
    BZ2_CAT_PREGNANCY_CLOTHING,
    BZ2_CAT_PREGNANCY_MOTHERS,
    BZ2_CAT_PROTECTIVE_EQUIPMENT,
    BZ2_CAT_PROTECTIVE_EQUIPMENT_OTHER,
    BZ2_CAT_RAINWEAR,
    BZ2_CAT_RENTAL,
    BZ2_CAT_RESPIRATORS,
    BZ2_CAT_RETRAINING,
    BZ2_CAT_ROLLER_SKATES,
    BZ2_CAT_ROLLER_SKATING,
    BZ2_CAT_ROLLER_SKATING_HELMETS,
    BZ2_CAT_ROLLER_SKATING_OTHER,
    BZ2_CAT_ROMPERS,
    BZ2_CAT_RUNNING,
    BZ2_CAT_RUNNING_ACCESSORIES,
    BZ2_CAT_RUNNING_CLOTHING,
    BZ2_CAT_RUNNING_FOOTWEAR,
    BZ2_CAT_RUNNING_OTHER,
    BZ2_CAT_SAFETY,
    BZ2_CAT_SAFETY_GLASSES,
    BZ2_CAT_SAFETY_GLOVES,
    BZ2_CAT_SCOOTER,
    BZ2_CAT_SERVICES,
    BZ2_CAT_SHORTS_CROPS,
    BZ2_CAT_SKATEBOARDS,
    BZ2_CAT_SKATEBOARDS_BOARDS,
    BZ2_CAT_SKATEBOARDS_HELMETS,
    BZ2_CAT_SKATEBOARDS_OTHER,
    BZ2_CAT_SKI_FOOTWEAR,
    BZ2_CAT_SKI_GOGGLES,
    BZ2_CAT_SKI_HELMETS,
    BZ2_CAT_SKI_POLES,
    BZ2_CAT_SKIRTS,
    BZ2_CAT_SKIS,
    BZ2_CAT_SKIS_SNOWBOARDS,
    BZ2_CAT_SKIS_SNOWBOARDS_OTHER,
    BZ2_CAT_SLEDGES_BOBSLEIGHS,
    BZ2_CAT_SLEEPING_BAGS,
    BZ2_CAT_SLEEPSUITS,
    BZ2_CAT_SLEEPWEAR,
    BZ2_CAT_SNORKELS_AND_FINS,
    BZ2_CAT_SNOWBOARD_FOOTWEAR,
    BZ2_CAT_SNOWBOARDS,
    BZ2_CAT_SNOWSUITS,
    BZ2_CAT_SOCKS_TIGHTS,
    BZ2_CAT_SPORT,
    BZ2_CAT_SPORT_BOTTLES,
    BZ2_CAT_SPORT_BOTTLES_BOTTLES,
    BZ2_CAT_SPORT_BOTTLES_OTHER,
    BZ2_CAT_SPORT_MASSAGE,
    BZ2_CAT_SPORT_OTHERS,
    BZ2_CAT_SPORT_OTHERS_ACCESSORIES,
    BZ2_CAT_SPORT_OTHERS_CLOTHING,
    BZ2_CAT_SPORT_OTHERS_FOOTWEAR,
    BZ2_CAT_SPORT_OTHERS_OTHER,
    BZ2_CAT_SPORT_VITAMINS,
    BZ2_CAT_SPORT_WATCHES,
    BZ2_CAT_SPORTS_BAGS,
    BZ2_CAT_STRENGHTENING_AIDS,
    BZ2_CAT_SWEATSHIRTS_JUMPERS_VESTS,
    BZ2_CAT_SWIMMING_CAPS,
    BZ2_CAT_SWIMMING_CIRCLES_SLEEVES,
    BZ2_CAT_SWIMMING_DIVING,
    BZ2_CAT_SWIMMING_GOGGLES,
    BZ2_CAT_SWIMMING_NEOPRENES,
    BZ2_CAT_SWIMMING_OTHER,
    BZ2_CAT_SWIMWEAR_DRESSING_GOWNS,
    BZ2_CAT_TENNIS_SQUASH,
    BZ2_CAT_TENNIS_SQUASH_BALLS,
    BZ2_CAT_TENNIS_SQUASH_CLOTHING,
    BZ2_CAT_TENNIS_SQUASH_FOOTWEAR,
    BZ2_CAT_TENNIS_SQUASH_OTHER,
    BZ2_CAT_TENNIS_SQUASH_RACKETS,
    BZ2_CAT_THERMOMETERS,
    BZ2_CAT_TICKETS_VOUCHERS,
    BZ2_CAT_TO_THE_GARAGE,
    BZ2_CAT_TOYS,
    BZ2_CAT_TRAMPOLINES,
    BZ2_CAT_TRAMPOLINES_SPARE_PARTS,
    BZ2_CAT_TRAMPOLINES_STAIRS,
    BZ2_CAT_TRAMPOLINES_TRAMPOLINES,
    BZ2_CAT_TRIKE,
    BZ2_CAT_TROLLEYS_TRAILERS,
    BZ2_CAT_TROUSERS_JEANS_JOGGERS,
    BZ2_CAT_TRUCKS,
    BZ2_CAT_TSHIRTS_TOPS,
    BZ2_CAT_TUTORING,
    BZ2_CAT_TWINS,
    BZ2_CAT_TWINS_GROUP,
    BZ2_CAT_TWINS_MISC,
    BZ2_CAT_UNDERWEAR,
    BZ2_CAT_VETERAN_VEHICLES,
    BZ2_CAT_VIDEO_AND_PHOTOGRAPHY,
    BZ2_CAT_WEIGHT_TRAINING,
    BZ2_CAT_WEIGHT_TRAINING_CLOTHING,
    BZ2_CAT_WEIGHT_TRAINING_OTHER,
    BZ2_CAT_WORK_ABROAD,
    BZ2_CAT_WORK_CLOTHES_FOOTWEAR,
};

export const SPECIAL_PRAM_MODELS = ['Doona'];
