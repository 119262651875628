import Page, { Props as PageProps } from 'mk2/components/Page';
import { TrackPageview } from 'mk2/components/TrackPageview';
import { UserAgentParsed } from 'mk2/helpers/detects';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { getLogger } from 'mk2/logger';
import PageDefinition from 'mk2/pages/Page';
import {
    getRequestBaseUrl,
    getRequestDeviceMobile,
    getRequestPermissions,
    getRequestState,
    getRequestUser,
    getRequestUserAgent,
    AppState,
} from 'mk2/reducers';
import { routes } from 'mk2/router';
import { UserEntity } from 'mk2/schemas';
import { HistoryLocationState } from 'mk2/services/browserHistory';
import { createRouterContextValue, RouterContext } from 'mk2/services/RouterContext';
import { Store } from 'mk2/store/configureStore';
import 'mk2/styles/globals.scss';
import * as PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { connect, shallowEqual, useDispatch, useSelector, useStore, MapStateToProps } from 'react-redux';
import { match, matchPath, withRouter, RouteComponentProps, StaticContext } from 'react-router';
import { bindActionCreators } from 'redux';

const logger = getLogger('Application');

export type AppName =
    | 'about'
    | 'adminclub'
    | 'bazaar'
    | 'bo'
    | 'blogs'
    | 'contests'
    | 'counsellings'
    | 'dash'
    | 'faq'
    | 'forum'
    | 'groups'
    | 'hearts'
    | 'home'
    | 'mail'
    | 'market'
    | 'plan'
    | 'settings'
    | 'strollers'
    | 'testings'
    | 'unknown'
    | 'users'
    | 'wall'
    | 'wiki';

interface BaseRoute<T = any> {
    app: AppName;
    exact?: boolean;
    strict?: boolean;
    path?: string;
}

export interface StaticRoute extends BaseRoute {
    page: PageDefinition<any, any, any>;
}

export interface DynamicRoute extends BaseRoute {
    page?: PageDefinition<any, any, any>;
    getPage: () => Promise<{ default: new () => PageDefinition<any, any, any> }>;
    modules: string[];
}

export type Route = StaticRoute | DynamicRoute;

interface OwnProps {
    store: Store<any>;
}

interface StateProps {
    baseUrl: string;
    isMobile: boolean;
    requestUser: UserEntity;
    requestUserPermissions: string[];
    userAgent: UserAgentParsed;
    hasIgnoredOldBrowserWarning: boolean;
}

interface DispatchProps {}

type RouteProps = RouteComponentProps<any, StaticContext, HistoryLocationState>;

type Props = OwnProps & StateProps & DispatchProps & RouteProps;

const dummyState = {};

/**
 * When route is changed - re-render with new connected component
 */
class Application extends React.Component<Props> {
    public static contextTypes = {
        loadable: PropTypes.shape({
            report: PropTypes.func.isRequired,
        }),
    };

    private currentRoute: Route;
    private currentProps: PageProps;

    private loading: boolean = false;

    public constructor(props: Props, context: any) {
        super(props, context);

        this.selectCurrentPage(props);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.loading) {
            return;
        }

        this.selectCurrentPage(nextProps);
    }

    public shouldComponentUpdate() {
        return !this.loading;
    }

    public render() {
        if (this.loading) {
            return null;
        }

        return (
            <RouterContext.Provider value={createRouterContextValue(routes, this.props.history)}>
                <TrackPageview app={this.currentProps.app} location={this.props.location}>
                    <ConnectedPage
                        {...this.currentProps}
                        mapStateToProps={this.currentRoute.page.mapStateToProps()}
                        mapDispatchToProps={this.currentRoute.page.mapDispatchToProps()}
                    />
                </TrackPageview>
            </RouterContext.Provider>
        );
    }

    private computeMatch({ location, path, strict, exact }) {
        const pathname = location.pathname;
        return path ? matchPath(pathname, { path, strict, exact }) : null;
    }

    private selectCurrentPage(props: Props) {
        const {
            baseUrl, isMobile, requestUser, requestUserPermissions, userAgent, hasIgnoredOldBrowserWarning,
            store, history, location, staticContext,
         } = props;

        let matchedRoute: Route;
        let routeMatch: match<any>;
        let current: Route;

        for (current of routes) {
            const { path, exact, strict } = current;
            routeMatch = this.computeMatch({ path, exact, strict, location });
            if (routeMatch) {
                matchedRoute = current;
                break;
            }
        }

        if (!matchedRoute) {
            if (current.path) { // Last route should be all catch without 'path'
                // Should not happen
                throw new Error('Not route matched - did you forget to add NotFoundPage as last?');
            }
            matchedRoute = current;
        }

        const resolved = () => {
            this.currentProps = {
                // Page.RouteProps (react-router > Route)
                match: routeMatch,
                location,
                history,
                staticContext,
                // Page.OwnProps
                app: matchedRoute.app,
                pageInstance: matchedRoute.page,
                baseUrl,
                isMobile,
                requestUser,
                requestUserPermissions,
                userAgent,
                hasIgnoredOldBrowserWarning,
                route: matchedRoute,
            };

            if (this.currentRoute !== matchedRoute) {
                this.currentRoute = matchedRoute;
                matchedRoute.page.registerInStore(store);

                const wasLoading = this.loading;
                this.loading = false;
                if (wasLoading) {
                    this.setState(dummyState);
                }
            }
        };

        if ('modules' in matchedRoute) {
            if (this.context.loadable && Array.isArray(matchedRoute.modules)) {
                matchedRoute.modules.forEach((moduleName) => {
                    this.context.loadable.report(moduleName);
                });
            }
        }

        if (!matchedRoute.page) {
            this.loading = true;

            (matchedRoute as DynamicRoute).getPage().then(
                ({ default: PageDef }) => {
                    matchedRoute.page = new PageDef();
                    resolved();
                },
                (err) => {
                    logger.error(err);
                },
            );
        } else {
            resolved();
        }
    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    return {
        baseUrl: getRequestBaseUrl(state),
        isMobile: getRequestDeviceMobile(state),
        requestUser: getRequestUser(state),
        requestUserPermissions: getRequestPermissions(state),
        userAgent: getRequestUserAgent(state),
        hasIgnoredOldBrowserWarning: getRequestState(state).hasIgnoredOldBrowserWarning,
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {};

const ApplicationWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Application));

const ApplicationWithStore: React.FunctionComponent<{}> = (props) => {
    // Convert type to our Store type
    const store: Store<any> = useStore() as any;
    return <ApplicationWithRouter store={store} {...props} />;
};

export default ApplicationWithStore;

type ApplicationPageProps<TStateProps = any, TDispatchProps = any> = PageProps & {
    mapStateToProps: MapStateToProps<TStateProps, PageProps, AppState>;
    mapDispatchToProps: MapDispatchToPropsObject<TDispatchProps>;
};

const ConnectedPage: React.FunctionComponent<ApplicationPageProps> = ({
    mapStateToProps: stateToProps,
    mapDispatchToProps: dispatchToProps,
    ...props
}) => {
    const mapStateToPropsWithOwnProps = useCallback((state) => stateToProps(state, props), [stateToProps, props]);
    const stateProps = useSelector(mapStateToPropsWithOwnProps, shallowEqual);

    const dispatch = useDispatch();
    const actions = useMemo(() => {
        return bindActionCreators(dispatchToProps, dispatch);
    }, [dispatch, dispatchToProps]);

    return <Page {...props} {...stateProps} {...actions} />;
};
