/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp17 } from './commonProp17';
import { commonProp16 } from './commonProp16';

export const multimedia_audio : {[key: string]: Array<[string, string]>} = {
    p_subject: [
        ["AUDIO_SONGS", "P\u00edsni\u010dky a ukol\u00e9bavky"],
        ["AUDIO_BOOKS", "Mluven\u00e9 slovo"],
        ["AUDIO_LANGUAGES", "V\u00fdu\u010dba jazykov"],
        ["AUDIO_OTHER", "Jin\u00fd"],
    ],
    p_language: commonProp17,
    p_data_carrier: [
        d("CD"),
        ["CASSETTE", "Kazeta"],
        ["VINYL", "LP deska"],
        ["OTHER_DATA_CARRIER", "Jin\u00fd"],
    ],
    p_for_whom: commonProp16,
};
