/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';

export const fancy_dress : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["FANCY_DRESS", "Kost\u00fdm"],
        ["HATS_HEADWEAR", "Klobouky, \u010delenky a pokr\u00fdvky hlavy"],
        ["WIGS", "Paruky a p\u0159\u00ed\u010desky"],
        ["MASKS", "\u0160krabo\u0161ky a masky na tv\u00e1\u0159"],
        ["FOOTWEAR", "Obuv"],
        ["FACIAL_HAIR", "Bradky a vousy"],
        ["GLASSES_JEWELLERY", "Br\u00fdle, bi\u017eut\u00e9rie"],
        ["BOA_HAND_FAN_WINGS", "Boa, v\u011bj\u00ed\u0159e, k\u0159\u00eddla"],
        ["WEAPONS_ARMOUR", "Me\u010de, pistole a zbran\u011b"],
        ["GLOVES_TIES_BRACES", "Rukavice, kravaty a k\u0161andy"],
        ["FANCY_DRESS_ACCESSORIES_OTHER", "Ostatn\u00ed dopl\u0148ky"],
    ],
    p_thema: [
        ["THEMA_ANIMALS", "Zv\u00ed\u0159\u00e1tka, hmyz"],
        ["THEMA_FLOWERS", "Kv\u011btinky a rostlinky"],
        ["THEMA_PRINCESS_FAIRY_BALLET", "Princezny, v\u00edly a tane\u010dnice"],
        ["THEMA_WITCH_WIZARD", "\u010carod\u011bjnice, \u010darod\u011bjn\u00edci, kouzeln\u00edci"],
        ["THEMA_HALLOWEEN_VAMPIRE_SCARY", "Halloween, up\u00ed\u0159i a stra\u0161idla"],
        ["THEMA_CLOWN", "\u0160a\u0161kov\u00e9 a klauni"],
        ["THEMA_OCCUPATION", "Profese, \u0159emesla"],
        ["THEMA_NINJA_WARRIOR", "Nind\u017eov\u00e9 a bojovn\u00edci"],
        ["THEMA_PIRATES_SAILORS", "Pir\u00e1ti, pir\u00e1tky a n\u00e1mo\u0159n\u00edci"],
        ["THEMA_INDIAN", "Indi\u00e1ni a indi\u00e1nky"],
        ["THEMA_COWBOYS", "Kovbojov\u00e9"],
        ["THEMA_PRINCE_KNIGHT", "Princov\u00e9 a ryt\u00ed\u0159i"],
        ["THEMA_FILM_CHARACTERS", "Filmov\u00ed hrdinov\u00e9"],
        ["THEMA_FOLK_DRESS", "Lidov\u00fd kroj"],
        ["THEMA_ANJEL_DEVIL_SANTA", "And\u011bl, \u010dert, Mikul\u00e1\u0161"],
        ["THEMA_20S_30S", "20. a 30. l\u00e9ta"],
        ["THEMA_60S_HIPPIES", "60. l\u00e9ta, hippies"],
        ["THEMA_HAWAIIAN_PARTY", "Havajsk\u00e1 p\u00e1rty"],
        ["THEMA_OTHER", "Jin\u00e1 t\u00e9ma"],
    ],
    p_sex: commonProp5,
    p_age_years: [
        ["0-1YEARS", "Miminko (0-1 rok)"],
        ["1-3YEARS", "Batole (1-3 roky)"],
        ["3-6YEARS", "\u0160kolka\u0159 (3-6 rok\u016f)"],
        ["6-10YEARS", "Mal\u00fd \u0161kol\u00e1k (6-10 rok\u016f)"],
        ["10-15YEARS", "Velk\u00fd \u0161kol\u00e1k (10-15 rok\u016f)"],
        ["15+YEARS", "BAZAAR_15+YEARS"],
        ["OTHER_AGE", "Neuveden\u00fd"],
    ],
};
