import { History, Location } from 'history';
import { getSessionStorage } from 'mk2/helpers/storages';
import { HistoryLocationState } from 'mk2/services/browserHistory';
import React from 'react';

interface OwnProps {
    location: Location<HistoryLocationState>;
    history: History;
}

type Props = OwnProps;

export class ScrollToTop extends React.Component<Props> {

    public static getScrollTop() {
        return 'scrollY' in window
            ? window.scrollY  // normal browsers
            : document.documentElement.scrollTop; // IE
    }

    private hasScrollRestoration: boolean;

    constructor(props: Props) {
        super(props);

        // some browsers (e.g. chrome) can now restore scroll position themself
        this.hasScrollRestoration = typeof window !== 'undefined' && 'scrollRestoration' in window.history;
        if (this.hasScrollRestoration) {
            // window.history.scrollRestoration = 'auto';

            // CeMi: Zatial pouzivame vsade "nas" scrollRestoration kod, ma lepsie UX.
            //
            // Chrome kod totiz niekedy na 'go forward' nastavi ako scrollTop namiesto 0
            // nejaky iny (necakany) offset
            window.history.scrollRestoration = 'manual';
            this.hasScrollRestoration = false;
        }
    }

    public componentDidMount() {
        if (this.hasScrollRestoration) {
            return;
        }

        const { location, history } = this.props;

        if (history && history.action === 'REPLACE') {
            // zachovaj scroll position, ked sa len vymenilo url
            // (chceme zobrazovat aktualne URL pre vyklikany filter)
            return;
        }

        // locationState might be null in old browsers (android 4.0)
        const locationState: HistoryLocationState = location.state;

        if (locationState && locationState.dontScrollOnPush && history.action === 'PUSH') {
            return;
        }

        const scroll = getSessionStorage().getItem(`ScrollToTop__scroll__${location.key}`);
        if (scroll) {
            window.scrollTo(0, 0);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.hasScrollRestoration) {
            return;
        }

        const { location, history } = this.props;

        // Store scroll position
        if (nextProps.location.key !== location.key) {
            getSessionStorage().setItem(`ScrollToTop__scroll__${location.key}`,
                                        `${ScrollToTop.getScrollTop()}`);
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.hasScrollRestoration) {
            return;
        }

        const { location, history } = this.props;

        if (history && history.action === 'REPLACE') {
            // zachovaj scroll position, ked sa len vymenilo url
            // (chceme zobrazovat aktualne URL pre vyklikany filter)
            return;
        }

        const locationState: HistoryLocationState = location.state;

        if (locationState && locationState.dontScrollOnPush && history.action === 'PUSH') {
            return;
        }

        if (location.key !== prevProps.location.key) {
            const scroll = getSessionStorage().getItem(`ScrollToTop__scroll__${location.key}`);
            // ak ideme na inu ako FormPageType.Main, chceme scroll position resetnut na 0
            const y = scroll ? parseFloat(scroll) : 0;

            // If location hash is defined - act as a browser anchor scrolling
            const hash = location.hash;

            if (y === 0 && (!hash || scroll)) {
                // requestIdleCallback sometimes takes too long what cause scroll flashing
                // For scroll to top on new page we don't have to wait for content to render
                window.scrollTo(0, y);
            } else {
                // ak su v strome dalsie redux-connected componenty, prekreslia sa az v nasledujucich
                // react render cykloch, ked im redux-subscription pushne spravu, ze sa zmenil store.
                // Musime dovtedy pockat s nastavenim scroll position, inak bude mat stranke chybajuci obsah
                // a tym padom nespravnu vysku
                const whenReactIdle = (window as any).requestIdleCallback || window.requestAnimationFrame;
                whenReactIdle(() => {
                    // Hash in the URL has changed - so scroll to the element if exists
                    if (hash && !scroll) {
                        const el = document.querySelector(hash);
                        if (el) {
                            el.scrollIntoView(true);
                            return;
                        }
                    }

                    window.scrollTo(0, y);
                });
            }
        }
    }

    public render() {
        const { children } = this.props;
        return children ? React.Children.only(children) : null;
    }

}
