/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const basketball_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("adidas"),
        d("Air Jordan"),
        d("arcore"),
        d("axis"),
        d("compressport"),
        d("craft"),
        d("etape"),
        d("fila"),
        d("finmark"),
        d("head"),
        d("K1X"),
        d("kipsta"),
        d("klimatex"),
        d("lewro"),
        d("lotto"),
        d("mizuno"),
        d("new era"),
        d("nike"),
        d("oneil"),
        d("R-Jet"),
        d("Reaper"),
        d("scott"),
        d("sensor"),
        d("swix"),
        d("tarmak"),
        d("ulvang"),
        d("under armour"),
        d("wilson"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
