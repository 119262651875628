import * as Sentry from '@sentry/minimal';
import { Severity } from '@sentry/types';

const loggers = {};

export class Logger {
    private id: string;

    constructor(id: string) {
        const cached = loggers[id];
        if (cached) {
            return cached;
        }

        loggers[id] = this;
        this.id = id;
    }

    /**
     * Logs a debug message.
     *
     * @param args The data to log.
     */
    public debug(...args: any[]): void { /* stub */ }

    /**
     * Logs info.
     *
     * @param args The data to log.
     */
    public info(...args: any[]): void { /* stub */ }

    /**
     * Logs a warning.
     *
     * @param args The data to log.
     */
    public warn(...args: any[]): void { /* stub */ }

    /**
     * Logs an error.
     *
     * @param args The data to log.
     */
    public error(...args: any[]): void { /* stub */ }
}

function connectConsoleToLogger() {
    const proto = Logger.prototype;
    // NodeJS has no console.debug
    proto.debug = (console.debug || console.log).bind(console); // tslint:disable-line
    proto.info = console.info.bind(console); // tslint:disable-line
    proto.warn = console.warn.bind(console); // tslint:disable-line
    proto.error = console.error.bind(console); // tslint:disable-line
}

export function connectSentryToLogger() {
    const proto = Logger.prototype;

    const setLoggerScope = (scope, loggerId: string, msgParams: any[]) => {
        scope.setTag('logger', loggerId.replace(/\//g, '__'));

        if (msgParams.length === 1 && typeof msgParams[0] === 'object') {
            // if only one object, expand all is keys/values
            Object.keys(msgParams[0]).forEach((key) => {
                scope.setExtra(key, msgParams[0][key]);
            });
        } else {
            msgParams.forEach((v, idx) => {
                scope.setExtra(`param${idx}`, v);
            });
        }
    };

    proto.debug = function(message, ...rest) { // tslint:disable-line
        Sentry.withScope((scope) => {
            setLoggerScope(scope, this.id, rest);
            Sentry.captureMessage(message, Severity.Debug);
        });

        (console.debug || console.log)(message, ...rest); // tslint:disable-line
    };
    proto.info = function(message, ...rest) { // tslint:disable-line
        Sentry.withScope((scope) => {
            setLoggerScope(scope, this.id, rest);
            Sentry.captureMessage(message, Severity.Info);
        });

        console.info(message); // tslint:disable-line
    };
    proto.warn = function(message, ...rest) { // tslint:disable-line
        Sentry.withScope((scope) => {
            setLoggerScope(scope, this.id, rest);
            Sentry.captureMessage(message, Severity.Warning);
        });

        console.warn(message, ...rest); // tslint:disable-line
    };
    proto.error = function(messageOrError, ...rest) { // tslint:disable-line
        Sentry.withScope((scope) => {
            setLoggerScope(scope, this.id, rest);
            Sentry.captureException(messageOrError);
        });

        console.error(messageOrError, ...rest); // tslint:disable-line
    };
}

if (process.env.NODE_ENV === 'test') {
    // Ignore all prints
} else {
    // by default log to console. When sentry is initialized,
    // it will register itself by calling connectSentryToLogger()
    connectConsoleToLogger();
}

export function getLogger(id: string): Logger {
    return loggers[id] || new Logger(id);
}
