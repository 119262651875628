import React from 'react';

interface OwnProps {
    className?: string;
    count: number;
}

/**
 * Cervene koliesko v strede s vycentrovanym bielym textom
 *
 * (pouzivame svg pretoze vie vycentrovat text presnejsie ako css-kova verzia)
 */
export class CountBadge extends React.Component<OwnProps> {

    public render() {
        const { className, count } = this.props;

        const badgeCase = count < 100 ? 2 : (count < 1000 ? 3 : 4);
        return (
            <svg className={className} viewBox={`0 0 ${26 + (badgeCase * 32) + 10} 100`}>
                <g id="UrTavla">
                    {(badgeCase === 2) ? (
                        <circle x="0" y="0" fill="#ee0728" stroke="white" strokeWidth="10" cx="50" cy="50" r="45" />
                    ) : (
                        <rect x="5" y="5" fill="#ee0728" stroke="white" strokeWidth="10" rx="45" ry="45" width={26 + (badgeCase * 32)} height="90" />
                    )}
                    <text
                      x={20 + (badgeCase * 15)}
                      y={50}
                      stroke="none"
                      fill="white"
                      textAnchor="middle"
                      textLength={badgeCase * 32}
                      fontSize={60}
                      fontWeight="bold"
                      dominantBaseline="central"
                      lengthAdjust="spacing"
                    >
                        {count < 1000 ? count : '+999'}
                    </text>
                </g>
            </svg>
        );
    }
}
