import {
    groupsCommentEditUrl,
    groupsEntityLikersUrl,
    groupsGroupBlockedMembersUrl,
    groupsGroupBlockMemberUrl,
    groupsGroupEditUrl,
    groupsGroupInfoUrl,
    groupsGroupInvitationUrl,
    groupsGroupInvitedMembersUrl,
    groupsGroupMembershipRequestsUrl,
    groupsGroupMembersUrl,
    groupsGroupPhotosUrl,
    groupsGroupReportedUrl,
    groupsGroupUrl,
    groupsListFromCategoryUrl,
    groupsListUrl,
    groupsMyActivityFeedUrl,
    groupsNewGroupFormUrl,
    groupsNewGroupSelectUrl,
    groupsNewGroupUrl,
    groupsPhotoDetailUrl,
    groupsPostDetailUrl,
    groupsPostEditUrl,
    groupsSubscribedFeedUrl,
} from 'mk2/apps/groups/urls';
import { Route } from 'mk2/containers/Application/Application';

export const routes: Route[] = [{
    app: 'forum',
    exact: true,
    path: groupsMyActivityFeedUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupMyActivityFeed/GroupMyActivityFeedPage' /* webpackChunkName: "groups.GroupMyActivityFeed" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupMyActivityFeed/GroupMyActivityFeedPage' ],
}, {
    app: 'forum',
    exact: true,
    path: groupsSubscribedFeedUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupSubscribedFeed/GroupSubscribedFeedPage' /* webpackChunkName: "groups.GroupSubscribedFeed" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupSubscribedFeed/GroupSubscribedFeedPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupFeed/GroupFeedPage' /* webpackChunkName: "groups.GroupFeed" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupFeed/GroupFeedPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupReportedUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupReportedFeed/GroupReportedFeedPage' /* webpackChunkName: "groups.GroupReportedFeed" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupReportedFeed/GroupReportedFeedPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsNewGroupUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupCreateExplanation/GroupCreateExplanationPage' /* webpackChunkName: "groups.GroupCreateExplanation" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupCreateExplanation/GroupCreateExplanationPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsNewGroupSelectUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupCreateSelect/GroupCreateSelectPage' /* webpackChunkName: "groups.GroupCreateSelect" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupCreateSelect/GroupCreateSelectPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsNewGroupFormUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupCreate/GroupCreatePage' /* webpackChunkName: "groups.GroupCreate" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupCreate/GroupCreatePage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsEntityLikersUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupEntityLikers/GroupEntityLikersPage' /* webpackChunkName: "groups.GroupEntityLikers" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupEntityLikers/GroupEntityLikersPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsPostEditUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupPostEdit/GroupPostEditPage' /* webpackChunkName: "groups.GroupPostEdit" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupPostEdit/GroupPostEditPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsCommentEditUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupCommentEdit/GroupCommentEditPage' /* webpackChunkName: "groups.GroupCommentEdit" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupCommentEdit/GroupCommentEditPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupInfoUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupInfo/GroupInfoPage' /* webpackChunkName: "groups.GroupInfo" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupInfo/GroupInfoPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupPhotosUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupPhotos/GroupPhotosPage' /* webpackChunkName: "groups.GroupPhotos" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupPhotos/GroupPhotosPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupEditUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupEdit/GroupEditPage' /* webpackChunkName: "groups.GroupEdit" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupEdit/GroupEditPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupBlockedMembersUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupBlockedMembers/GroupBlockedMembersPage' /* webpackChunkName: "groups.GroupBlockedMembers" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupBlockedMembers/GroupBlockedMembersPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupInvitedMembersUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupInvitedMembers/GroupInvitedMembersPage' /* webpackChunkName: "groups.GroupInvitedMembers" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupInvitedMembers/GroupInvitedMembersPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupMembershipRequestsUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupMembershipRequests/GroupMembershipRequestsPage' /* webpackChunkName: "groups.GroupMembershipRequests" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupMembershipRequests/GroupMembershipRequestsPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupInvitationUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupInvitation/GroupInvitationPage' /* webpackChunkName: "groups.GroupInvitation" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupInvitation/GroupInvitationPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupBlockMemberUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupBlockMember/GroupBlockMemberPage' /* webpackChunkName: "groups.GroupBlockMember" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupBlockMember/GroupBlockMemberPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsGroupMembersUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupMembers/GroupMembersPage' /* webpackChunkName: "groups.GroupMembers" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupMembers/GroupMembersPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsListUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/ListOfGroups/ListOfGroupsPage' /* webpackChunkName: "groups.ListOfGroups" */);
    },
    modules: [ 'mk2/apps/groups/containers/ListOfGroups/ListOfGroupsPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsListFromCategoryUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/ListOfGroups/ListOfGroupsPage' /* webpackChunkName: "groups.ListOfGroups" */);
    },
    modules: [ 'mk2/apps/groups/containers/ListOfGroups/ListOfGroupsPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsPhotoDetailUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupPostPhoto/GroupPostPhotoPage' /* webpackChunkName: "groups.GroupPostPhoto" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupPostPhoto/GroupPostPhotoPage' ],
}, {
    app: 'groups',
    exact: true,
    path: groupsPostDetailUrl,
    getPage: () => {
        return import('mk2/apps/groups/containers/GroupPostDetail/GroupPostDetailPage' /* webpackChunkName: "groups.GroupPostDetail" */);
    },
    modules: [ 'mk2/apps/groups/containers/GroupPostDetail/GroupPostDetailPage' ],
}];
