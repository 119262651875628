/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const snowsuits : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SNOWSUIT", "Zimn\u00ed kombin\u00e9za"],
        ["SNOW_SET", "Zimn\u00ed komplet (bunda + oteplova\u010dky)"],
        ["SNOW_PANT", "Oteplova\u010dky"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
