import request from 'superagent';

function _applyApiAuth(req, apiAuth) {
    apiAuth.verifier(); // throws if problem

    apiAuth.headers.forEach(function([name, value]) {
        req.set(name, value);
    });

    if (apiAuth.cookies.length > 0) {
        let cookieHeaderValue = apiAuth.cookies.map(function([name, value]) { return name + '=' + encodeURIComponent(value); }).join(';');
        req.set('Cookie', cookieHeaderValue);
    }
}

function makeRequest(apiAuth, method, url, data, urlEncoded) {
    let fullUrl = apiAuth.baseUrl + url;
    let req = request(method, fullUrl);
    req.withCredentials();
    req.set('X-Requested-With', 'XMLHttpRequest');

    try {
        _applyApiAuth(req, apiAuth);
    } catch (e) {
        return Promise.reject(e);
    }

    if (urlEncoded) {
        req.type('urlencoded');
    }
    if (data) {
        if (method === 'GET') {
            req.query(data);
        } else {
            req.send(data);
        }
    }

    return req.catch((err) => {
        // fluxible-router is checking "statusCode" instead of "status"
        err.statusCode = err.status;
        throw err;
    });
}

const DEFAULT_NUMBER_OF_RETRIES = 1;

/**
 * Try to make request - if it fails with crossDomain error, retry it
 *
 * @param apiAuth
 * @param method {string}
 * @param url {string}
 * @param data {object}
 * @param urlEncode {string}
 * @param repeatable {int|bool|undefined} Can we retry request?
 *      if undefined - DEFAULT_NUMBER_OF_RETRIES will be used when method === 'GET'
 *      if true - DEFAULT_NUMBER_OF_RETRIES will be used
 *      if int - number of retries
 */
function makeRequestWithRetries(apiAuth, method, url, data, urlEncode, repeatable) {
    let numOfRetries = 0;

    if ((repeatable === undefined && method === 'GET') // auto-detect
        || repeatable === true) { // use default
        numOfRetries = DEFAULT_NUMBER_OF_RETRIES;
    }

    let promise = makeRequest(apiAuth, method, url, data, urlEncode);
    for (let i = 0; i < numOfRetries; i++) {
        promise = promise.catch(err => {
            if (err.crossDomain) {
                // Retry request
                return makeRequest(apiAuth, method, url, data, urlEncode);
            }

            throw err; // Re-throw
        });
    }
    return promise;
}

/**
 * Wait for device to become onLine
 *
 * TODO: Try to rewrite using "online" event and single timeout
 *
 * @param waitTime How long we should wait - defaults to: 500ms
 * @param retries How many times we should try to wait - defaults to: 20
 * @returns Promise
 */
function waitForOnLine(waitTime = 500, retries = 20) {
    if (typeof navigator === 'undefined' // NodeJS/browser without navigator? - no wait
        || navigator.onLine !== false) { // User is online or navigator.onLine is no available - so continue as normal
        return Promise.resolve();
    }

    // Reject after waitTime
    let promise = new Promise((resolve, reject) => {
        setTimeout(reject, waitTime);
    });

    if (retries) {
        promise = promise.catch(() => waitForOnLine(waitTime, retries - 1));
    } else {
        promise = promise.catch(() => {
            // Check onLine again
            // return navigator.onLine ? Promise.resolve() : Promise.reject(new Error('User is offline!'));
            // Continue as normal - try to make a request no matter of navigator.onLine value
            return Promise.resolve();
        });
    }

    return promise;
}

export function get(apiAuth, url, data, repeatable) {
    return waitForOnLine().then(() => makeRequestWithRetries(apiAuth, 'GET', url, data, undefined, repeatable));
}

export function post(apiAuth, url, data, repeatable) {
    return waitForOnLine().then(() => makeRequestWithRetries(apiAuth, 'POST', url, data, undefined, repeatable));
}

export function postUrlEncoded(apiAuth, url, data, repeatable) {
    return waitForOnLine().then(() => makeRequestWithRetries(apiAuth, 'POST', url, data, true, repeatable));
}

export function put(apiAuth, url, data, repeatable) {
    return waitForOnLine().then(() => makeRequestWithRetries(apiAuth, 'PUT', url, data, undefined, repeatable));
}

export function del(apiAuth, url, data, repeatable) {
    return waitForOnLine().then(() => makeRequestWithRetries(apiAuth, 'DELETE', url, data, undefined, repeatable));
}
