import cx from 'classnames';
import { photoUrl } from 'mk/photo/photoUrl';
import { Img } from 'mk2/components/Img';
import { PhotoEntity } from 'mk2/schemas';
import React from 'react';
import styles from './ImgResponsive.mscss';

interface OwnPropsShared {
    className?: string;

    // if set, image width = parent width and image height <= maxHeight
    // what overflows is trimmed
    maxHeight?: number;
    alt?: string;
    lazy?: boolean;
}

type OwnPropsWithPhoto = OwnPropsShared & {
    photo: PhotoEntity;
    photoVersion: string;

    imgUrl?: never;
    width?: never;
    height?: never;
};

type OwnPropsWithSrc = OwnPropsShared & {
    imgUrl: string;
    width: number;
    height: number;

    photo?: never;
    photoVersion?: never;
};

/**
 * Scales image to width of parent container (div). Keeping aspect ratio of the input image,
 * image height is computed automatically.
 *
 * Avoids page reflows while image is being loaded, it allocates the needed space in advance, before the image
 * is loaded.
 */
export class ImgResponsive extends React.Component<OwnPropsWithPhoto | OwnPropsWithSrc> {

    public render() {
        const { className, maxHeight, alt, lazy } = this.props;

        let height: number;
        let width: number;
        let imgUrl: string;
        if (this.props.photo) {
            height = this.props.photo.height;
            width = this.props.photo.width;
            imgUrl = photoUrl(this.props.photo, this.props.photoVersion);
        } else {
            height = this.props.height;
            width = this.props.width;
            imgUrl = this.props.imgUrl;
        }

        return (
            <span
                className={cx(styles.ImgResponsive, className)}
                style={maxHeight && {maxHeight}}
            >
                <i style={{display: 'block', paddingTop: (height / width * 100) + '%'}} />
                <Img
                    className={styles.ImgResponsive__img}
                    src={imgUrl}
                    alt={alt}
                    width={undefined/* We need to set width to undefined for responsive image to work */}
                    height={undefined/* We need to set height to undefined for responsive image to work */}
                    lazy={lazy}
                />
            </span>
        );
    }
}
