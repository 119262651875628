// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const NO = "Ne";
export const STROLLER_BACK_SUPPORT_POSITIONING_BUCKLE = "p\u0159ezkou";
export const STROLLER_BACK_SUPPORT_POSITIONING_BUTTON = "tla\u010d\u00edtkem";
export const STROLLER_BACK_SUPPORT_POSITIONING_FRONT_RECLINE = "mechanismem nakl\u00e1n\u011bn\u00ed pod sedadlem";
export const STROLLER_BACK_SUPPORT_POSITIONING_REAR_RECLINE = "mechanismem nakl\u00e1n\u011bn\u00ed vzadu";
export const STROLLER_BACK_SUPPORT_POSITIONING_SIDE_HANDLES = "p\u00e1\u010dkami po stran\u00e1ch";
export const STROLLER_BACK_SUPPORT_POSITIONING_SIDE_RECLINE = "mechanismem nakl\u00e1n\u011bn\u00ed po stran\u00e1ch sedadla";
export const STROLLER_BACK_SUPPORT_POSITIONING_STRAP = "p\u00e1sem";
export const STROLLER_BACK_SUPPORT_POSITIONING_ZIP = "zipem";
export const STROLLER_BUMPER_BAR_TYPE_BAR = "ty\u010dov\u00e9";
export const STROLLER_BUMPER_BAR_TYPE_TRAY = "pult\u00edkov\u00e9";
export const STROLLER_BUMPER_BAR_TYPE_T_SHAPED = "ve tvaru T";
export const STROLLER_CARD_RECOMMENDED_CHOICE_HAPPY_MEDIUM = "Zlat\u00fd st\u0159ed";
export const STROLLER_CARD_RECOMMENDED_CHOICE_POPULAR = "Za babku";
export const STROLLER_CARD_RECOMMENDED_CHOICE_TOP = "TOP";
export const STROLLER_CENTRAL_BRAKE_SYSTEM_HAND_OPERATED = "mechanismus na rukojeti";
export const STROLLER_CENTRAL_BRAKE_SYSTEM_ONE_STEP_CRADLE_BRAKE = "n\u00e1\u0161lapn\u00e1 kol\u00e9bkov\u00e1";
export const STROLLER_CENTRAL_BRAKE_SYSTEM_PUSH_LIFT = "n\u00e1\u0161lapn\u00e1 stiskni/zvedni";
export const STROLLER_CENTRAL_BRAKE_SYSTEM_PUSH_PUSH = "n\u00e1\u0161lapn\u00e1 stiskni/stiskni";
export const STROLLER_CLEANING_THE_STROLLER_FABRICS_MANUALLY = "ru\u010dn\u011b";
export const STROLLER_CLEANING_THE_STROLLER_FABRICS_WACHINE_MACHINE = "pran\u00ed";
export const STROLLER_FEATURE_BACK_SUPPORT_ALMOST_FLAT = "t\u00e9m\u011b\u0159 \u00fapln\u00fd rovn\u00fd leh (165\u00b0)";
export const STROLLER_FEATURE_BACK_SUPPORT_ALMOST_TROUGH = "t\u00e9m\u011b\u0159 \u00fapln\u00fd kor\u00fdtkov\u00fd leh";
export const STROLLER_FEATURE_BACK_SUPPORT_FULL_FLAT = "\u00fapln\u00fd rovn\u00fd leh (180\u00b0)";
export const STROLLER_FEATURE_BACK_SUPPORT_FULL_TROUGH = "\u00fapln\u00fd kor\u00fdtkov\u00fd leh";
export const STROLLER_FEATURE_BACK_SUPPORT_HALF_FLAT = "pololeh (150\u00b0)";
export const STROLLER_FEATURE_BUCKET_SEAT_ADJUSTABLE_RECLINE = "Nastaviteln\u00fd leh";
export const STROLLER_FEATURE_BUCKET_SEAT_BUCKET = "Kor\u00fdtko";
export const STROLLER_FEATURE_BUCKET_SEAT_HYBRID = "Hybrid";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_AVERAGE = "vhodn\u00fd pro pr\u016fm\u011brn\u011b vysok\u00e9 rodi\u010de";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_SHORT = "vhodn\u00fd pro n\u00edzk\u00e9 rodi\u010de";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_TALL = "vhodn\u00fd pro vysok\u00e9 rodi\u010de";
export const STROLLER_FOLDING_SYSTEM_3D = "3D";
export const STROLLER_FOLDING_SYSTEM_FLAT = "na plocho";
export const STROLLER_FOLDING_SYSTEM_TELESCOPIC = "teleskopick\u00fd";
export const STROLLER_FOLDING_SYSTEM_UMBRELLA = "na de\u0161tn\u00edk";
export const STROLLER_HANDLE_ADJUSTMENT_FIXED = "pevn\u00e1 (fixn\u00ed)";
export const STROLLER_HANDLE_ADJUSTMENT_PIVOTING = "oto\u010dn\u00e1";
export const STROLLER_HANDLE_ADJUSTMENT_TELESCOPIC = "teleskopick\u00e1";
export const STROLLER_HARNESS_TYPE_2B = "2 bodovy";
export const STROLLER_HARNESS_TYPE_3B = "3 bodovy";
export const STROLLER_HARNESS_TYPE_5B = "5 bodovy";
export const STROLLER_HARNESS_TYPE_6B = "6 bodovy";
export const STROLLER_MATERIAL_ALUMINIUM = "hlin\u00edk";
export const STROLLER_MATERIAL_CARBON = "karbon";
export const STROLLER_MATERIAL_CORK = "korek";
export const STROLLER_MATERIAL_DURAL = "dural";
export const STROLLER_MATERIAL_FABRIC = "l\u00e1tka";
export const STROLLER_MATERIAL_FOAM = "p\u011bna";
export const STROLLER_MATERIAL_HARD_RUBBER = "tvrzen\u00e1 guma";
export const STROLLER_MATERIAL_IRON = "\u017eelezo";
export const STROLLER_MATERIAL_LEATHER = "k\u016f\u017ee";
export const STROLLER_MATERIAL_LEATHERETTE = "ko\u017eenka";
export const STROLLER_MATERIAL_MAGNESIUM = "ho\u0159\u010d\u00edk";
export const STROLLER_MATERIAL_METAL = "kov";
export const STROLLER_MATERIAL_NYLON = "nylon";
export const STROLLER_MATERIAL_OTHER = "ostatn\u00ed";
export const STROLLER_MATERIAL_PLASTIC = "plast";
export const STROLLER_MATERIAL_RECYCLED_PLASTIC = "recyklovan\u00fd plast";
export const STROLLER_MATERIAL_RUBBER = "guma";
export const STROLLER_MATERIAL_STEEL = "ocel";
export const STROLLER_MATERIAL_WOOD = "d\u0159evo";
export const STROLLER_NUMBER_OF_KIDS_MORE = "pro dvoj\u010data/sourozence";
export const STROLLER_NUMBER_OF_KIDS_ONE = "pro jedno d\u00edt\u011b";
export const STROLLER_OR_CONJUNCTION = " nebo ";
export const STROLLER_ROTATING_FIXATION_WHEELS_FIXED = "fixn\u00ed kole\u010dka";
export const STROLLER_ROTATING_FIXATION_WHEELS_ROTATING = "oto\u010dn\u00e1 kole\u010dka";
export const STROLLER_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE = "oto\u010dn\u00e1 s mo\u017enost\u00ed fixace";
export const STROLLER_SUSPENSION_ELASTOMERS = "elastomery";
export const STROLLER_SUSPENSION_FRAME = "konstrukc\u00ed";
export const STROLLER_SUSPENSION_MECHANICAL = "mechanick\u00e9";
export const STROLLER_SUSPENSION_MECHANICAL_ELASTOMERS = "mechanick\u00e9 a elastomery";
export const STROLLER_SUSPENSION_MECHANICAL_FRAME = "mechanick\u00e9 a konstrukc\u00ed";
export const STROLLER_SUSPENSION_MECHANICAL_ROCKER = "mechanick\u00e9 a v\u00e1hadla";
export const STROLLER_SUSPENSION_NONE = "bez odpru\u017een\u00ed";
export const STROLLER_SUSPENSION_PNEUMATIC = "pneumatick\u00e9";
export const STROLLER_SUSPENSION_PNEUMATIC_FRAME = "pneumatick\u00e9 a konstrukc\u00ed";
export const STROLLER_SUSPENSION_PNEUMATIC_MECHANICAL = "pneumatick\u00e9 a mechanick\u00e9";
export const STROLLER_SUSPENSION_PNEUMATIC_SPRING = "pru\u017einov\u00e9 a pneumatick\u00e9";
export const STROLLER_SUSPENSION_ROCKER = "vahadl\u00e1";
export const STROLLER_SUSPENSION_SPRING = "pru\u017einov\u00e9";
export const STROLLER_SUSPENSION_SPRING_ELASTOMERS = "pru\u017einov\u00e9 a elastomery";
export const STROLLER_SUSPENSION_SPRING_MECHANICAL = "pru\u017einov\u00e9 a mechanick\u00e9";
export const STROLLER_SUSPENSION_SPRING_MECHANICAL_FRAME = "pru\u017einov\u00e9 a mechanick\u00e9 a konstrukc\u00ed";
export const STROLLER_SUSPENSION_SPRING_MECHANICAL_PNEUMATIC = "pru\u017einov\u00e9 a mechanick\u00e9 a pneumatick\u00e9";
export const STROLLER_SUSPENSION_STRAPS = "\u0159em\u00ednky";
export const STROLLER_TYPE_2IN1 = "2v1";
export const STROLLER_TYPE_2KOMBI = "dvojkombinace";
export const STROLLER_TYPE_2KOMBI_DEEP_SPORTY = "hlbok\u00fd+\u0161portov\u00fd";
export const STROLLER_TYPE_2KOMBI_DEEP_TRAVEL_SYSTEM = "hlbok\u00fd+vaj\u00ed\u010dko";
export const STROLLER_TYPE_2KOMBI_SPORTY_TRAVEL_SYSTEM = "\u0161portov\u00fd+vaj\u00ed\u010dko";
export const STROLLER_TYPE_3KOMBI = "trojkombinace";
export const STROLLER_TYPE_CHECKED_BAGGAGE = "Cestovn\u00ed";
export const STROLLER_TYPE_CITY_TERRAIN = "M\u011bstsk\u00fd";
export const STROLLER_TYPE_COUNTRYSIDE_TERRAIN = "Vesnice";
export const STROLLER_TYPE_DEEP = "hlubok\u00fd";
export const STROLLER_TYPE_GOLF = "golfov\u00fd";
export const STROLLER_TYPE_HARD_TERRAIN = "Ter\u00e9nn\u00ed";
export const STROLLER_TYPE_LONG_NAME_2KOMBI = "Ko\u010d\u00e1rky dvojkombinace";
export const STROLLER_TYPE_LONG_NAME_3KOMBI = "Ko\u010d\u00e1rky trojkombinace";
export const STROLLER_TYPE_LONG_NAME_CHECKED_BAGGAGE = "Cestovn\u00ed ko\u010d\u00e1rky";
export const STROLLER_TYPE_LONG_NAME_CITY_TERRAIN = "Ko\u010d\u00e1rky vhodn\u00e9 do m\u011bsta";
export const STROLLER_TYPE_LONG_NAME_COUNTRYSIDE_TERRAIN = "Ko\u010d\u00e1rky vhodn\u00e9 do vesnice";
export const STROLLER_TYPE_LONG_NAME_DEEP = "Hlubok\u00e9 ko\u010d\u00e1rky";
export const STROLLER_TYPE_LONG_NAME_GOLF = "Golfov\u00e9 ko\u010d\u00e1rky";
export const STROLLER_TYPE_LONG_NAME_HARD_TERRAIN = "Ter\u00e9nn\u00ed ko\u010d\u00e1rky";
export const STROLLER_TYPE_LONG_NAME_RETRO = "Retro ko\u010d\u00e1rky";
export const STROLLER_TYPE_LONG_NAME_SIBLINGS = "Sourozeneck\u00e9 ko\u010d\u00e1rky";
export const STROLLER_TYPE_LONG_NAME_SPORT_RUNNING = "Ko\u010d\u00e1rek na b\u011bh a sport";
export const STROLLER_TYPE_LONG_NAME_SPORTY = "Sportovn\u00ed ko\u010d\u00e1rky";
export const STROLLER_TYPE_RETRO = "Retro";
export const STROLLER_TYPE_SIBLINGS = "sourozeneck\u00fd";
export const STROLLER_TYPE_SIBLINGS_DOUBLE = "double (2 d\u011bti vedle sebe)";
export const STROLLER_TYPE_SIBLINGS_TANDEM = "tandem (d\u011bti um\u00edst\u011bn\u00e9 za sebou/nad sebou)";
export const STROLLER_TYPE_SIBLINGS_TRIPLE = "tripple (3 d\u011bti vedle sebe)";
export const STROLLER_TYPE_SPORT_RUNNING = "B\u011bh a sport";
export const STROLLER_TYPE_SPORTY = "sportovn\u00ed";
export const STROLLER_TYPE_TRAVEL_SYSTEM = "cestovn\u00ed syst\u00e9m";
export const STROLLER_WHEELS_ALL = "v\u0161echny";
export const STROLLER_WHEELS_FRONT = "p\u0159edn\u00ed";
export const STROLLER_WHEELS_REAR = "zadn\u00ed";
export const STROLLER_WHEEL_TYPE_WEB_AIRLESS_WHEELS = "nenafukovac\u00ed (s v\u00fdpln\u00ed)";
export const STROLLER_WHEEL_TYPE_WEB_AIR_WHEELS = "nafukovac\u00ed";
export const STROLLER_WHEEL_TYPE_WEB_EBONITE_WHEELS = "tvrzen\u00e1 guma";
export const STROLLER_WHEEL_TYPE_WEB_FOAM_WHEELS = "p\u011bnov\u00e1";
export const STROLLER_WHEEL_TYPE_WEB_PLASTIC_WHEELS = "plastov\u00e9";
export const YES = "Ano";
