import { ACTION_TO_ADD_REPLY } from 'mk/autogenerated/translations/Like.sagas.38e23e3640f1a4498304141eda0a60d9'
import { likeSave, LikeToggleAction, LIKE_TOGGLE } from 'mk2/containers/Like/Like.actions';
import { saveLike as apiSaveLike } from 'mk2/containers/Like/Like.api';
import { likeKey, LikeableEntity } from 'mk2/containers/Like/Like.schemas';
import { handleXHRPostErrorSaga, XHRAction, XHRPostError } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { getEntitiesEntity } from 'mk2/reducers';
import { checkIfUserIsLoggedIn } from 'mk2/sagas/auth';
import React from 'react';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

const logger = getLogger('Like.sagas');

export function* saveLike({ entity, id, xhr }: LikeToggleAction & XHRAction) {

    if (!(yield call(checkIfUserIsLoggedIn, ACTION_TO_ADD_REPLY))) {
        return;
    }

    const key = likeKey(entity, id);

    // Get current state
    const current: LikeableEntity = yield select(getEntitiesEntity, 'likeable', key);

    // Sent fake response in the request action, so that entities reducer updates data
    const newState = {
        ...current,
        isLiked: !current.isLiked,
        likesCount: Math.max(0, current.likesCount + (current.isLiked ? -1 : 1)),
    };

    const fakeResponse = {
        entities: {
            likeable: {
                [key]: newState,
            },
        },
    };

    yield put(likeSave.request(entity, id, fakeResponse));

    try {
        yield call(apiSaveLike, xhr, entity, id, newState.isLiked);
        yield put(likeSave.success(entity, id, {})); // We already updated state in likeSave.request
    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(likeSave.failure(entity, id, error));
    }
}

export default function* root() {
    yield all([
        takeEvery(LIKE_TOGGLE, saveLike),
    ]);
}
