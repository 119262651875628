import adSlot from 'mk2/containers/AdSlot/AdSlot.sagas';
import adSlotBranding from 'mk2/containers/AdSlotBranding/AdSlotBranding.sagas';
import adSlotMail from 'mk2/containers/AdSlotMail/AdSlotMail.sagas';
import ab from 'mk2/containers/AB/AB.sagas';
import deletion from 'mk2/containers/Deletion/Deletion.sagas';
import installPrompt from 'mk2/containers/InstallPrompt/InstallPrompt.sagas';
import like from 'mk2/containers/Like/Like.sagas';
import pageHeader from 'mk2/containers/PageHeader/PageHeader.sagas';
import photosUpload from 'mk2/containers/PhotosUpload/PhotosUpload.sagas';
import star from 'mk2/containers/Star/Star.sagas';
import toolbar from 'mk2/containers/Toolbar/Toolbar.sagas';
import webpushNotifications from 'mk2/containers/WebpushNotifications/WebpushNotifications.sagas';
import analytics from 'mk2/sagas/analytics';
import form from 'mk2/sagas/form';
import request from 'mk2/sagas/request';
import { all, fork } from 'redux-saga/effects';

export default function* root() {
    yield all([
        // Global sagas
        fork(request),
        fork(analytics),
        fork(form),

        // Global containers
        fork(adSlot),
        fork(ab),
        fork(adSlotBranding),
        fork(adSlotMail),
        fork(deletion),
        fork(star),
        fork(toolbar),
        fork(like),
        fork(pageHeader),
        fork(photosUpload),
        fork(webpushNotifications),
        fork(installPrompt),

        // fork(newsletterModal),
    ]);
}
