/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp16 } from './commonProp16';

export const safety_gloves : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["DISPOSABLE", "Jednor\u00e1zov\u00e9"],
        ["WORK_GLOVES", "Pracovn\u00ed"],
    ],
    p_for_whom: commonProp16,
    p_size: [
        d("S"),
        d("M"),
        d("L"),
        d("XL"),
    ],
};
