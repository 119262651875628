import { SLUG_LEGACY_RE, SLUG_RE } from 'mk2/helpers/urls';

const TOPIC_SLUG = `:topicSlug(${SLUG_RE})`;
const POST_SLUG = `:postSlug(${SLUG_LEGACY_RE})`;

export const counsellingsHomeUrl = `/ask-an-expert/`;
export const counsellingsNewUrl = `/ask-an-expert/new/${TOPIC_SLUG}/`;
export const counsellingsReplyUrl = `/ask-an-expert/reply/:questions(new|answered)/${TOPIC_SLUG}/`;
export const counsellingsTopicUrl = `/ask-an-expert/${TOPIC_SLUG}/`;
export const counsellingsPostDetailUrl = `/ask-an-expert/${TOPIC_SLUG}/${POST_SLUG}/`;
