import union from 'lodash-es/union';
import * as rawCategories from 'mk/autogenerated/bzCategories';
import * as allProps from 'mk/autogenerated/bzProps';
import { CATEGORY_NAMES, CATEGORY_SEO_NAMES } from 'mk/bazaar/common/enums';

export interface LeafCategory {
    slug: string;
    name: string;
    parent: string;
    parentCategory?: Category;
    props: string[];
    pathnameFilters?: string[];
    children?: Category[];
}

export interface RootCategory {
    name: string;
    slug: string;
    parent: string;
    children: Category[];
}

export type Category = LeafCategory | RootCategory;

const categoriesDict = {};
const categoriesDictBySlug: { [slug: string]: LeafCategory } = {};

const servicesCategoryName = 'services';
const jobsCategoryName = 'jobs';

// Guard for LeafCategory
export function isLeafCategory(category: any): category is LeafCategory {
    return (category.children?.length ?? 0) === 0;
}

Object.keys(rawCategories).forEach((key) => {
    const groupCategories: Category[] = rawCategories[key];
    groupCategories.forEach((category: Category) => {
        categoriesDict[category.name] = category;
        // categories that contain other categories have no slug
        if (isLeafCategory(category)) {
            categoriesDictBySlug[category.slug] = category;
        }
    });
});

// Second pass to create children tree and map parent
Object.keys(rawCategories).forEach((key) => {
    const groupCategories: Category[] = rawCategories[key];
    groupCategories.forEach((cat) => {
        if (cat.parent) {
            const parentCategory = categoriesDict[cat.parent];
            (cat as LeafCategory).parentCategory = parentCategory;
            if (parentCategory) {
                parentCategory.children = parentCategory.children ?? [];
                parentCategory.children.push(cat);
            }
        }
    });
});

const rootCategories = rawCategories.index as RootCategory[];

export function dict() {
    return categoriesDict;
}

export function dictBySlug() {
    return categoriesDictBySlug;
}

export function list(): RootCategory[] {
    return rootCategories;
}

export function getPropertiesForCategories(names: string[]): string[] {
    let props = [];
    if (!(names && names.length)) {
        return ['p_size', 'p_sex', 'p_color'];
    }
    names.forEach((name) => {
        if (name in categoriesDict) {
            const cat = categoriesDict[name];
            if (isLeafCategory(cat)) {
                props = union(props, cat.props);
            }
        }
    });
    return props;
}

export function getFullLabel(catName: string): string {
    const msgId = `${catName.toUpperCase()}`;
    return CATEGORY_SEO_NAMES[`BZ2_CAT_SEO_${msgId}`];
}

export function getLabel(catName: string): string {
    const msgId = `${catName.toUpperCase()}`;
    return CATEGORY_NAMES[`BZ2_CAT_${msgId}`] || msgId;
}

export function isServicesCategory(name: string): boolean {
    return (
        name === servicesCategoryName || (categoriesDict[name] && categoriesDict[name].parent === servicesCategoryName)
    );
}

export function isJobsCategory(name: string): boolean {
    return name === jobsCategoryName || (categoriesDict[name] && categoriesDict[name].parent === jobsCategoryName);
}

export function getCategory(name: string): Category {
    return categoriesDict[name] ?? null;
}

export function getParentCategory(catName): Category {
    return categoriesDict[catName]?.parentCategory ?? null;
}

export function getTitleForCategoriesFilter(filter) {
    const categoryName = filter.cats && filter.cats[0] ? filter.cats[0] : null;
    const cat = categoriesDict[categoryName];
    const importantProps =
        categoryName && cat !== undefined && isLeafCategory(cat) && cat.pathnameFilters !== undefined
            ? cat.pathnameFilters
            : [];
    const categoryLabel = categoryName
        ? getFullLabel(categoryName)
            ? getFullLabel(categoryName)
            : getLabel(categoryName)
        : null;

    if (importantProps.length) {
        let title = '';

        importantProps.map((prop, i) => {
            if (i === 0) {
                if (filter.props[prop] !== undefined && filter.props[prop].length === 1) {
                    const thisProp = allProps[categoryName][prop].find((a) => {
                        return a[0] === filter.props[prop][0];
                    });
                    title +=
                        thisProp !== undefined && thisProp[2]
                            ? thisProp[2]
                            : thisProp !== undefined && thisProp[1]
                            ? categoryLabel + ' / ' + thisProp[1]
                            : categoryLabel;
                } else {
                    title += categoryLabel;
                }
            } else {
                if (filter.props[prop] !== undefined && filter.props[prop].length === 1) {
                    const thisProp = allProps[categoryName][prop].find((a) => {
                        return a[0] === filter.props[prop][0];
                    });
                    title += thisProp !== undefined && thisProp[1] ? ' / ' + thisProp[1] : null;
                }
            }
        });

        return title;
    } else {
        return categoryLabel;
    }
}
