import {
    number_DAYS,
    number_DAYS_AGO,
    number_HOURS,
    number_HOURS_AGO,
    number_MINUTES,
    number_MINUTES_AGO,
    number_MONTHS,
    number_MONTHS_AGO,
    number_SECONDS,
    number_SECONDS_AGO,
    number_WEEKS,
    number_WEEKS_AGO,
    number_YEARS,
    number_YEARS_AGO,
    DATE_ABBR_MONTH_1,
    DATE_ABBR_MONTH_10,
    DATE_ABBR_MONTH_11,
    DATE_ABBR_MONTH_12,
    DATE_ABBR_MONTH_2,
    DATE_ABBR_MONTH_3,
    DATE_ABBR_MONTH_4,
    DATE_ABBR_MONTH_5,
    DATE_ABBR_MONTH_6,
    DATE_ABBR_MONTH_7,
    DATE_ABBR_MONTH_8,
    DATE_ABBR_MONTH_9,
    MONTH_NAME_1,
    MONTH_NAME_10,
    MONTH_NAME_11,
    MONTH_NAME_12,
    MONTH_NAME_2,
    MONTH_NAME_3,
    MONTH_NAME_4,
    MONTH_NAME_5,
    MONTH_NAME_6,
    MONTH_NAME_7,
    MONTH_NAME_8,
    MONTH_NAME_9,
    TIMEFORMAT_AT_time,
    TIMEFORMAT_TODAY,
    TIMEFORMAT_YESTERDAY,
    YESTERDAY,
} from 'mk/autogenerated/translations/timeformat.14b2d059972fbeaf378853edccbc6f89'
import { anyToDate } from 'mk/common/timeUtils';
import { interpolate } from 'mk2/services/i18n';

/**
 * Returns datetime with time part set to 0:00:00
 *
 * @param d
 * @returns {Date}
 */
export function dateWithoutTime(d: Date) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

/**
 * Returns number of seconds and days between two dates
 *
 * @param dFrom - first date 'from'
 * @param dTo - second date 'to'
 * @returns {{diffSeconds: number, diffDays: number}}
 */
export function dateDiff(dFrom: string | Date, dTo: string | Date) {
    dFrom = anyToDate(dFrom);
    dTo = anyToDate(dTo);
    const dSeconds = Math.floor(dFrom.getTime() / 1000);
    const nowSeconds = Math.floor(dTo.getTime() / 1000);

    const diffSeconds = nowSeconds - dSeconds;

    const fromDate = dateWithoutTime(dFrom);
    const toDate = dateWithoutTime(dTo);

    const zoneDelta = (fromDate.getTimezoneOffset() - toDate.getTimezoneOffset()) * 6e4;

    const diffDayMillis = toDate.getTime() - fromDate.getTime() + zoneDelta;

    // we need to round, because daylight-saving makes fun :-)
    const diffDays = Math.floor(diffDayMillis / 86400000);

    return {
        diffSeconds,
        diffDays,
    };
}

/**
 * return date in MM YYY format in local timezone, e.g. 'marec 2021'
 *
 * Na serveri a v datovych modeloch mame cas ulozeny v UTC. Tato funkcia datum (v js Date obsahuje aj cas)
 * vypise v lokalnej casovej zone prehliadaca.
 */
export function monthAndYear(d: string | Date, shortMonthName = false) {
    d = anyToDate(d);
    const year = d.getFullYear();
    const month = shortMonthName
        ? translateMonthAbbr(d.getMonth())
        : translateMonth(d.getMonth());
    return month + ' ' + year;
}

/*
 * return date in YYYY-MM-DD format in local timezone, e.g. '2018-09-01'
 *
 * Na serveri a v datovych modeloch mame cas ulozeny v UTC. Tato funkcia datum (v js Date obsahuje aj cas)
 * vypise v lokalnej casovej zone prehliadaca.
 */
export function YMDLocal(d: string | Date) {
    d = anyToDate(d);
    return d.getFullYear() + '-' + `${d.getMonth() + 1}`.padStart(2, '0') + '-' + `${d.getDate()}`.padStart(2, '0');
}

/*
 * return date in YYYY-MM-DD format in UTC timezone, e.g. '2018-09-01'
 *
 * Na serveri a v datovych modeloch mame ulozeny cas v UTC. Tato funkcia datum (v js Date obsahuje aj cas)
 * vypise v UTC casovej zone.
 */
export function YMDUtc(d: string | Date) {
    d = anyToDate(d);
    return d.getUTCFullYear() + '-' + `${d.getUTCMonth() + 1}`.padStart(2, '0') + '-' + `${d.getUTCDate()}`.padStart(2, '0');
}

/*
 * return date in YYYY-MM-DDTHH:MM:SS format in local timezone, e.g. 2018-09-01T18:00:00
 *
 * Na serveri a v datovych modeloch mame cas ulozeny v UTC. Tato funkcia datum (v js Date obsahuje aj cas)
 * vypise v lokalnej casovej zone prehliadaca.
 */
export function YMDHMSLocal(d: string | Date) {
    d = anyToDate(d);
    return (
        YMDLocal(d) + 'T' +
        `${d.getHours()}`.padStart(2, '0') + ':' +
        `${d.getMinutes()}`.padStart(2, '0') + ':' +
        `${d.getSeconds()}`.padStart(2, '0')
    );
}

/*
 * e.g. return date in ISO 8601 yyyy-mm-ddThh:mm:ss.nnnnnn+|-hh:mm format, e.g. '2018-09-01T13:31:58.813Z'
 */
export function YMDHMSIso(d: string | Date) {
    d = anyToDate(d);
    return d.toISOString();
}

/**
 * equivalent of strftime('%H:%M', d) but without using strftime npm module
 * @param d
 * @returns {string}
 */
export function hoursAndMinutes(d: string | Date) {
    d = anyToDate(d);
    return `${d.getHours()}`.padStart(2, '0') + ':' + `${d.getMinutes()}`.padStart(2, '0');
}

/**
 * equivalent of strftime('%H:%M', d) but without using strftime npm module
 * @param d
 * @returns {string}
 */
export function hoursAndMinutesAndSeconds(d: string | Date) {
    d = anyToDate(d);
    return hoursAndMinutes(d) + ':' + `${d.getSeconds()}`.padStart(2, '0');
}

/**
 * Format full date  '19. sep 2018' or '19.9.2018'
 *
 * @param d
 * @param withMonthName
 * @returns {string}
 */
export function dateLocal(d: string | Date, withMonthName = true): string {
    d = anyToDate(d);

    if (withMonthName) {
        const monthAbbr = translateMonthAbbr(d.getMonth());
        return `${d.getDate()}. ${monthAbbr} ${d.getFullYear()}`;
    } else {
        return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    }
}

/**
 * Vrati text s fuzzy popisom casu
 *
 * if daysDiff == 0:
 *     'dnes o 10:07'
 * elif daysDiff == 1:
 *     'vcera o 10:07'
 * else
 *     10. mar 2017
 *
 * @param d - date
 * @param dNow - use this as current time (actual current time is used if not defined)
 * @param alwaysShowTime - always display time
 * @return string
 */
export function timeAt(d: string | Date, dNow?: string | Date, alwaysShowTime = false) {
    d = anyToDate(d);
    dNow = dNow ? anyToDate(dNow) : new Date();
    // const t: any = i18n.t as any;

    const { diffDays } = dateDiff(d, dNow);

    if (diffDays === 0) { // today
        return TIMEFORMAT_TODAY + ' ' + interpolate(TIMEFORMAT_AT_time, {
            count: d.getHours() % 10,
            time: hoursAndMinutes(d),
        });
    } else if (diffDays === 1) { // yesterday
        return TIMEFORMAT_YESTERDAY + ' ' + interpolate(TIMEFORMAT_AT_time, {
            count: d.getHours() % 10,
            time: hoursAndMinutes(d),
        });
    } else {
        const monthAbbr = translateMonthAbbr(d.getMonth());
        return `${d.getDate()}. ${monthAbbr} ${d.getFullYear()}`
            + (alwaysShowTime
                ? ' ' + interpolate(TIMEFORMAT_AT_time, {
                    count: d.getHours() % 10,
                    time: hoursAndMinutes(d),
                })
                : ''
            );
    }
}

/**
 * Vrati text s fuzzy popisom datumu
 *
 * if daysDiff == 0:
 *     'dnes'
 * elif daysDiff == 1:
 *     'vcera'
 * else
 *     10. mar 2017
 *
 * @param d - date
 * @param dNow - use this as current time (actual current time is used if not defined)
 * @return string
 */
export function dateAt(d: string | Date, dNow?: string | Date) {
    d = anyToDate(d);
    dNow = dNow ? anyToDate(dNow) : new Date();
    // const t: any = i18n.t as any;

    const { diffDays } = dateDiff(d, dNow);

    if (diffDays === 0) { // today
        return TIMEFORMAT_TODAY;
    } else if (diffDays === 1) { // yesterday
        return TIMEFORMAT_YESTERDAY;
    } else {
        const monthAbbr = translateMonthAbbr(d.getMonth());
        return `${d.getDate()}. ${monthAbbr} ${d.getFullYear()}`;
    }
}

/**
 * Calculate diff between `date1` and `date2` and return
 * diff as translated text. If `relative` is true, it will return
 * text without 'AGO' part
 *
 * @param dFrom - first date 'from'
 * @param dTo - second date 'to', if not set new Date() is used
 * @param between - if specified and true, now "ago" will be added
 * @return string
 */
function timeDiff(dFrom: string | Date, dTo: string | Date, between: boolean) {
    dFrom = anyToDate(dFrom);
    dTo = dTo ? anyToDate(dTo) : new Date();

    const { diffDays, diffSeconds } = dateDiff(dFrom, dTo);

    let res = null;
    if (diffDays === 0) {
        if (diffSeconds < 60) {
            res = interpolate(between ? number_SECONDS : number_SECONDS_AGO, {
                count: diffSeconds,
                number: diffSeconds,
            });
        } else if (diffSeconds < 3600) {
            const minutes = Math.floor(diffSeconds / 60);
            res = interpolate(between ? number_MINUTES : number_MINUTES_AGO, {count: minutes, number: minutes});
        } else {
            const hours = Math.floor(diffSeconds / 3600);
            res = interpolate(between ? number_HOURS : number_HOURS_AGO, {count: hours, number: hours});
        }
    } else if (diffDays === 1) {
        res = between ? interpolate(number_DAYS, {count: diffDays, number: diffDays}) : YESTERDAY;
    } else if (diffDays < 7) {
        res = interpolate(between ? number_DAYS : number_DAYS_AGO, {count: diffDays, number: diffDays});
    } else if (diffDays < 30) {
        const weeks = Math.floor(diffDays / 7);
        res = interpolate(between ? number_WEEKS : number_WEEKS_AGO, {count: weeks, number: weeks});
    } else if (diffDays < 365) {
        const months = Math.floor(diffDays / 30);
        res = interpolate(between ? number_MONTHS : number_MONTHS_AGO, {count: months, number: months});
    } else {
        const years = Math.floor(diffDays / 365);
        res = interpolate(between ? number_YEARS : number_YEARS_AGO, {count: years, number: years});
    }

    return res;
}

/**
 * Vrati text s fuzzy popisom kolko casu ubehlo od daneho datumu
 *
 * if daysDiff == 0:
 *     if timeDiff < 1min:
 *         'pred X sekundami'
 *     elif 1min < timeDiff < 60min:
 *         'pred X minutami'
 *     else:
 *         'pred X hodinami'
 * elif daysDiff == 1:
 *     'vcera'
 * elif 1 < daysDiff < 7:
 *     'pred X dnami'
 * elif 7 <= daysDiff < 30:
 *     'pred X tyzdnami'
 * elif 30 <= daysDiff < 365:
 *     'pred X mesiacmi'
 * else
 *     'pred X rokmi'
 *
 * @param dFrom - date
 * @param dNow - use this as current time (actual current time is used if not defined)
 * @param ignoreNegative - if true return diff 0 seconds (dFrom == dNow)
 * @return string
 */
export function timeAgo(dFrom: string | Date, dNow?: string | Date, ignoreNegative?: boolean): string {
    dFrom = anyToDate(dFrom);
    dNow = dNow ? anyToDate(dNow) : new Date();
    dFrom = dNow < dFrom && ignoreNegative ? dNow : dFrom;
    return timeDiff(dFrom, dNow, false);
}

/**
 * Vrati text s fuzzy popisom kolko casu ubehlo medzi dvoma datumami
 *
 * if daysDiff == 0:
 *     if timeDiff < 1min:
 *         'X sekund'
 *     elif 1min < timeDiff < 60min:
 *         'X minut'
 *     else:
 *         'X hodin'
 * elif 1 < daysDiff < 7:
 *     'X dni'
 * elif 7 <= daysDiff < 30:
 *     'X tyzdnov'
 * elif 30 <= daysDiff <365:
 *     'X mesiacov'
 * else
 *     'X rokov'
 *
 * @param dFrom - first date 'from'
 * @param dTo - second date 'to'
 * @return string
 */
export function timeBetween(dFrom: string | Date, dTo: string | Date): string {
    dFrom = dFrom ? anyToDate(dFrom) : new Date();
    return timeDiff(dFrom, dTo, true);
}

export function translateMonthAbbr(zeroMonth: number) {
    switch (zeroMonth) {
        case 0:
            return DATE_ABBR_MONTH_1;
        case 1:
            return DATE_ABBR_MONTH_2;
        case 2:
            return DATE_ABBR_MONTH_3;
        case 3:
            return DATE_ABBR_MONTH_4;
        case 4:
            return DATE_ABBR_MONTH_5;
        case 5:
            return DATE_ABBR_MONTH_6;
        case 6:
            return DATE_ABBR_MONTH_7;
        case 7:
            return DATE_ABBR_MONTH_8;
        case 8:
            return DATE_ABBR_MONTH_9;
        case 9:
            return DATE_ABBR_MONTH_10;
        case 10:
            return DATE_ABBR_MONTH_11;
        case 11:
            return DATE_ABBR_MONTH_12;
    }
}

export function translateMonth(zeroMonth: number) {
    switch (zeroMonth) {
        case 0:
            return MONTH_NAME_1;
        case 1:
            return MONTH_NAME_2;
        case 2:
            return MONTH_NAME_3;
        case 3:
            return MONTH_NAME_4;
        case 4:
            return MONTH_NAME_5;
        case 5:
            return MONTH_NAME_6;
        case 6:
            return MONTH_NAME_7;
        case 7:
            return MONTH_NAME_8;
        case 8:
            return MONTH_NAME_9;
        case 9:
            return MONTH_NAME_10;
        case 10:
            return MONTH_NAME_11;
        case 11:
            return MONTH_NAME_12;
    }
}

/**
 * sformatuje rozsah dvoch datumov. Vrati
 *   '01/2019'  (ak je to cely mesiac)
 *   '10.1.2019' (ak je to iba jeden den)
 *   '13.02.2019 - 16.5.2019'  (ak je to nevynimocny interval)
 *
 * @param dfrom - first date 'from'
 * @param dto - second date 'to'
 */
export function prettyDateRange(dfrom: string | Date, dto: string | Date): string {
    dfrom = anyToDate(dfrom);
    dto = anyToDate(dto);

    if (isEqualDay(dfrom, firstDayInMonth(dfrom)) && isEqualDay(dto, lastDayInMonth(dto)) &&
       dfrom.getMonth() === dto.getMonth() && dfrom.getFullYear() === dto.getFullYear()) {
        return `${dfrom.getMonth() + 1}/${dfrom.getFullYear()}`;
    } else if (dfrom === dto) {
        return dateLocal(dfrom, false);
    } else {
        return `${dateLocal(dfrom, false)} - ${dateLocal(dto, false)}`;
    }
}

/**
 * sformatuje rozsah mesiacov medzi dvoma datumami. Vrati
 *   'jan 2019'  (ak je rozsah 10.1.2019 - 23.1.2019)
 *   'jan-mar/20' (ak je rozsah 10.1.2020 - 17.3.2020)
 *   'mar/15-nov/20'  (ak je rozsah 17.3.2015 - 12.11.2020)
 *
 * @param dfrom - first date 'from'
 * @param dto - second date 'to'
 */
export function prettyMonthYearRange(dfrom: string | Date, dto: string | Date): string {
    dfrom = firstDayInMonth(anyToDate(dfrom));
    dto = firstDayInMonth(anyToDate(dto));

    if (isEqualDay(dfrom, dto)) {
        return `${translateMonthAbbr(dfrom.getMonth())} ${dfrom.getFullYear()}`;
    } else if (dfrom.getFullYear() === dto.getFullYear()) {
        return `${translateMonthAbbr(dfrom.getMonth())}-${translateMonthAbbr(dto.getMonth())}/${dfrom.getFullYear() % 100}`;
    } else {
        return `${translateMonthAbbr(dfrom.getMonth())}/${dfrom.getFullYear() % 100}-${translateMonthAbbr(dto.getMonth())}/${dto.getFullYear() % 100}`;
    }
}

const firstDayInMonth = (d: Date): Date =>
    new Date(d.getFullYear(), d.getMonth(), 1);

const lastDayInMonth = (d: Date): Date =>
    new Date(d.getFullYear(), d.getMonth() + 1, 0);

const isEqualDay = (d1: Date, d2: Date): boolean =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
