/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp15 } from './commonProp15';
import { commonProp4 } from './commonProp4';

export const ski_helmets : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp15,
    p_brand: [
        d("Alpina Sports"),
        d("Atomic"),
        d("Blizzard"),
        d("Bolle"),
        d("Briko"),
        d("Crazy Ears"),
        d("Dainese"),
        d("Elan"),
        d("Etape"),
        d("Fischer"),
        d("Giro"),
        d("Hatchey"),
        d("Head"),
        d("HS Sport"),
        d("Julbo"),
        d("Kask"),
        d("Oakley"),
        d("Oneil"),
        d("POC"),
        d("Quicksilver"),
        d("Reaper"),
        d("Rossignol"),
        d("Roxy"),
        d("Salomon"),
        d("Slokker"),
        d("Uvex"),
        d("Volant"),
        d("Smith"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
