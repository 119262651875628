/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp23 } from './commonProp23';

export const employment : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["JOBS_ADMINISTRATION", "Administrativa"],
        ["JOBS_FINANCE", "Finance"],
        ["JOBS_GASTRONOMY", "Gastro"],
        ["JOBS_CRAFTS", "\u0158emesla"],
        ["JOBS_TRADE", "Obchod"],
        ["JOBS_SERVICES", "Slu\u017eby"],
        ["JOBS_TRANSPORT_AND_LOGISTICS", "Doprava a logistika"],
        ["JOBS_TOURISM", "Cestovn\u00ed ruch"],
        ["JOBS_EDUCATION", "\u0160kolstv\u00ed a vzd\u011bl\u00e1v\u00e1n\u00ed"],
        ["JOBS_MANUFACTURING", "V\u00fdroba"],
        ["JOBS_HEALTHCARE", "Zdravotnictv\u00ed"],
        ["JOBS_TECHNICAL_STAFF", "Techni\u010dt\u00ed pracovn\u00edci"],
        ["JOBS_INFORMATION_TECHNOLOGY", "IT"],
        ["JOBS_SAFETY", "Bezpe\u010dnost"],
        ["JOBS_MARKETING", "Marketing"],
        ["JOBS_AGRICULTURE", "Zem\u011bd\u011blstv\u00ed"],
        ["JOBS_WORKERS_PROFESSIONS", "D\u011blnick\u00e9 profese"],
        ["JOBS_SCIENCE_AND_RESEARCH", "V\u011bda a v\u00fdzkum"],
        ["JOBS_CULTURE_AND_SPORTS", "Kultura a sport"],
        ["JOBS_MANAGEMENT", "Management"],
        ["JOBS_JUSTICE", "Pr\u00e1vo"],
        ["JOBS_GOVERNMENT", "St\u00e1tn\u00ed spr\u00e1va"],
        ["JOBS_CREATIVE_WORK", "Tv\u016fr\u010d\u00ed pr\u00e1ce a design"],
        ["JOBS_OTHER", "Jin\u00e9"],
    ],
    p_working_time: [
        ["FULL_TIME_JOB", "Pln\u00fd"],
        ["PART_TIME_JOB", "\u010c\u00e1ste\u010dn\u00fd"],
        ["TEMPORARY_JOB", "Brig\u00e1da"],
    ],
    p_education: [
        ["SECONDARY_WITHOUT_DIPLOMA", "Bez maturity"],
        ["SECONDARY_WITH_DIPLOMA", "S maturitou"],
        ["COLLEGE", "V\u0160"],
    ],
    p_maternity_leave_suitable: commonProp23,
    p_homeoffice: commonProp23,
};
