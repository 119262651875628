import { Plan, UserEntity } from 'mk2/schemas';

export function isAnonymous(user: UserEntity) {
    return !user || user.id === 0;
}

export function isAnonymousUsername(username: string): boolean {
    return (username === 'anonymous_user' || username === 'anonym');
}

export function isAnonymousUser(user: UserEntity): boolean {
    // NOT to be confused with 'isAnonymous' !
    // This function checks if a user is a special system 'anonymous_user' created as an internal owner of all "anonymous" posts.
    return isAnonymousUsername(user.username);
}

export function isAuthenticated(requestUser: UserEntity): boolean {
    // isAuthenticated ma parameter requestUser, aby sme enforcovali
    // ze sa nezacne pouzivat nieco ako get_current_request() v djangu
    // ale radsej vsade kde treba sa proposiela requestUser.

    // Toto je assert, ci niekde nie je bug a posle sa zly requestUser
    // assert(MK.requestUser.id===requestUser.id);

    return requestUser.id !== 0;
}

export function isAuthenticatedAs(requestUser: UserEntity, users: UserEntity | UserEntity[]): boolean {
    // isAuthenticatedAs ma parameter requestUser, aby sme enforcovali
    // ze sa nezacne pouzivat nieco ako get_current_request() v djangu
    // ale radsej vsade kde treba sa proposiela requestUser.

    // Toto je assert, ci niekde nie je bug a posle sa zly requestUser
    // assert(MK.requestUser.id===requestUser.id);
    users = Array.isArray(users) ? users : [users];

    return !isAnonymous(requestUser) && !!users.find((u) => requestUser.id === u.id);
}

export function isAuthenticatedAsAmbassador(requestUser: UserEntity): boolean {
    return isAuthenticated(requestUser) && !!requestUser.isAmbassadorOfBrandId;
}

export function isAuthenticatedAsAmbassadorOfBrand(requestUser: UserEntity, ambassadorBrandId: number): boolean {
    return isAuthenticated(requestUser)
        && !!requestUser.isAmbassadorOfBrandId
        && !!ambassadorBrandId
        && requestUser.isAmbassadorOfBrandId === ambassadorBrandId;
}

export function authorIsModerator(authorUser: UserEntity, moderatorUsers: UserEntity | UserEntity[]): boolean {
    moderatorUsers = Array.isArray(moderatorUsers) ? moderatorUsers : [moderatorUsers];

    return !isAnonymous(authorUser) && !!moderatorUsers.find((u) => authorUser.id === u.id);
}

export function canManageBazaar(userPermissions) {
    return userPermissions.indexOf('bazaar.can_manage_bazaar') >= 0;
}

export function canManageForum(userPermissions) {
    return userPermissions.indexOf('forum.can_manage_forum') >= 0;
}

export function canManageGroups(userPermissions) {
    return userPermissions.indexOf('users.can_manage_groups') >= 0;
}

export function canManageBlogs(userPermissions) {
    return userPermissions.indexOf('photoblog.can_manage_blogs') >= 0;
}

export function canEditWiki(userPermissions) {
    return userPermissions.indexOf('wiki.can_edit_wiki') >= 0;
}

export function canManageStrollers(userPermissions) {
    return userPermissions.indexOf('auth.can_manage_strollers') >= 0;
}

export function canReportProducts(userPermissions) {
    return userPermissions.indexOf('bazaar.can_report_products') >= 0;
}

export function canUseCleanSort(userPermissions) {
    return userPermissions.indexOf('bazaar.can_use_cleansort') >= 0;
}

export function canEditProducts(userPermissions) {
    return userPermissions.indexOf('bazaar.can_edit_products') >= 0;
}

export function canManageSales(userPermissions: string[]) {
    return userPermissions.indexOf('bo.can_manage_sales') >= 0;
}

export function hasAds(requestUser: UserEntity): boolean {
    return !requestUser.activePlan || requestUser.activePlan === Plan.FREE;
}

// keep in sync with bazaar.api.v3.utils#can_order_in_bazaar()
export function canOrderInBazaar(user: UserEntity, userPermissions: string[]) {
    return canManageBazaar(userPermissions) ||
        user.username === 'ceresnam_sk';
}
