import { Action } from 'redux';

export const JAM_NOTIFICATION = 'JAM_NOTIFICATION';
export interface JamNotificationAction extends Action {
    readonly type: typeof JAM_NOTIFICATION;
    readonly channel: string;
}

export const jamNotify = (channel: string): JamNotificationAction => ({
    type: JAM_NOTIFICATION, channel,
});
