/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp0 } from './commonProp0';
import { commonProp1 } from './commonProp1';
import { commonProp2 } from './commonProp2';
import { commonProp3 } from './commonProp3';
import { commonProp4 } from './commonProp4';

export const rompers : {[key: string]: Array<[string, string]>} = {
    p_sleeve: commonProp0,
    p_size: commonProp1,
    p_sex: commonProp2,
    p_brand: commonProp3,
    p_color: commonProp4,
};
