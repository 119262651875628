/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const running_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("adidas"),
        d("alpine pro"),
        d("arcore"),
        d("asics"),
        d("axis"),
        d("blizzard"),
        d("bula"),
        d("champion"),
        d("cmp"),
        d("colmar"),
        d("columbia"),
        d("compressport"),
        d("craft"),
        d("diesel"),
        d("eisb\u00e4r"),
        d("etape"),
        d("fila"),
        d("finmark"),
        d("fitforce"),
        d("fox"),
        d("hannah"),
        d("hilfiger"),
        d("icebreaker"),
        d("kama"),
        d("kappa"),
        d("levis"),
        d("lewro"),
        d("lotto"),
        d("mico"),
        d("mizuno"),
        d("mons royale"),
        d("northfinder"),
        d("northwave"),
        d("oneil"),
        d("poc"),
        d("progress"),
        d("puma"),
        d("reaper"),
        d("roxy"),
        d("rukka"),
        d("russel athletic"),
        d("salomon"),
        d("spyder"),
        d("starling"),
        d("the north face"),
        d("trimm"),
        d("ulvang"),
        d("umbro"),
        d("under armour"),
        d("voxx"),
        d("willard"),
        d("wilson"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
