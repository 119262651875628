/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const health_and_beauty : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HAIR_CARE", "P\u00e9\u010de o vlasy"],
        ["NAIL_CARE", "P\u00e9\u010de o nehty"],
        ["COSMETICS", "Kosmetika"],
        ["MASSAGES", "Mas\u00e1\u017ee"],
        ["PHYSIOTHERAPY", "Fyzioterapie"],
        ["FITNESS_ADVICE", "Fit poradenstv\u00ed"],
        ["TATTOOING", "Tetov\u00e1n\u00ed"],
        ["ANIMAL_CARE", "P\u00e9\u010de o zv\u00ed\u0159ata"],
    ],
    p_practice_length: commonProp22,
};
