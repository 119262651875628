import { CounsellingEntity, PostEntity } from 'mk2/apps/forum/schemas';
import { Entity, PhotoEntity } from 'mk2/schemas';
import { schema } from 'normalizr';

export enum HomePostType {
    DISCUSSION = 'discussion',
    WIKI_ARTICLE = 'wikiArticle',
    WIKI_REVIEW_ARTICLE = 'wikiReviewArticle',
    SHORT_MESSAGE = 'shortMessage',
    EXPERIENCE = 'experience',
    REVIEW = 'review',
    COUNSELLING = 'counselling',
    COUNSELLING_TOPIC = 'counsellingTopic',
    FORUM_TOPIC = 'forumTopic',
    FROM_INTERNET = 'fromInternet',
    PHOTOBLOG_ALBUM = 'photoblogAlbum',
    PHOTOBLOG_ARTICLE = 'photoblogArticle',
    CONTEST = 'contest',
    YOUTUBE_MK = 'youtubeMK',
}

export interface UtmQuery {
    utm_campaign?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_content?: string;
    mkutm_campaign?: string;
    mkutm_medium?: string;
    mkutm_source?: string;
    mkutm_content?: string;
}

export interface HomePostEntity extends Entity {
    photo: PhotoEntity;
    title: string;
    description: string;
    type: HomePostType;
    url: string;
    likes?: number;
    comments?: number;
}

export interface HomeCounsellingEntity extends Entity {
    counselling: CounsellingEntity;
    posts: PostEntity[];
}

export interface HomePhotoblogPostEntity extends Entity {
    photo: PhotoEntity;
    url: string;
}

export const HomePostSchema = new schema.Entity('homePost', {
});
export const HomePhotoblogPostSchema = new schema.Entity('homePhotoblogPost', {
});
export const HomeCounsellingSchema = new schema.Entity('homeCounselling', {
});
