import { LoginAction, LOGIN } from 'mk2/actions';
import { BlogsFriendsFeedFetchSuccessAction } from 'mk2/apps/blogs/containers/BlogsFriendsFeed/BlogsFriendsFeed.actions';
import { BlogsPopularFeedFetchSuccessAction } from 'mk2/apps/blogs/containers/BlogsPopularFeed/BlogsPopularFeed.actions';
import { ForumReadFeedTriggerAction, FORUM_READ_FEED_TRIGGER } from 'mk2/apps/forum/actions';
import { TopicReadAction } from 'mk2/apps/forum/containers/Topic/Topic.actions';
import { BrandDaysCampaignEntity } from 'mk2/apps/forum/schemas';
import { GroupSharedFeedReadAction } from 'mk2/apps/groups/containers/GroupShared/GroupShared.actions';
import { Count } from 'mk2/components/NotificationsBadge';
import {
    PageheaderRefreshFeedMenuApiSuccessAction,
    PageHeaderAddFeedMenuItemAction,
    PageHeaderFetchFlaggedCountsSuccessAction,
    PageHeaderFetchSuccessAction,
    PageHeaderRemoveFeedMenuItemAction,
    PAGEHEADER_ADD_FEEDMENU_ITEM,
    PAGEHEADER_FETCH_FLAGGED_COUNTS_SUCCESS,
    PAGEHEADER_FETCH_SUCCESS,
    PAGEHEADER_REFRESH_FEED_MENU_API_SUCCESS,
    PAGEHEADER_REMOVE_FEEDMENU_ITEM,
} from 'mk2/containers/PageHeader/PageHeader.action';
import { tupdate } from 'mk2/helpers/types';
import { PageHeaderUserData } from 'mk2/schemas';
import { Reducer } from 'redux';

export type FeedItemOrGroup = FeedItem | FeedGroup;

export interface PageHeaderState {
    userData: PageHeaderUserData;
    brandDaysCampaign: BrandDaysCampaignEntity;
    feedMenu: FeedItemOrGroup[];
}

export interface FeedGroup {
    isGroup: boolean;
    name: string;
    title: string;
    items: FeedItem[];
    icon: string;
    url: string;
    url2: string;
    url3: string;
    lastVisitCount: Count;
    isMobile: boolean;
    section?: string;
}

export interface FeedItem {
    isGroup: boolean;
    name: string;
    title: string;
    items?: FeedItem[];
    icon: string;
    url: string;
    url2: string;
    url3: string;
    lastVisitCount: Count;
    tabsLastVisitCounts?: {
        [tabName: string]: Count | null;
    };
    isMobile: boolean;
    section?: string;
}

export type MenuItem = FeedGroup | FeedItem;

const userDataInitialState: PageHeaderUserData = {
    heartsCount: 0,
    unreadMails: 0,
    newBazaarEvents: 0,
    newBazaarSell: 0,
    allBazaarSell: 0,
    wallStatusNews: 0,
    wishlistUpdates: 0,
    groupsCount: 0,
    newContests: 0,
    subscribedCounsellings: {},
    newTestingCalls: 0,
};

const pageHeaderInitialState: PageHeaderState = {
    userData: userDataInitialState,
    brandDaysCampaign: null,
    feedMenu: null,
};

const resetVisitedCount = (item: FeedItem, name: string) => {
    return item.name === name ? tupdate(item, { lastVisitCount: 0 }) : item;
};

const resetVisitedCountInFeeds = (item: FeedItem, name: string) => {
    return item.name === 'feeds'
        ? tupdate(item, {
              tabsLastVisitCounts: tupdate(item.tabsLastVisitCounts, {
                  [name]: 0,
              }),
          })
        : item;
};

declare type PageHeaderReducerAction =
    | PageHeaderFetchSuccessAction
    | PageHeaderFetchFlaggedCountsSuccessAction
    | PageHeaderAddFeedMenuItemAction
    | PageHeaderRemoveFeedMenuItemAction
    | GroupSharedFeedReadAction
    | BlogsFriendsFeedFetchSuccessAction
    | BlogsPopularFeedFetchSuccessAction
    | TopicReadAction
    | ForumReadFeedTriggerAction
    | PageheaderRefreshFeedMenuApiSuccessAction
    | LoginAction;

export const pageHeaderReducer: Reducer<PageHeaderState> = (
    state: PageHeaderState = pageHeaderInitialState,
    action: PageHeaderReducerAction,
) => {
    switch (action.type) {
        case PAGEHEADER_FETCH_SUCCESS:
            return tupdate(state, {
                userData: action.data,
            });
        case PAGEHEADER_FETCH_FLAGGED_COUNTS_SUCCESS:
            if (state.feedMenu && state.feedMenu.length > 0) {
                let idxFlagged: number;
                let idxFlaggedGroups: number;
                Object.keys(state.feedMenu).forEach((k) => {
                    if (state.feedMenu[k].name === 'flagged') {
                        idxFlagged = parseInt(k, 10);
                    }
                    if (state.feedMenu[k].name === 'flagged-groups') {
                        idxFlaggedGroups = parseInt(k, 10);
                    }
                });
                if (idxFlagged !== undefined && idxFlaggedGroups !== undefined) {
                    let newFeedMenu = [...state.feedMenu];
                    newFeedMenu = [
                        ...newFeedMenu.slice(0, idxFlagged),
                        tupdate(newFeedMenu[idxFlagged], { lastVisitCount: action.flaggedCount }),
                        ...newFeedMenu.slice(idxFlagged + 1),
                    ];
                    newFeedMenu = [
                        ...newFeedMenu.slice(0, idxFlaggedGroups),
                        tupdate(newFeedMenu[idxFlaggedGroups], { lastVisitCount: action.flaggedGroupCount }),
                        ...newFeedMenu.slice(idxFlaggedGroups + 1),
                    ];
                    return tupdate(state, {
                        feedMenu: newFeedMenu,
                    });
                }
            }
            return state;

        case PAGEHEADER_ADD_FEEDMENU_ITEM:
            const menuGroupIdxForAdd = state.feedMenu.findIndex(
                (item) => item.isGroup === true && item.name === action.menuGroup,
            );
            if (menuGroupIdxForAdd >= 0) {
                const menuGroup = state.feedMenu[menuGroupIdxForAdd];
                const menuItem = menuGroup.items.find((item) => item.name === action.name);
                if (!menuItem) {
                    const newMenuItem: FeedItem = {
                        isGroup: false,
                        name: action.name,
                        title: action.title,
                        icon: action.icon,
                        url: action.url,
                        url2: null,
                        url3: null,
                        lastVisitCount: null,
                        isMobile: false,
                    };
                    let newFeedMenu = [...state.feedMenu];
                    newFeedMenu = [
                        ...newFeedMenu.slice(0, menuGroupIdxForAdd),
                        tupdate(newFeedMenu[menuGroupIdxForAdd], {
                            items: [...menuGroup.items, newMenuItem],
                        }),
                        ...newFeedMenu.slice(menuGroupIdxForAdd + 1),
                    ];
                    return tupdate(state, {
                        feedMenu: newFeedMenu,
                    });
                }
            }
            return state;

        case PAGEHEADER_REMOVE_FEEDMENU_ITEM:
            const menuGroupIdxForRemove = state.feedMenu.findIndex(
                (item) => item.isGroup === true && item.name === action.menuGroup,
            );
            if (menuGroupIdxForRemove >= 0) {
                const menuGroup = state.feedMenu[menuGroupIdxForRemove];
                const menuItemIdx = menuGroup.items.findIndex((item) => item.name === action.name);
                if (menuItemIdx >= 0) {
                    let newFeedMenu = [...state.feedMenu];
                    newFeedMenu = [
                        ...newFeedMenu.slice(0, menuGroupIdxForRemove),
                        tupdate(newFeedMenu[menuGroupIdxForRemove], {
                            items: [
                                ...menuGroup.items.slice(0, menuItemIdx),
                                ...menuGroup.items.slice(menuItemIdx + 1),
                            ],
                        }),
                        ...newFeedMenu.slice(menuGroupIdxForRemove + 1),
                    ];
                    return tupdate(state, {
                        feedMenu: newFeedMenu,
                    });
                }
            }
            return state;

        case 'BLOGS_FRIENDS_FEED_FETCH_SUCCESS': /* Keep as a string so that we reduce bundle size */
        case 'BLOGS_POPULAR_FEED_FETCH_SUCCESS' /* Keep as a string so that we reduce bundle size */:
            const feedName = {
                BLOGS_FRIENDS_FEED_FETCH_SUCCESS: 'friends',
                BLOGS_POPULAR_FEED_FETCH_SUCCESS: 'popular',
            }[action.type];
            if (feedName) {
                return tupdate(state, {
                    feedMenu: state.feedMenu.map((rootItem) => resetVisitedCountInFeeds(rootItem, feedName)),
                });
            }
            return state;

        case 'GROUP_SHARED_FEED_READ': /* Keep as a string so that we reduce bundle size */
        case 'TOPIC_READ' /* Keep as a string so that we reduce bundle size */:
            const itemName =
                action.type === 'GROUP_SHARED_FEED_READ' /* Keep as a string so that we reduce bundle size */
                    ? `group-${action.groupId}`
                    : `topic-${action.topicId}`;
            return tupdate(state, {
                feedMenu: state.feedMenu.map((rootItem) =>
                    rootItem.isGroup
                        ? tupdate(rootItem, {
                              items: rootItem.items.map((item) => resetVisitedCount(item, itemName)),
                          })
                        : resetVisitedCount(rootItem, itemName),
                ),
            });

        case FORUM_READ_FEED_TRIGGER:
            if (action.feed === 'contests') {
                return tupdate(state, {
                    userData: tupdate(state.userData, {
                        newContests: 0,
                    }),
                });
            } else if (action.feed === 'testingCalls') {
                return tupdate(state, {
                    userData: tupdate(state.userData, {
                        newTestingCalls: 0,
                    }),
                });
            } else if (action.feed.startsWith('subscribedCounsellings')) {
                const topicId = parseInt(action.feed.split('_')[1], 10);
                return {
                    ...state,
                    userData: {
                        ...state.userData,
                        subscribedCounsellings: {
                            ...state.userData.subscribedCounsellings,
                            [topicId]: 0,
                        },
                    },
                };
            } else {
                return state;
            }

        case LOGIN:
            return {
                ...state,
                userData: action.initialState.containers.global.pageHeader.userData,
                feedMenu: action.initialState.containers.global.pageHeader.feedMenu,
                brandDaysCampaign: action.initialState.containers.global.pageHeader.brandDaysCampaign,
            };

        case PAGEHEADER_REFRESH_FEED_MENU_API_SUCCESS:
            return {
                ...state,
                feedMenu: action.feedMenu,
            };

        default:
            return state;
    }
};
