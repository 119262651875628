/**
 * Returns true, if showing mobile version of the site
 *
 * Keep in sync with mq-mobile() in mixins.scss and with Google Tag Manager
 */
export function isMqMobile() {
    return !!window.matchMedia(
        process.env.RESPONSIVE_BASED_ON_VIEWPORT
            ? 'only screen and (max-width: 979px)'
            : 'only screen and (max-device-width:599px) and (max-width: 979px),' +
                  'only screen and (max-device-height:599px) and (max-width: 979px)',
    ).matches;
}

/**
 * Returns true, if showing desktop version of the site
 *
 * Keep in sync with mq-desktop() in mixins.scss and with Google Tag Manager
 */
export function isMqDesktop() {
    return !!window.matchMedia(
        process.env.RESPONSIVE_BASED_ON_VIEWPORT
            ? 'screen and (min-width: 980px)'
            : 'screen and (min-device-width:600px) and (min-device-height:600px),' +
                  'screen and (max-device-width:599px) and (min-width: 980px),' +
                  'screen and (max-device-height:599px) and (min-width: 980px)',
    ).matches;
}
