import { CommentFormData } from 'mk2/apps/forum/components/CommentCreateEditForm';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { CommentParent } from 'mk2/schemas';
import { Action } from 'redux';

///////////////////////////////////////////////////////////////////
// Progress broadcast

export const COMMENTS_FETCH_TRIGGER = 'COMMENTS_FETCH_TRIGGER';
export interface CommentsFetchTriggerAction {
    readonly type: typeof COMMENTS_FETCH_TRIGGER;
    readonly commentParent: CommentParent;
}

export const COMMENTS_FETCH_REQUEST = 'COMMENTS_FETCH_REQUEST';
export interface CommentsFetchRequestAction extends APIRequestAction {
    readonly type: typeof COMMENTS_FETCH_REQUEST;
    readonly commentParent: CommentParent;
}

export interface CommentsFetchNormalizedResponse extends NormalizedResponse {
    readonly result: {
        comments: number[];
        canAdd: boolean;
        hasHiddenText: boolean;
    };
}
export const COMMENTS_FETCH_SUCCESS = 'COMMENTS_FETCH_SUCCESS';
export interface CommentsFetchSuccessAction extends APISuccessAction<CommentsFetchNormalizedResponse> {
    readonly type: typeof COMMENTS_FETCH_SUCCESS;
    readonly commentParent: CommentParent;
}
export const COMMENTS_FETCH_FAILURE = 'COMMENTS_FETCH_FAILURE';
export interface CommentsFetchFailureAction extends APIFailureAction {
    readonly type: typeof COMMENTS_FETCH_FAILURE;
    readonly commentParent: CommentParent;
}

export const commentsFetchTrigger =
    (commentParent: CommentParent): CommentsFetchTriggerAction => ({
        type: COMMENTS_FETCH_TRIGGER, commentParent,
    });
export const commentsFetchApi = {
    request: (commentParent: CommentParent): CommentsFetchRequestAction => ({
        type: COMMENTS_FETCH_REQUEST, commentParent,
    }),
    success: (commentParent: CommentParent, response: CommentsFetchNormalizedResponse): CommentsFetchSuccessAction => ({
        type: COMMENTS_FETCH_SUCCESS, commentParent, response,
    }),
    failure: (commentParent: CommentParent, error: any): CommentsFetchFailureAction => ({
        type: COMMENTS_FETCH_FAILURE, commentParent, error,
    }),
};

////////////////////////////////////////////////////////////////////////////////////
// Comment Delete

export const COMMENT_DELETE_TRIGGER = 'COMMENT_DELETE_TRIGGER';
export interface CommentDeleteTriggerAction {
    readonly type: typeof COMMENT_DELETE_TRIGGER;
    readonly commentParent: CommentParent;
    readonly commentId: number;
}

export const COMMENT_DELETE_CONTINUE_WITH_REASON = 'COMMENT_DELETE_CONTINUE_WITH_REASON';
export interface CommentDeleteCountinueWithReasonAction {
    readonly type: typeof COMMENT_DELETE_CONTINUE_WITH_REASON;
    readonly commentParent: CommentParent;
    readonly commentId: number;
    readonly deletionReason?: string;
}

export interface CommentDeleteNormalizedResponse extends NormalizedResponse {
    readonly result: { comment: number; };
}
export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export interface CommentDeleteRequestAction extends APIRequestAction {
    readonly type: typeof COMMENT_DELETE_REQUEST;
    readonly commentParent: CommentParent;
    readonly commentId: number;
}
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export interface CommentDeleteSuccessAction extends APISuccessAction<CommentDeleteNormalizedResponse> {
    readonly type: typeof COMMENT_DELETE_SUCCESS;
    readonly commentParent: CommentParent;
    readonly commentId: number;
}
export const COMMENT_DELETE_FAILURE = 'COMMENT_DELETE_FAILURE';
export interface CommentDeleteFailureAction extends APIFailureAction {
    readonly type: typeof COMMENT_DELETE_FAILURE;
    readonly commentParent: CommentParent;
    readonly commentId: number;
}

export const commentDeleteTrigger = (commentParent: CommentParent, commentId: number): CommentDeleteTriggerAction => ({
    type: COMMENT_DELETE_TRIGGER, commentParent, commentId,
});
export const commentDeleteCountinueWithReason = (commentParent: CommentParent, commentId: number, deletionReason?: string): CommentDeleteCountinueWithReasonAction => ({
    type: COMMENT_DELETE_CONTINUE_WITH_REASON, commentParent, commentId, deletionReason,
});
export const commentDeleteApi = {
    request: (commentParent: CommentParent, commentId: number): CommentDeleteRequestAction => ({
        type: COMMENT_DELETE_REQUEST, commentParent, commentId,
    }),
    success: (commentParent: CommentParent, commentId: number, response: CommentDeleteNormalizedResponse): CommentDeleteSuccessAction => ({
        type: COMMENT_DELETE_SUCCESS, commentParent, commentId, response,
    }),
    failure: (commentParent: CommentParent, commentId: number, error: any): CommentDeleteFailureAction => ({
        type: COMMENT_DELETE_FAILURE, commentParent, commentId, error,
    }),
};

////////////////////////////////////////////////////////////////////////////////////
// Comment Create

export const COMMENT_CREATE_TRIGGER = 'COMMENT_CREATE_TRIGGER';
export interface CommentCreateTriggerAction {
    readonly type: typeof COMMENT_CREATE_TRIGGER;
    readonly commentParent: CommentParent;
    readonly commentFd: CommentFormData;
}
export const COMMENT_CREATE_REQUEST = 'COMMENT_CREATE_REQUEST';
export interface CommentCreateRequestAction extends APIRequestAction {
    readonly type: typeof COMMENT_CREATE_REQUEST;
    readonly commentParent: CommentParent;
}
export interface CommentCreateNormalizedResponse extends NormalizedResponse {
    readonly result: { comment: number };
}
export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS';
export interface CommentCreateSuccessAction extends APISuccessAction<CommentCreateNormalizedResponse> {
    readonly type: typeof COMMENT_CREATE_SUCCESS;
    readonly commentParent: CommentParent;
}
export const COMMENT_CREATE_FAILURE = 'COMMENT_CREATE_FAILURE';
export interface CommentCreateFailureAction extends APIFailureAction {
    readonly type: typeof COMMENT_CREATE_FAILURE;
    readonly commentParent: CommentParent;
}

export const commentCreateTrigger =
    (commentParent: CommentParent, commentFd: CommentFormData): CommentCreateTriggerAction => ({
        type: COMMENT_CREATE_TRIGGER, commentParent, commentFd,
    });
export const commentCreateApi = {
    request: (commentParent: CommentParent): CommentCreateRequestAction => ({
        type: COMMENT_CREATE_REQUEST, commentParent,
    }),
    success: (commentParent: CommentParent, response: CommentCreateNormalizedResponse): CommentCreateSuccessAction => ({
        type: COMMENT_CREATE_SUCCESS, commentParent, response,
    }),
    failure: (commentParent: CommentParent, error: any): CommentCreateFailureAction => ({
        type: COMMENT_CREATE_FAILURE, commentParent, error,
    }),
};

////////////////////////////////////////////////////////////////////////////////////
// Comments Reply

export const COMMENT_REPLY = 'COMMENT_REPLY';
export interface CommentReplyAction extends Action {
    readonly type: typeof COMMENT_REPLY;
    readonly commentParent: CommentParent;
    readonly username: string;
    readonly textareaElem: HTMLTextAreaElement;
}

export const commentReply = (commentParent: CommentParent, username: string, textareaElem: HTMLTextAreaElement): CommentReplyAction => ({
    type: COMMENT_REPLY, commentParent, username, textareaElem,
});

///////////////////////////////////////////////////////////////////
// Toggle comment highlight
export const COMMENT_TOGGLE_HIGHLIGHT = 'COMMENT_TOGGLE_HIGHLIGHT';
export interface CommentToggleHighlightAction extends Action {
    readonly type: typeof COMMENT_TOGGLE_HIGHLIGHT;
    readonly commentParent: CommentParent;
    readonly commentId: number;
}

export const commentToggleHighlight = (commentParent: CommentParent, commentId: number): CommentToggleHighlightAction => ({
    type: COMMENT_TOGGLE_HIGHLIGHT, commentParent, commentId,
});

//////////////////////////////////////////////////////////////////////
// Comment Create - action for not authenticated users

export const COMMENT_CREATE_UNAUTHENTICATED_TRIGGER = 'COMMENT_CREATE_UNAUTHENTICATED_TRIGGER';
export interface CommentCreateUnauthenticatedTriggerAction extends Action {
    readonly type: typeof COMMENT_CREATE_UNAUTHENTICATED_TRIGGER;
    readonly commentParent: CommentParent;
}

export const commentCreateUnauthenticatedTrigger = (commentParent: CommentParent): CommentCreateUnauthenticatedTriggerAction => ({
    type: COMMENT_CREATE_UNAUTHENTICATED_TRIGGER, commentParent,
});
