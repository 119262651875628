/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp10: Array<[string, string]> = [
    ["WOMENS", "Pro \u017eeny"],
    ["GIRLS", "Pro holky"],
];
