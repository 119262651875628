import { APIAction, APIFailureAction } from 'mk2/helpers/action';

export const errorMessageFiltered = (actionTypePrefixFilter: string) => (state: string = null, action: APIAction) => {
    if (!action.type.startsWith(actionTypePrefixFilter)) {
        // Loading state of something else changed
        return state;
    }

    return errorMessage(state, action);
};

export const errorMessage = (state: string = null, action: APIAction) => {
    if (action.type.endsWith('SUCCESS') || action.type.endsWith('REQUEST')) {
        return null;
    } else if (action.type.endsWith('FAILURE')) {
        return (action as APIFailureAction).error;
    } else {
        return state;
    }
};
