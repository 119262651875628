import uniq from 'lodash-es/uniq';
import {
    CommentsFetchFailureAction,
    CommentsFetchRequestAction,
    CommentsFetchSuccessAction,
    CommentCreateSuccessAction,
    CommentToggleHighlightAction,
    COMMENT_CREATE_SUCCESS,
    COMMENT_TOGGLE_HIGHLIGHT,
    COMMENTS_FETCH_FAILURE,
    COMMENTS_FETCH_REQUEST,
    COMMENTS_FETCH_SUCCESS,
} from 'mk2/apps/blogs/containers/Comments/Comments.actions';
import { tupdate } from 'mk2/helpers/types';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { CommentParent } from 'mk2/schemas';
import { Reducer } from 'redux';

export const commentsStateKey = (commentParent: CommentParent) =>
    `${commentParent.model}/${commentParent.id}`;

export interface CommentsState {
    commentToBeHighlighted: number;
    ids: number[];
    loadingState: LoadingState;
    errorMessage: string;
    canAdd: boolean;
    hasHiddenText: boolean;
}

export const initialCommentsState: CommentsState = {
    commentToBeHighlighted: null,
    ids: null,
    loadingState: LoadingState.INIT,
    errorMessage: null,
    canAdd: true,
    hasHiddenText: false,
};

export interface PostsCommentsState {
    [key: string]: CommentsState;
}

const initialPostsCommentsState = {};

declare type CommentsReducerAction =
    CommentsFetchRequestAction
    | CommentsFetchSuccessAction
    | CommentsFetchFailureAction
    | CommentCreateSuccessAction
    | CommentToggleHighlightAction;

export const postsCommentsReducer: Reducer<PostsCommentsState> = (state = initialPostsCommentsState, action: CommentsReducerAction) => {
    switch (action.type) {
        case COMMENTS_FETCH_REQUEST:
        case COMMENTS_FETCH_FAILURE: {
            const commentsKey = commentsStateKey(action.commentParent);
            return tupdate(state, {
                [commentsKey]: tupdate(state[commentsKey] || initialCommentsState, {
                    loadingState: loadingState(undefined, action),
                    errorMessage: errorMessage(undefined, action),
                }),
            });
        }

        case COMMENTS_FETCH_SUCCESS: {
            const commentsKey = commentsStateKey(action.commentParent);
            return tupdate(state, {
                [commentsKey]: tupdate(state[commentsKey] || initialCommentsState, {
                    ids: action.response.result.comments,
                    loadingState: loadingState(undefined, action),
                    errorMessage: errorMessage(undefined, action),
                    canAdd: action.response.result.canAdd,
                    hasHiddenText: action.response.result.hasHiddenText,
                }),
            });
        }

        case COMMENT_CREATE_SUCCESS: {
            const commentsKey = commentsStateKey(action.commentParent);
            const oldCommentsState = state[commentsKey] || initialCommentsState;
            return tupdate(state, {
                [commentsKey]: tupdate(oldCommentsState, {
                    // Make ids unique (the id of the new comment might have been appended meanwhile)
                    ids: uniq([...(oldCommentsState.ids ?? []), action.response.result.comment]),
                    hasHiddenText: false,
                }),
            });
        }

        case COMMENT_TOGGLE_HIGHLIGHT: {
            const commentsKey = commentsStateKey(action.commentParent);
            return tupdate(state, {
                [commentsKey]: tupdate(state[commentsKey] || initialCommentsState, {
                    commentToBeHighlighted: action.commentId,
                }),
            });
        }

        default:
            return state;
    }
};
