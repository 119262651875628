import { push } from 'connected-react-router';
import { ACTION_TO_ADD_POST, ACTION_TO_SEND_HEART } from 'mk/autogenerated/translations/sagas.cbc7e522cbba7970cc74784467100ab6'
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { forum_api_read_url } from 'mk/urls/functions';
import { counsellingsNewUrl } from 'mk2/apps/counsellings/urls';
import {
    forumReadFeedSave,
    AddCounsellingQuestionAction,
    ForumReadFeedTriggerAction,
    FORUM_ADD_COUNSELLING_QUESTION,
    FORUM_POST_SEND_HEART,
    FORUM_READ_FEED_TRIGGER,
} from 'mk2/apps/forum/actions';
import { handleXHRPostErrorSaga, normalizeError, XHRAction, XHRPostError } from 'mk2/helpers/api';
import { showWarningToast } from 'mk2/helpers/toasts';
import { url } from 'mk2/helpers/urls';
import { getLogger } from 'mk2/logger';
import { getRequestUser } from 'mk2/reducers';
import { checkIfUserIsLoggedIn } from 'mk2/sagas/auth';
import { UserEntity } from 'mk2/schemas';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

const logger = getLogger('forum/sagas');

interface ApiResponse {
}

function* readSave({ feed, xhr }: ForumReadFeedTriggerAction & XHRAction) {
    const requestUser: UserEntity = yield select(getRequestUser);
    if (!isAuthenticated(requestUser)) {
        return;
    }

    yield put(forumReadFeedSave.request(feed));

    try {
        const response: ApiResponse = yield call(() => xhr.post(forum_api_read_url(feed)));
        yield put(forumReadFeedSave.success(feed));
    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(forumReadFeedSave.failure(feed, normalizeError(error)));
    }
}

function* postSendHeart() { /* this saga runs only in case the user is not logged in. Else the send heart function is provided via url redirection */

    if (!(yield call(checkIfUserIsLoggedIn, ACTION_TO_SEND_HEART))) {
        return;
    }

}

function* postAddCounsellingQuestion({counselling, xhr}: AddCounsellingQuestionAction & XHRAction) {
    if (!(yield call(checkIfUserIsLoggedIn, ACTION_TO_ADD_POST))) {
        return;
    }

    if (counselling.isLocked) {
        yield showWarningToast(
            counselling.lockedMessage,
        );
        return;
    }

    const gotoUrl = url(counsellingsNewUrl, {topicSlug: counselling.topic.slug});
    yield put(push(gotoUrl));

}

export default function* root() {
    yield all([
        takeLatest(FORUM_READ_FEED_TRIGGER, readSave),
        takeEvery(FORUM_POST_SEND_HEART, postSendHeart),
        takeEvery(FORUM_ADD_COUNSELLING_QUESTION, postAddCounsellingQuestion),
    ]);
}
