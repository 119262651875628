import isEqual from 'lodash-es/isEqual';
import { APISuccessAction } from 'mk2/helpers/action';
import { Entity, NormalizedEntity } from 'mk2/schemas';
import { Action } from 'redux';

export interface EntitiesByID<T extends Entity = Entity> {
    [id: string]: NormalizedEntity<T>;
}

export interface EntitiesState {
    [entity: string]: EntitiesByID<Entity>;
}

interface EntitiesToRemove {
    [key: string]: Array<Entity['id']>;
}

export const ENTITIES_REMOVE = 'ENTITIES_REMOVE';

export interface EntitiesRemoveAction extends Action {
    readonly type: typeof ENTITIES_REMOVE;
    readonly entitiesToRemove: EntitiesToRemove;
}

export const entitiesRemove = (entitiesToRemove: EntitiesToRemove): EntitiesRemoveAction => ({
    type: ENTITIES_REMOVE,
    entitiesToRemove,
});

export function entitiesReducer(state: EntitiesState = {}, action: APISuccessAction | EntitiesRemoveAction | Action) {
    let newState = state;

    switch (action.type) {
        case ENTITIES_REMOVE: {
            const entitiesToRemove: EntitiesToRemove = (action as EntitiesRemoveAction).entitiesToRemove;
            const keys = Object.keys(entitiesToRemove);

            let keyStateChanged = false;
            const newKeyState = {
                ...state,
            };

            keys.forEach((key) => {
                if (!state[key]) {
                    // Wrong key - ignore
                    return;
                }

                // Convert all to strings
                const toRemove = entitiesToRemove[key].map((id) => id.toString());
                const ids = Object.keys(state[key]);
                let idStateChanged = false;

                const newIdState = ids.reduce((acc, cur) => {
                    if (!toRemove.includes(cur)) {
                        acc[cur] = state[key][cur];
                    } else {
                        idStateChanged = true;
                    }
                    return acc;
                }, {});

                if (idStateChanged) {
                    newKeyState[key] = newIdState;
                    keyStateChanged = true;
                }
            });

            if (keyStateChanged) {
                newState = newKeyState;
            }
            break;
        }
        default: {
            if ('response' in action && action.response && action.response.entities) {
                // We have only 2 levels
                const keys = Object.keys(action.response.entities);
                if (keys.length) {
                    const newKeyState = {
                        ...state,
                    };

                    let keyStateChanged = false;
                    keys.forEach((key) => {
                        const ids = Object.keys(action.response.entities[key]);
                        if (ids.length) {
                            const newIdState = {
                                ...newKeyState[key],
                            };

                            let idStateChanged = false;
                            ids.forEach((id) => {
                                if (!newIdState[id] || !isEqual(newIdState[id], action.response.entities[key][id])) {
                                    newIdState[id] = action.response.entities[key][id];
                                    idStateChanged = true;
                                }
                            });

                            if (idStateChanged) {
                                newKeyState[key] = newIdState;
                                keyStateChanged = true;
                            }
                        } else if (!(key in newKeyState)) {
                            newKeyState[key] = action.response.entities[key];
                            keyStateChanged = true;
                        }
                    });

                    if (keyStateChanged) {
                        newState = newKeyState;
                    }
                }
            }
            break;
        }
    }

    return newState;
}
