// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const STROLLER_FEATURE_ADAPTERS = "Adapt\u00e9ry na autoseda\u010dku";
export const STROLLER_FEATURE_ADAPTERS_CARRYCOT = "Adapt\u00e9ry na hlubokou vani\u010dku";
export const STROLLER_FEATURE_ADJUSTABLE_HANDLE = "Polohovac\u00ed rukoje\u0165";
export const STROLLER_FEATURE_ADJUSTABLE_SUSPENSION = "Nastaviteln\u00e9 odpru\u017een\u00ed";
export const STROLLER_FEATURE_ALL_WHEELS_ARE_DOUBLE = "V\u0161echna kola jsou zdvojen\u00e1";
export const STROLLER_FEATURE_AUTOLOCK = "Auto-lock p\u0159i skl\u00e1d\u00e1n\u00ed - konstrukce se automaticky uzamkne proti rozlo\u017een\u00ed";
export const STROLLER_FEATURE_AVAILABILITY = "Dostupnost";
export const STROLLER_FEATURE_BABY_BLANKET = "D\u011btsk\u00e1 deka";
export const STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_EXTENDED = "Prodlou\u017eiteln\u00e1 z\u00e1dov\u00e1 op\u011brka";
export const STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_IN_LYING_POSITION = "Z\u00e1dov\u00e1 op\u011brka jde do lehu";
export const STROLLER_FEATURE_BACK_SUPPORT_CAN_BE_POSITIONED = "Polohovateln\u00e1 z\u00e1dov\u00e1 op\u011brka";
export const STROLLER_FEATURE_BACK_SUPPORT_LENGTH_IN_CM = "D\u00e9lka z\u00e1dov\u00e9 op\u011brky v cm";
export const STROLLER_FEATURE_BACK_SUPPORT_POSITIONING_TYPE = "Typ polohov\u00e1n\u00ed z\u00e1dov\u00e9 op\u011brky";
export const STROLLER_FEATURE_BACK_SUPPORT_POSITIONS = "Po\u010det poloh z\u00e1dov\u00e9 op\u011brky";
export const STROLLER_FEATURE_BASE_COLOR = "Barva konstrukce";
export const STROLLER_FEATURE_BASE_FOR_THE_CAR_SEAT = "Z\u00e1kladna k autoseda\u010dce";
export const STROLLER_FEATURE_BIG_SHOPPING_BASKET = "Velk\u00fd n\u00e1kupn\u00ed ko\u0161\u00edk";
export const STROLLER_FEATURE_BOARD = "Skateboard";
export const STROLLER_FEATURE_BUCKET_SEAT = "Kor\u00fdtkov\u00e9 sezen\u00ed";
export const STROLLER_FEATURE_BUCKET_SECOND_SEAT = "Kor\u00fdtkov\u00e9 sezen\u00ed druh\u00e9ho sedadla";
export const STROLLER_FEATURE_BUGGY_LOCK = "Z\u00e1mek na ko\u010d\u00e1rek";
export const STROLLER_FEATURE_BUMPER_BAR = "Bezpe\u010dnostn\u00ed madlo";
export const STROLLER_FEATURE_BUMPER_BAR_CAN_BE_BOUGHT_SEPARATELY = "Mad\u00e9lko je mo\u017en\u00e9 ke ko\u010d\u00e1rku dokoupit zvl\u00e1\u0161\u0165";
export const STROLLER_FEATURE_BUMPER_BAR_CAN_BE_POSITIONED = "D\u011btsk\u00e9 madlo lze polohovat";
export const STROLLER_FEATURE_BUMPER_BAR_CAN_BE_REMOVED = "D\u011btsk\u00e9 madlo lze odejmout";
export const STROLLER_FEATURE_BUMPER_BAR_EQUIPPED_WITH_A_REMOVABLE_COVER = "Madlo m\u00e1 sn\u00edmateln\u00fd potah";
export const STROLLER_FEATURE_BUMPER_BAR_TYPE = "Typ d\u011btsk\u00e9ho madla";
export const STROLLER_FEATURE_CAN_BE_CONVERTED_TO_A_DOUBLE = "Mo\u017enost p\u0159ipojit sourozeneck\u00e9 sedadlo";
export const STROLLER_FEATURE_CAN_BE_CONVERTED_TO_A_SINGLE = "Mo\u017enost p\u0159ed\u011blat dvoum\u00edstn\u00ed ko\u010d\u00e1rek na jednom\u00edstn\u00ed";
export const STROLLER_FEATURE_CAN_BE_PULLED_BEHIND = "Po slo\u017een\u00ed je mo\u017en\u00e9 t\u00e1hnout ko\u010d\u00e1rek za sebou jako zavazadlo";
export const STROLLER_FEATURE_CARRYCOT = "Hlubok\u00e1 korba";
export const STROLLER_FEATURE_CARRYCOT_CAN_BE_ATTACHED = "Mo\u017enost na ko\u010d\u00e1rek nasadit hlubokou vani\u010dku";
export const STROLLER_FEATURE_CARRYCOT_DIMS_IN_CM = "Vnit\u0159n\u00ed rozm\u011br hlubok\u00e9 korby v cm";
export const STROLLER_FEATURE_CARRYCOT_HAS_HANDLE = "Hlubok\u00e1 korba m\u00e1 \u00fachyt na p\u0159en\u00e1\u0161en\u00ed";
export const STROLLER_FEATURE_CARRYCOT_HAS_VENTILATION = "Vani\u010dka m\u00e1 v\u011btrac\u00ed otvory v z\u00e1kladn\u011b";
export const STROLLER_FEATURE_CARRYCOT_USABLE_IN_CAR = "Autovani\u010dka - mo\u017enost pou\u017eit\u00ed hlubok\u00e9 korby m\u00edsto autoseda\u010dky kat. 0+\n";
export const STROLLER_FEATURE_CARRYCOT_WITH_CRADLE = "Vani\u010dka m\u00e1 funkci kol\u00e9bky";
export const STROLLER_FEATURE_CARRYCOT_WITH_PANORAMIC_WINDOW_ON_SIDE = "Vani\u010dka m\u00e1 panoramatick\u00e9 ok\u00e9nko (na boku)";
export const STROLLER_FEATURE_CARRYCOT_WITH_VENTILATION_WINDOW_ON_ROOF = "Vani\u010dka m\u00e1 ventila\u010dn\u00ed ok\u00e9nko (ve st\u0159\u00ed\u0161ce)";
export const STROLLER_FEATURE_CAR_SEAT = "Autoseda\u010dka (vaj\u00ed\u010dko)";
export const STROLLER_FEATURE_CAR_SEAT_CAN_BE_ATTACHED = "Mo\u017enost p\u0159ipojen\u00ed autoseda\u010dky";
export const STROLLER_FEATURE_CAR_SEAT_COLOR = "Barva autoseda\u010dky (vaj\u00ed\u010dka)";
export const STROLLER_FEATURE_CAR_SEAT_COMPATIBILITY_MOST_BRANDS = "Mo\u017enost p\u0159ipojen\u00ed autoseda\u010dek zna\u010dky Cybex, Maxi-Cosi, GB, Recaro, BeSafe, Kiddy, Joie, Avionaut, Nuna Pipa, Safety 1st (stejn\u00fd adapt\u00e9r)";
export const STROLLER_FEATURE_CAR_SEAT_COMPATIBILITY_ROEMER = "Mo\u017enost p\u0159ipojen\u00ed autoseda\u010dek zna\u010dky R\u00f6mer";
export const STROLLER_FEATURE_CAR_SEAT_HAS_TO_BE_BOUGHT = "Autoseda\u010dku je pot\u0159ebn\u00e9 dokoupit zvl\u00e1\u0161\u0165";
export const STROLLER_FEATURE_CENTRAL_BRAKE_PRESENT = "P\u0159\u00edtomnost centr\u00e1ln\u00ed brzdy";
export const STROLLER_FEATURE_CENTRAL_BRAKE_SYSTEM = "Typ centr\u00e1ln\u00ed brzdy";
export const STROLLER_FEATURE_CHECKED_BAGGAGE = "Ko\u010d\u00e1rek lze slo\u017eit na velikost p\u0159\u00edru\u010dn\u00edho zavazadla do letadla";
export const STROLLER_FEATURE_CLEANING_THE_STROLLER_FABRICS = "\u010ci\u0161t\u011bn\u00ed potahu";
export const STROLLER_FEATURE_CLOSABLE_PEEK_A_BOO_WINDOW = "Uzav\u00edrateln\u00e9 n\u00e1hledov\u00e9 ok\u00e9nko";
export const STROLLER_FEATURE_COCOON = "Vlo\u017en\u00e1 ta\u0161ka";
export const STROLLER_FEATURE_COLOR = "Barva";
export const STROLLER_FEATURE_CONSTRUCTION = "Konstrukce";
export const STROLLER_FEATURE_CONSTRUCTION_TYPE = "Mono/Duo/Twin";
export const STROLLER_FEATURE_CUPHOLDER = "Dr\u017e\u00e1k n\u00e1poj\u016f";
export const STROLLER_FEATURE_CUPHOLDER_POSSIBILITY = "Mo\u017enost p\u0159ipojen\u00ed dr\u017e\u00e1ku na n\u00e1poje";
export const STROLLER_FEATURE_DISCONTINUED_YEAR = "Rok ukon\u010den\u00ed v\u00fdroby";
export const STROLLER_FEATURE_DUO_CARRYCOT = "Vani\u010dka (sourozeneck\u00e1)";
export const STROLLER_FEATURE_DUO_SEAT = "Seda\u010dka (sourozeneck\u00e1)";
export const STROLLER_FEATURE_EDITION = "Limitovan\u00e1 edice";
export const STROLLER_FEATURE_EXTENDABLE_HOOD = "Prodlou\u017eiteln\u00e1 st\u0159\u00ed\u0161ka";
export const STROLLER_FEATURE_EXTENSION_SET = "Roz\u0161i\u0159uj\u00edc\u00ed set (duo)";
export const STROLLER_FEATURE_FOLDABLE_CARRYCOT = "Skl\u00e1dac\u00ed vani\u010dka";
export const STROLLER_FEATURE_FOLDABLE_HANDRAIL = "P\u0159eklopiteln\u00e9 madlo";
export const STROLLER_FEATURE_FOLDED_DIMENSIONS_IN_CM = "Rozm\u011bry ve slo\u017een\u00e9m stavu (v\u00d7\u0161\u00d7d v cm)";
export const STROLLER_FEATURE_FOLDING_SYSTEM = "Typ skl\u00e1d\u00e1n\u00ed";
export const STROLLER_FEATURE_FOLDS_BY_ITSELF = "S\u00e1m se poskl\u00e1d\u00e1/rozlo\u017e\u00ed";
export const STROLLER_FEATURE_FOLDS_WITH_BOTH_SEATS_ATTACHED = "Poskl\u00e1d\u00e1n\u00ed je mo\u017en\u00e9 s ob\u011bma nasazen\u00fdmi sedadly";
export const STROLLER_FEATURE_FOLDS_WITH_SEAT_OUTWARDS = "Skl\u00e1d\u00e1 se sedadlem sm\u011brem ven (mo\u017en\u00e9 za\u0161pin\u011bn\u00ed)";
export const STROLLER_FEATURE_FOLDS_WITH_THE_SEAT_ATTACHED = "Skl\u00e1d\u00e1n\u00ed je mo\u017en\u00e9 i se sedadlem";
export const STROLLER_FEATURE_FOLDS_WITH_THE_SEAT_IN_REVERSE = "Skl\u00e1d\u00e1n\u00ed je mo\u017en\u00e9 i se sedadlem v protism\u011bru";
export const STROLLER_FEATURE_FOLLOW_THE_SUN_HOOD = "St\u0159\u00ed\u0161ka pou\u017eiteln\u00e1 jako slune\u010dn\u00ed \u0161t\u00edt (st\u0159\u00ed\u0161ka n\u00e1sleduje slunce - d\u00e1 se p\u0159ekl\u00e1p\u011bt dop\u0159edu)";
export const STROLLER_FEATURE_FOOTCOVER_COLOR = "Farba n\u00e1no\u017en\u00edku";
export const STROLLER_FEATURE_FOOTMUFF = "Fusak";
export const STROLLER_FEATURE_FOOTSTOOL = "Podno\u017eka";
export const STROLLER_FEATURE_FRONT_WHEELS_DIAMETER_IN_CM = "Pr\u016fm\u011br p\u0159edn\u00edch kol (v cm)";
export const STROLLER_FEATURE_FRONT_WHEEL_TYPE = "Typ kole\u010dek vp\u0159edu ";
export const STROLLER_FEATURE_FULLY_FLEDGED_SECOND_SEAT = "Druh\u00e9 sedadlo je plnohodnotn\u00e9 (stejn\u011b jako prvn\u00ed)";
export const STROLLER_FEATURE_HAND_BRAKE = "Ru\u010dn\u00ed brzda";
export const STROLLER_FEATURE_HANDLE_COLOR = "Barva rukojeti";
export const STROLLER_FEATURE_HANDLE_HEIGHT_IN_CM = "V\u00fd\u0161ka rukojeti v cm";
export const STROLLER_FEATURE_HANDLE_MATERIAL = "Materi\u00e1l rukojeti/rukojet\u00ed";
export const STROLLER_FEATURE_HARNESS_PADDING = "Polstrov\u00e1n\u00ed bezpe\u010dnostn\u00edch p\u00e1s\u016f";
export const STROLLER_FEATURE_HARNESS_TYPE = "Typ bezpe\u010dnostn\u00edch p\u00e1s\u016f pro d\u00edt\u011b";
export const STROLLER_FEATURE_HAS_CARRY_HANDLE = "P\u0159\u00edtomnost \u00fachytu/popruhu na p\u0159en\u00e1\u0161en\u00ed";
export const STROLLER_FEATURE_HEIGHT_ADAPTER = "V\u00fd\u0161kov\u00fd adapt\u00e9r";
export const STROLLER_FEATURE_HEIGHT_ADJUSTABLE_HARNESS = "Bezpe\u010dnostn\u00ed p\u00e1sy se daj\u00ed v\u00fd\u0161kov\u011b nastavit";
export const STROLLER_FEATURE_HEIGHT_ADJUSTABLE_HOOD = "V\u00fd\u0161kov\u011b nastaviteln\u00e1 st\u0159\u00ed\u0161ka";
export const STROLLER_FEATURE_HEIGHT_ADJUSTABLE_SEAT = "V\u00fd\u0161kov\u011b nastaviteln\u00e9 sedadlo";
export const STROLLER_FEATURE_HIGHER_POSITIONED_CARRYCOT = "Vysoce um\u00edst\u011bna vani\u010dka";
export const STROLLER_FEATURE_HIGHER_SEAT_POSITION = "V\u00fd\u0161 um\u00edst\u011bn\u00e9 sportovn\u00ed sedadlo";
export const STROLLER_FEATURE_HOOD_EQUIPPED_WITH_A_POCKET = "Kapsa na drobnosti ve st\u0159\u00ed\u0161ce";
export const STROLLER_FEATURE_HOOD_EQUIPPED_WITH_UV_PROTECTION = "UV faktor ve st\u0159\u00ed\u0161ce";
export const STROLLER_FEATURE_HOOD_EQUIPPED_WITH_VENTILATION_PANEL = "V\u011btrac\u00ed otvor / s\u00ed\u0165ka ve st\u0159\u00ed\u0161ce";
export const STROLLER_FEATURE_HOOD_FEATURES_A_SUN_VISOR = "P\u0159\u00edtomnost sklopn\u00e9ho k\u0161iltu na st\u0159\u00ed\u0161ce";
export const STROLLER_FEATURE_INCLINABLE_CARRYCOT = "Polohov\u00e1n\u00ed v hlubok\u00e9 korb\u011b";
export const STROLLER_FEATURE_LED_LIGHT = "LED sv\u011btlo";
export const STROLLER_FEATURE_LED_LIGHTING = "LED osv\u011btlen\u00ed";
export const STROLLER_FEATURE_LEG_REST_CAN_BE_EXTENDED = "Prodlou\u017eiteln\u00e1 op\u011brka no\u017ei\u010dek";
export const STROLLER_FEATURE_LEG_REST_CAN_BE_POSITIONED = "Polohovateln\u00e1 op\u011brka no\u017ei\u010dek";
export const STROLLER_FEATURE_LEG_REST_POSITIONS = "Po\u010det poloh op\u011brky no\u017ei\u010dek";
export const STROLLER_FEATURE_LINER = "Vlo\u017eka";
export const STROLLER_FEATURE_LINER_COLOR = "Barva vlo\u017eky";
export const STROLLER_FEATURE_MANUAL_BRAKE_PRESENT = "P\u0159\u00edtomnost ru\u010dn\u00ed (zpomalovac\u00ed) brzdy";
export const STROLLER_FEATURE_MATTRESS = "Matrace";
export const STROLLER_FEATURE_MAXIMUM_LOAD_PER_1ST_CHILD_IN_KG = "Maxim\u00e1ln\u00ed zat\u00ed\u017een\u00ed sedadla (prvn\u00ed d\u00edt\u011b - v kg)";
export const STROLLER_FEATURE_MAXIMUM_LOAD_PER_2ND_CHILD_IN_KG = "Maxim\u00e1ln\u00ed zat\u00ed\u017een\u00ed sedadla (druh\u00e9 d\u00edt\u011b - v kg)";
export const STROLLER_FEATURE_MAXIMUM_LOAD_PER_CHILD_IN_KG = "Maxim\u00e1ln\u00ed zat\u00ed\u017een\u00ed sedadla (jedno d\u00edt\u011b - v kg)";
export const STROLLER_FEATURE_MEMORY_BUTTON = "M\u00e1 memory button (pam\u011b\u0165ov\u00e9 tla\u010d\u00edtko), sta\u010d\u00ed p\u0159i ot\u00e1\u010den\u00ed/odn\u00edm\u00e1n\u00ed sla\u010dit (ne dr\u017eet)";
export const STROLLER_FEATURE_MOSQUITO_NET = "S\u00ed\u0165 proti hmyzu";
export const STROLLER_FEATURE_MOTORIZED = "Motorizovan\u00fd";
export const STROLLER_FEATURE_NUMBER_OF_DOUBLE_WHEELS = "Po\u010det zdvojen\u00fdch kol";
export const STROLLER_FEATURE_NUMBER_OF_KIDS = "Po\u010det d\u011bt\u00ed";
export const STROLLER_FEATURE_NUMBER_OF_WHEELS = "Po\u010det kol";
export const STROLLER_FEATURE_ONE_HAND_FOLDING = "Mo\u017enost slo\u017eit jednou rukou";
export const STROLLER_FEATURE_ONE_HAND_POSITIONING = "Polohov\u00e1n\u00ed jednou rukou";
export const STROLLER_FEATURE_ORGANIZER = "Organiz\u00e9r";
export const STROLLER_FEATURE_ORIGIN_COUNTRIES = "P\u016fvod zna\u010dky";
export const STROLLER_FEATURE_PARASOL = "Slune\u010dn\u00edk";
export const STROLLER_FEATURE_PARENT_TRAY_ORGANIZER = "Pult/organiz\u00e9r pro rodi\u010de u rukojeti";
export const STROLLER_FEATURE_PEEK_A_BOO_WINDOW_IN_THE_HOOD = "P\u0159\u00edtomnost n\u00e1hledov\u00e9ho ok\u00e9nka ve st\u0159\u00ed\u0161ce";
export const STROLLER_FEATURE_PEEK_A_BOO_WINDOW_WITH_SILENT_CLOSURE = "Magnetick\u00e9 / nehlu\u010dn\u00e9 otev\u00edr\u00e1n\u00ed n\u00e1hledov\u00e9ho ok\u00e9nka (bez such\u00fdch zip\u016f \u010di zipu)";
export const STROLLER_FEATURE_PHONE_HOLDER = "Dr\u017e\u00e1k na telefon";
export const STROLLER_FEATURE_PLATFORM_MATERIAL = "Materi\u00e1l podvozku";
export const STROLLER_FEATURE_POSSIBILITY_OF_TERRAIN_WHEELS = "Mo\u017enost dokoupen\u00ed ter\u00e9nn\u00edch kol s pneumatikami";
export const STROLLER_FEATURE_PRICE = "Cena";
export const STROLLER_FEATURE_RAINCOAT = "Pl\u00e1\u0161t\u011bnka";
export const STROLLER_FEATURE_REAR_WHEELS_DIAMETER_IN_CM = "Pr\u016fm\u011br kole\u010dek vzadu (v cm)";
export const STROLLER_FEATURE_REAR_WHEEL_TYPE = "Typ kole\u010dek vzadu";
export const STROLLER_FEATURE_RECOMMENDED_AGE = "Doporu\u010den\u00fd v\u011bk d\u00edt\u011bte (v m\u011bs\u00edc\u00edch)";
export const STROLLER_FEATURE_REMOVABLE_HOOD = "Odn\u00edmateln\u00e1 st\u0159\u00ed\u0161ka";
export const STROLLER_FEATURE_RETROREFLECTOR = "Reflexn\u00ed prvky";
export const STROLLER_FEATURE_ROOF = "St\u0159\u00ed\u0161ka";
export const STROLLER_FEATURE_ROOF_PRESENT = "P\u0159\u00edtomnost st\u0159\u00ed\u0161ky";
export const STROLLER_FEATURE_ROTATING_FIXATION_WHEELS = "Ot\u00e1\u010den\u00ed p\u0159edn\u00edch kole\u010dek";
export const STROLLER_FEATURE_ROTATING_SEAT = "Oto\u010dn\u00e9 sedadlo";
export const STROLLER_FEATURE_ROTATING_SECOND_SEAT = "Druh\u00e9 sedadlo je oto\u010dn\u00e9";
export const STROLLER_FEATURE_SEAT_DIMS_IN_CM = "\u0160\u00ed\u0159ka x hloubka sedac\u00ed plochy v cm";
export const STROLLER_FEATURE_SEAT_FABRICS_CAN_BE_TAKEN_OFF = "Potah lze sundat";
export const STROLLER_FEATURE_SEAT_SURFACE_TOTAL_LENGTH_IN_CM = "Celkov\u00e1 d\u00e9lka lo\u017en\u00e9 plochy sportovn\u00edho sedadla v cm";
export const STROLLER_FEATURE_SEAT_UNIT_STROLLER_BODY_EQUIPPED_WITH_VENTILATION_SYSTEM = "V\u011btr\u00e1n\u00ed na konstrukci / sedadle (nap\u0159. integrovan\u00e9 do sedac\u00ed \u010d\u00e1sti nebo p\u0159i sklopen\u00e9 poloze z\u00e1dov\u00e9 op\u011brky)";
export const STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_CAN_BE_POSITIONED = "Polohovateln\u00e1 z\u00e1dov\u00e1 op\u011brka - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_LENGTH_IN_CM = "D\u00e9lka z\u00e1dov\u00e9 op\u011brky - druh\u00e9 sedadlo (v cm)";
export const STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONING_TYPE = "Typ polohov\u00e1n\u00ed z\u00e1dov\u00e9 op\u011brky - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_BACK_SUPPORT_POSITIONS = "Po\u010det poloh z\u00e1dov\u00e9 op\u011brky - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_BUMPER_BAR = "P\u0159\u00edtomnost d\u011btsk\u00e9ho madla - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_DIMS_IN_CM = "\u0160\u00ed\u0159ka x hloubka sedac\u00ed plochy druh\u00e9ho sedadla v cm";
export const STROLLER_FEATURE_SECOND_SEAT_LEG_REST = "Op\u011brka nohou u druh\u00e9ho sedadla";
export const STROLLER_FEATURE_SECOND_SEAT_ONE_HAND_POSITIONING = "Polohov\u00e1n\u00ed jednou rukou - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_RECOMMENDED_AGE = "Doporu\u010den\u00fd v\u011bk d\u00edt\u011bte - druh\u00e9 sedadlo (v m\u011bs\u00edc\u00edch)";
export const STROLLER_FEATURE_SECOND_SEAT_ROOF_PRESENT = "P\u0159\u00edtomnost st\u0159\u00ed\u0161ky - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SECOND_SEAT_SURFACE_TOTAL_LENGTH_IN_CM = "Celkov\u00e1 d\u00e9lka lo\u017en\u00e9 plochy sportovn\u00edho sedadla v cm - druh\u00e9 sedadlo";
export const STROLLER_FEATURE_SETS = "Set";
export const STROLLER_FEATURE_SHOPPING_BASKET = "N\u00e1kupn\u00ed ko\u0161\u00edk";
export const STROLLER_FEATURE_SHOPPING_BASKET_CAPACITY_IN_KG = "Nosnost n\u00e1kupn\u00edho ko\u0161\u00edku v kg";
export const STROLLER_FEATURE_SHOPPING_BASKET_CLOSABLE = "Uzav\u00edrateln\u00fd n\u00e1kupn\u00ed ko\u0161\u00edk";
export const STROLLER_FEATURE_SIBLINGS_TYPE = "Typ sourozeneck\u00e9ho";
export const STROLLER_FEATURE_SNACK_TRAY = "Podnos na sva\u010dinu";
export const STROLLER_FEATURE_SPORTWHEELS = "Sportovn\u00ed kole\u010dka (nafukovac\u00ed)";
export const STROLLER_FEATURE_STANDING_FOLD = "Po slo\u017een\u00ed samostatn\u011b stoj\u00ed";
export const STROLLER_FEATURE_STANDUP_SUPPORT = "Stojan";
export const STROLLER_FEATURE_STEERING_WHEEL = "Volant";
export const STROLLER_FEATURE_STROLLER_BABY_BAG = "Sanit\u00e1rn\u00ed ta\u0161ka";
export const STROLLER_FEATURE_STROLLER_BAG = "Ta\u0161ka/batoh";
export const STROLLER_FEATURE_STROLLER_BODY_EQUIPPED_WITH_A_POCKET = "Kapsa na drobnosti na konstrukci";
export const STROLLER_FEATURE_STROLLER_FOOT_BAG = "N\u00e1no\u017en\u00edk";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT = "V\u00fd\u0161ka rodi\u010d\u016f";
export const STROLLER_FEATURE_STROLLER_SEAT = "\u0160portov\u00e9 sedadlo";
export const STROLLER_FEATURE_SUN_CANOPY_COLOR = "Barva st\u0159\u00ed\u0161ky";
export const STROLLER_FEATURE_SUN_VISOR = "Sv\u011btl\u00edk";
export const STROLLER_FEATURE_SUPPLIED_CAPE = "Dod\u00e1van\u00fd s pl\u00e1\u0161t\u011bnkou";
export const STROLLER_FEATURE_SUPPLIED_CHANGING_BAG = "Dod\u00e1van\u00fd s p\u0159ebalovac\u00ed ta\u0161kou";
export const STROLLER_FEATURE_SUPPLIED_FOOTMUFF = "Dod\u00e1van\u00fd s n\u00e1no\u017en\u00edkem";
export const STROLLER_FEATURE_SUPPLIED_MOSQUITO_NET = "Dod\u00e1van\u00fd s moskyti\u00e9rou";
export const STROLLER_FEATURE_SUPPLIED_PAD = "Dod\u00e1van\u00fd s podlo\u017ekou ke ko\u010d\u00e1rku";
export const STROLLER_FEATURE_SUPPLIED_TRANSPORT_BAG = "Dod\u00e1van\u00fd s ta\u0161kou na p\u0159enos";
export const STROLLER_FEATURE_SUSPENDED_WHEELS = "Odpru\u017een\u00e1 kole\u010dka";
export const STROLLER_FEATURE_SUSPENSION = "Odpru\u017een\u00ed";
export const STROLLER_FEATURE_SUSPENSION_TYPE = "Typ odpru\u017een\u00ed";
export const STROLLER_FEATURE_SWING_AWAY_BUMPER_BAR = "D\u011btsk\u00e9 madlo se d\u00e1 ot\u00e1\u010den\u00edm odklopit";
export const STROLLER_FEATURE_SWINGING = "Mo\u017enost houp\u00e1n\u00ed";
export const STROLLER_FEATURE_TOY = "Hra\u010dka";
export const STROLLER_FEATURE_TWIN_CARRYCOT = "Vani\u010dka (dvoj\u010de)";
export const STROLLER_FEATURE_TWO_CAR_SEATS_CAN_BE_ATTACHED = "Mo\u017enost p\u0159ipojen\u00ed dvou autoseda\u010dek";
export const STROLLER_FEATURE_TWO_WHEEL_MODE = "Mo\u017enost p\u0159ed\u011blat ko\u010d\u00e1rek na dvoukolov\u00fd (sj\u00ed\u017ed\u011bn\u00ed schod\u016f, tah\u00e1n\u00ed po p\u00edsku)";
export const STROLLER_FEATURE_TYPE = "Typ";
export const STROLLER_FEATURE_TYPE_2IN1_SEAT = "Typ seda\u010dky 2v1 (hlubok\u00e1 vani\u010dka se rozlo\u017e\u00ed na sportovn\u00ed seda\u010dku)";
export const STROLLER_FEATURE_TYPE_2KOMBI = "Typ dvojkombinace";
export const STROLLER_FEATURE_TYPE_OF_HANDLE_ADJUSTMENT = "Typ polohov\u00e1n\u00ed rukojeti";
export const STROLLER_FEATURE_UNFOLDED_DIMENSIONS_IN_CM = "Rozm\u011bry v rozlo\u017een\u00e9m stavu (v\u00d7\u0161\u00d7d cm)";
export const STROLLER_FEATURE_VERTICAL_SEAT  = "Z\u00e1dov\u00e1 op\u011brka jde polohovat do kolm\u00e9ho sedu (90\u00b0)";
export const STROLLER_FEATURE_WATERPROOF_HOOD = "Nepromokav\u00fd materi\u00e1l st\u0159\u00ed\u0161ky";
export const STROLLER_FEATURE_WEIGHT_IN_KG = "Hmotnost (v kg)";
export const STROLLER_FEATURE_WHEEL_COLOR = "Barva kole\u010dek";
export const STROLLER_FEATURE_WHEEL_DESCRIPTION_WEB = "Kola - doplnen\u00ed";
export const STROLLER_FEATURE_WHEEL_MATERIAL = "Materi\u00e1l kol";
export const STROLLER_FEATURE_WHEELS_CAN_BE_REMOVED = "Kole\u010dka lze sundat";
export const STROLLER_FEATURE_WHEEL_SIZE = "Velikost kol";
export const STROLLER_FEATURE_WRIST_SAFETY_STRAP = "Bezpe\u010dnostn\u00ed popruh na ruku k zastaven\u00ed ko\u010d\u00e1rku";
export const STROLLER_FEATURE_YOUNG_REDUCTION = "Redukce pro mal\u00e9 d\u00edt\u011b";
