import { loginUrl } from 'mk2/apps/users/urls';
import { InvalidCSRFToast } from 'mk2/components/InvalidCSRF';
import { DjangoFormErrors } from 'mk2/helpers/form';
import { showErrorToast, showServerErrorToast } from 'mk2/helpers/toasts';
import { url } from 'mk2/helpers/urls';
import { Logger } from 'mk2/logger';
import { getRoutingLocation } from 'mk2/selectors';
import { redirectInPWASaga } from 'mk2/services/browserHistory';
import { buffers, eventChannel, END } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { Response } from 'superagent';

export interface NormalizedResponse {
    readonly result: any;
    readonly entities: {
        [entity: string]: any;
    };
    readonly release?: string;
}

export interface XHRClient {
    get(url: string, data?: any, repeatable?: number): Promise<Response>;
    post(url: string, data?: any, repeatable?: number): Promise<Response>;
    put(url: string, data?: any, repeatable?: number): Promise<Response>;
    // DEL methoda je vykomentovana, aby sme ju nepouzivali,
    // pretoze niektore starsie browsery pri nej posielaju v body zly (poskodeny) JSON
    // del(url: string, data?: any, repeatable?: number): Promise<Response>;
    refreshApiAuth();
}

export interface XHRAction {
    xhr: XHRClient;
}

export interface XHRError<ResponseType> extends Error {
    status: number;
    errorCode?: string;
    response?: {  // type of response returned when http code != 200
        // headers: {[name: string]: string},
        body: ResponseType;
    };
}

export type XHRPostError = XHRError<{
    error: string;
}>;

interface XHRGETRedirect {
    location: string;
    status?: 301 | 302; // http status
}

export type XHRGetError = XHRError<
    {} | XHRGETRedirect
>;

export type XHRFormError = XHRError<{
    errors: DjangoFormErrors;
}>;

export function normalizeError(error) {
    if (error.status && error.status === 400) {
        const xhrError = error as XHRPostError;
        return xhrError.response.body.error;
    }

    if (error.status === 404) {
        return 'NOT FOUND';
    } else if (error.status === 410) {
        return 'GONE';
    } else if (error.status === 500) {
        return 'INTERNAL SERVER ERROR';
    }

    // Superagent error
    if (error.response && error.response.toError) {
        return error.response.toError().message;
    }

    if (error instanceof Error) {
        return error.message ? error.message : error;
    }

    return error;
}

export function* handleXHRGetErrorSaga(error: XHRGetError, logger: Logger) {
    if (error.errorCode === 'CSRF_CHANGED_ERROR') {
        yield showErrorToast(InvalidCSRFToast());

    } else if (error.status === 403 || error.status === 401) {
        // not authorize when doing GET, redirect to login page

        const location = yield select(getRoutingLocation);
        const currentUrl = `${location.pathname}${location.search}`;
        yield redirectInPWASaga(url(loginUrl, {},  {next: currentUrl}));
    } else if (error.status === 404 && error.response && (error.response.body as XHRGETRedirect).location) {
        // if backend returns http status 404 and response contains location: 'someUrl',
        // then we are asked to redirect
        const redirResponse: XHRGETRedirect = error.response.body as XHRGETRedirect;
        yield redirectInPWASaga(redirResponse.location, redirResponse.status);
    } else {
        logger.error(error);
    }
}

export function* handleXHRPostErrorSaga(error: XHRPostError, logger: Logger) {
    if (error.errorCode === 'CSRF_CHANGED_ERROR') {
        yield showErrorToast(InvalidCSRFToast());

    } else if (error.status === 400 || (error.status === 403 && error.response.body && error.response.body.error)) {
        const errorMessage: string = error.response.body.error;
        yield showErrorToast(errorMessage);
    } else if (error.status === 403 || error.status === 401) {
        const location = yield select(getRoutingLocation);
        const currentUrl = `${location.pathname}${location.search}`;
        yield redirectInPWASaga(url(loginUrl, {},  {next: currentUrl}));
    } else {
        logger.error(error);

        // TODO
        // add some logging to sentry/infinario
        // to see how many if these xhr requests fail on client
        // (e.g. due to LiFi)

        yield showServerErrorToast();
    }
}

export interface XHRChannelEvent {
    event: 'done' | 'error' | 'abort' | 'progress';
    // pridaj 'body', tak ako to robi superagent (tak aby sme neskor mohli na neho switchnut a pridat retry)
    successResponse?: {body: any};
    error?: XHRPostError;
    progress?: number;
}

/**
 * wraps XHR request into redux saga. See PhotosUpload.sagas.ts for usage example
 */
export function createXHRChannel(xhr: XMLHttpRequest, endpointUrl: string, postData: {[name: string]: string | Blob}) {
    return eventChannel<XHRChannelEvent | typeof END>((emitter) => {
        const onProgress = (ev: ProgressEvent) => {
            if (ev.lengthComputable) {
                const progress = ev.loaded / ev.total;
                const xev: XHRChannelEvent = {event: 'progress', progress};
                emitter(xev);
            }
        };
        const onComplete = () => {
            if (xhr.status === 200) {
                const xev: XHRChannelEvent = {event: 'done', successResponse: {body: JSON.parse(xhr.response)}};
                emitter(xev);
                emitter(END);
            } else {
                const error = new Error(xhr.statusText) as XHRPostError;
                error.status = xhr.status || 500;  // see w3c spec, status might be 0

                // try to parse response. in standard case, backend returns json with error details
                if (xhr.response) {
                    try {
                        error.response = {body: JSON.parse(xhr.response)};
                    } catch (e) {
                        // pass
                    }
                }

                const xev: XHRChannelEvent = {event: 'error', error};
                emitter(xev);
                emitter(END);
            }
        };
        const onFailure = (ev: ErrorEvent) => {
            const error = new Error(ev.message || xhr.statusText) as XHRPostError;
            error.status = xhr.status || 500;  // see w3c spec, status might be 0
            error.response = {body: xhr.response ? JSON.parse(xhr.response) : {}};

            const xev: XHRChannelEvent = {event: 'error', error};
            emitter(xev);
            emitter(END);
        };
        const onAbort = () => {
            const xev: XHRChannelEvent = {event: 'abort'};
            emitter(xev);
            emitter(END);
        };

        xhr.addEventListener('load', onComplete);
        xhr.addEventListener('error', onFailure);
        xhr.addEventListener('abort', onAbort);
        xhr.upload.addEventListener('progress', onProgress);
        xhr.upload.addEventListener('error', onFailure);
        xhr.upload.addEventListener('abort', onAbort);

        xhr.open('POST', endpointUrl, true);

        const fd = new FormData();
        Object.keys(postData).forEach((key) => {
            fd.append(key, postData[key]);
        });
        xhr.send(fd);

        return () => {
            xhr.removeEventListener('load', onComplete);
            xhr.removeEventListener('error', onFailure);
            xhr.removeEventListener('abort', onAbort);
            xhr.upload.removeEventListener('progress', onProgress);
            xhr.upload.removeEventListener('error', onFailure);
            xhr.upload.removeEventListener('abort', onAbort);
            xhr.onreadystatechange = null;
            xhr.abort();
        };
    }, buffers.sliding(2));
}
