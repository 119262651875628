import { PostEntity } from 'mk2/apps/forum/schemas';
import { BlogPostEntity, CommentEntity, Entity, ImageEntity, UserEntity } from 'mk2/schemas';
import { schema } from 'normalizr';

export const complaintKey = (entity: string, id: number): string => `${entity}/${id}`;

export enum ComplaintTargetType {
    ForumMessage = 'forum-message',
    ForumThread = 'forum-thread',
    PhotoblogComment = 'photoblog-comment',
    PhotoblogImage = 'photoblog-image',
    PhotoblogPost = 'photoblog-post',
    BazaarProduct = 'bazaar-product',
}

export type ComplaintTargetEntity = BlogPostEntity | CommentEntity | ImageEntity | PostEntity;

interface UnionKey {
    id: number;
    schema: ComplaintTargetType;
}

export interface ComplaintEntity extends Entity {
    readonly user: UserEntity;
    readonly target: ComplaintTargetEntity;
    readonly comment: string;
    readonly createdAt: string;
    readonly deletedAt: string;
    readonly imageId?: number;
}

export interface ComplaintWithoutTargetEntity extends Omit<ComplaintEntity, 'target'> {
    readonly target: UnionKey;
}

export const ComplaintTargetSchema = new schema.Union({}, 'target');

export const ComplaintSchema = new schema.Entity('admins.complaint', {
    target: ComplaintTargetSchema,
});

export const ComplaintWithoutTargetSchema = new schema.Entity('admins.complaint', {});
