import { History, Location } from 'history';
import isFunction from 'lodash-es/isFunction';
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import Page from 'mk2/pages/Page';
import { getRequestUser, AppState } from 'mk2/reducers';
import { UserEntity } from 'mk2/schemas';
import { redirectInPWA } from 'mk2/services/browserHistory';
import { Store } from 'mk2/store/configureStore';
import React from 'react';
import { MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';

interface OwnProps {}
interface StateProps {
    requestUser: UserEntity;
}
interface DispatchProps {}
type RouteProps<P> = RouteComponentProps<P>;

type Props<P> = OwnProps & StateProps & DispatchProps & RouteProps<P>;

export type RedirectDefinition<P> = string | ((p: Props<P>) => string);

export interface RedirectOptions<P> {
    to: RedirectDefinition<P>;
    permanent?: boolean;
    authOnly?: boolean;
}

export default class RedirectPage<P> extends Page<OwnProps, StateProps, DispatchProps> {

    private options: RedirectOptions<P>;

    constructor(options: RedirectOptions<P>) {
        super();

        this.options = options;
    }

    public mapStateToProps(): MapStateToPropsParam<StateProps, OwnProps & RouteProps<P>, AppState> {
        return (state: AppState, ownProps: OwnProps & RouteProps<P>): StateProps => ({
            requestUser: getRequestUser(state),
        });
    }

    public mapDispatchToProps(): MapDispatchToPropsObject<DispatchProps> {
        return {};
    }

    public getPermissionRequired(props: Props<P>): boolean {
        const { requestUser } = props;
        const { authOnly } = this.options;
        return !authOnly || isAuthenticated(requestUser);
    }

    public getMetaTitle(props: Props<P>): string {
        return '';
    }

    public hasHeaderBackButton(props: Props<P>): boolean {
        return false;
    }

    public render(props: Props<P>): false | JSX.Element {
        const { requestUser, history, location } = props;
        const { to, permanent } = this.options;
        const gotoUrl = isFunction(to) ? to(props) : to;

        return (
            <RedirectTo
                history={history}
                location={location}
                gotoUrl={gotoUrl}
                permanent={permanent}
                requestUser={requestUser}
            />
        );
    }

    public registerInStore(store: Store<AppState>) {
        // nothing to do
    }
}

interface RedirectToProps<P> {
    history: History;
    location: Location;
    gotoUrl: string;
    permanent: boolean;
    requestUser: UserEntity;
}

class RedirectTo<P> extends React.Component<RedirectToProps<P>> {
    public UNSAFE_componentWillMount() {
        const { history, gotoUrl, permanent } = this.props;
        redirectInPWA(history, gotoUrl, permanent ? 301 : 302);
    }

    public render() {
        return null;
    }
}
