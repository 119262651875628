/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const horseback_riding_other : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_color: commonProp4,
};
