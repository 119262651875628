import { FormPageType } from 'mk2/constants/enums';
import { UploadedPhoto } from 'mk2/containers/PhotosUpload/PhotosUpload';
import {
    PhotoUploadFailureAction,
    PhotoUploadMoveAction,
    PhotoUploadProgressAction,
    PhotoUploadRemoveAction,
    PhotoUploadRemoveAllAction,
    PhotoUploadRequestAction,
    PhotoUploadRotateAction,
    PhotoUploadSuccessAction,
    PHOTO_UPLOAD_FAILURE,
    PHOTO_UPLOAD_MOVE,
    PHOTO_UPLOAD_PROGRESS,
    PHOTO_UPLOAD_REMOVE,
    PHOTO_UPLOAD_REMOVE_ALL,
    PHOTO_UPLOAD_REQUEST,
    PHOTO_UPLOAD_ROTATE,
    PHOTO_UPLOAD_SUCCESS,
} from 'mk2/containers/PhotosUpload/PhotosUpload.actions';
import {
    MK_REDUX_FORM_CANCEL_PAGE,
    MK_REDUX_FORM_CLOSE_PAGE,
    MK_REDUX_FORM_OPEN_PAGE,
    MKReduxFormCancelPage,
    MKReduxFormClosePage,
    MKReduxFormOpenPage,
} from 'mk2/helpers/form.actions';
import { tupdate } from 'mk2/helpers/types';
import { PhotoEntity, PhotoOrientation, PhotoSchema } from 'mk2/schemas';
import { Reducer } from 'redux';
import { reducer as reduxFormReducer, FormAction as ReduxFormAction, FormState as ReduxFormState } from 'redux-form';

// keep in sync with PhotosField.PhotoUploadStatus in bzforms.py
export enum PhotoUploadStatus {
    PENDING,
    SUCCESS,
    FAILURE,
}

interface PhotoUploadBase {
    id: number;
    fileName: string;
    fileSize: number;
}

export interface PendingPhotoUpload extends PhotoUploadBase {
    status: PhotoUploadStatus.PENDING;
    progress: number;
}

export interface SuccessfulPhotoUpload extends PhotoUploadBase {
    status: PhotoUploadStatus.SUCCESS;
    fileName: string;
    fileSize: number;
    photoId: number;
    orientation: PhotoOrientation;
}

export type PhotoEntityOrSuccessfulPhotoUpload = PhotoEntity | SuccessfulPhotoUpload;

export interface FailedPhotoUpload extends PhotoUploadBase {
    status: PhotoUploadStatus.FAILURE;
    errorMessage: string;
}

export interface MKReduxFormState extends ReduxFormState {
    currentFormPage: FormPageType; // current page of multi-page form
    previousValues: any[]; // values when we left the main page and opened other subpage
    previousPages: FormPageType[];
    photos: UploadedPhoto[];
    values?: {
        photos?: Array<PendingPhotoUpload | SuccessfulPhotoUpload | FailedPhotoUpload>;
    };
}

export const initialMKReduxFormState: MKReduxFormState = {
    currentFormPage: FormPageType.Main,
    previousValues: [],
    previousPages: [],
    photos: [],

    // Potrebujeme kvoli zdedenym typingom mat deklarovane aj registeredFields.
    //
    // Ako defaultnu hodnotu nastavime undefined, rovnaku hodnotu pouziva aj redux-form.
    // Pri prvej REGISTER_FIELD action si potom undefined zameni za object.
    //
    // Povodne sme pouzivali ako defaultnu hodnotu []. To sposobovalo, ze pri submite
    // sa niektore form fields s errorom neoznacili ako 'touched=true'. Preto sa pri nich
    // potom nevyrenderovali cervene boxy s chybovou spravou.
    registeredFields: undefined,
};

export interface MKReduxFormsState {
    [formName: string]: MKReduxFormState;
}

const initialMKReduxFormsState: MKReduxFormsState = {};

type MKReduxFormsReducerActions =
    | MKReduxFormOpenPage
    | MKReduxFormClosePage
    | MKReduxFormCancelPage
    | PhotoUploadRequestAction
    | PhotoUploadProgressAction
    | PhotoUploadSuccessAction
    | PhotoUploadFailureAction
    | PhotoUploadRotateAction
    | PhotoUploadRemoveAction
    | PhotoUploadRemoveAllAction
    | PhotoUploadMoveAction
    | any;

function replaceOrAppend<S>(arr: S[], x: S, isIdent: (x1: S, x2: S) => boolean): S[] {
    let replaced = false;

    const ret: S[] = [];
    for (const a of arr) {
        if (isIdent(a, x)) {
            ret.push(x);
            replaced = true;
        } else {
            ret.push(a);
        }
    }

    if (!replaced) {
        ret.push(x);
    }

    return ret;
}

function hasPhotosField(state: MKReduxFormState): boolean {
    // podla declaracie registeredFields je pole, ale ked debugujem je to objekt
    return (
        (state.registeredFields && 'photos' in state.registeredFields) ||
        // Check if form values has 'photos' field - for formPages where photos are currently hidden (no registered)
        (state.values && 'photos' in state.values)
    );
}

function initializeUndefinedParams(
    actualState: MKReduxFormState,
    initialState: MKReduxFormState,
    ensurePhotosExists: boolean,
): MKReduxFormState {
    const patchedState: MKReduxFormState = tupdate(initialState, actualState);

    // patched state je uz urcite novy objekt, mozem priamo modifikovat ten
    if (ensurePhotosExists) {
        if (!patchedState.values) {
            patchedState.values = {
                photos: [],
            };
        } else if (!patchedState.values.photos) {
            patchedState.values.photos = [];
        }
    }

    return patchedState;
}

export const mkReduxFormsReducer: Reducer<MKReduxFormsState> = (
    state = initialMKReduxFormsState,
    action: MKReduxFormsReducerActions,
) => {
    switch (action.type) {
        case MK_REDUX_FORM_OPEN_PAGE: {
            const formName = action.formName;
            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, false);

            return tupdate(state, {
                [formName]: tupdate(formState, {
                    currentFormPage: action.formPage,
                    previousValues: [...formState.previousValues, formState.values],
                    previousPages: [...formState.previousPages, formState.currentFormPage],
                }),
            });
        }

        case MK_REDUX_FORM_CLOSE_PAGE: {
            const formName = action.formName;
            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, false);

            // closing subpage via 'Confirm', commit changes
            return tupdate(state, {
                [formName]: tupdate(formState, {
                    currentFormPage: formState.previousPages[formState.previousPages.length - 1],
                    previousValues: formState.previousValues.slice(0, formState.previousValues.length - 1),
                    previousPages: formState.previousPages.slice(0, formState.previousPages.length - 1),
                }),
            });
        }

        case MK_REDUX_FORM_CANCEL_PAGE: {
            const formName = action.formName;
            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, false);

            // Nothing to cancel
            if (formState.previousPages.length === 0) {
                return state;
            }

            // closing subpage via 'Cancel' button, rollback changes
            return tupdate(state, {
                [formName]: tupdate(formState, {
                    currentFormPage: formState.previousPages[formState.previousPages.length - 1],
                    values: formState.previousValues[formState.previousValues.length - 1],
                    previousValues: formState.previousValues.slice(0, formState.previousValues.length - 1),
                    previousPages: formState.previousPages.slice(0, formState.previousPages.length - 1),
                }),
            });
        }

        case PHOTO_UPLOAD_REQUEST: {
            const formName = action.formName;

            // ignore for forms without photos field
            if (!hasPhotosField(state[formName])) {
                return state;
            }

            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, true);

            const pu: PendingPhotoUpload = {
                status: PhotoUploadStatus.PENDING,
                id: action.uploadId,
                fileName: action.file.name,
                fileSize: action.file.size,
                progress: 0,
            };

            return tupdate(state, {
                [formName]: tupdate(formState, {
                    values: tupdate(formState.values, {
                        photos: replaceOrAppend(formState.values.photos, pu, (u1, u2) => u1.id === u2.id),
                    }),
                }),
            });
        }

        case PHOTO_UPLOAD_PROGRESS: {
            const formName = action.formName;

            // ignore for forms without photos field or destroyed forms
            if (!state[formName] || !hasPhotosField(state[formName])) {
                return state;
            }

            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, true);

            let pu: PendingPhotoUpload = formState.values.photos.find(
                (u) => u.status === PhotoUploadStatus.PENDING && u.id === action.uploadId,
            ) as PendingPhotoUpload;

            if (pu) {
                pu = tupdate(pu, { progress: action.progress });

                return tupdate(state, {
                    [formName]: tupdate(formState, {
                        values: tupdate(formState.values, {
                            photos: replaceOrAppend(formState.values.photos, pu, (u1, u2) => u1.id === u2.id),
                        }),
                        // Update progress on all form pages
                        previousValues: formState.previousValues.map(
                            (vals) =>
                                vals && {
                                    ...vals,
                                    photos: vals.photos.map((p) => (p.id === pu.id ? pu : p)),
                                },
                        ),
                    }),
                });
            }
            // if pending photoupload not found (cancelled multipage form page)
            return state;
        }

        case PHOTO_UPLOAD_SUCCESS: {
            const formName = action.formName;

            // ignore for forms without photos field or destroyed forms
            if (!state[formName] || !hasPhotosField(state[formName])) {
                return state;
            }

            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, true);

            const pu: PendingPhotoUpload = formState.values.photos.find(
                (u) => u.status === PhotoUploadStatus.PENDING && u.id === action.uploadId,
            ) as PendingPhotoUpload;

            if (pu) {
                const su: PhotoEntityOrSuccessfulPhotoUpload = {
                    ...action.response.entities[PhotoSchema.key][action.response.result.photo],
                    status: PhotoUploadStatus.SUCCESS,
                    id: action.uploadId,
                    fileName: pu.fileName,
                    fileSize: pu.fileSize,
                    photoId: action.response.result.photo,
                    orientation: PhotoOrientation.NORMAL,
                };

                return tupdate(state, {
                    [formName]: tupdate(formState, {
                        values: tupdate(formState.values, {
                            photos: replaceOrAppend(
                                formState.values.photos,
                                su as SuccessfulPhotoUpload,
                                (u1, u2) => u1.id === u2.id,
                            ),
                        }),
                        // Update progress on all form pages
                        previousValues: formState.previousValues.map(
                            (vals) =>
                                vals && {
                                    ...vals,
                                    photos: vals.photos.map((p) => (p.id === su.id ? su : p)),
                                },
                        ),
                    }),
                });
            }
            // if pending photoupload not found (cancelled multipage form page)
            return state;
        }

        case PHOTO_UPLOAD_FAILURE: {
            const formName = action.formName;

            // ignore for forms without photos field or destroyed forms
            if (!state[formName] || !hasPhotosField(state[formName])) {
                return state;
            }

            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, true);

            const pu: PendingPhotoUpload = formState.values.photos.find(
                (u) => u.status === PhotoUploadStatus.PENDING && u.id === action.uploadId,
            ) as PendingPhotoUpload;

            if (pu) {
                const su: FailedPhotoUpload = {
                    status: PhotoUploadStatus.FAILURE,
                    id: action.uploadId,
                    fileName: pu.fileName,
                    fileSize: pu.fileSize,
                    errorMessage: action.error,
                };

                return tupdate(state, {
                    [formName]: tupdate(formState, {
                        values: tupdate(formState.values, {
                            photos: replaceOrAppend(formState.values.photos, su, (u1, u2) => u1.id === u2.id),
                        }),
                        // Update progress on all form pages
                        previousValues: formState.previousValues.map(
                            (vals) =>
                                vals && {
                                    ...vals,
                                    photos: vals.photos.map((p) => (p.id === su.id ? su : p)),
                                },
                        ),
                    }),
                });
            }
            // if pending photoupload not found (cancelled multipage form page)
            // or coulde be failure action generated from xhr.abort() and
            // upload was already removed by previous PHOTO_UPLOAD_REMOVE
            return state;
        }

        case PHOTO_UPLOAD_ROTATE: {
            const formName = action.formName;

            // ignore for forms without photos field
            if (!hasPhotosField(state[formName])) {
                return state;
            }

            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, true);

            let su: SuccessfulPhotoUpload = formState.values.photos.find(
                (u) => u.status === PhotoUploadStatus.SUCCESS && u.id === action.uploadId,
            ) as SuccessfulPhotoUpload;
            if (!su) {
                throw new Error('Photo is not successfully uploaded');
            }

            su = tupdate(su, { orientation: action.orientation });

            return tupdate(state, {
                [formName]: tupdate(formState, {
                    values: tupdate(formState.values, {
                        photos: replaceOrAppend(formState.values.photos, su, (u1, u2) => u1.id === u2.id),
                    }),
                }),
            });
        }

        case PHOTO_UPLOAD_MOVE: {
            const formName = action.formName;

            // ignore for forms without photos field
            if (!hasPhotosField(state[formName])) {
                return state;
            }

            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, true);

            const diff = action.from - action.to;
            const photos = formState.values.photos;
            const item = photos[action.from];
            const length = photos.length;
            const newPhotos =
                diff > 0
                    ? // Move left
                      [
                          ...photos.slice(0, action.to),
                          item,
                          ...photos.slice(action.to, action.from),
                          ...photos.slice(action.from + 1, length),
                      ]
                    : // Move right
                      [
                          ...photos.slice(0, action.from),
                          ...photos.slice(action.from + 1, action.to + 1),
                          item,
                          ...photos.slice(action.to + 1, length),
                      ];

            return tupdate(state, {
                [formName]: tupdate(formState, {
                    values: tupdate(formState.values, {
                        photos: newPhotos,
                    }),
                }),
            });
        }

        case PHOTO_UPLOAD_REMOVE: {
            const formName = action.formName;

            // ignore for forms without photos field
            if (!hasPhotosField(state[formName])) {
                return state;
            }

            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, true);

            return tupdate(state, {
                [formName]: tupdate(formState, {
                    values: tupdate(formState.values, {
                        photos: formState.values.photos.filter((u) => u.id !== action.uploadId),
                    }),
                }),
            });
        }

        case PHOTO_UPLOAD_REMOVE_ALL: {
            const formName = action.formName;

            // ignore for forms without photos field
            if (!hasPhotosField(state[formName])) {
                return state;
            }

            const formState = initializeUndefinedParams(state[formName], initialMKReduxFormState, true);

            return tupdate(state, {
                [formName]: tupdate(formState, {
                    values: tupdate(formState.values, {
                        photos: [],
                    }),
                }),
            });
        }

        default: {
            // pass to default redux-form reducer
            const rfstate = reduxFormReducer(state, action) as MKReduxFormsState;
            if (!rfstate) {
                return rfstate;
            }

            // ak redux form zalozil pre aktualny form novy state, injectni do stavu
            // ze current form page je 'main'
            const rfaction = action as ReduxFormAction;
            if (rfaction.type.startsWith('@@redux-form/')) {
                const formName = rfaction.meta ? rfaction.meta.form : null;
                if (formName && rfstate[formName] && !rfstate[formName].currentFormPage) {
                    rfstate[formName].currentFormPage = FormPageType.Main;
                }
            }

            return rfstate;
        }
    }
};
