/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp15: Array<[string, string]> = [
    d("XS"),
    d("S"),
    d("M"),
    d("L"),
    d("XL"),
    d("XXL"),
    ["ONE_SIZE_FIT_ALL", "Univerz\u00e1ln\u00ed velikost"],
];
