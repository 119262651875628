import cx from 'classnames';
import { Header } from 'mk2/components/Header';
import React from 'react';
import styles from './PageContentDesktopHeader.mscss';

interface OwnProps {
    headerTitle: string;
    headerLeft: JSX.Element;
    headerRight: JSX.Element;
    tabs: JSX.Element[];
    legacyDjango?: boolean;
}

export class PageContentDesktopHeader extends React.PureComponent<OwnProps> {

    public render() {
        const { headerTitle, headerLeft, headerRight, tabs, legacyDjango } = this.props;

        return (headerTitle || headerLeft || headerRight || (tabs && tabs.length)) ? (
            <div className={styles.PageContentDesktopHeader__header}>
                <Header
                    heading={headerTitle}
                    left={headerLeft}
                    right={headerRight}
                    isMobile={false}
                />

                {tabs && tabs.length && (
                    <div
                        className={cx(styles.PageContentDesktopHeader__tabs,
                                      legacyDjango && styles['PageContentDesktopHeader__tabs--legacyDjango'])}
                    >
                        {tabs.map((tab, index) => tab && (
                            <div key={index} className={styles.PageContentDesktopHeader__tab}>{tab}</div>
                        ))}
                    </div>
                )}
            </div>
        ) : null;
    }
}
