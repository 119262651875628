import {
    dashPrepareSupersetFunnelViewsUrl,
    dashSeoPerformaceUrl,
} from 'mk2/apps/dash/urls';
import { DynamicRoute } from 'mk2/containers/Application/Application';

export const routes: DynamicRoute[] = [
    {
        app: 'dash',
        exact: true,
        path: dashSeoPerformaceUrl,
        getPage: () =>
            import('mk2/apps/dash/containers/SeoPerformance/SeoPerformancePage' /* webpackChunkName: "dash.SeoPerformance" */),
        modules: ['mk2/apps/dash/containers/SeoPerformance/SeoPerformancePage'],
    }, {
        app: 'dash',
        exact: true,
        path: dashPrepareSupersetFunnelViewsUrl,
        getPage: () => {
            return import('mk2/apps/dash/containers/PrepareSupersetFunnelViews/PrepareSupersetFunnelViewsPage' /* webpackChunkName: "dash.PrepareSupersetFunnelViews" */);
        },
        modules: [ 'mk2/apps/dash/containers/StrollerEditPattern/StrollerEditPatternPage' ],
    },
];
