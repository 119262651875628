/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const trike : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("Baby Mix"),
        d("Baby Tiger"),
        d("Bayo"),
        d("Buddy Toys"),
        d("Caretero"),
        d("Chicco"),
        d("Chipolino"),
        d("DOLU"),
        d("Feber"),
        d("Injusa"),
        d("KinderKraft"),
        d("Lean Toys"),
        d("Little Tikes"),
        d("Lorelli"),
        d("Milly Mally"),
        d("Puky"),
        d("Qplay"),
        d("R-Sport"),
        d("Ramiz"),
        d("Rastar"),
        d("Rito"),
        d("Rolly Toyz"),
        d("Smoby"),
        d("Sun Baby"),
        d("Tiny Bike"),
        d("TOYZ"),
        d("Turbo"),
        d("Volare"),
        d("ZOPA"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
