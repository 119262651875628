/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const sport_watches : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("aiko"),
        d("aligator"),
        d("ALPINA"),
        d("amazfit"),
        d("Apple"),
        d("armodd"),
        d("ASUS"),
        d("BILLOW"),
        d("canyon"),
        d("carneo"),
        d("Casio"),
        d("crefit"),
        d("CRIVIT"),
        d("cube1"),
        d("cubot"),
        d("doogee"),
        d("evolveo"),
        d("festina"),
        d("Fitbit"),
        d("FITFORT"),
        d("forever"),
        d("fossil"),
        d("gamin"),
        d("Garett"),
        d("Garmin"),
        d("haylou"),
        d("helmer"),
        d("Honor"),
        d("Huawei"),
        d("iGet"),
        d("immax"),
        d("inSPORTline"),
        d("lamax"),
        d("Lenovo"),
        d("Neogo"),
        d("niceboy"),
        d("polar"),
        d("Samsung"),
        d("sequent"),
        d("Sigma"),
        d("smartomat"),
        d("Suunto"),
        d("Timex"),
        d("TOMTOM"),
        d("umidigi"),
        d("VeryFit"),
        d("vtech"),
        d("withings"),
        d("Wotchi"),
        d("WowME"),
        d("XD Design"),
        d("Xiaomi"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
