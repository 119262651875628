import { tupdate } from 'mk2/helpers/types';
import {
    CreateDeleteActionFunction,
    DeletionHideFormAction,
    DeletionShowFormAction,
    DELETION_HIDE_FORM,
    DELETION_SHOW_FORM,
} from './Deletion.actions';
import { deletionKey } from './Deletion.schemas';

export interface DeletionsState {
    [entitySlashId: string]: DeletionState;
}

export const initialDeletionsState: DeletionsState = {};

interface DeletionState {
    show: boolean;
    createDeleteAction: CreateDeleteActionFunction;
}

const initialDeletionState: DeletionState = {
    show: false,
    createDeleteAction: null,
};

type DeletionReducerAction = DeletionHideFormAction | DeletionShowFormAction;

export const deletionReducer = (state: DeletionsState = initialDeletionsState, action: DeletionReducerAction) => {
    switch (action.type) {
        case DELETION_SHOW_FORM:
            const showFormKey = deletionKey(action.entity, action.id);
            return tupdate(state, {
                [showFormKey]: {
                    ...(state[showFormKey] || initialDeletionState),
                    createDeleteAction: action.createDeleteAction,
                    show: true,
                },
            });

        case DELETION_HIDE_FORM:
            const hideFormKey = deletionKey(action.entity, action.id);
            return tupdate(state, {
                [hideFormKey]: {
                    ...(state[hideFormKey] || initialDeletionState),
                    show: false,
                },
            });

        default:
            return state;
    }
};
