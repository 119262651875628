import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { LocationDescriptor } from 'history';
import { Btn, BtnLayout, BtnType } from 'mk2/components/Btn';
import { goBackInPWA, HistoryLocationState } from 'mk2/services/browserHistory';
import React from 'react';
import { withRouter, RouteComponentProps, StaticContext } from 'react-router';

interface OwnProps {
    className?: string;
    icon?: string | IconLookup;
    asAnchor?: boolean;
    link: LocationDescriptor;
    layout?: BtnLayout;
    type?: BtnType;
    label?: React.ReactNode;
    'data-cy'?: string;

    callback?();
}

type RouteProps = RouteComponentProps<{}, StaticContext, HistoryLocationState>;

type Props = OwnProps & RouteProps;

class BackBtnComponent extends React.PureComponent<Props> {
    public static defaultProps: Partial<Props> = {
        layout: BtnLayout.Icon,
    };

    public render() {
        const { className, icon, link, asAnchor, layout, label, type, 'data-cy': dataCy } = this.props;

        return (
            <Btn
                layout={layout}
                icon={icon}
                className={className}
                link={link}
                asAnchor={asAnchor}
                onClick={!asAnchor ? this.onClick : undefined}
                label={label}
                type={type}
                data-cy={dataCy}
            />
        );
    }

    private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { link, history, callback } = this.props;

        event.preventDefault();
        event.stopPropagation();

        if (callback) {
            callback();
        }

        goBackInPWA(history, link);
    };
}

export const BackBtn = withRouter(BackBtnComponent);
