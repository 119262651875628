import { PhotoUploadStatus } from 'mk2/helpers/form.reducers';
import { Entity, PhotoAspect, PhotoEntity, PhotoMimetype, PhotoPreviewEntity, StrollerPhoto } from 'mk2/schemas';

function getLoadedImage(f): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent) => {
            const fr = e.currentTarget as FileReader;
            const image = new Image();
            image.src = fr.result as string;
            image.onload = () => {
                resolve(image);
            };
            image.onerror = (err) => { reject(err); };
        };
        reader.onerror = (err) => { reject(err); };
        reader.readAsDataURL(f);
    });
}

export async function getPhotoPreview(f): Promise<PhotoPreviewEntity> {
    let pp: PhotoPreviewEntity = null;
    if (f) {
        const image = await getLoadedImage(f);
        let mimetype;
        switch (f.name.slice(-4)) {
            case '.gif': mimetype = PhotoMimetype.GIF; break;
            case '.png': mimetype = PhotoMimetype.PNG; break;
            case '.jpg': mimetype = PhotoMimetype.JPEG; break;
            default: mimetype = null;
        }
        switch (f.name.slice(-5)) {
            case '.jpeg': mimetype = PhotoMimetype.JPEG; break;
            case '.webp': mimetype = PhotoMimetype.WEBP; break;
        }
        pp = {
            id: f.name,
            fileName: f.name,
            imgBase64Data: image.src,
            originalWidth: image.width,
            originalHeight: image.height,
            aspect: image.width === image.height ? PhotoAspect.SQUARE :
                (image.width >= image.height ?
                    PhotoAspect.LANDSCAPE :
                    PhotoAspect.PORTRAIT),
            mimetype,
        };

    }
    if (pp) {
        return pp;
    } else {
        throw new Error('image load error');
    }
}

export function getExifOrientation(data: ArrayBuffer) {
    const view = new DataView(data);

    if (view.getUint16(0, false) !== 0xFFD8) {
        return -2; // Not JPEG
    }

    const length = view.byteLength;
    let offset = 2;
    while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) {
            return -1;
        }

        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) {
                return -1;
            }

            const little = view.getUint16(offset += 6, false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
                if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                    return view.getUint16(offset + (i * 12) + 8, little);
                }
            }
        } else if ((marker & 0xFF00) !== 0xFF00) { // tslint:disable-line no-bitwise
            break;
        } else {
            offset += view.getUint16(offset, false);
        }
    }

    return -1;
}

export function photoEntitiesToSuccessfulPhotoUploads(photos: PhotoEntity[]) {
    // TODO consider cacheing
    const successfulPhotoUploads = [];
    let increment = 0;
    for (const p of photos) {
        successfulPhotoUploads.push({
            ...p,
            photoId: p.id,
            id: increment,
            status: PhotoUploadStatus.SUCCESS,
            orientation: 0,
            code: p.code,
            fileName: '',
            fileSize: 0,
        });
        increment++;
    }
    return successfulPhotoUploads;
}

export interface EntityWithPhotofile extends Entity {
    readonly photofile: PhotoEntity;
}

export function entitiesWithPhotofilePropToSuccessfulPhotoUploads(photos: EntityWithPhotofile[]) {
    // TODO consider cacheing
    const successfulPhotoUploads = [];
    let increment = 0;
    for (const p of photos) {
        successfulPhotoUploads.push({
            ...p.photofile,
            photoId: p.photofile.id,
            id: increment,
            status: PhotoUploadStatus.SUCCESS,
            orientation: 0,
            code: p.photofile.code,
            fileName: '',
            fileSize: 0,
        });
        increment++;
    }
    return successfulPhotoUploads;
}

type EntityWithPhotoOrPhotofile = EntityWithPhoto | EntityWithPhotofile | PhotoEntity;

export interface EntityWithPhoto extends Entity {
    readonly photo: PhotoEntity;
}

export interface EntityWithPhotofile extends Entity {
    readonly photofile: PhotoEntity;
}

export function entitiesWithPhotoPropToSuccessfulPhotoUploads(photos: EntityWithPhotoOrPhotofile[]) {
    // TODO consider cacheing
    const successfulPhotoUploads = [];
    let increment = 0;
    for (const p of photos) {
        const photoEntity =
            'photo' in p ? p.photo // EntityWithPhoto
            : 'photofile' in p ? p.photofile // EntityWithPhotofile
            : p; // PhotoEntity
        successfulPhotoUploads.push({
            ...photoEntity,
            photoId: photoEntity.id,
            id: increment,
            status: PhotoUploadStatus.SUCCESS,
            orientation: 0,
            code: photoEntity.code,
            fileName: '',
            fileSize: 0,
        });
        increment++;
    }
    return successfulPhotoUploads;
}

export function resizeForCover(p: PhotoEntity | StrollerPhoto, minSize: number) {
    let ratio;
    if (Object.keys(p).includes('width')) {
        const photoFile = p as PhotoEntity;
        ratio = photoFile.width / photoFile.height;
    } else if (Object.keys(p).includes('originalWidth')) {
        const strollerPhoto = p as StrollerPhoto;
        ratio = strollerPhoto.originalWidth / strollerPhoto.originalHeight;
    } else {
         ratio = 1; // or raise error?
    }

    let width;
    let height;

    if (ratio < 1) {
        width = minSize;
        height = width / ratio;
    } else {
        height = minSize;
        width = height * ratio;
    }
    return { width, height };
}
