import { ID_RE, USERNAME } from 'mk2/helpers/urls';

const KID_ID = `:kidId(${ID_RE})`;

export const settingsUrl = '/settings/';

export const settingsProfileUrl = `/settings/${USERNAME}/profile/`;
export const settingsLocationUrl = `/settings/${USERNAME}/location/`;

export const settingsKidsUrl = `/settings/${USERNAME}/kids/`;
export const settingsKidAddUrl = `/settings/${USERNAME}/kid/add/:sex(boy|girl)/`;
export const settingsKidEditUrl = `/settings/${USERNAME}/kid/edit/${KID_ID}/`;

export const settingsAccountUrl = `/settings/${USERNAME}/account/`;
export const settingsCancelAccountUrl = `/settings/${USERNAME}/cancellation/`;
export const settingsChangePasswordUrl = `/settings/${USERNAME}/change-password/`;

export const mailNotificationsSettingsUrl = `/settings/mail-notifications/`;

