/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp7 } from './commonProp7';
import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const sweatshirts_jumpers_vests : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SWEATSHIRT", "Mikina"],
        ["KNITWEAR", "Svetr, pulovr"],
        ["VESTS", "Vesta (nezateplen\u00e1)"],
        ["BOLERO", "Bolerko"],
        ["ROLLNECK", "Rol\u00e1k"],
        ["PONCHO", "Pon\u010do"],
    ],
    p_size: commonProp7,
    p_sex: commonProp5,
    p_dressing: [
        ["WITH_CLOSING", "Se zap\u00edn\u00e1n\u00edm"],
        ["OVER_HEAD", "P\u0159es hlavu"],
    ],
    p_hood: [
        ["WITH_HOOD", "S kapuc\u00ed"],
        ["WITHOUT_HOOD", "Bez kapuce"],
    ],
    p_brand: commonProp6,
    p_color: commonProp4,
};
