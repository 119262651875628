import { faExclamationTriangle, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOAD_ERROR_MESSAGE } from 'mk/autogenerated/translations/Loading.849546df34e273a4f49e2134010ee382'
import { getLogger } from 'mk2/logger';
import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import styles from './Loading.mscss';

const logger = getLogger('components/Loading');

type Props = Pick<LoadingComponentProps, Exclude<keyof LoadingComponentProps, 'retry'>>;

export const Loading: React.StatelessComponent<Props> = ({ error, pastDelay }) => {
    if (error) {
        logger.error(error);
        return (
            <div>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {LOAD_ERROR_MESSAGE}
           </div>
        );
    } else if (pastDelay) {
        return (
            <div className={styles.Loading}>
                <FontAwesomeIcon icon={faSpinner} spin />
            </div>
        );
    } else {
        return null;
    }
};
