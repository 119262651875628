import {
    NewsletterSubscriberCreateSuccessAction,
    NEWSLETTER_SUBSCRIBER_CREATE_SUCCESS,
} from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter.actions';

export interface PregnancyNewsletterState {
    submitted: boolean;
}

const initialState: PregnancyNewsletterState = {
    submitted: false,
};

type PregnancyNewsletterActions = NewsletterSubscriberCreateSuccessAction;

const reducer = (state: PregnancyNewsletterState = initialState, action: PregnancyNewsletterActions) => {
    switch (action.type) {
        case NEWSLETTER_SUBSCRIBER_CREATE_SUCCESS:
            return {
                ...state,
                submitted: true,
            };
        default:
            return state;
    }
};

export default reducer;

export const getSubmitted = (state: PregnancyNewsletterState) => state.submitted;
