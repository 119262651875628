import cloneDeep from 'lodash-es/cloneDeep';
import compact from 'lodash-es/compact';
import defaults from 'lodash-es/defaults';
import fromPairs from 'lodash-es/fromPairs';
import intersection from 'lodash-es/intersection';
import pick from 'lodash-es/pick';
import * as allProps from 'mk/autogenerated/bzProps';
import {
    BAZAAR_SIZE_OTHER,
    BZ2_FACET_P_ISOFIX,
    BZ2_FACET_P_LENGTH_ORDINAL,
    BZ2_FACET_P_WIDTH_ORDINAL,
    COMMON_PLACES_ABROAD,
} from 'mk/autogenerated/translations/filterUtils.789ef3ddb09038374860ac4301da50b5'
import { QUALITY_VALUES } from 'mk/bazaar/common/enums';
import { dict, getPropertiesForCategories, LeafCategory } from 'mk/bazaar/product/categories';
import { HttpNotFoundError } from 'mk/common/httpErrors';
import { slugify } from 'mk/common/utils';
import { getCountyIdForSlug, getCountyName } from 'mk/geo';
import { initialSettings, Settings } from 'mk2/reducers/request';

interface Props {
    // TODO: Specify which types exists!
    [type: string]: string[];
}

export interface Filter {
    cats: string[];
    locations: number[];
    places: number[];
    price_hi: number;
    price_lo: number;
    props: Props;
    q: string;
    users: string[];
    settings: Settings;
    sellers?: string; // TODO: Allowed values?
    complaints?: boolean;
    notMarked?: boolean;
    valid?: boolean;
    // Page or start should be defined
    start?: number; // Start has priority
    page?: number;
}

export interface PathnameFilter {
    locations: number[];
    props: Props;
}

const emptyFilter: Filter = {
    cats: [],
    locations: [],
    places: [],
    price_hi: null,
    price_lo: null,
    props: {
        p_size: [],
        p_sex: [],
        p_color: [],
    },
    q: '',
    users: [],
    settings: {},
};

export function defaultFilter(): Filter {
    return emptyFilter;
}

export function makeFullFilter(filter: Partial<Filter>): Filter {
    return {
        ...emptyFilter,
        ...filter,
        props: {
            ...emptyFilter.props,
            ...filter.props,
        },
        settings: {
            ...emptyFilter.settings,
            ...filter.settings,
        },
    };
}

export function deserializeFilterFromPathname(category: LeafCategory, pathnameFilters: string): PathnameFilter {
    const categoryProps = allProps[category.name];
    if (!categoryProps) {
        return { locations: [], props: {} };
    }

    // We should trim all ':' and the end of the pathnameFilters
    if (pathnameFilters.endsWith('--')) {
        throw new HttpNotFoundError('Pathname props ending with \'--\' are not allowed');
    }

    const filterParts = pathnameFilters.replace(/:+$/, '').split('--');
    return filterParts.reduce(
        (acc, cur, index) => {
            if (!cur) {
                // Ignore if not set
                return acc;
            }

            const prop = category.pathnameFilters[index];
            if (prop) {
                if (prop === 'location') {
                    const location = cur === slugify(COMMON_PLACES_ABROAD) ? 0 : getCountyIdForSlug(cur);
                    if (!location) {
                        throw new HttpNotFoundError('Given location in pathname wasn\'t found in the config');
                    }
                    acc.locations = [location];
                } else {
                    const found = categoryProps[prop].find((option) => {
                        if (prop === 'p_size') {
                            // Do not slugify p_size, because we have '<' symbol there
                            return option[0] === cur;
                        } else {
                            const slugged = slugify(option.length === 1 ? option[0] : option[1]);
                            return slugged === cur;
                        }
                    });

                    if (found) {
                        acc.props[prop] = [found[0]]; // We want first value -> key
                    } else {
                        // Throw 404 if prop is not found
                        throw new HttpNotFoundError('Given property in pathname wasn\'t found in the config');
                    }
                }
            }
            return acc;
        },
        { locations: [], props: {} },
    );
}

export function serializeFilterForPathname(filter: Filter): string {
    if (!filter.cats.length) {
        return null;
    }

    const categoryProps = allProps[filter.cats[0]];
    if (!categoryProps) {
        return null;
    }

    const category: LeafCategory = dict()[filter.cats[0]];
    if (!category.pathnameFilters || !category.pathnameFilters.length) {
        return null;
    }

    let pathname = category.pathnameFilters.reduce((acc, cur) => {
        if (cur === 'location') {
            if (filter.locations && filter.locations.length === 1) {
                acc = `${acc}${slugify(
                    filter.locations[0] === 0 ? COMMON_PLACES_ABROAD : getCountyName(filter.locations[0]),
                )}`;
            }
        } else if (filter.props[cur] && filter.props[cur].length === 1) {
            const found = categoryProps[cur].find((option) => filter.props[cur][0] === option[0]);
            if (!found) {
                return acc;
            }
            if (cur === 'p_size') {
                // Do not slugify p_size, because we have '<' symbol there
                acc = `${acc}${found[0]}`;
            } else {
                const slugged = slugify(found.length === 1 ? found[0] : found[1]);
                acc = `${acc}${slugged}`;
            }
        }

        acc = `${acc}--`;
        return acc;
    }, '');

    pathname = pathname.replace(/(--)+$/, ''); // trim ending --
    return pathname || null;
}

export function filterByCategoryAndProps(categoryName, props): Filter {
    let filter = cloneDeep(emptyFilter);
    filter = setCategory(filter, categoryName);
    filter.props = props;
    return filter;
}

export function setCategory(filter: Filter, category: string): Filter {
    const newCat = category ? [category] : [];
    const propsForCat = getPropertiesForCategories(newCat);

    // keep only properties which are valid for that category
    let props = pick(filter.props, propsForCat);
    // and update if some are missing
    const propsForCatDict = fromPairs(propsForCat.map((prop) => [prop, []]));
    props = defaults(props, propsForCatDict);

    return {
        ...filter,
        cats: newCat,
        props,
    };
}

export function setFacet(filter: Filter, facet: keyof Filter['props'], option: string, checked: boolean): Filter {
    if (checked) {
        filter.props[facet] = filter.props[facet] || [];
        if (filter.props[facet].indexOf(option) === -1) {
            return {
                ...filter,
                props: {
                    ...filter.props,
                    [facet]: [...filter.props[facet], option],
                },
            };
        }
    } else {
        if (filter.props[facet]) {
            return {
                ...filter,
                props: {
                    ...filter.props,
                    [facet]: filter.props[facet].filter((i) => i !== option),
                },
            };
        }
    }
    return filter;
}

export function setQuery(filter: Filter, query: string): Filter {
    return {
        ...filter,
        q: query,
    };
}

export function resetPage(filter: Filter): Filter {
    return {
        ...filter,
        page: 1,
    };
}

export function setLocations(filter: Filter, option: number, checked: boolean): Filter {
    if (checked) {
        if (filter.locations.indexOf(option) === -1) {
            return {
                ...filter,
                locations: [...filter.locations, option],
            };
        }
    } else {
        return {
            ...filter,
            locations: filter.locations.filter((i) => i !== option),
        };
    }
    return filter;
}

export function setPlaces(filter: Filter, option: number, checked: boolean): Filter {
    if (checked) {
        if (filter.places.indexOf(option) === -1) {
            return {
                ...filter,
                places: [...filter.places, option],
            };
        }
    } else {
        return {
            ...filter,
            places: filter.places.filter((i) => i !== option),
        };
    }
    return filter;
}

export function setUser(filter: Filter, username: string): Filter {
    return {
        ...filter,
        users: [username],
    };
}

export function setSettings(filter: Filter, settings: Partial<Filter['settings']>): Filter {
    return {
        ...filter,
        settings: {
            ...filter.settings,
            ...settings,
        },
    };
}

export function setSellers(filter: Filter, status: Filter['sellers']): Filter {
    return {
        ...filter,
        sellers: status,
    };
}

export function clearFacet(filter: Filter, facet: keyof Filter['props']): Filter {
    if (filter.props[facet]) {
        return {
            ...filter,
            props: {
                ...filter.props,
                [facet]: [],
            },
        };
    } else {
        return filter;
    }
}

export function clearCategory(filter: Filter): Filter {
    return setCategory(filter, null);
}

export function clearLocations(filter: Filter): Filter {
    return {
        ...filter,
        locations: [],
        places: [],
    };
}

export function clearPlaces(filter: Filter): Filter {
    return {
        ...filter,
        places: [],
    };
}

export function shouldSetInTheQuery(name: string, filter: Filter): boolean {
    if (!filter.cats || filter.cats.length !== 1) {
        return true;
    }

    const value = name === 'location' ? filter.locations : filter.props[name];
    if (value.length !== 1) {
        return true;
    }

    const category: LeafCategory = dict()[filter.cats[0]];
    return !category.pathnameFilters || category.pathnameFilters.indexOf(name) === -1;
}

/**
 * Serialize filter for the URL query
 *
 * @param {object} filter Actual filter
 * @param ignoreDefaultSettings
 * @returns {object}
 */
export function serializeFilterForQuery(filter: Filter, ignoreDefaultSettings = false) {
    const query: any = {};
    const { locations, q, price_lo, price_hi, sellers, complaints, notMarked, places, valid } = filter;

    // locations
    if (locations?.length && shouldSetInTheQuery('location', filter)) {
        // _.compact will clear all undefined values
        query.locations = compact(
            filter.locations.map((location) => {
                return location === 0 ? COMMON_PLACES_ABROAD : getCountyName(location);
            }),
        ).join(',');
    }

    // places
    if (places?.length) {
        // _.compact will clear all undefined values
        query.places = compact(filter.places).join(',');
    }

    // q
    if (q) {
        query.q = filter.q;
    }

    // price_lo
    // zero is also valid number
    if (price_lo || price_lo === 0) {
        query.price_lo = price_lo;
    }

    // price_hi
    // zero is also valid number
    if (price_hi || price_hi === 0) {
        query.price_hi = price_hi;
    }

    // sellers
    if (sellers) {
        query.sellers = sellers;
    }

    // complaints
    if (complaints) {
        query.complaints = true;
    }

    if (notMarked) {
        query.notMarked = true;
    }

    if (valid === true) {
        query.valid = true;
    } else if (valid === false) {
        query.valid = false;
    }

    // props
    // Props are serialized as uppercase keys
    const props = Object.keys(filter.props).filter((prop) => {
        // Filter empty props
        return filter.props[prop].length && shouldSetInTheQuery(prop, filter);
    });
    props.forEach((prop) => {
        const key = prop.toUpperCase().substring(2);
        query[key] = filter.props[prop].join(',');
    });

    // settings
    // copy values from settings - just rename 'gridSort' to 'sort', because we don't want
    // to lose old index - see Google Webmasters
    // and join quality array to string
    Object.keys(filter.settings).forEach((setting) => {
        if (filter.settings[setting] === undefined || filter.settings[setting] === null) {
            // Ignore null or undefined values within settings
            return;
        }

        // Ignore default values
        if (
            ignoreDefaultSettings &&
            ((setting === 'quality' && filter.settings[setting].join(',') === initialSettings[setting].join(',')) ||
                filter.settings[setting] === initialSettings[setting])
        ) {
            return;
        }

        if (setting === 'gridSort') {
            query.sort = filter.settings[setting];
        } else if (setting === 'quality') {
            query.quality = intersection(Object.keys(QUALITY_VALUES), filter.settings[setting]).join(',');
        } else if (['cleanSort', 'myGridFilter', 'myGridSort', 'wishlistSort', 'placesRadius'].includes(setting)) {
            query[setting] = filter.settings[setting];
        }
    });

    return query;
}

// displaying "yes"/ "no" / number facets are not intuitive, we change labels
export const PRODUCT_FACET_LABELS = {
    p_isofix: {
        'YEA': BZ2_FACET_P_ISOFIX,
        'NAY': null,
    },
    'cots' : {
        p_width_ordinal: BZ2_FACET_P_WIDTH_ORDINAL + ': ',
        p_length_ordinal: BZ2_FACET_P_LENGTH_ORDINAL + ': ',
        },
};

export const cleanFacetValue = (facetValue) => {
    if (facetValue === 'SIZE_OTHER') {
        return BAZAAR_SIZE_OTHER;
    }
    else {
        return  facetValue;
    }
};
