import { createLocation, History, Location, LocationDescriptor } from 'history';
import { CURRENT_REVISION } from 'mk/autogenerated/gitData';
import { BASE_URL, IS_PROD } from 'mk/settings';
import { loginUrl, signupUrl } from 'mk2/apps/users/urls';
import { Route } from 'mk2/containers/Application/Application';
import { hasRoute } from 'mk2/helpers/router';
import { apiAuth } from 'mk2/services/browserXhr';
import { RouterContext } from 'mk2/services/RouterContext';
import { parse } from 'query-string';
import React, { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import { LinkProps } from 'react-router-dom'; // tslint:disable-line

export type OwnProps = LinkProps; // inherit from react-router-dom/LinkProps

interface InjectedProps {
    routes: Route[];
    history: History;
    release?: string;
}

const baseUrlRe = new RegExp('^' + BASE_URL);

const resolveToLocation = (to: OwnProps['to'], currentLocation: Location) =>
    typeof to === 'function' ? to(currentLocation) : to;

const normalizeToLocation = (to: LocationDescriptor, currentLocation: Location) => {
    return typeof to === 'string' ? createLocation(to, null, null, currentLocation) : to;
};

class LinkWithRoutes extends React.PureComponent<OwnProps & InjectedProps> {
    public static defaultProps = {
        replace: false,
    };

    public render() {
        const { to, replace, innerRef, routes, history, ...rest } = this.props;

        let href: string;
        if (typeof to === 'string') {
            href = to;
        } else {
            const location = normalizeToLocation(resolveToLocation(to, history.location), history.location);
            href = location ? history.createHref(location) : '';
        }

        // https://trello.com/c/tamkDLUC/6540-do-get-parametrov-pre-login-a-signup-linky-v-headeri-nevkladat-nexturl-ale-pridat-az-na-onclick
        if (href.includes('?')) {
            const qs = parse(href.substr(href.indexOf('?')));
            if (qs.next && (href.startsWith(loginUrl) || href.startsWith(signupUrl))) {
                href = href.startsWith(loginUrl) ? loginUrl : signupUrl;
            }
        }

        return <a {...rest} onClick={this.onClick} href={href} ref={innerRef} />;
    }

    private onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        const { to, onClick, target, replace, routes, history, release } = this.props;

        const href = event.currentTarget.href;

        if (onClick) {
            onClick(event);
        }

        if (
            !event.defaultPrevented && // onClick prevent default
            event.button === 0 && // ignore everything but left clicks
            !target && // let browser handle "target=_blank" etc.
            !isModifiedEvent(event) && // ignore clicks with modifier keys
            hasRoute(href, routes) // Check if we have handler for this URL
        ) {
            event.preventDefault();

            const method = replace ? history.replace : history.push;
            let destination = to;
            if (typeof destination === 'string') { // Remove BASE_URL from absolute links
                destination = destination.replace(baseUrlRe, '');
            }
            const location = normalizeToLocation(resolveToLocation(destination, history.location), history.location);

            if (IS_PROD && !!release && release !== CURRENT_REVISION) {
                window.location.href = location.pathname;
            } else {
                try {
                    apiAuth.verifier(); // Throws exception if csrf token has changed
                    method(location);
                } catch (e) {
                    window.location.href = location.pathname;
                }
            }
        }
    };
}

export const Link: React.FunctionComponent<OwnProps> = React.memo((props) => {
    const { routes, history } = useContext(RouterContext);
    const context = useContext(ReactReduxContext);
    const state = context?.store?.getState();

    return (
        <LinkWithRoutes {...props} routes={routes} history={history} release={state?.release?.version} />
    );
});

Link.displayName = 'Link';

function isModifiedEvent(event: React.MouseEvent<HTMLAnchorElement>) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}
