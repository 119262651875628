import { SERVER_FULL_NAME } from 'mk/autogenerated/translations/structuredData.ed8ddddf54cbda2550e7884f371547f3'
import assetUrl from 'mk/common/assetUrl';
import { photoUrl } from 'mk/photo/photoUrl';
import { ACTIVE_SERVER_ID, BASE_URL } from 'mk/settings';
import { homepageUrl } from 'mk2/apps/home/urls';
import { getAllDescendantsOfTypes, getAttributesDict, removeQuotes } from 'mk2/helpers/article_ast/utils';
import { AST, PhotoEntity } from 'mk2/schemas';
import { Organization, WithContext } from 'schema-dts';

export const organizationModrykonikWithoutContext = (full: boolean = true): Organization => full
    ? {
        // according to https://developers.google.com/search/docs/data-types/article#amp
        '@type': 'Organization',
        '@id': BASE_URL + homepageUrl,
        'url': BASE_URL + homepageUrl,
        'name': SERVER_FULL_NAME,
        'logo': {
            '@type': 'ImageObject',
            'url': assetUrl + `img/logos/ver-4/logo-organization-${ACTIVE_SERVER_ID}@1x.png`,
            'height': '60',
            'width': {201: '269', 202: '269'}[ACTIVE_SERVER_ID],
        },
    } : {
        '@type': 'Organization',
        '@id': BASE_URL + homepageUrl,
    };

export const organizationModrykonik = (full: boolean = true): WithContext<Organization> => {
    return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        ...organizationModrykonikWithoutContext(full) as {},
    };
};

export const findCoverPhotoEntityFromAst = (ast: AST, photos: PhotoEntity[]) => {
    const mediaNodes = getAllDescendantsOfTypes(ast, ['pic']);
    for (const mediaNode of mediaNodes) {
        const attributes = getAttributesDict(mediaNode);
        if (attributes.hasOwnProperty('cover')) {
            const coverPhotoCode = removeQuotes(attributes.hasOwnProperty('code')
                ? attributes.code
                : attributes.hasOwnProperty('cd')
                    ? attributes.cd
                    : null);
            return photos.find((p) => p.code === coverPhotoCode);
        }
    }
    return null;
};

export function getUrlOfCoverImageOrFirstImageOrFallback(ast: AST, photos: PhotoEntity[]): string {
    const coverPhoto: PhotoEntity = findCoverPhotoEntityFromAst(ast, photos);
    return coverPhoto
        ? photoUrl(coverPhoto, 's1600x1600')
        : getUrlOfFirstImageOrFallback(photos);
}

export function getUrlOfFirstImageOrFallback(photos: PhotoEntity | PhotoEntity[]): string {
    const mainPhoto: PhotoEntity = Array.isArray(photos)
        ? (photos.length > 0 ? photos[0] : undefined)
        : photos;

    // according to https://developers.google.com/search/docs/data-types/article#amp
    //
    // Images should be at least 1200 pixels wide.
    return mainPhoto
        ? photoUrl(mainPhoto, 's1600x1600')
        : assetUrl + 'img/general/article-main-img-fallback@1x.png';
}
