import cx from 'classnames';
import { Location } from 'history';
import { AdSlot } from 'mk2/containers/AdSlot/AdSlot';
import { BrandingProps } from 'mk2/pages/Page';
import React from 'react';
import styles from './AdSlotBranding.mscss';

interface OwnProps extends BrandingProps {
    location: Location;
    isMobile: boolean;
}

type Props = OwnProps;

export class AdSlotBranding extends React.PureComponent<Props> {

    public render() {
        const { zone, targeting, location, isMobile } = this.props;
        return (
            <React.Fragment>
                <div className={cx(!isMobile && styles.AdSlotBranding__desktopWrap)}>
                    <AdSlot
                        className={isMobile ? styles.AdSlotBranding__mobile : styles.AdSlotBranding__desktop}
                        zone={isMobile ? `${zone} (Mobile)` : `${zone} (Desktop)`}
                        location={location}
                        targeting={targeting}
                        prefix="mk-branding"
                        marginBottomWithAd={isMobile ? 3 : undefined}
                        marginTopWithAd={isMobile ? 3 : undefined}
                    />
                </div>
            </React.Fragment>
        );
    }
}
