/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const adults_homeware : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SHOPPING_BAGS", "N\u00e1kupn\u00ed ta\u0161ky a voz\u00edky"],
        ["STATIONERY", "Kancel\u00e1\u0159sk\u00e9 a psac\u00ed pot\u0159eby"],
        ["OTHER", "Jin\u00e9"],
    ],
};
