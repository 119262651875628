/**
 * IDEA: Dynamic inject reducers and sagas
 */

import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import has from 'lodash-es/has';
import merge from 'lodash-es/merge';
import set from 'lodash-es/set';
import { LOGIN } from 'mk2/actions';
import { XHRClient } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { createDebugMiddleware } from 'mk2/middlewares/createDebugMiddleware';
import { createSentryMiddleware } from 'mk2/middlewares/createSentryMiddleware';
import { createXhrMiddleware } from 'mk2/middlewares/createXhrMiddleware';
import reducersFactory from 'mk2/reducers';
import initialSagas from 'mk2/sagas';
import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
    Middleware,
    Reducer,
    Store,
    StoreCreator,
} from 'redux';
import createSagaMiddleware, { SagaIterator, Task } from 'redux-saga';

declare const window: any;

type Saga = (...args: any[]) => SagaIterator;
interface InjectedSaga {
    saga: Saga;
    task: Task;
}

interface MKStore<TState> extends Store<TState> {
    getRootTasks(): [Task];
    reloadReducer(key: string, reducer: Reducer<any>);
    reloadSaga(key: string, saga: Saga);
    injectReducer(key: string, reducer: Reducer<any>, force?: boolean);
    injectSaga(key: string, reducer: Saga, force?: boolean);
}

export { MKStore as Store };

const logger = getLogger('store/configureStore');

const configureStore = <TState>(history: History, xhr: XHRClient, initialState?: any): MKStore<TState> => {
    let composeEnhancers;
    if (process.env.NODE_ENV === 'development') {
        composeEnhancers =
            typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                      actionSanitizer: (action) => ({
                          ...action,
                          xhr: '<< XHR >>',
                      }),
                  })
                : compose;
    } else {
        composeEnhancers = compose;
    }

    const initialReducers = reducersFactory(history);

    const sagaMiddleware = createSagaMiddleware({
        onError: (err) => logger.error(err),
    });

    const middlewares: Middleware[] = [
        sagaMiddleware,
        createXhrMiddleware(xhr),
        routerMiddleware(history),
        createSentryMiddleware(),
    ];

    if (process.env.NODE_ENV === 'development') {
        middlewares.push(createDebugMiddleware());
    }

    const createStoreWithMiddleware: StoreCreator = composeEnhancers(applyMiddleware(...middlewares))(createStore);

    const store = createStoreWithMiddleware(
        combineReducersRecurse(
            {
                // Clone
                ...initialReducers,
            },
            initialState,
        ),
    );

    const initialTask = sagaMiddleware.run(initialSagas);

    const injectedReducers = {};
    const injectedSagas: { [key: string]: InjectedSaga } = {};

    Object.defineProperty(store, 'getRootTasks', {
        value: () => [initialTask, ...Object.keys(injectedSagas).map((s) => injectedSagas[s].task)],
    });

    Object.defineProperty(store, 'reloadReducer', {
        value: (key: string, reducer: Reducer<any>) => {
            set(initialReducers, key, reducer);
            store.replaceReducer(combineReducersRecurse(merge({}, initialReducers, injectedReducers), initialState));
        },
    });

    Object.defineProperty(store, 'reloadSaga', {
        value: (key: string, saga: Saga) => {
            if (injectedSagas[key]) {
                injectedSagas[key].task.cancel();
            }
            injectedSagas[key] = {
                saga,
                task: sagaMiddleware.run(saga),
            };
        },
    });

    Object.defineProperty(store, 'injectReducer', {
        value: (key: string, reducer: Reducer<any>, force = false) => {
            const reducers = {
                ...initialReducers,
                ...injectedReducers,
            };

            if (!has(reducers, key) || force) {
                set(injectedReducers, key, reducer);
                store.replaceReducer(
                    combineReducersRecurse(merge({}, initialReducers, injectedReducers), initialState),
                );
            }
        },
    });

    Object.defineProperty(store, 'injectSaga', {
        value: (key: string, saga: Saga, force = false) => {
            const exists = injectedSagas[key];
            if (!exists || force) {
                if (exists) {
                    // cancel running saga
                    exists.task.cancel();
                }

                injectedSagas[key] = {
                    saga,
                    task: sagaMiddleware.run(saga),
                };
            }
        },
    });

    return store as MKStore<TState>;
};

export default configureStore;

function combineReducersRecurse(reducers, preloadedState?: any, key?: string) {
    if (typeof reducers === 'function') {
        return (state = preloadedState, action) => {
            switch (action.type) {
                case LOGIN:
                    return reducers(
                        // NOTE: We want to reset all reducers except these globals
                        //  - they should handle 'LOGIN' action themself
                        ['router', 'toast', 'request', 'response', 'form', 'toastr'].includes(key) ? state : undefined,
                        action,
                    );
                default:
                    return reducers(state, action);
            }
        };
    }

    if (typeof reducers === 'object') {
        // Walk whole tree
        return combineReducers(
            Object.keys(reducers).reduce((acc, cur) => {
                acc[cur] = combineReducersRecurse(
                    reducers[cur],
                    preloadedState ? preloadedState[cur] : undefined,
                    key ? `${key}.${cur}` : cur,
                );
                return acc;
            }, {}),
        );
    }

    throw new Error('Invalid item in reducer tree');
}
