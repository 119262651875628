// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const FROM = "Od";
export const NO = "Ne";
export const number_MONTHS = ["%(number)s m\u011bs\u00edc", "%(number)s m\u011bs\u00edce", "%(number)s m\u011bs\u00edc\u016f"];
export const STROLLER_FEATURE_AVAILABILITY_ALL = "v\u0161echny (i nevyr\u00e1b\u011bn\u00e9)";
export const STROLLER_FEATURE_AVAILABILITY_AVAILABLE = "v\u0161echny aktu\u00e1ln\u011b dostupn\u00e9";
export const STROLLER_FEATURE_AVAILABILITY_ONLY_NEW = "jen nov\u00e9";
export const STROLLER_FEATURE_AVAILABILITY_ONLY_OLD = "jen bazarov\u00e9";
export const STROLLER_FEATURE_PRICE_RANGE_1 = "M\u00e9n\u011b ne\u017e 7 500 K\u010d";
export const STROLLER_FEATURE_PRICE_RANGE_2 = "7 500 K\u010d - 10 000 K\u010d";
export const STROLLER_FEATURE_PRICE_RANGE_3 = "10 000 K\u010d - 12 500 K\u010d";
export const STROLLER_FEATURE_PRICE_RANGE_4 = "12 500 K\u010d - 17 500 K\u010d";
export const STROLLER_FEATURE_PRICE_RANGE_5 = "17 500 K\u010d - 25 000 K\u010d";
export const STROLLER_FEATURE_PRICE_RANGE_6 = "25 000 K\u010d a v\u00edce";
export const STROLLER_FEATURE_RECOMMENDED_AGE_MORE_MONTHS = "a v\u00edce";
export const STROLLERS_SEARCH_FACET_BRAND = "Zna\u010dka";
export const STROLLERS_SEARCH_FULLTEXT = "Hledej v n\u00e1zvu a popisu ko\u010d\u00e1rk\u016f";
export const TO = "Do";
export const YES = "Ano";
