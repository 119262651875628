import omit from 'lodash-es/omit';
import { ZoneTargeting } from 'mk2/containers/AdSlot/interfaces';
import {
    AdSlotRefreshAction,
    AdSlotRegisterAction,
    AdSlotRenderEndedAction,
    AdSlotUnregisterAction,
    AD_SLOT_REGISTER,
    AD_SLOT_RENDER_ENDED,
    AD_SLOT_UNREGISTER,
} from 'mk2/containers/AdSlot/AdSlot.actions';

export interface AdSlotState {
    [slotId: string]: Slot;
}

interface Slot {
    zone: string;
    targeting: ZoneTargeting;

    lastRefresh?: Date;
}

type AdSlotActions = AdSlotRegisterAction
    | AdSlotUnregisterAction
    | AdSlotRenderEndedAction
    | AdSlotRefreshAction;

export const adSlotReducer = (state: AdSlotState = {}, action: AdSlotActions) => {
    switch (action.type) {
        case AD_SLOT_REGISTER:
            if (state[action.slotId]) {
                // Ignore if already registered
                return state;
            }

            return {
                ...state,
                [action.slotId]: {
                    zone: action.zone,
                    targeting: action.targeting,
                },
            };
        case AD_SLOT_UNREGISTER:
            if (!state[action.slotId]) {
                // Not registered
                return state;
            }

            return omit(state, action.slotId);
        case AD_SLOT_RENDER_ENDED:
            if (!state[action.slotId]) {
                // Not registered
                return state;
            }

            return {
                ...state,
                [action.slotId]: {
                    ...state[action.slotId],
                    lastRefresh: new Date(),
                },
            };
        default:
            return state;
    }
};
