import { ItemToTagSchema, WikiArticleEntitledVendorSchema } from 'mk2/apps/adminclub/schemas';
import {
    BazaarMessagePhotoSchema,
    BazaarMessageSchema,
    BazaarOrderSchema,
    BuyerSchema,
    MarkSchema,
    ProductPhotoFileSchema,
    ProductSchema,
    ReviewSchema,
    SellersCustomerSchema,
    SellerSchema,
    XMLImportFeedSchema,
    XMLImportItemSchema,
    XMLImportStatusSchema,
} from 'mk2/apps/bazaar/schemas';
import {
    BlogFeedItemSchema,
    BlogPostSchema,
    CommentSchema,
    GroupInvitationSchema,
    GroupMemberSchema,
    GroupSchema,
    ImageSchema,
} from 'mk2/apps/blogs/schemas';
import {
    AmbassadorItemsSchema,
    AmbassadorReportSchema,
    AskAnExpertReportSchema,
    OrderItemSchema,
    OrderReportSchema,
    TestingItemSchema,
    TestingReportSchema,
    WikiExperienceReferenceSchema,
} from 'mk2/apps/bo/schemas';
import {
    BrandDaysCampaignSchema,
    CategorySchema,
    ContestSchema,
    CounsellingCounsellorSchema,
    CounsellingSchema,
    ForumMessagePhotoSchema,
    ForumMessageSchema,
    PostSchema,
    ProductTestingPhotoSchema,
    ProductTestingSchema,
    TopicModeratorsDetailsSchema,
    TopicModeratorInvitationSchema,
    TopicModeratorSchema,
    TopicSchema,
} from 'mk2/apps/forum/schemas';
import { HomeCounsellingSchema, HomePhotoblogPostSchema, HomePostSchema } from 'mk2/apps/home/schemas';
import { KidSchema, ProfileEntity, ProfileSchema } from 'mk2/apps/settings/schemas';
import {
    StrollerBrandSchema,
    StrollerPatternSchema,
    StrollerPhotoSchema,
    StrollerQuestionSchema,
    StrollerSchema,
    StrollerUserReviewPhotoSchema,
    StrollerUserReviewSchema,
    StrollerVariantsPhotosSchema, StrollerVariantSchema,
} from 'mk2/apps/strollers/schemas';
import { TicketMessageSchema, UserStatsSchema } from 'mk2/apps/users/schemas';
import { MentionSchema } from 'mk2/apps/wall/schemas';
import {
    AST,
    ASTAdditionalDataFromServer,
    ExperiencePhotoSchema,
    ExperienceSchema,
    ProductCatalogImportSchema,
    WikiArticleSchema,
} from 'mk2/apps/wiki/schemas';
import { Count } from 'mk2/components/NotificationsBadge';
import {
    ComplaintSchema,
    ComplaintTargetSchema,
    ComplaintTargetType,
    ComplaintWithoutTargetSchema,
} from 'mk2/containers/Complaint/Complaint.schemas';
import { DeletionSchema, DeletionTargetSchema, DeletionTargetType } from 'mk2/containers/Deletion/Deletion.schemas';
import { createLikeableSchema } from 'mk2/containers/Like/Like.schemas';
import { schema } from 'normalizr';

/**
 * Keep relations of schemas here, do not import this file (mk2/schemas.ts) from other schemas.ts.
 *
 * TODO: It would be great if all entites has createdAt, updatedAt and syncedAt Dates so that we can make
 * queries that will fetch all changed data and also we can detect conflicts
 */
export interface Entity {
    readonly id: number;
}

export type NormalizedEntity<T extends Entity> = {
    [P in keyof T]: T[P] extends Entity ? T[P]['id'] : T[P];
};

export interface EntityWithSlug extends Entity {
    slug: string;
}

export enum Plan {
    FREE = 'free',
    PLUS = 'plus',
    PRO = 'pro',
}

export enum Role {
    USER,
    MODERATOR,
    ADMIN,
}

export interface UserEntity extends Entity {
    username: string;
    activePlan: Plan;
    avatar: string;
    avatarCampaign: string;
    phoneVerified: boolean;
    gender: string;
    isBlocked: boolean;
    isBlockedSelf: boolean;
    likesCount: number;
    isAmbassadorOfBrandId: number;
    isUntrustworthy: boolean;
    isVerifiedOrganization: boolean;
    isOfficialProfile: boolean;
}

export interface UserDomainDataEntity extends Entity {
    domainText: string;
    userText: string;
    categoryText: string;
}

export interface ProVendorEntity extends Entity {
    name: string;
    address: string;
    phone: string;
    email: string;
    web: string;
    facebook: string;
    card_text: string;
    logoPhoto: PhotoEntity;
}

export interface PhotoEntity extends Entity {
    // rovnaka hodnota ako PhotoFile.code
    readonly code: string;
    readonly width: number;
    readonly height: number;
    readonly aspect: PhotoAspect;
    readonly mimetype: PhotoMimetype;
    readonly versions: string[];
}

export interface PhotoPreviewEntity extends Entity {
    readonly fileName: string;
    readonly imgBase64Data: string;
    readonly originalWidth: number;
    readonly originalHeight: number;
    readonly aspect: PhotoAspect;
    readonly mimetype: PhotoMimetype;
}

export enum PhotoAspect {
    PORTRAIT = 0,
    LANDSCAPE,
    SQUARE,
}

export enum PhotoMimetype {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif',
    WEBP = 'image/webp',
}

export enum PhotoOrientation {
    NORMAL = 0,
    ROTATED_90,
    ROTATED_180,
    ROTATED_270,
}

export interface OldPhotoUploadResponse {
    token: string;
    photo_code: string;
    photo_versions: {
        [version: string]: {
            url: string;
            height: number;
            width: number;
        };
    };
    photo: PhotoEntity;
}

export interface CountryEntity extends Entity {
    name: string;
}

export const UserSchema = new schema.Entity('auth.user');
export const PhotoSchema = new schema.Entity('system.photofile');
export const PhotoPreviewSchema = new schema.Entity('photoPreview');
export const ProVendorSchema = new schema.Entity('users.vendor', {
    logoPhoto: PhotoSchema,
});
export const CountrySchema = new schema.Entity('geo.country');

export interface SubscribedCounsellingsCounts {
    [topicId: number]: Count;
}

export interface PageHeaderUserData {
    heartsCount: number;
    unreadMails: number;
    newBazaarEvents: number;
    newBazaarSell: number;
    allBazaarSell: number;
    wallStatusNews: number;
    wishlistUpdates: number;
    groupsCount: number;
    newContests: number;
    subscribedCounsellings: SubscribedCounsellingsCounts;
    newTestingCalls: number;
}

export enum HeaderMenuItem {
    UserPhoneVerification = 'phone-verification',
    UserSettings = 'settings',
    UserLogin = 'login',
    UserSignup = 'signup',
    BoOrders = 'orders',
    BoTestings = 'testings',
    BazaarSellerSearch = 'heading-sellers',
    BazaarWishlist = 'wishlist',
    BazaarShopping = 'shopping',
    BazaarMyBazaar = 'bazaar',
    BazaarMyWatchdogs = 'watchdogs',
    BazaarFaq = 'faq',
    BazaarAddItem = 'add-item',
    BlogFeeds = 'feeds',
    BlogsMyActivity = 'blogs-my-activity',
    BlogsUsersDirectory = 'blogs-users-directory',
    BlogsFriends = 'blogs-friends',
    BlogsFlagged = 'blogs-flagged',
    AdminclubBazaar = 'adminclub-bazaar',
    AdminclubConceptsHierarchy = 'adminclub-concepts-hierarchy',
    AdminclubForum = 'adminclub-forum',
    AdminclubForumCategories = 'adminclub-forum-categories',
    AdminclubHomepageFeedPosts = 'adminclub-homepage-feed-posts',
    AdminclubProductCatalogs = 'adminclub-product-catalogs',
    AdminclubSeoRenaming = 'adminclub-seo-renaming',
    AdminclubStrollers = 'adminclub-strollers',
    AdminclubXMLImport = 'adminclub-xml-import',
    DashSeoPerformance = 'dash-seo-performance',
    DashBazaarDashboard = 'dash-bazaar-dashboard',
    DashSuperset = 'dash-superset',
    StrollersHowToPick = 'strollers-how-to-pick',
    StrollersArticlesAndReviews = 'strollers-articles-and-reviews',
    StrollersCounselling = 'strollers-counselling',
    StrollersForum = 'strollers-forum',
}

export interface MenuSelection {
    activeSection?: string;
    activeItem?: string;
}

export interface ConceptEntity extends Entity {
    readonly articleId: number;
    readonly path: number[];
}

export const ConceptSchema = new schema.Entity('concepts');

export interface ContentItemEntity extends Entity {
    readonly key: string;
    readonly content: string;
    readonly contentAST: AST;
    readonly contentASTAdditionalData: ASTAdditionalDataFromServer;
    readonly photos: PhotoEntity[];
}

export const ContentItemSchema = new schema.Entity('cms.contentitem');

ContentItemSchema.define({
    photos: [PhotoSchema],
});

CommentSchema.define({
    authorUser: UserSchema,
    targetOwner: UserSchema,
    photos: [PhotoSchema],
    deletedBy: UserSchema,
    extraEntitiesOfArticle: [WikiArticleSchema],
    extraEntitiesOfStroller: [StrollerSchema],
    extraEntitiesOfPost: [BlogPostSchema],
});

ImageSchema.define({
    photo: PhotoSchema,
});

GroupSchema.define({
    coverPhoto: PhotoSchema,
    deletedBy: UserSchema,
});

GroupMemberSchema.define({
    user: UserSchema,
    blockedByUser: UserSchema,
});

GroupInvitationSchema.define({
    user: UserSchema,
    inviter: UserSchema,
});

BlogPostSchema.define({
    author: UserSchema, // Deprecated
    user: UserSchema,
    photos: [PhotoSchema],
    deletedBy: UserSchema,
    extraEntitiesOfArticle: [WikiArticleSchema],
    extraEntitiesOfStroller: [StrollerSchema],
    extraEntitiesOfPost: [BlogPostSchema],
    experience: ExperienceSchema,
    review: StrollerUserReviewSchema,
    stroller: StrollerSchema,
});

UserStatsSchema.define({
    user: UserSchema,
});

HomePostSchema.define({
    photo: PhotoSchema,
});

HomePhotoblogPostSchema.define({
    photo: PhotoSchema,
});

PostSchema.define({
    extraEntitiesOfArticle: [WikiArticleSchema],
    extraEntitiesOfStroller: [StrollerSchema],
    extraEntitiesOfPost: [BlogPostSchema],
    author: UserSchema,
    photos: [PhotoSchema],
    deletedBy: UserSchema,
});

CategorySchema.define({
    coverPhoto: PhotoSchema,
});

TopicModeratorSchema.define({
    user: UserSchema,
});

TopicSchema.define({
    moderators: [UserSchema],
    coverPhoto: PhotoSchema,
    deletedBy: UserSchema,
});

PostSchema.define({
    similarPosts: [PostSchema],
});

TopicModeratorInvitationSchema.define({
    invitee: UserSchema,
    inviter: UserSchema,
});

TopicModeratorsDetailsSchema.define({
    allModerators: [
        new schema.Object({
            user: UserSchema,
        }),
    ],
});

ProductTestingPhotoSchema.define({
    photofile: PhotoSchema,
});

ProductTestingSchema.define({
    photos: [PhotoSchema],
    user: UserSchema,
});

CounsellingSchema.define({
    counsellor: UserSchema,
    counsellorAvatarPhoto: PhotoSchema,
});

ContestSchema.define({
    photo: PhotoSchema,
});

BrandDaysCampaignSchema.define({
    user: UserSchema,
});

ForumMessageSchema.define({
    authorUser: UserSchema,
    targetOwner: UserSchema,
    photos: [PhotoSchema],
    deletedBy: UserSchema,
    extraEntitiesOfArticle: [WikiArticleSchema],
    extraEntitiesOfStroller: [StrollerSchema],
    extraEntitiesOfPost: [BlogPostSchema],
});

ForumMessagePhotoSchema.define({
    photofile: PhotoSchema,
});

CounsellingCounsellorSchema.define({
    user: UserSchema,
    avatarPhoto: PhotoSchema,
});

ItemToTagSchema.define({
    user: UserSchema,
});

WikiArticleEntitledVendorSchema.define({
    vendor: ProVendorSchema,
    vendorUser: UserSchema,
    article: WikiArticleSchema,
});

WikiExperienceReferenceSchema.define({
    author: UserSchema,
});

AmbassadorItemsSchema.define({
    screenshotPhoto_post1: PhotoSchema,
    screenshotPhoto_post2: PhotoSchema,
});

TestingItemSchema.define({
    screenshotPhoto_comments: PhotoSchema,
    screenshotPhoto_google: PhotoSchema,
});

OrderItemSchema.define({
    screenshotPhoto: PhotoSchema,
    screenshotPhotos: new schema.Values(PhotoSchema),
});

OrderReportSchema.define({
    items: new schema.Array({
        desktopScreenshotPhoto: PhotoSchema,
        mobileScreenshotPhoto: PhotoSchema,
        reviewArticle: WikiArticleSchema,
        parentReviewArticle: WikiArticleSchema,
    }),
});

TestingReportSchema.define({
    testers: [UserSchema],
    bestTests: new schema.Object({
        screenshot: PhotoSchema,
    }),
    google: new schema.Object({
        screenshot: PhotoSchema,
    }),
    facebook: new schema.Object({
        screenshot: PhotoSchema,
    }),
    comments: new schema.Object({
        screenshot: PhotoSchema,
    }),
});

AmbassadorReportSchema.define({
    avatarPhoto: PhotoSchema,
    successfulTopics: new schema.Array({
        screenshot: PhotoSchema,
    }),
    adhocScreenshot1: PhotoSchema,
    adhocScreenshot2: PhotoSchema,
});

AskAnExpertReportSchema.define({
    avatarPhoto: PhotoSchema,
    screenshot: PhotoSchema,
    topQuestion: PostSchema,
});

SellerSchema.define({
    user: UserSchema,
});

ProductSchema.define({
    images: [PhotoSchema],
});

ProductPhotoFileSchema.define({
    photofile: PhotoSchema,
});

ReviewSchema.define({
    reviewed: UserSchema,
    reviewer: UserSchema,
    photo: PhotoSchema,
    replyPhoto: PhotoSchema,
});

SellersCustomerSchema.define({
    buyer: UserSchema,
    seller: UserSchema,
});

BazaarMessageSchema.define({
    seller: UserSchema,
    photos: [PhotoSchema],
});

BuyerSchema.define({
    user: UserSchema,
});

BazaarMessagePhotoSchema.define({
    photofile: PhotoSchema,
});

MarkSchema.define({
    admin: UserSchema,
});

BlogFeedItemSchema.define({
    user: UserSchema,
    profile: UserSchema,
});

ExperienceSchema.define({
    authorUser: UserSchema,
    vendorReplyUser: UserSchema,
    vendorReplyVendor: ProVendorSchema,
});

ExperiencePhotoSchema.define({
    photofile: PhotoSchema,
});

WikiArticleSchema.define({
    author: UserSchema,
    photos: [PhotoSchema],
    extraEntitiesOfArticle: [WikiArticleSchema],
    extraEntitiesOfStroller: [StrollerSchema],
    extraEntitiesOfPost: [BlogPostSchema],
});

ProductCatalogImportSchema.define({
    user: UserSchema,
});

ProfileSchema.define({
    avatar: PhotoSchema,
});

KidSchema.define({
    photo: PhotoSchema,
});

MentionSchema.define({
    authorUser: UserSchema,
    otherAuthors: [UserSchema],
    photo: PhotoSchema,
});

HomeCounsellingSchema.define({
    posts: [PostSchema],
});

StrollerUserReviewPhotoSchema.define({
    photoFile: PhotoSchema,
});

StrollerUserReviewSchema.define({
    user: UserSchema,
    stroller: StrollerSchema,
    photos: [StrollerUserReviewPhotoSchema],
    likeable: createLikeableSchema('strollers.strolleruserreview'),
});

StrollerSchema.define({
    photos: [StrollerPhotoSchema],
    brand: StrollerBrandSchema,
    reviews: [StrollerUserReviewSchema],
    descriptionPhotos: [PhotoSchema],
});

StrollerVariantSchema.define({
    photos: [StrollerVariantsPhotosSchema],
});

ComplaintSchema.define({
    user: UserSchema,
});

ComplaintWithoutTargetSchema.define({
    user: UserSchema,
});

ComplaintTargetSchema.define({
    [ComplaintTargetType.ForumMessage]: ForumMessageSchema,
    [ComplaintTargetType.ForumThread]: PostSchema,
    [ComplaintTargetType.PhotoblogImage]: ImageSchema,
    [ComplaintTargetType.PhotoblogPost]: BlogPostSchema,
    [ComplaintTargetType.PhotoblogComment]: CommentSchema,
    [ComplaintTargetType.BazaarProduct]: ProductSchema,
});

DeletionSchema.define({
    user: UserSchema,
});

DeletionTargetSchema.define({
    [DeletionTargetType.PhotoblogPost]: BlogPostSchema,
    [DeletionTargetType.PhotoblogComment]: CommentSchema,
});

XMLImportFeedSchema.define({
    bazaar: SellerSchema,
    lastXmlImportStatus: XMLImportStatusSchema,
    eshopLogoPhoto: PhotoSchema,
});

XMLImportItemSchema.define({
    xmlImportFeed: XMLImportFeedSchema,
    stroller: StrollerSchema,
    strollerBrand: StrollerBrandSchema,
    strollerVariant: StrollerVariantSchema,
});

StrollerPatternSchema.define({
    patternImage: PhotoSchema,
});

BazaarOrderSchema.define({
});

TicketMessageSchema.define({
   photos: [PhotoSchema],
});

export const load = () => 'loaded';

export * from 'mk2/apps/adminclub/schemas';
export * from 'mk2/apps/bazaar/schemas';
export * from 'mk2/apps/blogs/schemas';
export * from 'mk2/apps/bo/schemas';
export * from 'mk2/apps/forum/schemas';
export * from 'mk2/apps/home/schemas';
export * from 'mk2/apps/settings/schemas';
export * from 'mk2/apps/strollers/schemas';
export * from 'mk2/apps/users/schemas';
export * from 'mk2/apps/wall/schemas';
export * from 'mk2/apps/wiki/schemas';
export * from 'mk2/containers/Complaint/Complaint.schemas';
export * from 'mk2/containers/Deletion/Deletion.schemas';
