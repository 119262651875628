/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const sports_bags : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("4F"),
        d("adidas"),
        d("Amix Nutrition"),
        d("aress"),
        d("consigned"),
        d("crossroad"),
        d("Czech Virus"),
        d("domyos"),
        d("energetics"),
        d("Extrifit"),
        d("fila"),
        d("fitforce"),
        d("Fitness4U"),
        d("Gaiam"),
        d("GymBeam"),
        d("kari traa"),
        d("kensis"),
        d("loap"),
        d("lotto"),
        d("MadMax"),
        d("myprotein"),
        d("nike"),
        d("Nutrend"),
        d("odlo"),
        d("OLP"),
        d("oneil"),
        d("power system"),
        d("Pro Touch"),
        d("puma"),
        d("reebok"),
        d("Spokey"),
        d("the north face"),
        d("umbro"),
        d("under armour"),
        d("urbanball"),
        d("vans"),
        d("venum"),
        d("willard"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
