import { ACTIVE_SERVER_ID } from 'mk/settings';
import React from 'react';

import assetUrl from 'mk/common/assetUrl';
import { Img } from 'mk2/components/Img';

const CUSTOM_RATIO = {
    1: 300 / 262,
    2: 300 / 268,
    3: 253 / 300,
    10: 290 / 300,
    11: 300 / 268,
    12: 292 / 300,
    13: 285 / 300,
    14: 290 / 300,
    15: 453 / 300,
    28: 455 / 300,
    29: 429 / 300,
    30: 440 / 300,
    31: 463 / 300,
    32: 427 / 300,
    33: 446 / 300,
    34: 422 / 300,
    35: 421 / 300,
    36: 257 / 300,
    37: 378 / 300,
    38: 268 / 300,
    39: 279 / 300,
    40: 419 / 300,
    41: 390 / 300,
    42: 268 / 300,
    43: 292 / 300,
};

export enum StickerSize {
    Icon = 25,
    Small = 75,
    Big = 100,
    Bigger = 120,
    XL = 180,
}

interface OwnProps {
    className?: string;
    id: number;
    size: StickerSize;

    /** If true, image is loaded only if it comes to viewport */
    lazy?: boolean;

    onClick?();
}

type Props = OwnProps;

class Sticker extends React.PureComponent<Props> {
    public static displayName = 'Sticker';

    public render() {
        const { className, id, size, lazy, onClick } = this.props;

        return (
            <Img
                className={className}
                src={`${assetUrl}img/stickers/${ACTIVE_SERVER_ID}/${id}.png`}
                width={size * (CUSTOM_RATIO[id] || 1)}
                height={size}
                onClick={onClick}
                lazy={lazy}
            />
        );
    }
}

export default Sticker;
