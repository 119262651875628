import useIsomorphicLayoutEffect from 'mk2/hooks/useIsomorphicLayoutEffect';
import { useCallback, useRef, DependencyList } from 'react';

function useEventCallback<T extends (...args: any[]) => any>(fn: T, dependencies: DependencyList): T {
    const ref = useRef<T | ((...args: any[]) => never)>((...args) => {
        throw new Error('Cannot call an event handler during rendering.');
    });

    useIsomorphicLayoutEffect(() => {
        ref.current = fn;
    }, [fn, ...dependencies]);

    return useCallback((...args: any[]) => {
        return ref.current(...args);
    }, [ref]) as T;
}

export default useEventCallback;
