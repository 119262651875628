import { PostEntity } from 'mk2/apps/forum/schemas';
import { AST, Entity, PhotoEntity, UserEntity, WikiArticleEntity } from 'mk2/schemas';
import { schema } from 'normalizr';

export interface CustomerEntity extends Entity {
    name: string;
    address: string;
    city: string;
    zip: string;
    country: number;
    regid: string;
    vatid: string;
}

export const CustomerSchema = new schema.Entity('customer');

export interface AgencyEntity extends Entity {
    customer: CustomerEntity;
    notes: string;
}

export const AgencySchema = new schema.Entity('agencies', {
    customer: CustomerSchema,
});

export interface BoOrderAttachment extends Entity {
    filename: string;
}

export interface BoOrderHistory extends Entity {
    changeTime: string;
    diff: string;
    username: string;
}

// keep in sync with OrderItem.PRODUCT_TYPE_* in django
export enum BoOrderItemProductType {
    AMBASSADOR = 'ambassador',
    BILLBOARD = 'billboard',
    BRANDING = 'branding',
    CONTEST = 'contest',
    COUNSELING = 'counseling',
    DAYS_WITH = 'days_with',
    HALFPAGE = 'halfpage',
    HALFPAGE_VIDEO = 'halfpage_video',
    IP = 'ip',
    IP_REMARKETING = 'ip_remarketing',
    LARGE_RECTANGLE = 'large_rectangle',
    LARGE_RECTANGLE_VIDEO = 'large_rectangle_video',
    NATIVE_ARTICLE = 'native_article',
    NATIVE_CONTENT_AD = 'native_content_ad',
    OUTSTREAM_VIDEO = 'outstream_video',
    PR_ARTICLE = 'pr_article',
    PRO_ESHOP = 'pro_eshop',
    REDACTION_ARTICLE = 'redaction_article',
    RESEARCH = 'research',
    REVIEWS_DETAILED_REVIEWS = 'reviews_detailed_reviews',
    REVIEWS_PAGE = 'reviews_page',
    REVIEWS_PROMO = 'reviews_promo',
    REVIEWS_PROMO_EXTRA = 'reviews_promo_extra',
    REVIEWS_REDACTION_TESTING = 'reviews_redaction_testing',
    REVIEWS_REDACTION_TESTING_WITH_EXPS = 'reviews_redaction_testing_with_exps',
    REVIEWS_SAMPLING = 'reviews_sampling',
    REVIEWS_YOUTUBE = 'reviews_youtube',
    SEO_ARTICLE = 'seo_article',
    TESTING = 'testing',
    UNKNOWN = 'unknown',
    YOUTUBE = 'youtube',
}

// keep in sync with OrderItem.INVOICE_MODE_* in django
export enum OrderItemInvoiceMode {
    Month = 0,
    MonthsAliquote = 1,
    MonthsEqually = 2,
    Ignore = -1,
}

export interface BoAmbassadorOrderItem extends Entity {
    year: number;
    month: number;
    reportName: string;
    summary: string;
    post1Url: string;
    post2Url: string;
    screenshotPhoto_post1: PhotoEntity;
    screenshotPhoto_post2: PhotoEntity;
    adhoc1Caption: string;
    adhoc2Caption: string;
    screenshotPhoto_adhoc1: PhotoEntity;
    screenshotPhoto_adhoc2: PhotoEntity;
}

export interface BoTestingOrderItem extends Entity {
    reportName: string;
    summary: string;
    hashtag: string;
    bestTestUrl: string;
    googleKeyword: string;
    googlePosition: string;
    facebookUsersImpressed: number;
    facebookClicks: number;
    bestCommentIds: string;
    testerUsernames: string;
    screenshotPhoto_comments: PhotoEntity;
    screenshotPhoto_google: PhotoEntity;
}

export interface BlogsArticleReferenceEntity extends Entity {
    titleAST: AST;
    url: string;
    createdAt: string;
}

export const BlogsArticleReferenceSchema = new schema.Entity('bo.blogsarticleref', {});

export interface WikiArticleReferenceEntity extends Entity {
    title: string;
    url: string;
    slug: string;
    createdAt: string;
}

export const WikiArticleReferenceSchema = new schema.Entity('bo.wikiarticleref', {});

export interface WikiExperienceReferenceEntity extends Entity {
    title: string;
    body: string;
    author: UserEntity;
    starsCount: number;
    createdAt: string;
    deletedAt: string;
}

export const WikiExperienceReferenceSchema = new schema.Entity('bo.wikiexperienceref', {
});

export interface BoOrderItem extends Entity {
    name: string;
    productType: BoOrderItemProductType;
    amount: MoneyAmount;
    dateFrom: any;
    dateTo: any;
    invoiceMode: OrderItemInvoiceMode;
    invoiceMonth: string;
    invoiceMonthFrom: string;
    invoiceMonthTo: string;
    invoiceMonths: string;
    agencyBonus: number;
    monthlyExpense: number;
    admLineitemId: number;
    admImpressions: number;
    googleKeyword: string;
    googlePosition: string;
    admReach: number;
    facebookUsersImpressed: number;
    facebookClicks: number;
    trackedHashtag: string;
    askAnExpertUrl: string;
    frThreadUrl: string;
    pbArticleUrl: string;
    wkArticleRef: WikiArticleReferenceEntity;
    parentWkArticleRef?: WikiArticleReferenceEntity;
    wkReviewRefs: WikiExperienceReferenceEntity[];
    clickLinkUrls: string[];
    mailingCampaignId: number;
    mailingCampaignAmount: number;
    mailingCampaignRemarketingHashtag: string;
    brandDaysCampaignId: number;
    videoUrl: string;
    ambBrandId: number;
    screenshotPhoto?: PhotoEntity;
    screenshotPhotos: { [type: string]: PhotoEntity };
    ambassadorItems: { [yearMonth: string]: BoAmbassadorOrderItem };
    testingItem: BoTestingOrderItem;
    fieldsNotCompletedBySales: string[];
    fieldsNotCompletedByProduction: string[];
}

// keep in sync with order_invoicing_plan_to_json() in django
export interface OrderInvoicingPlanEntity extends Entity {
    invoiceMonth: string;
    invoiceAmount: MoneyAmount;
    invoiceItems: Array<{
        name: string;
        dfrom: string;
        dto: string;
        amount: MoneyAmount;
        annualAgencyBonus: number;
        orderItemId: number;
    }>;
    campaignName: string;
    deliveredDfrom: string;
    deliveredDto: string;
    commentForCustomer: string;
    orderId: number;
    approvedOn: string;
    invoicedOn: string;
}

export const OrderInvoicingPlanSchema = new schema.Entity('orderInvoicingPlans', {});

export interface OrderInvoicingError {
    invoiceId: number;
    orderId: number;
    orderInvoicingPlanId: number;

    invoiceAmount: MoneyAmount;
    invoiceItemsAmount: MoneyAmount;
    invoicingPlanAmount: MoneyAmount;
}

export interface OrderInvoicingPlanningError {
    orderId: number;

    orderAmount: MoneyAmount;
    invoicingPlansAmount: MoneyAmount;
    invoicingPlansItemsAmount: MoneyAmount;
}

export interface OrderAgencyBonus {
    agencyId: number;
    orderId: number;
    invoiceId: number;
    items: Array<{
        orderItemId: number;
        bonusPercentage: number;
        bonusAmount: MoneyAmount;
    }>;
}

export interface OrderAgencyBonusError {
    orderId: number;
    orderItemId: number;

    orderItemBonus: number;
    invoiceId: number;
    invoiceItemBonus: number;

    orderInvoicingPlanId: number;
    orderInvoicingPlanItemId: number;
    planItemBonus: number;
}

export interface OrderEntity extends Entity {
    nqProposalId: number;
    pdDealId: number;
    name: string;
    createdDate: string;
    deletedAt: string;
    ownerId: number;
    ownerName: string;
    comment: string;
    commentInvoice: string;
    campaignNameInvoice: string;
    isBarter: boolean;
    isProgrammatic: boolean;
    signedDate: string;
    summary: string;
    items: BoOrderItem[];
    attachments: BoOrderAttachment[];
    lastDfpFetchDate: string;
    reportName: string;
    agency: AgencyEntity;
    invoicingPlan: OrderInvoicingPlanEntity[];
    admOrderId: number;
}

const Attachment = new schema.Entity('boOrderAttachments', {
});

export const AmbassadorItemsSchema = new schema.Values({
});

export const TestingItemSchema = new schema.Object({
});

export const OrderItemSchema = new schema.Entity('boOrderItems', {
    ambassadorItems: AmbassadorItemsSchema,
    testingItem: TestingItemSchema,
    wkArticleRef: WikiArticleReferenceSchema,
    parentWkArticleRef: WikiArticleReferenceSchema,
});

export const OrderSchema = new schema.Entity('boOrders', {
    attachments: [Attachment],
    items: [OrderItemSchema],
    agency: AgencySchema,
});

export const OrderHistorySchema = new schema.Entity('boOrderHistories', {
});

enum InvoiceType {
    Invoice,
    Cancellation,
    CreditNote,
}

export interface MoneyAmount {
    value: number;
    currency: string;
}

export interface InvoiceEntity extends Entity {
    number: number;
    type: InvoiceType;
    createdDate: string;
    deliveredDate: string;
    dueDate: string;
    amount: MoneyAmount;
    vat: MoneyAmount;
    text: string;
    orderId?: number;
    orderInvoicingPlanId?: number;
}

export const InvoiceSchema = new schema.Entity('invoices');

type DayImpression = [string, number];

export type DayImpressions = DayImpression[];

export interface OrderReportDeviceStats {
    lineItemId: number;
    desktop: {
        clicks: number;
        impressions: number;
        avgViewRate?: number; // native video metric
        avgViewTime?: number; // native video metric
    };
    mobile: {
        clicks: number;
        impressions: number;
        avgViewRate?: number; // native video metric
        avgViewTime?: number; // native video metric
    };
    total?: {
        // the total value of 'impressions' and 'clicks' are just the sum of desktop.* + mobile.*

        // native video metric fields
        avgViewRate?: number;
        avgViewTime?: number;
        viewed000: number; // start
        viewed025: number; // first quartile
        viewed050: number; // midpoint
        viewed075: number; // third quartile
        viewed100: number; // complete
    };
}

export interface OrderReportBannerItem {
    id: number;
    type:
        BoOrderItemProductType.BILLBOARD | BoOrderItemProductType.BRANDING |
        BoOrderItemProductType.HALFPAGE | BoOrderItemProductType.HALFPAGE_VIDEO |
        BoOrderItemProductType.LARGE_RECTANGLE | BoOrderItemProductType.LARGE_RECTANGLE_VIDEO |
        BoOrderItemProductType.NATIVE_CONTENT_AD;
    desktopScreenshotPhoto: PhotoEntity;
    mobileScreenshotPhoto: PhotoEntity;
    dateFrom: string;
    dateTo: string;
    dailyImpressions: DayImpressions;
    plannedImpressions: number;
    deviceStats: OrderReportDeviceStats;
    hasCompletedFields: boolean; // ci uz su zadane vsetky potrebne udaje od sales a production
    hasCompletedStats: boolean;  // ci uz su k dispozicii statistiky za cele obdobie
}

export interface OrderReportArticleItem {
    id: number;
    type:
        BoOrderItemProductType.NATIVE_ARTICLE | BoOrderItemProductType.PR_ARTICLE |
        BoOrderItemProductType.REDACTION_ARTICLE | BoOrderItemProductType.SEO_ARTICLE;
    clicks: number;
    desktopScreenshotPhoto: PhotoEntity;
    mobileScreenshotPhoto: PhotoEntity;
    facebookScreenshotPhoto: PhotoEntity;
    title: string;
    publishedDate: string;
    viewsUnique: number;
    viewsTotal: number;
    url: string;
    hasCompletedFields: boolean; // ci uz su zadane vsetky potrebne udaje od sales a production
    hasCompletedStats: boolean;  // ci uz su k dispozicii statistiky za cele obdobie
}

export interface OrderReportContestItem {
    id: number;
    type: BoOrderItemProductType.CONTEST;
    dateFrom: string;
    dateTo: string;
    title: string;
    url: string;
    notifiedUsers: number;
    involvedUsers: number;
    uniqueViews: number;
    clicks: number;
    clientUrl: string;
    notifScreenshotPhoto: PhotoEntity;
    forumScreenshotPhoto: PhotoEntity;
    facebookScreenshotPhoto: PhotoEntity;
    hasCompletedFields: boolean; // ci uz su zadane vsetky potrebne udaje od sales a production
    hasCompletedStats: boolean;  // ci uz su k dispozicii statistiky za cele obdobie
}

export interface OrderReportInternalMailItem {
    id: number;
    type: BoOrderItemProductType.IP | BoOrderItemProductType.IP_REMARKETING;
    orderedAmount: number;
    sentAmount: number;
    subject: string;
    desktopScreenshotPhoto: PhotoEntity;
    dateFrom: string;
    deviceStats: OrderReportDeviceStats;
    hasCompletedFields: boolean; // ci uz su zadane vsetky potrebne udaje od sales a production
    hasCompletedStats: boolean;  // ci uz su k dispozicii statistiky za cele obdobie
}

export interface OrderReportReviewItem {
    id: number;
    type:
        BoOrderItemProductType.REVIEWS_PROMO | BoOrderItemProductType.REVIEWS_PROMO_EXTRA |
        BoOrderItemProductType.REVIEWS_PAGE | BoOrderItemProductType.REVIEWS_SAMPLING |
        BoOrderItemProductType.REVIEWS_YOUTUBE | BoOrderItemProductType.REVIEWS_DETAILED_REVIEWS |
        BoOrderItemProductType.REVIEWS_REDACTION_TESTING | BoOrderItemProductType.REVIEWS_REDACTION_TESTING_WITH_EXPS;
    title: string;
    publishedDate: string;
    viewsUnique: number;
    viewsTotal: number;
    clicks: number;
    experienceAuthors: number;
    url: string;
    reviewArticle: WikiArticleEntity;
    parentReviewArticle: WikiArticleEntity;
    desktopScreenshotPhoto: PhotoEntity;
    mobileScreenshotPhoto: PhotoEntity;
    hasCompletedFields: boolean; // ci uz su zadane vsetky potrebne udaje od sales a production
    hasCompletedStats: boolean;  // ci uz su k dispozicii statistiky za cele obdobie

    youtube: YoutubeData;
    facebook: FacebookData;
    google: GoogleResultsData;
    promo: PromoData;
    redactionTesting: RedactionTestingData;
    redactionTestingWithExps: RedactionTestingWithExpsData;
    detailedReviews: DetailedReviewsData;
    sampling: SamplingData;
}

export interface OrderReportEntity extends Entity {
    name: string;
    summary: string;
    items: Array<
        OrderReportBannerItem |
        OrderReportContestItem |
        OrderReportInternalMailItem |
        OrderReportArticleItem |
        OrderReportReviewItem
    >;
    accessKey: string;
}

export const OrderReportSchema = new schema.Entity('orderReports', {
});

export interface CommercialMailEntity extends Entity {
    isActive: boolean;
    sender: string;
    subject: string;
    dateFrom: string;
    dateTo: string;
    amount: number;
    delivered: number;
    height: number;
    priority: number;
    conditions: string;
}

export const CommercialMailSchema = new schema.Entity('commercialMails');

export interface BestTestData {
    screenshot: PhotoEntity;
    usersImpressed: number;
    usersInvolved: number;
    shown: number;
    link: string;
}

export interface SamplingData {
    screenshot: PhotoEntity;
    link: string;
}

export interface YoutubeData {
    screenshot: PhotoEntity;
    link: string;
    views: number;
    likes: number;
}

export interface GoogleResultsData {
    screenshot: PhotoEntity;
    keyword: string;
    position: string;
}

export interface FacebookData {
    screenshot: PhotoEntity;
    usersImpressed: number;
    clicks: number;
}

export interface PromoData {
    screenshot: PhotoEntity;
    deviceStats: OrderReportDeviceStats;
    uniqueReach: number;
}

export interface RedactionTestingData {
    screenshot: PhotoEntity;
    testingArticleViewsUniqueCount: number;
    testingArticleViewsTotalCount: number;
    testingArticleTitle: string;
    testingArticleUrl: string;
}

export interface RedactionTestingWithExpsData {
    screenshot: PhotoEntity;
    bestReviewId: number;
}

export interface DetailedReviewsData {
    screenshot: PhotoEntity;
    usersImpressed: number;
    shown: number;
    callPostViewsUniqueCount: number | null;
    callPostViewsTotalCount: number | null;
    callPostCommentersCount: number | null;
    testers: UserEntity[];
    bestReviewId: number;
}

export interface SamplingData {
    screenshot: PhotoEntity;
    bestReviewId: number;
}

export interface CommentsData {
    screenshot: PhotoEntity;
}

export interface TitleAndUrl {
    title: string;
    url: string;
}

export interface TestingReportEntity extends Entity {
    name: string;
    summary: string;
    usersInvolved: number;
    usersImpressed: number;
    shown: number;
    callPostViewsUniqueCount: number | null;
    callPostViewsTotalCount: number | null;
    callPostCommentersCount: number | null;
    callWikiArticleViewsUniqueCount: number | null;
    callWikiArticleViewsTotalCount: number | null;
    testers: UserEntity[];
    bestTest: BestTestData;
    google: GoogleResultsData;
    facebook: FacebookData;
    comments: CommentsData;
    forumTest: TitleAndUrl;
    blogTests: TitleAndUrl[];
    accessKey: string;
}

export const TestingReportSchema = new schema.Entity('boReportsTesting', {
});

export interface TopicData {
    screenshot: PhotoEntity;
    usersImpressed: number;
    usersInvolved: number;
    shown: number;
}

export interface AmbassadorReportEntity extends Entity {
    name: string;
    month: number;
    year: number;
    summary: string;
    avatarPhoto: PhotoEntity;
    followers: number;
    followersDiff: number;
    engagement: number;
    commercialEngagementRatio: number;
    usersImpressed: number;
    commercialUsersImpressedRatio: number;
    shown: number;
    commercialShownRatio: number;
    groupMembers: number;
    groupPosts: number;
    reviewPagesCount: number;
    reviewPagesExperiences: number;
    reviewPagesMostViewedPageTitle: string;
    reviewPagesMostViewedPageUrl: string;
    reviewPagesTotalViews: number;
    activeUsers: number;
    activeUsersImpressedRatio: number;
    impressedUsers: DayImpressions;
    successfulTopics: TopicData[];
    adhocScreenshot1: PhotoEntity;
    adhocScreenshot1Caption: string;
    adhocScreenshot2: PhotoEntity;
    adhocScreenshot2Caption: string;
    accessKey: string;
}

export const AmbassadorReportSchema = new schema.Entity('boReportsAmbassador', {
});

export interface AskAnExpertReportEntity extends Entity {
    name: string;
    month: number;
    year: number;
    avatarPhoto: PhotoEntity;
    monthlyUniques: number;
    answeredQuestions: number;
    followers: number;
    impressedUsers: DayImpressions;
    accessKey: string;
    screenshot: PhotoEntity;
    topQuestion: PostEntity;
    topQuestionViews: number;
}

export const AskAnExpertReportSchema = new schema.Entity('boReportsAskAnExpert', {
});

export enum TestingStatus {
    teaser = 0,
    call = 1,
    testing = 2,
    finished = 3,
}

export enum AdManagerCreativeType {
    None = 'none',
    Image = 'image',
    Custom = 'custom',
    ThirdParty = 'thirdparty',
    Native = 'native',
}

export enum ScreenshotType {
    Facebook = 'facebook',
    Desktop = 'desktop',
    Forum = 'forum',
    Mobile = 'mobile',
    Notif = 'notif',
    Post1 = 'post1',
    Post2 = 'post2',
    Google = 'google',
    Comments = 'comments',
}

export interface TrackedHashtagEntity extends Entity {
    name: string;
    createdAt: string;
}

export const TrackedHashtagSchema = new schema.Entity('photoblog.trackedhashtag', {});
