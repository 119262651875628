import { analyticsStoreEvent } from 'mk2/actions';
import { Route } from 'mk2/containers/Application/Application';
import { hasRoute } from 'mk2/helpers/router';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { RouterContext } from 'mk2/services/RouterContext';
import React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';

interface OwnProps {
    name: string;
    props: Record<string, string | number | boolean>;
    children: React.ReactNode;
}

interface RouterProps {
    routes: Route[];
}

interface DispatchProps {
    onStoreEvent(name: string, props: any, redirect?: string);
}

type Props = OwnProps & DispatchProps & RouterProps;

class TrackClickComponent extends React.Component<Props> {

    private node: Element | Text | null;

    // Prevents multiple clicks on a links within TrackClick before redirecting
    private prevents: boolean = false;

    public componentDidMount() {
        this.node = findDOMNode(this);
        this.registerEvents(this.node);
    }

    public componentDidUpdate() {
        const node = findDOMNode(this);
        if (node !== this.node) {
            this.unregisterEvents(this.node);
            this.registerEvents(node);
            this.node = node;
        }
    }

    public componentWillUnmount() {
        this.unregisterEvents(this.node);
    }

    public render() {
        return React.Children.only(this.props.children);
    }

    private registerEvents(node: Element | Text | null) {
        if (node instanceof Element) {
            node.addEventListener('click', this.onClick);
            node.addEventListener('auxclick', this.onClick);
        }
    }

    private unregisterEvents(node: Element | Text | null) {
        if (node instanceof Element) {
            node.removeEventListener('click', this.onClick);
            node.removeEventListener('auxclick', this.onClick);
        }
    }

    private onClick = (event: MouseEvent) => {
        if (this.prevents) {
            // ignore second click
            event.preventDefault();
            return;
        }

        const { onStoreEvent, name, props } = this.props;
        let redirect;

        if (!openNewWindow(event)) {
            const a: Element = (event.target as Element).closest('a');
            if (a && !a.getAttribute('target')) {
                redirect = a.getAttribute('href');
            }
        }

        // We have to delay page load till store event is sent, because on FF AJAX are interrupted
        // Also check if we are still in SPA
        if (redirect && !hasRoute(redirect, this.props.routes)) {

            // set `prevents = true` except some action links (like expand/collapse of CollapsedTextBox/Clamp,..)
            if (redirect !== '' && redirect !== '#') {
                this.prevents = true;
            }
            event.preventDefault();
            onStoreEvent(name, props, redirect);
        } else {
            onStoreEvent(name, props);
        }
    };
}

const TrackClickWithRoutes: React.FunctionComponent<OwnProps & DispatchProps> = (props) => (
    <RouterContext.Consumer>
        {({ routes }) => (
            <TrackClickComponent {...props} routes={routes} />
        )}
    </RouterContext.Consumer>
);

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onStoreEvent: analyticsStoreEvent,
};

export const TrackClick = connect(null, mapDispatchToProps)(TrackClickWithRoutes);

export function openNewWindow(e: MouseEvent) {
    // button === 1 -> middle button
    // meta, ctrl -> new background tab
    // shift -> new window
    return e.button === 1 || e.metaKey || e.ctrlKey || e.shiftKey;
}
