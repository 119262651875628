import * as Sentry from '@sentry/minimal';
import { homepageUrl } from 'mk2/apps/home/urls';
import { goBackInPWA, HistoryLocationState } from 'mk2/services/browserHistory';
import React from 'react';
import { withRouter, RouteComponentProps, StaticContext } from 'react-router';
import ServerError from './ServerError';

interface OwnProps {}

interface OwnState {
    hasError: boolean;
    sentryEventId: string;
}

type RouteProps = RouteComponentProps<{}, StaticContext, HistoryLocationState>;
type Props = OwnProps & RouteProps;

class ErrorBoundaryComponent extends React.Component<Props, OwnState> {

    public static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    private contentRef = React.createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);

        const { history } = props;

        history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });

        this.state = {
            hasError: false,
            sentryEventId: null,
        };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras({ componentStack: errorInfo.componentStack });

            const sentryEventId = Sentry.captureException(error);
            console.error(error);  // tslint:disable-line:no-console

            this.setState({ sentryEventId });
        });

        // if error occurs during first render on client, but it rendered
        // succesfully on server (SSR), we have to delete the SSR content,
        // otherwise <ServerError> is displayed below content from SSR.
        const contentElem = this.contentRef.current;
        if (contentElem) {
            let prevEl = contentElem.previousElementSibling;
            while (prevEl) {
                prevEl.parentElement.removeChild(prevEl);
                prevEl = contentElem.previousElementSibling;
            }
        }
    }

    public render() {
        const { hasError, sentryEventId } = this.state;
        const { history } = this.props;

        const mkGoBackDepthGtZero = history && history.location && history.location.state && history.location.state.mkGoBackDepth > 0;

        // see https://docs.sentry.io/platforms/javascript/react/
        if (hasError) {
            return (
                <ServerError
                    sentryEventId={sentryEventId}
                    hasMkGoBackLocation={mkGoBackDepthGtZero}
                    innerRef={this.contentRef}
                    onGoBack={this.handleGoBack}
                />
            );
        } else {
            return this.props.children;
        }
    }

    private handleGoBack = () => {
        const { history } = this.props;
        goBackInPWA(history, homepageUrl);
    };
}

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
