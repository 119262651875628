/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const disinfection : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SPRAY", "Sprej"],
        ["GEL", "Gel"],
        ["UNIVERSAL", "Univerz\u00e1ln\u00ed"],
        ["OTHER", "Jin\u00e9"],
    ],
};
