import cx from 'classnames';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import { UserEntity } from 'mk2/schemas';
import React from 'react';
import styles from './AvatarImg.mscss';

interface OwnProps {
    className?: string;
    user: UserEntity;
    href?: string;
    size?: 16 | 18 | 24 | 28 | 32 | 36 | 40 | 50 | 110 | 124 | 150 | 200;
    userAvatarBig?: string;
    forceShowPlanBadge?: 'plus' | 'pro';
}

// TODO: Add tooltip - use react bootstrap!!! - don't make own solution :)
export class AvatarImg extends React.Component<OwnProps> {

    public static defaultProps: Partial<OwnProps> = {
        size: 50,
    };

    public shouldComponentUpdate(n: OwnProps) {
        const p = this.props;

        return n.user.avatar !== p.user.avatar
            || n.user.avatarCampaign !== p.user.avatarCampaign
            || n.user.activePlan !== p.user.activePlan;
    }

    public render() {
        const { user, size, href, className, userAvatarBig, forceShowPlanBadge } = this.props;
        const imageSrc = size > 50
            ? userAvatarBig || user.avatarCampaign || user.avatar
            : user.avatar;

        const image = (
            <div className={styles.AvatarImg__container}>
                <Img lazy src={imageSrc} width={size} height={size} />
            </div>
        );

        const strip = (
            <div className={styles.AvatarImg__strip} />
        );

        const activePlan = forceShowPlanBadge || user.activePlan;
        const showStrip = activePlan === 'plus' || activePlan === 'pro';

        const classes = cx(
            className, styles.AvatarImg,
            styles[`AvatarImg--${size}`],
            styles[`AvatarImg--${activePlan}`],
            ([150, 200].includes(size) || forceShowPlanBadge) && styles.AvatarImg__withStripBadge,
        );

        let container;
        if (href) {
            container = (
                <Link to={href} className={classes}>
                    {image}
                    {showStrip && strip}
                </Link>
            );
        } else {
            container = (
                <span className={classes}>
                    {image}
                    {showStrip && strip}
                </span>
            );
        }

        return container;
    }
}
