import cx from 'classnames';
import { BooleanMark } from 'mk2/apps/strollers/components/BooleanMark';
import { BooleanPair } from 'mk2/apps/strollers/features/other';
import { Features } from 'mk2/apps/strollers/schemas';
import React from 'react';
import styles from './BooleanFeature.mscss';

interface OwnProps {
    className?: string;
    features: Features;
    feature: any;
}

export class BooleanFeature extends React.Component<OwnProps> {
    public render() {
        const { feature, features, className } = this.props;

        const value = feature.rawValue(features);

        switch (value) {
            case true:
            case false:
                const booleanPair: BooleanPair = [value, null];
                return (
                    <BooleanMark
                        className={cx(styles.BooleanFeature, className)}
                        booleanPair={booleanPair}
                    />
                );
            default:
                return null;
        }
    }
}
