/**
 * Safe implementation of the session and local storages
 *
 * Safari throws an error during setItem() in the Private mode
 */

const fakeWindow: Partial<{
    localStorage: Storage;
    sessionStorage: Storage;
}> = {};

// TODO: Consider saving data to cookies - because with this implementation data is lost on refresh
function factory() {
    const aKeys = [];
    const oStorage = {};

    Object.defineProperty(oStorage, 'getItem', {
        value(sKey) {
            return sKey ? this[sKey] : null;
        },
        writable: false,
        configurable: false,
        enumerable: false,
    });

    Object.defineProperty(oStorage, 'key', {
        value(nKeyId) {
            return aKeys[nKeyId];
        },
        writable: false,
        configurable: false,
        enumerable: false,
    });

    Object.defineProperty(oStorage, 'setItem', {
        value(sKey, sValue) {
            if (!sKey) {
                return;
            }

            oStorage[sKey] = sValue;
            aKeys.push(sKey);
        },
        writable: false,
        configurable: false,
        enumerable: false,
    });

    Object.defineProperty(oStorage, 'length', {
        get() {
            return aKeys.length;
        },
        configurable: false,
        enumerable: false,
    });

    Object.defineProperty(oStorage, 'removeItem', {
        value(sKey) {
            if (!sKey) {
                return;
            }

            delete oStorage[sKey];
            const index = aKeys.indexOf(sKey);
            if (index > -1) {
                aKeys.splice(index, 1);
            }
        },
        writable: false,
        configurable: false,
        enumerable: false,
    });

    this.get = () => {
        return oStorage;
    };
    this.configurable = false;
    this.enumerable = true;
}

Object.defineProperty(fakeWindow, 'localStorage', new (factory)());
Object.defineProperty(fakeWindow, 'sessionStorage', new (factory)());

function checkStorage(name: 'localStorage' | 'sessionStorage') {
    const testKey = 'test';
    try {
        const storage = typeof window !== 'undefined' && window[name];
        if (!storage) {
            return false;
        }

        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return storage;
    } catch (err) {
        return false;
    }
}

export const getSessionStorage = () => checkStorage('sessionStorage') ? window.sessionStorage : fakeWindow.sessionStorage;
export const getLocalStorage = () => checkStorage('localStorage') ? window.localStorage : fakeWindow.localStorage;
