/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp23 } from './commonProp23';

export const internships : {[key: string]: Array<[string, string]>} = {
    p_maternity_leave_suitable: commonProp23,
    p_homeoffice: commonProp23,
};
