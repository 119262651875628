import {
    PHOTO_UPLOAD_ABORTED_UPLOAD,
    PHOTO_UPLOAD_FILE_IS_TOO_BIG,
    PHOTO_UPLOAD_WRONG_IMAGE_FORMAT,
} from 'mk/autogenerated/translations/PhotosUpload.sagas.2f277f5975ab94e3d88ebab6bafd1928'
import { csrfToken } from 'mk/common/csrfToken';
import { system_api_photo_upload_url } from 'mk/urls/functions';
import { FormValues } from 'mk2/apps/adminclub/containers/XMLImportItems/StrollerVariantConfigurationForm';
import { resizeImage } from 'mk2/containers/PhotosUpload/resize';
import {
    photoUploadApi,
    photoUploadRemoveAll,
    PhotosUploadTriggerAction,
    PhotoUploadRemoveAction,
    PhotoUploadRequestAction,
    PHOTO_UPLOAD_REMOVE,
    PHOTO_UPLOAD_REMOVE_ALL,
    PHOTO_UPLOAD_REQUEST,
    PHOTOS_UPLOAD_TRIGGER,
} from 'mk2/containers/PhotosUpload/PhotosUpload.actions';
import { createXHRChannel, normalizeError, XHRAction, XHRChannelEvent } from 'mk2/helpers/api';
import { MKReduxFormState } from 'mk2/helpers/form.reducers';
import { getLogger } from 'mk2/logger';
import { getFormState, getRequestPermissions } from 'mk2/reducers';
import { PhotoDetailSchema, PhotoEntity, PhotoMimetype, PhotoSchema, StrollerPhoto } from 'mk2/schemas';
import { getDenormalizedEntities, getDenormalizedEntity } from 'mk2/selectors';
import { normalize } from 'normalizr';
import { change, getFormValues } from 'redux-form';
import { buffers } from 'redux-saga';
import { actionChannel, all, call, delay, fork, put, race, select, take, takeEvery } from 'redux-saga/effects';

const logger = getLogger('PhotosUpload.sagas');

interface ApiResponsePhotoUpload {
    body: {
        photo: PhotoEntity;
        so_photo: StrollerPhoto;
    };
}

let uploadIdCounter = 0;

const MAX_UPLOAD_FILE_SIZE = 30 * 1024 * 1024;

function* uploadFiles({formName, config, files, processPhotoUploadOnFormPage, accessKey }: PhotosUploadTriggerAction & XHRAction) {
    if (!files.length) {
        return;
    }

    if (!config.multiple) {
        // take only first file if multiple not allowed, and remove previous upload
        files = [files[0]];
        yield put(photoUploadRemoveAll(formName));
    }

    // wait till the formState.currentFormPage is the decided processPhotoUploadOnFormPage
    // (to awoid starting the photoUploadApi.request on the Main form page)
    let formState: MKReduxFormState;
    do {
        formState = yield select(getFormState, formName);
        yield delay(50);
    } while (
        formState.currentFormPage !== processPhotoUploadOnFormPage
    );

    if (formState.values && formState.values.photos) {
        let maxId = 0;
        uploadIdCounter = formState.values.photos.length;
        for (const p of formState.values.photos) {
            maxId = p.id && p.id > maxId ? p.id : maxId;
        }
        uploadIdCounter = uploadIdCounter < maxId ? maxId : uploadIdCounter;
    }

    for (const f of files) {
        const uploadId = ++uploadIdCounter;
        yield put(photoUploadApi.request(formName, uploadId, config, f, accessKey));
    }
}

// serialize photo uploads, so that we do not upload more than one file at the same time
function* watchUploadRequests() {
    const uploadChannel = yield actionChannel(PHOTO_UPLOAD_REQUEST, buffers.expanding());
    while (true) {
        const {
            formName, config, uploadId, file, accessKey,
        }: PhotoUploadRequestAction = yield take(uploadChannel);

        // potrebujeme try-catch, aby ked sa pri uploade jednej fotky stane exception,
        // sme neprestali cakat na vsetky dalsie photo uploads
        try {
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                yield put(photoUploadApi.failure(formName, uploadId, PHOTO_UPLOAD_FILE_IS_TOO_BIG));
                continue;
            } else if (file.type !== '' &&  // if the type cannot be determined, the value is empty-string
                file.type !== PhotoMimetype.JPEG &&
                file.type !== PhotoMimetype.PNG &&
                file.type !== PhotoMimetype.GIF &&
                file.type !== PhotoMimetype.WEBP
                ) {
                yield put(photoUploadApi.failure(formName, uploadId, PHOTO_UPLOAD_WRONG_IMAGE_FORMAT));
                continue;
            }

            // ak user nie je admin, zmensi obrazok
            const requestUserPermissions: string[] = yield select(getRequestPermissions);
            const isAdmin = (
                requestUserPermissions.indexOf('forum.can_manage_forum') >= 0 ||
                requestUserPermissions.indexOf('wiki.can_edit_wiki') >= 0
            );
            const resizedImage: Blob = !isAdmin ? yield call(() => resizeImage(file)) : file;

            // uploadni obrazok, posielaj priebezne info o progresse
            const xhr = new XMLHttpRequest();
            const uploadUrl = system_api_photo_upload_url();
            const channel = yield call(() => createXHRChannel(xhr, uploadUrl, {
                csrfmiddlewaretoken: csrfToken(),
                photoType: config.photoType,
                strollerId: config.strollerId?.toString(),
                file: resizedImage,
                accessKey,
            }));
            while (true) {
                const { channelEvent, cancelEvent, cancelAllEvent} = yield race({
                    channelEvent: take(channel),
                    cancelEvent: take(PHOTO_UPLOAD_REMOVE),
                    cancelAllEvent: take(PHOTO_UPLOAD_REMOVE_ALL),
                });

                if (cancelAllEvent ||
                    (cancelEvent && (cancelEvent as PhotoUploadRemoveAction).uploadId === uploadId)) {
                    // userka dala odstranit upload, co prave uploadnujeme, cancelnime
                    // xhr.abort() raises 'abort' event from createXHRChannel
                    xhr.abort();
                } else if (channelEvent) {
                    const {event, progress, error, successResponse}: XHRChannelEvent = channelEvent;
                    if (event === 'done') {
                        const response = successResponse as ApiResponsePhotoUpload;
                        const normalizedResponse = normalize(response.body, {photo: PhotoSchema});
                        yield put(photoUploadApi.success(formName, uploadId, normalizedResponse));

                        if (config.photoType === 'stroller_edit_photos_photo') {
                            const currentValues: FormValues = yield select(getFormValues(formName));
                            currentValues.photos.pop();
                            yield put(change(formName, 'photos', [...currentValues.photos, response.body.so_photo]));
                            yield put(change(formName, 'selected', {...currentValues.selected, [response.body.so_photo.id]: true}));
                        }
                        break;
                    } else if (event === 'error') {
                        yield put(photoUploadApi.failure(formName, uploadId, normalizeError(error)));
                        break;
                    } else if (event === 'abort') {
                        yield put(photoUploadApi.failure(formName, uploadId, PHOTO_UPLOAD_ABORTED_UPLOAD));
                        break;
                    } else if (event === 'progress') {
                        yield put(photoUploadApi.progress(formName, uploadId, progress));
                    }
                }
            }
        } catch (error) {
            logger.error(error);
            yield put(photoUploadApi.failure(formName, uploadId, normalizeError(error)));
        }
    }
}

export default function* root() {
    yield all([
        takeEvery(PHOTOS_UPLOAD_TRIGGER, uploadFiles),
        process.env.SERVER ? null : fork(watchUploadRequests),
    ]);
}
