import { parse } from 'cookie';

// we intentionally do not cache the result of this function
// while it's true that the value should not change
// during a page's lifetime, if the user does something
// in an another tab, then it can change
// so it is safer to calculate it every time

export function csrfToken() {
    if (typeof navigator !== 'undefined' && typeof document !== 'undefined') {
        if (!navigator.cookieEnabled || !document.cookie) {
            // User has disabled cookies
            // We should not blow the app with error - instead let JS run normally
            // and when user try to interact with API, django will return 403
            return undefined;
        }

        return parse(document.cookie).csrftoken;
    }
}
