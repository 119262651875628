export interface ReleaseState {
    version?: string;
}

export function releaseReducer(state: ReleaseState = {}, action: any) {
    if (!!action?.response?.release) {
        return {
            version: action.response.release,

        };
    }

    return state;
}
