import { FacetValue } from 'mk2/apps/strollers/containers/StrollersSearch/StrollersSearch.constants';
import { Entity, PhotoEntity } from 'mk2/schemas';
import { schema } from 'normalizr';

// Profile

export enum PregnancyState {
    DoNotTell = 0,
    Pregnant = 1,
    Trying = 2,
    DoNotPlan = 3,
    HasChildren = 99,
}

export enum PregnantWith {
    WeDontKnow = 1,
    ItWillBeSurprise = 2,
    Boy = 3,
    Girl = 4,
    TwinsBoys = 5,
    TwinsGirls = 6,
    TwinsBoyAndGirl = 9,
    TwinsSurprise = 10,
    Triplets = 12,
}

export interface ProfileEntity extends Entity {
    photos: PhotoEntity[];

    fullName: string;
    phoneNumber: string;

    bankAccount: string;
    bankCode: string;

    sex: SexType;
    birthDate: string;
    locationCounty: {
        id: number,
        name: string,
    };
    place: {
        id: number,
        name: string,
    };
    pregnancyState: PregnancyState;
    pregnantWith: PregnantWith;
    dueDate: string;
    childName: string;
    about: string;
    email: string;
    pregnancyNewsletterIsHidden: boolean;
    blockedAvatar: boolean;
    blockedAvatarReason: number;
    blockedAvatarTime: string;
    blockedAvatarComment: string;
}

export const ProfileSchema = new schema.Entity('profile', {
});

// Kids

export enum SexType {
    Unknown = 0,
    Girl = 1,
    Boy = 2,
}

export interface KidEntity extends Entity {
    name: string;
    birthDate: string; // datum v tvare YYYY-MM-DD
    sex: SexType;
    photos: PhotoEntity[];
}

export const KidSchema = new schema.Entity('kids', {
});

// Account

export interface AccountEntity extends Entity {
    email: string;
    emailNotifications: FacetValue[];
    wallNotifications: FacetValue[];
    phoneNumber: string;
    cannotSendEmail: boolean;
    bankAccount: string;
}

export const AccountSchema = new schema.Entity('account', {});

export interface NotificationsSettingsEntity extends Entity {
    ip: boolean;
    wall: boolean;
    bazaar: boolean;
}

export const NotificationsSettingsSchema = new schema.Entity('notificationsSettings', {});
