import { ZoneTargeting } from 'mk2/containers/AdSlot/interfaces';
import { Action } from 'redux';

export const AD_SLOT_REGISTER = 'AD_SLOT_REGISTER';

export interface AdSlotRegisterAction extends Action {
    readonly type: typeof AD_SLOT_REGISTER;
    readonly slotId: string;
    readonly zone: string;
    readonly targeting: ZoneTargeting;
}

export const adSlotRegister = (slotId: string, zone: string, targeting: ZoneTargeting): AdSlotRegisterAction => ({
    type: AD_SLOT_REGISTER, slotId, zone, targeting,
});

export const AD_SLOT_UNREGISTER = 'AD_SLOT_UNREGISTER';

export interface AdSlotUnregisterAction extends Action {
    readonly type: typeof AD_SLOT_UNREGISTER;
    readonly slotId: string;
    readonly zone: string;
}

export const adSlotUnregister = (slotId: string, zone: string): AdSlotUnregisterAction => ({
    type: AD_SLOT_UNREGISTER, slotId, zone,
});

export const AD_SLOT_REFRESH = 'AD_SLOT_REFRESH';

export interface AdSlotRefreshAction extends Action {
    readonly type: typeof AD_SLOT_REFRESH;
    readonly slotId: string;
}

export const adSlotRefresh = (slotId: string): AdSlotRefreshAction => ({
    type: AD_SLOT_REFRESH,
    slotId,
});

export const AD_SLOT_RENDER_ENDED = 'AD_SLOT_RENDER_ENDED';

export interface AdSlotRenderEndedAction extends Action {
    readonly type: typeof AD_SLOT_RENDER_ENDED;
    readonly slotId: string;
    readonly event: SlotRenderEndedEvent;
}

interface SlotRenderEndedEvent {
    isEmpty: boolean;
    size: number[] | string;
}

export const adSlotRenderEnded = (slotId: string, event: SlotRenderEndedEvent): AdSlotRenderEndedAction => ({
    type: AD_SLOT_RENDER_ENDED,
    slotId, event,
});
