import { Action } from 'redux';

import {
    APIFailureAction,
    APIRequestAction,
    APISuccessAction,
} from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';

// API
export interface StarSaveAction extends Action {
    readonly entity: string;
    readonly id: number;
}

export const STAR_SAVE_REQUEST = 'STAR_SAVE_REQUEST';
export interface StarSaveRequestAction extends StarSaveAction, APIRequestAction {
    readonly type: typeof STAR_SAVE_REQUEST;
    readonly response: NormalizedResponse;
}

export const STAR_SAVE_SUCCESS = 'STAR_SAVE_SUCCESS';
export interface StarSaveSuccessAction extends StarSaveAction, APISuccessAction {
    readonly type: typeof STAR_SAVE_SUCCESS;
}

export const STAR_SAVE_FAILURE = 'STAR_SAVE_FAILURE';
export interface StarSaveFailureAction extends StarSaveAction, APIFailureAction {
    readonly type: typeof STAR_SAVE_FAILURE;
}

export const starSave = {
    // Note: We are sending fake response in the request, so that entities reducer will optimistic update data
    request: (entity: string, id: number, response: any): StarSaveRequestAction => ({ type: STAR_SAVE_REQUEST, entity, id, response }),
    success: (entity: string, id: number, response: any): StarSaveSuccessAction => ({ type: STAR_SAVE_SUCCESS, entity, id, response }),
    failure: (entity: string, id: number, error: any): StarSaveFailureAction => ({ type: STAR_SAVE_FAILURE, entity, id, error }),
};

// Triggers
export const STAR_TOGGLE = 'STAR_TOGGLE';

export interface StarToggleAction extends Action {
    readonly type: typeof STAR_TOGGLE;
    readonly id: number;
    readonly entity: string;
}

export const starToggle = (entity: string, id: number): StarToggleAction => ({ type: STAR_TOGGLE, entity, id });
