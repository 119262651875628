import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import {
    BlogPostSetCommentsOpenedAction,
    BLOG_POST_SET_COMMENTS_OPENED,
} from 'mk2/apps/blogs/components/BlogPost/BlogPost.actions';
import { tupdate } from 'mk2/helpers/types';
import { HistoryLocationState } from 'mk2/services/browserHistory';
import { Reducer } from 'redux';

export interface BlogPostState {
    commentsOpened: boolean;
}

export const initialBlogPostState: BlogPostState = {
    commentsOpened: false,
};

export interface BlogPostsState {
    [blogPostId: number]: BlogPostState;
}

export const initialBlogPostsState: BlogPostsState = {};

declare type BlogPostssReducerAction = BlogPostSetCommentsOpenedAction
    | LocationChangeAction<HistoryLocationState>;

const blogPostsReducer: Reducer<BlogPostsState> = (
    state: BlogPostsState = initialBlogPostsState,
    action: BlogPostssReducerAction,
) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return initialBlogPostsState;
        }
        case BLOG_POST_SET_COMMENTS_OPENED: {
            const key = action.postId;
            const subState = state[key] || initialBlogPostState;
            return tupdate(state, {
                [key]: tupdate(subState, {
                    commentsOpened: action.commentsOpened,
                }),
            });
        }

        default:
            return state;
    }
};

export default blogPostsReducer;
