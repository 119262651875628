/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp20 } from './commonProp20';
import { commonProp19 } from './commonProp19';

export const motorhomes : {[key: string]: Array<[string, string]>} = {
    p_fuel: commonProp20,
    p_transmission: commonProp19,
    p_number_of_seats: [
        d("4"),
        d("5"),
        d("6"),
    ],
    p_sleeping_places: [
        d("2"),
        d("3"),
        d("4"),
        d("5"),
        d("6"),
        d("8"),
    ],
};
