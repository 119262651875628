// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const STROLLER_FEATURE_PRODUCT_IS_NO_LONGER_SOLD = "V\u00fdroba ukon\u010dena";
export const STROLLER_FEATURE_PRODUCT_IS_NO_LONGER_SOLD_SINCE_date = "V\u00fdroba ukon\u010dena %(date)s";
export const STROLLER_RATING_1 = "Katastrofa";
export const STROLLER_RATING_10 = "Dokonal\u00fd";
export const STROLLER_RATING_2 = "Hrozn\u00fd";
export const STROLLER_RATING_3 = "\u0160patn\u00fd";
export const STROLLER_RATING_4 = "Podpr\u016fm\u011brn\u00fd";
export const STROLLER_RATING_5 = "Akceptovateln\u00fd";
export const STROLLER_RATING_6 = "Ujde";
export const STROLLER_RATING_7 = "Dobr\u00fd";
export const STROLLER_RATING_8 = "Velmi dobr\u00fd";
export const STROLLER_RATING_9 = "Skv\u011bl\u00fd";
