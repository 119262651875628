import { STATIC_DOMAINS } from 'mk/settings';
import { SuccessfulPhotoUpload } from 'mk2/helpers/form.reducers';
import { PhotoEntity, PhotoMimetype } from 'mk2/schemas';

// keep in sync with system/utils.py
const PHOTO_CODE_CHAR_COUNT = 11;

function getDomain(code) {
    const isDevPhotoCode = code.length > PHOTO_CODE_CHAR_COUNT && code.startsWith('dev');
    const section = !isDevPhotoCode ? 's3' : 's3dev';
    const domains = STATIC_DOMAINS[section];
    const num = code.charCodeAt(code.length - 1);
    const idx = num % domains.length;

    return domains[idx];
}

export function photoUrl(photo: PhotoEntity, version: string) {
    const domain = getDomain(photo.code);
    const extension = {
        [PhotoMimetype.JPEG]: 'jpg',
        [PhotoMimetype.PNG]: 'png',
        [PhotoMimetype.GIF]: 'gif',
    }[photo.mimetype];
    return `https://${domain}/${photo.code}_${version}.${extension}`;
}

export function decidePreviewPhotoVersion(version, uploadedPhoto) {
    return (
        (!uploadedPhoto || !uploadedPhoto.versions || uploadedPhoto.versions.includes(version)) ? version
            : uploadedPhoto.versions.includes('s150x150') ? 's150x150'
                : uploadedPhoto.versions.includes('s170x170') ? 's170x170'
                    : uploadedPhoto.versions.includes('s165x165') ? 's165x165'
                        : uploadedPhoto.versions.includes('s560x560') ? 's560x560' : 's1600x1600'
    );
}

