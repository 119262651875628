
export interface AdUnitScreenSizeConfig {
    minScreenWidth: number;
    bannerSizes: Array<[number, number]>;
    native: boolean;
}

export declare type AdUnitSizeConfig = AdUnitScreenSizeConfig[];

export const billboard: AdUnitSizeConfig = [
    {
        minScreenWidth: 980, // desktop
        bannerSizes: [
            [980, 250], [980, 120], [980, 90],
            [970, 250], [970, 90],
            [960, 90],
            [950, 90],
            [930, 180],
            [800, 250], [800, 90],
            [768, 90],
            [750, 200], [750, 100],
            [728, 90],
        ],
        native: false,
    },
    {
        minScreenWidth: 0, // mobile (not shown)
        bannerSizes: [],
        native: false,
    },
];

export const rectangleDesktopOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 980, // desktop
        bannerSizes: [[336, 280], [300, 250], [250, 250]],
        native: true,
    },
    {
        minScreenWidth: 0, // mobile (not shown)
        bannerSizes: [],
        native: false,
    },
];

export const rectangleMobileOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 600, // desktop (not shown)
        bannerSizes: [],
        native: false,
    },
    {
        minScreenWidth: 360,  // mobile (nexus5 - 360px)
        bannerSizes: [[336, 280], [300, 250], [250, 250]],
        native: true,
    },
    {
        minScreenWidth: 0,  // mobile (iphone4 - 320px)
        bannerSizes: [[300, 250], [250, 250]],
        native: true,
    },
];

export const halfpage: AdUnitSizeConfig = [
    {
        minScreenWidth: 980, // desktop
        bannerSizes: [[300, 600], [160, 600], [120, 600]],
        native: true,
    },
    {
        minScreenWidth: 0,  // mobile (not shown)
        bannerSizes: [],
        native: false,
    },
];

export const halfpageMobileOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 980, // desktop (not shown)
        bannerSizes: [],
        native: false,
    },
    {
        minScreenWidth: 0,  // mobile
        bannerSizes: [[300, 600], [160, 600], [120, 600]],
        native: true,
    },
];

export const halfpageOffsite: AdUnitSizeConfig = [
    {
        minScreenWidth: 1590, // desktop (1590 - 980 stranka - 10 margin) / 2 >= 300
        bannerSizes: [[300, 600], [160, 600], [120, 600]],
        native: false,
    },
    {
        minScreenWidth: 1310, // desktop  (1310 - 980 stranka - 10 margin) / 2 >= 160
        bannerSizes: [[160, 600], [120, 600]],
        native: false,
    },
    {
        minScreenWidth: 1230, // desktop  (1230 - 980 stranka - 10 margin) / 2 >= 120
        bannerSizes: [[120, 600]],
        native: false,
    },
    {
        minScreenWidth: 0, // mobile (not shown)
        bannerSizes: [],
        native: false,
    },
];

export const brandingDesktopOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 980, // desktop
        bannerSizes: [[2000, 1400], [970, 250]],
        native: false,
    },
    {
        minScreenWidth: 0, // mobile (not shown)
        bannerSizes: [],
        native: false,
    },
];

export const brandingMobileOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 600, // desktop (not shown)
        bannerSizes: [],
        native: false,
    },
    {
        minScreenWidth: 410,  // mobile (iphone 6/7/8 Plus/XS Max/XR - 414px, pixel 1/2/3 XL - 412px, samsung galaxy 8/9 plus 412px
        bannerSizes: [[420, 125], [420, 122], [400, 125], [390, 122], [374, 117], [358, 112], [320, 100]],
        native: false,
    },
    {
        minScreenWidth: 375,  // mobile (iphone 6/7/8/X/XS - 375px
        bannerSizes: [[375, 125], [374, 117], [358, 112], [320, 100]],
        native: false,
    },
    {
        minScreenWidth: 360,  // mobile (samsung galaxy 6/7/8/9 - 360px
        bannerSizes: [[360, 125], [358, 112], [320, 100]],
        native: false,
    },
    {
        // Creative is wider than screen width. When displaying, we center it
        // inside slot and hide parts that overflow
        minScreenWidth: 0,  // mobile (iphone 4/5 - 320px)
        bannerSizes: [[320, 125], [320, 100]],
        native: false,
    },
];

export const mail: AdUnitSizeConfig = [
    {
        minScreenWidth: 0,
        bannerSizes: [[730, 3000]],
        native: true,
    },
];

export const mailCtaButton: AdUnitSizeConfig = [
    {
        minScreenWidth: 0,
        bannerSizes: [],
        native: true,
    },
];

export const nativeRectangleDesktopOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 980, // desktop
        bannerSizes: [[336, 280]],
        native: true,
    },
    {
        minScreenWidth: 0, // mobile (not shown)
        bannerSizes: [],
        native: false,
    },
];

export const nativeRectangleMobileOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 600, // desktop (not shown)
        bannerSizes: [],
        native: false,
    },
    {
        minScreenWidth: 360,  // mobile (nexus5 - 360px)
        bannerSizes: [[336, 280]],
        native: true,
    },
    {
        minScreenWidth: 0,  // mobile (iphone4 - 320px)
        bannerSizes: [],
        native: true,
    },
];

// https://support.google.com/admanager/answer/6015986?hl=en
export const interstitialDesktopOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 600, // desktop (not shown)
        bannerSizes: [[1, 1]],
        native: false,
    },
    {
        // Creative is wider than screen width. When displaying, we center it
        // inside slot and hide parts that overflow
        minScreenWidth: 0,  // mobile (iphone4 - 320px; up to iphone 6+ - 416px)
        bannerSizes: [],
        native: false,
    },
];

// https://support.google.com/admanager/answer/6015986?hl=en
export const interstitialMobileOnly: AdUnitSizeConfig = [
    {
        minScreenWidth: 600, // desktop (not shown)
        bannerSizes: [],
        native: false,
    },
    {
        // Creative is wider than screen width. When displaying, we center it
        // inside slot and hide parts that overflow
        minScreenWidth: 0,  // mobile (iphone4 - 320px; up to iphone 6+ - 416px)
        bannerSizes: [[1, 1]],
        native: false,
    },
];
