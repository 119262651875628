/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const pc_gaming : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PC_GAME", "Hra"],
        ["GAME_CONSOLE", "Hern\u00ed konzola"],
        ["GAMING_ACCESSORIES", "Hern\u00ed p\u0159\u00edslu\u0161enstv\u00ed"],
    ],
    p_gaming_platform: [
        d("MICROSOFT XBOX"),
        d("NINTENDO"),
        d("NINTENDO WII"),
        d("SONY PLAYSTATION"),
        d("PC"),
        ["OTHER_PLATFORM", "Jin\u00e1 platforma"],
    ],
};
