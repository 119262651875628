import { BooleanFeature as BooleanFeatureComponent } from 'mk2/apps/strollers/components/features/BooleanFeature';
import { CountriesFeature as CountriesFeatureComponent } from 'mk2/apps/strollers/components/features/CountriesFeature';
import { DimensionsFeature as DimensionsFeatureComponent } from 'mk2/apps/strollers/components/features/DimensionsFeature';
import { NumericRangeFeature as NumericRangeFeatureComponent } from 'mk2/apps/strollers/components/features/NumericRangeFeature';
import { PlainFeature as PlainFeatureComponent } from 'mk2/apps/strollers/components/features/PlainFeature';
import { TwoDimsFeature as TwoDimsFeatureComponent } from 'mk2/apps/strollers/components/features/TwoDimsFeature';
import { FEATURE_LABEL_MESSAGES } from 'mk2/apps/strollers/features/constants';
import { Features, VariantAttributesWithOptions } from 'mk2/apps/strollers/schemas';
import { niceNumber } from 'mk2/apps/strollers/utils';

export interface FeatureOptions {
    nice?: boolean;
    infinityName?: string;
    sorted?: boolean;
}

export class Feature {
    public Component = PlainFeatureComponent;
    public featureName: string;
    public options: FeatureOptions;

    constructor(featureName: string, options: FeatureOptions = {}) {
        this.featureName = featureName;
        this.options = { ...this.getDefaultOptions(), ...options };
    }

    public getDefaultOptions(): FeatureOptions {
        return { nice: true, sorted: true };
    }

    public getLabelMessage(): string {
        return FEATURE_LABEL_MESSAGES[this.featureName];
    }

    public hasWorthyValue(features: Features | VariantAttributesWithOptions): boolean {
        if (!features) {
            return false;
        }
        const value = features[this.featureName];
        return (value !== undefined) && (value !== null);
    }

    public rawValue(features: Features): any {
        if (!features) {
            return null;
        }
        return features[this.featureName];
    }

    public niceValue(features: Features): string {
        if (!features) {
            return '';
        }
        const value = features[this.featureName];
        return value ? value.toString() : '';
    }
}

export class BooleanFeature extends Feature {
    public Component = BooleanFeatureComponent;

    public hasWorthyValue(features: Features): boolean {
        if (!features) {
            return false;
        }
        const value = features[this.featureName];
        return !!value;
    }

    public niceValue(features: Features): string {
        if (!features) {
            return '';
        }
        const value = features[this.featureName];
        switch (value) {
            case true:
                return 'áno';
            case false:
                return 'nie';
            default:
                return '';
        }
    }
}

export class TextFeature extends Feature {

    public niceValue(features: Features): string {
        if (!features) {
            return '';
        }
        const value = features[this.featureName];
        if (!value) {
            return '';
        }
        return value;
    }
}

export class NumericFeature extends Feature {

    public niceValue(features: Features): string {
        if (!features) {
            return '';
        }
        const value = features[this.featureName];
        if (!value) {
            return '';
        }
        return this.options.nice ? niceNumber(value) : value;
    }
}

export class NumericListFeature extends Feature {

    public decodeItem(value) {
        return this.options.nice ? niceNumber(value) : value;
    }

    public niceValue(features: Features): string {
        const arrValue: number[] = this.rawValue(features) as number[];
        if (!arrValue) {
            return '';
        }

        const translated = arrValue.map((value) => this.decodeItem(value));
        if (this.options.sorted) {
            translated.sort();
        }
        return translated.join(', ');
    }
}

export class InfiniteNumericListFeature extends NumericListFeature {
    private INFINITY = 999999999;

    public decodeItem(value) {
        if (value === this.INFINITY) {
            return this.options.infinityName;
        }
        return super.decodeItem(value);
    }
}

export class NumericRangeFeature extends Feature {
    public Component = NumericRangeFeatureComponent;

    public hasWorthyValue(features: Features): boolean {
        if (!features || !features[this.featureName]) {
            return false;
        }
        const valueMax = features[this.featureName].max;
        const valueMin = features[this.featureName].min;
        return !!(valueMax || valueMin);
    }

    public niceValue(features: Features): string {
        if (!features || !features[this.featureName]) {
            return '';
        }
        const valueMax = features[this.featureName].max;
        const valueMin = features[this.featureName].min;
        if ((valueMax !== undefined) && (valueMin !== undefined) && (valueMax !== valueMin)) {
            return `${niceNumber(valueMin)}\u00A0\u2013\u00A0${niceNumber(valueMax)}`;     // beware: nbsp + ndash used
        }
        const value = valueMax || valueMin;
        return value ? niceNumber(value) : '';
    }
}

interface WidthDepth {
    depth: number;
    width: number;
}

export class WidthDepthFeature extends Feature {
    public Component = TwoDimsFeatureComponent;

    public getDims(features: Features): WidthDepth {
        return {
            depth: features[this.featureName + '_depth'],
            width: features[this.featureName + '_width'],
        };
    }

    public hasWorthyValue(features: Features): boolean {
        const dims = this.getDims(features);
        return !!(dims.depth || dims.width);
    }
}

interface Dimensions {
    height: number;
    length: number;
    width: number;
}

export class DimensionsFeature extends Feature {
    public Component = DimensionsFeatureComponent;

    public getDims(features: Features): Dimensions {
        return features[this.featureName];
    }

    public hasWorthyValue(features: Features): boolean {
        const dims = this.getDims(features);
        return !!dims && !!(dims.height || dims.length || dims.width);
    }
}

export class CountriesFeature extends Feature {
    public Component = CountriesFeatureComponent;
}
