/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const home_furniture : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["KITCHEN_CABINETS", "Kuchy\u0148sk\u00e9 sk\u0159\u00ed\u0148ky a linky"],
        ["DINING_TABLES_CHAIRS", "J\u00eddeln\u00ed stoly a \u017eidle"],
        ["BAR_TABLES_CAHIRS", "Barov\u00e9 stoly a \u017eidle"],
        ["SOFAS_ARMCHAIRS", "Gau\u010de, pohovky a k\u0159esla"],
        ["LIVING_ROOM_TV_FURNITURE", "Ob\u00fdvac\u00ed st\u011bna, n\u00e1bytek na TV a m\u00e9dia"],
        ["COFFEE_SIDE_TABLES", "Konferen\u010dn\u00ed a p\u0159\u00edru\u010dn\u00ed stolky"],
        ["BOOKCASES_SHELVES", "Knihovny, police, vitr\u00edny"],
        ["CHESTS_OF_DRAWERS", "Komody"],
        ["WARDROBES", "Sk\u0159\u00edn\u011b na \u0161aty"],
        ["BEDS_MATTRESSES", "N\u00e1bytek do lo\u017enice"],
        ["BATHROOM_CABINTES", "N\u00e1bytek do koupelny"],
        ["OFFICE_TABLES_CHAIRS", "N\u00e1bytek do pracovny"],
        ["HALLWAY_FURNITURE", "N\u00e1bytek do p\u0159eds\u00edn\u011b"],
        ["GARDEN_FURNITURE", "N\u00e1bytek na balk\u00f3n, terasu"],
        ["OTHER_FURNITURE", "Jin\u00e9"],
    ],
    p_color: commonProp4,
};
