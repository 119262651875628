import { Entity, PhotoEntity, UserEntity } from 'mk2/schemas';
import { schema } from 'normalizr';

export interface UserStatsEntity extends Entity {
    id: number;
    user: UserEntity;
    isActive: boolean;
    signupDate: string;
    county: string;
    childrenInfo: string;
}

export const UserStatsSchema = new schema.Entity('userStats', {});

export interface TicketEntity extends Entity {
    id: number;
    ticketMessages: TicketMessageEntity[];
    status: string;
}

export const TicketSchema = new schema.Entity('about.ticket', {
});

export interface TicketMessageEntity extends Entity {
    id: number;
    text: string;
    authorIsAdmin: boolean;
    authorId: number;
    createdTime: string;
    photos: PhotoEntity[];
    ticketUserId: number;
}

export const TicketMessageSchema = new schema.Entity('about.ticketmessage', {

});

export interface TicketUserEntity extends Entity {
    id: number;
    supportUserId: number;
    username: string;
}
export const TicketUserSchema = new schema.Entity('about.ticketuser', {

});
