import { faCheck, faExclamationTriangle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React from 'react';
import styles from './Toast.mscss';

interface OwnProps {
    className?: string;
    type: 'success' | 'error' | 'warning';

    remove();
}

const ICONS = {
    success: faCheck,
    error: faExclamationTriangle,
    warning: faExclamationTriangle,
};

export const Toast: React.StatelessComponent<OwnProps> = ({ className, type, children, remove }) => (
    <div className={cx(className, styles.Toast, styles[`Toast--${type}`])}>
        <div className={cx(styles.Toast__icon, styles[`Toast__icon--${type}`])}>
            <FontAwesomeIcon icon={ICONS[type]} />
        </div>
        <div className={cx(styles.Toast__message, styles[`Toast__message--${type}`])}>
            {children}
        </div>
        <div className={cx(styles.Toast__close, styles[`Toast__close--${type}`])} onClick={remove}>
            <FontAwesomeIcon icon={faTimes} />
        </div>
    </div>
);

Toast.displayName = 'Toast';
