/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp20 } from './commonProp20';
import { commonProp19 } from './commonProp19';
import { commonProp4 } from './commonProp4';

export const cars : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["HATCHBACK", "hatchback"],
        ["CONVERTIBLE", "kabriolet"],
        ["COMBI", "kombi"],
        ["COUPE", "kup\u00e9"],
        ["LIFTBACK", "liftback"],
        ["LIMOUSINE", "limuz\u00edna"],
        ["FAMILY_MPV", "rodinn\u00e9 / MPV"],
        ["PICKUP", "pick-up"],
        ["SEDAN", "sedan"],
        ["SUV_OFFROAD", "SUV / Offroad"],
    ],
    p_brand: [
        d("Abarth"),
        d("Acura"),
        d("Alfa Romeo"),
        d("Alpine"),
        d("Ariel"),
        d("Aston Martin"),
        d("Audi"),
        d("Bentley"),
        d("BMW"),
        d("Bugatti"),
        d("Chevrolet"),
        d("Citro\u00ebn"),
        d("Cupra"),
        d("Dacia"),
        d("Daewoo"),
        d("Dodge"),
        d("DS"),
        d("Ferrari"),
        d("FIAT"),
        d("Ford"),
        d("GAZ"),
        d("Genesis"),
        d("Honda"),
        d("Hyundai"),
        d("Infiniti"),
        d("Iveco"),
        d("Jaguar"),
        d("Jeep"),
        d("Kia"),
        d("Koenigsegg"),
        d("KTM"),
        d("Lada"),
        d("Lamborghini"),
        d("Lancia"),
        d("Land Rover"),
        d("Lexus"),
        d("Lotus"),
        d("Maserati"),
        d("Mazda"),
        d("Mercedes-Benz"),
        d("Mini"),
        d("Mitsubishi"),
        d("Nissan"),
        d("Opel"),
        d("Pagani"),
        d("Peugeot"),
        d("Polestar"),
        d("Porsche"),
        d("Range Rover"),
        d("Renault"),
        d("Rolls-Royce"),
        d("SEAT"),
        d("\u0160koda"),
        d("Smart"),
        d("Ssangyong"),
        d("Subaru"),
        d("Suzuki"),
        d("Tesla"),
        d("Toyota"),
        d("TVR"),
        d("UAZ"),
        d("Vauxhall"),
        d("Volkswagen"),
        d("Volvo"),
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_fuel: commonProp20,
    p_transmission: commonProp19,
    p_number_of_seats: [
        d("2"),
        d("4"),
        d("5"),
        d("7"),
        ["MORE_THAN_7", "v\u00edce ne\u017e 7"],
    ],
    p_number_of_doors: [
        d("2"),
        d("3"),
        d("4"),
        d("5"),
    ],
    p_year_of_manufacture: [
        d("2020"),
        d("2019"),
        d("2018"),
        d("2017"),
        d("2016"),
        d("2015"),
        d("2014"),
        d("2013"),
        d("2012"),
        d("2011"),
        d("2010"),
        d("2009"),
        d("2008"),
        d("2007"),
        d("2006"),
        d("2005"),
        d("2004"),
        d("2003"),
        d("2002"),
        d("2001"),
        d("2000"),
        d("1999"),
        d("1998"),
        d("1997"),
        d("1996"),
        d("1995"),
        d("1994"),
        d("1993"),
        d("1992"),
        d("1991"),
        d("1990"),
    ],
    p_color: commonProp4,
};
