import { wallMentionsUrl, wallUrl } from 'mk2/apps/wall/urls';
import { Route } from 'mk2/containers/Application/Application';
import { url } from 'mk2/helpers/urls';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [{
    app: 'wall',
    exact: true,
    path: wallUrl,
    page: new RedirectPage<{folder: string}>({
        to: (props) => url(wallMentionsUrl, {username: props.requestUser.username}),
        permanent: true,
        authOnly: true,
    }),
}, {
    app: 'wall',
    exact: true,
    path: wallMentionsUrl,
    getPage: () => {
        return import('mk2/apps/wall/containers/MentionsList/MentionsListPage' /* webpackChunkName: "wall.MentionsList" */);
    },
    modules: [ 'mk2/apps/wall/containers/MentionsList/MentionsListPage' ],
}];
