/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const cosmetics_bathing : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["BATHING", "Koupac\u00ed pot\u0159eby"],
        ["TOWELS", "Ru\u010dn\u00edky a osu\u0161ky"],
        ["NAPPY_CHANGING", "Na p\u0159ebalov\u00e1n\u00ed"],
        ["POTTY", "No\u010dn\u00edky a toaletn\u00ed podsed\u00e1ky"],
        ["BABY_COSMETICS", "D\u011btsk\u00e1 kosmetika"],
        ["COMBS_BRUSHES", "H\u0159ebeny, kart\u00e1\u010de, n\u016f\u017eky"],
        ["TOOTHBRUSHES", "\u010ci\u0161t\u011bn\u00ed zub\u016f"],
        ["CHANGING_TABLES", "P\u0159ebalovac\u00ed pulty"],
        ["COSMETICS_BATHING_STEP", "Stup\u00ednek, sch\u016fdek"],
        ["OTHER_COSMETICS_BATHING", "Jin\u00e9"],
    ],
};
