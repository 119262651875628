import { ACTIVE_SERVER_ID } from 'mk/settings';
import { PageMode } from 'mk2/apps/strollers/containers/StrollersSearch/StrollersSearch.constants';
import { convertStrollerType } from 'mk2/apps/strollers/helpers';
import { url, ID_RE, SLUG_RE } from 'mk2/helpers/urls';

const STROLLER_SLUG = `:slug(${SLUG_RE})`;
const STROLLER_ID = `:strollerId(${ID_RE})`;
const STROLLER_VARIANT_ID = `:variantId(${ID_RE})`;
const STROLLER_SUB_VARIANT_ID = `:strollerSubVariantId(${ID_RE})`;
const BRAND_SLUG = `:slug(${SLUG_RE})`;
const STROLLER_PHOTO_ID = `:photoId(${ID_RE})`;
const REVIEW_ID = `:reviewId(${ID_RE})`;
const PHOTO_ID = `:photoId(${ID_RE})`;

const strollersPrefixes = {
    201: 'kocikopedia',
    202: 'kocarkopedie',
};
export const strollersPrefix = strollersPrefixes[ACTIVE_SERVER_ID];

export const strollersUrl = `/${strollersPrefix}/`;

export const strollersSearchUrl = `${strollersUrl}search/:pageMode(edit|results)/`;
export const strollersSearchByTypeUrl = `${strollersUrl}:pageMode(type)/:strollerType(${SLUG_RE})/`;
export const considerStrollersSearchResultUrl = (data) => {
    const considerKeys = Object.keys(data).filter((name) => {
        return !(
            // Array of values
            (Array.isArray(data[name]) && data[name].length === 0) ||
            // Single value
            (!Array.isArray(data[name]) && !data[name]) ||
            // Ignore availability
            (name === 'availability' && data[name] === 'available')
        );
    });

    if (considerKeys.length === 1 && considerKeys[0] === 'type' && data.type.length === 1) {
        return url(strollersSearchByTypeUrl,
            {
                pageMode: PageMode.SHOW_SEARCH_RESULTS_FILTER_BY_TYPE,
                strollerType: convertStrollerType(data.type[0], '_to-'),
            },
        );
    } else {
        const pageMode = PageMode.SHOW_SEARCH_RESULTS_FILTER_BY_QUERY;
        return url(strollersSearchUrl, { pageMode }, data);
    }
};

export const strollersComparisonUrl = `${strollersUrl}comparison/`;
export const strollersChartsUrl = `${strollersUrl}charts/`;

export const strollersBrandsUrl = `${strollersUrl}brands/`;
export const strollersListUrl = `${strollersUrl}brand/${BRAND_SLUG}/`;

export const strollersWikiUrl = `${strollersUrl}w/:articleSlug(${SLUG_RE})/`;
export const strollersDetailUrl = `${strollersUrl}${STROLLER_SLUG}/`;
export const strollersDetailVariantUrl = `${strollersDetailUrl}${STROLLER_VARIANT_ID}/`;
export const strollersPhotosGalleryUrl = `${strollersDetailUrl}images/`;
export const strollersVariantPhotosGalleryUrl = `${strollersDetailUrl}images/${STROLLER_VARIANT_ID}/`;
export const strollersPhotoUrl = `${strollersDetailUrl}image/${STROLLER_PHOTO_ID}/`;
export const strollersVariantPhotoUrl = `${strollersDetailUrl}image/${STROLLER_VARIANT_ID}/${STROLLER_PHOTO_ID}/`;
export const strollersAddReviewUrl = `${strollersDetailUrl}add_review/`;
export const strollersAddReviewScoreUrl = `${strollersDetailUrl}add_review/:screen(score)/`;
export const strollersReviewsUrl = `${strollersDetailUrl}reviews/`;
export const strollersAllReviewsPhotosGalleryUrl = `${strollersReviewsUrl}images/`;
export const strollersAllReviewsPhotosUrl = `${strollersReviewsUrl}image/${PHOTO_ID}/`;
export const strollersReviewPhotoUrl = `${strollersDetailUrl}review/${REVIEW_ID}/image/${PHOTO_ID}/`;
export const strollersAddQuestionUrl = `${strollersDetailUrl}add_question/`;
export const strollersRenameUrl = `${strollersUrl}rename/${STROLLER_ID}/`;
export const strollersMergeUrl = `${strollersUrl}merge/${STROLLER_ID}/`;
export const strollersEditDescription = `${strollersUrl}detail/edit/${STROLLER_ID}/`;
export const strollersEditPhotosUrl = `${strollersUrl}edit-photos/${STROLLER_ID}/${STROLLER_SUB_VARIANT_ID}/`;
export const strollersPhotoEditorUrl = `${strollersUrl}photo-editor/${STROLLER_ID}/`;

export const strollersArticlesUrl = `${strollersUrl}articles/`;
export const strollersArticlesOfSectionUrl = `${strollersUrl}articles/:sectionSlug(${SLUG_RE})/`;

export const strollersDetailAlternateUrl = (server) => `/${strollersPrefixes[server]}/${STROLLER_SLUG}/`;
