import {
    mailNotificationsSettingsUrl,
    settingsAccountUrl,
    settingsCancelAccountUrl,
    settingsChangePasswordUrl,
    settingsKidsUrl,
    settingsKidAddUrl,
    settingsKidEditUrl, settingsLocationUrl,
    settingsProfileUrl,
    settingsUrl,
} from 'mk2/apps/settings/urls';
import { Route } from 'mk2/containers/Application/Application';
import { url, USERNAME } from 'mk2/helpers/urls';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [{
    app: 'settings',
    exact: true,
    path: mailNotificationsSettingsUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/MailNotificationsSettings/MailNotificationsSettingsPage' /* webpackChunkName: "settings.MailNotificationsSettings" */);
    },
    modules: [ 'mk2/apps/settings/containers/CancelAccount/MailNotificationsSettings' ],
}, {
    app: 'settings',
    exact: true,
    path: settingsUrl,
    page: new RedirectPage({
        to: (props) => url(settingsProfileUrl, {username: props.requestUser.username}),
        permanent: true,
        authOnly: true,
    }),
}, {
    app: 'settings',
    exact: true,
    path: `/settings/${USERNAME}/`,
    page: new RedirectPage<{username: string}>({
        to: (props) => url(settingsProfileUrl, {username: props.match.params.username}),
        permanent: true,
        authOnly: true,
    }),
}, {
    app: 'settings',
    exact: true,
    path: settingsProfileUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/Profile/ProfilePage' /* webpackChunkName: "settings.Profile" */);
    },
    modules: [ 'mk2/apps/settings/containers/Profile/ProfilePage' ],
}, {
    app: 'settings',
    exact: true,
    path: settingsLocationUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/SetLocation/SetLocationPage' /* webpackChunkName: "settings.SetLocation" */);
    },
    modules: [ 'mk2/apps/settings/containers/SetLocation/SetLocationPage' ],
}, {
    app: 'settings',
    exact: true,
    path: settingsKidsUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/Kids/KidsPage' /* webpackChunkName: "settings.Kids" */);
    },
    modules: [ 'mk2/apps/settings/containers/Kids/KidsPage' ],
}, {
    app: 'settings',
    exact: true,
    path: settingsKidAddUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/KidAdd/KidAddPage' /* webpackChunkName: "settings.KidAdd" */);
    },
    modules: [ 'mk2/apps/settings/containers/KidEdit/KidAddPage' ],
}, {
    app: 'settings',
    exact: true,
    path: settingsKidEditUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/KidEdit/KidEditPage' /* webpackChunkName: "settings.KidEdit" */);
    },
    modules: [ 'mk2/apps/settings/containers/KidEdit/KidEditPage' ],
}, {
    app: 'settings',
    exact: true,
    path: settingsAccountUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/Account/AccountPage' /* webpackChunkName: "settings.Account" */);
    },
    modules: [ 'mk2/apps/settings/containers/Account/AccountPage' ],
}, {
    app: 'settings',
    exact: true,
    path: settingsCancelAccountUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/CancelAccount/CancelAccountPage' /* webpackChunkName: "settings.CancelAccount" */);
    },
    modules: [ 'mk2/apps/settings/containers/CancelAccount/CancelAccountPage' ],
}, {
    app: 'settings',
    exact: true,
    path: settingsChangePasswordUrl,
    getPage: () => {
        return import('mk2/apps/settings/containers/AccountChangePassword/AccountChangePasswordPage' /* webpackChunkName: "settings.AccountChangePassword" */);
    },
    modules: [ 'mk2/apps/settings/containers/CancelAccount/AccountChangePassword' ],
}];
