import { AdUnit, AD_UNITS, Collapse } from 'mk/ox/hbAdUnits';
import { PrebidAdUnit } from 'mk/ox/hbTypes';
import { CURRENT_EUR_TO_CZK_RATE } from 'mk/settings';
import {
    adSlotRefresh,
    adSlotRenderEnded,
    AdSlotRefreshAction,
    AdSlotRegisterAction,
    AdSlotUnregisterAction,
    AD_SLOT_REFRESH,
    AD_SLOT_REGISTER,
    AD_SLOT_UNREGISTER,
} from 'mk2/containers/AdSlot/AdSlot.actions';
import { getLogger } from 'mk2/logger';
import { AppState } from 'mk2/reducers';
import pbjs from 'prebid.js';
import 'prebid.js/modules/adfBidAdapter'; // tslint:disable-line
import 'prebid.js/modules/sizeMappingV2'; // tslint:disable-line
import React from 'react';
import { eventChannel } from 'redux-saga';
import { all, call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

// kolko sa caka, aby externe systemy odpovedali s ponukou reklamy
const PREBID_TIMEOUT = 700;

// mapovanie cien na Line Items v DFP
// http://prebid.org/dev-docs/publisher-api-reference.html#module_pbjs.setPriceGranularity
const PREBID_PRICE_BUCKETS = {
    buckets: [{
        max: 1,
        increment: 0.05,
    }, {
        max: 2,
        increment: 0.1,
    }, {
        max: 5,
        increment: 0.5,
    }],
};

const logger = getLogger('AdSlot.sagas');

let googletag;
let initialized = false;

declare const window;

let slotRenderEndedChannel = null;
function slotRenderEndedChannelSubscribe() {
    if (!slotRenderEndedChannel) {
        slotRenderEndedChannel = new Promise((resolve) => {
            googletag.cmd.push(() => {
                const channel = eventChannel((emitter) => {
                    let subscribed = false;

                    const handler = (event: googletag.events.SlotRenderEndedEvent) => {
                        if (!event.slot) {
                            // This handler sometimes catch events from `postMessage`
                            return; // ignore
                        }

                        const slotId = event.slot.getSlotElementId();
                        emitter({ slotId, event: { isEmpty: event.isEmpty, size: event.size } });
                    };

                    googletag.pubads().addEventListener('slotRenderEnded', handler);
                    subscribed = true;
                    return () => {
                        // googletag has no removeEventListener - so we only stop emitting
                        subscribed = false;
                    };
                });

                resolve(channel);
            });
        });
    }

    return slotRenderEndedChannel;
}

function init() {
    if (initialized) {
        // Already initialized - ignore next call
        return;
    }

    initialized = true;

    pbjs.processQueue();

    googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    window.googletag = googletag;

    googletag.cmd.push(() => {
        const pubads = googletag.pubads();
        pubads.disableInitialLoad();
        pubads.enableSingleRequest();

        // Global collapse setting (can be overridden per slot)
        // Ad slots will stay empty most of the time. So, collapse all
        // divs on the page BEFORE the browser fetches any ads
        // https://support.google.com/dfp_premium/answer/3072674?hl=en
        pubads.collapseEmptyDivs(true);

        googletag.enableServices();
    });

    slotRenderEndedChannelSubscribe();

    pbjs.que.push(() => {
        pbjs.setConfig({
            priceGranularity: PREBID_PRICE_BUCKETS,
            bidderTimeout: PREBID_TIMEOUT,
            pubcid: { expInterval: 525600 }, // expiry 1 year (default)
        });

        // TODO: Move enable analytics to mk/settings as a flag
        // if (enableAnalytics) {
        //     this.pbjs.enableAnalytics({
        //         provider: 'ga',
        //         options: {
        //             global: 'ga', // <string> name of GA global. Default is 'ga'
        //             trackerName: 'pbjsTracker',
        //             enableDistribution: true,
        //         },
        //     });
        // }
    });
}

const registeredSlots = new Map<string, googletag.Slot>();
const registering = new Set<string>();
function* adSlotRegistered({ slotId, zone, targeting }: AdSlotRegisterAction) {
    // Prevent double registrations
    if (registering.has(slotId) || registeredSlots.has(slotId)) {
        return;
    }

    registering.add(slotId);

    // Allways call init - init is smart to not reinitialize yourself
    init();

    const adUnit: AdUnit = AD_UNITS[zone];

    const registerPbjs = new Promise<void>((resolve) => {
        pbjs.que.push(() => {
            if (!registering.has(slotId)) {
                return;
            }

            const pbjsAdUnit: PrebidAdUnit = {
                code: slotId,
                bids: adUnit.bids,
                mediaTypes: {
                    banner: {
                        name: adUnit.name,
                        sizeConfig: adUnit.sizeConfig.map((sc) => ({
                            minViewPort: [sc.minScreenWidth, 0],
                            sizes: sc.bannerSizes,
                        })),
                    },
                },
            };

            pbjs.addAdUnits([pbjsAdUnit]);
            resolve();
        });
    });

    const registerGoogleTag = new Promise((resolve) => {
        googletag.cmd.push(() => {
            if (!registering.has(slotId)) {
                return;
            }

            // https://support.google.com/dfp_premium/answer/3423562?hl=en
            let gptSizeMappingBuilder: googletag.SizeMappingBuilder = googletag.sizeMapping();
            const allSlotSizes = [];
            adUnit.sizeConfig.forEach((sc) => {
                const screenSize = [sc.minScreenWidth, 0];
                const slotSizes: googletag.SingleSize[] = [...sc.bannerSizes];
                if (sc.native) slotSizes.push('fluid');
                allSlotSizes.push(...slotSizes);
                gptSizeMappingBuilder = gptSizeMappingBuilder.addSize(screenSize, slotSizes);
            });
            const gptSizeMapping: googletag.SizeMappingArray = gptSizeMappingBuilder.build();

            const gSlot = (
                !slotId.startsWith('mk-interstitial')
                    ? googletag.defineSlot(adUnit.gptid, allSlotSizes, slotId)
                    : googletag.defineOutOfPageSlot(adUnit.gptid, slotId)
                )
                .defineSizeMapping(gptSizeMapping)
                .addService(googletag.pubads());

            if (targeting) {
                Object.keys(targeting).forEach((key: string) => {
                    gSlot.setTargeting(key, targeting[key]);
                });
            }

            // set per zone collapsing (before fetch/after fetch/never)
            // https://support.google.com/dfp_premium/answer/3072674?hl=en
            if (adUnit.collapse !== undefined) {
                switch (adUnit.collapse) {
                    case Collapse.NEVER:
                        gSlot.setCollapseEmptyDiv(false);
                        break;
                    case Collapse.AFTER_FETCH:
                        gSlot.setCollapseEmptyDiv(true);
                        break;
                    case Collapse.BEFORE_FETCH:
                        gSlot.setCollapseEmptyDiv(true, true);
                        break;
                    default:
                        // pass
                }
            }

            // For proper behavior across all browsers, calling refresh must be
            // preceded by a call to display the ad slot.
            googletag.display(slotId);
            resolve(gSlot);
        });
    });

    const [ slot ] = yield call(() => Promise.all([ registerGoogleTag, registerPbjs ]));

    if (registering.delete(slotId)) {
        registeredSlots.set(slotId, slot);
        yield put(adSlotRefresh(slotId));
    }
}

function* adSlotUnregistered({ slotId }: AdSlotUnregisterAction) {
    if (!registeredSlots.has(slotId) // Slot is not registered
        && !registering.has(slotId)) { // Slot is not scheduled for register
        return;
    }

    init();

    const unregisterPbjs = new Promise<void>((resolve) => {
        pbjs.que.push(() => {
            if (registeredSlots.has(slotId)) {
                pbjs.removeAdUnit(slotId);
            }
            resolve();
        });
    });

    const unregisterGoogleTag = new Promise<void>((resolve) => {
        googletag.cmd.push(() => {
            if (registeredSlots.has(slotId)) {
                googletag.destroySlots([registeredSlots.get(slotId)]);
            }
            resolve();
        });
    });

    yield call(() => Promise.all([ unregisterPbjs, unregisterGoogleTag ]));

    registeredSlots.delete(slotId);
}

const slotsToRefresh = new Set<string>();
function* adSlotRefreshed({ slotId }: AdSlotRefreshAction) {
    if (!registeredSlots.has(slotId) // Slot is not registered
        && !registering.has(slotId)) { // Slot is not scheduled for register
        return;
    }

    slotsToRefresh.add(slotId);

    // yield call(() => new Promise((resolve) => requestAnimationFrame(resolve)));
    yield delay(0);

    pbjs.aliasBidder('adf', 'adform_sk');

    pbjs.que.push(() => {
        // Now we can refresh ads
        const gSlots = [];
        const slotsIds = [];
        slotsToRefresh.forEach((id: string) => {
            if (registeredSlots.has(id)) {
                gSlots.push(registeredSlots.get(id));
                slotsIds.push(id);
            }
        });
        slotsToRefresh.clear();

        // Some slots can be already unregistered
        if (gSlots.length === 0) {
            return;
        }

        // call bidders
        pbjs.requestBids({
            timeout: PREBID_TIMEOUT,
            adUnitCodes: slotsIds,
            bidsBackHandler: () => {
                googletag.cmd.push(() => {
                    // send request to DFP to display the best bid
                    pbjs.setTargetingForGPTAsync(slotsIds);

                    // DFP compares the best bid with offers from ADX (Google's ad exchange)
                    // and displays the priciest ad
                    googletag.pubads().refresh(gSlots);
                });
            },
        });
    });
}

function* emitRenderEnded() {
    init();

    const channel = yield call(slotRenderEndedChannelSubscribe);
    try {
        while (true) {
            const { slotId, event } = yield take(channel);
            yield put(adSlotRenderEnded(slotId, event));
        }
    } catch (err) {
        logger.error(err);
    }
}

function* registerAdSlotsFromServer() {
    const slots = yield select((state: AppState) => state.response.registeredSlots);

    for (const key of Object.keys(slots)) {
        const slot = slots[key];
        yield adSlotRegistered(slot);
    }
}

export default function* root() {
    if (!process.env.SERVER && window.location?.search?.includes?.('__disable_ads=true')) {
        return null;
    }

    yield all([
        takeEvery(AD_SLOT_REGISTER, adSlotRegistered),
        takeEvery(AD_SLOT_UNREGISTER, adSlotUnregistered),
        takeLatest(AD_SLOT_REFRESH, adSlotRefreshed),

        process.env.SERVER ? null : fork(emitRenderEnded),
        process.env.SERVER ? null : fork(registerAdSlotsFromServer),
    ]);
}
