import { CLICK_HERE, SESSION_TIME_OUT_TO_RELOAD_link } from 'mk/autogenerated/translations/InvalidCSRF.75011339a6330297d91bbc90bf3aa539'
import { Interpolate } from 'mk2/components/Interpolate';
import React from 'react';

interface OwnProps {
    action: (() => void);
}

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const InvalidCSRF: React.FunctionComponent<Props> = React.memo(({
    action,
}) => {
    return <Interpolate
                i18nKey={SESSION_TIME_OUT_TO_RELOAD_link}
                link={<a style={{ cursor: 'pointer' }} onClick={action}>{CLICK_HERE}</a>}
            />;
});

export function InvalidCSRFToast(action?: () => void) {
    if (!action && typeof location !== 'undefined') {
        const reload = () => {
            location.reload();
        };
        return <InvalidCSRF action={reload} />;
    }
    return <InvalidCSRF action={action} />;
}

InvalidCSRF.displayName = 'InvalidCSRF';
