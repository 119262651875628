/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp23 } from './commonProp23';

export const work_abroad : {[key: string]: Array<[string, string]>} = {
    p_homeoffice: commonProp23,
};
