import { isBrandingDesktopAdUnit, isMailAdUnit, AdUnit, Collapse } from 'mk/ox/hbAdUnits';
import { AdUnitScreenSizeConfig } from 'mk/ox/hbSizes';
import { cacheLast } from 'mk2/helpers/cache';

const maxHeightWithAdCache = cacheLast(true);

function getMaxHeightWithAdUncached(adUnit: AdUnit, isMobile: boolean) {
    const displayAdSlotPlaceholder = adUnit.collapse === Collapse.NEVER || adUnit.collapse === Collapse.AFTER_FETCH;
    if (displayAdSlotPlaceholder) {
        let maxScreenHeight = null;
        const screenWidth = typeof window !== 'undefined'
            ? document.body.clientWidth
            : isMobile ? 599 : 980;
        adUnit.sizeConfig.forEach((ssc: AdUnitScreenSizeConfig) => {
            if (ssc.minScreenWidth <= screenWidth) {
                ssc.bannerSizes.forEach((ss) => {
                    if (
                        (ss[1] > maxScreenHeight || maxScreenHeight === null)
                        // handling branding/mail AdUnit, which have specially set height
                        && !(ss[1] === 1400 && isBrandingDesktopAdUnit(adUnit))
                        && !(isMailAdUnit(adUnit))
                    ) {
                        maxScreenHeight = ss[1];
                    }
                });
            }
        });
        return [82, 125, 250, 280, 300, 600].includes(maxScreenHeight) ? maxScreenHeight : null;
    }
    return null;
}


export function getMaxHeightWithAd(adUnit: AdUnit, isMobile: boolean) {
    return maxHeightWithAdCache(() => getMaxHeightWithAdUncached(adUnit, isMobile), adUnit, typeof window);
}
