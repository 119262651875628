/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp8 } from './commonProp8';
import { commonProp9 } from './commonProp9';
import { commonProp10 } from './commonProp10';
import { commonProp7 } from './commonProp7';
import { commonProp11 } from './commonProp11';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const skirts : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["SKIRTS", "Sukn\u011b"],
        ["DUNGAREE_DRESSES", "Sukn\u011b s laclem, \u0161atovka"],
    ],
    p_usage: commonProp8,
    p_season: commonProp9,
    p_sex: commonProp10,
    p_size: commonProp7,
    p_length: commonProp11,
    p_brand: commonProp6,
    p_color: commonProp4,
};
