/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp22 } from './commonProp22';

export const caretaking : {[key: string]: Array<[string, string]>} = {
    p_practice_length: commonProp22,
    p_frequency: [
        ["IRREGULARLY", "Nepravideln\u011b"],
        ["EVERY_DAY", "Ka\u017ed\u00fd den"],
        ["ONCE_TWICE_PER_WEEK", "1-2x za t\u00fdden"],
        ["OTHER_FREQUENCY", "Jin\u00e1"],
    ],
};
