import { PaymentMethod } from 'mk2/apps/bazaar/containers/Checkout/CheckoutForm.constants';
import { createLikeableSchema, LikeableEntity } from 'mk2/containers/Like/Like.schemas';
import { UploadedPhoto } from 'mk2/containers/PhotosUpload/PhotosUpload';
import { createStarrableSchema, StarrableEntity } from 'mk2/containers/Star/Star.schemas';
import {
    ComplaintSchema,
    Entity,
    PhotoEntity, PhotoSchema, ProfileEntity, ProfileSchema,
    StrollerBrandEntity,
    StrollerEntity,
    StrollerVariantEntity,
    UserEntity, UserSchema,
} from 'mk2/schemas';
import { schema } from 'normalizr';
import { DateTime } from 'schema-dts';

export interface QualityCounts {
    old: number;
    new: number;
    handmade: number;
    services: number;
    jobs: number;
}

export interface SellerEntity extends Entity {
    user: UserEntity;
    username: string; // Needed so that we can search seller by username (findDenormalizedEntityByCond)
    productsCount: number; // Number of active seller products
    reviewsCount: number;
    reviewsFromBuyersCount: number;
    reviewsFromSellersCount: number;
    hasBlockedBazaar: boolean;
    terms: string;
    location: string;
    statusMessage: string;
    likeable: LikeableEntity;
    showSellerPhone: boolean;

    // My seller data
    hasAlreadyAddedProduct?: boolean;
    transactionsCount?: number;
    myProductsCount?: number;
    availableReposts?: number;
    countyId?: number;
    placeId?: number;

    // Vendor
    vendorName?: string;
}

export const SellerLikeableSchema = createLikeableSchema('users.profile');

export const SellerSchema = new schema.Entity('bazaar.bazaar', {
    likeable: SellerLikeableSchema,
});

export enum ProductQuality {
    NEW = 'new',
    OLD = 'old',
    HANDMADE = 'handmade',
    SERVICES = 'services',
    JOBS = 'jobs', // TODO consider to clean up ProductQuality.JOBS
    ESHOP = 'eshop',
}

export enum ProductStockStatus {
    NOT_IN_STOCK,
    IN_STOCK,
}

export enum ProductPricePer {
    PER_MONTH = 1,
    PER_HOUR = 2,
}

export enum FilterType {
    BAZAAR = 'BAZAAR',
    MY_BAZAAR = 'MY_BAZAAR',
    HER_BAZAAR = 'HER_BAZAAR',
    WISHLIST = 'WISHLIST',
}

export enum OrderStatus {
    NONE = 0,
    RESERVED = 1,
    SHIPPED = 2,
    DELIVERED = 3,
    PICKED_UP = 4,
}

export enum RepostableStatus {
    CREATED_RECENTLY = 'created_recently', // just created - minutes
    REPOSTED_RECENTLY = 'reposted_recently', // just reposted -minutes
    NOT_REPOSTABLE = 'not_repostable', // already active, hidden, deleted category...etc
    REPOSTABLE_FREE = 'repostable_free', // free to repost
    REPOSTABLE_PAID = 'repostable_paid', // aready reposted - have wait some time (1-7 days)
    REPOSTABLE_ENTER_FACETS = 'repostable_enter_facets', // listing has to be modified - missing facets, etc
    REPOSTABLE_NOT_AVAILABLE = 'repostable_not_available', // reposts over limit - cannot repost anymore fornow
    REPOSTING = 'reposting', // reposting request pending
    REPOSTING_FAILED = 'reposting_failed', // other error of reposting
}

export enum DeletedReason {
    DELETED_REASON_COPYRIGHT_PHOTOS = 1,
    DELETED_REASON_WRONG_PRICE = 2,
    DELETED_REASON_INVISIBLE_PRODUCT = 3,
    DELETED_REASON_DONT_KNOW_WHAT_SELLING = 4,
    DELETED_REASON_NOT_FOR_TWINS = 5,
    DELETED_REASON_NOT_ALLOWED = 6,
    DELETED_REASON_MULTIPLE_PRODUCTS = 7, // Not used for new products but it must exists for legacy
    DELETED_REASON_SERVICE = 8,
    DELETED_REASON_LANGUAGE = 9,
    DELETED_REASON_NOT_ALLOWED_DUPLICATE = 10,
    DELETED_REASON_NOT_ALLOWED_DRUGS = 11,
    DELETED_REASON_NOT_ALLOWED_OPEN = 12,
    DELETED_REASON_NOT_ALLOWED_ANNOUNCEMENT = 13,
    DELETED_REASON_NOT_ALLOWED_INQUIRY = 14,
    DELETED_REASON_NOT_ALLOWED_CONTEXT = 15, // Not used for new products but it must exists for legacy
    DELETED_REASON_ANOTHER_BAZAAR = 16,
}

export interface ProductPrice {
    startTime: string;
    endTime: string;
    price: string;
}

export interface ProductPhotoFile extends Entity {
    product?: ProductEntity;
    position: number;
    photofile: PhotoEntity;
}

// keep in sync with Product.PACKAGE_SIZE_*
export enum PackageSize {
    X2 = 2,
    X3 = 3,
}

export interface ProductEntity extends Entity {
    slug: string;
    slugsHistory: string[];
    title: string;
    photos?: ProductPhotoFile[];
    seo: {
        name: string;
        description: string;
    };
    state: {
        isActive: boolean;
        isInactive: boolean;
        isExpired: boolean;
        isDeleted: boolean;
        isHidden: boolean;
    };
    // CeMi: 30.6.2023 orderStatus je na vymazanie.
    // Je nespravne ho mat per Product, lebo product moze mat viacero objednavok (niektore neuspesne, zrusene),
    // ostatne cakajuce na vybevenie. Produkty prockarov mozu mat dokonca niekolko usepesnych objednavok naraz
    // (lebo nove veci sa daju kupit viac krat)
    orderStatus?: number;
    packageSize: PackageSize | null;
    description: string;
    price: string;
    priceOld: string;
    pricePer: number;
    quality: ProductQuality;
    seller: SellerEntity;
    properties: Array<[string, string, string]>;
    displayProperties: string[];
    stockStatus: ProductStockStatus;
    createdTime: string;
    startTime: string;
    endTime: string | null;
    deletedReason: DeletedReason;
    prices: ProductPrice[];
    isNoads: boolean;
    starrable?: StarrableEntity;
    placeId: number;
    countyId: number;
    countryId: number;

    // My Product data
    buyersCount?: number;
    messagesCount?: number;
    newMessagesCount?: number;
    repostableStatus?: RepostableStatus;
    repostableFreeIn?: number; // Number of days

    // Pro users
    eshopUrl?: string;

    // Admins
    deletedBy?: string; // Username of admin which deleted this product
    historyCount?: number;
}

export const ProductPhotoFileSchema = new schema.Entity('bazaar.productphotofile', {});

export const ProductSchema = new schema.Entity('bazaar.product', {
    seller: SellerSchema,
    starrable: createStarrableSchema('bazaar.product'),
    photos: [ProductPhotoFileSchema],
});

ProductPhotoFileSchema.define({
    product: ProductSchema,
});

export enum ReviewRating {
    UNSATISFIED = -1,
    SATISFIED = 1,
    VERY_SATISFIED = 2,
}

export enum ReviewRole {
    BUYER = 'buyer',
    SELLER = 'seller',
}

export interface ReviewEntity extends Entity {
    date: string;
    role: ReviewRole;
    rating: ReviewRating;
    message: string;
    reviewed: UserEntity;
    reviewer: UserEntity;
    product: ProductEntity;
    photo: PhotoEntity;
    replyDate: string;
    replyMessage: string;
    replyPhoto: PhotoEntity;
    isNew: boolean;
}

export const ReviewSchema = new schema.Entity('bazaar.review', {
    product: ProductSchema,
});

export interface PickupPointEntity extends Entity {
    name: string;
    street: string;
    zipCode: string;
    city: string;
    latitude: number;
    longitude: number;
    pickupPointId: string;
}

export const PickupPointSchema = new schema.Entity('bazaar.pickuppoint', {});

export interface BuyerEntity extends Entity {
    user: UserEntity;
    product: ProductEntity;
    // TODO: Deprecated -> use seller.location
    location: string;
    seller: SellerEntity; // Seller for buyer -> not real seller as product.seller
    messagesCount: number;
    newMessagesCount: number;
    lastMessage?: BazaarMessageEntity;
    // TODO: Deprecated -> use lastMessage.sentDate
    lastMessageAt: string;
    // TODO: Deprecated -> use lastMessage.isSeller
    isLastMessageFromSeller: boolean;
}

export const BuyerSchema = new schema.Entity('bazaar.buyer', {});

export interface SellersCustomerEntity extends Entity {
    buyer: UserEntity;
    seller: UserEntity;
    notes: string;
}

export const SellersCustomerSchema = new schema.Entity('bazaar.sellerscustomer', {});

export interface BazaarMessagePhotoEntity extends Entity {
    message: BazaarMessageEntity;
    photofile: PhotoEntity;
}

export enum BazaarMessageType {
    FromBuyer = 1,
    FromSeller = 2,
    SystemReservedFromBuyer= 3,
}

export interface BazaarMessageEntity extends Entity {
    buyer: BuyerEntity;
    // TODO: Deprecated -> use buyer.seller.user
    seller: UserEntity;
    message: string;
    messageType: BazaarMessageType;
    sentDate: string;
    // TODO: Deprecated -> use messagePhotos.map((m) => m.photofile)
    photos?: PhotoEntity[];
    messagePhotos?: BazaarMessagePhotoEntity[];
    isSeller: boolean;
    isNew: boolean;
    viewDate: string;
}


export enum DeliveryCompany {
    DPD = 'dpd',
    Packeta = 'packeta',
}

export enum Currency {
    Euro = '€',
    CzechKrown = 'Kč',
}

// keep in sync with BazaarOrder.STATUS_* in django
export enum BazaarOrderStatus {
    STATUS_RESERVED = 100,
    STATUS_PAID = 150,
    STATUS_RESERVED_CONFIRMED = 200,
    STATUS_SHIPPED = 300,
    STATUS_DELIVERED = 400,
    STATUS_PICKED_UP = 500,
    STATUS_PROBLEM = 600,
    STATUS_DELETED = 660,
    STATUS_EXPIRED = 680,
    STATUS_FINISHED = 700,

    OLD_RESERVED = 1,
    OLD_RESERVED_CONFIRMED = 2,
    OLD_SHIPPED = 3,
    OLD_DELIVERED = 4,
    OLD_PICKED_UP = 5,
    OLD_PROBLEM = 55,   // problem s objednavkou
    OLD_DELETED = 505,  // bola stornovana
    OLD_EXPIRED = 510,  // neodoslal do 5 dni
    OLD_FINISHED = 600,
}

export interface BazaarOrderPersonEntity extends Entity {
    name: string;
    point: PickupPointEntity;
    bankAccount?: string;
    phoneNumber: string;
    email: string;
}

export interface BazaarOrderEntity extends Entity {
    slug: string; // note: id === slug
    labelValidTill: string;
    sellerUser: UserEntity;
    buyerUser: UserEntity;
    productId: number;
    status: BazaarOrderStatus;
    shippingCarrier: DeliveryCompany;
    pickupPoint: PickupPointEntity;
    dropoffPoint: PickupPointEntity;
    ticketId: number;
    paymentMethod: PaymentMethod;
    label: string;

    reservedAt: string;
    paidAt: string;
    reservedConfirmedAt: string;
    shippedAt: string;
    deliveredAt: string;
    pickedUpAt: string;
    finishedAt: string;
    problemAt: string;
    deletedAt: string;
    expiredAt: string;

    mpsId: string;

    sellerName: string;
    sellerPhone: string;
    sellerEmail: string;
    sellerBankAccount: string;

    buyerName: string;
    buyerPhone: string;
    buyerEmail: string;

    productPrice: string;
    productDeliveryPrice?: string;

}

export const BazaarOrderSchema = new schema.Entity('bazaar.bazaarorder', {
});

export const BazaarMessagePhotoSchema = new schema.Entity('bazaar.messagephoto', {});

export const BazaarMessageSchema = new schema.Entity('bazaar.message', {
    buyer: BuyerSchema,
    messagePhotos: [BazaarMessagePhotoSchema],
});

BuyerSchema.define({
    product: ProductSchema,
    seller: SellerSchema,
    lastMessage: BazaarMessageSchema,
});

BazaarMessagePhotoSchema.define({
    message: BazaarMessageSchema,
});

export const ComplaintsPerProductSchema = new schema.Values([ComplaintSchema]);

export interface MarkEntity extends Entity {
    admin: UserEntity;
    createdTime: string;
    targetId: number;
}

export const MarkSchema = new schema.Entity('admins.adminlog', {});

export interface WatchdogEntity extends Entity {
    path: string;
    filter: any;
    isBroken: boolean;
}

export enum XMLImportStatus {
    RUNNING,
    SUCCESS,
    FAILED,
    INIT,
}

export interface XMLImportStatusEntity extends Entity {
    createdAt: string;
    updatedAt: string;
    finishedAt: string;
    log: string;
    status: XMLImportStatus;
    feedCount: number;
    deletedCount: number;
    failedCount: number;
    importedCount: number;
    repostedCount: number;
    createdCount: number;
    updatedCount: number;
    activatedCount: number;
    deactivatedCount: number;
}

export const XMLImportStatusSchema = new schema.Entity('strollers.xmlimportstatus', {});

export interface XMLImportItemEntity extends Entity {
    createdAt: string;
    updatedAt: string;
    ignoredAt: string;
    approvedAt: string;
    stroller: StrollerEntity;
    strollerBrand: StrollerBrandEntity;
    strollerVariant: StrollerVariantEntity;
    itemId: string;
    current: {
        id: string;
        category: string;
        description: string;
        eshopUrl: string;
        groupId: string;
        manufacturer: string;
        photos: string[];
        price: number;
        originalPrice: string;
        title: string;
        stock: ProductStockStatus;
    };
    xmlImportFeed: XMLImportFeedEntity;
}

export const XMLImportItemSchema = new schema.Entity('strollers.xmlimportitem');

export interface XMLImportFeedEntity extends Entity {
    eshopLogoPhoto: PhotoEntity;
    lastXmlImportStatus: XMLImportStatusEntity;
    feed: string;
    format: string;
    currency: string;
    name: string;
    categoryFilterRe: string;
    feedCount: number;
    queueCount: number;
    processedCount: number;
    ignoredCount: number;
    createdAt: string;
    updatedAt: string;
    pausedAt: string;
}

export const XMLImportFeedSchema = new schema.Entity('strollers.xmlimportfeed');
