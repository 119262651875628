// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const CAREER = "Kari\u00e9ra";
export const CAREER_URL = "http://www.modrykonik-hleda.com";
export const COMMERCIAL = "Informace pro market\u00e9ry";
export const COMMERCIAL_URL = "https://www.modrykonik-marketerum.cz/?utm_source=modrykonik.cz&utm_campaign=footer";
export const COOKIE_POLICY_PAGE = "Cookie pravidla";
export const DATA_PRIVACY = "Ochrana osobn\u00edch \u00fadaj\u016f";
export const FOLLOW_US_ON_FACEBOOK_URL = "https://i.modrykonik.cz/facebook_Modreho_konika";
export const FOOTER_CODEX_LINK = "Kodex";
export const FOOTER_CONTACT = "Kontakt";
export const FOOTER_HELP_LINK = "Zeptej se n\u00e1s";
export const INSTAGRAM_MK_LINK = "https://www.instagram.com/modrykonik_cz/";
export const RULES_BAZAAR = "Pravidla bazaru";
export const RULES_COMMON = "V\u0161eobecn\u00e9 podm\u00ednky pou\u017e\u00edv\u00e1n\u00ed";
export const STROLLER_STROLLEROPEDIA = "Ko\u010d\u00e1rkopedie";
