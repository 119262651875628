import {
    cardPaymentUrl,
    paymentFailureUrl,
    paymentSuccessUrl,
    planChoosePlanUrl,
    planGetPlusUrl,
    planGetProUrl,
    planPlanForUrl,
    smsPaymentUrl,
} from 'mk2/apps/plan/urls';
import { Route } from 'mk2/containers/Application/Application';

export const routes: Route[] = [{
    app: 'plan',
    exact: true,
    path: planGetPlusUrl,
    getPage: () => {
        return import('mk2/apps/plan/containers/GetPlus/GetPlusPage' /* webpackChunkName: "plus.GetPlus" */);
    },
    modules: [ 'mk2/apps/plus/containers/GetPlus/GetPlusPage' ],
}, {
    app: 'plan',
    exact: true,
    path: planGetProUrl,
    getPage: () => {
        return import('mk2/apps/plan/containers/GetPro/GetProPage' /* webpackChunkName: "plus.GetPro" */);
    },
    modules: [ 'mk2/apps/plus/containers/GetPro/GetProPage' ],
}, {
    app: 'plan',
    exact: true,
    path: planChoosePlanUrl,
    getPage: () => {
        return import('mk2/apps/plan/containers/PlanChoose/PlanChoosePage' /* webpackChunkName: "plus.PlanChoose" */);
    },
    modules: [ 'mk2/apps/plus/containers/PlanChoose/PlanChoosePage' ],
}, {
    app: 'plan',
    exact: true,
    path: planPlanForUrl,
    getPage: () => {
        return import('mk2/apps/plan/containers/PlanFor/PlanForPage' /* webpackChunkName: "plus.PlanFor" */);
    },
    modules: [ 'mk2/apps/plus/containers/PlanFor/PlanForPage' ],
},
    { // uspesna platba
    app: 'plan',
    exact: true,
    path: paymentSuccessUrl,
    getPage: () => {
        return import('mk2/apps/plan/containers/PaymentSuccess/PaymentSuccessPage' /* webpackChunkName: "plan.PaymentSuccess" */);
    },
    modules: [ 'mk2/apps/plus/containers/PaymentSuccess/PaymentSuccessPage' ],
},
     {  // neuspesna platba
    app: 'plan',
    exact: true,
    path: paymentFailureUrl,
    getPage: () => {
        return import('mk2/apps/plan/containers/PaymentFailure/PaymentFailurePage' /* webpackChunkName: "plan.PaymentFailure" */);
    },
    modules: [ 'mk2/apps/plus/containers/PaymentFailure/PaymentFailurePage' ],
},
    {  // cakanie platby
    app: 'plan',
    exact: true,
    path: smsPaymentUrl,
    getPage: () => {
        return import('mk2/apps/plan/containers/SmsPayment/SmsPaymentPage' /* webpackChunkName: "plan.SmsPayment" */);
    },
    modules: [ 'mk2/apps/plus/containers/SmsPayment/SmsPaymentPage' ],
},
    {
    app: 'plan',
    exact: true,
    path: cardPaymentUrl,
    getPage: () => {
        return import('mk2/apps/plan/containers/CardPayment/CardPaymentPage' /* webpackChunkName: "plus.CardPayment" */);
    },
    modules: [ 'mk2/apps/plus/containers/CardPayment/CardPaymentPage' ],
}];
