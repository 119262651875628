/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp21: Array<[string, string]> = [
    ["THEMA_ALL_YEAR_ROUND", "Celoro\u010dn\u00ed"],
    ["THEMA_XMAS", "V\u00e1noce"],
    ["THEMA_EASTER", "Velikonoce"],
    ["THEMA_HALLOWEEN", "Halloween"],
    ["THEMA_SPRING", "Jaro"],
    ["THEMA_SUMMER", "L\u00e9to"],
    ["THEMA_AUTUMN", "Podzim"],
    ["THEMA_WINTER", "Zima"],
];
