import isString from 'lodash-es/isString';
import React from 'react';
import createFragment from 'react-addons-create-fragment'; // TODO: Remove after fixing filters !!!

type ReactChild = React.ReactChild;

export type TextLike = string | string[] | ReactChild[];

/**
 * Merges multiple string fragments into one continuous, e.g.:
 * ['this', ' ', 'is', ' ', 'text', <img>] -> ['this is text', <img>]
 *
 * to be investigated: final string item is needed by React for some reason
 */
function mergeStrings(arr: ReactChild[]): ReactChild[] {
    if (arr.length <= 1) {
        return arr;
    }

    const result: ReactChild[] = [];
    let text = '';

    for (let i = 0; i < arr.length; i++) {
        if (isString(arr[i])) {
            text += arr[i];
            continue;
        }
        if (text) {
            result.push(text);
            text = '';
        }
        result.push(arr[i]);
    }

    if (text) {
        result.push(text);
    }

    return result;
}

/**
 * Each filter function (such as smileize, linebreaksbr) takes
 * as input a String and outputs array of Strings and ReactElements
 *
 * This functions takes care of flattening the results when chaining
 * more filters.
 *
 * @param text
 * @param filterFunc
 * @returns returns array created by createFragment or single item (string or react element)
 */
export function reactFilter(text: TextLike, filterFunc: (s: string) => ReactChild[]): ReactChild[] {
    // wrap input to array
    const texts: ReactChild[] = Array.isArray(text) ? text : [text];

    // map every text in texts to array of strings and react elements
    // and flatten results to single array
    const results = texts.reduce((ret, rc: ReactChild) => {
        if (React.isValidElement(rc)) {
            // skip if already a react element
            ret.push(rc);
        } else {
            // in number, convert to string
            const s: string = isString(rc) ? rc : `${rc}`;

            let fret: ReactChild[] = filterFunc(s);
            fret = mergeStrings(fret);
            ret.push(...fret);
        }

        return ret;
    }, []);

    // add keys and return as fragment to fix react-warning-keys
    const keyResult = {};
    results.forEach((rc, idx) => {
        keyResult['f' + idx] = rc;
    });
    return createFragment(keyResult) as ReactChild[];

    // return results.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
}
