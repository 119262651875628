/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp4: Array<[string, string]> = [
    ["WHITE", "B\u00edl\u00e1"],
    ["CREAMY", "B\u00e9\u017eov\u00e1"],
    ["PALE_YELLOW", "Sv\u011btle \u017elut\u00e1"],
    ["STRONG_YELLOW", "\u017dlut\u00e1"],
    ["OCHER", "Ho\u0159\u010dicov\u00e1"],
    ["ORANGE", "Oran\u017eov\u00e1"],
    ["SALMON_ORANGE", "Lososov\u00e1"],
    ["RED", "Syt\u011b \u010derven\u00e1"],
    ["WINE_RED", "V\u00ednov\u00e1"],
    ["PALE_PINK", "Sv\u011btle r\u016f\u017eov\u00e1"],
    ["STRONG_PINK", "Syt\u00e1 r\u016f\u017eov\u00e1"],
    ["PURPLE", "Fialov\u00e1"],
    ["PASTEL_VIOLET", "\u0160edofialov\u00e1"],
    ["PALE_BLUE", "Bled\u011b modr\u00e1"],
    ["DARK_BLUE", "Tmav\u011b modr\u00e1"],
    ["TURQUOISE_BLUE", "Tyrkysov\u011b modr\u00e1"],
    ["MINT", "M\u00e1tov\u00e1"],
    ["PALE_GREEN", "Bled\u011b zelen\u00e1"],
    ["DARK_GREEN", "Tmav\u011b zelen\u00e1"],
    ["OLIVE_GREEN", "Olivov\u00e1 / khaki"],
    ["LIGHT_BROWN", "Sv\u011btle hn\u011bd\u00e1"],
    ["DARK_BROWN", "Tmav\u011b hn\u011bd\u00e1"],
    ["LIGHT_GREY", "Sv\u011btle \u0161ed\u00e1"],
    ["DARK_GREY", "Tmav\u011b \u0161ed\u00e1"],
    ["DEEP_BLACK", "\u010cern\u00e1"],
    ["TRANSPARENT", "Pr\u016fsvitn\u00e1 / pr\u016fhledn\u00e1"],
    ["GOLD", "Zlat\u00e1"],
    ["SILVER", "St\u0159\u00edbrn\u00e1"],
    ["COPPER", "M\u011bd\u011bn\u00e1"],
    ["BLACK_AND_WHITE", "\u010cernob\u00edl\u00e1"],
    ["CRAZY_COLOR_MIX", "\u0160\u00edlen\u011b v\u00edcebarevn\u00e9"],
];
