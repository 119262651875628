/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const tickets_vouchers : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["TICKETS", "Vstupenky"],
        ["VOUCHERS", "Poukazy"],
        ["FLIGHT_TICKETS", "Letenky"],
    ],
};
