import cx from 'classnames';
import {
    FOOTER_DOWNLOAD_ON_APP_STORE,
    INSTALL_PROMPT_DESCRIPTION,
    INSTALL_PROMPT_DOWNLOAD_APP,
} from 'mk/autogenerated/translations/InstallPrompt.095a4b8e705c015869e339bb91701182'
import assetUrl from 'mk/common/assetUrl';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Img } from 'mk2/components/Img';
import Sticker, { StickerSize } from 'mk2/components/Sticker';
import { installPromptInstall } from 'mk2/containers/InstallPrompt/InstallPrompt.actions';
import { getInstallPromptState } from 'mk2/containers/InstallPrompt/InstallPrompt.selectors';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import useEventCallback from 'mk2/hooks/useEventCallback';
import { getRequestDeviceMobile, AppState } from 'mk2/reducers';
import React from 'react';
import { connect } from 'react-redux';
import styles from './InstallPrompt.mscss';

interface OwnProps {
    className?: string;
}

interface StateProps {
    ready: boolean;
    isMobile: boolean;
}

interface DispatchProps {
    onInstall();
}

type Props = OwnProps & StateProps & DispatchProps;

export const InstallPrompt: React.FunctionComponent<Props> = ({ className, onInstall, isMobile, ready }) => {
    const onInstallCallback = useEventCallback(
        (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            event.preventDefault();
            onInstall();
        },
        [onInstall],
    );

    if (!ready) {
        return null;
    }

    if (isMobile) {
        return (
            <div className={cx(styles.InstallPrompt, className, styles.InstallPrompt__mobile)}>
                <Sticker className={styles.InstallPrompt__mobile__icon} id={1} size={StickerSize.Bigger} />
                <div className={styles.InstallPrompt__mobile__content}>
                    <div className={styles.InstallPrompt__mobile__content__text}>{INSTALL_PROMPT_DESCRIPTION}</div>
                    <Btn
                        className={styles.InstallPrompt__mobile__content__cta}
                        type={BtnType.Blue}
                        label={INSTALL_PROMPT_DOWNLOAD_APP}
                        onClick={onInstallCallback}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <button
                className={cx(styles.InstallPrompt, className, styles.InstallPrompt__desktop)}
                onClick={onInstallCallback}
                type="button"
            >
                <Img
                    width={135}
                    height={40}
                    src={`${assetUrl}img/misc/add_to_homescreen_${ACTIVE_SERVER_ID}@2x.png`}
                    alt={INSTALL_PROMPT_DOWNLOAD_APP}
                />
            </button>
        );
    }
};

InstallPrompt.displayName = 'InstallPrompt';

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const substate = getInstallPromptState(state);
    return {
        ready: substate.ready,
        isMobile: getRequestDeviceMobile(state),
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onInstall: installPromptInstall,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallPrompt);
