/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp18 } from './commonProp18';
import { commonProp4 } from './commonProp4';

export const camera_photo_video : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["DIGITAL_CAMERA", "Digit\u00e1ln\u00ed fotoapar\u00e1t"],
        ["FILM_CAMERA", "Klasick\u00fd fotoapar\u00e1t (na film)"],
        ["VIDEO_CAMERA", "Videokamera"],
        ["PHOTO_CAMERA_BAGS_CASES", "Obaly a ta\u0161ky"],
        ["PHOTO_CAMERA_ACCESSORIES", "Dopl\u0148ky a p\u0159\u00edslu\u0161enstv\u00ed"],
    ],
    p_brand: commonProp18,
    p_color: commonProp4,
};
