import {
    _500_MESSAGE_2,
    _500_REPORT,
    CTA_GO_TO_HOMEPAGE,
    CTA_RETURN_BACK,
    LOAD_ERROR_RETRY,
    RAVEN_REPORT_ERROR_FORM_ENTRY,
    RAVEN_REPORT_ERROR_GENERIC,
    RAVEN_REPORT_LABEL_CLOSE,
    RAVEN_REPORT_LABEL_COMMENTS,
    RAVEN_REPORT_LABEL_EMAIL,
    RAVEN_REPORT_LABEL_NAME,
    RAVEN_REPORT_SUBMIT,
    RAVEN_REPORT_SUBTITLE,
    RAVEN_REPORT_SUBTITLE2,
    RAVEN_REPORT_SUCCESS_MESSAGE,
    RAVEN_REPORT_TITLE,
} from 'mk/autogenerated/translations/ServerError.ab852f528af7e1baa46b33ccaf577b32'
import assetUrl from 'mk/common/assetUrl';
import { homepageUrl } from 'mk2/apps/home/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Img } from 'mk2/components/Img';
import SiteMenu from 'mk2/containers/SiteMenu/SiteMenu';
import React from 'react';
import styles from './ServerError.mscss';

interface OwnProps {
    sentryEventId: string;
    hasMkGoBackLocation: boolean;
    innerRef?: React.RefObject<HTMLDivElement>;

    onGoBack();
}

class ServerError extends React.Component<OwnProps> {
    public render() {
        const { sentryEventId, hasMkGoBackLocation, innerRef } = this.props;

        return (
            <div className={styles.ServerError} ref={innerRef}>
                <SiteMenu
                    currentUrl=""
                    isHeaderSticky={false}
                    headerHasBackBtn={false}
                    mobileShowLogo
                />

                <div className={styles.ServerError__inner}>
                    <div className={styles.ServerError__icon}>
                        <Img
                            className={styles.ServerError__icon__image}
                            src={assetUrl + 'img/miky/broken-miky@2x.png'}
                            width={220}
                            height={271}
                        />
                    </div>
                    <div className={styles.ServerError__message}>
                        {_500_MESSAGE_2}
                    </div>
                    <div className={styles.ServerError__retryButton}>
                        <Btn
                            label={LOAD_ERROR_RETRY}
                            onClick={this.handleRetry}
                            type={BtnType.Blue}
                        />
                    </div>
                    <div className={styles.ServerError__goBackButton}>
                        {hasMkGoBackLocation ? (
                            <Btn
                                label={CTA_RETURN_BACK}
                                onClick={this.handleGoBack}
                                type={BtnType.Link}
                            />
                        ) : (
                            <Btn
                                label={CTA_GO_TO_HOMEPAGE}
                                link={homepageUrl}
                                type={BtnType.Link}
                            />
                        )}
                    </div>

                    {sentryEventId && (
                        <button type="button" className={styles.ServerError__report} onClick={this.handleOnReport}>
                            {_500_REPORT}
                        </button>
                    )}
                </div>
            </div>
        );
    }

    private handleRetry = () => {
        location.reload();
    };

    private handleGoBack = () => {
        this.props.onGoBack();
    };

    private handleOnReport = async () => {
        // import(), aby sme v SSR neimportovali @sentry/browser.
        // Uz je v bundli, nebude sa nacitavat
        const SentryBrowser = await import('@sentry/browser');

        SentryBrowser.showReportDialog({
            eventId: this.props.sentryEventId,
            title: RAVEN_REPORT_TITLE,
            subtitle: RAVEN_REPORT_SUBTITLE,
            subtitle2: RAVEN_REPORT_SUBTITLE2,
            labelName: RAVEN_REPORT_LABEL_NAME,
            labelEmail: RAVEN_REPORT_LABEL_EMAIL,
            labelComments: RAVEN_REPORT_LABEL_COMMENTS,
            labelClose: RAVEN_REPORT_LABEL_CLOSE,
            labelSubmit: RAVEN_REPORT_SUBMIT,
            errorGeneric: RAVEN_REPORT_ERROR_GENERIC,
            errorFormEntry: RAVEN_REPORT_ERROR_FORM_ENTRY,
            successMessage: RAVEN_REPORT_SUCCESS_MESSAGE,
        });
    };
}

export default ServerError;
