import { canManageBlogs, canManageForum, canManageGroups } from 'mk/bazaar/common/userUtils';
import {
    common_api_feed_menu_url,
    common_api_pageheaders_url,
    photoblog_api_v1_flagged_counts_url,
} from 'mk/urls/functions';
import {
    pageheaderRefreshFeedMenuApi,
    pageHeaderFetch,
    pageHeaderFetchFlaggedCountsApi,
    pageHeaderFetchFlaggedCountsTrigger,
    pageHeaderLoad,
    PageheaderRefreshFeedMenuAction,
    PageHeaderFetchFlaggedCountsTriggerAction,
    PageHeaderFetchRequestAction,
    PAGEHEADER_FETCH_FLAGGED_COUNTS_TRIGGER,
    PAGEHEADER_LOAD,
    PAGEHEADER_REFRESH_FEED_MENU,
} from 'mk2/containers/PageHeader/PageHeader.action';
import { FeedItemOrGroup } from 'mk2/containers/PageHeader/PageHeader.reducers';
import { normalizeError, XHRAction } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { getRequestPermissions } from 'mk2/reducers';
import { PageHeaderUserData } from 'mk2/schemas';
import { JamNotificationAction, JAM_NOTIFICATION } from 'mk2/services/jam.actions';
import { all, call, put, select, takeLatest, throttle } from 'redux-saga/effects';

const logger = getLogger('containers/PageHeader.sagas');

interface ApiResponsePageHeaders {
    body: PageHeaderUserData;
}

function* userbarJamNotification({}: JamNotificationAction) {
    yield put(pageHeaderLoad());
}

function* fetchPageHeaders({ xhr }: PageHeaderFetchRequestAction & XHRAction) {
    yield put(pageHeaderFetch.request());

    try {
        const response: ApiResponsePageHeaders = yield call(() => xhr.get(common_api_pageheaders_url()));

        yield put(pageHeaderFetch.success(response.body));
    } catch (error) {
        logger.error(error);
        yield put(pageHeaderFetch.failure(normalizeError(error)));
    }
}

interface ApiResponseFetchFlaggedCounts {
    body: {
        flagged_count: number;
        flagged_groups_count: number;
    };
}

function* fetchFlaggedCounts({ xhr }: PageHeaderFetchFlaggedCountsTriggerAction & XHRAction) {
    yield put(pageHeaderFetchFlaggedCountsApi.request());

    try {
        const response: ApiResponseFetchFlaggedCounts = yield call(() =>
            xhr.get(photoblog_api_v1_flagged_counts_url()),
        );
        yield put(
            pageHeaderFetchFlaggedCountsApi.success(
                response.body.flagged_count,
                response.body.flagged_groups_count,
                null,
            ),
        );
    } catch (error) {
        logger.error(error);
        yield put(pageHeaderFetchFlaggedCountsApi.failure(normalizeError(error)));
    }
}

interface RefreshFeedMenuApiResponse {
    body: FeedItemOrGroup[];
}

function* refreshFeedMenu({ xhr }: PageheaderRefreshFeedMenuAction & XHRAction) {
    yield put(pageheaderRefreshFeedMenuApi.request());
    try {
        const response: RefreshFeedMenuApiResponse = yield call(() => xhr.get(common_api_feed_menu_url()));
        yield put(pageheaderRefreshFeedMenuApi.success(response.body));

        const requestUserPermissions: string[] = yield select(getRequestPermissions);
        const isBlogsAdmin = canManageBlogs(requestUserPermissions);
        const isForumAdmin = canManageForum(requestUserPermissions);
        const isGroupAdmin = canManageGroups(requestUserPermissions);
        if (isBlogsAdmin || isForumAdmin || isGroupAdmin) {
            yield put(pageHeaderFetchFlaggedCountsTrigger());
        }
    } catch (error) {
        logger.error(error);
        yield put(pageheaderRefreshFeedMenuApi.failure(normalizeError(error)));
    }
}

export default function* root() {
    yield all([
        // Reload also when user loads mail detail so that unreadMails is refreshed
        takeLatest(
            [
                PAGEHEADER_LOAD,
                'MAIL_DETAIL_FETCH_SUCCESS' /* Keep as a string to reduce bundle size and not include whole MailDetail.actions */,
            ],
            fetchPageHeaders,
        ),
        takeLatest(PAGEHEADER_FETCH_FLAGGED_COUNTS_TRIGGER, fetchFlaggedCounts),

        // run once - then wait 10s for next reload
        throttle(
            10 * 1000,
            (action: JamNotificationAction) =>
                action.type === JAM_NOTIFICATION && action.channel.startsWith('userbar:'),
            userbarJamNotification,
        ),

        takeLatest(PAGEHEADER_REFRESH_FEED_MENU, refreshFeedMenu),
    ]);
}
