/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const motorcycles : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CHOPPER_MOTORCYCLE", "chopper"],
        ["CROSS_MOTORCYCLE", "cross"],
        ["ENDURO_MOTORCYCLE", "enduro"],
        ["MOPED_MOTORCYCLE", "moped"],
        ["SPORT_MOTORCYCLE", "sportovn\u00ed"],
        ["SCOOTER_MOTORCYCLE", "sk\u00fatr"],
        ["SUPERMOTO_MOTORCYCLE", "supermoto"],
        ["NAKED_BIKE_MOTORCYCLE", "naked bike"],
        ["TRIKE_MOTORCYCLE", "trike"],
    ],
    p_brand: [
        d("AJP"),
        d("Aprilia"),
        d("Benelli"),
        d("Beta"),
        d("Bimota"),
        d("BMW"),
        d("Buell"),
        d("Cagiva"),
        d("CF Moto"),
        d("\u010cZ"),
        d("Derbi"),
        d("Ducati"),
        d("Gas Gas"),
        d("Gilera"),
        d("Harley Davidson"),
        d("Honda"),
        d("Husqvarna"),
        d("Indian"),
        d("Jawa"),
        d("Kawasaki"),
        d("KTM"),
        d("Kymco"),
        d("Malaguti"),
        d("Moto Guzzi"),
        d("MV Augusta"),
        d("Peugeot"),
        d("Piaggio"),
        d("Royal Enfield"),
        d("Simson"),
        d("Suzuki"),
        d("SYM"),
        d("TM Racing"),
        d("Triumph"),
        d("Vespa"),
        d("Victory"),
        d("Yamaha"),
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
