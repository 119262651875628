import { denormalize, normalize, Schema } from 'normalizr';

import { NormalizedResponse } from 'mk2/helpers/api';
import { EntitiesState } from 'mk2/reducers/entities';

// nahrada za type-safe spread {...(u: U), ...(s: S)}, ktory typescript zatial nema.
// Su dva usecases, raz dava zmysel aby vysledok spreadu mal type 'U'
// a druhy raz, aby bol vysledok mal type 'U & S'
//
// https://github.com/Microsoft/TypeScript/issues/14706
// https://github.com/Microsoft/TypeScript/issues/12717
// https://github.com/Microsoft/TypeScript/issues/12997
export function tupdate<S extends object>(u: S, v: Partial<S>): S {
    return {...(u as any), ...(v as any)};
}
export function tmerge<U, V>(u: U, v: V): U & V {
    return {...(u as any), ...(v as any)};
}

// normalizr funkcie, pretypovane, tak ze vracaju a beru
// nase definicie resultu (NormalizedResponse) a entities (EntitiesState)
export function tdenormalize<T>(input: number, schema: Schema, entities: EntitiesState): T;
export function tdenormalize<T>(input: number[], schema: Schema, entities: EntitiesState): T[];
export function tdenormalize<T>(input: number | number[], schema: Schema, entities: EntitiesState): T | T[] {
    return denormalize(input, schema, entities) as any;
}

// fix pre react-redux, aby mapDispatchToProps hlasil chyby,
// ak sa nezhoduju definicie action creators
// (ak sa osvedci, presuniem ho do globals.d.ts)
export type MapDispatchToPropsObject<TDispatchProps> = {
    [K in keyof TDispatchProps]: TDispatchProps[K];
};
