export function hashCode(value: string): number {
    let hash = 0;
    if (value.length === 0) {
        return hash;
    }

    for (let i = 0; i < value.length; i++) {
        const char = value.charCodeAt(i);
        hash = (hash << 5) - hash + char; // tslint:disable-line no-bitwise
        // Convert to 32bit integer
        hash = hash & hash; // tslint:disable-line no-bitwise
    }

    return hash;
}

// https://stackoverflow.com/a/22429679
export function hashCode64(value: string): number {
    const h1 = hashCode(value);
    return h1 + hashCode(h1 + value);
}
