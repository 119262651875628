// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const BY_CREATION_TIME_NEWEST = "P\u0159idan\u00e9: ned\u00e1vno";
export const BY_CREATION_TIME_OLDEST = "P\u0159idan\u00e9: d\u00e1vno";
export const BY_NEW_MESSAGES = "Podle nejnov\u011bj\u0161\u00edch zpr\u00e1v";
export const BY_PRICE_ASC = "Cena: nejlevn\u011bj\u0161\u00ed";
export const BY_PRICE_DESC = "Cena: nejdra\u017e\u0161\u00ed";
export const BY_REPOST_TIME_NEWEST = "Aktualizovan\u00e9: ned\u00e1vno";
export const BY_REPOST_TIME_OLDEST = "Aktualizovan\u00e9: d\u00e1vno";
export const BY_TITLE = "Podle abecedy: A-Z";
export const BY_TITLE_REVERSED = "Podle abecedy: Z-A";
export const BZ2_CAT_ACCOMMODATION = "Ubytov\u00e1n\u00ed";
export const BZ2_CAT_ACCOMMODATION_RENTAL = "Pron\u00e1jem bydlen\u00ed";
export const BZ2_CAT_ADULTS_HANDICRAFTS = "Ru\u010dn\u00ed pr\u00e1ce (ne v\u00fdrobky)";
export const BZ2_CAT_ADULTS_HEALTH_BEAUTY = "Kr\u00e1sa a zdrav\u00ed";
export const BZ2_CAT_ADULTS_HOMEWARE = "Jin\u00e9 pro dom\u00e1cnost";
export const BZ2_CAT_ADULTS_MISC = "Jin\u00e9 pro dosp\u011bl\u00e9";
export const BZ2_CAT_ANIMALS = "Zv\u00ed\u0159ata";
export const BZ2_CAT_ANTIQUE_ART_OBJECTS = "Um\u011bleck\u00e9 p\u0159edm\u011bty";
export const BZ2_CAT_ANTIQUE_BADGES = "Odznaky a vojenstv\u00ed";
export const BZ2_CAT_ANTIQUE_BOOKS = "Star\u00e9 knihy a tisky";
export const BZ2_CAT_ANTIQUE_CERAMICS = "Keramika, sklo, porcel\u00e1n";
export const BZ2_CAT_ANTIQUE_CLOCKS = "Hodiny";
export const BZ2_CAT_ANTIQUE_FURNITURE = "Staro\u017eitn\u00fd n\u00e1bytek";
export const BZ2_CAT_ANTIQUE_JEWELRY = "Zlato, st\u0159\u00edbro, \u0161perky";
export const BZ2_CAT_ANTIQUE_MODELS = "Modely";
export const BZ2_CAT_ANTIQUE_MONEY = "Mince, bankovky";
export const BZ2_CAT_ANTIQUE_MUSICAL_INSTRUMENTS = "Hudebn\u00ed n\u00e1stroje";
export const BZ2_CAT_ANTIQUE_OTHERS = "Ostatn\u00ed";
export const BZ2_CAT_ANTIQUE_PICTURES = "Obrazy, obr\u00e1zky";
export const BZ2_CAT_ANTIQUE_POSTERS = "Pohledy, fotky, plak\u00e1ty";
export const BZ2_CAT_ANTIQUES = "Staro\u017eitnosti, sb\u011bratelstv\u00ed";
export const BZ2_CAT_ANTIQUE_STAMPS = "Zn\u00e1mky";
export const BZ2_CAT_ANTIQUE_TECHNOLOGY = "R\u00e1dia, fo\u0165\u00e1ky, technika";
export const BZ2_CAT_ANTIQUE_TOYS = "Hra\u010dky retro a staro\u017eitn\u00e9";
export const BZ2_CAT_ARCHERY = "Lukost\u0159elba";
export const BZ2_CAT_ATVS = "\u010cty\u0159kolky";
export const BZ2_CAT_AUTOMOTO = "Auto, moto";
export const BZ2_CAT_AUTOMOTO_SPARE_PARTS = "N\u00e1hradn\u00ed d\u00edly";
export const BZ2_CAT_BABY_CARRIERS = "\u0160\u00e1tky a nos\u00edtka";
export const BZ2_CAT_BABY_FEEDING = "Kojeneck\u00e9 pot\u0159eby";
export const BZ2_CAT_BABY_MISC = "Jin\u00e9 d\u011btsk\u00e9";
export const BZ2_CAT_BABYSITTING = "Hl\u00edd\u00e1n\u00ed d\u011bt\u00ed";
export const BZ2_CAT_BALANCE_BIKE = "Cykloodr\u00e1\u017eedla";
export const BZ2_CAT_BALL_GAMES = "M\u00ed\u010dov\u00e9 hry";
export const BZ2_CAT_BALL_GAMES_BALLS = "M\u00ed\u010de";
export const BZ2_CAT_BALL_GAMES_OTHER = "Ostatn\u00ed";
export const BZ2_CAT_BASEBALL = "Baseball, softball";
export const BZ2_CAT_BASEBALL_BATS = "Rukavice, p\u00e1lky, m\u00ed\u010dky";
export const BZ2_CAT_BASEBALL_OTHER = "Jin\u00e9";
export const BZ2_CAT_BASKETBALL = "Basketbal";
export const BZ2_CAT_BASKETBALL_BALLS = "M\u00ed\u010de";
export const BZ2_CAT_BASKETBALL_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_BASKETBALL_FOOTWEAR = "Obuv";
export const BZ2_CAT_BASKETBALL_OTHER = "P\u0159\u00edslu\u0161enstv\u00ed";
export const BZ2_CAT_BEACH_TENTS_CHAIRS = "Pl\u00e1\u017eov\u00e9 stany, leh\u00e1tka";
export const BZ2_CAT_BICYCLE = "Kola";
export const BZ2_CAT_BICYCLE_SEAT = "Cykloseda\u010dka";
export const BZ2_CAT_BICYCLE_TRAILER = "Cyklovoz\u00edky, miminkovn\u00edky";
export const BZ2_CAT_BIRTH = "Pom\u016fcky pro porod a \u0161estined\u011bl\u00ed";
export const BZ2_CAT_BLANKETS_WRAPPERS = "Deky, zavinova\u010dky";
export const BZ2_CAT_BLOUSE_SHIRTS = "Ko\u0161ile, bl\u016fzky";
export const BZ2_CAT_BOATING = "Vod\u00e1ctv\u00ed";
export const BZ2_CAT_BOATING_BARRELS = "Sudy, vaky";
export const BZ2_CAT_BOATING_BOATS = "Lod\u011b, rafty";
export const BZ2_CAT_BOATING_LIFE_JACKETS = "Z\u00e1chrann\u00e9 vesty, helmy";
export const BZ2_CAT_BOATING_OTHER = "Jin\u00e9";
export const BZ2_CAT_BOATING_PADDLES = "P\u00e1dla";
export const BZ2_CAT_BODYSUITS = "Body";
export const BZ2_CAT_BODYSUITS_SLEEPSUITS_ROMPERS = "Dupa\u010dky, body";
export const BZ2_CAT_BOUNCERS = "Leh\u00e1tka a ha\u010dky pro miminka";
export const BZ2_CAT_BREASTFEEDING_ACCESSORIES = "Pot\u0159eby pro koj\u00edc\u00ed matky";
export const BZ2_CAT_BUILDING_AND_CONSTRUCTION_MATERIAL = "Stavba a stavebn\u00ed materi\u00e1l";
export const BZ2_CAT_CAMERA_PHOTO_VIDEO = "Fotoapar\u00e1ty a videokamery";
export const BZ2_CAT_CARETAKING = "P\u00e9\u010de o ZTP a seniory";
export const BZ2_CAT_CARS = "Osobn\u00ed auta";
export const BZ2_CAT_CAR_SEATS = "Autoseda\u010dky";
export const BZ2_CAT_CAR_SEATS_ACCESSORIES = "P\u0159\u00edslu\u0161enstv\u00ed nebo jin\u00e9";
export const BZ2_CAT_CAR_SEATS_AND_ACCESSORIES = "Autoseda\u010dky a p\u0159\u00edslu\u0161enstv\u00ed";
export const BZ2_CAT_CELEBRATIONS = "Karneval, svatba, oslava";
export const BZ2_CAT_CHANGING_BAG = "P\u0159ebalovac\u00ed ta\u0161ka, batoh";
export const BZ2_CAT_CHILDREN_CAMPS = "D\u011btsk\u00e9 a p\u0159\u00edm\u011bstsk\u00e9 t\u00e1bory";
export const BZ2_CAT_CHILDREN_CARE = "P\u00e9\u010de o d\u011bti";
export const BZ2_CAT_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_CLOTHING_MEGAPACKS = "Bal\u00edky / mix oble\u010den\u00ed";
export const BZ2_CAT_COSMETICS_BATHING = "Koup\u00e1n\u00ed, p\u0159ebalov\u00e1n\u00ed a hygiena";
export const BZ2_CAT_COTS = "Postele, ohr\u00e1dky, kol\u00e9bky";
export const BZ2_CAT_CRAFTSMEN = "\u0158emesln\u00edci";
export const BZ2_CAT_CROSSCOUNTRY_SKIING = "B\u011b\u017eky";
export const BZ2_CAT_CROSSCOUNTRY_SKIING_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_CROSSCOUNTRY_SKIING_FOOTWEAR = "Obuv";
export const BZ2_CAT_CROSSCOUNTRY_SKIING_OTHER = "Jin\u00e9";
export const BZ2_CAT_CROSSCOUNTRY_SKIS = "B\u011b\u017eky";
export const BZ2_CAT_CYCLING = "Cyklistika";
export const BZ2_CAT_CYCLING_ACCESSORIES = "P\u0159\u00edslu\u0161enstv\u00ed";
export const BZ2_CAT_CYCLING_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_CYCLING_FOOTWEAR = "Obuv";
export const BZ2_CAT_CYCLING_HELMETS = "Helmy, chr\u00e1ni\u010de";
export const BZ2_CAT_CYCLING_OTHER = "Jin\u00e9";
export const BZ2_CAT_DANCING = "Tanec";
export const BZ2_CAT_DANCING_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_DANCING_FOOTWEAR = "Obuv";
export const BZ2_CAT_DANCING_OTHER = "Dopl\u0148ky";
export const BZ2_CAT_DARTS = "\u0160ipky";
export const BZ2_CAT_DINING = "J\u00eddeln\u00ed \u017eidle a pot\u0159eby";
export const BZ2_CAT_DINING_OTHER = "J\u00eddeln\u00ed pot\u0159eby";
export const BZ2_CAT_DISINFECTION = "Dezinfekce";
export const BZ2_CAT_DRESSES = "\u0160aty";
export const BZ2_CAT_ELECTRIC_BICYCLE = "Elektrokola";
export const BZ2_CAT_ELECTRONICS = "Mobily, foto, elektronika";
export const BZ2_CAT_EMPLOYMENT = "Zam\u011bstn\u00e1n\u00ed";
export const BZ2_CAT_EXERCISE_MATS = "Podlo\u017eky na cvi\u010den\u00ed";
export const BZ2_CAT_FACE_MASKS = "Rou\u0161ky";
export const BZ2_CAT_FACE_PAINTING = "Malov\u00e1n\u00ed na tv\u00e1\u0159";
export const BZ2_CAT_FACE_SHIELDS = "Ochrann\u00e9 \u0161t\u00edty";
export const BZ2_CAT_FANCY_DRESS = "Karneval";
export const BZ2_CAT_FASHION_ACCESSORIES = "M\u00f3dn\u00ed dopl\u0148ky";
export const BZ2_CAT_FASHION_ACCESSORIES_ACCESSORIES = "M\u00f3dn\u00ed dopl\u0148ky";
export const BZ2_CAT_FASHION_ACCESSORIES_HANDBAGS = "Kabelky, batohy, kufry";
export const BZ2_CAT_FASHION_ACCESSORIES_JEWELRY = "\u0160perky, bi\u017euterie";
export const BZ2_CAT_FEET_TROUSERS = "Polodupa\u010dky";
export const BZ2_CAT_FIGHTING = "Bojov\u00e9 sporty";
export const BZ2_CAT_FIGHTING_ACCESSORIES = "Dopl\u0148ky";
export const BZ2_CAT_FIGHTING_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_FIGHTING_OTHER = "P\u0159\u00edslu\u0161enstv\u00ed";
export const BZ2_CAT_FINANCE = "Finance";
export const BZ2_CAT_FISHING = "Rybolov";
export const BZ2_CAT_FISHING_BOATS = "\u010cluny";
export const BZ2_CAT_FISHING_CHAIRS = "\u017didle, k\u0159esla, leh\u00e1tka";
export const BZ2_CAT_FISHING_OTHER = "Jin\u00e9";
export const BZ2_CAT_FISHING_RODS = "Pruty, udice, navij\u00e1ky";
export const BZ2_CAT_FISHING_SHELTERS = "De\u0161tn\u00edky, p\u0159\u00edst\u0159e\u0161ky";
export const BZ2_CAT_FITNESS_MACHINES = "Posilovac\u00ed stroje";
export const BZ2_CAT_FLOORBALL = "Florbal, hokejbal";
export const BZ2_CAT_FLOORBALL_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_FLOORBALL_OTHER = "Ostatn\u00ed";
export const BZ2_CAT_FLOORBALL_STICKS = "Hokejky";
export const BZ2_CAT_FOOD = "Potraviny";
export const BZ2_CAT_FOOTBALL = "Fotbal";
export const BZ2_CAT_FOOTBALL_BALLS = "M\u00ed\u010de";
export const BZ2_CAT_FOOTBALL_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_FOOTBALL_FOOTWEAR = "Kopa\u010dky";
export const BZ2_CAT_FOOTBALL_OTHER = "Jin\u00e9";
export const BZ2_CAT_FOOTMUFFS = "Fusaky";
export const BZ2_CAT_FOOTWEAR = "Obuv";
export const BZ2_CAT_GARDEN = "Zahrada";
export const BZ2_CAT_GIFTS = "D\u00e1rkov\u00e9 a vzpom\u00ednkov\u00e9 p\u0159edm\u011bty";
export const BZ2_CAT_GOLF = "Golf";
export const BZ2_CAT_GOLF_ACCESSORIES = "Dopl\u0148ky";
export const BZ2_CAT_GOLF_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_GOLF_CLUBS = "Hole";
export const BZ2_CAT_GOLF_FOOTWEAR = "Obuv";
export const BZ2_CAT_GOLF_OTHER = "Jin\u00e9";
export const BZ2_CAT_GUIDE_BAR = "Vod\u00edc\u00ed a spojovac\u00ed ty\u010de";
export const BZ2_CAT_GYMNASTICS_BALLET = "Gymnastika, balet";
export const BZ2_CAT_GYMNASTICS_BALLET_ACCESSORIES = "Dopl\u0148ky";
export const BZ2_CAT_GYMNASTICS_BALLET_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_GYMNASTICS_BALLET_FOOTWEAR = "Obuv";
export const BZ2_CAT_GYMNASTICS_BALLET_OTHER = "Jin\u00e9";
export const BZ2_CAT_HATS_MITTENS_SCARVES = "\u010cepice, rukavice, \u0161\u00e1ly";
export const BZ2_CAT_HEALTH_AND_BEAUTY = "Zdrav\u00ed a kr\u00e1sa";
export const BZ2_CAT_HEAVY_MACHINERY = "Pracovn\u00ed stroje";
export const BZ2_CAT_HIGH_CHAIRS = "J\u00eddeln\u00ed \u017eidli\u010dka";
export const BZ2_CAT_HIKING = "Turistika, kempov\u00e1n\u00ed";
export const BZ2_CAT_HIKING_BABY_CARRIES = "Krosny na no\u0161en\u00ed d\u011bt\u00ed";
export const BZ2_CAT_HIKING_BACKPACK = "Batohy";
export const BZ2_CAT_HIKING_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_HIKING_FOOTWEAR = "Obuv";
export const BZ2_CAT_HIKING_MATS = "Karimatky, matrace";
export const BZ2_CAT_HIKING_OTHER = "P\u0159\u00edslu\u0161enstv\u00ed a Jin\u00e9";
export const BZ2_CAT_HIKING_POLES = "Turistick\u00e9 hole";
export const BZ2_CAT_HIKING_SLEEPING_BAGS = "Spac\u00ed pytle";
export const BZ2_CAT_HIKING_TENTS = "Stany";
export const BZ2_CAT_HOBBY_CLASSES = "Z\u00e1jmov\u00e9 krou\u017eky";
export const BZ2_CAT_HOCKEY_STICKS_PUCKS = "Hokejky, puky";
export const BZ2_CAT_HOME_ACCESSORIES_DECORATIONS = "Bytov\u00e9 dopl\u0148ky a dekorace";
export const BZ2_CAT_HOME_APPLIANCES = "Spot\u0159ebi\u010de do dom\u00e1cnosti";
export const BZ2_CAT_HOME_CARE_CLEANING = "Drogerie a \u00faklid";
export const BZ2_CAT_HOME_CARPETS_RUGS = "Koberce a p\u0159edlo\u017eky";
export const BZ2_CAT_HOME_COOKING_BAKING_WARE = "Va\u0159en\u00ed, pe\u010den\u00ed";
export const BZ2_CAT_HOME_DINING = "Stolov\u00e1n\u00ed";
export const BZ2_CAT_HOME_FOOD_STORAGE = "Skladov\u00e1n\u00ed a balen\u00ed potravin";
export const BZ2_CAT_HOME_FURNITURE = "N\u00e1bytek";
export const BZ2_CAT_HOME_LIGHTING = "Lampy a osv\u011btlen\u00ed";
export const BZ2_CAT_HOME_LIVING = "Dom\u00e1cnost";
export const BZ2_CAT_HOME_TEXTILES = "Text\u00edlie";
export const BZ2_CAT_HORSEBACK_RIDING = "J\u00edzda na koni";
export const BZ2_CAT_HORSEBACK_RIDING_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_HORSEBACK_RIDING_FOOTWEAR = "Obuv";
export const BZ2_CAT_HORSEBACK_RIDING_HELMETS = "Helmy, chr\u00e1ni\u010de";
export const BZ2_CAT_HORSEBACK_RIDING_OTHER = "Jin\u00e9";
export const BZ2_CAT_HOUSE_AND_GARDEN = "D\u016fm a zahrada";
export const BZ2_CAT_HOUSEHOLD_HELP = "Pomoc v dom\u00e1cnosti";
export const BZ2_CAT_HUNTING = "Myslivectv\u00ed";
export const BZ2_CAT_ICE_HOCKEY = "Hokej, zimn\u00ed bruslen\u00ed";
export const BZ2_CAT_ICE_HOCKEY_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_ICE_HOCKEY_FOOTWEAR = "Brusle";
export const BZ2_CAT_ICE_HOCKEY_HELMETS = "Helmy, chr\u00e1ni\u010de, rukavice";
export const BZ2_CAT_ICE_HOCKEY_OTHER = "Jin\u00e9";
export const BZ2_CAT_INLINE_SKATES = "In-line (kole\u010dka za sebou)";
export const BZ2_CAT_INTERNSHIPS = "Praxe a st\u00e1\u017ee";
export const BZ2_CAT_JACKETS_COATS = "Bundy, kab\u00e1ty, saka";
export const BZ2_CAT_JOBS = "Pr\u00e1ce, brig\u00e1dy";
export const BZ2_CAT_KIDS_PARTY = "Oslava";
export const BZ2_CAT_KINDERGARDEN_SCHOOL = "\u0160kolka\u0159i a \u0161kol\u00e1ci, pom\u016fcky";
export const BZ2_CAT_LEGGINGS_JEGGINGS = "Leg\u00edny, d\u017eeg\u00edny";
export const BZ2_CAT_MAJORETTES = "Ma\u017eoretky, roztlesk\u00e1va\u010dky";
export const BZ2_CAT_MAJORETTES_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_MAJORETTES_OTHER = "Dopl\u0148ky";
export const BZ2_CAT_MEDICAL_TESTS = "Testy";
export const BZ2_CAT_MISC = "Jin\u00e9";
export const BZ2_CAT_MISC_MOTHERHOOD = "Pom\u016fcky pro t\u011bhotenstv\u00ed";
export const BZ2_CAT_MOTHERS_NOURISHMENT = "V\u00fd\u017eivov\u00e9 dopl\u0148ky pro t\u011bhotn\u00e9 a maminky";
export const BZ2_CAT_MOTOCROSS = "Motokros";
export const BZ2_CAT_MOTOCROSS_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_MOTOCROSS_HELMETS = "Helmy, chr\u00e1ni\u010de";
export const BZ2_CAT_MOTOCROSS_MACHINES = "Motok\u00e1ry apod.";
export const BZ2_CAT_MOTOCROSS_OTHER = "Jin\u00e9";
export const BZ2_CAT_MOTORCYCLES = "Motorky";
export const BZ2_CAT_MOTORHOMES = "Obytn\u00e9 vozy";
export const BZ2_CAT_MOUNTAINEERING = "Horolezectv\u00ed";
export const BZ2_CAT_MOUNTAINEERING_CLIPS = "Pojistky a \u00favazy";
export const BZ2_CAT_MOUNTAINEERING_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_MOUNTAINEERING_FOOTWEAR = "Obuv";
export const BZ2_CAT_MOUNTAINEERING_HELMETS = "Helmy";
export const BZ2_CAT_MOUNTAINEERING_OTHER = "P\u0159\u00edslu\u0161enstv\u00ed";
export const BZ2_CAT_MULTIMEDIA = "Knihy, hudba a film";
export const BZ2_CAT_MULTIMEDIA_AUDIO = "Hudba a audio";
export const BZ2_CAT_MULTIMEDIA_BOOKS = "Knihy";
export const BZ2_CAT_MULTIMEDIA_CALENDARS = "Kalend\u00e1\u0159e, di\u00e1\u0159e";
export const BZ2_CAT_MULTIMEDIA_COLORING = "Omalov\u00e1nky";
export const BZ2_CAT_MULTIMEDIA_MAGAZINES = "\u010casopisy";
export const BZ2_CAT_MULTIMEDIA_VIDEO = "Film a video";
export const BZ2_CAT_NAPPIES = "Plenky";
export const BZ2_CAT_NOURISHMENT = "D\u011btsk\u00e1 v\u00fd\u017eiva, potravinov\u00e9 dopl\u0148ky";
export const BZ2_CAT_NURSERY = "D\u011btsk\u00fd pokoj";
export const BZ2_CAT_NURSING_BRA = "Podprsenky na kojen\u00ed";
export const BZ2_CAT_OCCASION_WEAR = "Slavnostn\u00ed a v\u00e1no\u010dn\u00ed oble\u010den\u00ed";
export const BZ2_CAT_OTHER_ELECTRONICS = "Jin\u00e1 elektronika";
export const BZ2_CAT_OTHER_SERVICES = "Jin\u00e9";
export const BZ2_CAT_OUTFITS = "Komplety, soupravy";
export const BZ2_CAT_OVERALLS = "Overaly";
export const BZ2_CAT_PC_GAMING = "Hern\u00ed konzole a hry";
export const BZ2_CAT_PC_NOTEBOOKS = "Notebooky a po\u010d\u00edta\u010de";
export const BZ2_CAT_PHONES_TABLETS_EREADERS = "Mobily, tablety a \u010dte\u010dky e-knih";
export const BZ2_CAT_PHOTOGRAPHY = "Focen\u00ed";
export const BZ2_CAT_PING_PONG_BEDMINTON = "Ping pong, badminton, l\u00edn\u00fd tenis";
export const BZ2_CAT_PING_PONG_BEDMINTON_BALLS = "M\u00ed\u010dky";
export const BZ2_CAT_PING_PONG_BEDMINTON_OTHER = "P\u0159\u00edslu\u0161enstv\u00ed";
export const BZ2_CAT_PING_PONG_BEDMINTON_RACKETS = "P\u00e1lky, rakety";
export const BZ2_CAT_PRAMS = "Ko\u010d\u00e1rky";
export const BZ2_CAT_PRAMS_ACCESSORIES = "P\u0159\u00edslu\u0161enstv\u00ed";
export const BZ2_CAT_PRAMS_AND_ACCESSORIES = "Ko\u010d\u00e1rky a fusaky";
export const BZ2_CAT_PREGNANCY_CLOTHING = "T\u011bhotensk\u00e9 oble\u010den\u00ed";
export const BZ2_CAT_PREGNANCY_MOTHERS = "T\u011bhotenstv\u00ed, kojen\u00ed";
export const BZ2_CAT_PROTECTIVE_EQUIPMENT = "Ochrann\u00e9 pom\u016fcky";
export const BZ2_CAT_PROTECTIVE_EQUIPMENT_OTHER = "Jin\u00e9";
export const BZ2_CAT_RAINWEAR = "Oble\u010den\u00ed do de\u0161t\u011b";
export const BZ2_CAT_RENTAL = "P\u016fj\u010dovna";
export const BZ2_CAT_RESPIRATORS = "Respir\u00e1tory";
export const BZ2_CAT_RETRAINING = "Rekvalifikace, kurzy";
export const BZ2_CAT_ROLLER_SKATES = "Kole\u010dkov\u00e9 brusle (kole\u010dka vedle sebe)";
export const BZ2_CAT_ROLLER_SKATING = "In-line, kole\u010dkov\u00e9 brusle";
export const BZ2_CAT_ROLLER_SKATING_HELMETS = "Helmy a chr\u00e1ni\u010de";
export const BZ2_CAT_ROLLER_SKATING_OTHER = "P\u0159\u00edslu\u0161enstv\u00ed a jin\u00e9";
export const BZ2_CAT_ROMPERS = "Letn\u00ed overal";
export const BZ2_CAT_RUNNING = "B\u011bh, atletika";
export const BZ2_CAT_RUNNING_ACCESSORIES = "Dopl\u0148ky";
export const BZ2_CAT_RUNNING_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_RUNNING_FOOTWEAR = "Obuv";
export const BZ2_CAT_RUNNING_OTHER = "P\u0159\u00edslu\u0161enstv\u00ed a jin\u00e9";
export const BZ2_CAT_SAFETY = "Monitory dechu, ch\u016fvi\u010dky a bezpe\u010dnost";
export const BZ2_CAT_SAFETY_GLASSES = "Ochrann\u00e9 br\u00fdle";
export const BZ2_CAT_SAFETY_GLOVES = "Rukavice";
export const BZ2_CAT_SCOOTER = "Kolob\u011b\u017eky";
export const BZ2_CAT_SEO_BABY_ACCESSORIES = "D\u011btsk\u00e9 m\u00f3dn\u00ed dopl\u0148ky";
export const BZ2_CAT_SEO_BLOUSE_SHIRTS = "Ko\u0161ile, bl\u016fzky";
export const BZ2_CAT_SEO_BODYSUITS_SLEEPSUITS_ROMPERS = "Dupa\u010dky, body";
export const BZ2_CAT_SEO_CLOTHING_MEGAPACKS = "Bal\u00edky / mix oble\u010den\u00ed ";
export const BZ2_CAT_SEO_DRESSES = "\u0160aty";
export const BZ2_CAT_SEO_DRESSES_SKIRTS = "Sukn\u011b, \u0161aty";
export const BZ2_CAT_SEO_HATS_MITTENS_SCARVES = "\u010cepice, rukavice, \u0161\u00e1ly";
export const BZ2_CAT_SEO_JACKETS_COATS = "Bundy, kab\u00e1ty, saka";
export const BZ2_CAT_SEO_OCCASION_WEAR = "Slavnostn\u00ed a v\u00e1no\u010dn\u00ed oble\u010den\u00ed";
export const BZ2_CAT_SEO_OUTFITS = "Komplety, soupravy";
export const BZ2_CAT_SEO_SHORTS_CROPS = "\u0160ortky, t\u0159\u00ed\u010dtvr\u0165\u00e1ky";
export const BZ2_CAT_SEO_SKIRTS = "Sukn\u011b";
export const BZ2_CAT_SEO_SLEEPING_BAGS = "D\u011btsk\u00e9 spac\u00ed pytle";
export const BZ2_CAT_SEO_SLEEPWEAR = "Py\u017eama, no\u010dn\u00ed ko\u0161ile a \u017eupany";
export const BZ2_CAT_SEO_SNOWSUITS = "Zimn\u00ed kombin\u00e9zy a oteplov\u00e1ky";
export const BZ2_CAT_SEO_SOCKS_TIGHTS = "Pono\u017eky, pun\u010doch\u00e1\u010de, n\u00e1vleky";
export const BZ2_CAT_SEO_SWEATSHIRTS_JUMPERS_VESTS = "Mikiny, svetry, bolerka";
export const BZ2_CAT_SEO_SWIMWEAR_DRESSING_GOWNS = "Plavky";
export const BZ2_CAT_SEO_TROUSERS_JEANS_JOGGERS = "Kalhoty, d\u017e\u00edny, tepl\u00e1ky";
export const BZ2_CAT_SEO_TSHIRTS_TOPS = "Tri\u010dka, t\u00edlka";
export const BZ2_CAT_SEO_TWINS = "Oble\u010den\u00ed a obuv pro dvoj\u010data";
export const BZ2_CAT_SEO_TWINS_MISC = "R\u016fzn\u00e9 pro dvoj\u010data (ne ko\u010d\u00e1rky)";
export const BZ2_CAT_SEO_UNDERWEAR = "Spodn\u00ed pr\u00e1dlo";
export const BZ2_CAT_SERVICES = "Slu\u017eby";
export const BZ2_CAT_SHORTS_CROPS = "\u0160ortky, t\u0159\u00ed\u010dtvr\u0165\u00e1ky";
export const BZ2_CAT_SKATEBOARDS = "Skateboard apod.";
export const BZ2_CAT_SKATEBOARDS_BOARDS = "Boardy";
export const BZ2_CAT_SKATEBOARDS_HELMETS = "Helmy, chr\u00e1ni\u010de";
export const BZ2_CAT_SKATEBOARDS_OTHER = "Jin\u00e9";
export const BZ2_CAT_SKI_FOOTWEAR = "Ly\u017ea\u0159sk\u00e9 boty";
export const BZ2_CAT_SKI_GOGGLES = "Br\u00fdle";
export const BZ2_CAT_SKI_HELMETS = "Helmy";
export const BZ2_CAT_SKI_POLES = "H\u016flky";
export const BZ2_CAT_SKIRTS = "Sukn\u011b";
export const BZ2_CAT_SKIS = "Ly\u017ee";
export const BZ2_CAT_SKIS_SNOWBOARDS = "Ly\u017ee, snowboard";
export const BZ2_CAT_SKIS_SNOWBOARDS_OTHER = "Jin\u00e9";
export const BZ2_CAT_SLEDGES_BOBSLEIGHS = "S\u00e1\u0148ky, boby";
export const BZ2_CAT_SLEEPING_BAGS = "Spac\u00ed pytle";
export const BZ2_CAT_SLEEPSUITS = "Overaly, dupa\u010dky";
export const BZ2_CAT_SLEEPWEAR = "Py\u017eama, no\u010dn\u00ed ko\u0161ile, \u017eupany";
export const BZ2_CAT_SNORKELS_AND_FINS = "\u0160norchly a ploutve";
export const BZ2_CAT_SNOWBOARD_FOOTWEAR = "Boty na snowboard";
export const BZ2_CAT_SNOWBOARDS = "Snowboardy";
export const BZ2_CAT_SNOWSUITS = "Oble\u010den\u00ed do sn\u011bhu";
export const BZ2_CAT_SOCKS_TIGHTS = "Pono\u017eky, pun\u010doch\u00e1\u010de, n\u00e1vleky";
export const BZ2_CAT_SPORT = "Sport";
export const BZ2_CAT_SPORT_BOTTLES = "Sportovn\u00ed lahve";
export const BZ2_CAT_SPORT_BOTTLES_BOTTLES = "L\u00e1hve, termosky";
export const BZ2_CAT_SPORT_BOTTLES_OTHER = "P\u0159\u00edslu\u0161enstv\u00ed";
export const BZ2_CAT_SPORT_MASSAGE = "Sportovn\u00ed mas\u00e1\u017ee, tejpy";
export const BZ2_CAT_SPORT_OTHERS = "Jin\u00e9";
export const BZ2_CAT_SPORT_OTHERS_ACCESSORIES = "Dopl\u0148ky";
export const BZ2_CAT_SPORT_OTHERS_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_SPORT_OTHERS_FOOTWEAR = "Obuv";
export const BZ2_CAT_SPORT_OTHERS_OTHER = "Jin\u00e9";
export const BZ2_CAT_SPORTS_BAGS = "Sportovn\u00ed ta\u0161ky, batohy";
export const BZ2_CAT_SPORT_VITAMINS = "V\u00fd\u017eiva a vitam\u00edny pro sportovce";
export const BZ2_CAT_SPORT_WATCHES = "Sportovn\u00ed chytr\u00e9 hodinky a n\u00e1ramky";
export const BZ2_CAT_STRENGHTENING_AIDS = "Pom\u016fcky pro posilov\u00e1n\u00ed";
export const BZ2_CAT_SWEATSHIRTS_JUMPERS_VESTS = "Mikiny, svetry, bolerka";
export const BZ2_CAT_SWIMMING_CAPS = "\u010cepice";
export const BZ2_CAT_SWIMMING_CIRCLES_SLEEVES = "Kruhy, ruk\u00e1vky apod.";
export const BZ2_CAT_SWIMMING_DIVING = "Plav\u00e1n\u00ed, pot\u00e1p\u011bn\u00ed";
export const BZ2_CAT_SWIMMING_GOGGLES = "Br\u00fdle";
export const BZ2_CAT_SWIMMING_NEOPRENES = "Neopreny";
export const BZ2_CAT_SWIMMING_OTHER = "Jin\u00e9";
export const BZ2_CAT_SWIMWEAR_DRESSING_GOWNS = "Plavky";
export const BZ2_CAT_TENNIS_SQUASH = "Tenis, squash";
export const BZ2_CAT_TENNIS_SQUASH_BALLS = "M\u00ed\u010dky";
export const BZ2_CAT_TENNIS_SQUASH_CLOTHING = "Oble\u010den\u00ed";
export const BZ2_CAT_TENNIS_SQUASH_FOOTWEAR = "Obuv";
export const BZ2_CAT_TENNIS_SQUASH_OTHER = "Jin\u00e9";
export const BZ2_CAT_TENNIS_SQUASH_RACKETS = "Rakety";
export const BZ2_CAT_THERMOMETERS = "Teplom\u011bry";
export const BZ2_CAT_TICKETS_VOUCHERS = "Vstupenky, poukazy";
export const BZ2_CAT_TO_THE_GARAGE = "Do gar\u00e1\u017ee";
export const BZ2_CAT_TOYS = "Hra\u010dky a hry";
export const BZ2_CAT_TRAMPOLINES = "Trampol\u00edny";
export const BZ2_CAT_TRAMPOLINES_SPARE_PARTS = "N\u00e1hradn\u00ed d\u00edly";
export const BZ2_CAT_TRAMPOLINES_STAIRS = "Sch\u016fdky";
export const BZ2_CAT_TRAMPOLINES_TRAMPOLINES = "Trampol\u00edny";
export const BZ2_CAT_TRIKE = "T\u0159\u00edkolky";
export const BZ2_CAT_TROLLEYS_TRAILERS = "Voz\u00edk, p\u0159\u00edv\u011bs, n\u00e1v\u011bs";
export const BZ2_CAT_TROUSERS_JEANS_JOGGERS = "Kalhoty, d\u017e\u00edny, tepl\u00e1ky";
export const BZ2_CAT_TRUCKS = "N\u00e1kladn\u00ed auta";
export const BZ2_CAT_TSHIRTS_TOPS = "Tri\u010dka, t\u00edlka (ne spodn\u00ed pr\u00e1dlo)";
export const BZ2_CAT_TUTORING = "Dou\u010dov\u00e1n\u00ed";
export const BZ2_CAT_TWINS = "Oble\u010den\u00ed a obuv";
export const BZ2_CAT_TWINS_GROUP = "Dvoj\u010data";
export const BZ2_CAT_TWINS_MISC = "R\u016fzn\u00e9 (ne ko\u010d\u00e1rky)";
export const BZ2_CAT_UNDERWEAR = "Spodn\u00ed pr\u00e1dlo";
export const BZ2_CAT_VETERAN_VEHICLES = "Veter\u00e1ny";
export const BZ2_CAT_VIDEO_AND_PHOTOGRAPHY = "Foto, film";
export const BZ2_CAT_WEIGHT_TRAINING = "Fitness, aerobic, j\u00f3ga";
export const BZ2_CAT_WEIGHT_TRAINING_CLOTHING = "Oble\u010den\u00ed a dopl\u0148ky";
export const BZ2_CAT_WEIGHT_TRAINING_OTHER = "Jin\u00e9";
export const BZ2_CAT_WORK_ABROAD = "Pr\u00e1ce v zahrani\u010d\u00ed";
export const BZ2_CAT_WORK_CLOTHES_FOOTWEAR = "Pracovn\u00ed od\u011bvy, pracovn\u00ed obuv";
export const BZ2_ESHOP_PRODUCT_HOVER = "E-shop";
export const BZ2_FACET_CATEGORY = "Kategorie";
export const BZ2_FACET_CATS = "Kategorie";
export const BZ2_FACET_GROUPS = "Moje skupiny";
export const BZ2_FACET_LOCATION = "Lokalita";
export const BZ2_FACET_LOCATIONS = "Okres";
export const BZ2_FACET_P_AGE_GROUP = "V\u011bk";
export const BZ2_FACET_P_AGE_MONTHS = "Velikost (v\u011bk)";
export const BZ2_FACET_P_AGE_YEARS = "V\u011bk";
export const BZ2_FACET_P_ANIMAL = "Zv\u00ed\u0159e";
export const BZ2_FACET_P_BRACES = "Lacl\u00e1\u010de";
export const BZ2_FACET_P_BRAND = "Zna\u010dka";
export const BZ2_FACET_P_BRAND_MODEL = "Model";
export const BZ2_FACET_P_BRAND_MODELS = "Model";
export const BZ2_FACET_P_COLOR = "Kl\u00ed\u010dov\u00e1 barva";
export const BZ2_FACET_P_CUP_SIZE = "Ko\u0161\u00ed\u010dek";
export const BZ2_FACET_P_DATA_CARRIER = "Nosi\u010d";
export const BZ2_FACET_P_DRESSING = "Obl\u00e9k\u00e1n\u00ed";
export const BZ2_FACET_P_EDUCATION = "Vzd\u011bl\u00e1n\u00ed";
export const BZ2_FACET_P_FIT = "Uchycen\u00ed";
export const BZ2_FACET_P_FITS_ON_MODEL = "Sed\u00ed na";
export const BZ2_FACET_P_FOR_WHOM = "Pro koho";
export const BZ2_FACET_P_FREQUENCY = "Frekvence";
export const BZ2_FACET_P_FUEL = "Palivo";
export const BZ2_FACET_P_GAMING_PLATFORM = "Hern\u00ed platforma";
export const BZ2_FACET_P_HEEL = "Podpatek";
export const BZ2_FACET_P_HOMEOFFICE = "Homeoffice";
export const BZ2_FACET_P_HOOD = "Kapuce";
export const BZ2_FACET_P_ISOFIX = "Isofix";
export const BZ2_FACET_PLACES = "Obec";
export const BZ2_FACET_P_LANGUAGE = "Jazyk";
export const BZ2_FACET_P_LAYERS = "Vrstvy";
export const BZ2_FACET_P_LENGTH = "D\u00e9lka";
export const BZ2_FACET_P_LENGTH_ORDINAL = "D\u00e9lka (cm)";
export const BZ2_FACET_P_LEVEL = "\u00darove\u0148";
export const BZ2_FACET_P_MANUFACTURER = "V\u00fdrobce";
export const BZ2_FACET_P_MATERIAL = "Materi\u00e1l";
export const BZ2_FACET_P_MATERNITY_LEAVE_SUITABLE = "Vhodn\u00e9 p\u0159i mate\u0159sk\u00e9";
export const BZ2_FACET_P_NECKLINE = "V\u00fdst\u0159ih";
export const BZ2_FACET_P_NUMBER_OF_DOORS = "Po\u010det dve\u0159\u00ed";
export const BZ2_FACET_P_NUMBER_OF_SEATS = "Po\u010det m\u00edst";
export const BZ2_FACET_P_OCCASION = "P\u0159\u00edle\u017eitost";
export const BZ2_FACET_P_PLACE = "M\u00edsto";
export const BZ2_FACET_P_PLACEMENT = "Um\u00edst\u011bn\u00ed";
export const BZ2_FACET_P_PRACTICE_LENGTH = "D\u00e9lka praxe";
export const BZ2_FACET_P_PRICE = "Cena v K\u010d";
export const BZ2_FACET_P_QUALITY = "Kvalita";
export const BZ2_FACET_P_SCHOOL_SUBJECT = "P\u0159edm\u011bt";
export const BZ2_FACET_P_SCREEN_SIZE = "\u00dahlop\u0159\u00ed\u010dka";
export const BZ2_FACET_P_SEASON = "Ro\u010dn\u00ed obdob\u00ed";
export const BZ2_FACET_P_SEAT_FACING = "Sezen\u00ed";
export const BZ2_FACET_P_SEX = "Pro koho";
export const BZ2_FACET_P_SEX_TWIN = "Pohlav\u00ed";
export const BZ2_FACET_P_SHAPE = "St\u0159ih";
export const BZ2_FACET_P_SIZE = "Velikost";
export const BZ2_FACET_P_SIZE_INTERVAL = "Velikost";
export const BZ2_FACET_P_SIZE_WEIGHT = "Velikost";
export const BZ2_FACET_P_SLEEPING_PLACES = "M\u00edst na span\u00ed";
export const BZ2_FACET_P_SLEEVE = "Ruk\u00e1v";
export const BZ2_FACET_P_SPACE_TYPE = "Typ prostoru";
export const BZ2_FACET_P_STYLE = "Styl";
export const BZ2_FACET_P_SUBJECT = "\u017d\u00e1nr";
export const BZ2_FACET_P_THEMA = "T\u00e9ma";
export const BZ2_FACET_P_TRANSMISSION = "P\u0159evodovka";
export const BZ2_FACET_P_TYPE = "Typ";
export const BZ2_FACET_P_USAGE = "Pou\u017eit\u00ed";
export const BZ2_FACET_P_WEIGHT = "Hmotnost";
export const BZ2_FACET_P_WIDTH_ORDINAL = "\u0160\u00ed\u0159ka (cm)";
export const BZ2_FACET_P_WORKING_TIME = "\u00davazek";
export const BZ2_FACET_P_YEAR_OF_MANUFACTURE = "Rok v\u00fdroby";
export const BZ2_FACET_QUALITY = "Pou\u017eit\u00e9 zbo\u017e\u00ed / Vlastn\u00ed v\u00fdroba / Nov\u00e9 zbo\u017e\u00ed";
export const BZ2_FACET_SCREEN_SIZE = "\u00dahlop\u0159\u00ed\u010dka";
export const BZ2_HANDMADE_PRODUCT_HOVER = "Vlastn\u00ed v\u00fdroba";
export const BZ2_JOBS_PRODUCT_HOVER = "Pr\u00e1ce";
export const BZ2_NEW_PRODUCT_HOVER = "Nov\u00e9 zbo\u017e\u00ed";
export const BZ2_OLD_PRODUCT_HOVER = "Pou\u017eit\u00e9 zbo\u017e\u00ed";
export const BZ2_SERVICES_PRODUCT_HOVER = "Slu\u017eby";
export const BZ2_SORT_COMPLAINTS = "Nejv\u00edce nahl\u00e1\u0161en\u00e9";
export const BZ2_SORT_COMPLAINTS_ASC = "Nejm\u00e9n\u011b nahl\u00e1\u0161en\u00e9";
export const BZ2_SORT_COMPLAINT_TIME = "Nahl\u00e1\u0161en\u00e9: ned\u00e1vno";
export const BZ2_SORT_COMPLAINT_TIME_ASC = "Nahl\u00e1\u0161en\u00e9: d\u00e1vno";
export const BZ2_SORT_PRICE = "Cena: nejlevn\u011bj\u0161\u00ed";
export const BZ2_SORT_PRICE_DESC = "Cena: nejdra\u017e\u0161\u00ed";
export const BZ2_SORT_RECENT_ADDED = "P\u0159id\u00e1no: Ned\u00e1vno";
export const BZ2_SORT_RECENT_UPDATED = "Aktualizov\u00e1no: Ned\u00e1vno";
export const BZ2_SORT_SCORE = "Popul\u00e1rn\u00ed";
export const BZ2_SORT_SCORE_NEW = "Relevantn\u00ed";
export const BZ2_SORT_WATCHDOG = "Ulo\u017een\u00e9 hled\u00e1n\u00ed";
export const BZ2_SORT_WISHLIST_ADDED_ASC = "Ulo\u017een\u00e9: d\u00e1vno";
export const BZ2_SORT_WISHLIST_ADDED_DESC = "Ulo\u017een\u00e9: ned\u00e1vno";
export const PRODUCT_FILTER_ACTUALIZABLE = "Daj\u00ed se aktualizovat";
export const PRODUCT_FILTER_ALL = "V\u0161e (krom\u011b smazan\u00fdch)";
export const PRODUCT_FILTER_DELETED = "Smazan\u00e9";
export const PRODUCT_FILTER_DISPLAYING = "Zobrazuj\u00ed se";
export const PRODUCT_FILTER_EXPIRED = "Pro\u0161l\u00e9 (28 dn\u00ed +)";
export const PRODUCT_FILTER_NONDISPLAYING = "Nezobrazuj\u00ed se";
export const PRODUCT_FILTER_NOTANSWERED = "Kde jsem neodpov\u011bd\u011bla";
