import { EventEmitter } from 'events';

import { JAM_ENDPOINT } from 'mk/settings';
import { getLogger } from 'mk2/logger';
import { jamNotify } from 'mk2/services/jam.actions';

const logger = getLogger('services/jam');

let _client: Promise<Client> = null;
const channels = new Set<string>();
let reduxDispatch = null;

/**
 * message ktora pride zo serveru
 */
interface JamMessage {
    // format stringu je ping:{channel}
    data: string;
}

interface ClientOptions {
    reconnectInterval: number;
    maxReconnectInterval: number;
    reconnectDecay: number;
    timeoutInterval: number;
    maxReconnectAttempts?: number;
}

enum ClientStatuses {
    CONNECTING,
    CONNECTED,
    RECONNECTING,
    CLOSED,
}

class Client extends EventEmitter {

    private instance: WebSocket;

    private options: ClientOptions = {
        reconnectInterval: 1000,
        maxReconnectInterval: 30000,
        reconnectDecay: 1.5,
        timeoutInterval: 5000,
    };

    private reconnectAttempts: 0;

    private state = ClientStatuses.CONNECTING;

    constructor(private url: string, options?: Partial<ClientOptions>) {
        super();

        this.options = {
            ...this.options,
            ...options,
        };

        this.open();
    }

    public send(data: any) {
        if (this.instance) {
            this.instance.send(data);
        } else {
            // Resend when connect / reconnect
            this.once(this.state === ClientStatuses.RECONNECTING ? 'reconnect' : 'connect', () => {
                this.send(data);
            });
        }
    }

    public close(code = 1000, reason?: string) {
        this.state = ClientStatuses.CLOSED;
        if (this.instance) {
            this.instance.close(code, reason);
        }
    }

    private open(reconnectAttempt = false) {
        if (reconnectAttempt) {
            if (this.options.maxReconnectAttempts && this.reconnectAttempts > this.options.maxReconnectAttempts) {
                return;
            }
        } else {
            this.emit('connecting');
            this.reconnectAttempts = 0;
        }

        this.instance = new WebSocket(this.url);

        let timeOut = false;
        const timeout = setTimeout(() => {
            timeOut = true;
            this.instance.close();
            this.emit('timeout');
            timeOut = false;
        }, this.options.timeoutInterval);

        this.instance.onopen = (event) => {
            clearTimeout(timeout);
            this.state = ClientStatuses.CONNECTED;
            this.reconnectAttempts = 0;
            this.emit(reconnectAttempt ? 'reconnect' : 'connect' , event);
            reconnectAttempt = false;
        };

        this.instance.onclose = (event) => {
            clearTimeout(timeout);
            this.instance = null;
            if (this.state === ClientStatuses.CLOSED) {
                this.emit('close', event);
            } else {
                this.state = ClientStatuses.RECONNECTING;
                this.emit('reconnecting', {
                    ...event,
                    reconnectAttempt: this.reconnectAttempts,
                });

                if (!reconnectAttempt && !timeOut) {
                    this.emit('close', event);
                }

                const delay = Math.min(this.options.reconnectInterval * Math.pow(this.options.reconnectDecay, this.reconnectAttempts), this.options.maxReconnectInterval);
                setTimeout(() => {
                    this.reconnectAttempts++;
                    this.open(true);
                }, delay);
            }
        };

        this.instance.onmessage = (event) => {
            this.emit('message', event);
        };

        this.instance.onerror = (event) => {
            const err = new Error(`Websocket error: (${event})`);
            (err as any).context = event;
            this.emit('error', err);
        };
    }

}

function createClient(): Promise<Client> {
    if (_client) {
        return _client;
    }

    if (typeof WebSocket === 'undefined') {
        // while normal android4-browser has no websocket support
        // some androids ("samsung galaxy s3 mini" for example)
        // has a not-working version. so we explicitly have to
        // disable it on android4-browser.
        return null;
    }

    const jamUrl: string = JAM_ENDPOINT;
    if (!jamUrl) {
        // jam endpoint is not defined
        return null;
    }

    _client = new Promise<Client>((resolve, reject) => {
        const instance = new Client(jamUrl);

        instance.addListener('message', (event: JamMessage) => {
            const message = event.data;
            const match = message.match(/^ping:(.+)$/);
            if (!match) {
                logger.error(`invalid message from jam: {message}`);
                return;
            }

            const channel = match[1];
            reduxDispatch(jamNotify(channel));
        });

        instance.on('connect', () => {
            resolve(instance);
        });

        instance.on('error', (err) => {
            reject(err);
        });

        // Resubscribe to channels when reconnected
        instance.on('reconnect', () => {
            channels.forEach((channel) => {
                instance.send(`subscribe:${channel}`);
            });
        });
    });

    return _client;
}

async function close() {
    if (!_client) {
        // already closed
        return;
    }

    const instance = await _client;
    instance.close();
    _client = null;
}

export function initJam(dispatch) {
    reduxDispatch = dispatch;
}

export async function subscribeJam(channel: string) {
    if (channels.has(channel)) {
        return; // Ignore - we are already subscribed
    }

    channels.add(channel);

    try {
        const client = await createClient();
        if (!client) {
            // jam is disabled
            return;
        }

        client.send('subscribe:' + channel);
    } catch (err) {
        console.error(err); // tslint:disable-line
        // NOTE: Ignore sending to sentry - currently we can do nothing with that
        // logger.error(err);
    }
}

export async function unsubscribeJam(channel: string) {
    if (!channels.has(channel)) {
        return; // Ignore - we are not subscribed
    }

    channels.delete(channel);

    try {
        const client = await createClient();
        if (!client) {
            // jam is disabled
            return;
        }

        client.send('unsubscribe:' + channel);

        if (!channels.size) {
            await close();
        }
    } catch (err) {
        console.error(err); // tslint:disable-line
        // NOTE: Ignore sending to sentry - currently we can do nothing with that
        // logger.error(err);
    }
}
