/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const dining_other : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["LEARNING_TOWERS", "U\u010d\u00edc\u00ed v\u011b\u017ee"],
        ["CUP", "Hrne\u010dek"],
        ["BOTTLE", "L\u00e1hev (ne kojeneck\u00e1)"],
        ["CUTLERY", "P\u0159\u00edbory"],
        ["PLATES_BOWLS", "Tal\u00ed\u0159e, misky"],
        ["DINING_SET", "J\u00eddeln\u00ed set"],
        ["DINING_MAT", "J\u00eddeln\u00ed podlo\u017eka"],
        ["REFILLABLE_POCKETS", "Plniteln\u00e9 kapsi\u010dky"],
        ["FRUIT_FEEDING_NETS", "Krm\u00edc\u00ed s\u00ed\u0165ka na ovoce"],
        ["APRON_BIB", "Z\u00e1st\u011brka, brynd\u00e1k"],
        ["THERMOS", "Termoska, termoobal"],
        ["OTHER_DINING", "Jin\u00e9"],
    ],
};
