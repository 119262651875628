import { goBack, push, replace, LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import { ScrollToTop } from 'mk2/components/ScrollToTop';
import { FormPageType } from 'mk2/constants/enums';
import {
    mkReduxFormCancelPage,
    MK_REDUX_FORM_CANCEL_PAGE,
    MK_REDUX_FORM_CLOSE_PAGE,
    MK_REDUX_FORM_OPEN_PAGE,
    MKReduxFormCancelPage,
    MKReduxFormClosePage,
    MKReduxFormOpenPage,
} from 'mk2/helpers/form.actions';
import { MKReduxFormState } from 'mk2/helpers/form.reducers';
import { getSessionStorage } from 'mk2/helpers/storages';
import { getFormState } from 'mk2/reducers';
import { getRoutingLocation } from 'mk2/selectors';
import { HistoryLocationState } from 'mk2/services/browserHistory';
import { parse, stringify } from 'query-string';
import { all, put, select, takeEvery } from 'redux-saga/effects';

function* openFormPage({ formPage, formName }: MKReduxFormOpenPage) {
    const location = yield select(getRoutingLocation);
    if (location.state?.currentPage === undefined) {
        yield put(
            replace<HistoryLocationState>({
                ...location,
                search: stringify({
                    ...parse(location.search),
                    // MS Edge has location.state === undefined when goBack()
                    // so track state in query string
                    fp: FormPageType.Main,
                    fn: formName,
                    fip: '1',
                }),
                state: {
                    formIgnorePrompt: true,
                    formName,
                    currentPage: FormPageType.Main,
                },
            }),
        );
        const replaced = yield select(getRoutingLocation);
        // We are pushing location change updates
        // and react can skip re-rendering (batching)
        // so fake ScrollToTop position save
        getSessionStorage().setItem(`ScrollToTop__scroll__${replaced.key}`, `${ScrollToTop.getScrollTop()}`);
    }

    if (location.state?.currentPage !== formPage) {
        yield put(
            push<HistoryLocationState>({
                ...location,
                search: stringify({
                    ...parse(location.search),
                    // MS Edge has location.state === undefined when goBack()
                    // so track state in query string
                    fp: formPage,
                    fn: formName,
                    fip: '1',
                }),
                state: {
                    formIgnorePrompt: true,
                    formName,
                    currentPage: formPage,
                },
            }),
        );
    }
}

function* cancelFormPage({}: MKReduxFormCancelPage) {
    const location = yield select(getRoutingLocation);
    const qs = parse(location.search, { parseNumbers: true });
    const formName = location.state?.formName || (qs.fn as string);
    if (!formName) {
        return; // Ignore
    }

    const formState: MKReduxFormState = yield select(getFormState, formName);
    const currentPage = location.state?.currentPage || (qs.fp as number) || FormPageType.Main;
    if (currentPage !== formState.currentFormPage) {
        yield put(goBack());
    }
}

function* closeFormPage({}: MKReduxFormClosePage) {
    yield put(goBack());
}

function* locationChange({ payload }: LocationChangeAction<HistoryLocationState>) {
    // Close form page only on pop
    if (payload.action !== 'POP') {
        return;
    }

    const location = payload.location;
    const qs = parse(payload.location.search, { parseNumbers: true });
    const formName = location.state?.formName || (qs.fn as string);
    if (!formName) {
        return; // Ignore
    }

    const formState: MKReduxFormState = yield select(getFormState, formName);
    const currentPage = location.state?.currentPage || (qs.fp as number) || FormPageType.Main;
    // Cancel current page but skip Main page - we have nothing to cancel
    if (currentPage !== formState.currentFormPage) {
        // Cancel current page
        yield put(mkReduxFormCancelPage(formName));
    }
}

export default function* root() {
    yield all([
        takeEvery(MK_REDUX_FORM_OPEN_PAGE, openFormPage),
        takeEvery(MK_REDUX_FORM_CANCEL_PAGE, cancelFormPage),
        takeEvery(MK_REDUX_FORM_CLOSE_PAGE, closeFormPage),
        takeEvery(LOCATION_CHANGE, locationChange),
    ]);
}
