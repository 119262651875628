import { ACTIVE_SERVER_ID } from 'mk/settings';
import { COMMON_PLACE_CATEGORY_SLUG } from 'mk2/apps/forum/constants';
import { ID_RE, SLUG_LEGACY_RE, SLUG_RE, USERNAME } from 'mk2/helpers/urls';

const TOPIC_SLUG = `:topicSlug(${SLUG_RE})`;
const POST_SLUG = `:postSlug(${SLUG_LEGACY_RE})`;
const CATEGORY_SLUG = `:categorySlug(${SLUG_RE})`;
const POST_ID = `:postId(${ID_RE})`;
const COMMENT_ID = `:commentId(${ID_RE})`;
const TOPIC_ID = `:topicId(${ID_RE})`;
const CONTEST_ID = `:contestId(${ID_RE})`;

const FOREIGN_COUNTRIES: string = {201: 'zahranicie', 202: 'zahranici'}[ACTIVE_SERVER_ID];

export const forumUrl = '/forum/';
export const forumUpdatedUrl = '/forum/updated/';
export const forumMyActivityFeedUrl = '/forum/my-activity/';
export const forumReportedUrl = `/forum/reported/${TOPIC_SLUG}/`;
export const forumTopicsUrl = '/forum/topics/';
export const forumListOfForumsUrl = forumTopicsUrl;
export const forumCommonPlaceForeignTopicsUrl = `/forum/topics/${COMMON_PLACE_CATEGORY_SLUG}/${FOREIGN_COUNTRIES}/`;
export const forumCommonPlaceTopicsUrl = `/forum/topics/${COMMON_PLACE_CATEGORY_SLUG}/`;
export const forumTopicsCategoryUrl = `/forum/topics/${CATEGORY_SLUG}/`;
export const forumTopicUrl = `/forum/category/${TOPIC_SLUG}/`;  // nepouzivat priamo ale cez topicUrl(), kvoli seo zatial s 'category' namiesto 'topic'
export const forumTopicNewUrl = `/forum/topic/new/`;
export const forumTopicInfoUrl = `/forum/topic/info/${TOPIC_SLUG}/`;
export const forumTopicEditUrl = `/forum/topic/edit/${TOPIC_ID}/`;
export const forumTopicModeratorInvitationUrl = `/forum/topic/moderator-invitation/${TOPIC_SLUG}/${USERNAME}/`;
export const forumPostNewUrl = '/forum/thread/new/';
export const forumPostNewInCategoryUrl = `/forum/thread/new/${CATEGORY_SLUG}/`;
export const forumPostNewInTopicUrl = `/forum/thread/new/${CATEGORY_SLUG}/${TOPIC_SLUG}/`;
export const forumPostLikersUrl = `/forum/${TOPIC_SLUG}/${POST_SLUG}/likers/`;
export const forumPostCommentLikersUrl = `/forum/${TOPIC_SLUG}/${POST_SLUG}/${COMMENT_ID}/likers/`;
export const forumPostEditUrl = `/forum/post/edit/${POST_ID}/`;
export const forumPostDetailUrl = `/forum/${TOPIC_SLUG}/${POST_SLUG}/`;
export const forumContestNewUrl = `/forum/contest/new/${TOPIC_SLUG}/`;
export const forumContestEditUrl = `/forum/contest/edit/${CONTEST_ID}/`;
export const forumCommentEditUrl = `/forum/comment/edit/${COMMENT_ID}/`;
export const forumBrandDaysArchiveUrl = '/forum/archive/campaigns/';
export const forumSearchUrl = '/forum/search/';
export const forumUntrustworthyUserFeedUrl = `/forum/untrustworthy-user/${USERNAME}/`;
