import { heartsUrl } from 'mk2/apps/hearts/urls';
import { Route } from 'mk2/containers/Application/Application';

export const routes: Route[] = [{
    app: 'hearts',
    exact: true,
    path: heartsUrl,
    getPage: () => {
        return import('mk2/apps/hearts/containers/HeartsForm/HeartsFormPage' /* webpackChunkName: "hearts.HeartsForm" */);
    },
    modules: [ 'mk2/apps/hearts/containers/HeartsForm/HeartsFormPage' ],

}];
