///////////////////////////////////////////////////////////////////
// Set BlogPost's Comments opened

export const BLOG_POST_SET_COMMENTS_OPENED = 'BLOG_POST_SET_COMMENTS_OPENED';
export interface BlogPostSetCommentsOpenedAction {
    readonly type: typeof BLOG_POST_SET_COMMENTS_OPENED;
    readonly postId: number;
    readonly commentsOpened: boolean;
}

export const blogPostSetCommentsOpened =
    (postId: number, commentsOpened: boolean): BlogPostSetCommentsOpenedAction => ({
        type: BLOG_POST_SET_COMMENTS_OPENED, postId, commentsOpened,
    });
