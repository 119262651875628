/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp14 } from './commonProp14';
import { commonProp4 } from './commonProp4';

export const mountaineering_footwear : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp14,
    p_brand: [
        d("alfa"),
        d("BOSP"),
        d("Five Ten"),
        d("Garmont"),
        d("Grifit"),
        d("Hanwag"),
        d("High Colorado"),
        d("Hoka One One"),
        d("La Sportiva"),
        d("Lowa"),
        d("Oc\u00fan"),
        d("Terrex"),
        d("Triop"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
