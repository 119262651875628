/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const kids_party : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PLATES", "Tal\u00ed\u0159e"],
        ["CUPS", "Skleni\u010dky"],
        ["TABLE_CLOTHS", "Ubrusy"],
        ["NAPKINS", "Ubrousky"],
        ["DRINKING_STRAWS", "Br\u010dka"],
        ["PARTY_SET", "P\u00e1rty set"],
        ["PARTY_CANDLES_CAKE_ACCESSORIES", "Sv\u00ed\u010dky a ozdoby na dort"],
        ["BALLONS", "Bal\u00f3nky"],
        ["TABLE_DECORATIONS", "Dekorace na st\u016fl"],
        ["HANGING_DECORATIONS", "Z\u00e1v\u011bsn\u00e9 dekorace"],
        ["INVITATIONS_NAME_CARDS", "Pozv\u00e1nky, jmenovky"],
        ["HEAD_ACCESSORIES", "\u010celenky, klobou\u010dky"],
        ["PARTY_GAMES", "Hry a z\u00e1bava na p\u00e1rty"],
        ["KIDS_PARTY_OTHER", "Ostatn\u00ed"],
    ],
    p_thema: [
        ["THEMA_TRANSPORTATIONS", "Auta, vlaky a dopravn\u00ed prost\u0159edky"],
        ["THEMA_DINOSAUR", "Dinosau\u0159i"],
        ["THEMA_FOOTBALL", "Fotbal"],
        ["THEMA_PIRATES_SAILORS", "Pir\u00e1ti, pir\u00e1tky a n\u00e1mo\u0159n\u00edci"],
        ["THEMA_PRINCESS_FAIRY_BALLET", "Princezny, v\u00edly a tane\u010dnice"],
        ["THEMA_PRINCE_KNIGHT", "Princov\u00e9 a ryt\u00ed\u0159i"],
        ["THEMA_CLOWN", "\u0160a\u0161kov\u00e9 a klauni"],
        ["THEMA_SPACE", "Vesm\u00edr"],
        ["THEMA_ANIMALS", "Zv\u00ed\u0159\u00e1tka, hmyz"],
        ["THEMA_101_DALMATIANS", "101 dalmatin\u016f"],
        ["THEMA_ANGRY_BRIDS", "Angry Birds"],
        ["THEMA_CARS_MCQUEEN", "Auta (Blesk McQueen)"],
        ["THEMA_AVENGERS", "Avengers"],
        ["THEMA_BARBIE", "Barbie"],
        ["THEMA_BATMAN", "Batman"],
        ["THEMA_DUMBO", "Dumbo"],
        ["THEMA_HELLO_KITTY", "Hello Kitty"],
        ["THEMA_PLANES_DISNEY", "Letadla (Planes)"],
        ["THEMA_LITTLE_PET_SHOP", "Little Pet Shop"],
        ["THEMA_FROZEN_DISNEY", "Ledov\u00e9 kr\u00e1lovstv\u00ed (Frozen)"],
        ["THEMA_WINNIE-THE-POOH", "Medv\u00eddek Pu"],
        ["THEMA_MASHA_AND_THE_BEAR", "M\u00e1\u0161a a medv\u011bd"],
        ["THEMA_MIA_AND_ME", "Mia a j\u00e1 (Mia and Me)"],
        ["THEMA_MICKEY_MOUSE", "Mickey Mouse"],
        ["THEMA_MINIE_MOUSE", "Minie Mouse"],
        ["THEMA_THE_MINIONS", "Mimoni (The Minions)"],
        ["THEMA_MONSTER_HIGH", "Monster High"],
        ["THEMA_MY_LITTLE_PONY", "My Little Pony"],
        ["THEMA_NEMO", "Nemo"],
        ["THEMA_NINJA_TURTLES", "Ninja \u017eelvy"],
        ["THEMA_PEPPA_PIG", "Pras\u00e1tko Pepina (Peppa Pig)"],
        ["THEMA_CINDERELLA", " Popelka (Cinderella)"],
        ["THEMA_FIREMAN_SAM", "Hasi\u010d Sam"],
        ["THEMA_SOFIA_THE_FIRST", "Princezna Sofie prvn\u00ed"],
        ["THEMA_MONSTERS_INC", "P\u0159\u00ed\u0161erky (Monsters)"],
        ["THEMA_SCOOBY_DOO", "Scooby Doo"],
        ["THEMA_SHREK", "Shrek"],
        ["THEMA_SNOW_WHITE", "Sn\u011bhurka"],
        ["THEMA_SPIDERMAN", "Spiderman"],
        ["THEMA_SPONGE_BOB", "Sponge Bob"],
        ["THEMA_STAR_WARS", "Star Wars"],
        ["THEMA_THE_SMURFS", "\u0160moulov\u00e9"],
        ["THEMA_TRANSFORMERS", "Transformers"],
        ["THEMA_MAYA_THE_BEE", "V\u010delka M\u00e1ja"],
        ["THEMA_THOMAS", "Ma\u0161inka Tom\u00e1\u0161"],
        ["THEMA_CILILING", "Zvonilka (Cililing)"],
        ["THEMA_WEDDING", "Svatba"],
        ["THEMA_BABY_BORN", "Narozen\u00ed d\u00edt\u011bte"],
        ["THEMA_ANNIVERSARY", "V\u00fdro\u010d\u00ed"],
        ["THEMA_STAG_PARTY", "Rozlu\u010dka se svobodou"],
        ["THEMA_OTHER", "Jin\u00e1 t\u00e9ma"],
    ],
    p_sex: commonProp5,
    p_color: commonProp4,
};
