import {
    ArticleFetchFailureAction,
    ArticleFetchRequestAction,
    ArticleFetchSuccessAction,
    ARTICLE_FETCH_FAILURE,
    ARTICLE_FETCH_REQUEST,
    ARTICLE_FETCH_SUCCESS,
} from 'mk2/apps/blogs/containers/Article/Article.actions';
import type { ArticleEditSaveArticleSuccessAction } from 'mk2/apps/blogs/containers/ArticleEdit/ArticleEdit.actions';
import { tupdate } from 'mk2/helpers/types';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

export interface ArticleState {
    postId: number | null;
    suggestedArticleIds: number[];
    suggestedArticlesHomepagePhotos: {[articleId: number]: number};
    loadingState: LoadingState;
    errorMessage: string;
}

export const initialArticleState: ArticleState = {
    postId: null,
    suggestedArticleIds: null,
    suggestedArticlesHomepagePhotos: {},
    loadingState: LoadingState.INIT,
    errorMessage: null,
};

export interface ArticlesState {
    [key: string]: ArticleState;
}

const initialArticlesState: ArticlesState = {};

declare type ArticlesReducerAction =
    ArticleFetchRequestAction
    | ArticleFetchSuccessAction
    | ArticleFetchFailureAction
    | ArticleEditSaveArticleSuccessAction;

const articlesReducer: Reducer<ArticlesState> = (state = initialArticlesState, action: ArticlesReducerAction) => {
    switch (action.type) {
        case ARTICLE_FETCH_REQUEST:
        case ARTICLE_FETCH_FAILURE: {
            const key = `${action.username}/${action.articleSlug}`;
            return tupdate(state, {
                [key]: tupdate(state[key] || initialArticleState, {
                    loadingState: loadingState(undefined, action),
                    errorMessage: errorMessage(undefined, action),
                }),
            });
        }

        case ARTICLE_FETCH_SUCCESS: {
            const key = `${action.username}/${action.articleSlug}`;
            return tupdate(state, {
                [key]: tupdate(state[key] || initialArticleState, {
                    postId: action.response.result.post,
                    suggestedArticleIds: action.response.result.suggestedArticles,
                    suggestedArticlesHomepagePhotos: action.response.result.suggestedArticlesHomepagePhotos,
                    loadingState: loadingState(undefined, action),
                    errorMessage: errorMessage(undefined, action),
                }),
            });
        }

        // Update state of article after editing
        // NOTE: Use string so that we do not bundle ArticleEdit.actions.ts file
        case 'ARTICLE_EDIT_SAVE_ARTICLE_SUCCESS': {
            const key = `${action.username}/${action.articleSlug}`;
            return tupdate(state, {
                [key]: tupdate(state[key] || initialArticleState, {
                    postId: action.response.result.post,
                }),
            });
        }

        default:
            return state;
    }
};

export default articlesReducer;
