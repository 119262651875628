/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp7 } from './commonProp7';
import { commonProp4 } from './commonProp4';

export const weight_training_clothing : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_size: commonProp7,
    p_brand: [
        d("Adidas"),
        d("ALPINE PRO"),
        d("Better Bodies"),
        d("Brutal Labz"),
        d("Czech Virus"),
        d("Gym Glamour"),
        d("GymBeam"),
        d("Hi-TEC"),
        d("IRON AESTHETICS"),
        d("Jack Wolfskin"),
        d("Kevin Levrone"),
        d("Klimatex"),
        d("Loap"),
        d("Nebbia"),
        d("Nike"),
        d("Power System"),
        d("Prom-IN"),
        d("Reebok"),
        d("Regatta"),
        d("Under Armour"),
        d("Warrior Labs"),
        d("Weider"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
