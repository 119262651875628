import fromPairs from 'lodash-es/fromPairs';
import * as bidders from 'mk/ox/hbBidders';
import * as sizes from 'mk/ox/hbSizes';
import { PrebidBidder } from 'mk/ox/hbTypes';
import { ACTIVE_SERVER_ID } from 'mk/settings';

export enum Collapse {
    NEVER,
    AFTER_FETCH,
    BEFORE_FETCH,
}

export interface AdUnit {
    name: string;
    gptid: string;
    sizeConfig: sizes.AdUnitSizeConfig;
    bids: PrebidBidder[];
    collapse: Collapse;
}

export const forum: AdUnit[] = [{
    name: 'Forum - Branding (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-branding2',
        202: '/10813505/mk.cz-branding2',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingDesktopOnly,
    bids: {
        201: [bidders.adform.brandingDesktopSK],
        202: [bidders.adform.brandingDesktopCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum - Branding (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-branding9',
        202: '/10813505/mk.cz-branding9',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingMobileOnly,
    bids: {
        201: [bidders.adform.brandingMobileSK],
        202: [bidders.adform.brandingMobileCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    // sticky zona musi byt specialne oznacena v AdX, aby neporusovala podmienky
    name: 'Forum Thread - Halfpage Sticky (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-halfpage2',
        202: '/10813505/mk.cz-halfpage2',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpage,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum Thread - Halfpage (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-halfpage9',
        202: '/10813505/mk.cz-halfpage9',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpageMobileOnly,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.BEFORE_FETCH,  // CeMi 9.9.2021: lebo coverage je < 50%
}, {
    name: 'Forum Thread - Halfpage 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-halfpage12',
        202: '/10813505/mk.cz-halfpage12',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpageMobileOnly,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.BEFORE_FETCH,  // CeMi 9.9.2021: lebo coverage je < 50%
}, {
    name: 'Forum Thread - Rectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle7',
        202: '/10813505/mk.cz-rectangle7',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    // videoFormat: VideoFormat.OutstreamRectangle,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum Thread - Rectangle 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle8',
        202: '/10813505/mk.cz-rectangle8',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum Thread - Rectangle 3 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle9',
        202: '/10813505/mk.cz-rectangle9',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum Thread - Rectangle 4 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle10',
        202: '/10813505/mk.cz-rectangle10',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum Thread - Rectangle 5 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle28',
        202: '/10813505/mk.cz-rectangle28',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name:  'Forum Thread - Rectangle 6 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle29',
        202: '/10813505/mk.cz-rectangle29',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum Category - Rectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle14',
        202: '/10813505/mk.cz-rectangle14',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum Category - Rectangle 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle15',
        202: '/10813505/mk.cz-rectangle15',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    // sticky zona musi byt specialne oznacena v AdX, aby neporusovala podmienky
    name: 'Forum Category - Halfpage Sticky (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-halfpage5',
        202: '/10813505/mk.cz-halfpage5',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpage,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum - NativeRectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-nativerectangle3',
        202: '/10813505/mk.cz-nativerectangle3',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.nativeRectangleDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Forum - NativeRectangle 1 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-nativerectangle4',
        202: '/10813505/mk.cz-nativerectangle4',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.nativeRectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}];

export const groups: AdUnit[] = [{
    name: 'Groups - Branding (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-branding4',
        202: '/10813505/mk.cz-branding4',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingDesktopOnly,
    bids: {
        201: [bidders.adform.brandingDesktopSK],
        202: [bidders.adform.brandingDesktopCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups - Branding (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-branding11',
        202: '/10813505/mk.cz-branding11',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingMobileOnly,
    bids: {
        201: [bidders.adform.brandingMobileSK],
        202: [bidders.adform.brandingMobileCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups Feed - Halfpage Sticky (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-halfpage3',
        202: '/10813505/mk.cz-halfpage3',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpage,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups Feed - Halfpage (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-halfpage10',
        202: '/10813505/mk.cz-halfpage10',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpageMobileOnly,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups Feed - Rectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle20',
        202: '/10813505/mk.cz-rectangle20',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups Feed - Rectangle 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle21',
        202: '/10813505/mk.cz-rectangle21',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups Feed - Rectangle 3 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle22',
        202: '/10813505/mk.cz-rectangle22',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups Feed - Rectangle 4 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle23',
        202: '/10813505/mk.cz-rectangle23',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups Feed - Rectangle 5 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle30',
        202: '/10813505/mk.cz-rectangle30',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Groups Feed - Rectangle 6 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle31',
        202: '/10813505/mk.cz-rectangle31',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}];

export const blogs: AdUnit[] = [{
    name: 'Blogs - Branding (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-branding3',
        202: '/10813505/mk.cz-branding3',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingDesktopOnly,
    bids: {
        201: [bidders.adform.brandingDesktopSK],
        202: [bidders.adform.brandingDesktopCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs - Branding (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-branding10',
        202: '/10813505/mk.cz-branding10',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingMobileOnly,
    bids: {
        201: [bidders.adform.brandingMobileSK],
        202: [bidders.adform.brandingMobileCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Feed - Halfpage Sticky (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-halfpage4',
        202: '/10813505/mk.cz-halfpage4',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpage,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Feed - Rectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle16',
        202: '/10813505/mk.cz-rectangle16',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Feed - Rectangle 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle17',
        202: '/10813505/mk.cz-rectangle17',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Feed - Rectangle 3 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle18',
        202: '/10813505/mk.cz-rectangle18',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Feed - Rectangle 4 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle19',
        202: '/10813505/mk.cz-rectangle19',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Feed - Halfpage (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-halfpage11',
        202: '/10813505/mk.cz-halfpage11',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpageMobileOnly,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    // sticky zona musi byt specialne oznacena v AdX, aby neporusovala podmienky
    name: 'Blogs Article - Halfpage Sticky (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-halfpage7',
        202: '/10813505/mk.cz-halfpage7',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpage,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Article - Rectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle11',
        202: '/10813505/mk.cz-rectangle11',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Article - Rectangle 2 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle12',
        202: '/10813505/mk.cz-rectangle12',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Article - Rectangle 3 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle13',
        202: '/10813505/mk.cz-rectangle13',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Blogs Article - Rectangle 4 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle26',
        202: '/10813505/mk.cz-rectangle26',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}];

export const market: AdUnit[] = [{
    name: 'Market - Branding (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-branding5',
        202: '/10813505/mk.cz-branding5',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market - Branding (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-branding12',
        202: '/10813505/mk.cz-branding12',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    // sticky zona musi byt specialne oznacena v AdX, aby neporusovala podmienky
    name: 'Market Item - Halfpage Sticky (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-halfpage1',
        202: '/10813505/mk.cz-halfpage1',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpageOffsite,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
// CeMi: 14.6.2017 - vypnuty, generuje len 250Eur mesacne, a kazi nizkym CTR priame kampane
//     name: 'Market Item - Billboard (Desktop)',
//     gptid: {
//         201: '/10813505/mk.sk-billboard1',
//         202: '/10813505/mk.cz-billboard1',
//     }[ACTIVE_SERVER_ID],
//     sizeConfig: sizes.billboard,
//     bids: {
//         201: [bidders.adform.billboardSK],
//         202: [bidders.adform.billboardCZ],
//     }[ACTIVE_SERVER_ID],
//     collapse: Collapse.AFTER_FETCH,
// } {,
    name: 'Market Item - Rectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle4',
        202: '/10813505/mk.cz-rectangle4',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Item - Rectangle 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle5',
        202: '/10813505/mk.cz-rectangle5',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Item - Rectangle 3 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle6',
        202: '/10813505/mk.cz-rectangle6',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Section - Billboard 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-billboard2',
        202: '/10813505/mk.cz-billboard2',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.billboard,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Section - Billboard 2 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-billboard3',
        202: '/10813505/mk.cz-billboard3',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.billboard,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Section - Rectangle 1 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle1',
        202: '/10813505/mk.cz-rectangle1',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Section - Rectangle 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle2',
        202: '/10813505/mk.cz-rectangle2',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Section - Rectangle 3 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle3',
        202: '/10813505/mk.cz-rectangle3',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Section - Rectangle 4 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle32',
        202: '/10813505/mk.cz-rectangle32',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Market Section - Rectangle 5 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle33',
        202: '/10813505/mk.cz-rectangle33',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}];

export const wiki: AdUnit[] = [{
    name: 'Wiki - Branding (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-branding6',
        202: '/10813505/mk.cz-branding6',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingDesktopOnly,
    bids: {
        201: [bidders.adform.brandingDesktopSK],
        202: [bidders.adform.brandingDesktopCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Wiki - Branding (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-branding13',
        202: '/10813505/mk.cz-branding13',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingMobileOnly,
    bids: {
        201: [bidders.adform.brandingMobileSK],
        202: [bidders.adform.brandingMobileCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    // sticky zona musi byt specialne oznacena v AdX, aby neporusovala podmienky
    name: 'Wiki - Halfpage Sticky (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-halfpage8',
        202: '/10813505/mk.cz-halfpage8',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpage,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Wiki - Rectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle27',
        202: '/10813505/mk.cz-rectangle27',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Wiki - Rectangle 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle34',
        202: '/10813505/mk.cz-rectangle34',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}];

export const homepage: AdUnit[] = [{
    name: 'Homepage - Branding (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-branding1',
        202: '/10813505/mk.cz-branding1',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingDesktopOnly,
    bids: {
        201: [bidders.adform.brandingDesktopSK],
        202: [bidders.adform.brandingDesktopCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Homepage - Branding (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-branding8',
        202: '/10813505/mk.cz-branding8',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingMobileOnly,
    bids: {
        201: [bidders.adform.brandingMobileSK],
        202: [bidders.adform.brandingMobileCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Homepage - Rectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-rectangle24',
        202: '/10813505/mk.cz-rectangle24',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.NEVER,  // aby nas nepenalizovalo za LCS na homepage above the fold
}, {
    name: 'Homepage - Rectangle 2 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-rectangle25',
        202: '/10813505/mk.cz-rectangle25',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: {
        201: [bidders.adform.rectangleSK],
        202: [bidders.adform.rectangleCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Homepage - NativeRectangle 1 (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-nativerectangle1',
        202: '/10813505/mk.cz-nativerectangle1',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.nativeRectangleDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Homepage - NativeRectangle 1 (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-nativerectangle2',
        202: '/10813505/mk.cz-nativerectangle2',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.nativeRectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}];

export const mail: AdUnit[] = [{
    name: 'Mail - Commercial (Desktop & Mobile)',
    gptid: {
        201: '/10813505/mk.sk-ip1',
        202: '/10813505/mk.cz-ip1',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.mail,
    bids: [],
    collapse: Collapse.NEVER,
}, {
    name: 'Mail - CTA Button (Desktop & Mobile)',
    gptid: {
        201: '/10813505/mk.sk-ip3',
        202: '/10813505/mk.cz-ip3',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.mailCtaButton,
    bids: [],
    collapse: Collapse.NEVER,
}];

export const strollers: AdUnit[] = [{
    name: 'Strollers - Branding (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-branding17',
        202: '/10813505/mk.cz-branding17',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Strollers - Branding (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-branding18',
        202: '/10813505/mk.cz-branding18',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.brandingMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Strollers Index - Halfpage Sticky (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-halfpage6',
        202: '/10813505/mk.cz-halfpage6',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.halfpage,
    bids: {
        201: [bidders.adform.halfpageSK],
        202: [bidders.adform.halfpageCZ],
    }[ACTIVE_SERVER_ID],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Strollers Detail - Rectangle (Desktop)',
    gptid: {
            201: '/10813505/mk.sk-rectangle35',
            202: '/10813505/mk.cz-rectangle35',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleDesktopOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}, {
    name: 'Strollers Detail - Rectangle (Mobile)',
    gptid: {
            201: '/10813505/mk.sk-rectangle36',
            202: '/10813505/mk.cz-rectangle36',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.rectangleMobileOnly,
    bids: [],
    collapse: Collapse.AFTER_FETCH,
}];

export const interstitial: AdUnit[] = [{
    name: 'Interstitial (Mobile)',
    gptid: {
        201: '/10813505/mk.sk-interstitial1',
        202: '/10813505/mk.cz-interstitial1',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.interstitialMobileOnly,
    bids: [],
    collapse: Collapse.NEVER,
}, {
    name: 'Interstitial (Desktop)',
    gptid: {
        201: '/10813505/mk.sk-interstitial2',
        202: '/10813505/mk.cz-interstitial2',
    }[ACTIVE_SERVER_ID],
    sizeConfig: sizes.interstitialDesktopOnly,
    bids: [],
    collapse: Collapse.NEVER,
}];

// see https://admanager.google.com/10813505#inventory/ad_unit/list
export const AD_UNITS: {[adUnitName: string]: AdUnit } = fromPairs([
    ...homepage,
    ...forum,
    ...groups,
    ...blogs,
    ...market,
    ...wiki,
    ...mail,
    ...strollers,
    ...interstitial,
].map((adUnit) => [adUnit.name, adUnit]));

export function isBrandingDesktopAdUnit(adUnit: AdUnit) {
    return adUnit.name.endsWith('Branding (Desktop)');
}

export function isMailAdUnit(adUnit: AdUnit) {
    return adUnit.name === 'Mail - Commercial (Desktop & Mobile)' ||
        adUnit.name === 'Mail - CTA Button (Desktop & Mobile)';
}
