/**
 * Because of IE 11 and old androids we are increasing our bundles size
 */
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/map';
import 'core-js/features/object/assign';
import 'core-js/features/object/set-prototype-of';
import 'core-js/features/promise';
import 'core-js/features/regexp';
import 'core-js/features/set';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/pad-start';
import 'core-js/features/string/repeat';
import 'core-js/features/string/starts-with';
import 'core-js/features/weak-map';

// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
// For browsers that do not support Element.closest()
if (!Element.prototype.matches) {
    Element.prototype.matches =
        (Element.prototype as any).msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        let el = this; // tslint:disable-line:no-this-assignment

        do {
            if (el.matches(s)) {
                return el;
            }
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);

        return null;
    };
}
