import { FormPageType } from 'mk2/constants/enums';
import { PhotosUploadConfig } from 'mk2/containers/PhotosUpload/PhotosUpload';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { PhotoOrientation } from 'mk2/schemas';

/////////////////////////////////////////////////////////////////////
// Photos upload trigger

export const PHOTOS_UPLOAD_TRIGGER = 'PHOTOS_UPLOAD_TRIGGER';
export interface PhotosUploadTriggerAction {
    readonly type: typeof PHOTOS_UPLOAD_TRIGGER;
    readonly formName: string;
    readonly config: PhotosUploadConfig;
    readonly files: File[];
    readonly processPhotoUploadOnFormPage: FormPageType;

    // counselling reply - unauthenticated upload (e.g. accessKey='counselling_reply:slug:pE96mf3AUfB4mLJv')
    readonly accessKey?: string;
}

export const photosUploadTrigger =
    (formName: string, config: PhotosUploadConfig, files: File[], processPhotoUploadOnFormPage: FormPageType, accessKey?: string): PhotosUploadTriggerAction => ({
        type: PHOTOS_UPLOAD_TRIGGER, formName, config, files, processPhotoUploadOnFormPage, accessKey,
    });

/////////////////////////////////////////////////////////////////////
// (One) Photo upload

export const PHOTO_UPLOAD_REQUEST = 'PHOTO_UPLOAD_REQUEST';
export interface PhotoUploadRequestAction extends APIRequestAction {
    readonly type: typeof PHOTO_UPLOAD_REQUEST;
    readonly formName: string;
    readonly config: PhotosUploadConfig;
    readonly uploadId: number;
    readonly file: File;
    readonly accessKey?: string;
}
export const PHOTO_UPLOAD_PROGRESS = 'PHOTO_UPLOAD_PROGRESS';
export interface PhotoUploadProgressAction {
    readonly type: typeof PHOTO_UPLOAD_PROGRESS;
    readonly formName: string;
    readonly uploadId: number;
    progress: number;
}
export interface PhotoUploadNormalizedResponse extends NormalizedResponse {
    readonly result: {photo: number};
}
export const PHOTO_UPLOAD_SUCCESS = 'PHOTO_UPLOAD_SUCCESS';
export interface PhotoUploadSuccessAction extends APISuccessAction<PhotoUploadNormalizedResponse> {
    readonly type: typeof PHOTO_UPLOAD_SUCCESS;
    readonly formName: string;
    readonly uploadId: number;
}
export const PHOTO_UPLOAD_FAILURE = 'PHOTO_UPLOAD_FAILURE';
export interface PhotoUploadFailureAction extends APIFailureAction {
    readonly type: typeof PHOTO_UPLOAD_FAILURE;
    readonly formName: string;
    readonly uploadId: number;
}

export const photoUploadApi = {
    request: (formName: string, uploadId: number, config: PhotosUploadConfig, file: File, accessKey?: string): PhotoUploadRequestAction => ({
        type: PHOTO_UPLOAD_REQUEST, formName, uploadId, config, file, accessKey,
    }),
    progress: (formName: string, uploadId: number, progress: number): PhotoUploadProgressAction => ({
        type: PHOTO_UPLOAD_PROGRESS, formName, uploadId, progress,
    }),
    success: (formName: string, uploadId: number, response: PhotoUploadNormalizedResponse): PhotoUploadSuccessAction => ({
        type: PHOTO_UPLOAD_SUCCESS, formName, uploadId, response,
    }),
    failure: (formName: string, uploadId: number, error: any): PhotoUploadFailureAction => ({
        type: PHOTO_UPLOAD_FAILURE, formName, uploadId, error,
    }),
};

/////////////////////////////////////////////////////////////////////
// Photo rotate

export const PHOTO_UPLOAD_ROTATE = 'PHOTO_UPLOAD_ROTATE';
export interface PhotoUploadRotateAction {
    readonly type: typeof PHOTO_UPLOAD_ROTATE;
    readonly formName: string;
    readonly uploadId: number;
    readonly orientation: PhotoOrientation;
}

export const photoUploadRotate =
    (formName: string, uploadId: number, orientation: PhotoOrientation): PhotoUploadRotateAction => ({
        type: PHOTO_UPLOAD_ROTATE, formName, uploadId, orientation,
    });

/////////////////////////////////////////////////////////////////////
// Photo move

export const PHOTO_UPLOAD_MOVE = 'PHOTO_UPLOAD_MOVE';

export interface PhotoUploadMoveAction {
    readonly type: typeof PHOTO_UPLOAD_MOVE;
    readonly formName: string;
    readonly from: number;
    readonly to: number;
}

export const photoUploadMove = (formName: string, from: number, to: number): PhotoUploadMoveAction => ({ type: PHOTO_UPLOAD_MOVE, formName, from, to });

/////////////////////////////////////////////////////////////////////
// Photo remove

export const PHOTO_UPLOAD_REMOVE = 'PHOTO_UPLOAD_REMOVE';
export interface PhotoUploadRemoveAction {
    readonly type: typeof PHOTO_UPLOAD_REMOVE;
    readonly formName: string;
    readonly uploadId: number;
}

export const photoUploadRemove =
    (formName: string, uploadId: number): PhotoUploadRemoveAction => ({
        type: PHOTO_UPLOAD_REMOVE, formName, uploadId,
    });

export const PHOTO_UPLOAD_REMOVE_ALL = 'PHOTO_UPLOAD_REMOVE_ALL';
export interface PhotoUploadRemoveAllAction {
    readonly type: typeof PHOTO_UPLOAD_REMOVE_ALL;
    readonly formName: string;
}

export const photoUploadRemoveAll =
    (formName: string): PhotoUploadRemoveAllAction => ({
        type: PHOTO_UPLOAD_REMOVE_ALL, formName,
    });
