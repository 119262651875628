/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const home_care_cleaning : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["LAUNDRY", "Na pran\u00ed a \u017eehlen\u00ed"],
        ["DISHWASHING", "Na n\u00e1dob\u00ed, do my\u010dky"],
        ["HOUSEHOLD_CLEANERS", "\u00daklid"],
        ["AIR_FRESHENERS", "Osv\u011b\u017eova\u010de vzduchu a v\u016fn\u011b do bytu"],
        ["OTHER_HOME_CARE_CLEANING", "Jin\u00e9"],
    ],
};
