/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const building_and_construction_material : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["ELECTRICAL_WIRING", "Elektroinstalace"],
        ["PLUMBING", "Vodoinstalace"],
        ["INSULATION", "Izolace"],
        ["HEATING_AND_AIR_CONDITIONING", "Topen\u00ed a klimatizace"],
        ["TILING_AND_PAVING", "Obklady a dla\u017eba"],
        ["SAND_PEBBLES_AND_SOIL", "P\u00edsek, kam\u00ednky a zemina"],
        ["BUILDING_MATERIAL", "Stavebn\u00ed materi\u00e1l"],
        ["ROOFING", "St\u0159e\u0161n\u00ed krytina"],
        ["FACADE_PLASTER_AND_PAINT", "Fas\u00e1da, om\u00edtky a barvy"],
        ["WINDOWS_AND_DOORS", "Okna a dve\u0159e"],
        ["PROJECTS", "Projekty"],
        ["CONSTRUCTION_SHEDS", "Stavebn\u00ed boudy"],
        ["CONSTRUCTION_MACHINERY_AND_TOOLS", "Stavebn\u00ed stroje a n\u00e1stroje"],
        ["SCAFFOLDING", "Le\u0161en\u00ed"],
        ["OTHER", "Jin\u00e9"],
    ],
};
