import { isMqMobile } from 'mk/common/responsive';
import {
    AdSlotRenderEndedAction,
    AdSlotUnregisterAction,
    AD_SLOT_RENDER_ENDED,
    AD_SLOT_UNREGISTER,
} from 'mk2/containers/AdSlot/AdSlot.actions';
import React from 'react';
import { all, takeLatest } from 'redux-saga/effects';
import styles from './AdSlotMail.mscss';

const isOrientationHandlerRegistered: Map<string, () => void> = new Map<string, () => void>();
function scaleAd(slotId: string) {
    const slot = document.getElementById(slotId);
    const parentDiv = slot.parentElement;

    // sirka AdSlotu pevne nastavena v DFP
    const fromWidth = 730;
    // vyska reklamy, zadava Tomas v backoffice (cislo z rozsahu 500 - 3000px)
    const contentHeight = Math.max(
        500,
        Math.min(3000, parseInt(parentDiv.getAttribute('data-content-height'), 10) || 1000),
    );

    if (isMqMobile()) {
        const toWidth = slot.clientWidth;
        const scale = Math.min(toWidth / fromWidth, 1);

        const childElem: HTMLElement = slot.firstChild as HTMLElement;
        if (childElem.tagName === 'DIV') {
            childElem.style.transform = `scale(${scale})`;
            childElem.style.transformOrigin = '50% 0';
        }

        if (!isOrientationHandlerRegistered.has(slotId)) {
            let timer;
            const orientationChangeHandler = () => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    scaleAd(slotId);
                }, 500);
            };

            window.addEventListener('orientationchange', orientationChangeHandler);

            const cleanup = () => {
                clearTimeout(timer);
                // One time only
                window.removeEventListener('orientationchange', orientationChangeHandler, false);
                isOrientationHandlerRegistered.delete(slotId);
            };
            isOrientationHandlerRegistered.set(slotId, cleanup);
        }

        slot.style.height = `${contentHeight * scale}px`;
    } else {
        slot.style.height = `${contentHeight}px`;
    }
}

function* rendered({ slotId, event }: AdSlotRenderEndedAction) {
    const slot = document.getElementById(slotId);

    if (event.isEmpty) {
        slot.classList.add(...styles['AdSlotMail__mail--notLoaded'].split(' '));
    } else {
        slot.classList.remove(...styles['AdSlotMail__mail--notLoaded'].split(' '));
    }

    const isFluid = event.size?.[0] === 0 && event.size?.[1] === 0;
    if (!event.isEmpty && !isFluid) {
        scaleAd(slotId);
    }
}

function* unregister({ slotId }: AdSlotUnregisterAction) {
    if (isOrientationHandlerRegistered.has(slotId)) {
        isOrientationHandlerRegistered.get(slotId)();
    }
}

export default function* root() {
    yield all([
        takeLatest(
            (action) => action.type === AD_SLOT_RENDER_ENDED && action.slotId.startsWith('mk-admail-'),
            rendered,
        ),
        takeLatest(
            (action) => action.type === AD_SLOT_UNREGISTER && action.slotId.startsWith('mk-admail-'),
            unregister,
        ),
    ]);
}
