/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const misc_motherhood : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PREGNANCY_BELT", "Podp\u016frn\u00fd p\u00e1s"],
        ["PREGNANCY_PILLOW", "T\u011bhotensk\u00fd pol\u0161t\u00e1\u0159"],
        ["PREGNANCY_MASSAGE", "Mas\u00e1\u017e a cvi\u010den\u00ed"],
        ["PREGNANCY_COSMETICS", "T\u011bhotensk\u00e1 kosmetika, kr\u00e9my, strie"],
        ["PREGNANCY_DIAGNOSTICS", "Ovula\u010dn\u00ed a t\u011bhotensk\u00fd test"],
        ["PREGNANCY_GADGETS", "Dom\u00e1c\u00ed ultrazvuk, mikroskop, tlak, teplom\u011br"],
        ["SEAT_BELT", "Bezpe\u010dnostn\u00ed p\u00e1s"],
    ],
};
