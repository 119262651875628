import { Location } from 'history';
import { getLogger } from 'mk2/logger';
import React from 'react';

declare const window: any;

interface OwnProps {
    app: string;
    location: Location;
}

type Props = OwnProps;

const logger = getLogger('TrackPageview');

function reportPageView(location: Location, app: string) {
    // Run on next event loop - so that we are not blocking react
    // TODO: consider requestIdleCallback?
    setTimeout(() => {
        const base = document.querySelector('base');
        const baseHref = base ? base.getAttribute('href') : '/';
        const url = `${baseHref.replace(/\/$/, '')}${location.pathname}${location.search}${location.hash}`;

        if (typeof (window.dataLayer) !== 'undefined') {
            window.dataLayer.push({
                'event': 'track_pageview',
                'gtm.elementUrl': url,
                'gtm.url': url,
            });
        } else {
            logger.error('no GTM datalayer');
        }
    });
}

export class TrackPageview extends React.Component<Props> {

    public componentDidMount() {
        reportPageView(this.props.location, this.props.app);
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.location !== prevProps.location) {
            reportPageView(this.props.location, this.props.app);
        }
    }

    public render() {
        const { children } = this.props;
        return children ? React.Children.only(children) : null;
    }

}
