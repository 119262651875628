// naschval tu zbieram globalne vsetky typy pages co mame vo formoch,
// aby sme videli, co sa da reusovat
export enum FormPageType {
    Main,
    Photos,
    Stickers,
    SelectTopicCategory,
    SelectGroupCategory,
    SelectScore,
    SelectFacetValue,
    ChangeAvatar,
    SignupPhoneVerification,
    SignupPhotoUpload,
    SignupTokenValidation,
    PhoneVerificationTokenValidation,
    AnonymousModeExplanation,
    SelectBazaarCategory,
    SelectBazaarProperty,
    SettingsEmailNotifications,
    SettingsWallNotifications,
    LoginPhoneVerification,
    LoginPhoneVerificationSupport,
    LoginSupport,
    BazaarCheckoutLocation,
}
