import { faqAskQuestionUrl, faqIndexUrl, faqOldHelpUrl } from 'mk2/apps/faq/urls';
import { Route } from 'mk2/containers/Application/Application';

export const routes: Route[] = [{
    app: 'faq',
    exact: true,
    path: faqAskQuestionUrl,
    getPage: () => {
        return import('mk2/apps/faq/containers/FaqAskQuestion/FaqAskQuestionPage' /* webpackChunkName: "faq.FaqAskQuestion" */);
    },
    modules: [ 'mk2/apps/faq/containers/FaqAskQuestion/FaqAskQuestionPage' ],
}, {
    app: 'faq',
    exact: true,
    path: faqOldHelpUrl,
    getPage: () => {
        return import('mk2/apps/faq/containers/HelpRedirect/HelpRedirectPage' /* webpackChunkName: "faq.HelpRedirect" */);
    },
    modules: [ 'mk2/apps/faq/containers/HelpRedirect/HelpRedirectPage' ],
}, {
    app: 'faq',
    exact: true,
    path: faqIndexUrl,
    getPage: () => {
        return import('mk2/apps/faq/containers/FaqIndex/FaqIndexPage' /* webpackChunkName: "faq.FaqIndex" */);
    },
    modules: [ 'mk2/apps/faq/containers/FaqIndex/FaqIndexPage' ],
}];
