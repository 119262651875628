import { Action } from 'redux';

export const WEBPUSH_NOTIFICATIONS_DISABLE = 'WEBPUSH_NOTIFICATIONS_DISABLE';

export interface WebpushNotificationsDisableAction extends Action {
    readonly type: typeof WEBPUSH_NOTIFICATIONS_DISABLE;
    readonly automatic: boolean;
}

export const webpushNotificationsDisable = (automatic: boolean = false): WebpushNotificationsDisableAction => ({
    type: WEBPUSH_NOTIFICATIONS_DISABLE,
    automatic,
});

export const WEBPUSH_NOTIFICATIONS_SUBSCRIBE = 'WEBPUSH_NOTIFICATIONS_SUBSCRIBE';

export interface WebpushNotificationsSubscribeAction extends Action {
    readonly type: typeof WEBPUSH_NOTIFICATIONS_SUBSCRIBE;
    readonly automatic: boolean;
}

export const webpushNotificationsSubscribe = (automatic: boolean = false): WebpushNotificationsSubscribeAction => ({
    type: WEBPUSH_NOTIFICATIONS_SUBSCRIBE,
    automatic,
});

export const WEBPUSH_NOTIFICATIONS_SUBSCRIBED = 'WEBPUSH_NOTIFICATIONS_SUBSCRIBED';

export interface WebpushNotificationsSubscribedAction extends Action {
    readonly type: typeof WEBPUSH_NOTIFICATIONS_SUBSCRIBED;
    readonly token: string;
}

export const webpushNotificationsSubscribed = (token: string): WebpushNotificationsSubscribedAction => ({
    type: WEBPUSH_NOTIFICATIONS_SUBSCRIBED,
    token,
});
