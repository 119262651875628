import { WIKI_CATEGORIES } from 'mk/autogenerated/wikiCategories';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { strollersPrefix, strollersWikiUrl } from 'mk2/apps/strollers/urls';
import { WIKI_CALCULATORS_ARTICLE_SLUG, WIKI_REVIEWS_ARTICLE_SLUG } from 'mk2/apps/wiki/helpers';
import { url, GetParams, ID_RE, SLUG_RE } from 'mk2/helpers/urls';

const ARTICLE_ID = `:articleId(${ID_RE})`;
const CATEGORY_SLUG = `:categorySlug(${SLUG_RE})`;
const ARTICLE_SLUG = `:articleSlug(${SLUG_RE})`;
const EXPERIENCE_ID = `:experienceId(${ID_RE})`;
const PHOTO_ID = `:photoId(${ID_RE})`;

export const wikiUrl = '/wiki/';
export const wikiReviewHomepageArticleUrl = `/:categorySlug(${WIKI_REVIEWS_ARTICLE_SLUG})/:articleSlug(${WIKI_REVIEWS_ARTICLE_SLUG})/`;
export const wikiReviewArticleUrl = `/:categorySlug(${WIKI_REVIEWS_ARTICLE_SLUG})/${ARTICLE_SLUG}/`;
export const wikiArticleUrl = `/:categorySlug(${WIKI_CATEGORIES.map((wc) => wc.slug).join('|')})/${ARTICLE_SLUG}/`;
export const wikiArticlePhotoUrl = `/:categorySlug(${WIKI_CATEGORIES.map((wc) => wc.slug).join('|')})/${ARTICLE_SLUG}/image/${PHOTO_ID}/`;
export const wikiArticleSearch = `/:categorySlug(${WIKI_CATEGORIES.map((wc) => wc.slug).join('|')})/${ARTICLE_SLUG}/search/`;
export const wikiArticleEditUrl = `/wiki/article/edit/${ARTICLE_ID}/`;
export const wikiArticleCreateUrl = '/wiki/article/new/';
export const wikiAddExperienceUrl = `/wiki/article/${CATEGORY_SLUG}/${ARTICLE_SLUG}/add-experience/`;
export const wikiAddExperienceToUndefinedArticleUrl = `/wiki/add-experience/`;
export const wikiArticleExperiencePhotoUrl = `/wiki/article/${CATEGORY_SLUG}/${ARTICLE_SLUG}/experience/${EXPERIENCE_ID}/image/${PHOTO_ID}/`;

export function getWikiArticleUrl(
    categorySlug: string,
    articleSlug: string,
    getParams?: GetParams,
    hash?: string,
): string {
    if (categorySlug === strollersPrefix) {
        return url(strollersWikiUrl, { articleSlug }, getParams, hash);
    } else {
        return url(wikiArticleUrl, { categorySlug, articleSlug }, getParams, hash);
    }
}

export const wikiFaqContactUrl = getWikiArticleUrl(
    'faq',
    {
        201: 'prevadzkovatel-a-fakturacne-udaje',
        202: 'provozovatel-a-fakturacni-udaje',
    }[ACTIVE_SERVER_ID],
);

export const pregnancyCalculatorUrl = getWikiArticleUrl(
    WIKI_CALCULATORS_ARTICLE_SLUG,
    'tehotenska-kalkulacka',
);
