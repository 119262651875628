/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp8: Array<[string, string]> = [
    ["CASUAL_WEAR", "B\u011b\u017en\u00e9"],
    ["OCCASIONAL_WEAR", "P\u0159\u00edle\u017eitostn\u00e9"],
];
