/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp8 } from './commonProp8';
import { commonProp9 } from './commonProp9';
import { commonProp10 } from './commonProp10';
import { commonProp7 } from './commonProp7';
import { commonProp11 } from './commonProp11';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const dresses : {[key: string]: Array<[string, string]>} = {
    p_usage: commonProp8,
    p_sleeve: [
        ["NO_SLEEVE", "Bez ruk\u00e1vu"],
        ["THREE_QUARTER_SLEEVE", "3/4 ruk\u00e1v"],
        ["SHORT_SLEEVE", "Kr\u00e1tk\u00fd ruk\u00e1v"],
        ["LONG_SLEEVE", "Dlouh\u00fd ruk\u00e1v"],
    ],
    p_season: commonProp9,
    p_sex: commonProp10,
    p_size: commonProp7,
    p_length: commonProp11,
    p_brand: commonProp6,
    p_color: commonProp4,
};
