import { STROLLER_INFINITE_RECLINE } from 'mk/autogenerated/translations/index.a047eb7a6653bc7c1441c68cc9a806ac'
import {
    BooleanFeature,
    CountriesFeature,
    DimensionsFeature,
    Feature,
    InfiniteNumericListFeature,
    NumericFeature,
    NumericListFeature,
    NumericRangeFeature,
    TextFeature,
    WidthDepthFeature,
} from 'mk2/apps/strollers/features/basic';
import {
    BackSupportCanBeInLyingPositionFeature,
    BackSupportPositioningTypeFeature,
    BucketSeatFeature,
    BumperBarTypeFeature,
    CentralBrakeSystemFeature,
    CleaningTheStrollerFabricsFeature,
    ColorFeature,
    ConfigurationFeature,
    FoldingSystemFeature,
    HandleAdjustmentTypeFeature,
    HarnessTypeFeature,
    MaterialFeature,
    NumberOfKidsFeature,
    RecommendedChoiceFeature,
    RecommendedChoiceOfCategoriesFeature,
    RotatingFixationWheelsFeature,
    SiblingsTypeFeature,
    StrollerFeatureStrollerForParentByHeightFeature,
    SuspendedWheelsFeature,
    SuspensionTypeFeature,
    Type2KombiFeature,
    TypeFeature,
    WheelTypeFeature,
    YesNoFeature,
} from 'mk2/apps/strollers/features/options';
import {
    CarrycotCanBeAttached,
    CarSeatCanBeAttached,
    CarSeatHasToBeBought,
    SuppliedChangingBagFeature,
    VariantBooleanListFeature,
    VariantListWidthDepthFeature,
} from 'mk2/apps/strollers/features/other';
import { string } from 'prop-types';

interface FeaturesList {
    [K: string]: Feature;
}

export const FEATURES_LIST = {
    adjustable_handle: new BooleanFeature('adjustable_handle'),
    adjustable_suspension: new BooleanFeature('adjustable_suspension'),
    all_wheels_are_double: new BooleanFeature('all_wheels_are_double'),
    autolock: new BooleanFeature('autolock'),
    back_support_can_be_extended: new BooleanFeature('back_support_can_be_extended'),
    back_support_can_be_in_lying_position: new BackSupportCanBeInLyingPositionFeature('back_support_can_be_in_lying_position'),
    back_support_can_be_positioned: new BooleanFeature('back_support_can_be_positioned'),
    back_support_length_in_cm: new NumericRangeFeature('back_support_length_in_cm'),
    back_support_positioning_type: new BackSupportPositioningTypeFeature('back_support_positioning_type'),
    back_support_positions: new InfiniteNumericListFeature('back_support_positions_l', {
        infinityName: STROLLER_INFINITE_RECLINE,
    }),
    big_shopping_basket: new BooleanFeature('big_shopping_basket'),
    bucket_seat: new BucketSeatFeature('bucket_seat'),
    bucket_second_seat: new BooleanFeature('bucket_second_seat'),
    bumper_bar: new BooleanFeature('bumper_bar'),
    bumper_bar_can_be_bought_separately: new BooleanFeature('bumper_bar_can_be_bought_separately'),
    bumper_bar_can_be_positioned: new BooleanFeature('bumper_bar_can_be_positioned'),
    bumper_bar_can_be_removed: new BooleanFeature('bumper_bar_can_be_removed'),
    bumper_bar_equipped_with_a_removable_cover: new BooleanFeature('bumper_bar_equipped_with_a_removable_cover'),
    bumper_bar_type: new BumperBarTypeFeature('bumper_bar_type'),
    can_be_converted_to_a_double: new BooleanFeature('can_be_converted_to_a_double'),
    can_be_converted_to_a_single: new BooleanFeature('can_be_converted_to_a_single'),
    can_be_pulled_behind: new BooleanFeature('can_be_pulled_behind'),
    car_seat_can_be_attached: new CarSeatCanBeAttached('car_seat_can_be_attached'),
    car_seat_compatibility_most_brands: new BooleanFeature('car_seat_compatibility_most_brands'),
    car_seat_compatibility_roemer: new BooleanFeature('car_seat_compatibility_roemer'),
    car_seat_has_to_be_bought: new CarSeatHasToBeBought('car_seat_has_to_be_bought'),
    carrycot_can_be_attached: new CarrycotCanBeAttached('carrycot_can_be_attached'),
    carrycot_dims_in_cm: new VariantListWidthDepthFeature('carrycot_dims_in_cm'),
    carrycot_has_handle: new BooleanFeature('carrycot_has_handle'),
    carrycot_has_ventilation: new VariantBooleanListFeature('carrycot_has_ventilation'),
    carrycot_usable_in_car: new VariantBooleanListFeature('carrycot_usable_in_car'),
    carrycot_with_cradle: new BooleanFeature('carrycot_with_cradle'),
    carrycot_with_ventilation_window_on_roof: new BooleanFeature('carrycot_with_ventilation_window_on_roof'),
    carrycot_with_panoramic_window_on_side: new BooleanFeature('carrycot_with_panoramic_window_on_side'),
    central_brake_present: new BooleanFeature('central_brake_present'),
    central_brake_system: new CentralBrakeSystemFeature('central_brake_system'),
    checked_baggage: new BooleanFeature('checked_baggage'),
    cleaning_the_stroller_fabrics: new CleaningTheStrollerFabricsFeature('cleaning_the_stroller_fabrics'),
    closable_peek_a_boo_window: new BooleanFeature('closable_peek_a_boo_window'),
    cupholder_possibility: new BooleanFeature('cupholder_possibility'),
    discontinued_year: new NumericFeature('discontinued_year', {
        nice: false,
    }),
    extendable_hood: new BooleanFeature('extendable_hood'),
    foldable_carrycot: new BooleanFeature('foldable_carrycot'),
    foldable_handrail: new BooleanFeature('foldable_handrail'),
    folded_dimensions_in_cm: new DimensionsFeature('folded_dimensions_in_cm'),
    folding_system: new FoldingSystemFeature('folding_system'),
    folds_by_itself: new BooleanFeature('folds_by_itself'),
    folds_with_both_seats_attached: new BooleanFeature('folds_with_both_seats_attached'),
    folds_with_seat_outwards: new BooleanFeature('folds_with_seat_outwards'),
    folds_with_the_seat_attached: new BooleanFeature('folds_with_the_seat_attached'),
    folds_with_the_seat_in_reverse: new BooleanFeature('folds_with_the_seat_in_reverse'),
    follow_the_sun_hood: new BooleanFeature('follow_the_sun_hood'),
    front_wheels_diameter_in_cm: new NumericFeature('front_wheels_diameter_in_cm'),
    fully_fledged_second_seat: new BooleanFeature('fully_fledged_second_seat'),
    handle_height_in_cm: new NumericRangeFeature('handle_height_in_cm'),
    handle_material: new MaterialFeature('handle_material'),
    harness_padding: new BooleanFeature('harness_padding'),
    harness_type: new HarnessTypeFeature('harness_type'),
    has_carry_handle: new BooleanFeature('has_carry_handle'),
    height_adjustable_harness: new BooleanFeature('height_adjustable_harness'),
    height_adjustable_hood: new BooleanFeature('height_adjustable_hood'),
    height_adjustable_seat: new BooleanFeature('height_adjustable_seat'),
    higher_positioned_carrycot: new BooleanFeature('higher_positioned_carrycot'),
    higher_seat_position: new BooleanFeature('higher_seat_position'),
    hood_equipped_with_a_pocket: new BooleanFeature('hood_equipped_with_a_pocket'),
    hood_equipped_with_uv_protection: new BooleanFeature('hood_equipped_with_uv_protection'),
    hood_equipped_with_ventilation_panel: new BooleanFeature('hood_equipped_with_ventilation_panel'),
    hood_features_a_sun_visor: new BooleanFeature('hood_features_a_sun_visor'),
    inclinable_carrycot: new BooleanFeature('inclinable_carrycot'),
    led_lighting: new BooleanFeature('led_lighting'),
    leg_rest_can_be_extended: new BooleanFeature('leg_rest_can_be_extended'),
    leg_rest_can_be_positioned: new BooleanFeature('leg_rest_can_be_positioned'),
    leg_rest_positions: new NumericFeature('leg_rest_positions'),
    manual_brake_present: new BooleanFeature('manual_brake_present'),
    maximum_load_per_1st_child_in_kg: new NumericFeature('maximum_load_per_1st_child_in_kg'),
    maximum_load_per_2nd_child_in_kg: new NumericFeature('maximum_load_per_2nd_child_in_kg'),
    maximum_load_per_child_in_kg: new NumericFeature('maximum_load_per_child_in_kg'),
    memory_button: new BooleanFeature('memory_button'),
    model_url: new Feature('model_url'),
    motorized: new BooleanFeature('motorized'),
    number_of_double_wheels: new NumericFeature('number_of_double_wheels'),
    number_of_wheels: new NumericListFeature('number_of_wheels_l', {
        sorted: false,
    }),
    one_hand_folding: new BooleanFeature('one_hand_folding'),
    one_hand_positioning: new BooleanFeature('one_hand_positioning'),
    origin_countries: new CountriesFeature('origin_countries'),
    parent_tray_organizer: new BooleanFeature('parent_tray_organizer'),
    peek_a_boo_window_in_the_hood: new BooleanFeature('peek_a_boo_window_in_the_hood'),
    peek_a_boo_window_with_silent_closure: new BooleanFeature('peek_a_boo_window_with_silent_closure'),
    platform_material: new MaterialFeature('platform_material'),
    possibility_of_terrain_wheels: new BooleanFeature('possibility_of_terrain_wheels'),
    type_2in1_seat: new BooleanFeature('type_2in1_seat'),
    wheel_description_sk: new TextFeature('wheel_description_sk'),
    wheel_description_cz: new TextFeature('wheel_description_cz'),
    rear_wheels_diameter_in_cm: new NumericFeature('rear_wheels_diameter_in_cm'),
    recommended_age: new NumericRangeFeature('recommended_age'),
    removable_hood: new BooleanFeature('removable_hood'),
    retroreflector: new BooleanFeature('retroreflector'),
    roof_present: new BooleanFeature('roof_present'),
    back_support_can_be_in_vertical_seat_position: new BooleanFeature('back_support_can_be_in_vertical_seat_position'),
    rotating_seat: new BooleanFeature('rotating_seat'),
    rotating_second_seat: new BooleanFeature('rotating_second_seat'),
    seat_dims_in_cm: new WidthDepthFeature('seat_dims_in_cm'),
    seat_fabrics_can_be_taken_off: new BooleanFeature('seat_fabrics_can_be_taken_off'),
    seat_surface_total_length_in_cm: new NumericRangeFeature('seat_surface_total_length_in_cm'),
    seat_unit_stroller_body_equipped_with_ventilation_system: new BooleanFeature(
        'seat_unit_stroller_body_equipped_with_ventilation_system',
    ),
    second_seat_back_support_can_be_positioned: new BooleanFeature('second_seat_back_support_can_be_positioned'),
    second_seat_back_support_length_in_cm: new NumericRangeFeature('second_seat_back_support_length_in_cm'),
    second_seat_back_support_positioning_type: new BackSupportPositioningTypeFeature(
        'second_seat_back_support_positioning_type',
    ),
    second_seat_back_support_positions: new InfiniteNumericListFeature('second_seat_back_support_positions_l', {
        infinityName: STROLLER_INFINITE_RECLINE,
    }),
    second_seat_bumper_bar: new BooleanFeature('second_seat_bumper_bar'),
    second_seat_dims_in_cm: new WidthDepthFeature('second_seat_dims_in_cm'),
    second_seat_leg_rest: new BooleanFeature('second_seat_leg_rest'),
    second_seat_one_hand_positioning: new BooleanFeature('second_seat_one_hand_positioning'),
    second_seat_recommended_age: new NumericRangeFeature('second_seat_recommended_age'),
    second_seat_roof_present: new BooleanFeature('second_seat_roof_present'),
    second_seat_surface_total_length_in_cm: new NumericRangeFeature('second_seat_surface_total_length_in_cm'),
    shopping_basket: new BooleanFeature('shopping_basket'),
    shopping_basket_capacity_in_kg: new NumericFeature('shopping_basket_capacity_in_kg'),
    shopping_basket_closable: new BooleanFeature('shopping_basket_closable'),
    standing_fold: new BooleanFeature('standing_fold'),
    stroller_body_equipped_with_a_pocket: new BooleanFeature('stroller_body_equipped_with_a_pocket'),
    supplied_cape: new BooleanFeature('supplied_cape'),
    supplied_changing_bag: new SuppliedChangingBagFeature('supplied_changing_bag'),
    supplied_footmuff: new BooleanFeature('supplied_footmuff'),
    supplied_mosquito_net: new BooleanFeature('supplied_mosquito_net'),
    supplied_pad: new BooleanFeature('supplied_pad'),
    supplied_transport_bag: new BooleanFeature('supplied_transport_bag'),
    suspended_wheels: new SuspendedWheelsFeature('suspended_wheels'),
    suspension: new BooleanFeature('suspension'),
    suspension_type: new SuspensionTypeFeature('suspension_type'),
    swing_away_bumper_bar: new BooleanFeature('swing_away_bumper_bar'),
    swinging: new BooleanFeature('swinging'),
    two_car_seats_can_be_attached: new BooleanFeature('two_car_seats_can_be_attached'),
    two_wheel_mode: new BooleanFeature('two_wheel_mode'),
    type: new TypeFeature('type'),
    type_of_handle_adjustment: new HandleAdjustmentTypeFeature('type_of_handle_adjustment'),
    unfolded_dimensions_in_cm: new DimensionsFeature('unfolded_dimensions_in_cm'),
    video_url: new Feature('video_url'),
    waterproof_hood: new BooleanFeature('waterproof_hood'),
    weight_in_kg: new NumericFeature('weight_in_kg'),
    wheels_can_be_removed: new BooleanFeature('wheels_can_be_removed'),
    wrist_safety_strap: new BooleanFeature('wrist_safety_strap'),
    type_2kombi: new Type2KombiFeature('type_2kombi'),
    number_of_kids: new NumberOfKidsFeature('number_of_kids'),
    siblings_type: new SiblingsTypeFeature('siblings_type'),
    rotating_fixation_wheels: new RotatingFixationWheelsFeature('rotating_fixation_wheels'),
    stroller_for_parent_by_height: new StrollerFeatureStrollerForParentByHeightFeature('stroller_for_parent_by_height'),
    recommended_choice: new RecommendedChoiceFeature('recommended_choice'),
    recommended_choice_of_categories: new RecommendedChoiceOfCategoriesFeature('recommended_choice_of_categories'),
    very_good_folding: new BooleanFeature('very_good_folding'),

    // VARIANT ATTRIBUTES
    variant_rear_wheel_type: new WheelTypeFeature('variant_rear_wheel_type'),
    variant_front_wheel_type: new WheelTypeFeature('variant_front_wheel_type'),

    // CONFIGURABLE FEATURES
    adapters: new YesNoFeature('adapters'),
    adapters_carrycot: new YesNoFeature('adapters_carrycot'),
    base_color: new ColorFeature('base_color'),
    board: new YesNoFeature('board'),
    // bumper_bar (listed above)
    car_seat: new ConfigurationFeature('car_seat'),
    carrycot: new YesNoFeature('carrycot'),
    color: new ColorFeature('color'),
    construction: new ConfigurationFeature('construction'),
    duo_carrycot: new YesNoFeature('duo_carrycot'),
    duo_seat: new YesNoFeature('duo_seat'),
    extension_set: new YesNoFeature('extension_set'),
    footcover_color: new ColorFeature('footcover_color'),
    footmuff: new YesNoFeature('footmuff'),
    footstool: new YesNoFeature('footstool'),
    handle_color: new ColorFeature('handle_color'),
    height_adapter: new YesNoFeature('height_adapter'),
    mosquito_net: new YesNoFeature('mosquito_net'),
    organizer: new YesNoFeature('organizer'),
    raincoat: new YesNoFeature('raincoat'),
    roof: new YesNoFeature('roof'),
    sportwheels: new YesNoFeature('sportwheels'),
    stroller_baby_bag: new YesNoFeature('stroller_baby_bag'),
    stroller_bag: new YesNoFeature('stroller_bag'),
    stroller_foot_bag: new YesNoFeature('stroller_foot_bag'),
    stroller_seat: new YesNoFeature('stroller_seat'),
    sun_visor: new YesNoFeature('sun_visor'),
    twin_carrycot: new YesNoFeature('twin_carrycot'),
    wheel_color: new ColorFeature('wheel_color'),
    young_reduction: new YesNoFeature('young_reduction'),
};
