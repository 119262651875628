import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { PostVisibility } from 'mk2/schemas';

/////////////////////////////////////////////////////////////////////
// Set post visibility

interface BlogsSetPostVisibilityAction {
    readonly postId: number;
    readonly visibility: PostVisibility;
}

export interface BlogsSetPostVisibilityNormalizedResponse extends NormalizedResponse {
    readonly result: {
        post: number,
    };
}

export const BLOGS_SET_POST_VISIBILITY_TRIGGER = 'BLOGS_SET_POST_VISIBILITY_TRIGGER';
export interface BlogsSetPostVisibilityTriggerAction extends BlogsSetPostVisibilityAction {
    readonly type: typeof BLOGS_SET_POST_VISIBILITY_TRIGGER;
}

export const BLOGS_SET_POST_VISIBILITY_REQUEST = 'BLOGS_SET_POST_VISIBILITY_REQUEST';
export interface BlogsSetPostVisibilityRequestAction extends BlogsSetPostVisibilityAction, APIRequestAction {
    readonly type: typeof BLOGS_SET_POST_VISIBILITY_REQUEST;
}

export const BLOGS_SET_POST_VISIBILITY_SUCCESS = 'BLOGS_SET_POST_VISIBILITY_SUCCESS';
export interface BlogsSetPostVisibilitySuccessAction extends BlogsSetPostVisibilityAction, APISuccessAction<BlogsSetPostVisibilityNormalizedResponse> {
    readonly type: typeof BLOGS_SET_POST_VISIBILITY_SUCCESS;
}

export const BLOGS_SET_POST_VISIBILITY_FAILURE = 'BLOGS_SET_POST_VISIBILITY_FAILURE';
export interface BlogsSetPostVisibilityFailureAction extends BlogsSetPostVisibilityAction, APIFailureAction {
    readonly type: typeof BLOGS_SET_POST_VISIBILITY_FAILURE;
}

export const blogsSetPostVisibilityTrigger = (postId: number, visibility: PostVisibility): BlogsSetPostVisibilityTriggerAction => ({
    type: BLOGS_SET_POST_VISIBILITY_TRIGGER, postId, visibility,
});
export const blogsSetPostVisibilityApi = {
    request: (postId: number, visibility: PostVisibility): BlogsSetPostVisibilityRequestAction => ({
        type: BLOGS_SET_POST_VISIBILITY_REQUEST, postId, visibility,
    }),
    success: (postId: number, visibility: PostVisibility, response: any): BlogsSetPostVisibilitySuccessAction => ({
        type: BLOGS_SET_POST_VISIBILITY_SUCCESS, postId, visibility, response,
    }),
    failure: (postId: number, visibility: PostVisibility, error: any): BlogsSetPostVisibilityFailureAction => ({
        type: BLOGS_SET_POST_VISIBILITY_FAILURE, postId, visibility, error,
    }),
};

/////////////////////////////////////////////////////////////////////
// Ignore user

interface BlogsIgnoreUserAction {
    readonly username: string;
}

export interface BlogsIgnoreUserNormalizedResponse extends NormalizedResponse {
    readonly result: {};
}

export const BLOGS_IGNORE_USER_TRIGGER = 'BLOGS_IGNORE_USER_TRIGGER';
export interface BlogsIgnoreUserTriggerAction extends BlogsIgnoreUserAction {
    readonly type: typeof BLOGS_IGNORE_USER_TRIGGER;
}

export const BLOGS_IGNORE_USER_REQUEST = 'BLOGS_IGNORE_USER_REQUEST';
export interface BlogsIgnoreUserRequestAction extends BlogsIgnoreUserAction, APIRequestAction {
    readonly type: typeof BLOGS_IGNORE_USER_REQUEST;
}

export const BLOGS_IGNORE_USER_SUCCESS = 'BLOGS_IGNORE_USER_SUCCESS';
export interface BlogsIgnoreUserSuccessAction extends BlogsIgnoreUserAction, APISuccessAction<BlogsIgnoreUserNormalizedResponse> {
    readonly type: typeof BLOGS_IGNORE_USER_SUCCESS;
}

export const BLOGS_IGNORE_USER_FAILURE = 'BLOGS_IGNORE_USER_FAILURE';
export interface BlogsIgnoreUserFailureAction extends BlogsIgnoreUserAction, APIFailureAction {
    readonly type: typeof BLOGS_IGNORE_USER_FAILURE;
}

export const blogsIgnoreUserTrigger = (username: string): BlogsIgnoreUserTriggerAction => ({
    type: BLOGS_IGNORE_USER_TRIGGER, username,
});
export const blogsIgnoreUserApi = {
    request: (username: string): BlogsIgnoreUserRequestAction => ({
        type: BLOGS_IGNORE_USER_REQUEST, username,
    }),
    success: (username: string, response: any): BlogsIgnoreUserSuccessAction => ({
        type: BLOGS_IGNORE_USER_SUCCESS, username, response,
    }),
    failure: (username: string, error: any): BlogsIgnoreUserFailureAction => ({
        type: BLOGS_IGNORE_USER_FAILURE, username, error,
    }),
};

/////////////////////////////////////////////////////////////////////
// Set displaying at feed

interface BlogsSetDisplayingAtFeedAction {
    readonly postId: number;
    readonly displaying: boolean;
}

export interface BlogsSetDisplayingAtFeedNormalizedResponse extends NormalizedResponse {
    readonly result: {
        post: number,
    };
}

export const BLOGS_SET_DISPLAYING_AT_FEED_TRIGGER = 'BLOGS_SET_DISPLAYING_AT_FEED_TRIGGER';
export interface BlogsSetDisplayingAtFeedTriggerAction extends BlogsSetDisplayingAtFeedAction {
    readonly type: typeof BLOGS_SET_DISPLAYING_AT_FEED_TRIGGER;
}

export const BLOGS_SET_DISPLAYING_AT_FEED_REQUEST = 'BLOGS_SET_DISPLAYING_AT_FEED_REQUEST';
export interface BlogsSetDisplayingAtFeedRequestAction extends BlogsSetDisplayingAtFeedAction, APIRequestAction {
    readonly type: typeof BLOGS_SET_DISPLAYING_AT_FEED_REQUEST;
}

export const BLOGS_SET_DISPLAYING_AT_FEED_SUCCESS = 'BLOGS_SET_DISPLAYING_AT_FEED_SUCCESS';
export interface BlogsSetDisplayingAtFeedSuccessAction extends BlogsSetDisplayingAtFeedAction, APISuccessAction<BlogsSetDisplayingAtFeedNormalizedResponse> {
    readonly type: typeof BLOGS_SET_DISPLAYING_AT_FEED_SUCCESS;
}

export const BLOGS_SET_DISPLAYING_AT_FEED_FAILURE = 'BLOGS_SET_DISPLAYING_AT_FEED_FAILURE';
export interface BlogsSetDisplayingAtFeedFailureAction extends BlogsSetDisplayingAtFeedAction, APIFailureAction {
    readonly type: typeof BLOGS_SET_DISPLAYING_AT_FEED_FAILURE;
}

export const blogsSetDisplayingAtFeedTrigger = (postId: number, displaying: boolean): BlogsSetDisplayingAtFeedTriggerAction => ({
    type: BLOGS_SET_DISPLAYING_AT_FEED_TRIGGER, postId, displaying,
});
export const blogsSetDisplayingAtFeedApi = {
    request: (postId: number, displaying: boolean): BlogsSetDisplayingAtFeedRequestAction => ({
        type: BLOGS_SET_DISPLAYING_AT_FEED_REQUEST, postId, displaying,
    }),
    success: (postId: number, displaying: boolean, response: BlogsSetDisplayingAtFeedNormalizedResponse): BlogsSetDisplayingAtFeedSuccessAction => ({
        type: BLOGS_SET_DISPLAYING_AT_FEED_SUCCESS, postId, displaying, response,
    }),
    failure: (postId: number, displaying: boolean, error: any): BlogsSetDisplayingAtFeedFailureAction => ({
        type: BLOGS_SET_DISPLAYING_AT_FEED_FAILURE, postId, displaying, error,
    }),
};

/////////////////////////////////////////////////////////////////////
// Delete post

interface BlogsDeletePostAction {
    readonly postId: number;
}

export interface BlogsDeletePostNormalizedResponse extends NormalizedResponse {
    readonly result: {
        post: number,
    };
}

export const BLOGS_DELETE_POST_TRIGGER = 'BLOGS_DELETE_POST_TRIGGER';
export interface BlogsDeletePostTriggerAction extends BlogsDeletePostAction {
    readonly type: typeof BLOGS_DELETE_POST_TRIGGER;
    readonly redirectUrl: string;
}

export const BLOGS_DELETE_POST_REQUEST = 'BLOGS_DELETE_POST_REQUEST';
export interface BlogsDeletePostRequestAction extends BlogsDeletePostAction, APIRequestAction {
    readonly type: typeof BLOGS_DELETE_POST_REQUEST;
}

export const BLOGS_DELETE_POST_SUCCESS = 'BLOGS_DELETE_POST_SUCCESS';
export interface BlogsDeletePostSuccessAction extends BlogsDeletePostAction, APISuccessAction<BlogsDeletePostNormalizedResponse> {
    readonly type: typeof BLOGS_DELETE_POST_SUCCESS;
}

export const BLOGS_DELETE_POST_FAILURE = 'BLOGS_DELETE_POST_FAILURE';
export interface BlogsDeletePostFailureAction extends BlogsDeletePostAction, APIFailureAction {
    readonly type: typeof BLOGS_DELETE_POST_FAILURE;
}

export const blogsDeletePostTrigger =
    (postId: number, redirectUrl?: string): BlogsDeletePostTriggerAction => ({
        type: BLOGS_DELETE_POST_TRIGGER, postId, redirectUrl,
    });
export const blogsDeletePostApi = {
    request: (postId: number): BlogsDeletePostRequestAction => ({
        type: BLOGS_DELETE_POST_REQUEST, postId,
    }),
    success: (postId: number, response: BlogsDeletePostNormalizedResponse): BlogsDeletePostSuccessAction => ({
        type: BLOGS_DELETE_POST_SUCCESS, postId, response,
    }),
    failure: (postId: number, error: any): BlogsDeletePostFailureAction => ({
        type: BLOGS_DELETE_POST_FAILURE, postId, error,
    }),
};

/////////////////////////////////////////////////////////////////////
// Load user's memberships in groups

export const BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_TRIGGER = 'BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_TRIGGER';
export interface BlogsLoadUserGroupMembershipsTriggerAction {
    readonly type: typeof BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_TRIGGER;
}

export const BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_REQUEST = 'BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_REQUEST';
export interface BlogsLoadUserGroupMembershipsRequestAction extends APIRequestAction {
    readonly type: typeof BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_REQUEST;
}

export const BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_SUCCESS = 'BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_SUCCESS';
export interface BlogsLoadUserGroupMembershipsSuccessAction extends APISuccessAction {
    readonly type: typeof BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_SUCCESS;
    readonly memberships: number[];
}

export const BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_FAILURE = 'BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_FAILURE';
export interface BlogsLoadUserGroupMembershipsFailureAction extends APIFailureAction {
    readonly type: typeof BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_FAILURE;
}

export const blogsLoadUserGroupMembershipsTrigger = (): BlogsLoadUserGroupMembershipsTriggerAction => ({
    type: BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_TRIGGER,
});
export const blogsLoadUserGroupMembershipsApi = {
    request: (): BlogsLoadUserGroupMembershipsRequestAction => ({
        type: BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_REQUEST,
    }),
    success: (response, memberships: number[]): BlogsLoadUserGroupMembershipsSuccessAction => ({
        type: BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_SUCCESS, response, memberships,
    }),
    failure: (error: any): BlogsLoadUserGroupMembershipsFailureAction => ({
        type: BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_FAILURE, error,
    }),
};
