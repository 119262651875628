export interface UserAgentParsed {
    os: {
        name: string;
        version: string;
    };
    browser: {
        name: string;
        version: string;
    };
    device: {
        type: string;
    };
    flags?: {
        oldBrowserVersion: boolean;
        buggyClamp: boolean;
        buggySelectImages: boolean;
    };
}

let _supportsPassive = null;
export function supportsPassive() {
    if (_supportsPassive !== null) {
        return _supportsPassive;
    }

    // Test via a getter in the options object to see if the passive property is accessed
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: () => {
          _supportsPassive = true;
        },
      });
      window.addEventListener('test', null, opts);
    } catch (e) {
        // Ignore
    }

    return _supportsPassive;
}

// FIXME toto treba prerobit v novej appke, aby posielala cez header a nie cookie
// lebo Chrome zdiela cookies medzi webview a browserom a potom si browser mysli ze je appka
export function isAndroidApp(): boolean {
    return (
        typeof document !== 'undefined' &&
        document.cookie.replace(
            /(?:(?:^|.*;\s*)mobile_app\s*=\s*([^;]*).*$)|^.*$/,
            '$1') === 'android'
    );
}

let _supportsEmoji = null;
const EMOJIS_TO_TEST = [ '\ud83d\udc28', '\ud83d\ude42' ];
export function supportsEmoji() {
    if (_supportsEmoji !== null) {
        return _supportsEmoji;
    }

    // NodeJS supports emoji
    if (typeof window === 'undefined') {
        return _supportsEmoji = true;
    }

    // Ignore windows emoji support
    if (!window.navigator.platform || window.navigator.platform.indexOf('Win') !== -1) {
        return _supportsEmoji = false;
    }

    const node = window.document.createElement('canvas');

    const ctx = node.getContext('2d');
    if (!ctx) {
        return _supportsEmoji = false;
    }

    ctx.fillStyle = '#f00';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.font = '32px Arial';

    _supportsEmoji = true;
    EMOJIS_TO_TEST.forEach((emoji) => {
        ctx.clearRect(0, 0, node.width, node.height);
        ctx.fillText(emoji, 0, 0);
        _supportsEmoji = _supportsEmoji && ctx.getImageData(0, 0, 1, 1).data[0] !== 0;
    });

    return _supportsEmoji;
}

/*
 * V niektorych verziach chrome na androide zamrza screen pre vyber fotky na upload
 */
export function hasBuggySelectImages(ua: UserAgentParsed): boolean {
    return (
        // na androide
        ua && ua.os && ua.os.name === 'Android' &&
        // v browseri aj appke
        ua.browser && ['Chrome', 'Chrome WebView'].indexOf(ua.browser.name) >= 0 &&
        // 24.4.2018: robi chrome 66 (stable) aj chrome 67 (dev channel)
        ua.browser.version && /^6[67]\./.test(ua.browser.version)
    );
}

export function hasBuggyPhotoUploadInput(ua: UserAgentParsed): boolean {
    const isAndroidBrowser = (
        // na androide 4.4
        ua && ua.os && ua.os.name === 'Android' && ua.os.version && ua.os.version.startsWith('4.4') &&
        // v appke so starym web view
        ua.browser && ['Android Browser'].indexOf(ua.browser.name) >= 0
    );
    if (!isAndroidBrowser) {
        return false;
    }

    return isAndroidApp();
}

export function hasBuggyClamp(ua: UserAgentParsed): boolean {
    return (
        ua && (
            (ua.browser.name === 'Firefox' && ua.browser.version && /^(5[0-9]|6[0-7])\./.test(ua.browser.version))
            || (ua.browser.name === 'IE')
        )
    );
}


export function isOldBrowserVersion(ua: UserAgentParsed): boolean {
    // Vrati true, ak ide o browser ktory podporujeme, ale ma staru verziu. Ak browser nepozname,
    // tak vrati false, aby sme neblokli moderny browser z buducnosti

    // Chrome >= 63 is supported
    const oldChrome =
        ua.browser.name === 'Chrome' &&
        ua.browser.version && /^([1-5][0-9]|6[0-2])(\.|$)/.test(ua.browser.version);
    // Firefox >= 60 is supported
    const oldFirefox =
        ua.browser.name === 'Firefox' &&
        ua.browser.version && /^([1-9]|[1-5][0-9])(\.|$)/.test(ua.browser.version);
    // Samsung >= 9.2 is supported
    const oldSamsung =
        ua.browser.name === 'Samsung Browser' &&
        ua.browser.version && /^([1-8]|9$|9\.1)(\.|$)/.test(ua.browser.version);
    // Android Browser >= 63 is supported
    const oldAndroidBrowser =
        ua.browser.name === 'Android Browser' &&
        ua.browser.version && /^([1-5][0-9]|6[0-2])(\.|$)/.test(ua.browser.version);
    // Mobile Safari >= 11.0 is supported
    const oldMobileSafari =
        ua.browser.name === 'Mobile Safari' &&
        ua.browser.version && /^([1-9]|10)(\.|$)/.test(ua.browser.version);
    // Opera >= 50 is supported
    const oldOpera =
        ua.browser.name === 'Opera' &&
        ua.browser.version && /^([1-9]|[1-4][0-9])(\.|$)/.test(ua.browser.version);


    return oldChrome || oldFirefox || oldSamsung || oldAndroidBrowser || oldMobileSafari || oldOpera;
}