/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const video_and_photography : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PHOTOGRAPHY_SERVICE", "Fotografie"],
        ["VIDEO_SERVICE", "Video"],
        ["GRAPHICS_SERVICE", "Grafika"],
        ["WEB_SERVICE", "Web"],
    ],
};
