import { ProfileFormData } from 'mk2/apps/settings/components/ProfileForm';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { UserEntity } from 'mk2/schemas';

/////////////////////////////////////////////////////////////////////
// Profile Load

export const PROFILE_FETCH_TRIGGER = 'PROFILE_FETCH_TRIGGER';
export interface ProfileFetchTriggerAction {
    readonly type: typeof PROFILE_FETCH_TRIGGER;
    readonly username: string;
}
export const PROFILE_FETCH_REQUEST = 'PROFILE_FETCH_REQUEST';
export interface ProfileFetchRequestAction extends APIRequestAction {
    readonly type: typeof PROFILE_FETCH_REQUEST;
    readonly username: string;
}
export interface ProfileFetchNormalizedResponse  extends NormalizedResponse {
    readonly result: { profile: number };
}
export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS';
export interface ProfileFetchSuccessAction extends APISuccessAction<ProfileFetchNormalizedResponse> {
    readonly type: typeof PROFILE_FETCH_SUCCESS;
    readonly username: string;
}
export const PROFILE_FETCH_FAILURE = 'PROFILE_FETCH_FAILURE';
export interface ProfileFetchFailureAction extends APIFailureAction {
    readonly type: typeof PROFILE_FETCH_FAILURE;
    readonly username: string;
}

export const profileFetchTrigger = (username: string): ProfileFetchTriggerAction => ({
    type: PROFILE_FETCH_TRIGGER, username,
});
export const profileFetchApi = {
    request: (username: string): ProfileFetchRequestAction => ({
        type: PROFILE_FETCH_REQUEST,
        username,
    }),
    success: (username: string, response: ProfileFetchNormalizedResponse): ProfileFetchSuccessAction => ({
        type: PROFILE_FETCH_SUCCESS, response,
        username,
    }),
    failure: (username: string, error: any): ProfileFetchFailureAction => ({
        type: PROFILE_FETCH_FAILURE, error,
        username,
    }),
};

/////////////////////////////////////////////////////////////////////
// Profile Save

export const PROFILE_SAVE_TRIGGER = 'PROFILE_SAVE_TRIGGER';
export interface ProfileSaveTriggerAction {
    readonly type: typeof PROFILE_SAVE_TRIGGER;
    readonly username: string;
    readonly profileData: ProfileFormData;
}
export const PROFILE_SAVE_REQUEST = 'PROFILE_SAVE_REQUEST';
export interface ProfileSaveRequestAction extends APIRequestAction {
    readonly type: typeof PROFILE_SAVE_REQUEST;
    readonly username: string;
}
export interface ProfileSaveNormalizedResponse extends NormalizedResponse {
    readonly result: { profile: number };
}
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS';
export interface ProfileSaveSuccessAction extends APISuccessAction<ProfileSaveNormalizedResponse> {
    readonly type: typeof PROFILE_SAVE_SUCCESS;
    readonly username: string;
    readonly requestUser: UserEntity;
}
export const PROFILE_SAVE_FAILURE = 'PROFILE_SAVE_FAILURE';
export interface ProfileSaveFailureAction extends APIFailureAction {
    readonly type: typeof PROFILE_SAVE_FAILURE;
    readonly username: string;
}

export const profileSaveTrigger = (username: string, profileData: ProfileFormData): ProfileSaveTriggerAction => ({
    type: PROFILE_SAVE_TRIGGER, username, profileData,
});
export const profileSaveApi = {
    request: (username: string): ProfileSaveRequestAction => ({
        type: PROFILE_SAVE_REQUEST, username,
    }),
    success: (username: string, requestUser: UserEntity, response: ProfileSaveNormalizedResponse): ProfileSaveSuccessAction => ({
        type: PROFILE_SAVE_SUCCESS, username, requestUser, response,
    }),
    failure: (username: string, error: any): ProfileSaveFailureAction => ({
        type: PROFILE_SAVE_FAILURE, username, error,
    }),
};
