/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const cycling_accessories : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("4RACE"),
        d("Adidas"),
        d("ALPINA"),
        d("alpina sports"),
        d("alum online"),
        d("Arcore"),
        d("Ate"),
        d("axa"),
        d("B-SOUL"),
        d("Banwood"),
        d("BBB"),
        d("bell"),
        d("BFORCE"),
        d("blackburn"),
        d("bliz"),
        d("blizzard"),
        d("Bobike"),
        d("boll\u00e9"),
        d("Bosch"),
        d("briko"),
        d("BTWIN"),
        d("CRAFT"),
        d("cyckliq"),
        d("c\u00e9b\u00e9"),
        d("Decathlon"),
        d("disney"),
        d("ENERO"),
        d("etape"),
        d("extol premium"),
        d("fabric"),
        d("fenix"),
        d("Ferdus"),
        d("finmark"),
        d("Firstbike"),
        d("force"),
        d("fox"),
        d("fox racing"),
        d("giro"),
        d("granite"),
        d("hamax"),
        d("haven"),
        d("head"),
        d("homit"),
        d("hoteche"),
        d("jumbo"),
        d("Junso"),
        d("jus one"),
        d("just one"),
        d("k2"),
        d("KELLYS"),
        d("klimatex"),
        d("laceto"),
        d("lampa"),
        d("lezyne"),
        d("LONGUS"),
        d("M-WAVE"),
        d("master"),
        d("mavic"),
        d("max"),
        d("melon"),
        d("MERCO"),
        d("Met"),
        d("Micro"),
        d("minibrilla"),
        d("mondo"),
        d("movino"),
        d("NAKAMURA"),
        d("neon"),
        d("Nike"),
        d("oakley"),
        d("OLPRAN"),
        d("One"),
        d("orava"),
        d("OXELO"),
        d("poc"),
        d("POLEDNIK"),
        d("profilite"),
        d("PUKY"),
        d("R2"),
        d("rabus"),
        d("ratikon"),
        d("reaper"),
        d("ROCKBROS"),
        d("Rudy Project"),
        d("Salice"),
        d("Schwalbe"),
        d("SCOTT"),
        d("sena"),
        d("Shimano"),
        d("sigma"),
        d("sks"),
        d("slime"),
        d("smart"),
        d("smith"),
        d("Sportisimo"),
        d("stiga"),
        d("STRIDER"),
        d("St\u00f6rrvik"),
        d("surreti"),
        d("syncros"),
        d("Tchibo"),
        d("tempish"),
        d("topeak"),
        d("tracon electric"),
        d("TRULY"),
        d("tst"),
        d("tufo"),
        d("twn"),
        d("Uvex"),
        d("varnet"),
        d("Ventura"),
        d("Volare"),
        d("warner bros"),
        d("Wiley X"),
        d("wista"),
        d("z\u00e9fal"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
