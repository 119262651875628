import { FormPageType } from 'mk2/constants/enums';
import { PhotosUploadConfig } from 'mk2/containers/PhotosUpload/PhotosUpload';
import { ToolbarAction } from 'mk2/containers/Toolbar/Toolbar.enums';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { Action } from 'redux';

export const TOOLBAR_TOGGLE = 'TOOLBAR_TOGGLE';
export interface ToolbarToggleAction extends Action {
    readonly type: typeof TOOLBAR_TOGGLE;
    readonly formName: string;
    readonly opened: ToolbarAction;
}
export const toolbarOpenEmojis = (formName: string): ToolbarToggleAction => ({
    type: TOOLBAR_TOGGLE, formName, opened: ToolbarAction.EMOJIS,
});
export const toolbarOpenPhotos = (formName: string): ToolbarToggleAction => ({
    type: TOOLBAR_TOGGLE, formName, opened: ToolbarAction.PHOTOS,
});
export const toolbarOpenStickers = (formName: string): ToolbarToggleAction => ({
    type: TOOLBAR_TOGGLE, formName, opened: ToolbarAction.STICKERS,
});
export const toolbarCloseAll = (formName: string): ToolbarToggleAction => ({
    type: TOOLBAR_TOGGLE, formName, opened: ToolbarAction.NONE,
});

// Toolbar Prepare Photos Upload Trigger
export const TOOLBAR_PREPARE_PHOTOS_UPLOAD_TRIGGER = 'TOOLBAR_PREPARE_PHOTOS_UPLOAD_TRIGGER';
export interface ToolbarPreparePhotosUploadTriggerAction {
    readonly type: typeof TOOLBAR_PREPARE_PHOTOS_UPLOAD_TRIGGER;
    readonly formName: string;
    readonly config: PhotosUploadConfig;
    readonly files: File[];
    readonly processPhotoUploadOnFormPage: FormPageType;

    // counselling reply - unauthenticated upload (e.g. accessKey='counselling_reply:slug:pE96mf3AUfB4mLJv')
    readonly accessKey?: string;
}
export const TOOLBAR_PREPARE_PHOTOS_UPLOAD_REQUEST = 'TOOLBAR_PREPARE_PHOTOS_UPLOAD_REQUEST';
export interface ToolbarPreparePhotosUploadRequestAction extends APIRequestAction {
    readonly type: typeof TOOLBAR_PREPARE_PHOTOS_UPLOAD_REQUEST;
    readonly formName: string;
    readonly config: PhotosUploadConfig;
    readonly files: File[];
}
export interface ToolbarPreparePhotosUploadNormalizedResponse extends NormalizedResponse {
    readonly result: number;
}
export const TOOLBAR_PREPARE_PHOTOS_UPLOAD_SUCCESS = 'TOOLBAR_PREPARE_PHOTOS_UPLOAD_SUCCESS';
export interface ToolbarPreparePhotosUploadSuccessAction extends APISuccessAction<ToolbarPreparePhotosUploadNormalizedResponse> {
    readonly type: typeof TOOLBAR_PREPARE_PHOTOS_UPLOAD_SUCCESS;
    readonly formName: string;
    readonly config: PhotosUploadConfig;
    readonly files: File[];
    readonly response: ToolbarPreparePhotosUploadNormalizedResponse;
}
export const TOOLBAR_PREPARE_PHOTOS_UPLOAD_FAILURE = 'TOOLBAR_PREPARE_PHOTOS_UPLOAD_FAILURE';
export interface ToolbarPreparePhotosUploadFailureAction extends APIFailureAction {
    readonly type: typeof TOOLBAR_PREPARE_PHOTOS_UPLOAD_FAILURE;
    readonly formName: string;
    readonly config: PhotosUploadConfig;
    readonly files: File[];
}
export const toolbarPreparePhotosUploadTrigger =
    (formName, config: PhotosUploadConfig, files: File[], processPhotoUploadOnFormPage: FormPageType, accessKey?: string): ToolbarPreparePhotosUploadTriggerAction => ({
        type: TOOLBAR_PREPARE_PHOTOS_UPLOAD_TRIGGER, formName, config, files, processPhotoUploadOnFormPage, accessKey,
    });
export const preparePhotosUploadApi = {
    request: (formName, config: PhotosUploadConfig, files: File[]): ToolbarPreparePhotosUploadRequestAction => ({
        type: TOOLBAR_PREPARE_PHOTOS_UPLOAD_REQUEST, formName, config, files,
    }),
    success: (formName, config: PhotosUploadConfig, files: File[], response: ToolbarPreparePhotosUploadNormalizedResponse): ToolbarPreparePhotosUploadSuccessAction => ({
        type: TOOLBAR_PREPARE_PHOTOS_UPLOAD_SUCCESS, formName, config, files, response,
    }),
    failure: (formName, config: PhotosUploadConfig, files: File[], error: any): ToolbarPreparePhotosUploadFailureAction => ({
        type: TOOLBAR_PREPARE_PHOTOS_UPLOAD_FAILURE, formName, config, files, error,
    }),
};
