import { remove as diacriticsRemove } from 'diacritics';

export function slugify(s: string) {
    if (!s) {
        return '';
    }

    return diacriticsRemove(s.trim().toLowerCase())
        .replace(/[,/.´]/g, ' ') // Replace spaces
        .replace(/[^\w\s-]/g, '') // Replace non characters
        .replace(/[-\s]+/g, '-') // Shrink multiple -
        .replace(/^-+/, '') // Trim from start
        .replace(/-+$/, ''); // Trim from end
}
