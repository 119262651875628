import { isMqMobile } from 'mk/common/responsive';
import { deviceChanged } from 'mk2/actions';
import {
    getRequestDeviceMobile,
} from 'mk2/reducers';
import { eventChannel } from 'redux-saga';
import { all, call, fork, put, select, take } from 'redux-saga/effects';

function watchScreen() {
    return eventChannel((emitter) => {
        window.addEventListener('orientationchange', emitter);
        window.addEventListener('resize', emitter);

        return () => {
            window.removeEventListener('resize', emitter);
            window.removeEventListener('orientationchange', emitter);
        };
    });
}

function* deviceChanges() {
    // Register events
    if (typeof window === 'undefined') {
        // ignore
        return;
    }

    const channel = yield call(watchScreen);

    while (true) {
        yield take(channel);

        const mobile = isMqMobile();

        const oldMobile = yield select(getRequestDeviceMobile);

        if (oldMobile !== mobile) {
            yield put(deviceChanged(mobile));
            // Set cookie, so that we can use it during server side rendering
            document.cookie = `ssr_device=${mobile ? '10' : '01'}; path=/`;
        }
    }
}

export default function* root() {
    yield all([
        fork(deviceChanges),
    ]);
}
