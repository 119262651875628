/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const football_balls : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("Adidas"),
        d("Puma"),
        d("Erima"),
        d("Nike"),
        d("Select"),
        d("Gala"),
        d("QUICK Sport"),
        d("Cappa"),
        d("Wiky"),
        d("Kelme"),
        d("Mondo"),
        d("Stanley Rogers"),
        d("New Balance"),
        d("FOREVER COLLECTIBLES"),
        d("Lamps"),
        d("Hisense"),
        d("Wilson"),
        d("Stiga"),
        d("Molten"),
        d("Mikasa"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
