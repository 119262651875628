/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp12 } from './commonProp12';
import { commonProp4 } from './commonProp4';

export const fashion_accessories_jewelry : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["RINGS", "Prst\u00fdnky"],
        ["EARRINGS", "N\u00e1u\u0161nice"],
        ["PIERCING", "Piercing"],
        ["BRACELETS", "N\u00e1ramek"],
        ["NECKLACES", "N\u00e1hrdeln\u00edk"],
        ["PENDANT", "P\u0159\u00edv\u011bsek"],
        ["BROOCHES", "Bro\u017e"],
        ["JEWELRY_SET", "Kompletn\u00ed sada"],
        ["JEWEL_CASE", "\u0160perkovnice"],
    ],
    p_sex: commonProp5,
    p_brand: commonProp12,
    p_color: commonProp4,
};
