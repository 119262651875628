import { History } from 'history';
import { AppState } from 'mk2/reducers';
import * as xhr from 'mk2/services/browserXhr';
import configureStore, { Store } from 'mk2/store/configureStore';
import { SagaIterator } from 'redux-saga';

// TODO @types: Add typing for rootSagas
export function initStore(history: History, rootSagas: (() => SagaIterator) | null, initialState: Partial<AppState> = {}): Store<AppState> {

    const store: Store<AppState> = configureStore<AppState>(history, xhr, initialState);

    if (rootSagas) {
        store.injectSaga('root', rootSagas);
    }

    return store;
}
