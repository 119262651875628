/**
 * Re-export xhr methods with browser auth data
 */

// TOOD: Use something browser fetch (or polyfill)

import * as xhr from 'mk/xhr';
import { makeClientApiAuth, refreshApiAuthCsrfToken } from 'mk/xhr/apiAuth';

export let apiAuth = makeClientApiAuth();

export const get = (url: string, data?: any, repeatable?: number) => xhr.get(apiAuth, url, data, repeatable);
export const post = (url: string, data?: any, repeatable?: number) => xhr.post(apiAuth, url, data, repeatable);
export const put = (url: string, data?: any, repeatable?: number) => xhr.put(apiAuth, url, data, repeatable);
export const refreshApiAuth = () => { refreshApiAuthCsrfToken(); apiAuth = makeClientApiAuth(); };
