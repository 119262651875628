import { ACTIVE_SERVER_ID } from 'mk/settings';

const FAQ_INDEX_ARTICLE_SLUG = `:articleSlug(faq|faq-index-[a-z-\\d]+)`;

export const faqUrl = '/faq/';
export const faqAskQuestionUrl = '/faq/form/';
export const faqIndexUrl = `/faq/${FAQ_INDEX_ARTICLE_SLUG}/`;
export const faqRootUrl = '/faq/faq';
export const faqOldHelpUrl = '/help/';

export const faqBuyerReportUrl = {
    201: '/faq/mam-podozrenie-na-podvod-v-bazari/',
    202: '/faq/mam-podezreni-na-podvod-v-bazaru/',
}[ACTIVE_SERVER_ID];

export const faqVerifiedReviewUrl = {
    201: '/faq/ktore-recenzie-overujeme-ako-a-preco-ich-overujeme/',
    202: '/faq/ktere-recenze-overujeme-jak-a-proc-je-overujeme/',
}[ACTIVE_SERVER_ID];

export const faqOldBrowserUrl = {
    201: '/faq/ako-aktualizovat-internetovy-prehliadac/',
    202: '/faq/jak-aktualizovat-internetovy-prohlizec/',
}[ACTIVE_SERVER_ID];

export const faqPlanPlusProUrl = {
    201: '/faq/faq/#h-platene-baliky-plus-pro',
    202: '/faq/faq/#h-placene-baliky-plus-pro',
}[ACTIVE_SERVER_ID];

export const faqXmlImportUrl = {
    201: '/faq/faq/#h-xml-import-inzeratov',
    202: '/faq/faq/#h-xml-import-inzeratu',
}[ACTIVE_SERVER_ID];

export const faqBazaarSellingTips = {
    201: '/faq/3-tipy-ako-zlepsit-inzerat-na-kocik',
    202: '/faq/3-tipy-jak-zlepsit-inzerat-na-kocarek',
}[ACTIVE_SERVER_ID];
