/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp16 } from './commonProp16';

export const multimedia_video : {[key: string]: Array<[string, string]>} = {
    p_data_carrier: [
        d("DVD"),
        ["VHS", "Videokazeta"],
        ["BLU_RAY", "Blu-ray"],
        ["OTHER_DATA_CARRIER", "Jin\u00fd"],
    ],
    p_for_whom: commonProp16,
};
