/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

export const commonProp16: Array<[string, string]> = [
    ["FOR_KIDS", "Pro d\u011bti"],
    ["FOR_ADULTS", "Pro dosp\u011bl\u00e9"],
];
