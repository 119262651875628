import { PrebidBidder } from 'mk/ox/hbTypes';

export const adform: {[name: string]: PrebidBidder} = {
    rectangleSK: {
        bidder: 'adform_sk',
        params: {
            mid: '292756',
        },
    },
    rectangleCZ: {
        bidder: 'adform_sk',
        params: {
            mid: '257169',
        },
    },
    halfpageSK: {
        bidder: 'adform_sk',
        params: {
            mid: '310187',
        },
    },
    halfpageCZ: {
        bidder: 'adform_sk',
        params: {
            mid: '310186',
        },
    },
    billboardSK: {
        bidder: 'adform_sk',
        params: {
            mid: '310178',
        },
    },
    billboardCZ: {
        bidder: 'adform_sk',
        params: {
            mid: '310182',
        },
    },
    brandingDesktopSK: {
        bidder: 'adform_sk',
        params: {
            mid: '354654',
        },
    },
    brandingDesktopCZ: {
        bidder: 'adform_sk',
        params: {
            mid: '370360',
        },
    },
    brandingMobileSK: {
        bidder: 'adform_sk',
        params: {
            mid: '370408',
        },
    },
    brandingMobileCZ: {
        bidder: 'adform_sk',
        params: {
            mid: '370407',
        },
    },
};
