/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const tennis_squash_rackets : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("Acra"),
        d("Artengo"),
        d("Artis"),
        d("Babolat"),
        d("Brother"),
        d("Dunlop"),
        d("Fischer"),
        d("Head"),
        d("Merco"),
        d("Oliver"),
        d("Opfeel"),
        d("Prince"),
        d("Pro Kennex"),
        d("ProKennex"),
        d("Salming"),
        d("Sedco"),
        d("Slazenger"),
        d("Spartan"),
        d("Tecnifibre"),
        d("TecnoPro"),
        d("Teloon"),
        d("Tregare"),
        d("Victor"),
        d("Volkl"),
        d("Wilson"),
        d("Wish"),
        d("Worker"),
        d("Yonex"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
