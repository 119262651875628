import blogPostsReducer, { BlogPostsState } from 'mk2/apps/blogs/components/BlogPost/BlogPost.reducers';
import articlesReducer, { ArticlesState } from 'mk2/apps/blogs/containers/Article/Article.reducers';
import { ArticleCreateState } from 'mk2/apps/blogs/containers/ArticleCreate/ArticleCreate.reducers';
import { ArticleDraftsState } from 'mk2/apps/blogs/containers/ArticleDrafts/ArticleDrafts.reducers';
import { ArticleEditsState } from 'mk2/apps/blogs/containers/ArticleEdit/ArticleEdit.reducers';
import { BlogsAlbumCreatePagesState } from 'mk2/apps/blogs/containers/BlogsAlbumCreate/BlogsAlbumCreate.reducers';
import { BlogsAlbumDetailPagesState } from 'mk2/apps/blogs/containers/BlogsAlbumDetail/BlogsAlbumDetail.reducers';
import { BlogsAlbumEditPagesState } from 'mk2/apps/blogs/containers/BlogsAlbumEdit/BlogsAlbumEdit.reducers';
import { BlogsAlbumEditPhotosPageState } from 'mk2/apps/blogs/containers/BlogsAlbumEditPhotos/BlogsAlbumEditPhotos.reducers';
import { BlogsAlbumReorderPagesState } from 'mk2/apps/blogs/containers/BlogsAlbumReorder/BlogsAlbumReorder.reducers';
import { BlogsEntityLikersState } from 'mk2/apps/blogs/containers/BlogsEntityLikers/BlogsEntityLikers.reducers';
import { BlogsFeedState } from 'mk2/apps/blogs/containers/BlogsFeed/BlogsFeed.reducers';
import { BlogsFriendsFeedState } from 'mk2/apps/blogs/containers/BlogsFriendsFeed/BlogsFriendsFeed.reducers';
import { BlogsImageDetailPagesState } from 'mk2/apps/blogs/containers/BlogsImageDetail/BlogsImageDetail.reducers';
import { BlogsPopularFeedState } from 'mk2/apps/blogs/containers/BlogsPopularFeed/BlogsPopularFeed.reducers';
import userGroupsReducer, { UserGroupsState } from 'mk2/apps/blogs/containers/BlogsShared/BlogsShared.reducers';
import { BlogsShortMessageCreatePagesState } from 'mk2/apps/blogs/containers/BlogsShortMessageCreate/BlogsShortMessageCreate.reducers';
import { BlogsShortMessageDetailPagesState } from 'mk2/apps/blogs/containers/BlogsShortMessageDetail/BlogsShortMessageDetail.reducers';
import { BlogsShortMessageEditPagesState } from 'mk2/apps/blogs/containers/BlogsShortMessageEdit/BlogsShortMessageEdit.reducers';
import { BlogPostSharesState } from 'mk2/apps/blogs/containers/BlogPostShare/BlogPostShare.reducers';
import { postsCommentsReducer, PostsCommentsState } from 'mk2/apps/blogs/containers/Comments/Comments.reducers';
import { CommentEditsState } from 'mk2/apps/blogs/containers/CommentEdit/CommentEdit.reducers';
import { LikeInfoPopupsState } from 'mk2/apps/blogs/containers/LikeInfoPopup/LikeInfoPopup.reducers';
import { LikeSectionsState } from 'mk2/apps/blogs/containers/LikeSection/LikeSection.reducers';
import { ProfileDetailsState } from 'mk2/apps/blogs/containers/ProfileDetail/ProfileDetail.reducers';
import { State as ProfileProductsState } from 'mk2/apps/blogs/containers/ProfileProducts/ProfileProducts.reducers';
import { VendorEditPagesState } from 'mk2/apps/blogs/containers/VendorEdit/VendorEdit.reducers';

export interface BlogsSharedState {
    comments: PostsCommentsState;
    blogArticles: ArticlesState;
    blogPosts: BlogPostsState;
    likeInfoPopups: LikeInfoPopupsState;
    likeSections: LikeSectionsState;
    userGroups: UserGroupsState;
}

const blogsSharedReducer = {
    comments: postsCommentsReducer,
    blogArticles: articlesReducer,
    blogPosts: blogPostsReducer,
    userGroups: userGroupsReducer,
};

export interface BlogsAppState {
    shared: BlogsSharedState;
    profileDetails: ProfileDetailsState;
    profileProducts: ProfileProductsState;
    blogsFeed: BlogsFeedState;
    blogsFriendsFeed: BlogsFriendsFeedState;
    blogsPopularFeed: BlogsPopularFeedState;
    blogsArticles: ArticlesState;
    blogsArticleEdits: ArticleEditsState;
    blogsArticleCreates: ArticleCreateState;
    blogsArticleDrafts: ArticleDraftsState;
    blogsEntityLikers: BlogsEntityLikersState;
    blogsPostShare: BlogPostSharesState;
    blogsShortMessageDetails: BlogsShortMessageDetailPagesState;
    blogsShortMessageCreates: BlogsShortMessageCreatePagesState;
    blogsShortMessageEdits: BlogsShortMessageEditPagesState;
    blogsAlbumDetails: BlogsAlbumDetailPagesState;
    blogsAlbumReorders: BlogsAlbumReorderPagesState;
    blogsAlbumCreates: BlogsAlbumCreatePagesState;
    blogsAlbumEdits: BlogsAlbumEditPagesState;
    blogsAlbumEditPhotosPages: BlogsAlbumEditPhotosPageState;
    commentEdits: CommentEditsState;
    blogsImageDetails: BlogsImageDetailPagesState;
    blogsVendorEdits: VendorEditPagesState;
}

export const blogsReducer = {
    shared: blogsSharedReducer,
};
