import isEmpty from 'lodash-es/isEmpty';
import memoize from 'lodash-es/memoize';
import assetUrl from 'mk/common/assetUrl';
import { ACTIVE_SERVER_ID, BASE_URL } from 'mk/settings';
import * as path2regex from 'path-to-regexp';
import { stringify } from 'query-string';

export const ID_RE = '[0-9]+';
export const USERNAME_RE = '[0-9a-z_\\.\\-]+';
export const SLUG_RE = '[a-z-\\d]+';
export const HASHTAG_RE = '[a-z-\\d\\._]+';

// uz neumoznujeme aby boli v slugu underscore (_) a bodka (.), ale niektore stare linky ich este mozu obsahovat
export const SLUG_LEGACY_RE = '[a-z-\\d\\._]+';

export const USERNAME = `:username(${USERNAME_RE})`;

// cache compiled patterns
const cachedPath2RegexCompile = memoize(path2regex.compile);

interface UrlPathParams {
    [name: string]: string | number;
}

export interface GetParams {
    [key: string]: string | number | boolean | string[] | number[] | boolean[];
}

export function url(
    // TODO merge with mk/urls/authgenhelper/url()
    urlPattern: string,
    keys: UrlPathParams,        // parametre do url path  (do /.../)
    getParams?: GetParams,   // paramterge do query stringu (do ?...)
    hash?: string,
) {
    const pathRe = cachedPath2RegexCompile(urlPattern);
    let surl = pathRe(keys);

    if (getParams && Object.keys(getParams).length > 0) {
        const serialized = serializeGetParams(getParams);
        if (!!serialized) {
            surl = surl + '?' + serializeGetParams(getParams);
        }
    }

    if (hash) {
        surl = surl + '#' + hash;
    }

    return surl;
}

/**
 * pre krajsie url-ka, aby sme nedavali do get parametrov ?page=1
 */
export function removePage1(getParams: GetParams) {
    if (getParams && (getParams.page === 1 || getParams.page === '1')) {
        const clonedParams = { ...getParams };
        delete clonedParams.page;
        return clonedParams;
    }

    return getParams;
}

/**
 * pre krajsie url-ka, aby sme nedavali do get parametrov ?key=
 */
export function removeEmpty(getParams: GetParams, options?: {removeZero?: boolean; removeEmptyArray?: boolean}): GetParams {
    if (getParams) {
        const clonedParams: GetParams = {};
        Object.keys(getParams).forEach((key) => {
            const val = getParams[key];
            if (val === null || val === undefined || val === '') {
                return;
            }
            if (options?.removeZero && (val === 0 || val === '0')) {
                return;
            }
            if (options?.removeEmptyArray && Array.isArray(val) && !(val as any[]).length) {
                return;
            }

            clonedParams[key] = val;
        });

        return clonedParams;
    }

    return getParams;
}

/**
 * skonvertuje get parametre spat do stringu
 * {q: 'kocik', title: true}  --->  q=kocik&title=true
 */
export function serializeGetParams(getParams: GetParams): string {
    if (getParams && Object.keys(getParams).length > 0) {
        const qs = stringify(getParams, { skipNull: true });
        return !isEmpty(qs) ? qs : undefined;
    } else {
        return undefined;
    }
}


/**
 * naparsuje string tvaru '10,11,12,13' do pola integerov [10,11,12,13]
 */
export function parseIntList(s: string): number[] {
    return s ? s.split(',').map((v) => parseInt(v.trim(), 10)).filter((n) => !isNaN(n)) : [];
}

/**
 * naparsuje string tvaru 'true' do boolean
 */
export function parseBoolean(s: string): boolean {
    return s && !!s.match(/^\s*(?:1|true|yes)\s*$/i);
}

/**
 * skonvertuje pole integerov [10,11,12,13] do stringu oddelenych ciarkou
 */
export function serializeIntList(ns: number[]): string {
    return ns && ns.length > 0 ? ns.map((v) => v.toString(10)).join(',') : '';
}

const domainNameForServer = (serverId: number) => ({
    201: 'modrykonik.sk',
    202: 'modrykonik.cz',
}[serverId]);

const urlForServer = (serverId: number): string =>
    `https://www.${domainNameForServer(serverId)}`;

export const ACTIVE_DOMAIN_NAME: string = BASE_URL.split('.').slice(-2).join('.');            // modrykonik.cz
export const ACTIVE_WWW_HOST_NAME: string = BASE_URL.replace(/https:\/\//, ''); // www.modrykonik.cz

/**
 * Pridata https://www.modrykonik.(sk|cz) do url
 */
export function makeAbsoluteUrl(url, onServer?: 201 | 202) { // tslint:disable-line:no-shadowed-variable
    if (url.startsWith('http')) {
        return url;
    }

    return onServer
        ? urlForServer(onServer) + url
        : BASE_URL + url;
}

export function fbMorykonikProfileUrl(): string {
    return {
        201: 'https://www.facebook.com/Modrykonik.sk',
        202: 'https://www.facebook.com/Modrykonik.cz',
    }[ACTIVE_SERVER_ID];
}

export function modrykonikLogoUrl(): string {
    return assetUrl + `img/logos/ver-4/logo-${ACTIVE_SERVER_ID}@2x.png`;
}

/**
 * get url domain
 */
export function getDomain(fullUrl: string) {
    return fullUrl.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
}
