import { ID_RE } from 'mk2/helpers/urls';

const ORDER_ID = `:orderId(${ID_RE})`;
const TESTING_ID = `:testingId(${ID_RE})`;
const ORDER_ITEM_ID = `:orderItemId(${ID_RE})`;
const ATTACHMENT_ID = `:attachmentId(${ID_RE})`;
const MAILING_CAMPAIGN_ID = `:mailingCampaignId(${ID_RE})`;
const BRAND_DAYS_CAMPAIGN_ID = `:brandDaysCampaignId(${ID_RE})`;
const AGENCY_ID = `:agencyId(${ID_RE})`;

const ADM_ORDER_ID = `:admOrderId(${ID_RE})`;
const ADM_LINEITEM_ID = `:admLineItemId(${ID_RE})`;

export const boUrl = '/bo/';
export const boBankPaymentsUrl = '/bo/bank-payments/';
export const boPaymentOrdersUrl = '/bo/bank-payment-orders/';
export const boCommentsSnapUrl = '/bo/orders/comments-snap/';

export const boOrdersUrl = '/bo/orders/';
export const boOrdersAttachmentUrl = `/bo/orders-dj/attachment/${ATTACHMENT_ID}/`;
export const boOrdersChartUrl = '/bo/orders/chart/';
export const boOrdersInvoicingUrl = `/bo/orders/invoicing/`;
export const boOrderCreateUrl = '/bo/orders/create/';
export const boOrdersDetailUrl = `/bo/orders/${ORDER_ID}/`;
export const boOrdersEditUrl = `/bo/orders/${ORDER_ID}/edit/`;
export const boOrdersHistoryUrl = `/bo/orders/${ORDER_ID}/history/`;
export const boOrdersAgencyBonusesUrl = '/bo/orders/agency-bonuses/';
export const boOrdersProductionUrl = `/bo/orders/${ORDER_ID}/production/${ORDER_ITEM_ID}/`;
export const boOrdersReportTestingUrl = `/bo/orders/report/${ORDER_ID}/testing/${ORDER_ITEM_ID}/`;
export const boOrdersReportAmbassadorUrl = `/bo/orders/report/${ORDER_ID}/ambassador/${ORDER_ITEM_ID}/:yearMonth([0-9]+)/`;
export const boOrdersReportAskAnExpertUrl = `/bo/orders/report/${ORDER_ID}/ask-an-expert/${ORDER_ITEM_ID}/:yearMonth([0-9]+)/`;
export const boOrdersReportUrl = `/bo/orders/report/${ORDER_ID}/`;
export const boOrdersSignedUrl = '/bo/orders/signed/';

export const boAgenciesUrl = '/bo/agencies/';
export const boAgencyUrl = `/bo/agency/${AGENCY_ID}/`;

export const boTestingsUrl = '/bo/testings/';
export const boTestingEditUrl = `/bo/testings/edit/${TESTING_ID}/`;
export const boTestingCreateUrl = `/bo/testings/new/`;

export const boMailingCampaignsUrl = '/bo/mailing-campaigns/';
export const boMailingCampaignCreateUrl = `/bo/mailing-campaign/new/`;
export const boMailingCampaignEditUrl = `/bo/mailing-campaign/${MAILING_CAMPAIGN_ID}/`;
export const boMailingCampaignStatsUrl = `/bo/mailing-campaign/${MAILING_CAMPAIGN_ID}/stats/`;

export const boBrandDaysCampaignsUrl = '/bo/brand-days-campaigns/';
export const boBrandDaysCampaignCreateUrl = `/bo/brand-days-campaign/new/`;
export const boBrandDaysCampaignEditUrl = `/bo/brand-days-campaign/${BRAND_DAYS_CAMPAIGN_ID}/`;
export const boBrandDaysCampaignStatsUrl = `/bo/brand-days-campaign/${BRAND_DAYS_CAMPAIGN_ID}/stats/`;

export const boTrackedHashtagsUrl = '/bo/tracked-hashtags/';
export const boTrackedHashtagCreateUrl = '/bo/tracked-hashtag/0/';

export const admOrderUrl = `//admanager.google.com/10813505#delivery/order/order_overview/order_id=${ADM_ORDER_ID}`;
export const admLineitemUrl = `//admanager.google.com/10813505#delivery/line_item/detail/line_item_id=${ADM_LINEITEM_ID}`;

export const ytUploadVideoUrl = `https://studio.youtube.com/channel/UC9r1ke0IDok7Z0HeBH3-Xvg/videos/upload`;
