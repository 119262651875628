import { ACTION_TO_ADD_POST } from 'mk/autogenerated/translations/analytics.801d8a46fbf9d198ef1b0b8af60dd397'
import { anltx_api_store_browser_event_url } from 'mk/urls/functions';
import {
    analyticsStoreEvent,
    AnalyticsStoreEventAction,
    ANALYTICS_STORE_EVENT,
    FORUM_POST_ADD_UNAUTHENTICATED,
} from 'mk2/actions';
import { forumPostNewUrl } from 'mk2/apps/forum/urls';
import { XHRAction } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { checkIfUserIsLoggedIn } from 'mk2/sagas/auth';
import { eventChannel } from 'redux-saga';
import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';

const logger = getLogger('sagas/analytics');

function watchStoreEventFromPostMessage() {
    return eventChannel((emitter) => {
        const listener = (event) => {
            if (event.data && event.data.source) {
                if (event.data.source === 'mkStoreEvent') {
                    emitter({
                        name: event.data.name,
                        props: event.data.props,
                    });
                }
            }
        };

        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        };
    });
}

function* storeEvent({ xhr, name, props, redirect }: AnalyticsStoreEventAction & XHRAction) {
    try {
        const response = yield call(() =>
            xhr.post(anltx_api_store_browser_event_url(), {
                __name__: name,
                ...props,
            }),
        );
    } catch (error) {
        // just log and do nothing -> we do not need to inform users if there is an error
        logger.error(error);
    }

    if (redirect && typeof window !== 'undefined') {
        window.location.href = redirect;
        // TODO: When whole MK will be in React -> replace with
        // yield redirectInPWASaga(redirect);
    }
}

function* postAddUnauthenticated() {
    /* this saga runs only in case the user is not logged in  */
    if (!(yield call(checkIfUserIsLoggedIn, ACTION_TO_ADD_POST, forumPostNewUrl))) {
        return;
    }
}

function* storeEventsFromPostMessage() {
    // Run only in the browser
    if (typeof window === 'undefined') {
        return;
    }

    const channel = yield call(watchStoreEventFromPostMessage);
    while (true) {
        const { name, props } = yield take(channel);
        yield put(analyticsStoreEvent(name, props));
    }
}

export default function* root() {
    yield all([
        takeEvery(ANALYTICS_STORE_EVENT, storeEvent),
        takeEvery(FORUM_POST_ADD_UNAUTHENTICATED, postAddUnauthenticated),
        fork(storeEventsFromPostMessage),
    ]);
}
