import { schema } from 'normalizr';

import { Entity } from 'mk2/schemas';

export interface StarrableEntity extends Entity {
    readonly entity: string;

    isStarred: boolean;
    starsCount: number;
    createdTime: string | null;
}

export const starKey = (entity: string, id: number): string => `${entity}/${id}`;

export const createStarrableSchema = (entity) => new schema.Entity('starrable', {}, {
    idAttribute: (value, parent, key) => starKey(entity, value.id || parent.id),
    processStrategy: (value, parent, key) => ({ ...value, id: parent.id, entity }),
});
