import { QueryParams } from 'mk2/apps/blogs/containers/Article/ArticlePage';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';

///////////////////////////////////////////////////////////////////
// Article load

export const ARTICLE_FETCH_TRIGGER = 'ARTICLE_FETCH_TRIGGER';
export interface ArticleFetchTriggerAction {
    readonly type: typeof ARTICLE_FETCH_TRIGGER;
    readonly username: string;
    readonly articleSlug: string;
    readonly articleOpened: boolean;
    readonly query: QueryParams;
    readonly accessKey: string;
}
export const ARTICLE_FETCH_REQUEST = 'ARTICLE_FETCH_REQUEST';
export interface ArticleFetchRequestAction extends APIRequestAction {
    readonly type: typeof ARTICLE_FETCH_REQUEST;
    readonly username: string;
    readonly articleSlug: string;
    readonly accessKey: string;
}
export interface ArticleFetchNormalizedResponse extends NormalizedResponse {
    readonly result: {
        post: number;
        comments: number[];
        suggestedArticles: number[];
        suggestedArticlesHomepagePhotos: {[articleId: number]: number};
    };
}
export const ARTICLE_FETCH_SUCCESS = 'ARTICLE_FETCH_SUCCESS';
export interface ArticleFetchSuccessAction extends APISuccessAction<ArticleFetchNormalizedResponse> {
    readonly type: typeof ARTICLE_FETCH_SUCCESS;
    readonly username: string;
    readonly articleSlug: string;
    readonly accessKey: string;
}
export const ARTICLE_FETCH_FAILURE = 'ARTICLE_FETCH_FAILURE';
export interface ArticleFetchFailureAction extends APIFailureAction {
    readonly type: typeof ARTICLE_FETCH_FAILURE;
    readonly username: string;
    readonly articleSlug: string;
    readonly accessKey: string;
}

export const articleFetchTrigger =
    (username: string, articleSlug: string, articleOpened: boolean, query: QueryParams, accessKey: string): ArticleFetchTriggerAction => ({
        type: ARTICLE_FETCH_TRIGGER, username, articleSlug, articleOpened, query, accessKey,
    });
export const articleFetchApi = {
    request: (username: string, articleSlug: string, accessKey: string): ArticleFetchRequestAction => ({
        type: ARTICLE_FETCH_REQUEST, username, articleSlug, accessKey,
    }),
    success: (username: string, articleSlug: string, accessKey: string, response: ArticleFetchNormalizedResponse): ArticleFetchSuccessAction => ({
        type: ARTICLE_FETCH_SUCCESS, username, articleSlug, accessKey, response,
    }),
    failure: (username: string, articleSlug: string, accessKey: string, error: any): ArticleFetchFailureAction => ({
        type: ARTICLE_FETCH_FAILURE, username, articleSlug, accessKey, error,
    }),
};

// ///////////////////////////////////////////////////////////////////
// Article Delete

export const ARTICLE_DELETE_TRIGGER = 'ARTICLE_DELETE_TRIGGER';
export interface ArticleDeleteTriggerAction {
    readonly type: typeof ARTICLE_DELETE_TRIGGER;
    readonly articleId: number;
    readonly redirectUrl: string;
}
export const ARTICLE_DELETE_REQUEST = 'ARTICLE_DELETE_REQUEST';
export interface ArticleDeleteRequestAction extends APIRequestAction {
    readonly type: typeof ARTICLE_DELETE_REQUEST;
    readonly articleId: number;
}
export interface ArticleDeleteNormalizedResponse extends NormalizedResponse {
    readonly result: {
        post: number,
    };
}
export const ARTICLE_DELETE_SUCCESS = 'ARTICLE_DELETE_SUCCESS';
export interface ArticleDeleteSuccessAction extends APISuccessAction<ArticleDeleteNormalizedResponse> {
    readonly type: typeof ARTICLE_DELETE_SUCCESS;
    readonly articleId: number;
}
export const ARTICLE_DELETE_FAILURE = 'ARTICLE_DELETE_FAILURE';
export interface ArticleDeleteFailureAction extends APIFailureAction {
    readonly type: typeof ARTICLE_DELETE_FAILURE;
    readonly articleId: number;
}

export const articleDeleteTrigger =
    (articleId: number, redirectUrl?: string): ArticleDeleteTriggerAction => ({
        type: ARTICLE_DELETE_TRIGGER, articleId, redirectUrl,
    });
export const articleDeleteApi = {
    request: (articleId: number): ArticleDeleteRequestAction => ({
        type: ARTICLE_DELETE_REQUEST, articleId,
    }),
    success: (articleId: number, response: ArticleDeleteNormalizedResponse): ArticleDeleteSuccessAction => ({
        type: ARTICLE_DELETE_SUCCESS, articleId, response,
    }),
    failure: (articleId: number, error: any): ArticleDeleteFailureAction => ({
        type: ARTICLE_DELETE_FAILURE, articleId, error,
    }),
};

// ///////////////////////////////////////////////////////////////////
// Article page view

export const ARTICLE_PAGE_VIEW = 'BLOGS_ARTICLE_PAGE_VIEW';
export interface ArticlePageViewTriggerAction {
    readonly type: typeof ARTICLE_PAGE_VIEW;
    readonly username: string;
    readonly articleSlug: string;
}

export const articlePageViewTrigger =
    (username: string, articleSlug: string): ArticlePageViewTriggerAction => ({
        type: ARTICLE_PAGE_VIEW, username, articleSlug,
    });
