import { ID_RE, SLUG_LEGACY_RE, SLUG_RE } from 'mk2/helpers/urls';

const ENTITY_ID = `:entityId(${ID_RE})`;
const GROUP_ID = `:groupId(${ID_RE})`;
const IMAGE_ID = `:imageId(${ID_RE})`;
const POST_ID = `:postId(${ID_RE})`;
const COMMENT_ID = `:commentId(${ID_RE})`;
const POST_SLUG = `:postSlug(${SLUG_LEGACY_RE})`;
const CATEGORY_SLUG = `:categorySlug(${SLUG_RE})`;

export const groupsUrl = '/group/';
export const groupsNewGroupUrl = `/group/new/`;
export const groupsNewGroupSelectUrl = `/group/new/select/`;
export const groupsNewGroupFormUrl = `/group/new/:type(public|private)/`;
export const groupsEntityLikersUrl = `/group/likers/:type(comment|post)/${ENTITY_ID}/`;
export const groupsMyActivityFeedUrl = `/group/my-activity/`;
export const groupsSubscribedFeedUrl = `/group/subscribed/`;
export const groupsGroupUrl = `/group/${GROUP_ID}/`;
export const groupsGroupReportedUrl = `/group/${GROUP_ID}/reported/`;
export const groupsGroupInfoUrl = `/group/${GROUP_ID}/detail/`;
export const groupsGroupEditUrl = `/group/${GROUP_ID}/edit/`;
export const groupsGroupPhotosUrl = `/group/${GROUP_ID}/photos/`;
export const groupsGroupMembersUrl = `/group/${GROUP_ID}/members/`;
export const groupsGroupBlockMemberUrl = `/group/${GROUP_ID}/block-member/`;
export const groupsGroupBlockedMembersUrl = `/group/${GROUP_ID}/blocked-members/`;
export const groupsGroupInvitedMembersUrl = `/group/${GROUP_ID}/invited-members/`;
export const groupsGroupMembershipRequestsUrl = `/group/${GROUP_ID}/membership-requests/`;
export const groupsGroupInvitationUrl = `/group/${GROUP_ID}/invitation/:invitation/`;
export const groupsPhotoDetailUrl = `/group/${GROUP_ID}/${POST_SLUG}/${IMAGE_ID}/`;
export const groupsPostDetailUrl = `/group/${GROUP_ID}/${POST_SLUG}/`;
export const groupsPostEditUrl = `/group/post/${POST_ID}/edit/`;
export const groupsCommentEditUrl = `/group/comment/${COMMENT_ID}/edit/`;
export const groupsListUrl = '/group/list/';
export const groupsListFromCategoryUrl = `/group/list/${CATEGORY_SLUG}/`;
