import { PregnancyNewsletterFormData } from 'mk2/components/PregnancyNewsletterForm';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { HistoryLocationState } from 'mk2/services/browserHistory';
import { ParsedQuery } from 'query-string';

// ///////////////////////////////////////////////////////////////////
// Subscriber Save

export const NEWSLETTER_SUBSCRIBER_CREATE_TRIGGER = 'NEWSLETTER_SUBSCRIBER_CREATE_TRIGGER';
export interface NewsletterSubscriberCreateTriggerAction {
    readonly type: typeof NEWSLETTER_SUBSCRIBER_CREATE_TRIGGER;
    readonly formName: string;
    readonly subscriberFd: PregnancyNewsletterFormData;
    readonly historyLocationState: HistoryLocationState;
    readonly signupProps: ParsedQuery;
    readonly extraEventProps: ParsedQuery;
}
export const NEWSLETTER_SUBSCRIBER_CREATE_REQUEST = 'NEWSLETTER_SUBSCRIBER_CREATE_REQUEST';
export interface NewsletterSubscriberCreateRequestAction extends APIRequestAction {
    readonly type: typeof NEWSLETTER_SUBSCRIBER_CREATE_REQUEST;
    readonly formName: string;
    readonly subscriberFd: PregnancyNewsletterFormData;
}

export const NEWSLETTER_SUBSCRIBER_CREATE_SUCCESS = 'NEWSLETTER_SUBSCRIBER_CREATE_SUCCESS';
export interface NewsletterSubscriberCreateSuccessAction extends APISuccessAction {
    readonly type: typeof NEWSLETTER_SUBSCRIBER_CREATE_SUCCESS;
    readonly formName: string;
    readonly subscriberFd: PregnancyNewsletterFormData;
    readonly response: any;
}
export const NEWSLETTER_SUBSCRIBER_CREATE_FAILURE = 'NEWSLETTER_SUBSCRIBER_CREATE_FAILURE';
export interface NewsletterSubscriberCreateFailureAction extends APIFailureAction {
    readonly type: typeof NEWSLETTER_SUBSCRIBER_CREATE_FAILURE;
    readonly subscriberFd: PregnancyNewsletterFormData;
}

export const newsletterSubscriberCreateTrigger = (formName: string, subscriberFd: PregnancyNewsletterFormData, historyLocationState: HistoryLocationState, signupProps: ParsedQuery, extraEventProps: ParsedQuery): NewsletterSubscriberCreateTriggerAction => ({
        type: NEWSLETTER_SUBSCRIBER_CREATE_TRIGGER, formName, subscriberFd, historyLocationState, signupProps, extraEventProps,
    });
export const newsletterSubscriberCreateApi = {
    request: (formName: string, subscriberFd: PregnancyNewsletterFormData): NewsletterSubscriberCreateRequestAction => ({
        type: NEWSLETTER_SUBSCRIBER_CREATE_REQUEST, formName, subscriberFd,
    }),
    success: (formName: string, subscriberFd: PregnancyNewsletterFormData, response: any): NewsletterSubscriberCreateSuccessAction => ({
        type: NEWSLETTER_SUBSCRIBER_CREATE_SUCCESS, formName, subscriberFd, response,
    }),
    failure: (formName: string, subscriberFd: PregnancyNewsletterFormData, error: any): NewsletterSubscriberCreateFailureAction => ({
        type: NEWSLETTER_SUBSCRIBER_CREATE_FAILURE, subscriberFd, error,
    }),
};
