import cx from 'classnames';
import React from 'react';
import styles from './NotificationsBadge.mscss';

export enum Type {
    Default,
    Important,
}

export type Count =
    | number
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '13'
    | '14'
    | '15'
    | '16'
    | '17'
    | '18'
    | '19'
    | '20'
    | '20+';

interface OwnProps {
    /** Add custom classname to container */
    className?: string;

    /** Type of the badge - currently we have default (gray) and important (red) */
    type?: Type;

    /** Number displayed in the badge - if more than 20 -> it will display 20+ */
    count?: Count;

    /** Custom background color */
    backgroundColor?: string;

    /** Custom color */
    color?: string;

    /** Enable shadow */
    shadow?: boolean;

    /** Disable number limit (20+) */
    limit?: boolean;
}

export const NotificationsBadge: React.FunctionComponent<OwnProps> = ({
    className,
    type = Type.Default,
    count,
    backgroundColor,
    color,
    shadow = false,
    limit = true,
}) => {
    if (!count) {
        return null;
    }

    return (
        <div
            className={cx(
                styles.NotificationsBadge,
                styles[`NotificationsBadge--${Type[type]}`],
                shadow && styles[`NotificationsBadge--shadow`],
                className,
            )}
            style={{
                backgroundColor,
                color,
            }}
        >
            {typeof count === 'number' && count > 20 && limit ? '20+' : count}
        </div>
    );
};
