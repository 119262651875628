import { parse as cookieParse, serialize as cookieSerialize } from 'cookie';
import { Location } from 'history';
import range from 'lodash-es/range';
import { ANONYMOUS_USERNAME, LOGIN_TITLE, SIGNUP_TITLE } from 'mk/autogenerated/translations/utils.362b1bed39a4b7511501ceccc108b3f8'
import { isAnonymousUsername } from 'mk/bazaar/common/userUtils';
import assetUrl from 'mk/common/assetUrl';
import { logout_url } from 'mk/urls/functions';
import { SexType } from 'mk2/apps/settings/schemas';
import { HOME_COUNTRY_PHONE_PREFIX, SIBLING_COUNTRY_PHONE_PREFIX } from 'mk2/apps/users/constants';
import { loginUrl, signupUrl } from 'mk2/apps/users/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { hasRoute } from 'mk2/helpers/router';
import { translateMonth } from 'mk2/helpers/timeformat';
import { url, GetParams } from 'mk2/helpers/urls';
import { getLogger } from 'mk2/logger';
import { routes } from 'mk2/router';
import { parse } from 'query-string';
import React from 'react';

export const fixUsername = (username: string): string => {
    return isAnonymousUsername(username) ? ANONYMOUS_USERNAME : username;
};

export const GENERIC_AVATAR_PATH = 'img/avatars/user/no_photo_';

export function getGenericAvatar(size: 50 | 150, sex: SexType): string {
    return `${assetUrl}${GENERIC_AVATAR_PATH}${size}_${sex === SexType.Boy ? 2 : 1}.png`;
}

export function getLastKnownUsernameCookie(): string {
    return cookieParse(document.cookie)._remember || null;
}

export function storeLastKnownUsernameCookie(username: string) {
    document.cookie = cookieSerialize('_remember', username, {
        path: '/',
        maxAge: 400 * 24 * 60 * 60,  // 400 days
    });
}

export function getPregnancyNewsletterSubscribedCookie(): boolean {
    return typeof window === 'undefined'
        ? false
        : Boolean(cookieParse(document.cookie).pregnancyNewsletterSubscribed);
}

export function getNewGroupsCookie(): boolean {
    return cookieParse(document.cookie).new_groups === '1';
}

export function getIgnoreOldBrowserWarningCookie(): boolean {
    return Boolean(cookieParse(document.cookie).ignoreOldBrowserWarning);
}

export function storeIgnoreOldBrowserWarningCookie() {
    document.cookie = cookieSerialize('ignoreOldBrowserWarning', 'true', {
        path: '/',
        maxAge: 7 * 24 * 60 * 60,  // 1 week
    });
}

function isUnsuitableRedirectUrl(rurl: string): boolean {
    return rurl.startsWith(logout_url())
        || rurl.indexOf(loginUrl) >= 0
        || rurl.indexOf('://') >= 0
        || rurl.indexOf(' ') >= 0;
}

export function isNextPagePWA(location: Location, defaultUrl: string): boolean {
    const redirectTo = (parse(location.search).next as string) || defaultUrl;
    return hasRoute(redirectTo, routes);
}

export function gotoUrlAfterLoginOrSignup(baseUrl: string, location: Location, defaultUrl: string, redirectInPWA: boolean): string {
    let redirectTo = parse(location.search).next as string;

    if (redirectInPWA) {
        baseUrl = '';
    }

    redirectTo = (!redirectTo || isUnsuitableRedirectUrl(redirectTo))
        ? null
        : baseUrl + redirectTo; // FIXME az bude cela app v react, netreba absolut url a bude fungovat success toast

    if (!redirectTo) {
        redirectTo = baseUrl + defaultUrl;  // iframe can be embedded in other pages than www.modrykonik.sk, so we need to use absolute url
    }
    return redirectTo;
}

declare var window: any;
const logger = getLogger('users/utils');

export function gtmEvent(eventName: string) {
    // Run on next event loop - so that we are not blocking react
    setTimeout(() => {
        const base = document.querySelector('base');
        const baseHref = base ? base.getAttribute('href') : '/';
        const currentUrl = `${baseHref.replace(/\/$/, '')}${location.pathname}${location.search}${location.hash}`;

        if (typeof (window.dataLayer) !== 'undefined') {
            window.dataLayer.push({
                'event': eventName,
                'gtm.elementUrl': currentUrl,
                'gtm.url': currentUrl,
            });
        } else {
            logger.error('no GTM datalayer');
        }
    });
}

export function loginSignupTabs(active: string, query: GetParams) {
    return [(
        <Btn
            key="signup"
            type={BtnType.Tab}
            label={SIGNUP_TITLE}
            link={url(signupUrl, {}, query)}
            active={active === 'signup'}
        />), (
        <Btn
            key="login"
            type={BtnType.Tab}
            label={LOGIN_TITLE}
            link={url(loginUrl, {}, query)}
            active={active === 'login'}
        />)];
}

export function monthsFrom1980() {
    const options = [];
    const monthsInYear = range(12);
    const yearsFrom1980 = range(1980, new Date().getFullYear() + 2);
    yearsFrom1980.forEach((year) => {
        options.push({value: `${year}`, label: `${year}`});
        monthsInYear.forEach((month) =>
            options.push({value: `${year}-${month + 1}`, label: `${year} - ${translateMonth(month)}`}));
    });
    return options.reverse();
}

export function prettyNumber(phone) {
    if (phone.substring(0, 3) !== '+42') {
        // don't pretty format foreign numbers
        return phone;
    }
    return `${phone.substring(0, 4)} ${phone.substring(4, 7)} ${phone.substring(7, 10)} ${phone.substring(10)}`;
}

// keep in sync with has_validation_via_sms() in apps/sms/utils.py
export function hasValidationViaSms(fullPhoneNumberOrCountryPrefix): boolean {
    const smsPrefixes = [
        HOME_COUNTRY_PHONE_PREFIX, SIBLING_COUNTRY_PHONE_PREFIX, // sk cz
        // najcastejsie krajiny
        // https://docs.google.com/spreadsheets/d/1-aFfRStNXmMPnwv3ls5aeiurYMrwTDP26ogqcxenepg/edit?usp=sharing
        '+43', // at
        '+49', // de
    ];

    for (const prefix of smsPrefixes) {
        if (fullPhoneNumberOrCountryPrefix.startsWith(prefix)) {
            return true;
        }
    }

    return false;
}
