/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const veteran_vehicles : {[key: string]: Array<[string, string]>} = {
    p_year_of_manufacture: [
        ["UNTIL_1904", "do 1904"],
        d("1905-1918"),
        d("1919-1930"),
        d("1931-1945"),
        d("1946-1960"),
        d("1961-1970"),
        d("1971-1990"),
        ["YOUNGTIMER", "Youngtimer"],
    ],
};
