import { XHRAction, XHRClient } from 'mk2/helpers/api';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

/**
 * Inject xhr client API to all actions
 */
export function createXhrMiddleware<DispatchExt = {}, S = any, D extends Dispatch = Dispatch>(
    xhr: XHRClient,
): Middleware<DispatchExt, S, D> {
    return (api: MiddlewareAPI<D, S>) => (next: Dispatch<AnyAction>) => (
        action: AnyAction & XHRAction,
    ): AnyAction & XHRAction => {
        action.xhr = xhr;
        return next(action);
    };
}
