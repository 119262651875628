/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const thermometers : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["CONTACTLESS", "Bezkontaktn\u00ed"],
        ["CONTACT", "Kontaktn\u00ed"],
        ["CHILDRENS", "D\u011btsk\u00e9"],
    ],
};
