import cx from 'classnames';
import { Features } from 'mk2/apps/strollers/schemas';
import { niceNumber } from 'mk2/apps/strollers/utils';
import { hasVal } from 'mk2/helpers/sanitize';
import React from 'react';
import styles from './DimensionsFeature.mscss';

interface OwnProps {
    className?: string;
    features: Features;
    feature: any;
}

const renderValue = (value) => {
    return hasVal(value) ? niceNumber(value) : '?';
};

export class DimensionsFeature extends React.Component<OwnProps> {
    public render() {
        const { feature, features, className } = this.props;

        if (!feature.hasWorthyValue(features)) {
            return null;
        }

        const dims = feature.getDims(features);

        return (
            <div className={cx(styles.MinMaxDimensionsFeature, className)}>
                {renderValue(dims.height)}
                &nbsp;×&nbsp;
                {renderValue(dims.width)}
                &nbsp;×&nbsp;
                {renderValue(dims.length)}
            </div>
        );
    }
}
