/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { url } from 'mk2/helpers/urls';
import * as urlpatterns from 'mk/urls/patterns';

export const common_context_url = (qs?) => url(urlpatterns.common_context_pattern, {}, qs);
export const common_api_pageheaders_url = (qs?) => url(urlpatterns.common_api_pageheaders_pattern, {}, qs);
export const common_api_feed_menu_url = (qs?) => url(urlpatterns.common_api_feed_menu_pattern, {}, qs);
export const common_api_redirect_to_eshop_url = (slug, qs?) => url(urlpatterns.common_api_redirect_to_eshop_pattern, {slug}, qs);
export const common_api_basic_redirect_to_eshop_url = (slug, qs?) => url(urlpatterns.common_api_basic_redirect_to_eshop_pattern, {slug}, qs);
export const common_api_prepare_superset_funnel_views_url = (qs?) => url(urlpatterns.common_api_prepare_superset_funnel_views_pattern, {}, qs);
export const i18n_list_url = (qs?) => url(urlpatterns.i18n_list_pattern, {}, qs);
export const star_api_star_url = (object_type, object_id, qs?) => url(urlpatterns.star_api_star_pattern, {object_type, object_id}, qs);
export const logout_url = (qs?) => url(urlpatterns.logout_pattern, {}, qs);
export const users_must_auth_url = (url, qs?) => url(urlpatterns.users_must_auth_pattern, {url}, qs);
export const users_api_search_users_url = (qs?) => url(urlpatterns.users_api_search_users_pattern, {}, qs);
export const users_api_pregnancy_newsletter_subscribe_url = (qs?) => url(urlpatterns.users_api_pregnancy_newsletter_subscribe_pattern, {}, qs);
export const users_api_pregnancy_calculator_load_url = (qs?) => url(urlpatterns.users_api_pregnancy_calculator_load_pattern, {}, qs);
export const users_api_pregnancy_calculator_submit_url = (qs?) => url(urlpatterns.users_api_pregnancy_calculator_submit_pattern, {}, qs);
export const users_api_webpush_subscribe_url = (qs?) => url(urlpatterns.users_api_webpush_subscribe_pattern, {}, qs);
export const users_api_webpush_unsubscribe_url = (qs?) => url(urlpatterns.users_api_webpush_unsubscribe_pattern, {}, qs);
export const users_api_webpush_disable_url = (qs?) => url(urlpatterns.users_api_webpush_disable_pattern, {}, qs);
export const users_api_auth_login_url = (qs?) => url(urlpatterns.users_api_auth_login_pattern, {}, qs);
export const users_api_auth_login_load_url = (qs?) => url(urlpatterns.users_api_auth_login_load_pattern, {}, qs);
export const users_api_auth_signup_load_url = (qs?) => url(urlpatterns.users_api_auth_signup_load_pattern, {}, qs);
export const users_api_auth_signup_url = (qs?) => url(urlpatterns.users_api_auth_signup_pattern, {}, qs);
export const users_api_auth_validate_inputs_url = (qs?) => url(urlpatterns.users_api_auth_validate_inputs_pattern, {}, qs);
export const users_api_email_password_reset_url = (qs?) => url(urlpatterns.users_api_email_password_reset_pattern, {}, qs);
export const users_api_password_reset_verify_token_url = (qs?) => url(urlpatterns.users_api_password_reset_verify_token_pattern, {}, qs);
export const users_api_password_reset_set_new_url = (qs?) => url(urlpatterns.users_api_password_reset_set_new_pattern, {}, qs);
export const admins_api_complaint_url = (object_type, object_id, qs?) => url(urlpatterns.admins_api_complaint_pattern, {object_type, object_id}, qs);
export const admins_api_complaints_delete_url = (object_type, object_id, qs?) => url(urlpatterns.admins_api_complaints_delete_pattern, {object_type, object_id}, qs);
export const forum_api_post_get_url = (post_id, qs?) => url(urlpatterns.forum_api_post_get_pattern, {post_id}, qs);
export const forum_api_post_create_url = (qs?) => url(urlpatterns.forum_api_post_create_pattern, {}, qs);
export const forum_api_post_save_url = (qs?) => url(urlpatterns.forum_api_post_save_pattern, {}, qs);
export const forum_api_delete_post_url = (post_id, qs?) => url(urlpatterns.forum_api_delete_post_pattern, {post_id}, qs);
export const forum_api_post_pin_url = (post_id, qs?) => url(urlpatterns.forum_api_post_pin_pattern, {post_id}, qs);
export const forum_api_post_unpin_url = (post_id, qs?) => url(urlpatterns.forum_api_post_unpin_pattern, {post_id}, qs);
export const forum_api_topic_create_url = (qs?) => url(urlpatterns.forum_api_topic_create_pattern, {}, qs);
export const forum_api_topic_save_url = (qs?) => url(urlpatterns.forum_api_topic_save_pattern, {}, qs);
export const forum_api_topic_subscribe_url = (username, qs?) => url(urlpatterns.forum_api_topic_subscribe_pattern, {username}, qs);
export const forum_api_topic_unsubscribe_url = (username, qs?) => url(urlpatterns.forum_api_topic_unsubscribe_pattern, {username}, qs);
export const forum_api_topic_set_as_favorite_url = (qs?) => url(urlpatterns.forum_api_topic_set_as_favorite_pattern, {}, qs);
export const forum_api_get_topic_info_url = (qs?) => url(urlpatterns.forum_api_get_topic_info_pattern, {}, qs);
export const forum_api_delete_topic_url = (topic_slug, qs?) => url(urlpatterns.forum_api_delete_topic_pattern, {topic_slug}, qs);
export const forum_api_topic_moderator_invite_url = (qs?) => url(urlpatterns.forum_api_topic_moderator_invite_pattern, {}, qs);
export const forum_api_topic_moderator_remove_url = (qs?) => url(urlpatterns.forum_api_topic_moderator_remove_pattern, {}, qs);
export const forum_api_topic_moderator_invitation_url = (qs?) => url(urlpatterns.forum_api_topic_moderator_invitation_pattern, {}, qs);
export const forum_api_topic_moderator_accept_invitation_url = (qs?) => url(urlpatterns.forum_api_topic_moderator_accept_invitation_pattern, {}, qs);
export const forum_api_topic_moderator_decline_invitation_url = (qs?) => url(urlpatterns.forum_api_topic_moderator_decline_invitation_pattern, {}, qs);
export const forum_api_contest_create_url = (qs?) => url(urlpatterns.forum_api_contest_create_pattern, {}, qs);
export const forum_api_contest_save_url = (qs?) => url(urlpatterns.forum_api_contest_save_pattern, {}, qs);
export const forum_api_contest_get_url = (contest_id, qs?) => url(urlpatterns.forum_api_contest_get_pattern, {contest_id}, qs);
export const forum_api_counselling_question_create_url = (qs?) => url(urlpatterns.forum_api_counselling_question_create_pattern, {}, qs);
export const forum_api_counselling_question_reject_url = (qs?) => url(urlpatterns.forum_api_counselling_question_reject_pattern, {}, qs);
export const forum_api_get_counselling_data_url = (topic_slug, qs?) => url(urlpatterns.forum_api_get_counselling_data_pattern, {topic_slug}, qs);
export const forum_api_get_min_counselling_data_url = (topic_slug, qs?) => url(urlpatterns.forum_api_get_min_counselling_data_pattern, {topic_slug}, qs);
export const forum_api_get_categories_url = (qs?) => url(urlpatterns.forum_api_get_categories_pattern, {}, qs);
export const forum_api_topics_anonymous_url = (category_slug, qs?) => url(urlpatterns.forum_api_topics_anonymous_pattern, {category_slug}, qs);
export const forum_api_topics_url = (category_slug, username, qs?) => url(urlpatterns.forum_api_topics_pattern, {category_slug, username}, qs);
export const forum_api_get_topic_url = (topic_slug, qs?) => url(urlpatterns.forum_api_get_topic_pattern, {topic_slug}, qs);
export const forum_api_common_place_topics_url = (qs?) => url(urlpatterns.forum_api_common_place_topics_pattern, {}, qs);
export const forum_api_list_of_forums_anonymous_url = (qs?) => url(urlpatterns.forum_api_list_of_forums_anonymous_pattern, {}, qs);
export const forum_api_list_of_forums_url = (username, qs?) => url(urlpatterns.forum_api_list_of_forums_pattern, {username}, qs);
export const forum_api_feed_url = (qs?) => url(urlpatterns.forum_api_feed_pattern, {}, qs);
export const forum_api_feed_newest_url = (qs?) => url(urlpatterns.forum_api_feed_newest_pattern, {}, qs);
export const forum_api_feed_updated_url = (qs?) => url(urlpatterns.forum_api_feed_updated_pattern, {}, qs);
export const forum_api_feed_liked_url = (username, qs?) => url(urlpatterns.forum_api_feed_liked_pattern, {username}, qs);
export const forum_api_feed_commented_url = (username, qs?) => url(urlpatterns.forum_api_feed_commented_pattern, {username}, qs);
export const forum_api_my_activity_feed_url = (username, qs?) => url(urlpatterns.forum_api_my_activity_feed_pattern, {username}, qs);
export const forum_api_feed_reported_url = (topic_slug, qs?) => url(urlpatterns.forum_api_feed_reported_pattern, {topic_slug}, qs);
export const forum_api_post_likers_url = (topic_slug, post_slug, qs?) => url(urlpatterns.forum_api_post_likers_pattern, {topic_slug, post_slug}, qs);
export const forum_api_post_comment_likers_url = (topic_slug, post_slug, comment_id, qs?) => url(urlpatterns.forum_api_post_comment_likers_pattern, {topic_slug, post_slug, comment_id}, qs);
export const forum_api_archive_campaigns_url = (qs?) => url(urlpatterns.forum_api_archive_campaigns_pattern, {}, qs);
export const forum_api_post_detail_url = (topic_slug, post_slug, qs?) => url(urlpatterns.forum_api_post_detail_pattern, {topic_slug, post_slug}, qs);
export const forum_api_comment_get_url = (comment_id, qs?) => url(urlpatterns.forum_api_comment_get_pattern, {comment_id}, qs);
export const forum_api_comment_create_url = (qs?) => url(urlpatterns.forum_api_comment_create_pattern, {}, qs);
export const forum_api_comment_save_url = (qs?) => url(urlpatterns.forum_api_comment_save_pattern, {}, qs);
export const forum_api_delete_comment_url = (comment_id, qs?) => url(urlpatterns.forum_api_delete_comment_pattern, {comment_id}, qs);
export const forum_api_move_up_comment_url = (comment_id, qs?) => url(urlpatterns.forum_api_move_up_comment_pattern, {comment_id}, qs);
export const forum_api_read_url = (feed, qs?) => url(urlpatterns.forum_api_read_pattern, {feed}, qs);
export const forum_api_search_url = (qs?) => url(urlpatterns.forum_api_search_pattern, {}, qs);
export const forum_api_feed_untrustworthy_user_url = (username, qs?) => url(urlpatterns.forum_api_feed_untrustworthy_user_pattern, {username}, qs);
export const counsellings_api_home_url = (qs?) => url(urlpatterns.counsellings_api_home_pattern, {}, qs);
export const testings_api_testing_get_url = (testing_hashtag, qs?) => url(urlpatterns.testings_api_testing_get_pattern, {testing_hashtag}, qs);
export const testings_api_testing_edit_get_url = (testing_hashtag, qs?) => url(urlpatterns.testings_api_testing_edit_get_pattern, {testing_hashtag}, qs);
export const testings_api_testing_edit_save_url = (testing_id, qs?) => url(urlpatterns.testings_api_testing_edit_save_pattern, {testing_id}, qs);
export const contests_api_home_url = (qs?) => url(urlpatterns.contests_api_home_pattern, {}, qs);
export const contests_api_set_following_url = (qs?) => url(urlpatterns.contests_api_set_following_pattern, {}, qs);
export const system_api_photo_upload_url = (qs?) => url(urlpatterns.system_api_photo_upload_pattern, {}, qs);
export const photo_upload_url = (photo_type_name, qs?) => url(urlpatterns.photo_upload_pattern, {photo_type_name}, qs);
export const mail_api_mailbox_url = (username, folder, qs?) => url(urlpatterns.mail_api_mailbox_pattern, {username, folder}, qs);
export const mail_api_message_url = (id, qs?) => url(urlpatterns.mail_api_message_pattern, {id}, qs);
export const mail_api_read_url = (id, qs?) => url(urlpatterns.mail_api_read_pattern, {id}, qs);
export const mail_api_delete_url = (qs?) => url(urlpatterns.mail_api_delete_pattern, {}, qs);
export const mail_api_undelete_url = (qs?) => url(urlpatterns.mail_api_undelete_pattern, {}, qs);
export const mail_api_send_url = (qs?) => url(urlpatterns.mail_api_send_pattern, {}, qs);
export const ads_api_v1_mailing_campaign_sendme_url = (qs?) => url(urlpatterns.ads_api_v1_mailing_campaign_sendme_pattern, {}, qs);
export const ads_generate_pampers_coupon_url = (qs?) => url(urlpatterns.ads_generate_pampers_coupon_pattern, {}, qs);
export const bazaar_api_v2_suggest_category_url = (qs?) => url(urlpatterns.bazaar_api_v2_suggest_category_pattern, {}, qs);
export const bazaar_api_v2_search_suggest_url = (qs?) => url(urlpatterns.bazaar_api_v2_search_suggest_pattern, {}, qs);
export const bazaar_api_v3_reviews_url = (username, role, qs?) => url(urlpatterns.bazaar_api_v3_reviews_pattern, {username, role}, qs);
export const bazaar_api_v3_reviews_read_url = (username, role, qs?) => url(urlpatterns.bazaar_api_v3_reviews_read_pattern, {username, role}, qs);
export const bazaar_api_v3_reviews_new_url = (buyer_id, qs?) => url(urlpatterns.bazaar_api_v3_reviews_new_pattern, {buyer_id}, qs);
export const bazaar_api_v3_reviews_delete_url = (id, qs?) => url(urlpatterns.bazaar_api_v3_reviews_delete_pattern, {id}, qs);
export const bazaar_api_v3_reviews_reply_add_url = (id, qs?) => url(urlpatterns.bazaar_api_v3_reviews_reply_add_pattern, {id}, qs);
export const bazaar_api_v3_reviews_reply_delete_url = (id, qs?) => url(urlpatterns.bazaar_api_v3_reviews_reply_delete_pattern, {id}, qs);
export const bazaar_api_v3_sellers_liked_url = (qs?) => url(urlpatterns.bazaar_api_v3_sellers_liked_pattern, {}, qs);
export const bazaar_api_v3_sellers_clear_last_created_product_url = (qs?) => url(urlpatterns.bazaar_api_v3_sellers_clear_last_created_product_pattern, {}, qs);
export const bazaar_api_v3_seller_url = (username, qs?) => url(urlpatterns.bazaar_api_v3_seller_pattern, {username}, qs);
export const bazaar_api_v3_seller_products_url = (username, qs?) => url(urlpatterns.bazaar_api_v3_seller_products_pattern, {username}, qs);
export const bazaar_api_v3_seller_terms_save_url = (bazaar_id, qs?) => url(urlpatterns.bazaar_api_v3_seller_terms_save_pattern, {bazaar_id}, qs);
export const bazaar_api_v3_seller_set_show_phone_url = (user_id, qs?) => url(urlpatterns.bazaar_api_v3_seller_set_show_phone_pattern, {user_id}, qs);
export const bazaar_api_v3_seller_repost_all_products_url = (username, qs?) => url(urlpatterns.bazaar_api_v3_seller_repost_all_products_pattern, {username}, qs);
export const bazaar_api_v3_transactions_url = (username, qs?) => url(urlpatterns.bazaar_api_v3_transactions_pattern, {username}, qs);
export const bazaar_api_v3_transactions_save_notes_url = (username, buyer_id, qs?) => url(urlpatterns.bazaar_api_v3_transactions_save_notes_pattern, {username, buyer_id}, qs);
export const bazaar_api_v3_transactions_messages_url = (username, buyer_id, qs?) => url(urlpatterns.bazaar_api_v3_transactions_messages_pattern, {username, buyer_id}, qs);
export const bazaar_api_v3_buyers_remove_url = (buyer_id, qs?) => url(urlpatterns.bazaar_api_v3_buyers_remove_pattern, {buyer_id}, qs);
export const bazaar_api_v3_buyers_restore_url = (buyer_id, qs?) => url(urlpatterns.bazaar_api_v3_buyers_restore_pattern, {buyer_id}, qs);
export const bazaar_api_v3_products_url = (qs?) => url(urlpatterns.bazaar_api_v3_products_pattern, {}, qs);
export const bazaar_api_v3_products_create_url = (qs?) => url(urlpatterns.bazaar_api_v3_products_create_pattern, {}, qs);
export const bazaar_api_v3_products_delete_url = (qs?) => url(urlpatterns.bazaar_api_v3_products_delete_pattern, {}, qs);
export const bazaar_api_v3_products_activate_url = (qs?) => url(urlpatterns.bazaar_api_v3_products_activate_pattern, {}, qs);
export const bazaar_api_v3_products_deactivate_url = (qs?) => url(urlpatterns.bazaar_api_v3_products_deactivate_pattern, {}, qs);
export const bazaar_api_v3_products_repost_url = (qs?) => url(urlpatterns.bazaar_api_v3_products_repost_pattern, {}, qs);
export const bazaar_api_v3_product_load_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_product_load_pattern, {product_id}, qs);
export const bazaar_api_v3_product_edit_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_product_edit_pattern, {product_id}, qs);
export const bazaar_api_v3_product_delete_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_product_delete_pattern, {product_id}, qs);
export const bazaar_api_v3_product_activate_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_product_activate_pattern, {product_id}, qs);
export const bazaar_api_v3_product_deactivate_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_product_deactivate_pattern, {product_id}, qs);
export const bazaar_api_v3_product_ok_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_product_ok_pattern, {product_id}, qs);
export const bazaar_api_v3_product_undelete_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_product_undelete_pattern, {product_id}, qs);
export const bazaar_api_v3_product_view_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_product_view_pattern, {product_id}, qs);
export const bazaar_api_v3_product_related_products_url = (product_slug, qs?) => url(urlpatterns.bazaar_api_v3_product_related_products_pattern, {product_slug}, qs);
export const bazaar_api_v3_product_checkout_info_url = (product_slug, qs?) => url(urlpatterns.bazaar_api_v3_product_checkout_info_pattern, {product_slug}, qs);
export const bazaar_api_v3_product_checkout_fees_url = (product_slug, qs?) => url(urlpatterns.bazaar_api_v3_product_checkout_fees_pattern, {product_slug}, qs);
export const bazaar_api_v3_product_reserve_url = (product_slug, qs?) => url(urlpatterns.bazaar_api_v3_product_reserve_pattern, {product_slug}, qs);
export const bazaar_api_v3_change_status_url = (qs?) => url(urlpatterns.bazaar_api_v3_change_status_pattern, {}, qs);
export const bazaar_api_v3_save_problem_url = (qs?) => url(urlpatterns.bazaar_api_v3_save_problem_pattern, {}, qs);
export const bazaar_api_v3_get_bazaar_order_info_url = (qs?) => url(urlpatterns.bazaar_api_v3_get_bazaar_order_info_pattern, {}, qs);
export const bazaar_api_v3_bazaar_order_finish_url = (qs?) => url(urlpatterns.bazaar_api_v3_bazaar_order_finish_pattern, {}, qs);
export const bazaar_api_v3_delete_order_product_url = (qs?) => url(urlpatterns.bazaar_api_v3_delete_order_product_pattern, {}, qs);
export const bazaar_api_v3_get_order_label_url = (qs?) => url(urlpatterns.bazaar_api_v3_get_order_label_pattern, {}, qs);
export const bazaar_api_v3_all_pickup_points_url = (qs?) => url(urlpatterns.bazaar_api_v3_all_pickup_points_pattern, {}, qs);
export const bazaar_api_v3_dropoff_points_only_url = (qs?) => url(urlpatterns.bazaar_api_v3_dropoff_points_only_pattern, {}, qs);
export const bazaar_api_v3_pickup_points_in_bounds_url = (qs?) => url(urlpatterns.bazaar_api_v3_pickup_points_in_bounds_pattern, {}, qs);
export const bazaar_api_v3_product_detail_url = (product_slug, qs?) => url(urlpatterns.bazaar_api_v3_product_detail_pattern, {product_slug}, qs);
export const bazaar_api_v3_messages_reply_url = (buyer_id, qs?) => url(urlpatterns.bazaar_api_v3_messages_reply_pattern, {buyer_id}, qs);
export const bazaar_api_v3_messages_new_message_url = (product_id, qs?) => url(urlpatterns.bazaar_api_v3_messages_new_message_pattern, {product_id}, qs);
export const bazaar_api_v3_messages_read_url = (qs?) => url(urlpatterns.bazaar_api_v3_messages_read_pattern, {}, qs);
export const bazaar_api_v3_watchdog_subscribe_url = (qs?) => url(urlpatterns.bazaar_api_v3_watchdog_subscribe_pattern, {}, qs);
export const bazaar_api_v3_watchdog_unsubscribe_url = (qs?) => url(urlpatterns.bazaar_api_v3_watchdog_unsubscribe_pattern, {}, qs);
export const bazaar_api_v3_watchdog_list_url = (qs?) => url(urlpatterns.bazaar_api_v3_watchdog_list_pattern, {}, qs);
export const bazaar_api_v3_watchdog_list_token_url = (token, qs?) => url(urlpatterns.bazaar_api_v3_watchdog_list_token_pattern, {token}, qs);
export const bazaar_api_v3_watchdog_unsubscribe_multi_url = (qs?) => url(urlpatterns.bazaar_api_v3_watchdog_unsubscribe_multi_pattern, {}, qs);
export const bazaar_api_v3_watchdog_unsubscribe_multi_token_url = (token, qs?) => url(urlpatterns.bazaar_api_v3_watchdog_unsubscribe_multi_token_pattern, {token}, qs);
export const bazaar_api_v3_watchdog_subscribe_after_pause_url = (qs?) => url(urlpatterns.bazaar_api_v3_watchdog_subscribe_after_pause_pattern, {}, qs);
export const bazaar_api_v3_watchdog_subscribe_after_pause_token_url = (token, qs?) => url(urlpatterns.bazaar_api_v3_watchdog_subscribe_after_pause_token_pattern, {token}, qs);
export const bazaar_api_v3_wishlist_inactive_url = (qs?) => url(urlpatterns.bazaar_api_v3_wishlist_inactive_pattern, {}, qs);
export const bazaar_api_v3_shopping_url = (username, qs?) => url(urlpatterns.bazaar_api_v3_shopping_pattern, {username}, qs);
export const bazaar_api_v3_shopping_archive_url = (username, qs?) => url(urlpatterns.bazaar_api_v3_shopping_archive_pattern, {username}, qs);
export const bazaar_api_v3_json_data_for_native_ads_url = (qs?) => url(urlpatterns.bazaar_api_v3_json_data_for_native_ads_pattern, {}, qs);
export const bazaar_api_v3_hide_current_adding_stroller_review_announcement_url = (username, qs?) => url(urlpatterns.bazaar_api_v3_hide_current_adding_stroller_review_announcement_pattern, {username}, qs);
export const bazaar_redirect_to_watchdog_url = (watchdog_id, qs?) => url(urlpatterns.bazaar_redirect_to_watchdog_pattern, {watchdog_id}, qs);
export const bazaar_single_page_wishlist_category_url = (username, category_slug, qs?) => url(urlpatterns.bazaar_single_page_wishlist_category_pattern, {username, category_slug}, qs);
export const bazaar_single_page_wishlist_category_filters_url = (username, category_slug, filters, qs?) => url(urlpatterns.bazaar_single_page_wishlist_category_filters_pattern, {username, category_slug, filters}, qs);
export const bazaar_single_page_seller_category_url = (username, category_slug, qs?) => url(urlpatterns.bazaar_single_page_seller_category_pattern, {username, category_slug}, qs);
export const bazaar_single_page_seller_category_page_url = (username, category_slug, page, qs?) => url(urlpatterns.bazaar_single_page_seller_category_page_pattern, {username, category_slug, page}, qs);
export const bazaar_single_page_seller_category_filters_url = (username, category_slug, filters, qs?) => url(urlpatterns.bazaar_single_page_seller_category_filters_pattern, {username, category_slug, filters}, qs);
export const bazaar_single_page_seller_category_filters_page_url = (username, category_slug, filters, page, qs?) => url(urlpatterns.bazaar_single_page_seller_category_filters_page_pattern, {username, category_slug, filters, page}, qs);
export const bazaar_single_page_modify_product_url = (category_slug, hash, title, qs?) => url(urlpatterns.bazaar_single_page_modify_product_pattern, {category_slug, hash, title}, qs);
export const bazaar_single_page_my_bazaar_url = (username, qs?) => url(urlpatterns.bazaar_single_page_my_bazaar_pattern, {username}, qs);
export const bazaar_single_page_my_bazaar_widget_url = (username, qs?) => url(urlpatterns.bazaar_single_page_my_bazaar_widget_pattern, {username}, qs);
export const bazaar_single_page_my_bazaar_category_url = (username, category_slug, qs?) => url(urlpatterns.bazaar_single_page_my_bazaar_category_pattern, {username, category_slug}, qs);
export const bazaar_single_page_my_bazaar_category_page_url = (username, category_slug, page, qs?) => url(urlpatterns.bazaar_single_page_my_bazaar_category_page_pattern, {username, category_slug, page}, qs);
export const bazaar_single_page_my_bazaar_category_filters_url = (username, category_slug, filters, qs?) => url(urlpatterns.bazaar_single_page_my_bazaar_category_filters_pattern, {username, category_slug, filters}, qs);
export const bazaar_single_page_my_bazaar_category_filters_page_url = (username, category_slug, filters, page, qs?) => url(urlpatterns.bazaar_single_page_my_bazaar_category_filters_page_pattern, {username, category_slug, filters, page}, qs);
export const bazaar_single_page_product_grid_category_url = (category_slug, qs?) => url(urlpatterns.bazaar_single_page_product_grid_category_pattern, {category_slug}, qs);
export const bazaar_single_page_product_grid_category_page_url = (category_slug, page, qs?) => url(urlpatterns.bazaar_single_page_product_grid_category_page_pattern, {category_slug, page}, qs);
export const bazaar_single_page_product_grid_category_filters_url = (category_slug, filters, qs?) => url(urlpatterns.bazaar_single_page_product_grid_category_filters_pattern, {category_slug, filters}, qs);
export const bazaar_single_page_product_grid_category_filters_page_url = (category_slug, filters, page, qs?) => url(urlpatterns.bazaar_single_page_product_grid_category_filters_page_pattern, {category_slug, filters, page}, qs);
export const bazaar_single_page_item_detail_url = (category_slug, hash, title, qs?) => url(urlpatterns.bazaar_single_page_item_detail_pattern, {category_slug, hash, title}, qs);
export const bazaar_product_history_url = (product_id, qs?) => url(urlpatterns.bazaar_product_history_pattern, {product_id}, qs);
export const bazaar_redirect_to_eshop_url = (product_id, qs?) => url(urlpatterns.bazaar_redirect_to_eshop_pattern, {product_id}, qs);
export const shop_reviews_widget_url = (username, qs?) => url(urlpatterns.shop_reviews_widget_pattern, {username}, qs);
export const market_admin_url = (qs?) => url(urlpatterns.market_admin_pattern, {}, qs);
export const photoblog_api_v1_flagged_counts_url = (qs?) => url(urlpatterns.photoblog_api_v1_flagged_counts_pattern, {}, qs);
export const blogs_api_profile_detail_url = (qs?) => url(urlpatterns.blogs_api_profile_detail_pattern, {}, qs);
export const blogs_api_profile_feed_get_url = (qs?) => url(urlpatterns.blogs_api_profile_feed_get_pattern, {}, qs);
export const blogs_api_profile_set_like_url = (qs?) => url(urlpatterns.blogs_api_profile_set_like_pattern, {}, qs);
export const blogs_api_profile_fetch_related_users_url = (qs?) => url(urlpatterns.blogs_api_profile_fetch_related_users_pattern, {}, qs);
export const blogs_api_profile_fetch_users_groups_url = (qs?) => url(urlpatterns.blogs_api_profile_fetch_users_groups_pattern, {}, qs);
export const blogs_api_profile_delete_avatar_url = (qs?) => url(urlpatterns.blogs_api_profile_delete_avatar_pattern, {}, qs);
export const blogs_api_profile_fetch_vendor_data_url = (qs?) => url(urlpatterns.blogs_api_profile_fetch_vendor_data_pattern, {}, qs);
export const blogs_api_profile_set_vendor_data_url = (username, qs?) => url(urlpatterns.blogs_api_profile_set_vendor_data_pattern, {username}, qs);
export const blogs_api_create_blog_post_url = (qs?) => url(urlpatterns.blogs_api_create_blog_post_pattern, {}, qs);
export const blogs_api_feed_get_url = (category, qs?) => url(urlpatterns.blogs_api_feed_get_pattern, {category}, qs);
export const blogs_api_friends_feed_all_get_url = (qs?) => url(urlpatterns.blogs_api_friends_feed_all_get_pattern, {}, qs);
export const blogs_api_popular_feed_all_get_url = (qs?) => url(urlpatterns.blogs_api_popular_feed_all_get_pattern, {}, qs);
export const blogs_api_post_min_data_from_id_url = (qs?) => url(urlpatterns.blogs_api_post_min_data_from_id_pattern, {}, qs);
export const blogs_api_article_get_url = (qs?) => url(urlpatterns.blogs_api_article_get_pattern, {}, qs);
export const blogs_api_article_parse_url = (qs?) => url(urlpatterns.blogs_api_article_parse_pattern, {}, qs);
export const blogs_api_article_create_url = (qs?) => url(urlpatterns.blogs_api_article_create_pattern, {}, qs);
export const blogs_api_article_drafts_get_url = (qs?) => url(urlpatterns.blogs_api_article_drafts_get_pattern, {}, qs);
export const blogs_api_article_edit_url = (qs?) => url(urlpatterns.blogs_api_article_edit_pattern, {}, qs);
export const blogs_api_article_opened_url = (qs?) => url(urlpatterns.blogs_api_article_opened_pattern, {}, qs);
export const blogs_api_blog_entity_likers_url = (entity_type, entity_id, qs?) => url(urlpatterns.blogs_api_blog_entity_likers_pattern, {entity_type, entity_id}, qs);
export const blogs_api_short_message_detail_url = (qs?) => url(urlpatterns.blogs_api_short_message_detail_pattern, {}, qs);
export const blogs_api_short_message_get_url = (qs?) => url(urlpatterns.blogs_api_short_message_get_pattern, {}, qs);
export const blogs_api_short_message_save_url = (qs?) => url(urlpatterns.blogs_api_short_message_save_pattern, {}, qs);
export const blogs_api_photo_detail_url = (qs?) => url(urlpatterns.blogs_api_photo_detail_pattern, {}, qs);
export const blogs_api_album_detail_url = (qs?) => url(urlpatterns.blogs_api_album_detail_pattern, {}, qs);
export const blogs_api_album_get_url = (qs?) => url(urlpatterns.blogs_api_album_get_pattern, {}, qs);
export const blogs_api_album_reorder_url = (qs?) => url(urlpatterns.blogs_api_album_reorder_pattern, {}, qs);
export const blogs_api_album_edit_url = (qs?) => url(urlpatterns.blogs_api_album_edit_pattern, {}, qs);
export const blogs_api_album_create_url = (qs?) => url(urlpatterns.blogs_api_album_create_pattern, {}, qs);
export const blogs_api_groups_url = (qs?) => url(urlpatterns.blogs_api_groups_pattern, {}, qs);
export const blogs_api_group_memberships_url = (qs?) => url(urlpatterns.blogs_api_group_memberships_pattern, {}, qs);
export const blogs_api_fetch_new_groups_url = (qs?) => url(urlpatterns.blogs_api_fetch_new_groups_pattern, {}, qs);
export const blogs_api_group_create_url = (qs?) => url(urlpatterns.blogs_api_group_create_pattern, {}, qs);
export const blogs_api_delete_image_url = (image_id, qs?) => url(urlpatterns.blogs_api_delete_image_pattern, {image_id}, qs);
export const blogs_api_rotate_image_url = (image_id, qs?) => url(urlpatterns.blogs_api_rotate_image_pattern, {image_id}, qs);
export const blogs_api_save_image_title_url = (image_id, qs?) => url(urlpatterns.blogs_api_save_image_title_pattern, {image_id}, qs);
export const blogs_api_group_invitation_url = (invitation_id, qs?) => url(urlpatterns.blogs_api_group_invitation_pattern, {invitation_id}, qs);
export const blogs_api_group_accept_invitation_url = (invitation_id, qs?) => url(urlpatterns.blogs_api_group_accept_invitation_pattern, {invitation_id}, qs);
export const blogs_api_group_decline_invitation_url = (invitation_id, qs?) => url(urlpatterns.blogs_api_group_decline_invitation_pattern, {invitation_id}, qs);
export const blogs_api_group_entity_likers_url = (entity_type, entity_id, qs?) => url(urlpatterns.blogs_api_group_entity_likers_pattern, {entity_type, entity_id}, qs);
export const blogs_api_group_post_delete_url = (post_id, qs?) => url(urlpatterns.blogs_api_group_post_delete_pattern, {post_id}, qs);
export const blogs_api_group_post_get_url = (post_id, qs?) => url(urlpatterns.blogs_api_group_post_get_pattern, {post_id}, qs);
export const blogs_api_group_post_pin_url = (post_id, qs?) => url(urlpatterns.blogs_api_group_post_pin_pattern, {post_id}, qs);
export const blogs_api_group_post_save_url = (post_id, qs?) => url(urlpatterns.blogs_api_group_post_save_pattern, {post_id}, qs);
export const blogs_api_group_post_unpin_url = (post_id, qs?) => url(urlpatterns.blogs_api_group_post_unpin_pattern, {post_id}, qs);
export const blogs_api_get_group_subscribed_feed_url = (qs?) => url(urlpatterns.blogs_api_get_group_subscribed_feed_pattern, {}, qs);
export const blogs_api_get_group_my_activity_feed_url = (qs?) => url(urlpatterns.blogs_api_get_group_my_activity_feed_pattern, {}, qs);
export const blogs_api_get_group_feed_url = (group_id, qs?) => url(urlpatterns.blogs_api_get_group_feed_pattern, {group_id}, qs);
export const blogs_api_guide_send_heart_url = (qs?) => url(urlpatterns.blogs_api_guide_send_heart_pattern, {}, qs);
export const blogs_api_group_info_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_info_pattern, {group_id}, qs);
export const blogs_api_group_edit_get_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_edit_get_pattern, {group_id}, qs);
export const blogs_api_group_edit_save_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_edit_save_pattern, {group_id}, qs);
export const blogs_api_group_set_is_listed_private_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_set_is_listed_private_pattern, {group_id}, qs);
export const blogs_api_group_set_show_deleted_posts_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_set_show_deleted_posts_pattern, {group_id}, qs);
export const blogs_api_group_set_hide_posts_from_feed_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_set_hide_posts_from_feed_pattern, {group_id}, qs);
export const blogs_api_group_ask_to_join_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_ask_to_join_pattern, {group_id}, qs);
export const blogs_api_group_join_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_join_pattern, {group_id}, qs);
export const blogs_api_group_leave_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_leave_pattern, {group_id}, qs);
export const blogs_api_group_block_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_block_pattern, {group_id}, qs);
export const blogs_api_group_set_as_favorite_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_set_as_favorite_pattern, {group_id}, qs);
export const blogs_api_group_invite_moderator_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_invite_moderator_pattern, {group_id}, qs);
export const blogs_api_group_remove_moderator_url = (group_id, member_id, qs?) => url(urlpatterns.blogs_api_group_remove_moderator_pattern, {group_id, member_id}, qs);
export const blogs_api_group_members_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_members_pattern, {group_id}, qs);
export const blogs_api_group_membership_requests_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_membership_requests_pattern, {group_id}, qs);
export const blogs_api_group_invited_members_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_invited_members_pattern, {group_id}, qs);
export const blogs_api_group_blocked_members_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_blocked_members_pattern, {group_id}, qs);
export const blogs_api_group_unblock_member_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_unblock_member_pattern, {group_id}, qs);
export const blogs_api_group_photos_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_photos_pattern, {group_id}, qs);
export const blogs_api_get_group_post_detail_url = (group_id, post_slug, qs?) => url(urlpatterns.blogs_api_get_group_post_detail_pattern, {group_id, post_slug}, qs);
export const blogs_api_get_group_photo_detail_url = (group_id, post_slug, image_id, qs?) => url(urlpatterns.blogs_api_get_group_photo_detail_pattern, {group_id, post_slug, image_id}, qs);
export const blogs_api_group_create_post_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_create_post_pattern, {group_id}, qs);
export const blogs_api_group_invite_users_url = (group_id, qs?) => url(urlpatterns.blogs_api_group_invite_users_pattern, {group_id}, qs);
export const blogs_api_get_user_default_post_visibility_url = (qs?) => url(urlpatterns.blogs_api_get_user_default_post_visibility_pattern, {}, qs);
export const blogs_api_set_displaying_at_feed_url = (post_id, qs?) => url(urlpatterns.blogs_api_set_displaying_at_feed_pattern, {post_id}, qs);
export const blogs_api_set_post_visibility_url = (post_id, qs?) => url(urlpatterns.blogs_api_set_post_visibility_pattern, {post_id}, qs);
export const blogs_api_get_shareable_post_url = (post_id, qs?) => url(urlpatterns.blogs_api_get_shareable_post_pattern, {post_id}, qs);
export const blogs_api_share_post_url = (post_id, qs?) => url(urlpatterns.blogs_api_share_post_pattern, {post_id}, qs);
export const blogs_api_ignore_user_url = (username, qs?) => url(urlpatterns.blogs_api_ignore_user_pattern, {username}, qs);
export const blogs_api_get_comments_url = (model, object_id, qs?) => url(urlpatterns.blogs_api_get_comments_pattern, {model, object_id}, qs);
export const blogs_api_get_comments_by_ids_url = (qs?) => url(urlpatterns.blogs_api_get_comments_by_ids_pattern, {}, qs);
export const blogs_api_delete_comment_url = (qs?) => url(urlpatterns.blogs_api_delete_comment_pattern, {}, qs);
export const blogs_api_create_comment_url = (model, object_id, qs?) => url(urlpatterns.blogs_api_create_comment_pattern, {model, object_id}, qs);
export const blogs_api_edit_comment_url = (comment_id, qs?) => url(urlpatterns.blogs_api_edit_comment_pattern, {comment_id}, qs);
export const photoblog_all_ignored_url = (qs?) => url(urlpatterns.photoblog_all_ignored_pattern, {}, qs);
export const homepage_api_url = (qs?) => url(urlpatterns.homepage_api_pattern, {}, qs);
export const homepage_api_posts_url = (page, qs?) => url(urlpatterns.homepage_api_posts_pattern, {page}, qs);
export const adminclub_api_get_concepts_url = (qs?) => url(urlpatterns.adminclub_api_get_concepts_pattern, {}, qs);
export const adminclub_api_get_all_concepts_url = (qs?) => url(urlpatterns.adminclub_api_get_all_concepts_pattern, {}, qs);
export const adminclub_api_add_concept_url = (qs?) => url(urlpatterns.adminclub_api_add_concept_pattern, {}, qs);
export const adminclub_api_add_no_tag_url = (qs?) => url(urlpatterns.adminclub_api_add_no_tag_pattern, {}, qs);
export const adminclub_api_remove_concept_url = (qs?) => url(urlpatterns.adminclub_api_remove_concept_pattern, {}, qs);
export const adminclub_api_update_concept_position_url = (qs?) => url(urlpatterns.adminclub_api_update_concept_position_pattern, {}, qs);
export const adminclub_api_seo_forum_item_toggle_meta_actions_url = (qs?) => url(urlpatterns.adminclub_api_seo_forum_item_toggle_meta_actions_pattern, {}, qs);
export const adminclub_api_seo_forum_item_save_url = (qs?) => url(urlpatterns.adminclub_api_seo_forum_item_save_pattern, {}, qs);
export const adminclub_api_seo_forum_get_items_url = (purpose, qs?) => url(urlpatterns.adminclub_api_seo_forum_get_items_pattern, {purpose}, qs);
export const adminclub_api_wiki_fetch_article_vendor_relations_url = (qs?) => url(urlpatterns.adminclub_api_wiki_fetch_article_vendor_relations_pattern, {}, qs);
export const adminclub_api_wiki_add_article_vendor_relations_url = (qs?) => url(urlpatterns.adminclub_api_wiki_add_article_vendor_relations_pattern, {}, qs);
export const adminclub_api_wiki_remove_article_vendor_relations_url = (qs?) => url(urlpatterns.adminclub_api_wiki_remove_article_vendor_relations_pattern, {}, qs);
export const adminclub_api_wiki_fetch_review_category_for_create_url = (qs?) => url(urlpatterns.adminclub_api_wiki_fetch_review_category_for_create_pattern, {}, qs);
export const adminclub_api_wiki_fetch_review_category_for_edit_url = (article_id, qs?) => url(urlpatterns.adminclub_api_wiki_fetch_review_category_for_edit_pattern, {article_id}, qs);
export const adminclub_api_wiki_save_review_category_url = (qs?) => url(urlpatterns.adminclub_api_wiki_save_review_category_pattern, {}, qs);
export const adminclub_api_wiki_remove_review_category_url = (article_id, qs?) => url(urlpatterns.adminclub_api_wiki_remove_review_category_pattern, {article_id}, qs);
export const adminclub_api_product_catalog_import_run_url = (qs?) => url(urlpatterns.adminclub_api_product_catalog_import_run_pattern, {}, qs);
export const adminclub_api_product_catalog_imports_get_url = (qs?) => url(urlpatterns.adminclub_api_product_catalog_imports_get_pattern, {}, qs);
export const adminclub_api_xml_import_feeds_url = (qs?) => url(urlpatterns.adminclub_api_xml_import_feeds_pattern, {}, qs);
export const adminclub_api_xml_import_feeds_create_url = (qs?) => url(urlpatterns.adminclub_api_xml_import_feeds_create_pattern, {}, qs);
export const adminclub_api_xml_import_feeds_feed_url = (feed_id, qs?) => url(urlpatterns.adminclub_api_xml_import_feeds_feed_pattern, {feed_id}, qs);
export const adminclub_api_xml_import_feeds_edit_url = (feed_id, qs?) => url(urlpatterns.adminclub_api_xml_import_feeds_edit_pattern, {feed_id}, qs);
export const adminclub_api_xml_import_feeds_feed_history_url = (feed_id, qs?) => url(urlpatterns.adminclub_api_xml_import_feeds_feed_history_pattern, {feed_id}, qs);
export const adminclub_api_xml_import_feeds_export_url = (feed_id, qs?) => url(urlpatterns.adminclub_api_xml_import_feeds_export_pattern, {feed_id}, qs);
export const adminclub_api_xml_import_stats_url = (qs?) => url(urlpatterns.adminclub_api_xml_import_stats_pattern, {}, qs);
export const adminclub_api_xml_import_items_url = (qs?) => url(urlpatterns.adminclub_api_xml_import_items_pattern, {}, qs);
export const adminclub_api_xml_import_items_item_suggestions_url = (item_id, qs?) => url(urlpatterns.adminclub_api_xml_import_items_item_suggestions_pattern, {item_id}, qs);
export const adminclub_api_xml_import_items_item_ignore_url = (item_id, qs?) => url(urlpatterns.adminclub_api_xml_import_items_item_ignore_pattern, {item_id}, qs);
export const adminclub_api_xml_import_items_item_set_variant_url = (item_id, qs?) => url(urlpatterns.adminclub_api_xml_import_items_item_set_variant_pattern, {item_id}, qs);
export const adminclub_api_help_ticket_get_url = (token, qs?) => url(urlpatterns.adminclub_api_help_ticket_get_pattern, {token}, qs);
export const adminclub_api_help_ticket_add_message_url = (token, qs?) => url(urlpatterns.adminclub_api_help_ticket_add_message_pattern, {token}, qs);
export const adminclub_ips_url = (qs?) => url(urlpatterns.adminclub_ips_pattern, {}, qs);
export const adminclub_computers_url = (qs?) => url(urlpatterns.adminclub_computers_pattern, {}, qs);
export const adminclub_commenters_url = (qs?) => url(urlpatterns.adminclub_commenters_pattern, {}, qs);
export const adminclub_threads_url = (qs?) => url(urlpatterns.adminclub_threads_pattern, {}, qs);
export const adminclub_topics_url = (qs?) => url(urlpatterns.adminclub_topics_pattern, {}, qs);
export const adminclub_user_notes_url = (qs?) => url(urlpatterns.adminclub_user_notes_pattern, {}, qs);
export const adminclub_users_url = (qs?) => url(urlpatterns.adminclub_users_pattern, {}, qs);
export const adminclub_bazaar_url = (qs?) => url(urlpatterns.adminclub_bazaar_pattern, {}, qs);
export const adminclub_bazaar_buyers_url = (qs?) => url(urlpatterns.adminclub_bazaar_buyers_pattern, {}, qs);
export const adminclub_bazaar_moderators_url = (qs?) => url(urlpatterns.adminclub_bazaar_moderators_pattern, {}, qs);
export const adminclub_bazaar_moderators_life_url = (qs?) => url(urlpatterns.adminclub_bazaar_moderators_life_pattern, {}, qs);
export const adminclub_notes_url = (qs?) => url(urlpatterns.adminclub_notes_pattern, {}, qs);
export const adminclub_permissions_url = (qs?) => url(urlpatterns.adminclub_permissions_pattern, {}, qs);
export const adminclub_keywords_url = (qs?) => url(urlpatterns.adminclub_keywords_pattern, {}, qs);
export const adminclub_admin_log_url = (qs?) => url(urlpatterns.adminclub_admin_log_pattern, {}, qs);
export const adminclub_cms_url = (qs?) => url(urlpatterns.adminclub_cms_pattern, {}, qs);
export const adminclub_go_url = (qs?) => url(urlpatterns.adminclub_go_pattern, {}, qs);
export const adminclub_groups_url = (qs?) => url(urlpatterns.adminclub_groups_pattern, {}, qs);
export const adminclub_group_url = (id, qs?) => url(urlpatterns.adminclub_group_pattern, {id}, qs);
export const adminclub_hacker_url = (qs?) => url(urlpatterns.adminclub_hacker_pattern, {}, qs);
export const adminclub_police_url = (qs?) => url(urlpatterns.adminclub_police_pattern, {}, qs);
export const adminclub_images_url = (qs?) => url(urlpatterns.adminclub_images_pattern, {}, qs);
export const adminclub_ip_communication_url = (qs?) => url(urlpatterns.adminclub_ip_communication_pattern, {}, qs);
export const adminclub_strollers_questions_url = (qs?) => url(urlpatterns.adminclub_strollers_questions_pattern, {}, qs);
export const adminclub_strollers_reviews_url = (qs?) => url(urlpatterns.adminclub_strollers_reviews_pattern, {}, qs);
export const adminclub_tickets_notes_url = (qs?) => url(urlpatterns.adminclub_tickets_notes_pattern, {}, qs);
export const adminclub_tickets_url = (qs?) => url(urlpatterns.adminclub_tickets_pattern, {}, qs);
export const adminclub_ticket_create_url = (qs?) => url(urlpatterns.adminclub_ticket_create_pattern, {}, qs);
export const adminclub_seorenaming_url = (qs?) => url(urlpatterns.adminclub_seorenaming_pattern, {}, qs);
export const adminclub_rookies_url = (qs?) => url(urlpatterns.adminclub_rookies_pattern, {}, qs);
export const adminclub_forum_topic_url = (id, qs?) => url(urlpatterns.adminclub_forum_topic_pattern, {id}, qs);
export const adminclub_forum_post_url = (id, qs?) => url(urlpatterns.adminclub_forum_post_pattern, {id}, qs);
export const adminclub_bazaar_product_url = (id, qs?) => url(urlpatterns.adminclub_bazaar_product_pattern, {id}, qs);
export const adminclub_wiki_article_url = (id, qs?) => url(urlpatterns.adminclub_wiki_article_pattern, {id}, qs);
export const adminclub_wiki_experiences_url = (qs?) => url(urlpatterns.adminclub_wiki_experiences_pattern, {}, qs);
export const adminclub_wiki_experiences_detail_url = (id, qs?) => url(urlpatterns.adminclub_wiki_experiences_detail_pattern, {id}, qs);
export const adminclub_counselling_questions_url = (qs?) => url(urlpatterns.adminclub_counselling_questions_pattern, {}, qs);
export const adminclub_homepage_feed_posts_url = (qs?) => url(urlpatterns.adminclub_homepage_feed_posts_pattern, {}, qs);
export const adminclub_homepage_feed_posts_generate_page_token_url = (qs?) => url(urlpatterns.adminclub_homepage_feed_posts_generate_page_token_pattern, {}, qs);
export const adminclub_homepage_post_remove_url = (qs?) => url(urlpatterns.adminclub_homepage_post_remove_pattern, {}, qs);
export const adminclub_blogs_post_url = (id, qs?) => url(urlpatterns.adminclub_blogs_post_pattern, {id}, qs);
export const wall_api_mentions_url = (username, qs?) => url(urlpatterns.wall_api_mentions_pattern, {username}, qs);
export const wall_api_set_follow_url = (username, qs?) => url(urlpatterns.wall_api_set_follow_pattern, {username}, qs);
export const wall_api_thank_for_heart_url = (username, qs?) => url(urlpatterns.wall_api_thank_for_heart_pattern, {username}, qs);
export const wall_api_read_heart_url = (username, qs?) => url(urlpatterns.wall_api_read_heart_pattern, {username}, qs);
export const wall_api_mark_all_as_read_url = (username, qs?) => url(urlpatterns.wall_api_mark_all_as_read_pattern, {username}, qs);
export const wall_read_url = (mention_id, qs?) => url(urlpatterns.wall_read_pattern, {mention_id}, qs);
export const dash_api_seo_performance_data_url = (qs?) => url(urlpatterns.dash_api_seo_performance_data_pattern, {}, qs);
export const dash_new_vs_churn_url = (qs?) => url(urlpatterns.dash_new_vs_churn_pattern, {}, qs);
export const dash_revenue_url = (qs?) => url(urlpatterns.dash_revenue_pattern, {}, qs);
export const dash_group_categories_url = (qs?) => url(urlpatterns.dash_group_categories_pattern, {}, qs);
export const dash_traffic_url = (qs?) => url(urlpatterns.dash_traffic_pattern, {}, qs);
export const dash_traffic_users_url = (qs?) => url(urlpatterns.dash_traffic_users_pattern, {}, qs);
export const dash_emails_url = (qs?) => url(urlpatterns.dash_emails_pattern, {}, qs);
export const dash_user_stats_url = (qs?) => url(urlpatterns.dash_user_stats_pattern, {}, qs);
export const dash_server_stats_url = (qs?) => url(urlpatterns.dash_server_stats_pattern, {}, qs);
export const dash_server_stats_data_url = (qs?) => url(urlpatterns.dash_server_stats_data_pattern, {}, qs);
export const dash_amb_reports_url = (qs?) => url(urlpatterns.dash_amb_reports_pattern, {}, qs);
export const dash_last_active_url = (qs?) => url(urlpatterns.dash_last_active_pattern, {}, qs);
export const dash_calendar_url = (qs?) => url(urlpatterns.dash_calendar_pattern, {}, qs);
export const dash_dev_backups_url = (qs?) => url(urlpatterns.dash_dev_backups_pattern, {}, qs);
export const bo_api_v1_orders_get_orders_url = (qs?) => url(urlpatterns.bo_api_v1_orders_get_orders_pattern, {}, qs);
export const bo_api_v1_orders_graph_data_url = (qs?) => url(urlpatterns.bo_api_v1_orders_graph_data_pattern, {}, qs);
export const bo_api_v1_orders_signed_data_url = (qs?) => url(urlpatterns.bo_api_v1_orders_signed_data_pattern, {}, qs);
export const bo_api_v1_orders_agency_bonuses_url = (qs?) => url(urlpatterns.bo_api_v1_orders_agency_bonuses_pattern, {}, qs);
export const bo_api_orders_invoicing_url = (qs?) => url(urlpatterns.bo_api_orders_invoicing_pattern, {}, qs);
export const bo_api_order_create_url = (qs?) => url(urlpatterns.bo_api_order_create_pattern, {}, qs);
export const bo_api_order_create_init_url = (qs?) => url(urlpatterns.bo_api_order_create_init_pattern, {}, qs);
export const bo_api_order_create_checkurl_url = (qs?) => url(urlpatterns.bo_api_order_create_checkurl_pattern, {}, qs);
export const bo_api_order_edit_url = (qs?) => url(urlpatterns.bo_api_order_edit_pattern, {}, qs);
export const bo_api_order_get_url = (order_id, qs?) => url(urlpatterns.bo_api_order_get_pattern, {order_id}, qs);
export const bo_api_order_get_history_url = (order_id, qs?) => url(urlpatterns.bo_api_order_get_history_pattern, {order_id}, qs);
export const bo_api_order_delete_url = (order_id, qs?) => url(urlpatterns.bo_api_order_delete_pattern, {order_id}, qs);
export const bo_api_order_add_attachments_url = (qs?) => url(urlpatterns.bo_api_order_add_attachments_pattern, {}, qs);
export const bo_api_order_remove_attachment_url = (qs?) => url(urlpatterns.bo_api_order_remove_attachment_pattern, {}, qs);
export const bo_api_order_remove_invoice_url = (qs?) => url(urlpatterns.bo_api_order_remove_invoice_pattern, {}, qs);
export const bo_api_order_refresh_stats_url = (qs?) => url(urlpatterns.bo_api_order_refresh_stats_pattern, {}, qs);
export const bo_api_order_approve_plan_url = (qs?) => url(urlpatterns.bo_api_order_approve_plan_pattern, {}, qs);
export const bo_api_order_get_by_item_url = (order_item_id, qs?) => url(urlpatterns.bo_api_order_get_by_item_pattern, {order_item_id}, qs);
export const bo_api_order_create_lineitem_url = (qs?) => url(urlpatterns.bo_api_order_create_lineitem_pattern, {}, qs);
export const bo_api_order_save_pb_article_ref_url = (qs?) => url(urlpatterns.bo_api_order_save_pb_article_ref_pattern, {}, qs);
export const bo_api_order_save_wk_article_ref_url = (qs?) => url(urlpatterns.bo_api_order_save_wk_article_ref_pattern, {}, qs);
export const bo_api_order_save_wk_review_refs_url = (qs?) => url(urlpatterns.bo_api_order_save_wk_review_refs_pattern, {}, qs);
export const bo_api_order_save_article_report_url = (qs?) => url(urlpatterns.bo_api_order_save_article_report_pattern, {}, qs);
export const bo_api_order_save_tracked_hashtag_url = (qs?) => url(urlpatterns.bo_api_order_save_tracked_hashtag_pattern, {}, qs);
export const bo_api_testing_get_url = (testing_id, qs?) => url(urlpatterns.bo_api_testing_get_pattern, {testing_id}, qs);
export const bo_api_testing_save_url = (testing_id, qs?) => url(urlpatterns.bo_api_testing_save_pattern, {testing_id}, qs);
export const bo_api_testing_create_url = (qs?) => url(urlpatterns.bo_api_testing_create_pattern, {}, qs);
export const bo_api_v1_reports_get_report_url = (qs?) => url(urlpatterns.bo_api_v1_reports_get_report_pattern, {}, qs);
export const bo_api_v1_reports_get_ambassador_report_url = (qs?) => url(urlpatterns.bo_api_v1_reports_get_ambassador_report_pattern, {}, qs);
export const bo_api_v1_reports_get_ask_an_expert_report_url = (qs?) => url(urlpatterns.bo_api_v1_reports_get_ask_an_expert_report_pattern, {}, qs);
export const bo_api_v1_reports_get_testing_report_url = (qs?) => url(urlpatterns.bo_api_v1_reports_get_testing_report_pattern, {}, qs);
export const bo_api_v1_payments_card_url = (qs?) => url(urlpatterns.bo_api_v1_payments_card_pattern, {}, qs);
export const bo_api_v1_payments_sms_url = (qs?) => url(urlpatterns.bo_api_v1_payments_sms_pattern, {}, qs);
export const bo_api_v1_payments_status_url = (qs?) => url(urlpatterns.bo_api_v1_payments_status_pattern, {}, qs);
export const bo_api_v1_payment_success_url = (qs?) => url(urlpatterns.bo_api_v1_payment_success_pattern, {}, qs);
export const bo_api_v1_payment_failure_url = (qs?) => url(urlpatterns.bo_api_v1_payment_failure_pattern, {}, qs);
export const bo_sms_payments_url = (qs?) => url(urlpatterns.bo_sms_payments_pattern, {}, qs);
export const bo_invoices_url = (qs?) => url(urlpatterns.bo_invoices_pattern, {}, qs);
export const bo_bank_payments_url = (qs?) => url(urlpatterns.bo_bank_payments_pattern, {}, qs);
export const bo_card_payments_url = (qs?) => url(urlpatterns.bo_card_payments_pattern, {}, qs);
export const bo_customers_url = (qs?) => url(urlpatterns.bo_customers_pattern, {}, qs);
export const bo_agencies_url = (qs?) => url(urlpatterns.bo_agencies_pattern, {}, qs);
export const bo_counsellings_url = (qs?) => url(urlpatterns.bo_counsellings_pattern, {}, qs);
export const bo_generate_gtad_url = (qs?) => url(urlpatterns.bo_generate_gtad_pattern, {}, qs);
export const bo_mailing_campaigns_url = (qs?) => url(urlpatterns.bo_mailing_campaigns_pattern, {}, qs);
export const bo_invoice_create_url = (qs?) => url(urlpatterns.bo_invoice_create_pattern, {}, qs);
export const bo_invoice_file_for_user_url = (invoice_number, qs?) => url(urlpatterns.bo_invoice_file_for_user_pattern, {invoice_number}, qs);
export const bo_proforma_invoice_file_for_user_url = (qs?) => url(urlpatterns.bo_proforma_invoice_file_for_user_pattern, {}, qs);
export const bo_tracked_hashtags_url = (qs?) => url(urlpatterns.bo_tracked_hashtags_pattern, {}, qs);
export const bo_traffic_url = (qs?) => url(urlpatterns.bo_traffic_pattern, {}, qs);
export const bo_contests_url = (qs?) => url(urlpatterns.bo_contests_pattern, {}, qs);
export const bo_testings_url = (qs?) => url(urlpatterns.bo_testings_pattern, {}, qs);
export const bo_ambassadors_url = (qs?) => url(urlpatterns.bo_ambassadors_pattern, {}, qs);
export const like_api_likers_url = (qs?) => url(urlpatterns.like_api_likers_pattern, {}, qs);
export const like_api_like_url = (object_type, object_id, qs?) => url(urlpatterns.like_api_like_pattern, {object_type, object_id}, qs);
export const settings_api_profile_url = (username, qs?) => url(urlpatterns.settings_api_profile_pattern, {username}, qs);
export const settings_api_profile_save_url = (username, qs?) => url(urlpatterns.settings_api_profile_save_pattern, {username}, qs);
export const settings_api_kids_url = (username, qs?) => url(urlpatterns.settings_api_kids_pattern, {username}, qs);
export const settings_api_account_url = (username, qs?) => url(urlpatterns.settings_api_account_pattern, {username}, qs);
export const settings_api_account_save_url = (username, qs?) => url(urlpatterns.settings_api_account_save_pattern, {username}, qs);
export const settings_api_cancel_account_url = (username, qs?) => url(urlpatterns.settings_api_cancel_account_pattern, {username}, qs);
export const settings_api_account_change_password_url = (username, qs?) => url(urlpatterns.settings_api_account_change_password_pattern, {username}, qs);
export const settings_api_location_url = (username, qs?) => url(urlpatterns.settings_api_location_pattern, {username}, qs);
export const settings_api_location_save_url = (username, qs?) => url(urlpatterns.settings_api_location_save_pattern, {username}, qs);
export const settings_api_kid_get_url = (username, qs?) => url(urlpatterns.settings_api_kid_get_pattern, {username}, qs);
export const settings_api_kid_create_url = (username, qs?) => url(urlpatterns.settings_api_kid_create_pattern, {username}, qs);
export const settings_api_kid_save_url = (username, qs?) => url(urlpatterns.settings_api_kid_save_pattern, {username}, qs);
export const settings_api_kid_delete_url = (username, qs?) => url(urlpatterns.settings_api_kid_delete_pattern, {username}, qs);
export const settings_api_mail_notifications_save_url = (token, qs?) => url(urlpatterns.settings_api_mail_notifications_save_pattern, {token}, qs);
export const settings_api_mail_notifications_url = (qs?) => url(urlpatterns.settings_api_mail_notifications_pattern, {}, qs);
export const hearts_api_messages_url = (qs?) => url(urlpatterns.hearts_api_messages_pattern, {}, qs);
export const hearts_api_send_url = (qs?) => url(urlpatterns.hearts_api_send_pattern, {}, qs);
export const cms_api_v1_get_items_url = (qs?) => url(urlpatterns.cms_api_v1_get_items_pattern, {}, qs);
export const faq_api_index_url = (qs?) => url(urlpatterns.faq_api_index_pattern, {}, qs);
export const faq_api_fetch_ask_question_url = (qs?) => url(urlpatterns.faq_api_fetch_ask_question_pattern, {}, qs);
export const faq_api_send_ask_question_url = (qs?) => url(urlpatterns.faq_api_send_ask_question_pattern, {}, qs);
export const wiki_api_add_experience_url = (slug, qs?) => url(urlpatterns.wiki_api_add_experience_pattern, {slug}, qs);
export const wiki_api_add_experience_with_undef_article_url = (qs?) => url(urlpatterns.wiki_api_add_experience_with_undef_article_pattern, {}, qs);
export const wiki_api_v2_get_article_url = (qs?) => url(urlpatterns.wiki_api_v2_get_article_pattern, {}, qs);
export const wiki_api_v2_load_article_previews_url = (qs?) => url(urlpatterns.wiki_api_v2_load_article_previews_pattern, {}, qs);
export const wiki_api_v2_load_recent_blog_articles_url = (qs?) => url(urlpatterns.wiki_api_v2_load_recent_blog_articles_pattern, {}, qs);
export const wiki_api_v2_load_more_of_review_category_url = (qs?) => url(urlpatterns.wiki_api_v2_load_more_of_review_category_pattern, {}, qs);
export const wiki_api_v2_save_article_fetch_url = (article_id, qs?) => url(urlpatterns.wiki_api_v2_save_article_fetch_pattern, {article_id}, qs);
export const wiki_api_v2_save_article_url = (article_id, qs?) => url(urlpatterns.wiki_api_v2_save_article_pattern, {article_id}, qs);
export const wiki_api_v2_create_article_fetch_url = (qs?) => url(urlpatterns.wiki_api_v2_create_article_fetch_pattern, {}, qs);
export const wiki_api_v2_create_article_url = (qs?) => url(urlpatterns.wiki_api_v2_create_article_pattern, {}, qs);
export const wiki_api_v2_search_url = (qs?) => url(urlpatterns.wiki_api_v2_search_pattern, {}, qs);
export const wiki_api_v2_article_opened_url = (qs?) => url(urlpatterns.wiki_api_v2_article_opened_pattern, {}, qs);
export const wiki_api_v2_save_venor_reply_on_experience_url = (experience_id, qs?) => url(urlpatterns.wiki_api_v2_save_venor_reply_on_experience_pattern, {experience_id}, qs);
export const wiki_api_v2_delete_venor_reply_on_experience_url = (experience_id, qs?) => url(urlpatterns.wiki_api_v2_delete_venor_reply_on_experience_pattern, {experience_id}, qs);
export const wiki_api_v2_set_ambassador_brand_url = (article_id, qs?) => url(urlpatterns.wiki_api_v2_set_ambassador_brand_pattern, {article_id}, qs);
export const mailing_list_url = (qs?) => url(urlpatterns.mailing_list_pattern, {}, qs);
export const anltx_api_store_browser_event_url = (qs?) => url(urlpatterns.anltx_api_store_browser_event_pattern, {}, qs);
export const anltx_api_ab_url = (qs?) => url(urlpatterns.anltx_api_ab_pattern, {}, qs);
export const plan_invoices_url = (username, qs?) => url(urlpatterns.plan_invoices_pattern, {username}, qs);
export const plan_api_get_plus_data_url = (qs?) => url(urlpatterns.plan_api_get_plus_data_pattern, {}, qs);
export const plan_api_get_pro_data_url = (qs?) => url(urlpatterns.plan_api_get_pro_data_pattern, {}, qs);
export const plan_api_plan_for_load_data_url = (username, qs?) => url(urlpatterns.plan_api_plan_for_load_data_pattern, {username}, qs);
export const plan_api_plan_for_tamper_plan_url = (username, qs?) => url(urlpatterns.plan_api_plan_for_tamper_plan_pattern, {username}, qs);
export const plan_api_plan_for_payment_cancel_url = (username, qs?) => url(urlpatterns.plan_api_plan_for_payment_cancel_pattern, {username}, qs);
export const phone_api_verification_send_token_url = (qs?) => url(urlpatterns.phone_api_verification_send_token_pattern, {}, qs);
export const phone_api_verification_validate_token_url = (qs?) => url(urlpatterns.phone_api_verification_validate_token_pattern, {}, qs);
export const phone_api_verification_send_token_for_email_change_url = (username, qs?) => url(urlpatterns.phone_api_verification_send_token_for_email_change_pattern, {username}, qs);
export const phone_api_verification_validate_token_for_email_change_url = (username, qs?) => url(urlpatterns.phone_api_verification_validate_token_for_email_change_pattern, {username}, qs);
export const strollers_api_stroller_question_add_url = (qs?) => url(urlpatterns.strollers_api_stroller_question_add_pattern, {}, qs);
export const strollers_api_suggest_strollers2_url = (qs?) => url(urlpatterns.strollers_api_suggest_strollers2_pattern, {}, qs);
export const strollers_api_pattern_get_stroller_patterns_url = (qs?) => url(urlpatterns.strollers_api_pattern_get_stroller_patterns_pattern, {}, qs);
export const strollers_api_pattern_get_stroller_pattern_url = (qs?) => url(urlpatterns.strollers_api_pattern_get_stroller_pattern_pattern, {}, qs);
export const strollers_api_pattern_save_stroller_pattern_url = (qs?) => url(urlpatterns.strollers_api_pattern_save_stroller_pattern_pattern, {}, qs);
export const strollers_api_pattern_edit_stroller_pattern_url = (qs?) => url(urlpatterns.strollers_api_pattern_edit_stroller_pattern_pattern, {}, qs);
export const strollers_api_pattern_delete_stroller_pattern_url = (qs?) => url(urlpatterns.strollers_api_pattern_delete_stroller_pattern_pattern, {}, qs);
export const strollers_api_v2_comparison_load_url = (qs?) => url(urlpatterns.strollers_api_v2_comparison_load_pattern, {}, qs);
export const strollers_api_v2_comparison_toggle_url = (qs?) => url(urlpatterns.strollers_api_v2_comparison_toggle_pattern, {}, qs);
export const strollers_api_v2_comparison_remove_all_url = (qs?) => url(urlpatterns.strollers_api_v2_comparison_remove_all_pattern, {}, qs);
export const strollers_api_v2_comparison_store_ordering_url = (qs?) => url(urlpatterns.strollers_api_v2_comparison_store_ordering_pattern, {}, qs);
export const strollers_api_v2_home_url = (qs?) => url(urlpatterns.strollers_api_v2_home_pattern, {}, qs);
export const strollers_api_v2_get_brands_url = (qs?) => url(urlpatterns.strollers_api_v2_get_brands_pattern, {}, qs);
export const strollers_api_v2_chart_url = (qs?) => url(urlpatterns.strollers_api_v2_chart_pattern, {}, qs);
export const strollers_api_v2_get_strollers_url = (slug, qs?) => url(urlpatterns.strollers_api_v2_get_strollers_pattern, {slug}, qs);
export const strollers_api_v2_merge_info_url = (stroller_id, qs?) => url(urlpatterns.strollers_api_v2_merge_info_pattern, {stroller_id}, qs);
export const strollers_api_v2_merge_save_url = (qs?) => url(urlpatterns.strollers_api_v2_merge_save_pattern, {}, qs);
export const strollers_api_v2_stroller_detail_url = (slug, qs?) => url(urlpatterns.strollers_api_v2_stroller_detail_pattern, {slug}, qs);
export const strollers_api_v2_stroller_detail_variant_url = (slug, variant_id, qs?) => url(urlpatterns.strollers_api_v2_stroller_detail_variant_pattern, {slug, variant_id}, qs);
export const strollers_api_v2_stroller_load_more_similars_url = (slug, qs?) => url(urlpatterns.strollers_api_v2_stroller_load_more_similars_pattern, {slug}, qs);
export const strollers_api_v2_stroller_delete_url = (qs?) => url(urlpatterns.strollers_api_v2_stroller_delete_pattern, {}, qs);
export const strollers_api_v2_get_stroller_rename_info_url = (stroller_id, qs?) => url(urlpatterns.strollers_api_v2_get_stroller_rename_info_pattern, {stroller_id}, qs);
export const strollers_api_v2_stroller_rename_save_url = (stroller_id, qs?) => url(urlpatterns.strollers_api_v2_stroller_rename_save_pattern, {stroller_id}, qs);
export const strollers_api_v2_get_stroller_product_photos_url = (qs?) => url(urlpatterns.strollers_api_v2_get_stroller_product_photos_pattern, {}, qs);
export const strollers_api_v2_search_strollers_url = (qs?) => url(urlpatterns.strollers_api_v2_search_strollers_pattern, {}, qs);
export const strollers_api_v2_search_strollers_facets_url = (qs?) => url(urlpatterns.strollers_api_v2_search_strollers_facets_pattern, {}, qs);
export const strollers_api_v2_user_strollers_get_user_stroller_url = (qs?) => url(urlpatterns.strollers_api_v2_user_strollers_get_user_stroller_pattern, {}, qs);
export const strollers_api_v2_user_strollers_add_user_stroller_url = (qs?) => url(urlpatterns.strollers_api_v2_user_strollers_add_user_stroller_pattern, {}, qs);
export const strollers_api_v2_user_strollers_remove_user_stroller_url = (qs?) => url(urlpatterns.strollers_api_v2_user_strollers_remove_user_stroller_pattern, {}, qs);
export const strollers_api_v2_reviews_get_url = (qs?) => url(urlpatterns.strollers_api_v2_reviews_get_pattern, {}, qs);
export const strollers_api_v2_example_reviews_get_url = (qs?) => url(urlpatterns.strollers_api_v2_example_reviews_get_pattern, {}, qs);
export const strollers_api_v2_reviews_add_review_find_product_url = (qs?) => url(urlpatterns.strollers_api_v2_reviews_add_review_find_product_pattern, {}, qs);
export const strollers_api_v2_reviews_add_review_create_url = (qs?) => url(urlpatterns.strollers_api_v2_reviews_add_review_create_pattern, {}, qs);
export const strollers_api_v2_reviews_photos_get_url = (qs?) => url(urlpatterns.strollers_api_v2_reviews_photos_get_pattern, {}, qs);
export const strollers_api_v2_photo_editor_get_url = (qs?) => url(urlpatterns.strollers_api_v2_photo_editor_get_pattern, {}, qs);
export const strollers_api_v2_photo_editor_set_url = (qs?) => url(urlpatterns.strollers_api_v2_photo_editor_set_pattern, {}, qs);
export const strollers_api_v2_photo_editor_delete_url = (qs?) => url(urlpatterns.strollers_api_v2_photo_editor_delete_pattern, {}, qs);
export const strollers_api_v2_photos_edit_get_url = (qs?) => url(urlpatterns.strollers_api_v2_photos_edit_get_pattern, {}, qs);
export const strollers_api_v2_photos_edit_save_url = (qs?) => url(urlpatterns.strollers_api_v2_photos_edit_save_pattern, {}, qs);
export const strollers_api_v2_photos_edit_delete_url = (qs?) => url(urlpatterns.strollers_api_v2_photos_edit_delete_pattern, {}, qs);
export const strollers_api_v2_upload_stroller_photo_url = (qs?) => url(urlpatterns.strollers_api_v2_upload_stroller_photo_pattern, {}, qs);
export const strollers_api_v2_get_blog_articles_url = (hashtag, qs?) => url(urlpatterns.strollers_api_v2_get_blog_articles_pattern, {hashtag}, qs);
export const strollers_api_v2_eshop_exit_url = (stroller_id, stroller_eshop_product_id, qs?) => url(urlpatterns.strollers_api_v2_eshop_exit_pattern, {stroller_id, stroller_eshop_product_id}, qs);
export const strollers_api_v2_detail_edit_save_url = (stroller_id, qs?) => url(urlpatterns.strollers_api_v2_detail_edit_save_pattern, {stroller_id}, qs);
export const strollers_api_v2_detail_edit_get_url = (stroller_id, qs?) => url(urlpatterns.strollers_api_v2_detail_edit_get_pattern, {stroller_id}, qs);
