/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp6 } from './commonProp6';
import { commonProp4 } from './commonProp4';

export const underwear : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["UNDERSHIRTS", "T\u00edlko, n\u00e1t\u011bln\u00edk"],
        ["BRAS", "Podprsenka"],
        ["UNDERWEAR_BODYSUITE", "Body"],
        ["BRIEFS", "Kalhotky, slipy, boxerky"],
        ["UNDERWEAR_SET", "Souprava"],
        ["UNDERWEAR_MIX", "Mix"],
        ["THERMAL_WEAR", "Termopr\u00e1dlo a funk\u010dn\u00ed spodn\u00ed pr\u00e1dlo"],
        ["UNDERWEAR_ACCESSORIES", "Dopl\u0148ky"],
        ["UNDERWEAR_CORSET", "Korzet"],
    ],
    p_size: [
        d("<50"),
        d("50"),
        d("56"),
        d("62"),
        d("68"),
        d("74"),
        d("80"),
        d("86"),
        d("92"),
        d("98"),
        d("104"),
        d("110"),
        d("116"),
        d("122"),
        d("128"),
        d("134"),
        d("140"),
        d("146"),
        d("152"),
        d("158"),
        d("164"),
        d("170"),
        d("176"),
        d("182"),
        d("188"),
        d("XS"),
        d("S"),
        d("M"),
        d("L"),
        d("XL"),
        d("XXL"),
        d("XXXL"),
        d("4XL"),
        d("5XL"),
        d("60AA"),
        d("60A"),
        d("60B"),
        d("60C"),
        d("60D"),
        d("65AA"),
        d("65A"),
        d("65B"),
        d("65C"),
        d("65D"),
        d("70AA"),
        d("70A"),
        d("70B"),
        d("70C"),
        d("70D"),
        d("70E"),
        d("70F"),
        d("75AA"),
        d("75A"),
        d("75B"),
        d("75C"),
        d("75D"),
        d("75E"),
        d("75F"),
        d("75G"),
        d("80AA"),
        d("80A"),
        d("80B"),
        d("80C"),
        d("80D"),
        d("80E"),
        d("80F"),
        d("80G"),
        d("80H"),
        d("85AA"),
        d("85A"),
        d("85B"),
        d("85C"),
        d("85D"),
        d("85E"),
        d("85F"),
        d("85G"),
        d("85H"),
        d("85I"),
        d("90AA"),
        d("90A"),
        d("90B"),
        d("90C"),
        d("90D"),
        d("90E"),
        d("90F"),
        d("90G"),
        d("90H"),
        d("90I"),
        d("90J"),
        d("95AA"),
        d("95A"),
        d("95B"),
        d("95C"),
        d("95D"),
        d("95E"),
        d("95F"),
        d("95G"),
        d("95H"),
        d("95I"),
        d("95J"),
        d("100A"),
        d("100B"),
        d("100C"),
        d("100D"),
        d("100E"),
        d("100F"),
        d("100G"),
        d("100H"),
        d("100I"),
        d("100J"),
        d("105A"),
        d("105B"),
        d("105C"),
        d("105D"),
        d("105E"),
        d("105F"),
        d("105G"),
        d("105H"),
        d("105I"),
        d("105J"),
        d("110A"),
        d("110B"),
        d("110C"),
        d("110D"),
        d("110E"),
        d("110F"),
        d("110G"),
        d("110H"),
        d("110I"),
        d("110J"),
        d("115B"),
        d("115C"),
        d("115D"),
        d("115E"),
        d("115F"),
        d("115G"),
        d("115H"),
        d("115I"),
        d("115J"),
        d("120B"),
        d("120C"),
        d("120D"),
        d("120E"),
        d("120F"),
        d("120G"),
        d("120H"),
        d("120I"),
        d("120J"),
        d("125B"),
        d("125C"),
        d("125D"),
        d("125E"),
        d("125F"),
        d("125G"),
        d("125H"),
        d("125I"),
        d("125J"),
    ],
    p_sex: commonProp5,
    p_brand: commonProp6,
    p_color: commonProp4,
};
