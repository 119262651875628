/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp4 } from './commonProp4';

export const prams : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PRAMS_BAG", "Hlubok\u00e9"],
        ["PRAMS_ALLINONE", "Kombinovan\u00e9"],
        ["PRAMS_JOGGING", "Sportovn\u00ed"],
        ["PRAMS_BUGGY", "Golfky"],
        ["PRAMS_FOR_TWINS", "Pro dvoj\u010data, sourozence"],
    ],
    p_brand: [
        d("4baby"),
        d("4Moms"),
        d("ABC Design"),
        d("Adamex"),
        d("Adbor"),
        d("Agio"),
        d("Aneco"),
        d("Anex"),
        d("Anmar"),
        d("Aprica"),
        d("Asalvo"),
        d("Aulon"),
        d("Austlen"),
        d("Avova"),
        d("Axkid"),
        d("Bababing"),
        d("Babideal"),
        d("Baby Ace"),
        d("Baby Design"),
        d("Baby Dreams"),
        d("Baby Elegance"),
        d("Baby Fashion"),
        d("Baby Jogger"),
        d("Baby Merc"),
        d("Baby Mix"),
        d("Baby Monsters"),
        d("Baby Roues"),
        d("Baby Sky"),
        d("Baby Smile"),
        d("Baby Trend"),
        d("Baby-Coo"),
        d("BabyActive"),
        d("Babyco"),
        d("BabyGo"),
        d("Babyhome"),
        d("Babylo"),
        d("Babylux"),
        d("Babyluxe"),
        d("Babypoint"),
        d("Babystyle"),
        d("Babytravel"),
        d("Babyworld"),
        d("Babyzen"),
        d("Be cool"),
        d("Beb\u00e9 Beni"),
        d("B\u00e9b\u00e9 Confort"),
        d("B\u00e9b\u00e9car"),
        d("Bebetto"),
        d("Beemoo"),
        d("Belecoo"),
        d("Beqooni"),
        d("Bergsteiger"),
        d("Bertini"),
        d("Bertoni"),
        d("Besrey"),
        d("Bestoo"),
        d("Bexa"),
        d("Biba"),
        d("Bizzi"),
        d("Bo Jungle"),
        d("BOB"),
        d("Bobas"),
        d("Bobo-San"),
        d("Bogus"),
        d("Bohemia"),
        d("Bomiko"),
        d("Bomimi"),
        d("Brevi"),
        d("Brio"),
        d("Britax"),
        d("Britton"),
        d("Bugaboo"),
        d("Bumbleride"),
        d("Bumprider"),
        d("Cam"),
        d("Camarelo"),
        d("Camini"),
        d("Cangaroo"),
        d("Carestino"),
        d("Caretero"),
        d("Carrello"),
        d("Casualplay"),
        d("Cavoe"),
        d("CBX"),
        d("Circle by ABC Design"),
        d("Cleo"),
        d("Coletto"),
        d("Colibro"),
        d("Colugo"),
        d("Combi"),
        d("Commi"),
        d("Concord"),
        d("Concordia Design"),
        d("Coneco"),
        d("Contours"),
        d("Corvus"),
        d("Cosatto"),
        d("Coto Baby"),
        d("Crescent"),
        d("Cuggl"),
        d("Cybex"),
        d("Dada Paradiso"),
        d("Darex"),
        d("Debug"),
        d("Delta Children"),
        d("Deltim"),
        d("Dema"),
        d("Depemo"),
        d("Didibaby"),
        d("Didofy"),
        d("Diono"),
        d("Disney Baby"),
        d("Domexim"),
        d("Doona"),
        d("Dorjan"),
        d("Dubatti"),
        d("Dymex"),
        d("EasyGo"),
        d("Easywalker"),
        d("Eddie Bauer"),
        d("Edrema"),
        d("El-Jot"),
        d("Elite Design"),
        d("Elodie Details"),
        d("Emjot"),
        d("Emmaljunga"),
        d("Ergobaby"),
        d("Espiro"),
        d("Esprit"),
        d("Euro-Cart"),
        d("Eurobaby"),
        d("Evenflo"),
        d("Expander"),
        d("Familidoo"),
        d("Fillikid"),
        d("Firefly"),
        d("Firkon"),
        d("Firstwheels"),
        d("Fisher Price"),
        d("Foppapedretti"),
        d("Futura Baby"),
        d("G-Mini"),
        d("GB"),
        d("Gesslein"),
        d("Giordani"),
        d("Graco"),
        d("Greentom"),
        d("Gusio"),
        d("Guzzie+Guss"),
        d("G\u017b-\u017babik"),
        d("Haberkorn"),
        d("Hamilton"),
        d("Happy Bee"),
        d("Hartan"),
        d("Hauck"),
        d("Hesba"),
        d("Hoco"),
        d("Hot Mom"),
        d("Chic 4 Baby"),
        d("Chicco"),
        d("Childhome"),
        d("Chipolino"),
        d("ibebe"),
        d("iCandy"),
        d("Ickle Bubba"),
        d("iCoo"),
        d("Indigo"),
        d("Infababy"),
        d("Inglesina"),
        d("Insevio"),
        d("InSTEP"),
        d("Invictus"),
        d("Jackmar"),
        d("Jan\u00e9"),
        d("Jarfex"),
        d("Jasmine"),
        d("Jedo"),
        d("Jette"),
        d("Joie"),
        d("Joolz"),
        d("Joovy"),
        d("JRNY"),
        d("Junama"),
        d("Junior Jones"),
        d("Kajtex"),
        d("Karex"),
        d("Kees"),
        d("Kerttu"),
        d("Kiddo"),
        d("Kiddy"),
        d("Kinderkraft"),
        d("Kinderwagon"),
        d("Knorr Baby"),
        d("Koelstra"),
        d("Kolcraft"),
        d("Koochi"),
        d("Krasnal"),
        d("Kunert"),
        d("Lalo"),
        d("Larktale"),
        d("Leclerc"),
        d("Lib\u00e9lulle"),
        d("Lionelo"),
        d("Lokko"),
        d("Lonex"),
        d("Looping"),
        d("Lorelli"),
        d("Maclaren"),
        d("Maked"),
        d("MamaKiddies"),
        d("Mamas&Papas"),
        d("Markur"),
        d("MAST Swiss Design"),
        d("Matpol"),
        d("Maxi-Cosi"),
        d("Mee-go"),
        d("Micralite"),
        d("Mikado"),
        d("Mikrus"),
        d("Milkbe"),
        d("Milly Mally"),
        d("Milu Kids"),
        d("Mima"),
        d("Mimosa"),
        d("Moby-System"),
        d("Mockingbird"),
        d("MoMi"),
        d("Momon"),
        d("Moni"),
        d("Moon"),
        d("Mothercare"),
        d("Mountain Buggy"),
        d("Movino"),
        d("Moweo"),
        d("Mutsy"),
        d("Muuvo"),
        d("M\u00fcV"),
        d("My Babiie"),
        d("My Junior"),
        d("MyChild"),
        d("Nan\u00e1n"),
        d("Naturkind"),
        d("Navington"),
        d("Nellys"),
        d("Neonato"),
        d("Nestor"),
        d("Newbuddy"),
        d("Nikimotion"),
        d("Noordi"),
        d("Nordic Moon"),
        d("Nowalex"),
        d("Nuna"),
        d("Nurse"),
        d("Obaby"),
        d("Oilily"),
        d("Omnio"),
        d("Orbit Baby"),
        d("Osann"),
        d("Out n About"),
        d("Oxo"),
        d("Pali"),
        d("Papiwi"),
        d("Paradise Baby"),
        d("Patron"),
        d("Peg-P\u00e9rego"),
        d("Petite Ch\u00e9rie"),
        d("Petite&Mars"),
        d("Phil&Teds"),
        d("Pierre Cardin"),
        d("Play"),
        d("Prampol"),
        d("Qtus"),
        d("Quatro"),
        d("Quax"),
        d("Quinny"),
        d("Quipolo"),
        d("Raf-Pol"),
        d("Recaro"),
        d("Red Castle"),
        d("Red Kite"),
        d("Redsbaby"),
        d("Reemy"),
        d("Renolux"),
        d("Retrus"),
        d("Riko"),
        d("Roan"),
        d("Rocco"),
        d("Roma Prams"),
        d("Royal"),
        d("Ruk-Bug"),
        d("Safety 1st"),
        d("Seed"),
        d("Seka"),
        d("Shom"),
        d("Schwinn"),
        d("Silver Cross"),
        d("Sirocco"),
        d("Skyline"),
        d("Slaro"),
        d("Sojan"),
        d("Steelcraft"),
        d("Steggi"),
        d("Stokke"),
        d("Stork"),
        d("StrollAir"),
        d("Summer Baby"),
        d("Summer Infant"),
        d("Sun Baby"),
        d("Tako"),
        d("Teddy"),
        d("Teutonia"),
        d("TFK"),
        d("The First Years"),
        d("Thule"),
        d("Tianrui"),
        d("Tiny Wonders"),
        d("Top Mark"),
        d("Touragoo"),
        d("Trend"),
        d("Tuc Tuc"),
        d("Tudore"),
        d("Tutek"),
        d("Tutis"),
        d("Tutti Bambini"),
        d("Twistshake"),
        d("Uppababy"),
        d("Urbini"),
        d("Valco Baby"),
        d("Vega"),
        d("Venicci"),
        d("Venice Child"),
        d("Verdi"),
        d("Vidiamo"),
        d("Wiejar"),
        d("Winner"),
        d("X-Lander"),
        d("Xiaomi"),
        d("Zagma"),
        d("Zekiwa"),
        d("Zeta"),
        d("Zoe"),
        d("Zooper"),
        d("Zopa"),
        d("Zuma Kids"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_brand_model: [
        d("4baby Atomic"),
        d("4baby City"),
        d("4baby Cosmo"),
        d("4baby Croxx"),
        d("4baby Damrey"),
        d("4baby Everso"),
        d("4baby Flexy"),
        d("4baby Guido"),
        d("4baby Izzy"),
        d("4baby Le Caprice"),
        d("4baby Moody XXII"),
        d("4baby Moody"),
        d("4baby Quick"),
        d("4baby Rapid Premium"),
        d("4baby Rapid Unique"),
        d("4baby Rapid"),
        d("4baby Rio"),
        d("4baby Shape"),
        d("4baby Smart 2.0"),
        d("4baby Smart"),
        d("4baby Stinger"),
        d("4baby Stinger Air"),
        d("4baby Twins"),
        d("4baby Twizzy"),
        d("4baby Twizzy XXII"),
        d("4baby Wave"),
        d("4Moms Moxi"),
        d("4Moms Origami"),
        d("ABC Design 3Tec"),
        d("ABC Design 3Tec Plus"),
        d("ABC Design 3Tec Style"),
        d("ABC Design 4Tec"),
        d("ABC Design Astro"),
        d("ABC Design Avito"),
        d("ABC Design Avito Style (2016)"),
        d("ABC Design Avus"),
        d("ABC Design Cobra"),
        d("ABC Design Cobra Plus"),
        d("ABC Design Condor 4"),
        d("ABC Design Condor 4 (2017)"),
        d("ABC Design Condor 4 Style (2016)"),
        d("ABC Design Condor 4S"),
        d("ABC Design Condor Air"),
        d("ABC Design Giro"),
        d("ABC Design Chili"),
        d("ABC Design Limbo"),
        d("ABC Design Mamba"),
        d("ABC Design Mamba Plus"),
        d("ABC Design Mint"),
        d("ABC Design Okini"),
        d("ABC Design Pepper"),
        d("ABC Design Ping"),
        d("ABC Design Ping 2"),
        d("ABC Design Primo"),
        d("ABC Design Rodeo"),
        d("ABC Design Salsa 3 2017"),
        d("ABC Design Salsa 3 Style (2016)"),
        d("ABC Design Salsa 4"),
        d("ABC Design Salsa 4 Air"),
        d("ABC Design Salsa 4 Style (2016)"),
        d("ABC Design Samba"),
        d("ABC Design Takeoff"),
        d("ABC Design Tereno 4"),
        d("ABC Design Tereno air"),
        d("ABC Design Turbo 4 2017"),
        d("ABC Design Turbo 4 Style"),
        d("ABC Design Turbo 4 T"),
        d("ABC Design Turbo 4S"),
        d("ABC Design Turbo 6 2017"),
        d("ABC Design Turbo 6 Fashion"),
        d("ABC Design Turbo 6 Style"),
        d("ABC Design Turbo 6S"),
        d("ABC Design Turbo S4F"),
        d("ABC Design Vicon 4"),
        d("ABC Design Viper 3S"),
        d("ABC Design Viper 4 2017"),
        d("ABC Design Viper 4 Diamond Edition"),
        d("ABC Design Viper 4 Fashion"),
        d("ABC Design Viper 4 Style"),
        d("ABC Design Viper 4S"),
        d("ABC Design Zoom"),
        d("ABC Design Zoom 2015"),
        d("ABC Design Zoom 2017"),
        d("ABC Design Zoom Air"),
        d("ABC Design Zoom Style"),
        d("Adamex Active"),
        d("Adamex Alex"),
        d("Adamex Aspena"),
        d("Adamex Avalon"),
        d("Adamex Avator"),
        d("Adamex Avila"),
        d("Adamex Barcelona"),
        d("Adamex Barletta"),
        d("Adamex Barletta Deluxe"),
        d("Adamex Barletta Dream Collection"),
        d("Adamex Barletta New"),
        d("Adamex Barletta Paris London Collection"),
        d("Adamex Barletta Rainbow Collection"),
        d("Adamex Barletta Retro Deluxe"),
        d("Adamex Barletta World Collection"),
        d("Adamex Belissa"),
        d("Adamex Cristiano"),
        d("Adamex Dragon"),
        d("Adamex Elsa"),
        d("Adamex Emilio"),
        d("Adamex Encore"),
        d("Adamex Enduro"),
        d("Adamex Erika"),
        d("Adamex Fiori"),
        d("Adamex Flamenco"),
        d("Adamex Galactic"),
        d("Adamex Galactic Eko"),
        d("Adamex Galaxy"),
        d("Adamex Galaxy Deluxe"),
        d("Adamex Galaxy Drifting"),
        d("Adamex Galaxy Lux"),
        d("Adamex Gallo"),
        d("Adamex Giano"),
        d("Adamex Gloria"),
        d("Adamex Gustaw 2"),
        d("Adamex Champion Alu"),
        d("Adamex Champion Retro"),
        d("Adamex Champion Retro Deluxe"),
        d("Adamex Chantal"),
        d("Adamex Jetto"),
        d("Adamex Jogger"),
        d("Adamex Katrina"),
        d("Adamex Lara"),
        d("Adamex Loran"),
        d("Adamex Luciano"),
        d("Adamex Lumi Air"),
        d("Adamex Marcello"),
        d("Adamex Mars"),
        d("Adamex Massimo"),
        d("Adamex Massimo Deluxe"),
        d("Adamex Massimo Sport"),
        d("Adamex Mobi"),
        d("Adamex Monte Deluxe Carbon"),
        d("Adamex Nano"),
        d("Adamex Neon Deluxe"),
        d("Adamex Neonex"),
        d("Adamex Neonex Alfa"),
        d("Adamex Neon"),
        d("Adamex Nitro"),
        d("Adamex Nitro Deluxe"),
        d("Adamex Pajero Alu"),
        d("Adamex Panda"),
        d("Adamex Paolo"),
        d("Adamex Patrol"),
        d("Adamex Porto"),
        d("Adamex Reggio"),
        d("Adamex Reggio Retro"),
        d("Adamex Riccio"),
        d("Adamex Rimini"),
        d("Adamex Royal"),
        d("Adamex Saturion"),
        d("Adamex Saturn"),
        d("Adamex Saturn Deluxe"),
        d("Adamex Senso"),
        d("Adamex Sierra Deluxe"),
        d("Adamex Sierra Special Edition"),
        d("Adamex Sofia"),
        d("Adamex Vicco"),
        d("Adamex Vicco Carmel&Grey"),
        d("Adamex X-Trail"),
        d("Adamex York"),
        d("Adamex Young"),
        d("Adamex Zico"),
        d("Adbor Arte 3x3"),
        d("Adbor Bartolino Classic"),
        d("Adbor Bartolino SuperB"),
        d("Adbor Duo Lux"),
        d("Adbor Duo Stars"),
        d("Adbor Duo"),
        d("Adbor Fortte"),
        d("Adbor Gato"),
        d("Adbor Joy"),
        d("Adbor Marsel Classic"),
        d("Adbor Marsel PerFor Classic"),
        d("Adbor Marsel PerFor Sport"),
        d("Adbor Marsel Sport"),
        d("Adbor Mio plus"),
        d("Adbor Mio Special Edition"),
        d("Adbor Mio Standard Edition"),
        d("Adbor ML Sport"),
        d("Adbor Nemo Exclusive"),
        d("Adbor Nemo"),
        d("Adbor Oskar Sport"),
        d("Adbor Ottis"),
        d("Adbor OX-V"),
        d("Adbor OXV-3D"),
        d("Adbor Pepe"),
        d("Adbor Piuma"),
        d("Adbor Ring Duo"),
        d("Adbor Ring"),
        d("Adbor S-Line"),
        d("Adbor S-Line (2013)"),
        d("Adbor Sille"),
        d("Adbor Texas"),
        d("Adbor Tori Classic"),
        d("Adbor Tori Sport"),
        d("Adbor Vero"),
        d("Adbor XS-Line"),
        d("Adbor Zipp"),
        d("Agio Z3"),
        d("Agio Z4"),
        d("Aneco Accord"),
        d("Aneco Adventure"),
        d("Aneco Allium"),
        d("Aneco Aquilla"),
        d("Aneco Avinion"),
        d("Aneco Ballade"),
        d("Aneco Elmo"),
        d("Aneco Explorer"),
        d("Aneco Futura Ecco"),
        d("Aneco Maggini"),
        d("Aneco Senso"),
        d("Aneco Venezia"),
        d("Aneco Venezia Classic"),
        d("Aneco Virginia"),
        d("Anex Air-X"),
        d("Anex Air-Z"),
        d("Anex Classic"),
        d("Anex Cross City"),
        d("Anex Cross"),
        d("Anex e/type"),
        d("Anex l/type"),
        d("Anex m/type PRO"),
        d("Anex m/type"),
        d("Anex Quant"),
        d("Anex Sport"),
        d("Anmar Classic"),
        d("Anmar Cornella"),
        d("Anmar Eliss"),
        d("Anmar Ellina"),
        d("Anmar Espace"),
        d("Anmar Fragma"),
        d("Anmar Harmony"),
        d("Anmar Hilux"),
        d("Anmar Jennifer"),
        d("Anmar Jennifer (Lux)"),
        d("Anmar Loona"),
        d("Anmar Marsel"),
        d("Anmar Maseratti"),
        d("Anmar Rapido"),
        d("Anmar Stellina"),
        d("Anmar Viper 300"),
        d("Anmar Viper"),
        d("Anmar Zico"),
        d("Aprica Cadence"),
        d("Aprica Karoon"),
        d("Aprica Karoon Plus High Seat"),
        d("Aprica Luxuna Air"),
        d("Aprica Luxuna Comfort"),
        d("Aprica Luxuna CTS"),
        d("Aprica Luxuna Dual"),
        d("Aprica Magical Air"),
        d("Aprica Magical Air Plus High Seat"),
        d("Aprica Nano Smart"),
        d("Aprica Nelccobed Twin"),
        d("Aprica Optia"),
        d("Aprica Presto"),
        d("Aprica Smooove"),
        d("Aprica Soraria Smart Basket"),
        d("Aprica Stick"),
        d("Asalvo Am\u00e9rica Plus"),
        d("Asalvo Berlin"),
        d("Asalvo Cabin"),
        d("Asalvo C\u00e1diz"),
        d("Asalvo Confort"),
        d("Asalvo Convertible Riso"),
        d("Asalvo Convertible Two+"),
        d("Asalvo C\u00f3rcega"),
        d("Asalvo Cotton"),
        d("Asalvo Devon"),
        d("Asalvo Dinamic"),
        d("Asalvo Dinamic Doble"),
        d("Asalvo Enzo"),
        d("Asalvo Flight"),
        d("Asalvo Fox"),
        d("Asalvo Freedom"),
        d("Asalvo Genius"),
        d("Asalvo Kino"),
        d("Asalvo Leader (L\u00edder)"),
        d("Asalvo Maldivas"),
        d("Asalvo Manchester"),
        d("Asalvo Mit"),
        d("Asalvo Moby"),
        d("Asalvo MoMa Plus"),
        d("Asalvo MoMa"),
        d("Asalvo Mombi"),
        d("Asalvo Montreal"),
        d("Asalvo Moving"),
        d("Asalvo Mykonos"),
        d("Asalvo Ness"),
        d("Asalvo New York"),
        d("Asalvo River Doble"),
        d("Asalvo Roma II"),
        d("Asalvo Roma"),
        d("Asalvo Runner"),
        d("Asalvo Running"),
        d("Asalvo Tokio"),
        d("Asalvo Tribeca"),
        d("Asalvo Trotter Plus"),
        d("Asalvo Two+"),
        d("Asalvo Wimi"),
        d("Asalvo yIYI"),
        d("Asalvo Yolo"),
        d("Aulon Aiqi"),
        d("Aulon Easy Travel"),
        d("Aulon Oyun Long"),
        d("Austlen Entourage"),
        d("Avova City Ranger"),
        d("Avova Colibri"),
        d("Axkid Life"),
        d("Bababing Raffi"),
        d("Baby Ace 042"),
        d("Baby Ace Limited Edition"),
        d("Baby Ace TB503 Angel Fish"),
        d("Baby Ace TB504 Buggy"),
        d("Baby Ace TB511 Innova"),
        d("Baby Ace TB513 Aquarius"),
        d("Baby Ace TB514 Taurus"),
        d("Baby Ace TB516 Gemini"),
        d("Baby Ace TB517 Libra"),
        d("Baby Ace TS001"),
        d("Baby Ace TS003"),
        d("Baby Ace TS004"),
        d("Baby Ace TS011"),
        d("Baby Ace TS012"),
        d("Baby Ace TS042 Capricorn 6in1"),
        d("Baby Ace TS043 Scorpio"),
        d("Baby Design Bueno"),
        d("Baby Design Bueno2"),
        d("Baby Design Bunny"),
        d("Baby Design Clever"),
        d("Baby Design Click"),
        d("Baby Design Coco"),
        d("Baby Design Dotty"),
        d("Baby Design Elf"),
        d("Baby Design Enjoy"),
        d("Baby Design Handy"),
        d("Baby Design Husky"),
        d("Baby Design Husky XL"),
        d("Baby Design Look"),
        d("Baby Design Look Air"),
        d("Baby Design Look Gel"),
        d("Baby Design Lupo Comfort"),
        d("Baby Design Lupo Comfort Limited"),
        d("Baby Design Mini"),
        d("Baby Design Prego"),
        d("Baby Design Rider"),
        d("Baby Design Smart"),
        d("Baby Design Smooth"),
        d("Baby Design Sprint"),
        d("Baby Design Sprint Plus"),
        d("Baby Design Sway"),
        d("Baby Design Travel"),
        d("Baby Design Travel Quick"),
        d("Baby Design Walker"),
        d("Baby Design Walker Lite"),
        d("Baby Design Wave"),
        d("Baby Design Zoy"),
        d("Baby Dreams Buggy"),
        d("Baby Dreams Smart"),
        d("Baby Dreams Sprint"),
        d("Baby Elegance Beep Twist"),
        d("Baby Elegance Cupla Duo"),
        d("Baby Elegance Envy"),
        d("Baby Elegance Flow"),
        d("Baby Elegance iSlide"),
        d("Baby Elegance Mist"),
        d("Baby Elegance Sketch"),
        d("Baby Elegance Tux"),
        d("Baby Elegance Venti"),
        d("Baby Fashion Avatar"),
        d("Baby Fashion Fanari"),
        d("Baby Fashion Fanari Classic"),
        d("Baby Fashion Isabell"),
        d("Baby Fashion Larisa"),
        d("Baby Fashion Scarlett"),
        d("Baby Jogger City Elite"),
        d("Baby Jogger City Elite 2"),
        d("Baby Jogger City Lite"),
        d("Baby Jogger City Mini"),
        d("Baby Jogger City Mini 2"),
        d("Baby Jogger City Mini 4"),
        d("Baby Jogger City Mini Double"),
        d("Baby Jogger City Mini GT"),
        d("Baby Jogger City Mini GT Double"),
        d("Baby Jogger City Mini GT2"),
        d("Baby Jogger City Mini GT2 Double"),
        d("Baby Jogger City Mini Zip"),
        d("Baby Jogger City Mini2 4"),
        d("Baby Jogger City Mini2 Double"),
        d("Baby Jogger City Premier"),
        d("Baby Jogger City Select"),
        d("Baby Jogger City Select 2"),
        d("Baby Jogger City Select Lux"),
        d("Baby Jogger City Sights"),
        d("Baby Jogger City Tour"),
        d("Baby Jogger City Tour 2"),
        d("Baby Jogger City Tour 2 Double"),
        d("Baby Jogger City Tour Lux"),
        d("Baby Jogger City Versa"),
        d("Baby Jogger City Versa GT"),
        d("Baby Jogger F.I.T."),
        d("Baby Jogger Summit X3"),
        d("Baby Jogger Summit X3 Double"),
        d("Baby Jogger Vue"),
        d("Baby Jogger Vue Lite"),
        d("Baby Merc Agat 2"),
        d("Baby Merc Bebello"),
        d("Baby Merc Faster"),
        d("Baby Merc Faster Classic"),
        d("Baby Merc Faster Style"),
        d("Baby Merc Faster Style 2"),
        d("Baby Merc GTX"),
        d("Baby Merc Junior Twist"),
        d("Baby Merc La Noche"),
        d("Baby Merc La Rosa"),
        d("Baby Merc Leo"),
        d("Baby Merc Leo Classic"),
        d("Baby Merc Mango"),
        d("Baby Merc Maylo"),
        d("Baby Merc Maylo Classic"),
        d("Baby Merc Mosca"),
        d("Baby Merc Neo 2 Style"),
        d("Baby Merc Neo Lux"),
        d("Baby Merc Neo Style"),
        d("Baby Merc Novis"),
        d("Baby Merc Piuma"),
        d("Baby Merc Q7"),
        d("Baby Merc Q9"),
        d("Baby Merc Retro"),
        d("Baby Merc S3 Plus"),
        d("Baby Merc S7"),
        d("Baby Merc Solar 2"),
        d("Baby Merc Travel"),
        d("Baby Merc Tribece Cloud"),
        d("Baby Merc Tribece Sun"),
        d("Baby Merc Zipy Classic"),
        d("Baby Merc Zipy Q"),
        d("Baby Mix Lugo"),
        d("Baby Monsters Alaska"),
        d("Baby Monsters Compact"),
        d("Baby Monsters Compact 2.0"),
        d("Baby Monsters Easy-Twin"),
        d("Baby Monsters Easy-Twin 2.0"),
        d("Baby Monsters Easy-Twin 3.0"),
        d("Baby Monsters Easy-Twin 3.0S"),
        d("Baby Monsters Easy-Twin 3.0S Light"),
        d("Baby Monsters Easy-Twin SE"),
        d("Baby Monsters Fast"),
        d("Baby Monsters Fresh"),
        d("Baby Monsters Fresh 2.0"),
        d("Baby Monsters Fresh 3.0"),
        d("Baby Monsters Globe"),
        d("Baby Monsters Kuki"),
        d("Baby Monsters Kuki Twin"),
        d("Baby Monsters Marla"),
        d("Baby Monsters Phoenix"),
        d("Baby Monsters Premium"),
        d("Baby Monsters Premium 2.0"),
        d("Baby Roues Letour Avant"),
        d("Baby Roues Letour Avant 3"),
        d("Baby Roues Letour Avant Luxe"),
        d("Baby Roues Letour II"),
        d("Baby Roues Letour Lux II"),
        d("Baby Roues Roll & Go"),
        d("Baby Roues TRAM"),
        d("Baby Sky Barton"),
        d("Baby Sky Deimos"),
        d("Baby Sky Star"),
        d("Baby Sky Stratos"),
        d("Baby Smile Axel"),
        d("Baby Smile FX"),
        d("Baby Smile Leo"),
        d("Baby Smile Moon"),
        d("Baby Smile Neo"),
        d("Baby Smile Ontario"),
        d("Baby Smile Twister"),
        d("Baby Smile V-Max"),
        d("Baby Trend 1st Debut"),
        d("Baby Trend Calypso"),
        d("Baby Trend City Clicker"),
        d("Baby Trend City Clicker LX"),
        d("Baby Trend Convoy DX"),
        d("Baby Trend Debut Sport"),
        d("Baby Trend Encore"),
        d("Baby Trend Encore Lite"),
        d("Baby Trend Envy"),
        d("Baby Trend Envy5"),
        d("Baby Trend Euroride"),
        d("Baby Trend Expedition"),
        d("Baby Trend Expedition Double"),
        d("Baby Trend Expedition EX Double"),
        d("Baby Trend Expedition FX"),
        d("Baby Trend Expedition RG"),
        d("Baby Trend Expedition Sport"),
        d("Baby Trend Expedition Sport LX"),
        d("Baby Trend Expedition SX"),
        d("Baby Trend EZ Ride5"),
        d("Baby Trend Falcon"),
        d("Baby Trend Freestyle"),
        d("Baby Trend Go-Lite Snap Fit Sprout"),
        d("Baby Trend Go-Lite Snap-N-Grow"),
        d("Baby Trend Hayden"),
        d("Baby Trend Manta Snap Gear"),
        d("Baby Trend Monaco"),
        d("Baby Trend Navigator"),
        d("Baby Trend Navigator Lite"),
        d("Baby Trend Nexton"),
        d("Baby Trend Passport"),
        d("Baby Trend Passport Pro"),
        d("Baby Trend Quad-Flex"),
        d("Baby Trend Range"),
        d("Baby Trend Range LX"),
        d("Baby Trend Rocket"),
        d("Baby Trend Rocket SE"),
        d("Baby Trend Royal SE"),
        d("Baby Trend Shuttle"),
        d("Baby Trend Sit N' Stand Deluxe"),
        d("Baby Trend Sit N' Stand Double"),
        d("Baby Trend Sit N' Stand Double Eclipse"),
        d("Baby Trend Sit N' Stand DX"),
        d("Baby Trend Sit N' Stand Eclipse"),
        d("Baby Trend Sit N' Stand Elite"),
        d("Baby Trend Sit N' Stand Elite Double Eclipse"),
        d("Baby Trend Sit N' Stand Elite Sport"),
        d("Baby Trend Sit N' Stand LX"),
        d("Baby Trend Sit N' Stand Sport"),
        d("Baby Trend Sit N' Stand Ultra"),
        d("Baby Trend Skyview"),
        d("Baby Trend Snap-N-Go"),
        d("Baby Trend Snap-N-Go Double"),
        d("Baby Trend Snap-N-Go EX"),
        d("Baby Trend Snap-N-Go FSX"),
        d("Baby Trend Snap-N-Go FX"),
        d("Baby Trend Snap-N-Go GX"),
        d("Baby Trend Snap-N-Go Ride Along Elite"),
        d("Baby Trend Spin"),
        d("Baby Trend Stealth"),
        d("Baby Trend Tag-A-Long"),
        d("Baby Trend Travel System Moonlight"),
        d("Baby Trend Tri-Flex"),
        d("Baby Trend TriFold Mini"),
        d("Baby Trend Velocity Lite"),
        d("Baby Trend Velocity Ultra Lite"),
        d("Baby Trend Venture"),
        d("Baby Trend XCEL"),
        d("Baby Trend XCEL-R8"),
        d("Baby-Coo Marley"),
        d("Baby-Coo Walker"),
        d("BabyActive Ballerina Heritage"),
        d("BabyActive Ballerina Wiklina (Prout\u011bn\u00e1)"),
        d("BabyActive El Nino"),
        d("BabyActive Elipso"),
        d("BabyActive Exclusive"),
        d("BabyActive Chic"),
        d("BabyActive Mini-Mo"),
        d("BabyActive Mommy"),
        d("BabyActive Musse"),
        d("BabyActive Shell Eko"),
        d("BabyActive Shell Prestige"),
        d("BabyActive Sport Q"),
        d("BabyActive Trippy"),
        d("BabyActive Twinni 2019"),
        d("BabyActive Wave"),
        d("BabyActive XQ"),
        d("BabyGo 4Seasons"),
        d("BabyGo Basket"),
        d("BabyGo Micro"),
        d("BabyGo Spring"),
        d("Babyhome 4 in 1 (Vida Plus)"),
        d("Babyhome 4ever"),
        d("Babyhome Emotion"),
        d("Babyhome Vida"),
        d("Babyhome Vida Golden"),
        d("Babylo Bolt"),
        d("Babylo Brava"),
        d("Babylo Scat"),
        d("Babylo Sky"),
        d("Babylo Vector"),
        d("Babylux Alu Way"),
        d("Babylux Avaro"),
        d("Babylux Future"),
        d("Babylux King"),
        d("Babylux Largo"),
        d("Babylux Magnum"),
        d("Babylux Margaret"),
        d("Babylux Qbaro"),
        d("Babylux White Lux"),
        d("Babyluxe Buggy"),
        d("Babypoint Casper"),
        d("Babypoint Fortuna"),
        d("Babypoint Junior"),
        d("Babypoint Lotus"),
        d("Babypoint Mark"),
        d("Babypoint Njoy"),
        d("Babypoint Orion"),
        d("Babypoint Picaso"),
        d("Babypoint Polo"),
        d("Babypoint Sprinter"),
        d("Babypoint Targus"),
        d("Babypoint V3"),
        d("Babypoint V4"),
        d("Babystyle Egg"),
        d("Babystyle Egg 2"),
        d("Babystyle Egg Quail"),
        d("Babystyle EggZ"),
        d("Babystyle Hybrid"),
        d("Babystyle Hybrid 2"),
        d("Babystyle Hybrid Cabi"),
        d("Babystyle Hybrid Carryon"),
        d("Babystyle Hybrid Curve"),
        d("Babystyle Hybrid Ezyfold"),
        d("Babystyle Oyster"),
        d("Babystyle Oyster 2"),
        d("Babystyle Oyster 3"),
        d("Babystyle Oyster Atom"),
        d("Babystyle Oyster Atom 2"),
        d("Babystyle Oyster Gem s klasick\u00fdm sezen\u00edm (hammock)"),
        d("Babystyle Oyster Gem s kor\u00fdtkov\u00fdm sezen\u00edm"),
        d("Babystyle Oyster Lite"),
        d("Babystyle Oyster Max"),
        d("Babystyle Oyster Max 2"),
        d("Babystyle Oyster Pearl"),
        d("Babystyle Oyster Switch"),
        d("Babystyle Oyster Twin"),
        d("Babystyle Oyster Twin Lite"),
        d("Babystyle Oyster Zero"),
        d("Babystyle Oyster Zero Gravity"),
        d("Babystyle Prestige 2 s Active podvozkem"),
        d("Babystyle Prestige 2 s Classic podvozkem"),
        d("Babystyle Prestige 3 s Active podvozkom"),
        d("Babystyle Prestige 3 s Classic podvozkom"),
        d("Babystyle Prestige s Classic podvozkem 2016"),
        d("Babystyle Prestige s S3D podvozkem"),
        d("Babyworld Cameron"),
        d("Babyworld Classic Retro"),
        d("Babyworld Faster"),
        d("Babyworld Goldi"),
        d("Babyworld Hilton"),
        d("Babyworld Kiddy"),
        d("Babyworld Nemo"),
        d("Babyworld Paris"),
        d("Babyworld Prado Lux"),
        d("Babyworld Prestige"),
        d("Babyworld Prometheus"),
        d("Babyworld Spider"),
        d("Babyworld Viking"),
        d("Babyzen Yoyo"),
        d("Babyzen Yoyo 2"),
        d("Babyzen Yoyo+"),
        d("Babyzen Zen"),
        d("Be cool Bandit"),
        d("Be cool Bubble"),
        d("Be cool Club Twin"),
        d("Be cool Hip Hop"),
        d("Be cool Chic"),
        d("Be cool Lift"),
        d("Be cool Light"),
        d("Be cool Outback"),
        d("Be cool Pleat"),
        d("Be cool Pleat Convert"),
        d("Be cool Quantum"),
        d("Be cool Slide"),
        d("Be cool Street"),
        d("Be cool Ultimate"),
        d("Be cool Zas"),
        d("Be cool Ziszas"),
        d("Beb\u00e9 Beni 4Runner"),
        d("Beb\u00e9 Beni Coco"),
        d("Beb\u00e9 Beni Halo"),
        d("Beb\u00e9 Beni Igo"),
        d("Beb\u00e9 Beni Izzy"),
        d("B\u00e9b\u00e9 Confort Adorra"),
        d("B\u00e9b\u00e9 Confort Adorra\u00b2"),
        d("B\u00e9b\u00e9 Confort Dana"),
        d("B\u00e9b\u00e9 Confort Dana For2"),
        d("B\u00e9b\u00e9 Confort Diza"),
        d("B\u00e9b\u00e9 Confort Elea"),
        d("B\u00e9b\u00e9 Confort Gia"),
        d("B\u00e9b\u00e9 Confort High Trek"),
        d("B\u00e9b\u00e9 Confort Jaya"),
        d("B\u00e9b\u00e9 Confort Laika"),
        d("B\u00e9b\u00e9 Confort Laika\u00b2"),
        d("B\u00e9b\u00e9 Confort Lana"),
        d("B\u00e9b\u00e9 Confort Lara"),
        d("B\u00e9b\u00e9 Confort Lara\u00b2"),
        d("B\u00e9b\u00e9 Confort Loola (Full)"),
        d("B\u00e9b\u00e9 Confort Loola 3"),
        d("B\u00e9b\u00e9 Confort Loola Up"),
        d("B\u00e9b\u00e9 Confort Maia Access"),
        d("B\u00e9b\u00e9 Confort Mara"),
        d("B\u00e9b\u00e9 Confort Mila"),
        d("B\u00e9b\u00e9 Confort Mya"),
        d("B\u00e9b\u00e9 Confort New Loola (Loola 2)"),
        d("B\u00e9b\u00e9 Confort Noa"),
        d("B\u00e9b\u00e9 Confort Nova 3"),
        d("B\u00e9b\u00e9 Confort Nova 4"),
        d("B\u00e9b\u00e9 Confort Stella"),
        d("B\u00e9b\u00e9 Confort Streety"),
        d("B\u00e9b\u00e9 Confort Streety 3"),
        d("B\u00e9b\u00e9 Confort Streety Next"),
        d("B\u00e9b\u00e9 Confort Streety Plus"),
        d("B\u00e9b\u00e9 Confort Viva"),
        d("B\u00e9b\u00e9 Confort Viva Plus"),
        d("B\u00e9b\u00e9 Confort Zelia"),
        d("B\u00e9b\u00e9 Confort Zelia\u00b2"),
        d("B\u00e9b\u00e9car Hip-Hop Tech"),
        d("B\u00e9b\u00e9car Hip-Hop XL"),
        d("B\u00e9b\u00e9car Hip-Hop+"),
        d("B\u00e9b\u00e9car i-Top"),
        d("B\u00e9b\u00e9car Icon"),
        d("B\u00e9b\u00e9car Ip-Op (XL)"),
        d("B\u00e9b\u00e9car Ip-Op Classic XL"),
        d("B\u00e9b\u00e9car Ip-Op EL"),
        d("B\u00e9b\u00e9car Ip-Op EL CT"),
        d("B\u00e9b\u00e9car Ip-Op Evolution"),
        d("B\u00e9b\u00e9car Novo"),
        d("B\u00e9b\u00e9car One&Two"),
        d("B\u00e9b\u00e9car Spot"),
        d("B\u00e9b\u00e9car Spot Tech"),
        d("B\u00e9b\u00e9car Spot+"),
        d("B\u00e9b\u00e9car Stylo AP"),
        d("B\u00e9b\u00e9car Stylo APS"),
        d("B\u00e9b\u00e9car Stylo Class"),
        d("B\u00e9b\u00e9car Stylo Class+"),
        d("B\u00e9b\u00e9car Stylo XL"),
        d("B\u00e9b\u00e9car Top+1"),
        d("B\u00e9b\u00e9car Via Cross"),
        d("B\u00e9b\u00e9car Via"),
        d("Bebetto 42"),
        d("Bebetto Aquarius"),
        d("Bebetto B42 Sport"),
        d("Bebetto Bresso"),
        d("Bebetto Bresso Premium Class"),
        d("Bebetto Erness"),
        d("Bebetto Explorer"),
        d("Bebetto Fabio"),
        d("Bebetto Flavio"),
        d("Bebetto Filippo"),
        d("Bebetto Filippo 2014"),
        d("Bebetto Holland"),
        d("Bebetto Holland 2016"),
        d("Bebetto Luca 2018"),
        d("Bebetto Magelan 2014"),
        d("Bebetto Magnum"),
        d("Bebetto Murano"),
        d("Bebetto Nico"),
        d("Bebetto Nico Estilo Pro"),
        d("Bebetto Nico Plus"),
        d("Bebetto Nitello"),
        d("Bebetto Pascal"),
        d("Bebetto Pascal 2014"),
        d("Bebetto Pascal Premium Class"),
        d("Bebetto Rainbow"),
        d("Bebetto Rainbow 2015"),
        d("Bebetto Silvia"),
        d("Bebetto Solaris"),
        d("Bebetto Tito"),
        d("Bebetto Tito Premium Class"),
        d("Bebetto Torino"),
        d("Bebetto Torino 2015"),
        d("Bebetto Verturro"),
        d("Bebetto Vulcano 2017"),
        d("Beemoo Basic"),
        d("Beemoo Classic"),
        d("Beemoo Easy Fly"),
        d("Beemoo Easy Fly+"),
        d("Beemoo Easy Travel 3"),
        d("Beemoo Easy Travel 4"),
        d("Beemoo Kombi"),
        d("Beemoo L.A"),
        d("Beemoo Mini Travel"),
        d("Beemoo Move"),
        d("Beemoo Premium"),
        d("Beemoo Race Air"),
        d("Beemoo Shopper"),
        d("Beemoo Simple Travel"),
        d("Beemoo Simple Travel+"),
        d("Beemoo Sport Kombo 2"),
        d("Beemoo Swing"),
        d("Beemoo Swing+"),
        d("Beemoo Transformer"),
        d("Beemoo Travel Light"),
        d("Beemoo Travel Light Lux"),
        d("Beemoo Twin"),
        d("Belecoo 535-2"),
        d("Belecoo 535-S"),
        d("Belecoo A1"),
        d("Belecoo A380"),
        d("Belecoo A8"),
        d("Belecoo Q3"),
        d("Belecoo S01"),
        d("Belecoo S05/S06"),
        d("Belecoo X5"),
        d("Belecoo X6"),
        d("Beqooni"),
        d("Bergsteiger Capri"),
        d("Bergsteiger Milano"),
        d("Bergsteiger Rio"),
        d("Bergsteiger Venedig"),
        d("Bertini Envy"),
        d("Bertini I Ride"),
        d("Bertini X2"),
        d("Bertini X4"),
        d("Bertini X5"),
        d("Bertoni Carrera"),
        d("Bertoni Carrera 4"),
        d("Bertoni City"),
        d("Bertoni Eclipse"),
        d("Bertoni Foxy"),
        d("Bertoni iMove"),
        d("Bertoni Star"),
        d("Besrey BR-C703S"),
        d("Besrey BR-C704S"),
        d("Besrey BR-C705S"),
        d("Besrey BR-C708S"),
        d("Besrey BR-C710S"),
        d("Besrey BR-C711S"),
        d("Besrey BR-C712S"),
        d("Besrey BR-C714S"),
        d("Bestoo Amatii"),
        d("Bestoo Barcelona"),
        d("Bestoo Evino"),
        d("Bestoo Keria"),
        d("Bestoo Primo"),
        d("Bestoo Safary"),
        d("Bestoo Skyline"),
        d("Bexa Air Pro"),
        d("Bexa Air"),
        d("Bexa Classic"),
        d("Bexa Cube"),
        d("Bexa D\u2019Angela"),
        d("Bexa Fashion"),
        d("Bexa Fresh"),
        d("Bexa Chrom"),
        d("Bexa Ideal 2.0"),
        d("Bexa Ideal New"),
        d("Bexa Ideal New 2.0"),
        d("Bexa Ideal"),
        d("Bexa iX"),
        d("Bexa Light Eco"),
        d("Bexa Light"),
        d("Bexa Line 2.0"),
        d("Bexa Line"),
        d("Bexa Next Gold"),
        d("Bexa Next Silver"),
        d("Bexa Prestige Eco"),
        d("Bexa Roxy"),
        d("Bexa Royal"),
        d("Bexa Ultra New"),
        d("Bexa Ultra Style V"),
        d("Bexa Ultra Style V Amo"),
        d("Bexa Ultra Style X"),
        d("Bexa Ultra"),
        d("Biba M Double"),
        d("Biba M Single"),
        d("Bizzi Growin Buggi Lite"),
        d("Bo Jungle B-Genius"),
        d("Bo Jungle B-Lummy"),
        d("Bo Jungle B-Strolly"),
        d("Bo Jungle B-Suvvy"),
        d("BOB Alterrain"),
        d("BOB Alterrain Pro"),
        d("BOB Blaze"),
        d("BOB Ironman Duallie"),
        d("BOB Ironman"),
        d("BOB Motion 3"),
        d("BOB Motion"),
        d("BOB Rambler"),
        d("BOB Revolution CE"),
        d("BOB Revolution Flex"),
        d("BOB Revolution Flex 2.0"),
        d("BOB Revolution Flex 3.0"),
        d("BOB Revolution Flex Duallie"),
        d("BOB Revolution Flex Duallie 2.0"),
        d("BOB Revolution Flex Duallie 3.0"),
        d("BOB Revolution Flex Lunar"),
        d("BOB Revolution Flex Lunar 2.0"),
        d("BOB Revolution PRO"),
        d("BOB Revolution PRO Duallie"),
        d("BOB Revolution SE"),
        d("BOB Revolution SE Duallie"),
        d("BOB Sport Utility Stroller"),
        d("BOB Sport Utility Stroller Duallie"),
        d("BOB Stroller Strides Duallie"),
        d("BOB Stroller Strides Fitness"),
        d("Bobas Maxim"),
        d("Bobo-San 6789G"),
        d("Bobo-San 6798G"),
        d("Bogus Apollo"),
        d("Bogus Bogus 1"),
        d("Bogus Bogus 2"),
        d("Bogus Bogus 3"),
        d("Bogus Bogus 4"),
        d("Bogus Bogus 5"),
        d("Bogus Bogus 7"),
        d("Bogus Bogus 8"),
        d("Bogus Bogus B2s"),
        d("Bogus Bogus B3s"),
        d("Bogus Bogus Twin"),
        d("Bogus Jovany Lux"),
        d("Bogus Mirage Lux"),
        d("Bogus Navara"),
        d("Bogus Olimpia"),
        d("Bogus Picasso"),
        d("Bogus Pohon"),
        d("Bogus Tino"),
        d("Bohemia Cube"),
        d("Bohemia Duo"),
        d("Bohemia Eco 3v1"),
        d("Bohemia Line Classic"),
        d("Bohemia Line XXL"),
        d("Bohemia Nori"),
        d("Bohemia Uno"),
        d("Bomiko Model L"),
        d("Bomiko Model M"),
        d("Bomiko Model S"),
        d("Bomiko Model XL"),
        d("Bomiko Model XS"),
        d("Bomiko Model XS 2015"),
        d("Bomimi Amadeo"),
        d("Bomimi Bastien Air"),
        d("Bomimi Bayo"),
        d("Bomimi Befi"),
        d("Bomimi Elio"),
        d("Bomimi Elon"),
        d("Bomimi Popy"),
        d("Brevi Adon"),
        d("Brevi B.Light"),
        d("Brevi Boomerang"),
        d("Brevi Crystal"),
        d("Brevi Eolo"),
        d("Brevi Ginger 3"),
        d("Brevi Grillo 2.0"),
        d("Brevi Marathon"),
        d("Brevi Marathon Twin"),
        d("Brevi Millestrade"),
        d("Brevi Mini Large"),
        d("Brevi Molla"),
        d("Brevi Ovo Premium"),
        d("Brevi Ovo Twin"),
        d("Brevi Ovo"),
        d("Brevi Presto City"),
        d("Brevi Presto Offroad"),
        d("Brevi Scatto"),
        d("Brevi Simplo"),
        d("Brevi Start"),
        d("Brevi Verso"),
        d("Brio Double Wave"),
        d("Brio Go"),
        d("Brio Go Next"),
        d("Brio Happy"),
        d("Brio Race"),
        d("Brio Sing"),
        d("Brio Smile"),
        d("Brio Spin"),
        d("Britax Affinity"),
        d("Britax Affinity 2"),
        d("Britax B-Agile 3"),
        d("Britax B-Agile 4"),
        d("Britax B-Agile 4 Plus"),
        d("Britax B-Agile Double"),
        d("Britax B-Agile M"),
        d("Britax B-Agile R"),
        d("Britax B-Clever"),
        d("Britax B-Dual"),
        d("Britax B-Free"),
        d("Britax B-Lite"),
        d("Britax B-Lite V2"),
        d("Britax B-Lively"),
        d("Britax B-Lively Double"),
        d("Britax B-Mobile 3"),
        d("Britax B-Mobile 4"),
        d("Britax B-Motion 3"),
        d("Britax B-Motion 3 Plus"),
        d("Britax B-Motion 4"),
        d("Britax B-Motion 4 Plus"),
        d("Britax B-Ready"),
        d("Britax B-Ready 2017"),
        d("Britax B-Ready G3"),
        d("Britax B-Smart 3"),
        d("Britax B-Smart 4"),
        d("Britax Go Big"),
        d("Britax Go Big2"),
        d("Britax Go Next"),
        d("Britax Go Next2"),
        d("Britax Go"),
        d("Britax Holiday"),
        d("Britax Holiday Double"),
        d("Britax Holiday2"),
        d("Britax One2 V2"),
        d("Britax Pathway"),
        d("Britax Smile"),
        d("Britax Smile 2"),
        d("Britax Smile III"),
        d("Britax Strider M"),
        d("Britax Strider S"),
        d("Britax Verve"),
        d("Britax Vigour 3"),
        d("Britax Vigour 3+"),
        d("Britax Vigour 4"),
        d("Britax Vigour 4+"),
        d("Britton 3Style"),
        d("Britton 3Style 2"),
        d("Britton 4Style"),
        d("Britton 4Style 2"),
        d("Britton Allroad"),
        d("Britton Aura"),
        d("Britton CountryClassic"),
        d("Britton Liverpool"),
        d("Britton Metro"),
        d("Britton Shopper"),
        d("Bugaboo Ant"),
        d("Bugaboo Bee 3"),
        d("Bugaboo Bee 5"),
        d("Bugaboo Bee 6"),
        d("Bugaboo Bee+"),
        d("Bugaboo Bee"),
        d("Bugaboo Buffalo"),
        d("Bugaboo Butterfly"),
        d("Bugaboo Cameleon"),
        d("Bugaboo Cameleon 2 (Cameleon+)"),
        d("Bugaboo Cameleon 3"),
        d("Bugaboo Cameleon 3 Plus"),
        d("Bugaboo Donkey"),
        d("Bugaboo Donkey 2"),
        d("Bugaboo Donkey 3"),
        d("Bugaboo Donkey 5"),
        d("Bugaboo Fox 2"),
        d("Bugaboo Fox 3"),
        d("Bugaboo Fox"),
        d("Bugaboo Frog"),
        d("Bugaboo Gecko"),
        d("Bugaboo Lynx"),
        d("Bugaboo Runner"),
        d("Bumbleride Era"),
        d("Bumbleride Flite"),
        d("Bumbleride Flyer"),
        d("Bumbleride Indie"),
        d("Bumbleride Indie 4"),
        d("Bumbleride Indie Twin"),
        d("Bumbleride Queen B"),
        d("Bumbleride Speed"),
        d("Bumprider Connect"),
        d("Cam Agile"),
        d("Cam Combi Family"),
        d("Cam Combi Family Dolcecuore"),
        d("Cam Combi Tris"),
        d("Cam Compass 2.0"),
        d("Cam Compass"),
        d("Cam Cortina X3 Tris Evolution"),
        d("Cam Cubo"),
        d("Cam Curvi"),
        d("Cam Dinamico"),
        d("Cam Dinamico Convert"),
        d("Cam Dinamico Premium"),
        d("Cam Dinamico Up"),
        d("Cam Fletto"),
        d("Cam Flip"),
        d("Cam Fluido Allegria"),
        d("Cam Fluido City"),
        d("Cam Fluido Easy"),
        d("Cam Fluido"),
        d("Cam Giramondo"),
        d("Cam Linea Classy"),
        d("Cam Linea Sport"),
        d("Cam Matic"),
        d("Cam MB X6"),
        d("Cam Met"),
        d("Cam Microair"),
        d("Cam Min\u00fa (Teddy)"),
        d("Cam Minuetto"),
        d("Cam Portofino"),
        d("Cam Pulsar"),
        d("Cam Taski Sport"),
        d("Cam Techno"),
        d("Cam Twin Flip"),
        d("Cam Twin Pulsar"),
        d("Cam Vogue"),
        d("Cam Zen"),
        d("Camarelo Abiro"),
        d("Camarelo Alicante"),
        d("Camarelo Art"),
        d("Camarelo Art Limited"),
        d("Camarelo Avenger"),
        d("Camarelo Avenger Plus"),
        d("Camarelo Baleo"),
        d("Camarelo Canillo"),
        d("Camarelo Carera"),
        d("Camarelo Carera New"),
        d("Camarelo Carmela"),
        d("Camarelo Colorado"),
        d("Camarelo Cone"),
        d("Camarelo Dakota"),
        d("Camarelo Elf"),
        d("Camarelo Elix"),
        d("Camarelo Eos"),
        d("Camarelo Eze"),
        d("Camarelo Faro"),
        d("Camarelo Figaro"),
        d("Camarelo Granada"),
        d("Camarelo Logan"),
        d("Camarelo Lupus"),
        d("Camarelo Maggio"),
        d("Camarelo Malaga"),
        d("Camarelo Navo"),
        d("Camarelo Neso"),
        d("Camarelo Ollio"),
        d("Camarelo Picasso"),
        d("Camarelo Picco"),
        d("Camarelo Pireus"),
        d("Camarelo Piro"),
        d("Camarelo Previo"),
        d("Camarelo Q Sport"),
        d("Camarelo Q12"),
        d("Camarelo Sandero"),
        d("Camarelo Sevilla"),
        d("Camarelo Sevilla Special Collection"),
        d("Camarelo Sirion"),
        d("Camarelo Sirion Eco"),
        d("Camarelo Smile"),
        d("Camarelo Sudari"),
        d("Camarelo Valencia"),
        d("Camarelo Veo"),
        d("Camarelo Vision"),
        d("Camarelo You"),
        d("Camarelo Zeo"),
        d("Camini Frontera"),
        d("Camini Yuna"),
        d("Cangaroo Arrow"),
        d("Cangaroo Diamond"),
        d("Cangaroo Drift"),
        d("Cangaroo Easy Fold"),
        d("Cangaroo Ellada"),
        d("Cangaroo Etro"),
        d("Cangaroo Hammer"),
        d("Cangaroo Julie"),
        d("Cangaroo Lea"),
        d("Cangaroo London"),
        d("Cangaroo Luxima"),
        d("Cangaroo Luxor"),
        d("Cangaroo Macan"),
        d("Cangaroo Milan"),
        d("Cangaroo Mina"),
        d("Cangaroo Mini"),
        d("Cangaroo Njoy"),
        d("Cangaroo Noble"),
        d("Cangaroo Paris"),
        d("Cangaroo Rachel"),
        d("Cangaroo Ritmo"),
        d("Cangaroo S-Line"),
        d("Cangaroo Sarah"),
        d("Cangaroo Siri"),
        d("Cangaroo Stefanie"),
        d("Cangaroo Sunny"),
        d("Cangaroo Sunrise"),
        d("Cangaroo X-Point"),
        d("Carestino Beverly"),
        d("Carestino Beverly V2"),
        d("Carestino Capri"),
        d("Carestino Cici"),
        d("Carestino City Travel"),
        d("Carestino City V2"),
        d("Carestino Deluxe"),
        d("Carestino Deluxe V2"),
        d("Carestino Duo"),
        d("Carestino Eco"),
        d("Carestino Ferro"),
        d("Carestino Kiev"),
        d("Carestino London"),
        d("Carestino Midway"),
        d("Carestino Monaco"),
        d("Carestino Nitro"),
        d("Carestino Plus"),
        d("Carestino Rapid"),
        d("Carestino Smart"),
        d("Carestino Wave"),
        d("Caretero Alfa"),
        d("Caretero Aviator"),
        d("Caretero Colosus"),
        d("Caretero Compass"),
        d("Caretero Four"),
        d("Caretero Frii"),
        d("Caretero Gringo"),
        d("Caretero Jeans"),
        d("Caretero Luvio"),
        d("Caretero Moby"),
        d("Caretero Monaco"),
        d("Caretero Navigator"),
        d("Caretero Quick"),
        d("Caretero Sonata"),
        d("Caretero Spacer Classic"),
        d("Caretero Spacer Deluxe"),
        d("Caretero Titan"),
        d("Carrello Alfa"),
        d("Carrello Astra"),
        d("Carrello Bravo"),
        d("Carrello Bravo Plus"),
        d("Carrello Pulse"),
        d("Carrello Vista"),
        d("Carrello Vista Air"),
        d("Casualplay Avant"),
        d("Casualplay Dad\u00e1"),
        d("Casualplay Downtown 2008"),
        d("Casualplay Downtown"),
        d("Casualplay Jazz Reverse"),
        d("Casualplay Kudu 3"),
        d("Casualplay Kudu 4"),
        d("Casualplay Livi"),
        d("Casualplay Loop"),
        d("Casualplay LOOPi Allroad"),
        d("Casualplay LOOPi City"),
        d("Casualplay LOOPi Running"),
        d("Casualplay Optim"),
        d("Casualplay Playxtrem Baby Twin"),
        d("Casualplay Playxtrem Dad\u00e1"),
        d("Casualplay Playxtrem Downtown"),
        d("Casualplay Playxtrem Skyline"),
        d("Casualplay Playxtrem Vintage"),
        d("Casualplay Playxtrem WOW!"),
        d("Casualplay S4"),
        d("Casualplay S4 Reverse"),
        d("Casualplay S6"),
        d("Casualplay Skyline Beatle"),
        d("Casualplay Space"),
        d("Casualplay Stwinner"),
        d("Casualplay Tour"),
        d("Casualplay Tour Twin"),
        d("Casualplay Vintage 3 (Vintage Reverse)"),
        d("Casualplay Wend"),
        d("Cavoe Avec"),
        d("Cavoe Axo Comfort"),
        d("Cavoe Axo Style"),
        d("Cavoe Ideo"),
        d("Cavoe Moi"),
        d("Cavoe Osis"),
        d("CBX Bimisi Flex"),
        d("CBX Bimisi Pure"),
        d("CBX Cura"),
        d("CBX Etu"),
        d("CBX Etu Plus"),
        d("CBX Fides"),
        d("CBX Hora"),
        d("CBX Kody Cozy"),
        d("CBX Kody Lux"),
        d("CBX Kody Pure"),
        d("CBX Kody"),
        d("CBX Leotie Flex"),
        d("CBX Leotie Flex Lux"),
        d("CBX Leotie Lux"),
        d("CBX Leotie Pure"),
        d("CBX Lua"),
        d("CBX Misu Air"),
        d("CBX Misu"),
        d("CBX Nona"),
        d("CBX Onida Flex"),
        d("CBX Sors"),
        d("CBX Woya"),
        d("CBX Yoki"),
        d("Circle by ABC Design Catania 4"),
        d("Circle by ABC Design Como 4"),
        d("Circle by ABC Design Como 4S"),
        d("Circle by ABC Design Moving Light"),
        d("Circle by ABC Design Poppin"),
        d("Circle by ABC Design Tandem"),
        d("Circle by ABC Design Treviso 3"),
        d("Circle by ABC Design Treviso 3S"),
        d("Circle by ABC Design Treviso 4"),
        d("Circle by ABC Design Treviso 4S"),
        d("Cleo 3v1 (Lux)"),
        d("Cleo Mito Retro"),
        d("Cleo Moses Retro"),
        d("Cleo Moses"),
        d("Cleo Rita Lux"),
        d("Cleo Rita Retro"),
        d("Cleo Toledo Retro"),
        d("Cleo Toledo"),
        d("Coletto Agio"),
        d("Coletto Amico"),
        d("Coletto Astin 2020"),
        d("Coletto Austin 3"),
        d("Coletto Austin 4"),
        d("Coletto Aveo (Quattro)"),
        d("Coletto Camino"),
        d("Coletto Cosimo"),
        d("Coletto Craft"),
        d("Coletto Craft 2.0"),
        d("Coletto Dante 2013"),
        d("Coletto Dante"),
        d("Coletto Emporio 2020"),
        d("Coletto Enzo"),
        d("Coletto Enzo Twin"),
        d("Coletto Fado"),
        d("Coletto Fado FX"),
        d("Coletto Florino"),
        d("Coletto Florino Carbon Eco"),
        d("Coletto Florino New"),
        d("Coletto Giovanni"),
        d("Coletto Jazzy"),
        d("Coletto Joggy"),
        d("Coletto Joggy s nafukovac\u00edmi koly"),
        d("Coletto Joggy s p\u011bnov\u00fdmi koly"),
        d("Coletto Jokko"),
        d("Coletto Lanza"),
        d("Coletto Latina"),
        d("Coletto Marcello"),
        d("Coletto Marco Polo"),
        d("Coletto Matteo"),
        d("Coletto Maya"),
        d("Coletto Maya 2"),
        d("Coletto Maya Automatic"),
        d("Coletto Milano"),
        d("Coletto Modena"),
        d("Coletto Nevia"),
        d("Coletto Nevia VT"),
        d("Coletto Nino"),
        d("Coletto Piccolo"),
        d("Coletto Savona"),
        d("Coletto Verona"),
        d("Colibro Clip"),
        d("Colibro Focus"),
        d("Colibro Nesto"),
        d("Colibro Novel"),
        d("Colibro Onemax"),
        d("Colugo Compact Stroller"),
        d("Combi Catalyst"),
        d("Combi F2 AF"),
        d("Combi F2 Plus AF"),
        d("Combi F2"),
        d("Combi Fold N Go"),
        d("Combi Fold N Go Double"),
        d("Combi Fold N Go Double 2016"),
        d("Combi Mechacal Handy"),
        d("Combi Mechacal Handy 4AC EG"),
        d("Combi Mechacal Handy EG"),
        d("Combi Shuttle"),
        d("Commi Carello"),
        d("Commi Izzi Go"),
        d("Commi Royal"),
        d("Commi Twinny"),
        d("Commi Unico"),
        d("Commi Zapp"),
        d("Commi Zipy"),
        d("Concord Camino"),
        d("Concord Fusion"),
        d("Concord Neo"),
        d("Concord Quix"),
        d("Concord Quix.Plus"),
        d("Concord Soul"),
        d("Concord Wanderer"),
        d("Concordia Design Candy"),
        d("Concordia Design Nanotechno"),
        d("Concordia Design Prado"),
        d("Concordia Design Ypsilon Alu"),
        d("Coneco Barracuda"),
        d("Coneco Campo"),
        d("Coneco Comodo"),
        d("Coneco Elmo"),
        d("Coneco Flash"),
        d("Coneco Joker"),
        d("Coneco Juupi"),
        d("Coneco La Vita"),
        d("Coneco Mimo"),
        d("Coneco Primavera"),
        d("Coneco Primavera Prestige"),
        d("Coneco Saturn"),
        d("Coneco Toledo"),
        d("Coneco Traper"),
        d("Coneco V3"),
        d("Coneco V4"),
        d("Contours Bitsy"),
        d("Contours Bitsy Elite"),
        d("Contours Curve"),
        d("Contours Curve Double"),
        d("Contours Element"),
        d("Contours Lite"),
        d("Contours Options 3"),
        d("Contours Options 4"),
        d("Contours Options Elite"),
        d("Corvus Basic"),
        d("Corvus City Travel"),
        d("Corvus Classic"),
        d("Corvus Classic Eco"),
        d("Corvus Classic Lux"),
        d("Corvus Romantica"),
        d("Corvus Romantica Vitrail"),
        d("Corvus Travel"),
        d("Cosatto Busy"),
        d("Cosatto Busy Go"),
        d("Cosatto Cabi"),
        d("Cosatto Fly"),
        d("Cosatto Giggle"),
        d("Cosatto Giggle 2"),
        d("Cosatto Giggle 3"),
        d("Cosatto Giggle Mix"),
        d("Cosatto Giggle Quad"),
        d("Cosatto ChaCha"),
        d("Cosatto Ooba"),
        d("Cosatto Pogo"),
        d("Cosatto Shuffle"),
        d("Cosatto Supa"),
        d("Cosatto Supa 3"),
        d("Cosatto Supa Dupa"),
        d("Cosatto Supa Dupa Go"),
        d("Cosatto Supa Go"),
        d("Cosatto To & Fro"),
        d("Cosatto To & Fro Duo"),
        d("Cosatto Uwu Mix"),
        d("Cosatto Wish"),
        d("Cosatto Wonder"),
        d("Cosatto Woop"),
        d("Cosatto Woosh"),
        d("Cosatto Woosh 2"),
        d("Cosatto Woosh Double"),
        d("Cosatto Woosh XL"),
        d("Cosatto Wow 2"),
        d("Cosatto Wow Continental"),
        d("Cosatto Wow XL"),
        d("Cosatto Wowee"),
        d("Cosatto Wow"),
        d("Cosatto Yo 2"),
        d("Cosatto Yo"),
        d("Coto Baby Aprilia"),
        d("Coto Baby Blues"),
        d("Coto Baby Etna"),
        d("Coto Baby Flamenco"),
        d("Coto Baby Latina"),
        d("Coto Baby Loca"),
        d("Coto Baby Loca 2020"),
        d("Coto Baby Messina"),
        d("Coto Baby Quara"),
        d("Coto Baby Riva"),
        d("Coto Baby Rosalio"),
        d("Coto Baby Rythm"),
        d("Coto Baby Soul"),
        d("Coto Baby Soul Q"),
        d("Coto Baby Sparrow"),
        d("Coto Baby Sydney"),
        d("Coto Baby Torino"),
        d("Coto Baby Torre"),
        d("Coto Baby Tulipo"),
        d("Coto Baby Verona"),
        d("Coto Baby Verona Comfort Line"),
        d("Crescent Classic"),
        d("Crescent Comfort"),
        d("Crescent Compact 360 XT Air"),
        d("Crescent Infinity"),
        d("Crescent Trike"),
        d("Cuggl Beech"),
        d("Cuggl Birch"),
        d("Cuggl Elm"),
        d("Cuggl Hawthorn"),
        d("Cuggl Hazel"),
        d("Cuggl Larch"),
        d("Cuggl Maple"),
        d("Cuggl Willow"),
        d("Cybex Agis M-Air 3"),
        d("Cybex Agis M-Air 4"),
        d("Cybex Avi"),
        d("Cybex Balios M"),
        d("Cybex Balios S"),
        d("Cybex Balios S 2v1 2020"),
        d("Cybex Balios S Lux"),
        d("Cybex Balios S Lux 2020"),
        d("Cybex Beezy"),
        d("Cybex Callisto"),
        d("Cybex e-Priam 2019"),
        d("Cybex Eezy S"),
        d("Cybex Eezy S-Twist"),
        d("Cybex Eezy S-Twist+"),
        d("Cybex Eezy S-Twist+\u00b2"),
        d("Cybex Eezy S-Twist\u00b2"),
        d("Cybex Eezy S+"),
        d("Cybex Eezy S+\u00b2"),
        d("Cybex Eezy S\u00b2"),
        d("Cybex Eternis M3"),
        d("Cybex Eternis M4"),
        d("Cybex Gazelle S 2020"),
        d("Cybex Iris M-Air"),
        d("Cybex Libelle"),
        d("Cybex Melio"),
        d("Cybex Mios 2"),
        d("Cybex Mios 3"),
        d("Cybex Mios"),
        d("Cybex Onyx"),
        d("Cybex Priam 3"),
        d("Cybex Priam 4"),
        d("Cybex Priam se sedadlem Light Seat"),
        d("Cybex Priam se sedadlem Lux Seat"),
        d("Cybex Ruby"),
        d("Cybex Talos S 2in1"),
        d("Cybex Talos S Lux 2020"),
        d("Cybex Topaz"),
        d("Cybex Twinyx"),
        d("Dada Paradiso Apus"),
        d("Dada Paradiso Carino"),
        d("Dada Paradiso Carino ALU"),
        d("Dada Paradiso Carmelo"),
        d("Dada Paradiso Dada Denim"),
        d("Dada Paradiso Etno"),
        d("Dada Paradiso Glamour"),
        d("Dada Paradiso Leo"),
        d("Dada Paradiso Livio Deluxe"),
        d("Dada Paradiso Max 500"),
        d("Dada Paradiso Max 500 New"),
        d("Dada Paradiso Mimi"),
        d("Dada Paradiso Mimo"),
        d("Dada Paradiso Moolty"),
        d("Dada Paradiso Orchidea"),
        d("Dada Paradiso Racing"),
        d("Dada Paradiso Rocky"),
        d("Dada Paradiso Romance"),
        d("Dada Paradiso Stars"),
        d("Dada Paradiso Super Sailor"),
        d("Dada Paradiso Sweet Trip"),
        d("Dada Paradiso Vario Fun"),
        d("Dada Paradiso Watermelon"),
        d("Dada Prams Nexus"),
        d("Darex Matrix"),
        d("Debug Baby Bug"),
        d("Delta Children CX Rider lat-Fold Stroller"),
        d("Delta Children DX Side x Side Stroller"),
        d("Delta Children J is for Jeep Brand Adventure All-Terrain Jogger Stroller"),
        d("Delta Children J is for Jeep Brand Atlas AL Sport Stroller"),
        d("Delta Children J is for Jeep Brand Atlas Stroller"),
        d("Delta Children J is for Jeep Brand Cross-Country All-Terrain Jogging Stroller"),
        d("Delta Children J is for Jeep Brand Cross-Country Sport Plus Jogger"),
        d("Delta Children J is for Jeep Brand Destination Side x Side Double Ultralight Stroller"),
        d("Delta Children J is for Jeep Brand Metro Plus Stroller"),
        d("Delta Children J is for Jeep Brand Metro Stroller"),
        d("Delta Children J is for Jeep Brand North Star Stroller"),
        d("Delta Children J is for Jeep Brand Scout AL Sport Stroller"),
        d("Delta Children J is for Jeep Brand Scout Double Stroller"),
        d("Delta Children J is for Jeep Brand Scout Stroller"),
        d("Delta Children J is for Jeep Brand Ultralight Adventure Stroller"),
        d("Delta Children J is for Jeep Brand Unlimited Reversible Handle Stroller"),
        d("Delta Children Jeep Patriot Open Trails Jogger"),
        d("Delta Children Jeep Sport Utility All-Terrain Jogger"),
        d("Delta Children Jeep Sport Utility All-Terrain Stroller"),
        d("Delta Children Jeep Unlimited Range Jogger"),
        d("Delta Children Lightweight Deluxe Stroller"),
        d("Delta Children LX Plus Side by Side Double Stroller"),
        d("Delta Children LX Side by Side Stroller"),
        d("Delta Children Pearl 2 Reversible Handle Stroller"),
        d("Delta Children The Clutch"),
        d("Deltim Amelis Hugo"),
        d("Deltim Amelis Muki"),
        d("Deltim Amelis Pro"),
        d("Deltim Amelis Super Six"),
        d("Dema Duet"),
        d("Dema Pure Basic"),
        d("Dema Pure Jumper"),
        d("Dema Pure Lothus"),
        d("Dema Pure Step Easy"),
        d("Dema Star"),
        d("Dema Step Lux"),
        d("Dema Sunny A"),
        d("Depemo Aktika"),
        d("Depemo Alpiron"),
        d("Depemo Apollon 2"),
        d("Depemo Ascalona (Turran)"),
        d("Depemo Aulus"),
        d("Depemo Bolla Pera"),
        d("Depemo Diamos"),
        d("Depemo Grander Lift"),
        d("Depemo Grander Play"),
        d("Depemo Lepus Lux"),
        d("Depemo Ravio Sport"),
        d("Depemo Retro"),
        d("Depemo Timer"),
        d("Depemo Tirso"),
        d("Depemo Torero"),
        d("Depemo Trido"),
        d("Didibaby Allante"),
        d("Didibaby Clarus"),
        d("Didibaby Classic"),
        d("Didibaby Classic Alu"),
        d("Didibaby Classic Retro"),
        d("Didibaby Meriva"),
        d("Didibaby Nativa"),
        d("Didofy Lotus"),
        d("Diono d2"),
        d("Diono Flexa Lux"),
        d("Diono Flexa"),
        d("Diono Quantum"),
        d("Diono Quantum Classic"),
        d("Diono Quantum2"),
        d("Diono Traverze"),
        d("Diono Traverze Luxe"),
        d("Diono Traverze Plus"),
        d("Diono Traverze Twin"),
        d("Disney Baby Lift and Stroll Plus"),
        d("Disney Baby Saunter Sport"),
        d("Disney Baby Simple Fold"),
        d("Disney Baby Smooth Ride"),
        d("Disney Baby Umbrella Stroller"),
        d("Domexim Sweetline"),
        d("Doona"),
        d("Dorjan Astro"),
        d("Dorjan Baby Boat Specjal"),
        d("Dorjan Baby-M"),
        d("Dorjan Basic"),
        d("Dorjan Basic VIP"),
        d("Dorjan Butterfly Twin"),
        d("Dorjan Classica Butterfly"),
        d("Dorjan Classica Harmony"),
        d("Dorjan Classica Lovely"),
        d("Dorjan Creativo"),
        d("Dorjan Danny Sport 5 Twin"),
        d("Dorjan Danny Sport Baby Boat"),
        d("Dorjan Danny Sport Prestige"),
        d("Dorjan Danny Sport Prestige Basic"),
        d("Dorjan Danny Sport Prim"),
        d("Dorjan Danny Sport Prim Alu"),
        d("Dorjan Danny Sport Prim VIP"),
        d("Dorjan Danny Sport Primo"),
        d("Dorjan Danny Sport Twin"),
        d("Dorjan Dream"),
        d("Dorjan Ecru Collection"),
        d("Dorjan Evo One City"),
        d("Dorjan Linen Line Twin"),
        d("Dorjan Prestige 2 Butterfly"),
        d("Dorjan Prestige 2 Butterfly VIP"),
        d("Dorjan Prestige 2 Harmony"),
        d("Dorjan Prestige 2 Lovely"),
        d("Dorjan Prestige Ecco Linen"),
        d("Dorjan Prim Twin"),
        d("Dorjan Prim VIP"),
        d("Dorjan Prim"),
        d("Dorjan Quick"),
        d("Dorjan Quick Premium"),
        d("Dorjan Quick Sport"),
        d("Dorjan Quick Twin"),
        d("Dorjan Rocco"),
        d("Dorjan Tessa"),
        d("Dorjan Tessa Classic"),
        d("Dorjan Via"),
        d("Dorjan Vivo Twin"),
        d("Dubatti One"),
        d("Dymex Aston Len"),
        d("Dymex Aston"),
        d("Dymex Macan 2018/2019"),
        d("Dymex Macan Clasic"),
        d("Dymex Macan Eko"),
        d("Dymex Macan Eko New"),
        d("Dymex Macan"),
        d("Dymex Titan Eko"),
        d("Dymex Titan Jodla"),
        d("Dymex Titan"),
        d("Dymex Wiki Duo"),
        d("Dymex Wiki"),
        d("EasyGo 2 of US"),
        d("EasyGo Canny"),
        d("EasyGo Domino"),
        d("EasyGo Duo Comfort"),
        d("EasyGo Fusion"),
        d("EasyGo Loop"),
        d("EasyGo Milo"),
        d("EasyGo Minima"),
        d("EasyGo Minima Plus"),
        d("EasyGo Nitro"),
        d("EasyGo Optimo"),
        d("EasyGo Optimo Air"),
        d("EasyGo Quantum Air"),
        d("EasyGo Quantum Alu"),
        d("EasyGo Soul Air"),
        d("EasyGo Soul"),
        d("EasyGo Virage"),
        d("EasyGo Virage Ecco"),
        d("Easywalker Buggy"),
        d("Easywalker Buggy XS"),
        d("Easywalker Buggy+"),
        d("Easywalker Disney Buggy"),
        d("Easywalker Disney Buggy XS"),
        d("Easywalker Duo Plus"),
        d("Easywalker Harvey"),
        d("Easywalker Harvey2"),
        d("Easywalker Harvey3"),
        d("Easywalker Harvey3 Premium"),
        d("Easywalker Charley"),
        d("Easywalker Jackey"),
        d("Easywalker Jackey XL"),
        d("Easywalker Jimmey"),
        d("Easywalker June"),
        d("Easywalker Miley"),
        d("Easywalker Miley2"),
        d("Easywalker Mini"),
        d("Easywalker Mini 2019"),
        d("Easywalker Mini Buggy"),
        d("Easywalker Mini Buggy GO"),
        d("Easywalker Mini Buggy SNAP"),
        d("Easywalker Mini Buggy TURN"),
        d("Easywalker Mini Buggy XL"),
        d("Easywalker Mini Buggy XS"),
        d("Easywalker Mosey"),
        d("Easywalker Mosey+"),
        d("Easywalker Qtro Plus"),
        d("Easywalker Rudey"),
        d("Easywalker Sky"),
        d("Easywalker Sky Plus"),
        d("Easywalker Triple - Pro Troj\u010data"),
        d("Eddie Bauer Alpine 3"),
        d("Eddie Bauer Alpine 4"),
        d("Eddie Bauer Origin"),
        d("Eddie Bauer Portage Stroller"),
        d("Eddie Bauer QuadTrek"),
        d("Eddie Bauer TrailGuide Jogger"),
        d("Eddie Bauer TriTrek"),
        d("Edrema Breva"),
        d("Edrema Diverso"),
        d("Edrema iD"),
        d("Edrema Impulse"),
        d("Edrema iQ"),
        d("Edrema Maxim"),
        d("Edrema Quanto"),
        d("Edrema Vector"),
        d("Edrema Vector FX"),
        d("Edrema Vector Sense"),
        d("El-Jot Aslan"),
        d("El-Jot Belletti"),
        d("El-Jot Brics"),
        d("El-Jot Candy"),
        d("El-Jot Diuk"),
        d("El-Jot Giulietta"),
        d("El-Jot Magnetic"),
        d("El-Jot Nelly"),
        d("El-Jot Scarlett"),
        d("El-Jot Sellena"),
        d("El-Jot Severine"),
        d("El-Jot Tessa"),
        d("El-Jot Tower"),
        d("El-Jot Vesper"),
        d("Elite Design Alpina"),
        d("Elite Design Basari"),
        d("Elite Design Eve"),
        d("Elite Design Inspiro"),
        d("Elite Design Royal"),
        d("Elite Design Turismo"),
        d("Elodie Details Mondo"),
        d("Elodie Details Stockholm Stroller"),
        d("Elodie Details Stockholm Stroller 2.0"),
        d("Elodie Details Stockholm Stroller 3.0"),
        d("Emjot Black&White"),
        d("Emjot HT2-HiTreec"),
        d("Emjot Miyo Trendy"),
        d("Emmaljunga Big Star S"),
        d("Emmaljunga Big Star Supreme"),
        d("Emmaljunga Double Viking"),
        d("Emmaljunga Edge Duo City"),
        d("Emmaljunga Edge Duo Classic"),
        d("Emmaljunga Edge Duo de Luxe"),
        d("Emmaljunga Edge Duo S"),
        d("Emmaljunga Mondial Duo City"),
        d("Emmaljunga Mondial Duo Classic"),
        d("Emmaljunga Mondial Duo de Luxe"),
        d("Emmaljunga Mondial Duo S"),
        d("Emmaljunga Nitro City"),
        d("Emmaljunga NXT eTwin Ergo"),
        d("Emmaljunga NXT eTwin Flat"),
        d("Emmaljunga NXT Challenge"),
        d("Emmaljunga NXT Twin Ergo"),
        d("Emmaljunga NXT Twin Flat"),
        d("Emmaljunga NXT30"),
        d("Emmaljunga NXT60 Ergo"),
        d("Emmaljunga NXT60 Flat"),
        d("Emmaljunga NXT90 Ergo"),
        d("Emmaljunga NXT90 B"),
        d("Emmaljunga NXT90 e"),
        d("Emmaljunga NXT90 Flat"),
        d("Emmaljunga NXT90 Go Double"),
        d("Emmaljunga Ozone City"),
        d("Emmaljunga Scooter 4"),
        d("Emmaljunga Scooter 4S"),
        d("Emmaljunga Super Nitro"),
        d("Emmaljunga Super Viking"),
        d("Emmaljunga Twin Nitro"),
        d("Emmaljunga Viking"),
        d("Ergobaby 180"),
        d("Ergobaby Metro"),
        d("Ergobaby Metro+"),
        d("Espiro Active"),
        d("Espiro Art"),
        d("Espiro Atlantic"),
        d("Espiro Axel"),
        d("Espiro Bueno"),
        d("Espiro Capri"),
        d("Espiro Coco"),
        d("Espiro Energy"),
        d("Espiro Enzo Evo"),
        d("Espiro Fuel"),
        d("Espiro Galaxy"),
        d("Espiro GTX"),
        d("Espiro Husky"),
        d("Espiro Magic"),
        d("Espiro Magic New"),
        d("Espiro Magic Premium Line"),
        d("Espiro Magic Pro"),
        d("Espiro Magic Scandi"),
        d("Espiro Magic Style"),
        d("Espiro Metro"),
        d("Espiro Meyo"),
        d("Espiro Modena"),
        d("Espiro Nano"),
        d("Espiro Next Avenue"),
        d("Espiro Next Avenue Limited"),
        d("Espiro Next Flow"),
        d("Espiro Next Manhattan"),
        d("Espiro Next Manhattan 2.0"),
        d("Espiro Next Melange 2.0"),
        d("Espiro Next Silver"),
        d("Espiro Next Special Edition"),
        d("Espiro Next Up"),
        d("Espiro Next Vengo"),
        d("Espiro Next"),
        d("Espiro Nox"),
        d("Espiro Only 2022"),
        d("Espiro Only Way 2021"),
        d("Espiro Only Way 2022"),
        d("Espiro Only 2020"),
        d("Espiro Prego"),
        d("Espiro Shine"),
        d("Espiro Sonic 2016"),
        d("Espiro Sonic Air"),
        d("Espiro Sonic Gel"),
        d("Espiro Vayo"),
        d("Espiro Vector"),
        d("Espiro Vector 4 Air"),
        d("Espiro Vector 4 Lite"),
        d("Espiro Vector Pro"),
        d("Espiro Wave"),
        d("Esprit Boston S-4"),
        d("Esprit Expedition"),
        d("Esprit Explorer"),
        d("Esprit Flash"),
        d("Esprit Houston"),
        d("Esprit Orlando"),
        d("Esprit Speed Sun"),
        d("Esprit Speed Sun Duo"),
        d("Esprit Sun Duo"),
        d("Esprit Sun Plus"),
        d("Esprit Vegas"),
        d("Euro-Cart Campo"),
        d("Euro-Cart Crossline"),
        d("Euro-Cart Crox 2020"),
        d("Euro-Cart Crox"),
        d("Euro-Cart Deco"),
        d("Euro-Cart Delta"),
        d("Euro-Cart Domino"),
        d("Euro-Cart Durango"),
        d("Euro-Cart Durango Sport"),
        d("Euro-Cart Elan"),
        d("Euro-Cart Express"),
        d("Euro-Cart Ezzo"),
        d("Euro-Cart Flex"),
        d("Euro-Cart Lira 3"),
        d("Euro-Cart Lira 4"),
        d("Euro-Cart Lumina"),
        d("Euro-Cart Mori"),
        d("Euro-Cart Passo"),
        d("Euro-Cart Passo Pro"),
        d("Euro-Cart Passo Sport"),
        d("Euro-Cart Ritmo"),
        d("Euro-Cart Runner"),
        d("Euro-Cart Solo"),
        d("Euro-Cart Spin"),
        d("Euro-Cart Vivo"),
        d("Euro-Cart Volt"),
        d("Euro-Cart Volt Pro"),
        d("Eurobaby Baobao"),
        d("Eurobaby Compact"),
        d("Eurobaby E-1266 Smart"),
        d("Eurobaby Eco Swiss design"),
        d("Eurobaby FI"),
        d("Eurobaby Freestyle Eco"),
        d("Eurobaby Freestyle Twins"),
        d("Eurobaby Onyx"),
        d("Eurobaby Onyx Tandem"),
        d("Eurobaby Onyx Tandem Sport"),
        d("Eurobaby Orinoko"),
        d("Eurobaby Sorento Komfort"),
        d("Eurobaby Winner"),
        d("Evenflo Aura (Alhambra)"),
        d("Evenflo Cambridge"),
        d("Evenflo FlexLite"),
        d("Evenflo Charleston"),
        d("Evenflo Invigor8"),
        d("Evenflo Minno"),
        d("Evenflo Minno Twin"),
        d("Evenflo Pivot"),
        d("Evenflo Porta"),
        d("Evenflo Princeton"),
        d("Evenflo Sibby"),
        d("Evenflo Victory"),
        d("Evenflo Zoo Friends Stroller"),
        d("Expander Antari"),
        d("Expander Astro"),
        d("Expander Drift"),
        d("Expander Elite"),
        d("Expander Enduro"),
        d("Expander Essence"),
        d("Expander Exeo"),
        d("Expander Macco"),
        d("Expander Mondo Black Line"),
        d("Expander Mondo Ecco"),
        d("Expander Mondo Grey Line"),
        d("Expander Mondo Prime"),
        d("Expander Moya"),
        d("Expander Naomi"),
        d("Expander Ratio"),
        d("Expander Storm"),
        d("Expander Vanguard"),
        d("Expander Vivo"),
        d("Expander Vivo Military"),
        d("Expander Xenon"),
        d("Familidoo Air"),
        d("Familidoo Air Twin"),
        d("Fillikid Alex"),
        d("Fillikid Basti"),
        d("Fillikid Ben"),
        d("Fillikid Birdy"),
        d("Fillikid Cajun"),
        d("Fillikid Cruiser"),
        d("Fillikid Emma"),
        d("Fillikid Felix"),
        d("Fillikid Jan"),
        d("Fillikid Jimmy"),
        d("Fillikid Lord"),
        d("Fillikid Paul"),
        d("Fillikid Rider"),
        d("Fillikid Tim"),
        d("Fillikid Tobi"),
        d("Fillikid Tobi SL"),
        d("Fillikid Tommy"),
        d("Fillikid Tommy Plus"),
        d("Fillikid Twin"),
        d("Firefly WeGo"),
        d("Firkon Balance"),
        d("Firkon Claris"),
        d("Firkon Flipper"),
        d("Firkon Sorano"),
        d("Firkon Swing LX"),
        d("Firkon Swing LX Retro"),
        d("Firkon Vario LX"),
        d("Firkon Verano LX"),
        d("Firkon Vesta City"),
        d("Firkon Vesta Standard"),
        d("Firkon Vesta Swing"),
        d("Firkon Vesta Vario"),
        d("Firstwheels City Elite"),
        d("Firstwheels City Twin"),
        d("Fisher Price Deluxe"),
        d("Fisher Price Deluxe Stroller"),
        d("Foppapedretti 3Chic"),
        d("Foppapedretti Bikini Plus"),
        d("Foppapedretti Boarding"),
        d("Foppapedretti Ecopass"),
        d("Foppapedretti Festival"),
        d("Foppapedretti Glam"),
        d("Foppapedretti Hurr\u00e0"),
        d("Foppapedretti iWood"),
        d("Foppapedretti Myo"),
        d("Foppapedretti Myo Tronic"),
        d("Foppapedretti Passenger"),
        d("Foppapedretti Pesopiuma"),
        d("Foppapedretti Pi\u00f9leggero"),
        d("Foppapedretti Supertres"),
        d("Foppapedretti Tuo"),
        d("Futura Baby Alfa"),
        d("Futura Baby Alfa Eco"),
        d("G-Mini Easy"),
        d("G-Mini Grand"),
        d("G-Mini Hero Air Fit"),
        d("G-Mini Hero Air Max"),
        d("G-Mini Chilly"),
        d("G-Mini Kadet"),
        d("G-Mini Kadet Premium"),
        d("G-Mini Lord"),
        d("G-Mini Move"),
        d("G-Mini Multi"),
        d("G-Mini Travel"),
        d("G-Mini Travel Premium"),
        d("G-Mini Trend"),
        d("G-Mini Trend Air"),
        d("GB Air"),
        d("GB Alara"),
        d("GB Beaula"),
        d("GB Beli 4"),
        d("GB Beli Air 4"),
        d("GB Biris Air 3"),
        d("GB Biris Air 4"),
        d("GB Ellum"),
        d("GB Evoq"),
        d("GB Hardy"),
        d("GB Idol"),
        d("GB Lyfe"),
        d("GB Majik"),
        d("GB Maris 2"),
        d("GB Maris"),
        d("GB Melio"),
        d("GB Motif"),
        d("GB Pockit Air All-Terrain"),
        d("GB Pockit+ All-Terrain"),
        d("GB Pockit+ All-City"),
        d("GB Pockit"),
        d("GB Q*fold"),
        d("GB Qbit"),
        d("GB Qbit+ All-Terrain"),
        d("GB Qbit+ All-City"),
        d("GB Rokin"),
        d("GB Sila 3"),
        d("GB Sila 4"),
        d("GB T-Bar"),
        d("GB X1 Urban Runner"),
        d("GB Zero 2014"),
        d("GB Zuzu"),
        d("Gesslein F10 Air+"),
        d("Gesslein F1"),
        d("Gesslein F2"),
        d("Gesslein F3 Air+"),
        d("Gesslein F4 Air+"),
        d("Gesslein F6 Air+"),
        d("Gesslein FX4 Classic"),
        d("Gesslein FX4 Life"),
        d("Gesslein Indy Active"),
        d("Gesslein Indy Comfort (Classic)"),
        d("Gesslein Indy Sport"),
        d("Gesslein Indy Style"),
        d("Gesslein M1"),
        d("Gesslein M3"),
        d("Gesslein M4"),
        d("Gesslein M6"),
        d("Gesslein S1"),
        d("Gesslein S2"),
        d("Gesslein S3 Air+"),
        d("Gesslein S4 Air+"),
        d("Gesslein S5 2x2"),
        d("Gesslein S5 2x4"),
        d("Gesslein S5 4x4"),
        d("Gesslein S5 Reverse"),
        d("Gesslein S8 Buggy"),
        d("Gesslein Smiloo Cuby"),
        d("Gesslein Smiloo Fun"),
        d("Gesslein Smiloo Happy"),
        d("Gesslein Smiloo Happy+"),
        d("Gesslein Swift"),
        d("Gesslein Trend"),
        d("Giordani Bebeios"),
        d("Giordani Club"),
        d("Giordani Ethos"),
        d("Giordani Jolly"),
        d("Giordani Manhattan"),
        d("Giordani Metropolitan"),
        d("Giordani Metropolitan Pro"),
        d("Giordani Racer"),
        d("Giordani Seattle"),
        d("Giordani Sunset"),
        d("Graco Aire3 Click Connect"),
        d("Graco Blox"),
        d("Graco Breaze"),
        d("Graco Breaze Lite"),
        d("Graco Breaze Lite 2"),
        d("Graco Citisport Lite"),
        d("Graco Evo Avant"),
        d("Graco Evo Mini"),
        d("Graco Evo XT"),
        d("Graco Evo"),
        d("Graco FastAction Fold"),
        d("Graco FastAction Fold 2.0"),
        d("Graco FastAction Fold DLX"),
        d("Graco FastAction Fold Duo LX"),
        d("Graco FastAction Fold Jogger"),
        d("Graco FastAction Fold Jogger XT"),
        d("Graco FastAction Fold Sport"),
        d("Graco FastAction Fold Sport LX"),
        d("Graco Fusio"),
        d("Graco Jetsetter"),
        d("Graco Metro"),
        d("Graco Mirage"),
        d("Graco Mirage Plus"),
        d("Graco Modes2Grow"),
        d("Graco Mosaic"),
        d("Graco Nimbly"),
        d("Graco Quattro Tour Deluxe"),
        d("Graco Quattro Tour Duo"),
        d("Graco Quattro Tour Sport"),
        d("Graco Ready2Grow"),
        d("Graco Ready2Grow LX"),
        d("Graco Relay"),
        d("Graco Remix"),
        d("Graco RoomFor2"),
        d("Graco Stadium Duo"),
        d("Graco Symbio"),
        d("Graco Symbio B"),
        d("Graco TrailRider"),
        d("Graco Travelite"),
        d("Graco Trekko"),
        d("Graco Trekko Duo"),
        d("Graco Ultima Plus"),
        d("Graco Uno2Duo"),
        d("Greentom Upp Classic 2015"),
        d("Greentom Upp Classic 2017"),
        d("Greentom Upp Classic"),
        d("Greentom Upp Reversible 2015"),
        d("Greentom Upp Reversible"),
        d("Gusio Carerra"),
        d("Gusio Cayenne"),
        d("Gusio Florencia (Florencja)"),
        d("Gusio New Carerra"),
        d("Guzzie+Guss Connect"),
        d("Guzzie+Guss Denman"),
        d("Guzzie+Guss Oxygen"),
        d("Guzzie+Guss Twice"),
        d("G\u017b-\u017babik Galaxy"),
        d("G\u017b-\u017babik Rocer"),
        d("G\u017b-\u017babik Rocer Classic"),
        d("Hamilton One"),
        d("Happy Bee Classic"),
        d("Happy Bee Mikado"),
        d("Happy Bee Nativa"),
        d("Happy Bee Trinity"),
        d("Happy Bee Werdena"),
        d("Hartan AMG"),
        d("Hartan Avantgarde"),
        d("Hartan Bit"),
        d("Hartan Buggy iX1"),
        d("Hartan I-GO"),
        d("Hartan i-maxx"),
        d("Hartan I-Mini"),
        d("Hartan Lite XL"),
        d("Hartan Mercedes AMG"),
        d("Hartan R1"),
        d("Hartan Racer GT"),
        d("Hartan Racer GTS"),
        d("Hartan Racer GTX"),
        d("Hartan Racer S"),
        d("Hartan Skater GT"),
        d("Hartan Skater GTS"),
        d("Hartan Sky GTS"),
        d("Hartan Sky GTX"),
        d("Hartan Sprint GT"),
        d("Hartan Topline S"),
        d("Hartan Topline X"),
        d("Hartan Trio X"),
        d("Hartan Two Select"),
        d("Hartan VIP GT"),
        d("Hartan VIP GTS"),
        d("Hartan VIP GTX"),
        d("Hartan VIP XL"),
        d("Hartan Viva GTR"),
        d("Hartan Xperia"),
        d("Hartan Xperia GTX"),
        d("Hartan ZX II"),
        d("Hauck Apollo"),
        d("Hauck Apollo 2019"),
        d("Hauck Apollo 2021"),
        d("Hauck Atlantic"),
        d("Hauck Atlantic Twin"),
        d("Hauck Citi Comfortfold"),
        d("Hauck Citi Neo 3"),
        d("Hauck Citi Neo II"),
        d("Hauck Citi"),
        d("Hauck Colibri"),
        d("Hauck Colt"),
        d("Hauck Condor"),
        d("Hauck Duett 2"),
        d("Hauck Duett 3"),
        d("Hauck Duett"),
        d("Hauck Eagle 4S"),
        d("Hauck Eagle"),
        d("Hauck Fisher Price Montreal Plus"),
        d("Hauck Fisher Price Orlando"),
        d("Hauck Fisher Price Palma Plus"),
        d("Hauck Fisher Price Rio Plus"),
        d("Hauck Fisher Price Toronto 4"),
        d("Hauck Fisher Price Vancouver"),
        d("Hauck Fisher Price Venice"),
        d("Hauck Freerider"),
        d("Hauck Go-S Sun (Sun Plus)"),
        d("Hauck Go-S"),
        d("Hauck King Air"),
        d("Hauck Lacrosse"),
        d("Hauck Lift Up 3"),
        d("Hauck Lift Up 4"),
        d("Hauck Lima"),
        d("Hauck Malibu"),
        d("Hauck Malibu XL"),
        d("Hauck Manhattan"),
        d("Hauck Mars"),
        d("Hauck Maxan 3"),
        d("Hauck Maxan 3 Plus"),
        d("Hauck Maxan 4"),
        d("Hauck Maxan 4 Plus"),
        d("Hauck Miami 3"),
        d("Hauck Miami 3 SLX"),
        d("Hauck Miami 4"),
        d("Hauck Miami 4S"),
        d("Hauck Micro"),
        d("Hauck Move so Simply Set"),
        d("Hauck Orlando"),
        d("Hauck Pacific"),
        d("Hauck Pacific 3"),
        d("Hauck Pacific 4"),
        d("Hauck Palma"),
        d("Hauck Priya"),
        d("Hauck Rapid 3"),
        d("Hauck Rapid 3R"),
        d("Hauck Rapid 3R Duo"),
        d("Hauck Rapid 4"),
        d("Hauck Rapid 4D"),
        d("Hauck Rapid 4D Air"),
        d("Hauck Rapid 4R Plus"),
        d("Hauck Rapid 4S"),
        d("Hauck Rapid 4X (Disney)"),
        d("Hauck Roadster Duo SL"),
        d("Hauck Roadster Duo SLX"),
        d("Hauck Roma"),
        d("Hauck Runner"),
        d("Hauck Runner 2"),
        d("Hauck Saturn R"),
        d("Hauck Shopper"),
        d("Hauck Shopper Comfortfold"),
        d("Hauck Shopper Neo II"),
        d("Hauck Shopper SLX"),
        d("Hauck Soul"),
        d("Hauck Speed Plus"),
        d("Hauck Speed Plus S"),
        d("Hauck Speed"),
        d("Hauck Spirit"),
        d("Hauck Sport"),
        d("Hauck Sprint"),
        d("Hauck Sprint S"),
        d("Hauck Sunny"),
        d("Hauck Swift Plus"),
        d("Hauck Swift X"),
        d("Hauck Swift X Duo"),
        d("Hauck Swift"),
        d("Hauck Tango"),
        d("Hauck Torro Duo"),
        d("Hauck Torro"),
        d("Hauck Travel N Care"),
        d("Hauck Travel N Care Plus"),
        d("Hauck Turbo Deluxe"),
        d("Hauck Turbo Duo"),
        d("Hauck Turbo Plus"),
        d("Hauck Turbo"),
        d("Hauck Twister"),
        d("Hauck Uptown"),
        d("Hauck Uptown Duo"),
        d("Hauck Vegas"),
        d("Hauck Viper SLX"),
        d("Hauck Viper"),
        d("Hauck Vision X"),
        d("Hauck Walk N Care"),
        d("Hesba ClassicA"),
        d("Hesba Concepto"),
        d("Hesba Condor Coup\u00e9"),
        d("Hesba Corrado"),
        d("Hesba Chrome duo swivel chassis"),
        d("Hoco Big Foot"),
        d("Hoco Diamond"),
        d("Hoco Energy"),
        d("Hoco Scout"),
        d("Hoco Tandem Slime"),
        d("Hoco Tommy"),
        d("Hoco Tornado S"),
        d("Hoco Travel Light"),
        d("Hoco Twindem Slime"),
        d("Hoco Winner X-Sport"),
        d("Hoco Winner"),
        d("Hot Mom 360"),
        d("Hot Mom Egg"),
        d("Chic 4 Baby Boomer"),
        d("Chic 4 Baby Doppio"),
        d("Chic 4 Baby Duo"),
        d("Chic 4 Baby Leni"),
        d("Chic 4 Baby Lido"),
        d("Chic 4 Baby Linus"),
        d("Chic 4 Baby Luca"),
        d("Chic 4 Baby Luca+"),
        d("Chic 4 Baby Luna"),
        d("Chic 4 Baby Momo"),
        d("Chic 4 Baby Passo"),
        d("Chic 4 Baby Pronto"),
        d("Chic 4 Baby Robbie"),
        d("Chic 4 Baby Tano"),
        d("Chic 4 Baby Viva"),
        d("Chic 4 Baby Volare"),
        d("Chicco Activ3"),
        d("Chicco Activ3 Air"),
        d("Chicco Artic"),
        d("Chicco Best Friend"),
        d("Chicco Bravo"),
        d("Chicco Bravo For 2"),
        d("Chicco Bravo For 2 LE"),
        d("Chicco Bravo LE"),
        d("Chicco Bravo Primo"),
        d("Chicco Capri"),
        d("Chicco Cortina"),
        d("Chicco Cortina Together"),
        d("Chicco Echo Twin"),
        d("Chicco Echo"),
        d("Chicco Enjoy Fun"),
        d("Chicco For Me"),
        d("Chicco Goody Plus"),
        d("Chicco I-Move"),
        d("Chicco Kwik.One"),
        d("Chicco Liteway"),
        d("Chicco Liteway 2"),
        d("Chicco Liteway 3"),
        d("Chicco Liteway Plus"),
        d("Chicco Living"),
        d("Chicco Living Smart"),
        d("Chicco London"),
        d("Chicco London Up"),
        d("Chicco LOVEmotion"),
        d("Chicco Miinimo"),
        d("Chicco Mini Bravo"),
        d("Chicco Mini Bravo Plus"),
        d("Chicco Mini Bravo Sport"),
        d("Chicco Multiride"),
        d("Chicco Multiway"),
        d("Chicco Multiway Evo"),
        d("Chicco MyCity"),
        d("Chicco Ohlal\u00e0"),
        d("Chicco Ohlal\u00e02"),
        d("Chicco Ohlal\u00e03"),
        d("Chicco Piccolo"),
        d("Chicco S3"),
        d("Chicco SimpliCity"),
        d("Chicco SimpliCity Plus"),
        d("Chicco Snappy"),
        d("Chicco Sprint"),
        d("Chicco StyleGo"),
        d("Chicco Tour 4"),
        d("Chicco TRE"),
        d("Chicco Trolley Me"),
        d("Chicco Urban"),
        d("Chicco Urban Plus"),
        d("Chicco Urban Plus Crossover"),
        d("Chicco Viaro"),
        d("Childhome Buggy 5"),
        d("Childhome Quadruple"),
        d("Childhome Sixseater"),
        d("Childhome Triplet"),
        d("Childhome Two by Two"),
        d("Childhome Urbanista"),
        d("Chipolino Aldo"),
        d("Chipolino Angel"),
        d("Chipolino April"),
        d("Chipolino Ava"),
        d("Chipolino Avenue"),
        d("Chipolino Avia"),
        d("Chipolino Breeze"),
        d("Chipolino Brillo"),
        d("Chipolino Camea"),
        d("Chipolino Carolina"),
        d("Chipolino Clarice"),
        d("Chipolino Classy"),
        d("Chipolino Coco"),
        d("Chipolino Dakota"),
        d("Chipolino Delta"),
        d("Chipolino Desta"),
        d("Chipolino Duo Smart"),
        d("Chipolino Electra"),
        d("Chipolino Elite"),
        d("Chipolino Emoji"),
        d("Chipolino Emotion"),
        d("Chipolino Ergo"),
        d("Chipolino Estelle"),
        d("Chipolino Etro"),
        d("Chipolino Everly"),
        d("Chipolino Exte"),
        d("Chipolino Fama"),
        d("Chipolino Ferrara"),
        d("Chipolino Fusion"),
        d("Chipolino Fusion Convertible"),
        d("Chipolino Havana"),
        d("Chipolino Iris"),
        d("Chipolino Lumia"),
        d("Chipolino Malta"),
        d("Chipolino Malta Convertible"),
        d("Chipolino Maxi Mix"),
        d("Chipolino Mika"),
        d("Chipolino Mika Convertible"),
        d("Chipolino Milo"),
        d("Chipolino Mixie"),
        d("Chipolino Motto"),
        d("Chipolino Move On"),
        d("Chipolino Nana"),
        d("Chipolino New Twin Duo"),
        d("Chipolino Nicole"),
        d("Chipolino Nina"),
        d("Chipolino Nina Convertible"),
        d("Chipolino Noah"),
        d("Chipolino Noby"),
        d("Chipolino Noma"),
        d("Chipolino Onyx"),
        d("Chipolino Optima"),
        d("Chipolino Oreo"),
        d("Chipolino Paris"),
        d("Chipolino Passo Doble"),
        d("Chipolino Perfecta"),
        d("Chipolino Piruet"),
        d("Chipolino Pooky"),
        d("Chipolino Pooky Travel"),
        d("Chipolino Prema"),
        d("Chipolino Primavera"),
        d("Chipolino Rimini"),
        d("Chipolino Rio Bueno"),
        d("Chipolino Ritmo"),
        d("Chipolino Sisi"),
        d("Chipolino Sofia"),
        d("Chipolino Tandem"),
        d("Chipolino Tempo"),
        d("Chipolino Terra"),
        d("Chipolino Tesoro"),
        d("Chipolino Trendy"),
        d("Chipolino Tria"),
        d("Chipolino Tria Convertible"),
        d("Chipolino Twix"),
        d("Chipolino Unica"),
        d("Chipolino Up&Down"),
        d("Chipolino Up&Down Convertible"),
        d("Chipolino Vibe"),
        d("Chipolino Vivi"),
        d("Chipolino Zeta"),
        d("ibebe i-stop"),
        d("ibebe i-stop Mini Mi2"),
        d("iCandy Apple"),
        d("iCandy Apple 2 Pear"),
        d("iCandy Core"),
        d("iCandy for Land Rover"),
        d("iCandy Cherry"),
        d("iCandy Lime"),
        d("iCandy New Peach"),
        d("iCandy New Strawberry"),
        d("iCandy Orange"),
        d("iCandy Orange 3"),
        d("iCandy Peach"),
        d("iCandy Peach 2"),
        d("iCandy Peach 3"),
        d("iCandy Peach 7"),
        d("iCandy Peach All-Terrain"),
        d("iCandy Peach All-Terrain Forest"),
        d("iCandy Peach Jogger"),
        d("iCandy Pear"),
        d("iCandy Raspberry"),
        d("iCandy Raspberry New (2018)"),
        d("iCandy Strawberry"),
        d("iCandy Strawberry 2"),
        d("iCandy Strawberry Style Collection"),
        d("Ickle Bubba Aston Rose"),
        d("Ickle Bubba Aurora"),
        d("Ickle Bubba Discovery"),
        d("Ickle Bubba Eclipse"),
        d("Ickle Bubba Globe"),
        d("Ickle Bubba Gravity"),
        d("Ickle Bubba Moon"),
        d("Ickle Bubba Stomp"),
        d("Ickle Bubba Stomp V2"),
        d("Ickle Bubba Stomp V3"),
        d("Ickle Bubba Stomp V4"),
        d("Ickle Bubba Venus"),
        d("iCoo Acrobat"),
        d("iCoo Acrobat XL"),
        d("iCoo Pace"),
        d("iCoo Pacific"),
        d("iCoo Peak 3 Air"),
        d("iCoo Peak 3"),
        d("iCoo Peak 4 Air"),
        d("iCoo Phoenix"),
        d("iCoo Photon"),
        d("iCoo Pico 3"),
        d("iCoo Pico 4"),
        d("iCoo Pico 7"),
        d("iCoo Pii"),
        d("iCoo Pluto"),
        d("iCoo Primo"),
        d("Indigo Caiman"),
        d("Indigo Clipper"),
        d("Indigo Marco"),
        d("Infababy Duo"),
        d("Infababy Duo-Elite"),
        d("Infababy Duo-Lite"),
        d("Infababy Duo-Sport"),
        d("Infababy Evo"),
        d("Infababy Ezeego"),
        d("Infababy Flo"),
        d("Infababy Halo"),
        d("Infababy Moto"),
        d("Infababy Primo"),
        d("Infababy Ultimo"),
        d("Inglesina Aptica"),
        d("Inglesina Aptica XT"),
        d("Inglesina Avio"),
        d("Inglesina Blink"),
        d("Inglesina Classica"),
        d("Inglesina Domino Triple"),
        d("Inglesina Domino Twin"),
        d("Inglesina Electa 2022"),
        d("Inglesina Espresso"),
        d("Inglesina Magnum"),
        d("Inglesina Maior"),
        d("Inglesina Net"),
        d("Inglesina Otutto Deluxe"),
        d("Inglesina Quad"),
        d("Inglesina Quad XT"),
        d("Inglesina Quid"),
        d("Inglesina Quid Vespa"),
        d("Inglesina Quid2"),
        d("Inglesina Sketch"),
        d("Inglesina Sofia"),
        d("Inglesina Swift"),
        d("Inglesina Trilogy"),
        d("Inglesina Trilogy City"),
        d("Inglesina Trilogy Plus"),
        d("Inglesina Trip"),
        d("Inglesina Twin Sketch"),
        d("Inglesina Twin Swift"),
        d("Inglesina Vittoria s podvozkem Comfort"),
        d("Inglesina Vittoria s podvozkem Ergo Bike"),
        d("Inglesina Zippy"),
        d("Inglesina Zippy Evo"),
        d("Inglesina Zippy Free"),
        d("Inglesina Zippy Light"),
        d("Inglesina Zippy Pro"),
        d("Insevio Dolphin"),
        d("InSTEP Grand Safari"),
        d("Invictus V-Plus"),
        d("Invictus V-Plus 2.0"),
        d("Invictus V-Pram"),
        d("Invictus V-Print"),
        d("Jackmar Raptor"),
        d("Jackmar Spiro"),
        d("Jackmar Vajper"),
        d("Jackmar Vajper 2"),
        d("Jackmar Vajper 3"),
        d("Jan\u00e9 Aero"),
        d("Jan\u00e9 Carrera"),
        d("Jan\u00e9 Carrera Anniversario"),
        d("Jan\u00e9 Cross Reverse"),
        d("Jan\u00e9 Crosslight"),
        d("Jan\u00e9 Crosswalk"),
        d("Jan\u00e9 Crosswalk Convert"),
        d("Jan\u00e9 Crosswalk R"),
        d("Jan\u00e9 Energy"),
        d("Jan\u00e9 Epic"),
        d("Jan\u00e9 Challenger"),
        d("Jan\u00e9 Kawai"),
        d("Jan\u00e9 Kendo"),
        d("Jan\u00e9 Minnum"),
        d("Jan\u00e9 Muum Buggy"),
        d("Jan\u00e9 Muum V1"),
        d("Jan\u00e9 Muum V2"),
        d("Jan\u00e9 Muum"),
        d("Jan\u00e9 Nanuq Twin"),
        d("Jan\u00e9 Nanuq XL"),
        d("Jan\u00e9 Nanuq"),
        d("Jan\u00e9 Nomad"),
        d("Jan\u00e9 Powertwin"),
        d("Jan\u00e9 Powertwin Pro"),
        d("Jan\u00e9 Rider"),
        d("Jan\u00e9 Rocket 2"),
        d("Jan\u00e9 Rocket"),
        d("Jan\u00e9 Slalom Pro"),
        d("Jan\u00e9 Slalom Reverse"),
        d("Jan\u00e9 Solo Reverse"),
        d("Jan\u00e9 Solo"),
        d("Jan\u00e9 Sonic Evo"),
        d("Jan\u00e9 Sonic"),
        d("Jan\u00e9 Touring"),
        d("Jan\u00e9 Trider Extreme"),
        d("Jan\u00e9 Trider"),
        d("Jan\u00e9 Twin Two"),
        d("Jan\u00e9 Twinlink"),
        d("Jan\u00e9 Twone"),
        d("Jan\u00e9 Unlimit"),
        d("Jarfex Basic Collection"),
        d("Jarfex Classic Style"),
        d("Jarfex Classic Style Limited"),
        d("Jarfex Handmade Collection"),
        d("Jarfex Paris Style"),
        d("Jarfex Sheashell Collection"),
        d("Jarfex Sport Collection"),
        d("Jasmine Bennetta"),
        d("Jasmine Bennetta Classic"),
        d("Jasmine Bennetta Duo"),
        d("Jasmine Bennetta Soft"),
        d("Jasmine Bennetta Soft Limited Edition"),
        d("Jasmine Camino"),
        d("Jasmine Camino Classic"),
        d("Jasmine Camino Vintage"),
        d("Jasmine Camino Vintage Soft"),
        d("Jasmine Daisy Classic"),
        d("Jasmine Daisy Soft"),
        d("Jasmine Prime"),
        d("Jedo Bartatina"),
        d("Jedo Bartatina Alu"),
        d("Jedo Fyn 4DS"),
        d("Jedo Fyn Duo 2017"),
        d("Jedo Fyn"),
        d("Jedo Koda 2017"),
        d("Jedo Lark"),
        d("Jedo Nevo"),
        d("Jedo Tamel 2017"),
        d("Jedo Trim"),
        d("Jette Jackson"),
        d("Jette Jacob"),
        d("Jette Jason"),
        d("Jette Jasper"),
        d("Jette Jazz"),
        d("Jette Jeff"),
        d("Jette Jeremy"),
        d("Jette Jeronimo"),
        d("Jette Jesse"),
        d("Jette Jimmy"),
        d("Jette Joel Air"),
        d("Jette Joel"),
        d("Jette Joker"),
        d("Jette Jonathan"),
        d("Jette Joseph"),
        d("Joie Aeria"),
        d("Joie Aire Lite"),
        d("Joie Aire LX"),
        d("Joie Aire Skip"),
        d("Joie Aire Twin"),
        d("Joie Brisk LX"),
        d("Joie Brisk"),
        d("Joie Crosster"),
        d("Joie Crosster Flex Signature"),
        d("Joie Evalite Duo"),
        d("Joie Extoura"),
        d("Joie Finiti"),
        d("Joie Float"),
        d("Joie Chrome DLX"),
        d("Joie Chrome"),
        d("Joie Juva"),
        d("Joie Kixx"),
        d("Joie Litetrax 3"),
        d("Joie Litetrax 4"),
        d("Joie Litetrax 4 Air"),
        d("Joie Litetrax 4 DLX"),
        d("Joie Litetrax 4 Flex LFC"),
        d("Joie Litetrax 4 Flex Signature"),
        d("Joie Litetrax 4 S"),
        d("Joie Litetrax E"),
        d("Joie Mirus"),
        d("Joie Muze (LX)"),
        d("Joie Mytrax Flex"),
        d("Joie Mytrax"),
        d("Joie Nitro LX"),
        d("Joie Nitro"),
        d("Joie Pact Flex LFC"),
        d("Joie Pact Flex Signature"),
        d("Joie Pact Lite"),
        d("Joie Pact"),
        d("Joie Tourist"),
        d("Joie Versatrax"),
        d("Joolz Aer"),
        d("Joolz Bloom"),
        d("Joolz Day+"),
        d("Joolz Day2"),
        d("Joolz Day3"),
        d("Joolz Day"),
        d("Joolz Geo2"),
        d("Joolz Geo3"),
        d("Joolz Geo"),
        d("Joolz Hub+"),
        d("Joolz Hub"),
        d("Joolz One Prime (One Grand)"),
        d("Joovy Balloon"),
        d("Joovy Big Caboose Graphite"),
        d("Joovy Caboose Graphite"),
        d("Joovy Caboose Ultralight Graphite"),
        d("Joovy Groove Ultralight"),
        d("Joovy New Groove"),
        d("Joovy Qool"),
        d("Joovy ScooterX2"),
        d("Joovy TooQool"),
        d("Joovy TwinGroove Ultralight"),
        d("Joovy TwinRoo+"),
        d("Joovy Zoom 360 Ultralight"),
        d("JRNY Meta"),
        d("Junama Air"),
        d("Junama Baby Heaven"),
        d("Junama Candy"),
        d("Junama Caro"),
        d("Junama City"),
        d("Junama Clermont"),
        d("Junama Corona Lite"),
        d("Junama Corona Strik"),
        d("Junama Corona Strik 4K"),
        d("Junama Cosatto"),
        d("Junama Diamond"),
        d("Junama Diamond Duo"),
        d("Junama Diamond Duo Slim"),
        d("Junama Diamond Individual"),
        d("Junama Diamond S Line"),
        d("Junama Diamond S Line Duo"),
        d("Junama Diamond S Line Duo Slim"),
        d("Junama Duo II"),
        d("Junama Duo II Slim"),
        d("Junama Duo"),
        d("Junama Enzo Duo"),
        d("Junama Enzo Duo Slim"),
        d("Junama Enzo"),
        d("Junama Exclusive"),
        d("Junama Fashion Pro"),
        d("Junama Fluo Duo"),
        d("Junama Fluo Duo II"),
        d("Junama Fluo Line"),
        d("Junama Fluo Line II"),
        d("Junama Fluo Slim"),
        d("Junama Fluo Slim II"),
        d("Junama Geographic"),
        d("Junama Geographic Duo Slim"),
        d("Junama Glitter"),
        d("Junama Glow"),
        d("Junama Impulse Arctic"),
        d("Junama Impulse Carbon"),
        d("Junama Impulse Colors"),
        d("Junama Impulse Eco"),
        d("Junama Impulse Chess"),
        d("Junama Impulse Chrome Design"),
        d("Junama Impulse Len"),
        d("Junama Impulse Marinero"),
        d("Junama Impulse Motostyle"),
        d("Junama Impulse News"),
        d("Junama Impulse Original Design"),
        d("Junama Impulse Street"),
        d("Junama Impulse Timber"),
        d("Junama Individual Duo"),
        d("Junama Individual Duo Slim"),
        d("Junama Invictus V-Plus"),
        d("Junama Madena"),
        d("Junama Madena Classic"),
        d("Junama Madena Duo"),
        d("Junama Madena Duo Slim"),
        d("Junama Merino"),
        d("Junama Mirror"),
        d("Junama Mirror Satin"),
        d("Junama Onyx"),
        d("Junama Paris Line"),
        d("Junama Saphire"),
        d("Junama Space"),
        d("Junama Speed Colors"),
        d("Junama Speed Chrom"),
        d("Junama Sport"),
        d("Junama Street 2K"),
        d("Junama Street 4K"),
        d("Junama Sunline Premium"),
        d("Junama Superstar"),
        d("Junama Termo Line"),
        d("Junama Vegan"),
        d("Junior Jones J-Carbon"),
        d("Junior Jones J-Cub"),
        d("Junior Jones J-Spirit"),
        d("Junior Jones J-Tourer"),
        d("Kajtex Cometa"),
        d("Kajtex Fashion"),
        d("Kajtex Luxus"),
        d("Kajtex Marina"),
        d("Kajtex Matrix"),
        d("Kajtex Navi Plus"),
        d("Kajtex Senator"),
        d("Kajtex Sprint Duo"),
        d("Kajtex Stylo"),
        d("Kajtex Tramonto"),
        d("Kajtex Vertigo"),
        d("Karex Allivio"),
        d("Karex Dynamic"),
        d("Karex Kamil"),
        d("Karex Legro"),
        d("Karex Quero"),
        d("Karex Sportive"),
        d("Karex Twing"),
        d("Karex Vallero"),
        d("Kees 2WIN"),
        d("Kees Aero"),
        d("Kees Buggy Loop"),
        d("Kees K2Go Deluxe"),
        d("Kees K2Go Plus"),
        d("Kees K2Go"),
        d("Kees Shuttle Combi"),
        d("Kees Side by Side"),
        d("Kees Tandem Deluxe"),
        d("Kees Tandem Loop 2Go"),
        d("Kees Twin K2 Plus"),
        d("Kees Xinn Plus Kekk"),
        d("Kees Xinn Twin Kekk"),
        d("Kerttu Aris"),
        d("Kerttu Bera"),
        d("Kerttu Hubi"),
        d("Kerttu K2"),
        d("Kerttu Lirdo"),
        d("Kerttu Lugo"),
        d("Kerttu Mea"),
        d("Kerttu Twist"),
        d("Kerttu Twist Gold"),
        d("Kerttu Volq"),
        d("Kiddo Breeze"),
        d("Kiddo Cruze 2.0"),
        d("Kiddo Jazz"),
        d("Kiddo Juke"),
        d("Kiddo Leaf"),
        d("Kiddo Travel"),
        d("Kiddo Twingo"),
        d("Kiddo Wave"),
        d("Kiddo Zipper"),
        d("Kiddo Zipper Duo"),
        d("Kiddy City \u2019n Move"),
        d("Kiddy Click \u2019n Move 1"),
        d("Kiddy Click \u2019n Move 2"),
        d("Kiddy Click \u2019n Move 3"),
        d("Kiddy Evocity 1"),
        d("Kiddy Evoglide 1"),
        d("Kiddy Evostar 1"),
        d("Kiddy Evostar Light 1"),
        d("Kiddy Urban Star 1"),
        d("Kinderkraft All Road"),
        d("Kinderkraft Askoy"),
        d("Kinderkraft B-Tour"),
        d("Kinderkraft Cruiser"),
        d("Kinderkraft Cruiser LX"),
        d("Kinderkraft Everyday"),
        d("Kinderkraft Evolution"),
        d("Kinderkraft Grande"),
        d("Kinderkraft Grande City"),
        d("Kinderkraft Grande LX"),
        d("Kinderkraft Grande Plus"),
        d("Kinderkraft Indy"),
        d("Kinderkraft Indy 2"),
        d("Kinderkraft Ivy"),
        d("Kinderkraft Juli"),
        d("Kinderkraft Kraft 5"),
        d("Kinderkraft Kraft 6"),
        d("Kinderkraft Lite"),
        d("Kinderkraft Lite Up"),
        d("Kinderkraft Mini Dot"),
        d("Kinderkraft Moov"),
        d("Kinderkraft Nea"),
        d("Kinderkraft Nubi"),
        d("Kinderkraft Pilot"),
        d("Kinderkraft Prime"),
        d("Kinderkraft Prime 2019"),
        d("Kinderkraft Rest"),
        d("Kinderkraft Route"),
        d("Kinderkraft Siesta 2016"),
        d("Kinderkraft Trig"),
        d("Kinderkraft Veo"),
        d("Kinderkraft Vesto"),
        d("Kinderkraft XMoov"),
        d("Kinderwagon Hop"),
        d("Kinderwagon Jump"),
        d("Kinderwagon Metro"),
        d("Kinderwagon Skip"),
        d("Kinderwagon Vie"),
        d("Knorr Baby Alive"),
        d("Knorr Baby Apart"),
        d("Knorr Baby Berlin"),
        d("Knorr Baby Buggy Carbon Art"),
        d("Knorr Baby Buggy Wood Art"),
        d("Knorr Baby Classic Premium"),
        d("Knorr Baby Classico"),
        d("Knorr Baby Commo"),
        d("Knorr Baby Crosser Melange"),
        d("Knorr Baby Dear Baby"),
        d("Knorr Baby Dune"),
        d("Knorr Baby Dynamik"),
        d("Knorr Baby For You"),
        d("Knorr Baby Harmony"),
        d("Knorr Baby Head Sportbuggy"),
        d("Knorr Baby Head Sportkombi"),
        d("Knorr Baby Joggy Novo Active"),
        d("Knorr Baby Joggy S"),
        d("Knorr Baby Joggy S Happy Colour"),
        d("Knorr Baby Joggy S Melange"),
        d("Knorr Baby Joggy S s p\u011bnou pln\u011bn\u00fdmi koly"),
        d("Knorr Baby K-One"),
        d("Knorr Baby Ladena"),
        d("Knorr Baby Life+"),
        d("Knorr Baby Lumi Lum"),
        d("Knorr Baby Noxxter"),
        d("Knorr Baby Piquetto"),
        d("Knorr Baby Precioso"),
        d("Knorr Baby Side by Side"),
        d("Knorr Baby Sportime"),
        d("Knorr Baby Streeter"),
        d("Knorr Baby Styler"),
        d("Knorr Baby V-Easy Fold"),
        d("Knorr Baby V-Easy Fold Happy Colour"),
        d("Knorr Baby V-Easy Fold Melange"),
        d("Knorr Baby Vamo S"),
        d("Knorr Baby Vero"),
        d("Knorr Baby Vero XL"),
        d("Knorr Baby Voletto"),
        d("Knorr Baby Voletto Carryo"),
        d("Knorr Baby Volkswagen Compact"),
        d("Knorr Baby Volkswagen Convert"),
        d("Knorr Baby Volkswagen Golf"),
        d("Knorr Baby Volkswagen Golf Buggy"),
        d("Knorr Baby Volkswagen GTI"),
        d("Knorr Baby Volkswagen GTI Buggy"),
        d("Knorr Baby X-Easy Fold"),
        d("Knorr Baby Yuu"),
        d("Knorr Baby Zoomix"),
        d("Koelstra Binque Daily"),
        d("Koelstra Latinique"),
        d("Koelstra Mambo Daily"),
        d("Koelstra Simba T4"),
        d("Koelstra Simba Twin T4"),
        d("Koelstra Twiggy T3"),
        d("Kolcraft All Terrain Adventure"),
        d("Kolcraft Cloud"),
        d("Kolcraft Cloud Double"),
        d("Kolcraft Cloud Plus"),
        d("Kolcraft Cloud Side-by-Side"),
        d("Kolcraft Cloud Sport"),
        d("Kolcraft Sprint Pro"),
        d("Kolcraft Sprint X"),
        d("Koochi Modhero"),
        d("Koochi Pushmatic"),
        d("Koochi Sneaker"),
        d("Koochi Speedstar"),
        d("Krasnal Angel"),
        d("Krasnal BabyFil"),
        d("Krasnal Bavario"),
        d("Krasnal Bavario White"),
        d("Krasnal Bavario+"),
        d("Krasnal CityGO"),
        d("Krasnal Coral"),
        d("Krasnal Diamant"),
        d("Krasnal Fiorino"),
        d("Krasnal Hugo"),
        d("Krasnal i-xeo"),
        d("Krasnal Nexxo"),
        d("Krasnal Nexxo Black"),
        d("Krasnal Nexxo Mineral"),
        d("Krasnal Opal"),
        d("Krasnal PeiX"),
        d("Krasnal Polaris"),
        d("Krasnal Polaris 2014"),
        d("Krasnal Polaris Lux"),
        d("Krasnal Polaris Retro"),
        d("Krasnal Saturn 2"),
        d("Krasnal Sport GT"),
        d("Krasnal Szymek Lux"),
        d("Krasnal Szymek Lux Black"),
        d("Krasnal Szymek Lux Silver"),
        d("Krasnal Szymek New"),
        d("Krasnal Wiking"),
        d("Kunert Booster Light 2019"),
        d("Kunert Calypso"),
        d("Kunert Deep"),
        d("Kunert Foxter"),
        d("Kunert Gold"),
        d("Kunert Lavado"),
        d("Kunert Lazzio"),
        d("Kunert Libero"),
        d("Kunert Master"),
        d("Kunert Mata"),
        d("Kunert Mila Premium"),
        d("Kunert Mila"),
        d("Kunert Molto"),
        d("Kunert Romantic"),
        d("Kunert Romantic Exclusive"),
        d("Kunert Rotax"),
        d("Kunert Silver"),
        d("Kunert Silver2"),
        d("Kunert Sting"),
        d("Kunert Talisman"),
        d("Kunert Talisman 2019"),
        d("Kunert Tiaro"),
        d("Kunert Turne"),
        d("Kunert Verino"),
        d("Kunert VIP"),
        d("Kunert Volver"),
        d("Lalo The Daily"),
        d("Lalo Volver"),
        d("Larktale Autofold"),
        d("Larktale Coast"),
        d("Larktale Chit Chat"),
        d("Larktale Chit Chat Plus"),
        d("Larktale Stroller Wagon"),
        d("Larktale The Daily"),
        d("Leclerc Hexagon"),
        d("Leclerc Influencer"),
        d("Leclerc Magicfold Plus"),
        d("Lib\u00e9lulle Kiko\u00e9 2"),
        d("Lib\u00e9lulle Kiko\u00e9 3"),
        d("Lib\u00e9lulle Kiko\u00e9 Twin 2.0"),
        d("Lib\u00e9lulle Linoa"),
        d("Lib\u00e9lulle Linoa 2.0"),
        d("Lib\u00e9lulle Natt"),
        d("Lib\u00e9lulle Trinity 1"),
        d("Lib\u00e9lulle Trinity 2"),
        d("Lionelo Alexia"),
        d("Lionelo Amber"),
        d("Lionelo Annet Plus"),
        d("Lionelo Annet Tour"),
        d("Lionelo Annet Tour Set"),
        d("Lionelo Bell"),
        d("Lionelo Dani"),
        d("Lionelo Elia"),
        d("Lionelo Elise"),
        d("Lionelo Emma Plus"),
        d("Lionelo Greet"),
        d("Lionelo Irma"),
        d("Lionelo Julie"),
        d("Lionelo Julie One"),
        d("Lionelo Lea"),
        d("Lionelo Liv"),
        d("Lionelo Mari"),
        d("Lionelo Mika"),
        d("Lionelo Natt 2020"),
        d("Lionelo Riya"),
        d("Lionelo Zoey"),
        d("Lokko Arida"),
        d("Lokko Bis"),
        d("Lokko Prim"),
        d("Lokko Trinity 2"),
        d("Lonex Atlantic Ecco"),
        d("Lonex Carozza"),
        d("Lonex City Light"),
        d("Lonex Classic"),
        d("Lonex Classic Ecco"),
        d("Lonex Classic Retro"),
        d("Lonex Comfort"),
        d("Lonex Comfort Carello"),
        d("Lonex Comfort Galaxy"),
        d("Lonex Comfort Prestige"),
        d("Lonex Comfort Special"),
        d("Lonex Cosmo"),
        d("Lonex Eko Pro"),
        d("Lonex Eleganto"),
        d("Lonex Emotion XT"),
        d("Lonex First"),
        d("Lonex Julia Baronessa"),
        d("Lonex King"),
        d("Lonex Parilla"),
        d("Lonex Pastel"),
        d("Lonex Pax"),
        d("Lonex Prim"),
        d("Lonex Retro Garden"),
        d("Lonex Retro Len"),
        d("Lonex Sanremo"),
        d("Lonex Speedy Ecco"),
        d("Lonex Speedy V Light"),
        d("Lonex Speedy V Light Ecco"),
        d("Lonex Speedy V Light Velure"),
        d("Lonex Speedy Verts"),
        d("Lonex Sport II"),
        d("Lonex Sport"),
        d("Lonex Sweet Baby"),
        d("Lonex Vogue"),
        d("Looping Squizz"),
        d("Looping Squizz 2"),
        d("Looping Squizz 3"),
        d("Looping Vogue"),
        d("Lorelli Adria"),
        d("Lorelli Aero"),
        d("Lorelli Alba"),
        d("Lorelli Alexa"),
        d("Lorelli Angel"),
        d("Lorelli Apollo"),
        d("Lorelli Arizona"),
        d("Lorelli Aster"),
        d("Lorelli Aurora"),
        d("Lorelli Boston"),
        d("Lorelli Calibra3"),
        d("Lorelli California"),
        d("Lorelli Carrera"),
        d("Lorelli Carrera 4"),
        d("Lorelli City"),
        d("Lorelli Combi"),
        d("Lorelli Cruise"),
        d("Lorelli Crysta"),
        d("Lorelli Daisy"),
        d("Lorelli Duo"),
        d("Lorelli Eclipse"),
        d("Lorelli Evo"),
        d("Lorelli Fantasy"),
        d("Lorelli Fiesta"),
        d("Lorelli Fiona"),
        d("Lorelli Fiorano"),
        d("Lorelli Flash"),
        d("Lorelli Foxy"),
        d("Lorelli Glory"),
        d("Lorelli Heaven"),
        d("Lorelli Helena"),
        d("Lorelli Ida"),
        d("Lorelli iMove"),
        d("Lorelli Infinity"),
        d("Lorelli Innes"),
        d("Lorelli Kara"),
        d("Lorelli Light"),
        d("Lorelli Lora"),
        d("Lorelli Lumina"),
        d("Lorelli Luna"),
        d("Lorelli Martina"),
        d("Lorelli Mia"),
        d("Lorelli Monza3"),
        d("Lorelli Monza3 s nafukovac\u00edmi koly"),
        d("Lorelli Myla"),
        d("Lorelli Onyx"),
        d("Lorelli Patrizia"),
        d("Lorelli Ramona"),
        d("Lorelli Rimini"),
        d("Lorelli Rimini Premium"),
        d("Lorelli Rio"),
        d("Lorelli S100"),
        d("Lorelli S200"),
        d("Lorelli S300"),
        d("Lorelli S500"),
        d("Lorelli S700"),
        d("Lorelli Savana"),
        d("Lorelli Sena"),
        d("Lorelli Smarty"),
        d("Lorelli Sola"),
        d("Lorelli Sport"),
        d("Lorelli Squizz 3"),
        d("Lorelli Star"),
        d("Lorelli Starlight"),
        d("Lorelli Storm"),
        d("Lorelli Terra"),
        d("Lorelli Toledo"),
        d("Lorelli Trek"),
        d("Lorelli Twin"),
        d("Lorelli Verso"),
        d("Lorelli Vista"),
        d("Maclaren Atom"),
        d("Maclaren BMW Buggy"),
        d("Maclaren BMW M"),
        d("Maclaren BMW Twin"),
        d("Maclaren Daytripper"),
        d("Maclaren Globetrotter"),
        d("Maclaren Grand Tour Lite"),
        d("Maclaren Grand Tour LX (GT LX)"),
        d("Maclaren Major Elite"),
        d("Maclaren Mark II"),
        d("Maclaren Mark II Recline"),
        d("Maclaren Quest"),
        d("Maclaren Quest Arc"),
        d("Maclaren Sherpa"),
        d("Maclaren Techno Arc"),
        d("Maclaren Techno XLR"),
        d("Maclaren Techno XLR 2018"),
        d("Maclaren Techno XT"),
        d("Maclaren Triumph"),
        d("Maclaren Twin Techno"),
        d("Maclaren Twin Triumph"),
        d("Maclaren Volo"),
        d("Maked Classic"),
        d("Maked Classic ML"),
        d("Maked eLLo"),
        d("Maked Lovell"),
        d("Maked Lucky"),
        d("Maked Lulu"),
        d("Maked Nemo"),
        d("Maked Speed"),
        d("Maked Tolo"),
        d("Maked Volo"),
        d("MamaKiddies Aqua"),
        d("MamaKiddies Baby Jogger"),
        d("MamaKiddies Blue Jeans"),
        d("MamaKiddies Carbon Exclusive"),
        d("MamaKiddies CityKing"),
        d("MamaKiddies Discovery"),
        d("MamaKiddies Dream"),
        d("MamaKiddies Experience"),
        d("MamaKiddies Explorer"),
        d("MamaKiddies Falcon"),
        d("MamaKiddies Fox"),
        d("MamaKiddies Frodo"),
        d("MamaKiddies Go-Go"),
        d("MamaKiddies Grand"),
        d("MamaKiddies Grand Prestige"),
        d("MamaKiddies Challenger"),
        d("MamaKiddies Charlie"),
        d("MamaKiddies IRun"),
        d("MamaKiddies Ismart"),
        d("MamaKiddies Jam"),
        d("MamaKiddies Light4 Go"),
        d("MamaKiddies Lux Creativ"),
        d("MamaKiddies Luxury"),
        d("MamaKiddies Marshal"),
        d("MamaKiddies Matti Flowers"),
        d("MamaKiddies Mignon"),
        d("MamaKiddies Moon"),
        d("MamaKiddies Nana"),
        d("MamaKiddies Neo"),
        d("MamaKiddies Prado Lux"),
        d("MamaKiddies Pr\u00e9mium Baby"),
        d("MamaKiddies Pr\u00e9mium BabyBee"),
        d("MamaKiddies Pr\u00e9mium Plus"),
        d("MamaKiddies Prestige"),
        d("MamaKiddies Prometheus"),
        d("MamaKiddies Skylove"),
        d("MamaKiddies Sleek"),
        d("MamaKiddies Sleek Plus"),
        d("MamaKiddies Smartly"),
        d("MamaKiddies Sun"),
        d("MamaKiddies Twin"),
        d("MamaKiddies Twin Jam"),
        d("Mamas&Papas Acro"),
        d("Mamas&Papas Airo"),
        d("Mamas&Papas Argo"),
        d("Mamas&Papas Armadillo"),
        d("Mamas&Papas Armadillo City"),
        d("Mamas&Papas Armadillo City 2"),
        d("Mamas&Papas Armadillo Flip"),
        d("Mamas&Papas Armadillo Flip XT"),
        d("Mamas&Papas Armadillo Flip XT 2"),
        d("Mamas&Papas Armadillo Flip XT 3"),
        d("Mamas&Papas Armadillo Sport"),
        d("Mamas&Papas Armadillo Twin"),
        d("Mamas&Papas Armadillo XT"),
        d("Mamas&Papas Cruise"),
        d("Mamas&Papas Cruise Twin"),
        d("Mamas&Papas Luna"),
        d("Mamas&Papas Luna Mix"),
        d("Mamas&Papas Mylo"),
        d("Mamas&Papas Mylo 2"),
        d("Mamas&Papas Ocarro"),
        d("Mamas&Papas Ora 3"),
        d("Mamas&Papas Ora 4"),
        d("Mamas&Papas Pliko"),
        d("Mamas&Papas Pulse +"),
        d("Mamas&Papas Rubix"),
        d("Mamas&Papas Sola"),
        d("Mamas&Papas Sola 2"),
        d("Mamas&Papas Sola 2 MTX"),
        d("Mamas&Papas Sola City"),
        d("Mamas&Papas Strada"),
        d("Mamas&Papas Swirl"),
        d("Mamas&Papas Swirl 2"),
        d("Mamas&Papas Tolo"),
        d("Mamas&Papas Tour"),
        d("Mamas&Papas Tour 2"),
        d("Mamas&Papas Tour 3"),
        d("Mamas&Papas Trek"),
        d("Mamas&Papas Trek 2"),
        d("Mamas&Papas Urbo"),
        d("Mamas&Papas Urbo 2"),
        d("Mamas&Papas Urbo Bug"),
        d("Mamas&Papas Voyage"),
        d("Mamas&Papas Voyage Twin"),
        d("Mamas&Papas Ziko Frankie"),
        d("Mamas&Papas Ziko Herbie"),
        d("Mamas&Papas Zoom"),
        d("Markur Matti"),
        d("Markur Matti Aqarele"),
        d("Markur Matti Classic"),
        d("Markur Matti Flowers"),
        d("Markur Zoom"),
        d("MAST Swiss Design M.2"),
        d("MAST Swiss Design M.2x"),
        d("MAST Swiss Design M.3"),
        d("MAST Swiss Design M.3x"),
        d("MAST Swiss Design M.4"),
        d("MAST Swiss Design M.4x"),
        d("Matpol Baby Car"),
        d("Matpol Enio"),
        d("Matpol Ibiza"),
        d("Matpol MatiSport"),
        d("Matpol Twin"),
        d("Maxi-Cosi Adorra"),
        d("Maxi-Cosi Adorra\u00b2"),
        d("Maxi-Cosi Dana"),
        d("Maxi-Cosi Dana For2"),
        d("Maxi-Cosi Diza"),
        d("Maxi-Cosi Elea"),
        d("Maxi-Cosi Foray"),
        d("Maxi-Cosi Gia"),
        d("Maxi-Cosi Jaya"),
        d("Maxi-Cosi Kaia"),
        d("Maxi-Cosi Laika"),
        d("Maxi-Cosi Laika\u00b2"),
        d("Maxi-Cosi Lara"),
        d("Maxi-Cosi Lara\u00b2"),
        d("Maxi-Cosi Leona"),
        d("Maxi-Cosi Leona\u00b2"),
        d("Maxi-Cosi Lila"),
        d("Maxi-Cosi Lila CP"),
        d("Maxi-Cosi Lila SP"),
        d("Maxi-Cosi Lila XP"),
        d("Maxi-Cosi Lila XP Plus"),
        d("Maxi-Cosi Loola (Full)"),
        d("Maxi-Cosi Loola 3"),
        d("Maxi-Cosi Mara"),
        d("Maxi-Cosi MatiSport"),
        d("Maxi-Cosi Maxi-Taxi"),
        d("Maxi-Cosi Mila"),
        d("Maxi-Cosi Mura 3"),
        d("Maxi-Cosi Mura 4"),
        d("Maxi-Cosi Mura Plus 3"),
        d("Maxi-Cosi Mura Plus 4"),
        d("Maxi-Cosi New Loola (Loola 2)"),
        d("Maxi-Cosi Noa"),
        d("Maxi-Cosi Nova 3"),
        d("Maxi-Cosi Nova 4"),
        d("Maxi-Cosi Plaza+"),
        d("Maxi-Cosi Stella"),
        d("Maxi-Cosi Street"),
        d("Maxi-Cosi Street+"),
        d("Maxi-Cosi Streety by B\u00e9b\u00e9 Confort"),
        d("Maxi-Cosi Streety Plus"),
        d("Maxi-Cosi Zelia"),
        d("Maxi-Cosi Zelia S"),
        d("Maxi-Cosi Zelia\u00b2"),
        d("Maxi-Cosi Zelia\u00b3"),
        d("Mee-go Feather"),
        d("Mee-go Glide"),
        d("Mee-go Inspire"),
        d("Mee-go Kangaroo"),
        d("Mee-go Milano Classic"),
        d("Mee-go Milano Special Edition"),
        d("Mee-go Milano Sport"),
        d("Mee-go Pramette"),
        d("Mee-go Trio"),
        d("Mee-go Zelia\u00b2"),
        d("Micralite FastFold"),
        d("Micralite ProFold"),
        d("Micralite Super-lie Classic"),
        d("Micralite Super-lite"),
        d("Micralite Toro"),
        d("Micralite Trio"),
        d("Micralite Twofold"),
        d("Mikado Aston"),
        d("Mikado Boston"),
        d("Mikado CityStar"),
        d("Mikado Comfort"),
        d("Mikado Duet Lux"),
        d("Mikado Duet"),
        d("Mikado Lullaby"),
        d("Mikado Luxor"),
        d("Mikado Luxor eko"),
        d("Mikado Milano"),
        d("Mikado Twin"),
        d("Mikrus Avinion"),
        d("Mikrus Comodo"),
        d("Mikrus Cross"),
        d("Mikrus Cruiser"),
        d("Mikrus Daily"),
        d("Mikrus Genua"),
        d("Mikrus Hugo"),
        d("Mikrus Plaudi II"),
        d("Mikrus Premium"),
        d("Mikrus Safari Cross"),
        d("Mikrus Speed"),
        d("Mikrus Stars II"),
        d("Mikrus Stelvio"),
        d("Mikrus Stelvio Eco"),
        d("Mikrus Tokyo"),
        d("Mikrus Venezia"),
        d("Mikrus Venezia II"),
        d("Mikrus Viva"),
        d("Milkbe Lullaby"),
        d("Milkbe Twofold"),
        d("Milly Mally Joker"),
        d("Milly Mally Meteor"),
        d("Milly Mally Rider"),
        d("Milly Mally Royal"),
        d("Milly Mally Venezia II"),
        d("Milly Mally Venus"),
        d("Milly Mally VIP"),
        d("Milu Kids Atteso"),
        d("Milu Kids Atteso Duo"),
        d("Milu Kids Atteso Ledo"),
        d("Milu Kids Bacio"),
        d("Milu Kids Berlinetta"),
        d("Milu Kids Castello"),
        d("Milu Kids Castello Forever"),
        d("Milu Kids Como"),
        d("Milu Kids Como X"),
        d("Milu Kids Fiori"),
        d("Milu Kids Giro Play"),
        d("Milu Kids Gold (Modern)"),
        d("Milu Kids Gold (Starlet)"),
        d("Milu Kids Modern"),
        d("Milu Kids Sesto"),
        d("Milu Kids Starlet"),
        d("Milu Kids Starlet Chrom"),
        d("Milu Kids Starlet New"),
        d("Milu Kids Starlet Next"),
        d("Milu Kids Starlet Plus"),
        d("Milu Kids Starlet Special Edition"),
        d("Milu Kids VIP"),
        d("Milu Kids Vivaio"),
        d("Mima Bo"),
        d("Mima Creo"),
        d("Mima Kobi 2G"),
        d("Mima Kobi"),
        d("Mima Vivaio"),
        d("Mima Xari 2012"),
        d("Mima Xari 2015"),
        d("Mima Xari Sport"),
        d("Mima Xari"),
        d("Mima Zigi 2017"),
        d("Mima Zigi"),
        d("Moby-System Almond"),
        d("Moby-System Barton"),
        d("Moby-System Camden"),
        d("Moby-System Zigi 3G"),
        d("Mockingbird Camden"),
        d("Mockingbird Stroller"),
        d("MoMi Estelle"),
        d("MoMi Estelle Dakar"),
        d("MoMi Marvin"),
        d("MoMi Ofra"),
        d("Momon After 53"),
        d("Momon After 59"),
        d("Momon After 59 ALondra"),
        d("Momon B.After"),
        d("Momon Diplomatica"),
        d("Momon Le Beb\u00e8"),
        d("Momon Mini.After"),
        d("Momon Nan\u00e1n"),
        d("Momon Smartie"),
        d("Momon Stroller"),
        d("Momon Tiramis\u00f9"),
        d("Momon Tourinot"),
        d("Momon Vernuji"),
        d("Momon Vernuji 2.0"),
        d("Moni Alma"),
        d("Moni Ciara"),
        d("Moni Gala"),
        d("Moni Geona"),
        d("Moni Gigi"),
        d("Moni Midas"),
        d("Moni Polly"),
        d("Moni Rome"),
        d("Moni Sindy"),
        d("Moni Sofie"),
        d("Moni Trento"),
        d("Moni Veyron"),
        d("Moon Cool"),
        d("Moon Flac"),
        d("Moon Gio Edition +"),
        d("Moon Jet-R"),
        d("Moon Jet"),
        d("Moon Kiss"),
        d("Moon Lusso"),
        d("Moon No ONE 2.0"),
        d("Moon No. One"),
        d("Moon Nuova Air"),
        d("Moon Nuova"),
        d("Moon Relaxx Basic"),
        d("Moon Relaxx Edition"),
        d("Moon ReSea S"),
        d("Moon ReSea S Basic"),
        d("Moon ReSea S Edition"),
        d("Moon ReSea S Edition +"),
        d("Moon ReSea Sport"),
        d("Moon ReSea"),
        d("Moon Rocca Basic"),
        d("Moon Rocca Edition"),
        d("Moon Rocca Edition +"),
        d("Moon Scala"),
        d("Moon SL"),
        d("Moon Solitaire"),
        d("Moon Star"),
        d("Moon Style"),
        d("Moon Tregg"),
        d("Mothercare 3-wheel Journey"),
        d("Mothercare 4-wheel Journey"),
        d("Mothercare Amble"),
        d("Mothercare Genie"),
        d("Mothercare Jive"),
        d("Mothercare Nanu"),
        d("Mothercare Nanu Plus (Nanu+)"),
        d("Mothercare Orb"),
        d("Mothercare Ride"),
        d("Mothercare Roam"),
        d("Mothercare Roll"),
        d("Mothercare Tregg"),
        d("Mothercare Xpedior 3-wheel"),
        d("Mothercare Xpedior 4-wheel"),
        d("Mothercare XSS"),
        d("Mothercare Xtreme"),
        d("Mountain Buggy +one (Plus One)"),
        d("Mountain Buggy Cosmopolitan Geo (The Luxury Collection)"),
        d("Mountain Buggy Cosmopolitan V1"),
        d("Mountain Buggy Cosmopolitan V2"),
        d("Mountain Buggy Duet V1"),
        d("Mountain Buggy Duet V2"),
        d("Mountain Buggy Duet V3"),
        d("Mountain Buggy Duo"),
        d("Mountain Buggy MB Mini"),
        d("Mountain Buggy Nano"),
        d("Mountain Buggy Nano Duo"),
        d("Mountain Buggy Nano V2"),
        d("Mountain Buggy Nano V3"),
        d("Mountain Buggy Swift"),
        d("Mountain Buggy Terrain V1"),
        d("Mountain Buggy Terrain V2"),
        d("Mountain Buggy Terrain V3"),
        d("Mountain Buggy Urban Jungle The Luxury Collection"),
        d("Mountain Buggy Urban Jungle V1"),
        d("Mountain Buggy Urban Jungle V2"),
        d("Mountain Buggy Urban Jungle"),
        d("Mountain Buggy Xtreme"),
        d("Movino Estera"),
        d("Movino Urban Jungle The Luxury Collection"),
        d("Moweo Aerius"),
        d("Moweo Civi"),
        d("Moweo Compact Fly+"),
        d("Moweo Curro Lux"),
        d("Moweo Dapper"),
        d("Moweo Easy Travel+"),
        d("Moweo Estera"),
        d("Moweo Lite Air"),
        d("Moweo Lite"),
        d("Moweo Mejor Air"),
        d("Moweo Metro"),
        d("Moweo Simple Jogger"),
        d("Moweo Simple Jogger Lux"),
        d("Moweo Simple Jogger Twin"),
        d("Moweo Turn"),
        d("Moweo Twist"),
        d("Mutsy 3Rider"),
        d("Mutsy 4Rider Lightweight"),
        d("Mutsy 4Rider Single Spoke"),
        d("Mutsy Easyrider"),
        d("Mutsy Evo"),
        d("Mutsy Exo"),
        d("Mutsy i2"),
        d("Mutsy Icon"),
        d("Mutsy IGo"),
        d("Mutsy Nexo"),
        d("Mutsy Nio"),
        d("Mutsy Slider"),
        d("Mutsy Spider"),
        d("Mutsy Trans4mer"),
        d("Mutsy Transporter"),
        d("Mutsy Urban Rider"),
        d("Muuvo Flex"),
        d("Muuvo Quick 2.0"),
        d("Muuvo Quick 3.0"),
        d("Muuvo Quick SE"),
        d("Muuvo Quick"),
        d("Muuvo Roox"),
        d("Muuvo Slick"),
        d("Muuvo Trick"),
        d("M\u00fcV Gaan"),
        d("M\u00fcV Reis"),
        d("M\u00fcV Slick"),
        d("My Babiie MB 100 Billie Faiers"),
        d("My Babiie MB01"),
        d("My Babiie MB02"),
        d("My Babiie MB100"),
        d("My Babiie MB109"),
        d("My Babiie MB150"),
        d("My Babiie MB200"),
        d("My Babiie MB30"),
        d("My Babiie MB30 Billie Faiers"),
        d("My Babiie MB30 Katie Piper"),
        d("My Babiie MB51"),
        d("My Babiie MB51 Katie Piper Believes"),
        d("My Babiie Reis"),
        d("My Junior HiTreec"),
        d("My Junior Miyo"),
        d("My Junior Noax"),
        d("My Junior Pico"),
        d("My Junior Pico\u00b2"),
        d("My Junior Sienna"),
        d("My Junior Sienta Duo"),
        d("My Junior Vita"),
        d("My Junior Vita 2"),
        d("MyChild Easy Twin"),
        d("MyChild Floe"),
        d("MyChild MB200"),
        d("MyChild Nimbus"),
        d("MyChild Sienta Duo"),
        d("Nan\u00e1n 59 Velvet Champagne"),
        d("Nan\u00e1n Everose"),
        d("Nan\u00e1n Chiara Ferragni Black"),
        d("Nan\u00e1n Chiara Ferragni Gold"),
        d("Nan\u00e1n Nanan 59"),
        d("Nan\u00e1n Vernuji Rain Drops"),
        d("Naturkind Terra"),
        d("Naturkind Varius Pro"),
        d("Naturkind Varius Pro Vegan"),
        d("Naturkind Vita"),
        d("Naturkind Vita Vegan"),
        d("Navington Cadet"),
        d("Navington Caravel"),
        d("Navington Contessa"),
        d("Navington Corvet"),
        d("Navington Galeon"),
        d("Navington Scooner"),
        d("Navington Vita Vegan"),
        d("Nellys Lally"),
        d("Nellys Scooner"),
        d("Neonato Multisport"),
        d("Neonato Neosport Fit"),
        d("Neonato Piuma"),
        d("Neonato Puro Solid"),
        d("Neonato Puro"),
        d("Neonato Reverso"),
        d("Neonato Reverso Sport"),
        d("Neonato Slick"),
        d("Neonato Zeta X3"),
        d("Nestor Active"),
        d("Nestor Aston"),
        d("Nestor Carmond"),
        d("Nestor Cross"),
        d("Nestor Faktor X"),
        d("Nestor Hornet"),
        d("Nestor Hornet de lux"),
        d("Nestor Lader Alu"),
        d("Nestor Marina"),
        d("Nestor Moon"),
        d("Nestor Quenne"),
        d("Nestor Rambler"),
        d("Nestor Resande"),
        d("Nestor Resande Sport"),
        d("Nestor Slick"),
        d("Nestor V-Strom"),
        d("Nestor V-Strom New Styl"),
        d("Nestor Walker"),
        d("Nestor X6 Drive"),
        d("Newbuddy Claudia"),
        d("Newbuddy Easy way"),
        d("Newbuddy Fortuna"),
        d("Newbuddy Izacco"),
        d("Newbuddy Junior"),
        d("Newbuddy Orion"),
        d("Newbuddy Walker"),
        d("Nikimotion Autofold"),
        d("Nikimotion Autofold Lite"),
        d("Nikimotion Blade"),
        d("Nikimotion Orion"),
        d("Noordi Aqua"),
        d("Noordi Fjordi"),
        d("Noordi Fjordi 2016"),
        d("Noordi Luno All Trails"),
        d("Noordi Luno"),
        d("Noordi Polaris City"),
        d("Noordi Sole Go"),
        d("Noordi Sole II"),
        d("Noordi Sole"),
        d("Nordic Moon Parasoll"),
        d("Nordic Moon Shadow"),
        d("Nordic Moon Sole Go"),
        d("Nordic Moon Sulky BMX S"),
        d("Nordic Moon Sulky City Smart"),
        d("Nordic Moon Sulky Dawn"),
        d("Nordic Moon Sulky L"),
        d("Nordic Moon Sulky S"),
        d("Nordic Moon Sulky Spirit tv"),
        d("Nuna Demi Grow"),
        d("Nuna Ivvi Savi"),
        d("Nuna Ivvi"),
        d("Nuna Ixxa"),
        d("Nuna Mixx 2"),
        d("Nuna Mixx Next"),
        d("Nuna Mixx"),
        d("Nuna Pepp Luxx"),
        d("Nuna Pepp Next"),
        d("Nuna Pepp"),
        d("Nuna Tavo Next"),
        d("Nuna Tavo"),
        d("Nuna Triv Next"),
        d("Nuna Triv"),
        d("Nuna TRVL"),
        d("Nurse City 3"),
        d("Nurse Compact"),
        d("Nurse Dakar"),
        d("Nurse First"),
        d("Nurse Flash"),
        d("Nurse Futura"),
        d("Nurse Hello"),
        d("Nurse Intrepid Convert"),
        d("Nurse Intrepid Duplo"),
        d("Nurse Look"),
        d("Nurse Moon"),
        d("Nurse Oval 3"),
        d("Nurse Pareo Twin"),
        d("Nurse Run"),
        d("Nurse Terra"),
        d("Nurse Tour"),
        d("Nurse Town 3"),
        d("Nurse Trek"),
        d("Nurse Triv"),
        d("Obaby Apollo Twin"),
        d("Obaby Atlas Lite"),
        d("Obaby Atlas"),
        d("Obaby Disney"),
        d("Obaby Disney Twin"),
        d("Obaby Hera"),
        d("Obaby Chase Switch (Pramette)"),
        d("Obaby Chase"),
        d("Obaby Leto Plus"),
        d("Obaby Leto"),
        d("Obaby Mercury Triple"),
        d("Obaby Metis Plus"),
        d("Obaby Metis"),
        d("Obaby Monty"),
        d("Obaby Sketch"),
        d("Obaby Trek"),
        d("Obaby Zeal"),
        d("Obaby ZeZu Multi"),
        d("Obaby ZeZu Twin"),
        d("Oilily Combi Buggy"),
        d("Oilily ZeZu Twin"),
        d("Omnio Combi Buggy"),
        d("Omnio Stroller"),
        d("Omnio Stroller V2"),
        d("Orbit Baby G2"),
        d("Orbit Baby G2 Double Helix"),
        d("Orbit Baby G3"),
        d("Orbit Baby G3 Double Helix"),
        d("Orbit Baby O2"),
        d("Orbit Baby Stroller V2"),
        d("Osann Beebop"),
        d("Osann Beebop 3G"),
        d("Osann Beebop Ferrari"),
        d("Osann Beebop Ferrari 2"),
        d("Osann Dizzy"),
        d("Osann Ella"),
        d("Osann Fastfold"),
        d("Osann Flame"),
        d("Osann Frisco"),
        d("Osann Iron"),
        d("Osann Joy"),
        d("Osann Key"),
        d("Osann O2"),
        d("Osann Pep"),
        d("Osann Subway"),
        d("Osann Vegas Family"),
        d("Osann Vegas"),
        d("Out n About Little Nipper"),
        d("Out n About Little Nipper Double"),
        d("Out n About Nipper Double"),
        d("Out n About Nipper Single"),
        d("Out n About Nipper Sport"),
        d("Out n About Nipper Sport Double"),
        d("Out n About Vegas Family"),
        d("Oxo Air"),
        d("Oxo Cubby+"),
        d("Oxo Cubby"),
        d("Oxo Nipper Sport Double"),
        d("Pali 4.UNO"),
        d("Pali Aigo"),
        d("Pali Bora Speed"),
        d("Pali Cubby+"),
        d("Pali Fly"),
        d("Pali Ghibli"),
        d("Pali Magic"),
        d("Pali Magnifico"),
        d("Pali SEI.9"),
        d("Pali TRE.9 Denim"),
        d("Pali TRE.9 Fitness"),
        d("Pali TRE.9 X2"),
        d("Pali TRE.9"),
        d("Papiwi Luna"),
        d("Papiwi Sky"),
        d("Papiwi TRE.9 X2"),
        d("Paradise Baby Euforia"),
        d("Paradise Baby Euforia Premium"),
        d("Paradise Baby Euforia-S"),
        d("Paradise Baby Excellent"),
        d("Paradise Baby Fancy"),
        d("Paradise Baby FX"),
        d("Paradise Baby Magnetico"),
        d("Paradise Baby Magnetico Slim"),
        d("Paradise Baby Maximo"),
        d("Paradise Baby Melody"),
        d("Paradise Baby New Melody"),
        d("Paradise Baby Rocco"),
        d("Paradise Baby Sky"),
        d("Paradise Baby Verso"),
        d("Paradise Baby VR"),
        d("Patron Apollon Alux"),
        d("Patron Argo"),
        d("Patron Athena"),
        d("Patron Atlas"),
        d("Patron Atlas Duplex"),
        d("Patron Delfy"),
        d("Patron Falco 4"),
        d("Patron Falco 4C"),
        d("Patron Falco 4T"),
        d("Patron Fenix 4T"),
        d("Patron Fenix-G"),
        d("Patron Fenix-G 4C"),
        d("Patron Limitovan\u00e1 Edice"),
        d("Patron Maars"),
        d("Patron New Melody"),
        d("Patron Pallas"),
        d("Patron Syrix S4"),
        d("Patron Syrix S4C"),
        d("Patron Terix"),
        d("Patron Terix 4"),
        d("Patron Triton 3"),
        d("Patron Triton 4"),
        d("Patron Triton-G 3"),
        d("Patron Triton-G 4"),
        d("Patron Zephyr"),
        d("Peg-P\u00e9rego Aria"),
        d("Peg-P\u00e9rego Aria Shopper"),
        d("Peg-P\u00e9rego Aria Shopper Twin"),
        d("Peg-P\u00e9rego Aria Twin"),
        d("Peg-P\u00e9rego Aria Twin 60/40"),
        d("Peg-P\u00e9rego Atlantico"),
        d("Peg-P\u00e9rego Book"),
        d("Peg-P\u00e9rego Book 500 Elite"),
        d("Peg-P\u00e9rego Book 51"),
        d("Peg-P\u00e9rego Book 51 Elite"),
        d("Peg-P\u00e9rego Book 51 Pop Up"),
        d("Peg-P\u00e9rego Book 51 S"),
        d("Peg-P\u00e9rego Book 51 S Elite"),
        d("Peg-P\u00e9rego Book 51 S Pop Up"),
        d("Peg-P\u00e9rego Book 51 S XL"),
        d("Peg-P\u00e9rego Book 51 XL"),
        d("Peg-P\u00e9rego Book Cross"),
        d("Peg-P\u00e9rego Book For Two"),
        d("Peg-P\u00e9rego Book Plus"),
        d("Peg-P\u00e9rego Book Plus 500"),
        d("Peg-P\u00e9rego Book Plus Elite"),
        d("Peg-P\u00e9rego Book Plus Pop Up"),
        d("Peg-P\u00e9rego Book Plus XL"),
        d("Peg-P\u00e9rego Book S"),
        d("Peg-P\u00e9rego Book S Elite"),
        d("Peg-P\u00e9rego Book S Pop Up"),
        d("Peg-P\u00e9rego Book S XL"),
        d("Peg-P\u00e9rego Book Scout"),
        d("Peg-P\u00e9rego Book Scout Elite"),
        d("Peg-P\u00e9rego Book Scout Pop Up"),
        d("Peg-P\u00e9rego Book Scout XL"),
        d("Peg-P\u00e9rego Booklet"),
        d("Peg-P\u00e9rego Booklet 50"),
        d("Peg-P\u00e9rego Booklet Lite"),
        d("Peg-P\u00e9rego Caravel 22"),
        d("Peg-P\u00e9rego Centro"),
        d("Peg-P\u00e9rego Classico"),
        d("Peg-P\u00e9rego Culla"),
        d("Peg-P\u00e9rego Duette (SW)"),
        d("Peg-P\u00e9rego Duette Piroet"),
        d("Peg-P\u00e9rego Duette Pop Up"),
        d("Peg-P\u00e9rego Futura"),
        d("Peg-P\u00e9rego GT3"),
        d("Peg-P\u00e9rego GT4"),
        d("Peg-P\u00e9rego Pliko Four"),
        d("Peg-P\u00e9rego Pliko Mini"),
        d("Peg-P\u00e9rego Pliko Mini 500"),
        d("Peg-P\u00e9rego Pliko Mini Ducati"),
        d("Peg-P\u00e9rego Pliko Mini Momodesign"),
        d("Peg-P\u00e9rego Pliko Mini Twin"),
        d("Peg-P\u00e9rego Pliko P3 Compact"),
        d("Peg-P\u00e9rego Pliko P3 Compact On Track Modular"),
        d("Peg-P\u00e9rego Pliko Switch"),
        d("Peg-P\u00e9rego Primonido Elite"),
        d("Peg-P\u00e9rego Selfie"),
        d("Peg-P\u00e9rego S\u00ec"),
        d("Peg-P\u00e9rego S\u00ec Switch"),
        d("Peg-P\u00e9rego Switch Easy Drive"),
        d("Peg-P\u00e9rego Switch Four"),
        d("Peg-P\u00e9rego Tak"),
        d("Peg-P\u00e9rego Team"),
        d("Peg-P\u00e9rego Triplette (SW)"),
        d("Peg-P\u00e9rego Triplette Piroet"),
        d("Peg-P\u00e9rego Triplette Pop Up"),
        d("Peg-P\u00e9rego Unico"),
        d("Peg-P\u00e9rego Uno"),
        d("Peg-P\u00e9rego Vela Easy Drive"),
        d("Peg-P\u00e9rego Veloce"),
        d("Peg-P\u00e9rego Vivace"),
        d("Peg-P\u00e9rego Young"),
        d("Peg-P\u00e9rego Ypsi"),
        d("Petite Ch\u00e9rie Avion Air"),
        d("Petite Ch\u00e9rie Classic"),
        d("Petite Ch\u00e9rie Doux"),
        d("Petite Ch\u00e9rie Excellence"),
        d("Petite Ch\u00e9rie Excellence 2"),
        d("Petite Ch\u00e9rie Heritage"),
        d("Petite Ch\u00e9rie Libre"),
        d("Petite Ch\u00e9rie Lively"),
        d("Petite Ch\u00e9rie Lively 2"),
        d("Petite Ch\u00e9rie Lyon"),
        d("Petite Ch\u00e9rie Soleil"),
        d("Petite Ch\u00e9rie Soleil Double"),
        d("Petite Ch\u00e9rie Solide S"),
        d("Petite Ch\u00e9rie Solide+"),
        d("Petite Ch\u00e9rie Vario"),
        d("Petite&Mars Classic"),
        d("Petite&Mars Cross"),
        d("Petite&Mars Easy"),
        d("Petite&Mars Easy Air"),
        d("Petite&Mars Fly 2016"),
        d("Petite&Mars Go"),
        d("Petite&Mars Grand II"),
        d("Petite&Mars Icon RWS"),
        d("Petite&Mars Move"),
        d("Petite&Mars Move II Air"),
        d("Petite&Mars Musca"),
        d("Petite&Mars Rover"),
        d("Petite&Mars Rover II"),
        d("Petite&Mars Royal"),
        d("Petite&Mars Spider"),
        d("Petite&Mars Street"),
        d("Petite&Mars Street Premium Line"),
        d("Petite&Mars Street+"),
        d("Petite&Mars Street+ Air"),
        d("Petite&Mars Street+ Oak 2021"),
        d("Petite&Mars Street+ RWS"),
        d("Petite&Mars Street2 Air"),
        d("Petite&Mars Terrain"),
        d("Petite&Mars Trails"),
        d("Petite&Mars Travel"),
        d("Petite&Mars Up."),
        d("Petite&Mars Vario"),
        d("Phil&Teds Classic V1"),
        d("Phil&Teds Classic V2"),
        d("Phil&Teds Dash"),
        d("Phil&Teds Dot V1"),
        d("Phil&Teds Dot V2"),
        d("Phil&Teds Explorer"),
        d("Phil&Teds Go"),
        d("Phil&Teds Go 2020+"),
        d("Phil&Teds Hammerhead"),
        d("Phil&Teds Mod"),
        d("Phil&Teds Navigator"),
        d("Phil&Teds Promenade"),
        d("Phil&Teds S3"),
        d("Phil&Teds S4"),
        d("Phil&Teds Smart Buggy V1"),
        d("Phil&Teds Smart Buggy V2"),
        d("Phil&Teds Smart Lux"),
        d("Phil&Teds Smart V3"),
        d("Phil&Teds Sport"),
        d("Phil&Teds Sport 2015 / Navigator 2"),
        d("Phil&Teds Sub 4"),
        d("Phil&Teds Ultralight (Mara)"),
        d("Phil&Teds Verve 1, 2"),
        d("Phil&Teds Verve 3"),
        d("Phil&Teds Vibe 1"),
        d("Phil&Teds Vibe 2"),
        d("Phil&Teds Vibe 3"),
        d("Phil&Teds Voyager"),
        d("Pierre Cardin Florida (Flower)"),
        d("Pierre Cardin Handy (Sahara)"),
        d("Pierre Cardin Lothus"),
        d("Pierre Cardin Novis"),
        d("Pierre Cardin Solide S"),
        d("Pierre Cardin Toledo"),
        d("Pierre Cardin Ultralight (Mara)"),
        d("Play Funky"),
        d("Play Indie"),
        d("Play Voyager"),
        d("Prampol Alisa"),
        d("Prampol Andy"),
        d("Prampol Celia"),
        d("Prampol Daisy"),
        d("Prampol Gazelle"),
        d("Prampol Lars"),
        d("Prampol Lucia"),
        d("Prampol Mio"),
        d("Prampol Pixel"),
        d("Prampol Silvia"),
        d("Prampol Simple"),
        d("Prampol Soft Line"),
        d("Prampol Soft Line Chrome"),
        d("Prampol Solam"),
        d("Prampol Solam Leather and Linen"),
        d("Prampol Solam Limited"),
        d("Prampol Style"),
        d("Prampol Style Chrome"),
        d("Prampol Twister"),
        d("Prampol Twister Eko"),
        d("Prampol Verona Eko"),
        d("Prampol Verona Eko Chrome"),
        d("Prampol Vigo"),
        d("Prampol Virage"),
        d("Prampol Zebra"),
        d("Qtus BeetleX"),
        d("Qtus DuetPro"),
        d("Qtus Lark"),
        d("Qtus Spider"),
        d("Qtus Tody"),
        d("Qtus Zebra"),
        d("Quatro 3"),
        d("Quatro 4"),
        d("Quatro Caddy"),
        d("Quatro Cross"),
        d("Quatro Evo 2018"),
        d("Quatro Evo 8-wheels"),
        d("Quatro Fifi"),
        d("Quatro Imola"),
        d("Quatro Jerry"),
        d("Quatro Jimmy"),
        d("Quatro Lili"),
        d("Quatro Lily"),
        d("Quatro Lion"),
        d("Quatro Makalu 4"),
        d("Quatro Maxi"),
        d("Quatro Mini"),
        d("Quatro Mio"),
        d("Quatro Monza"),
        d("Quatro Nafi"),
        d("Quatro Rally"),
        d("Quatro Rio"),
        d("Quatro Tody"),
        d("Quatro Tommy"),
        d("Quatro Valencia"),
        d("Quatro Vela"),
        d("Quax Avenue"),
        d("Quax Compact Xl Twins"),
        d("Quax Crooz"),
        d("Quax Mini"),
        d("Quax Prado"),
        d("Quax Travelsystem"),
        d("Quax Vision"),
        d("Quax Vogue"),
        d("Quax Zapp Xtra 4 2.0"),
        d("Quinny Buzz (3)"),
        d("Quinny Buzz 4"),
        d("Quinny Buzz Xtra (3)"),
        d("Quinny Buzz Xtra 2.0"),
        d("Quinny Buzz Xtra 4"),
        d("Quinny Buzz Xtra 4 2.0"),
        d("Quinny Hubb Duo"),
        d("Quinny Hubb Mono"),
        d("Quinny LDN"),
        d("Quinny Longboardstroller"),
        d("Quinny Moodd"),
        d("Quinny Moodd 4"),
        d("Quinny Senzz"),
        d("Quinny Speedi SX"),
        d("Quinny Vela"),
        d("Quinny VNC"),
        d("Quinny Yezz 2.0"),
        d("Quinny Yezz Air"),
        d("Quinny Yezz"),
        d("Quinny Zapp 4"),
        d("Quinny Zapp Flex"),
        d("Quinny Zapp Flex Plus"),
        d("Quinny Zapp Xpress"),
        d("Quinny Zapp Xtra"),
        d("Quinny Zapp Xtra 2.0"),
        d("Quinny Zapp Xtra 4 2.0"),
        d("Quinny Zapp"),
        d("Quipolo Alux"),
        d("Quipolo Carmela"),
        d("Quipolo Cruze"),
        d("Quipolo Sandero"),
        d("Raf-Pol Alu Way"),
        d("Raf-Pol Aspero 2018"),
        d("Raf-Pol Avaro"),
        d("Raf-Pol Axel"),
        d("Raf-Pol Colorlux"),
        d("Raf-Pol Future"),
        d("Raf-Pol Gold Lux"),
        d("Raf-Pol Largo"),
        d("Raf-Pol Lilly"),
        d("Raf-Pol Lumio"),
        d("Raf-Pol Magnum"),
        d("Raf-Pol Margaret"),
        d("Raf-Pol Marlux"),
        d("Raf-Pol Miracle"),
        d("Raf-Pol Optimal"),
        d("Raf-Pol Qbaro 2016"),
        d("Raf-Pol Queen"),
        d("Raf-Pol Rosso"),
        d("Raf-Pol Sandero"),
        d("Raf-Pol Sky Lark 2018"),
        d("Raf-Pol Tropical"),
        d("Raf-Pol White Lux"),
        d("Recaro Celona"),
        d("Recaro Citylife"),
        d("Recaro Easylife"),
        d("Recaro Easylife 2"),
        d("Recaro Easylife Elite"),
        d("Recaro Easylife Elite 2"),
        d("Recaro Performance Denali"),
        d("Recaro Performance Denali Luxury"),
        d("Recaro Performance Marquis Luxury"),
        d("Recaro Performance Sequoia Luxury"),
        d("Recaro Sadena"),
        d("Red Castle CityLink 2"),
        d("Red Castle CityLink 3"),
        d("Red Castle Connect Up"),
        d("Red Castle Evolutwin"),
        d("Red Castle Luxos"),
        d("Red Castle Performance Sequoia Luxury"),
        d("Red Castle Shop\u2019n Jogg Disc II"),
        d("Red Castle Whizz"),
        d("Red Kite Push Me 2U"),
        d("Red Kite Push Me Cube"),
        d("Red Kite Push Me Fusion"),
        d("Red Kite Push Me Lite"),
        d("Red Kite Push Me Metro"),
        d("Red Kite Push Me Quatro"),
        d("Red Kite Push Me Sprint"),
        d("Red Kite Push Me Twini"),
        d("Red Kite Push Me Zebu"),
        d("Red Kite Whizz"),
        d("Redsbaby Jive"),
        d("Redsbaby Jive\u00b2"),
        d("Redsbaby Jive\u00b2 Platinum"),
        d("Redsbaby Jive\u00b3"),
        d("Redsbaby Jive\u00b3 Platinum"),
        d("Redsbaby Metro"),
        d("Redsbaby Metro\u00b2"),
        d("Redsbaby Metro\u00b3"),
        d("Redsbaby Push Me Zebu"),
        d("Redsbaby Skip"),
        d("Redsbaby Skip\u00b2"),
        d("Reemy R8"),
        d("Reemy Skip"),
        d("Renolux TS Equation"),
        d("Renolux Iris"),
        d("Renolux Travelling"),
        d("Renolux Wink"),
        d("Retrus Alpina"),
        d("Retrus Amico"),
        d("Retrus Amico Art"),
        d("Retrus Avenir Fox"),
        d("Retrus Avenir S-Line Fox"),
        d("Retrus Domani"),
        d("Retrus Domani Carbon"),
        d("Retrus Domani Grey"),
        d("Retrus Eco Line"),
        d("Retrus Epos"),
        d("Retrus Fio Eco Line"),
        d("Retrus Fortis"),
        d("Retrus Futuro Alu"),
        d("Retrus Milano"),
        d("Retrus Next"),
        d("Retrus Orio"),
        d("Retrus Platinum"),
        d("Retrus Popi"),
        d("Retrus Press Eco Line"),
        d("Retrus S-Line"),
        d("Retrus S-Line Classic"),
        d("Retrus Sky"),
        d("Retrus Stella"),
        d("Retrus Tommy"),
        d("Retrus Toto"),
        d("Retrus Urbo"),
        d("Retrus Valenso"),
        d("Retrus Wink"),
        d("Riko Alpina FX"),
        d("Riko Alpina"),
        d("Riko Amigo"),
        d("Riko Aura S-Line"),
        d("Riko Aveo"),
        d("Riko Basic Plus"),
        d("Riko Bella"),
        d("Riko Brano Ecco"),
        d("Riko Brano Luxe"),
        d("Riko Brano Natural"),
        d("Riko Brano Pro"),
        d("Riko Brano"),
        d("Riko Expero"),
        d("Riko Fox"),
        d("Riko Marla"),
        d("Riko Modus"),
        d("Riko Molla"),
        d("Riko Nano Ecco"),
        d("Riko Nano Pro"),
        d("Riko Nano"),
        d("Riko Nesa"),
        d("Riko Nestro"),
        d("Riko Niki"),
        d("Riko Nuno Pro"),
        d("Riko Nuno"),
        d("Riko Piano"),
        d("Riko Primo"),
        d("Riko Qubus"),
        d("Riko RE-FLEX"),
        d("Riko Senso"),
        d("Riko Side"),
        d("Riko Sigma"),
        d("Riko Swift Natural"),
        d("Riko Swift Neon"),
        d("Riko Swift Premium"),
        d("Riko Team"),
        d("Riko Trex"),
        d("Riko Ultima"),
        d("Riko Vario"),
        d("Riko Villa"),
        d("Riko XD"),
        d("Roan Bass Next"),
        d("Roan Bass Soft"),
        d("Roan Bass"),
        d("Roan Bloom"),
        d("Roan Coss Classic"),
        d("Roan Coss"),
        d("Roan Emma"),
        d("Roan Esso"),
        d("Roan Ivi 2.0"),
        d("Roan Marita Prestige"),
        d("Roan Rocco"),
        d("Roan Sofia"),
        d("Roan Teo"),
        d("Roan XD"),
        d("Roma Prams Roma Bambino"),
        d("Roma Prams Roma Bambino SE"),
        d("Roma Prams Roma Capsule"),
        d("Roma Prams Roma Capsule\u00b2"),
        d("Roma Prams Roma Caramello"),
        d("Roma Prams Roma Moda"),
        d("Roma Prams Roma Rizzo"),
        d("Roma Prams Roma Vita"),
        d("Roma Prams Roma Vita\u00b2"),
        d("Roma Prams Teo"),
        d("Royal Jewel"),
        d("Royal Roma Vita\u00b2"),
        d("Ruk-Bug Jewel"),
        d("Ruk-Bug Solis"),
        d("Safety 1st Amble Luxe"),
        d("Safety 1st Amble Quad"),
        d("Safety 1st Compa'city"),
        d("Safety 1st Compa\u2019city II"),
        d("Safety 1st Compa\u2019city s madlem"),
        d("Safety 1st Connexion"),
        d("Safety 1st Crazy Peps"),
        d("Safety 1st Duodeal"),
        d("Safety 1st Easy Way"),
        d("Safety 1st Envy"),
        d("Safety 1st Hello"),
        d("Safety 1st Ideal Sportive"),
        d("Safety 1st Interval"),
        d("Safety 1st Kokoon"),
        d("Safety 1st Nook"),
        d("Safety 1st Peps"),
        d("Safety 1st Peps With Canopy"),
        d("Safety 1st Rainbow"),
        d("Safety 1st Riva"),
        d("Safety 1st Riva Flex"),
        d("Safety 1st Road Master"),
        d("Safety 1st Saunter 3"),
        d("Safety 1st Saunter 3 Luxe"),
        d("Safety 1st Slim"),
        d("Safety 1st Smooth Ride"),
        d("Safety 1st Step And Go"),
        d("Safety 1st Step And Go 2"),
        d("Safety 1st Taly"),
        d("Safety 1st Teamy"),
        d("Safety 1st Teeny"),
        d("Safety 1st Trendideal"),
        d("Safety 1st Urban Trek"),
        d("Safety 1st Urby"),
        d("Seed Papilio"),
        d("Seed Pli Mg"),
        d("Seed Pli Plus"),
        d("Seed Pli"),
        d("Seed Urban Trek"),
        d("Seka Carlo-Lux"),
        d("Seka Corado"),
        d("Seka Dimateo"),
        d("Seka Moretti"),
        d("Seka Pli Plus"),
        d("Seka Torrino"),
        d("Seka Vegas"),
        d("Seka Zippy-Lux"),
        d("Shom Led"),
        d("Shom Magical"),
        d("Shom Roberto Verino Elegance"),
        d("Shom Roberto Verino Smart"),
        d("Shom Zippy-Lux"),
        d("Schwinn Arrow"),
        d("Schwinn Interval"),
        d("Schwinn Solis"),
        d("Silver Cross 3D"),
        d("Silver Cross Avia"),
        d("Silver Cross Balmoral"),
        d("Silver Cross Clic"),
        d("Silver Cross Coast"),
        d("Silver Cross Dazzle"),
        d("Silver Cross Dune"),
        d("Silver Cross FastFold"),
        d("Silver Cross Fizz"),
        d("Silver Cross GetGo"),
        d("Silver Cross Halo"),
        d("Silver Cross Jet"),
        d("Silver Cross Jet 2"),
        d("Silver Cross Jet 3"),
        d("Silver Cross Kensington"),
        d("Silver Cross Micro"),
        d("Silver Cross Micro Twin"),
        d("Silver Cross New Pop"),
        d("Silver Cross Pacific"),
        d("Silver Cross Pioneer"),
        d("Silver Cross Pioneer 21"),
        d("Silver Cross Pop"),
        d("Silver Cross Pop 2"),
        d("Silver Cross Pop Duo"),
        d("Silver Cross Pop Star"),
        d("Silver Cross ProFold"),
        d("Silver Cross Reef"),
        d("Silver Cross Reflex"),
        d("Silver Cross Roberto Verino Smart"),
        d("Silver Cross Sleepover"),
        d("Silver Cross Sleepover Elegance"),
        d("Silver Cross Sleepover Linear"),
        d("Silver Cross Sleepover Sport"),
        d("Silver Cross Spirit"),
        d("Silver Cross Surf"),
        d("Silver Cross Surf 2"),
        d("Silver Cross Surf 3"),
        d("Silver Cross Surf Aston Martin"),
        d("Silver Cross Surf Elevation (All Terrain)"),
        d("Silver Cross Wave"),
        d("Silver Cross Wayfarer"),
        d("Silver Cross Wing"),
        d("Silver Cross Zest"),
        d("Sirocco Brave"),
        d("Sirocco Quattro Color"),
        d("Sirocco Quattro Premium"),
        d("Sirocco Trio"),
        d("Sirocco Trio Premium"),
        d("Sirocco Zest"),
        d("Skyline Amberline Active"),
        d("Skyline Amberline Classica"),
        d("Skyline Amberline Classica Retro"),
        d("Skyline Amberline Maxim"),
        d("Skyline Amberline Vista"),
        d("Skyline GTR2"),
        d("Skyline GTR"),
        d("Skyline GT"),
        d("Skyline Quattro Premium"),
        d("Skyline Rodeo Lux"),
        d("Skyline Rodeo Standard"),
        d("Skyline Soprano"),
        d("Slaro Arizona"),
        d("Slaro Barbara"),
        d("Slaro Blues PC"),
        d("Slaro Camila"),
        d("Slaro Camila Classic"),
        d("Slaro Capri"),
        d("Slaro Carmen"),
        d("Slaro Carmen Classic"),
        d("Slaro Emily"),
        d("Slaro Figaro"),
        d("Slaro Golf"),
        d("Slaro Harley"),
        d("Slaro Charlotte"),
        d("Slaro Charlotte Duo"),
        d("Slaro Indigo"),
        d("Slaro Indigo Duo"),
        d("Slaro James Bond"),
        d("Slaro Legend"),
        d("Slaro Madonna"),
        d("Slaro Madonna Lux"),
        d("Slaro Maximo"),
        d("Slaro Mexico"),
        d("Slaro Odyssey"),
        d("Slaro Queen"),
        d("Slaro Sancho"),
        d("Slaro Santa Cruz Duo"),
        d("Slaro Soprano"),
        d("Slaro Sydney"),
        d("Sojan Aga"),
        d("Sojan Azurro"),
        d("Sojan Bobas Car"),
        d("Sojan Boxter"),
        d("Sojan Broco"),
        d("Sojan Carrera"),
        d("Sojan Cayenne"),
        d("Sojan Cayman"),
        d("Sojan Cross"),
        d("Sojan Gallardo"),
        d("Sojan GTR"),
        d("Sojan Huracan"),
        d("Sojan Insignia"),
        d("Sojan Juke"),
        d("Sojan Kuga"),
        d("Sojan Monaco"),
        d("Sojan Supra"),
        d("Sojan Sydney"),
        d("Sojan Vito"),
        d("Steelcraft Acclaim"),
        d("Steelcraft Agile 4"),
        d("Steelcraft Agile Elite"),
        d("Steelcraft Agile Plus"),
        d("Steelcraft Agile Plus Reverse Handle"),
        d("Steelcraft Agile SP"),
        d("Steelcraft Agile Twin"),
        d("Steelcraft B-Ready"),
        d("Steelcraft Compact"),
        d("Steelcraft Compact Deluxe Edition"),
        d("Steelcraft Cruiser"),
        d("Steelcraft e-brake"),
        d("Steelcraft Express"),
        d("Steelcraft Holiday II"),
        d("Steelcraft Phoenix"),
        d("Steelcraft Profile"),
        d("Steelcraft Spree"),
        d("Steelcraft Sprint"),
        d("Steelcraft Vito"),
        d("Steggi Lux Air"),
        d("Steggi Lux Classic"),
        d("Steggi Retro"),
        d("Steggi Lux Trend"),
        d("Steggi Master"),
        d("Steggi Master Trend"),
        d("Steggi Pilot K"),
        d("Steggi Pilot T"),
        d("Stokke Beat"),
        d("Stokke Crusi"),
        d("Stokke Scoot V1"),
        d("Stokke Scoot V2"),
        d("Stokke Scoot V3"),
        d("Stokke Trailz Classic"),
        d("Stokke Trailz Terrain"),
        d("Stokke Xplory"),
        d("Stokke Xplory V2"),
        d("Stokke Xplory V3"),
        d("Stokke Xplory V4"),
        d("Stokke Xplory V5"),
        d("Stokke Xplory V6"),
        d("Stokke Xplory X"),
        d("Stork"),
        d("StrollAir CosmoS"),
        d("StrollAir My Duo"),
        d("StrollAir ReVu"),
        d("StrollAir SOLO"),
        d("StrollAir TANGO"),
        d("StrollAir TWIN WAY"),
        d("Summer Baby Cobe"),
        d("Summer Baby Dynamic"),
        d("Summer Baby Sempre"),
        d("Summer Infant 3D-one"),
        d("Summer Infant 3Dflip"),
        d("Summer Infant 3Dlite"),
        d("Summer Infant 3Dlite+"),
        d("Summer Infant 3Dtote"),
        d("Summer Infant 3Dtrek"),
        d("Summer Infant 3Dtwo"),
        d("Summer Infant 3Dzyre"),
        d("Summer Infant GOlite"),
        d("Sun Baby Almond"),
        d("Sun Baby Alu Way"),
        d("Sun Baby Barton"),
        d("Sun Baby Deimos"),
        d("Sun Baby Ibiza"),
        d("Tako Acoustic"),
        d("Tako Acoustic Duo"),
        d("Tako Acoustic Duo Slim"),
        d("Tako Alive Duo"),
        d("Tako Alive"),
        d("Tako Artemo"),
        d("Tako Baby Heaven Carbon"),
        d("Tako Baby Heaven Exclusive"),
        d("Tako Baby Heaven Line"),
        d("Tako Baby Heaven Natural"),
        d("Tako Baby Heaven Premium"),
        d("Tako Ballila"),
        d("Tako Bella Donna"),
        d("Tako Captiva"),
        d("Tako Captiva Mohican"),
        d("Tako Celebrity"),
        d("Tako City Move"),
        d("Tako City Move Duo"),
        d("Tako City Move Marine"),
        d("Tako City Move Timber"),
        d("Tako City Voyager"),
        d("Tako Corona 4K"),
        d("Tako Corona Duo"),
        d("Tako Corona Duo Slim"),
        d("Tako Corona"),
        d("Tako Dalga Classic"),
        d("Tako Dalga Lift"),
        d("Tako Dalga Lift Duo"),
        d("Tako Dalga Lift Duo Slim"),
        d("Tako Duo Jumper"),
        d("Tako Duo Light"),
        d("Tako Duo Line"),
        d("Tako Exist"),
        d("Tako Extreme"),
        d("Tako Extreme Flash"),
        d("Tako Extreme Pik"),
        d("Tako Fast Rider"),
        d("Tako Geometric"),
        d("Tako Jumper 4"),
        d("Tako Jumper 4 Duo"),
        d("Tako Jumper 4 Duo Slim"),
        d("Tako Jumper 5"),
        d("Tako Jumper 5 Duo"),
        d("Tako Jumper 5 Duo Slim"),
        d("Tako Jumper Light"),
        d("Tako Jumper V"),
        d("Tako Jumper X"),
        d("Tako Jumper X Duo"),
        d("Tako La Manche"),
        d("Tako Laret 2015"),
        d("Tako Laret Classic"),
        d("Tako Laret Duo"),
        d("Tako Laret"),
        d("Tako Lumi Lum"),
        d("Tako Lungo"),
        d("Tako MaxOne Deluxe"),
        d("Tako MaxOne"),
        d("Tako Mega line"),
        d("Tako Milos"),
        d("Tako Monodi"),
        d("Tako Moonlight"),
        d("Tako Moonlight Vela"),
        d("Tako Moonlight Zakard"),
        d("Tako Moonlight Zakard Duo"),
        d("Tako Moonlight Zakard Duo Slim"),
        d("Tako Nautilus"),
        d("Tako Neon"),
        d("Tako Nocturne"),
        d("Tako Omega Duo"),
        d("Tako Omega"),
        d("Tako Perso"),
        d("Tako Skiathos"),
        d("Tako Sportime"),
        d("Tako Star Line"),
        d("Tako Star Line Duo"),
        d("Tako Star Line Duo Slim"),
        d("Tako Sunline"),
        d("Tako Swift Style"),
        d("Tako V-Road"),
        d("Tako Vela Duo"),
        d("Tako Vela Duo Slim"),
        d("Tako Velino"),
        d("Tako Viva"),
        d("Tako Warrior"),
        d("Teddy Aura"),
        d("Teddy Coral"),
        d("Teddy Diadema"),
        d("Teddy Diana Prime"),
        d("Teddy Diana Prime Classic"),
        d("Teddy Fenix Duo"),
        d("Teddy Fenix Duo Prime"),
        d("Teddy Fenix Prime"),
        d("Teddy Fenix Prime Classic"),
        d("Teddy Fenix"),
        d("Teddy Liga Star"),
        d("Teddy Nova"),
        d("Teddy Porto Lux"),
        d("Teddy Pulsar"),
        d("Teddy Solar Duo S"),
        d("Teutonia BeYou!"),
        d("Teutonia BeYou! Elite (V4)"),
        d("Teutonia BeYou! V2"),
        d("Teutonia BeYou! V3"),
        d("Teutonia Bliss"),
        d("Teutonia Cosmo"),
        d("Teutonia Cosmo V2"),
        d("Teutonia Cosmo V3"),
        d("Teutonia Cosmo V4"),
        d("Teutonia \u00c9legance"),
        d("Teutonia Fun"),
        d("Teutonia Lambda"),
        d("Teutonia Mistral P"),
        d("Teutonia Mistral P V2"),
        d("Teutonia Mistral P V3"),
        d("Teutonia Mistral P V4"),
        d("Teutonia Mistral S"),
        d("Teutonia Mistral S V2"),
        d("Teutonia Mistral S V3"),
        d("Teutonia Mistral S V4"),
        d("Teutonia Spirit S3"),
        d("Teutonia T-Linx"),
        d("Teutonia Team Cosmo"),
        d("Teutonia Team Cosmo V2"),
        d("Teutonia Team Cosmo V3"),
        d("Teutonia Trio"),
        d("TFK Buggster S"),
        d("TFK Buggster S Air"),
        d("TFK Buggster SL"),
        d("TFK Dot"),
        d("TFK Dot 2"),
        d("TFK Dot Premium Line"),
        d("TFK Duo"),
        d("TFK Joggster Adventure"),
        d("TFK Joggster Adventure 2"),
        d("TFK Joggster Adventure Premium Line"),
        d("TFK Joggster Adventure Premium Line 2"),
        d("TFK Joggster III 12"),
        d("TFK Joggster III 16"),
        d("TFK Joggster Lite 12"),
        d("TFK Joggster Lite 16"),
        d("TFK Joggster Lite Twist"),
        d("TFK Joggster Lite Twist Premium Line"),
        d("TFK Joggster Sport"),
        d("TFK Joggster Sport 2"),
        d("TFK Joggster Trail"),
        d("TFK Joggster Trail 2"),
        d("TFK Joggster Trail Premium Line"),
        d("TFK Joggster Trail Premium Line 2"),
        d("TFK Joggster Twist"),
        d("TFK Joggster Twist Premium Line"),
        d("TFK Joggster X City"),
        d("TFK Joggster X Sport"),
        d("TFK Joggster X Twist"),
        d("TFK Joggster X4"),
        d("TFK Lite Mini"),
        d("TFK Mono2"),
        d("TFK Mono"),
        d("TFK Sport"),
        d("TFK Trio Twist"),
        d("TFK Trio"),
        d("TFK Twin Adventure"),
        d("TFK Twin Adventure 2"),
        d("TFK Twin Adventure Premium Line"),
        d("TFK Twin Adventure Premium Line 2"),
        d("TFK Twin Trail"),
        d("TFK Twin Trail 2"),
        d("TFK Twin Trail Premium Line"),
        d("TFK Twin Trail Premium Line 2"),
        d("TFK Twinner Lite"),
        d("TFK Twinner Lite Premium Line"),
        d("TFK Twinner Twist Duo"),
        d("TFK Twinner Twist Duo Premium Line"),
        d("The First Years Ignite"),
        d("The First Years Jet"),
        d("Thule Glide 1"),
        d("Thule Glide 2"),
        d("Thule Shine"),
        d("Thule Sleek"),
        d("Thule Spring"),
        d("Thule Urban Glide 1"),
        d("Thule Urban Glide 2"),
        d("Thule Urban Glide 2 Double"),
        d("Thule Urban Glide 4-wheel"),
        d("Thule Urban Glide Double"),
        d("Tianrui Lightweight Stroller"),
        d("Tianrui Plane Stroller"),
        d("Tianrui Pram Stroller"),
        d("Tiny Wonders"),
        d("Top Mark 2 Combi"),
        d("Top Mark Bobby"),
        d("Top Mark Dani"),
        d("Top Mark Dani Plus"),
        d("Top Mark Dex"),
        d("Top Mark Flynn"),
        d("Top Mark Jay"),
        d("Top Mark Jay II"),
        d("Top Mark Little Dutch Buggy"),
        d("Top Mark Lucca"),
        d("Top Mark Miffy"),
        d("Top Mark Mika"),
        d("Top Mark Pure"),
        d("Top Mark Reese"),
        d("Top Mark Riley"),
        d("Top Mark Rio"),
        d("Top Mark Vision"),
        d("Touragoo Adventure Air"),
        d("Touragoo Adventure Plus"),
        d("Touragoo Flame"),
        d("Touragoo Flame Plus"),
        d("Touragoo Mars"),
        d("Touragoo Mercury"),
        d("Touragoo Onyx"),
        d("Touragoo Ramble 4"),
        d("Touragoo Track"),
        d("Touragoo Trip 3"),
        d("Touragoo Trip 4"),
        d("Touragoo Trophy Air"),
        d("Touragoo Tweed Gel"),
        d("Touragoo Vesta"),
        d("Trend Kid"),
        d("Trend Mami"),
        d("Trend Pepe Classic"),
        d("Trend Pepe"),
        d("Trend Smile"),
        d("Trend Sweet"),
        d("Tuc Tuc Paseos"),
        d("Tuc Tuc Plume"),
        d("Tuc Tuc Tive"),
        d("Tuc Tuc Tive 2.0"),
        d("Tuc Tuc Yupi"),
        d("Tuc Tuc Yupi 2.0"),
        d("Tuc Tuc Yupi Shine"),
        d("Tudore Explorer"),
        d("Tudore Forester"),
        d("Tudore Linden"),
        d("Tudore Ranger"),
        d("Tudore Scout"),
        d("Tudore Trapper"),
        d("Tutek Diamos"),
        d("Tutek Diamos Pro"),
        d("Tutek Diamos VX"),
        d("Tutek Grander Lift"),
        d("Tutek Grander Play"),
        d("Tutek Grander Plus"),
        d("Tutek Inspire"),
        d("Tutek Ravio"),
        d("Tutek Retro"),
        d("Tutek Tambero"),
        d("Tutek Timer"),
        d("Tutek Tirso"),
        d("Tutek Torero"),
        d("Tutek Trido"),
        d("Tutek Turran Silver"),
        d("Tutek Vio"),
        d("Tutis (Zippy) Classic"),
        d("Tutis Aero Sport"),
        d("Tutis Aero"),
        d("Tutis Jogo"),
        d("Tutis Leo+"),
        d("Tutis Mimi Style"),
        d("Tutis Mio Plus"),
        d("Tutis Mio"),
        d("Tutis Nanni"),
        d("Tutis Sky"),
        d("Tutis Sport"),
        d("Tutis Uno+"),
        d("Tutis Uno3+"),
        d("Tutis Uno"),
        d("Tutis Viva Life"),
        d("Tutis Viva4"),
        d("Tutis Zille"),
        d("Tutis Zippy"),
        d("Tutti Bambini Arlo"),
        d("Tutti Bambini Koji"),
        d("Tutti Bambini Momi"),
        d("Tutti Bambini Riviera"),
        d("Twistshake Tour"),
        d("Uppababy Cruz"),
        d("Uppababy Cruz V2"),
        d("Uppababy G-Link"),
        d("Uppababy G-Link 2"),
        d("Uppababy G-Lite"),
        d("Uppababy G-Luxe"),
        d("Uppababy Minu"),
        d("Uppababy Minu V2"),
        d("Uppababy Ridge"),
        d("Uppababy Vista"),
        d("Uppababy Vista V2"),
        d("Urbini Avi"),
        d("Urbini Omni Plus"),
        d("Urbini Reversi"),
        d("Urbini Swiftli"),
        d("Urbini Turni"),
        d("Valco Baby Evo 2"),
        d("Valco Baby Evo 2 for 2"),
        d("Valco Baby Evo 3"),
        d("Valco Baby Ion"),
        d("Valco Baby Ion for 2 (Ion Twin)"),
        d("Valco Baby Latitude"),
        d("Valco Baby Neo Twin"),
        d("Valco Baby Nomad"),
        d("Valco Baby Nomad Duo"),
        d("Valco Baby Quad"),
        d("Valco Baby Quad X"),
        d("Valco Baby Rebel Q"),
        d("Valco Baby Rebel Q Air"),
        d("Valco Baby Rebel Q Sport"),
        d("Valco Baby Slim Twin"),
        d("Valco Baby Snap (Snap 3)"),
        d("Valco Baby Snap 4"),
        d("Valco Baby Snap 4 Flat Matte LTD Edition"),
        d("Valco Baby Snap 4 Tailormade"),
        d("Valco Baby Snap 4 Trend"),
        d("Valco Baby Snap Duo"),
        d("Valco Baby Snap Duo Elite"),
        d("Valco Baby Snap Duo Trend"),
        d("Valco Baby Snap Trend"),
        d("Valco Baby Snap Ultra"),
        d("Valco Baby Snap Ultra Duo"),
        d("Valco Baby Snap Ultra Tailormade"),
        d("Valco Baby Snap 4 Ultra Trend"),
        d("Valco Baby Spark"),
        d("Valco Baby Spark Duo"),
        d("Valco Baby T3 plus"),
        d("Valco Baby Tri Mode Duo X"),
        d("Valco Baby Tri Mode X"),
        d("Valco Baby Twin Latitude"),
        d("Valco Baby Velo"),
        d("Valco Baby Zee"),
        d("Valco Baby Zee Two"),
        d("Vega Cosmo Alu"),
        d("Venicci 3V"),
        d("Venicci Asti"),
        d("Venicci Bello"),
        d("Venicci Carbo"),
        d("Venicci Gold"),
        d("Venicci Gusto"),
        d("Venicci Insevio"),
        d("Venicci Italy"),
        d("Venicci Lanco"),
        d("Venicci Pure"),
        d("Venicci Pure 2.0"),
        d("Venicci Shadow"),
        d("Venicci Shadow 2.0"),
        d("Venicci Silver"),
        d("Venicci Soft"),
        d("Venicci Tinum"),
        d("Venicci Tinum 2.0"),
        d("Venicci Tinum Upline"),
        d("Venicci Valdi"),
        d("Venicci Vento"),
        d("Venicci Viva"),
        d("Venicci White/Black"),
        d("Venice Child Kangaroo"),
        d("Verdi Amber"),
        d("Verdi Avenir"),
        d("Verdi Aviator"),
        d("Verdi Bello"),
        d("Verdi Bravo"),
        d("Verdi Broko"),
        d("Verdi Carmelo"),
        d("Verdi Classic"),
        d("Verdi Eclipse"),
        d("Verdi Expert"),
        d("Verdi for2"),
        d("Verdi Fox"),
        d("Verdi Genua"),
        d("Verdi Laser"),
        d("Verdi Lider"),
        d("Verdi Max"),
        d("Verdi Mirage"),
        d("Verdi Neo-life"),
        d("Verdi Nevada"),
        d("Verdi Optima"),
        d("Verdi Oxy"),
        d("Verdi Pepe Eco"),
        d("Verdi Pepe Eco Plus"),
        d("Verdi Qvipo"),
        d("Verdi S6"),
        d("Verdi Smart"),
        d("Verdi Solar City"),
        d("Verdi Solar Classic"),
        d("Verdi Sonic"),
        d("Verdi Stars"),
        d("Verdi Twin"),
        d("Verdi Vango"),
        d("Verdi Vector"),
        d("Verdi Viper"),
        d("Verdi X-Space"),
        d("Vidiamo Limo"),
        d("Wiejar Amber"),
        d("Wiejar Angelo"),
        d("Wiejar Blue Velvet"),
        d("Wiejar Canyon"),
        d("Wiejar Constelations"),
        d("Wiejar Evado"),
        d("Wiejar Evado Eco"),
        d("Wiejar Evado GD"),
        d("Wiejar Fluo"),
        d("Wiejar Golden Sands"),
        d("Wiejar Lady"),
        d("Wiejar Little Princess"),
        d("Wiejar Malachyt"),
        d("Wiejar Martina"),
        d("Wiejar Mau"),
        d("Wiejar Maximus"),
        d("Wiejar Mirage"),
        d("Wiejar Modo Exclusive"),
        d("Wiejar Modo Exter"),
        d("Wiejar Modo Mau"),
        d("Wiejar Modo Next"),
        d("Wiejar Modo Sweet"),
        d("Wiejar Modo"),
        d("Wiejar Molokai Duo"),
        d("Wiejar Montenegro"),
        d("Wiejar Moonflower"),
        d("Wiejar Morning Shine"),
        d("Wiejar Obsidian"),
        d("Wiejar Piryt"),
        d("Wiejar Rider"),
        d("Wiejar Scarlet"),
        d("Wiejar Stella"),
        d("Wiejar Summer Queen"),
        d("Wiejar Sunrise"),
        d("Wiejar Twist"),
        d("X-Lander X-Cite"),
        d("X-Lander X-Double"),
        d("X-Lander X-Fit"),
        d("X-Lander X-Fly 2018"),
        d("X-Lander X-Fly"),
        d("X-Lander X-Follow"),
        d("X-Lander X-Go"),
        d("X-Lander X-Go Buggy"),
        d("X-Lander X-Jive"),
        d("X-Lander X-Move"),
        d("X-Lander X-Pulse 2016"),
        d("X-Lander X-Pulse"),
        d("X-Lander X-Run"),
        d("X-Lander X-Twin"),
        d("X-Lander X1"),
        d("X-Lander XA"),
        d("X-Lander XQ"),
        d("X-Lander XS"),
        d("X-Lander XT"),
        d("X-Lander XV"),
        d("Xiaomi MiTU"),
        d("Xiaomi Zhixing"),
        d("Zekiwa ALU Champion"),
        d("Zekiwa ALU Jazz"),
        d("Zekiwa ALU Twice"),
        d("Zekiwa Duett"),
        d("Zekiwa Duett Sport"),
        d("Zekiwa Galant"),
        d("Zekiwa Luxus Complete"),
        d("Zekiwa Monsun"),
        d("Zekiwa Nature"),
        d("Zekiwa Prestige - ZZ"),
        d("Zekiwa Saturn"),
        d("Zekiwa Sport Duo"),
        d("Zekiwa Sport Duo New Line"),
        d("Zekiwa Swing"),
        d("Zekiwa Touring Cross"),
        d("Zekiwa Tramper"),
        d("Zekiwa Trendy"),
        d("Zekiwa TRIset"),
        d("Zekiwa Walker"),
        d("Zeta CiTi Twin"),
        d("Zeta CiTi"),
        d("Zeta Vooom Twin"),
        d("Zeta Vooom"),
        d("Zoe Tandem"),
        d("Zoe XL1 Best"),
        d("Zoe XL1 Best v2"),
        d("Zoe XL2 Best"),
        d("Zoe XL2 Best v2"),
        d("Zoe XL3 Best"),
        d("Zoe XL3 Best v2"),
        d("Zoe XL4 Best"),
        d("Zoe XL4 Best v2"),
        d("Zoe XLC Best"),
        d("Zoe XLC Best v2"),
        d("Zooper Danzon Escape"),
        d("Zooper Flamenco"),
        d("Zooper Jazz\u00b2"),
        d("Zooper Jazz"),
        d("Zooper Tango Escape"),
        d("Zooper Tango Smart"),
        d("Zooper Twist Escape"),
        d("Zooper Twist Smart"),
        d("Zooper Waltz Escape"),
        d("Zooper Waltz Smart"),
        d("Zooper Z9"),
        d("Zopa Calibro"),
        d("Zopa City"),
        d("Zopa Corsa"),
        d("Zopa Cyrrus Air"),
        d("Zopa Cyrrus"),
        d("Zopa Easy"),
        d("Zopa Evoque"),
        d("Zopa Falcon"),
        d("Zopa Fusion 2"),
        d("Zopa Fusion"),
        d("Zopa IQ"),
        d("Zopa Jumper Plus"),
        d("Zopa Micra"),
        d("Zopa Mion"),
        d("Zopa Move Cross"),
        d("Zopa Move"),
        d("Zopa Mystic"),
        d("Zopa Pro 3 Rider"),
        d("Zopa Quattro Fix"),
        d("Zopa Quattro Move"),
        d("Zopa Quest"),
        d("Zopa Quiq"),
        d("Zopa Smile 4"),
        d("Zopa Stratos"),
        d("Zuma Kids Explorer"),
        d("Zuma Kids Mini"),
        d("Zuma Kids Moon"),
        d("Zuma Kids Solution"),
        ["OTHER_BRAND_MODEL", "Jin\u00fd model"],
    ],
    p_color: commonProp4,
};
