import { ACTIVE_SERVER_ID } from 'mk/settings';
import { PhotoAspect, PhotoEntity, PhotoMimetype } from 'mk2/schemas';

export const READ_FEED_TIMEOUT = 2 * 1000;

// keep synced with Thread.MSGS_PER_THREAD_PAGE = 30
export const COMMENTS_PER_POST_DETAIL_PAGE = 30;

export const ARCHIVED_DAYS_WITH_COVER_PHOTO: PhotoEntity = ACTIVE_SERVER_ID === 201
    ? {
        id: 152623740,
        code: '6pU1jGPh9nX',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    } : {
        id: 214265524,
        code: 'cRSrFOOUFwsP',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    };

export const LIKED_COVER_PHOTO: PhotoEntity = ACTIVE_SERVER_ID === 201
    ? {
        id: 152623741,
        code: '3AsUxTqjcYu',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    } : {
        id: 214265525,
        code: 'coKVM8x0p1wh',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    };

export const COMMENTED_COVER_PHOTO: PhotoEntity = ACTIVE_SERVER_ID === 201
    ? {
        id: 152623743,
        code: 'O1npjAUMhw1',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    } : {
        id: 214265526,
        code: 'cnnnLi52lqa4',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    };

export const TESTINGS_COVER_PHOTO: PhotoEntity = ACTIVE_SERVER_ID === 201
    ? {
        id: 152623718,
        code: 'yflgEZoHnP9',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    } : {
        id: 214265511,
        code: 'cbwBBMj90ME6',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    };

// caka na ikonku od Jamieho
export const CONTESTS_COVER_PHOTO: PhotoEntity = ACTIVE_SERVER_ID === 201
    ? {
        id: 156081451,
        code: 'kgdI3dgT7AM',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    } : {
        id: 214980775,
        code: 'co1PaNc9VRps',
        height: 96,
        width: 96,
        aspect: PhotoAspect.SQUARE,
        mimetype: PhotoMimetype.PNG,
        versions: ['s1600x1600'],
    };

export const ANONYMOUS_POST_HELP_LINK = '/help/#anonymous-post-forum';

// keep in sync with forum/models.py
export const CONTESTS_TOPIC_ID: number = {201: 317, 202: 265}[ACTIVE_SERVER_ID];
export const CORONAVIRUS_TOPIC_ID: number = {201: 804, 202: 539}[ACTIVE_SERVER_ID];
export const UNCATEGORIZED_TOPIC_ID: number = {201: 806, 202: 540}[ACTIVE_SERVER_ID];
export const UNCATEGORIZED_TOPIC_SLUG: string = {201: 'nezaradene', 202: 'nezarazene'}[ACTIVE_SERVER_ID];
export const TESTINGS_TOPIC_ID: number = {201: 335, 202: 286}[ACTIVE_SERVER_ID];
export const COUNSELLING_CATEGORY_ID: number = {201: 45, 202: 45}[ACTIVE_SERVER_ID];
export const OTHER_CATEGORY_ID: number = {201: 47, 202: 47}[ACTIVE_SERVER_ID];
export const OTHER_CATEGORY_SLUG: string = {201: 'ostatne', 202: 'ostatni'}[ACTIVE_SERVER_ID];
export const DAYS_WITH_CATEGORY_ID: number = {201: 32, 202: 32}[ACTIVE_SERVER_ID];
export const COMMON_PLACE_CATEGORY_SLUG: string = {201: 'spolocne-miesto', 202: 'spolecne-misto'}[ACTIVE_SERVER_ID];
