import { routes as aboutRoutes } from 'mk2/apps/about/routes';
import { routes as adminclubRoutes } from 'mk2/apps/adminclub/routes';
import { routes as bazaarRoutes } from 'mk2/apps/bazaar/routes';
import { routes as blogsRoutes } from 'mk2/apps/blogs/routes';
import { routes as boRoutes } from 'mk2/apps/bo/routes';
import { routes as contestsRoutes } from 'mk2/apps/contests/routes';
import { routes as counsellingsRoutes } from 'mk2/apps/counsellings/routes';
import { routes as dashRoutes } from 'mk2/apps/dash/routes';
import { routes as faqRoutes } from 'mk2/apps/faq/routes';
import { routes as forumRoutes } from 'mk2/apps/forum/routes';
import { routes as groupsRoutes } from 'mk2/apps/groups/routes';
import { routes as heartsRoutes } from 'mk2/apps/hearts/routes';
import { routes as homeRoutes } from 'mk2/apps/home/routes';
import { routes as mailRoutes } from 'mk2/apps/mail/routes';
import { routes as planRoutes } from 'mk2/apps/plan/routes';
import { routes as settingsRoutes } from 'mk2/apps/settings/routes';
import { routes as strollersRoutes } from 'mk2/apps/strollers/routes';
import { routes as testingsRoutes } from 'mk2/apps/testings/routes';
import { routes as usersRoutes } from 'mk2/apps/users/routes';
import { routes as wallRoutes } from 'mk2/apps/wall/routes';
import { routes as wikiRoutes } from 'mk2/apps/wiki/routes';
import { Route } from 'mk2/containers/Application/Application';

export const routes: Route[] = [
    ...homeRoutes,
    ...forumRoutes,
    ...counsellingsRoutes,
    ...testingsRoutes,
    ...contestsRoutes,
    ...blogsRoutes,
    ...groupsRoutes,
    ...bazaarRoutes,
    ...usersRoutes,
    ...mailRoutes,
    ...wallRoutes,
    ...heartsRoutes,
    ...strollersRoutes,
    ...aboutRoutes,
    ...faqRoutes,
    ...wikiRoutes,
    ...boRoutes,
    ...dashRoutes,
    ...settingsRoutes,
    ...planRoutes,
    ...adminclubRoutes,
    {
        app: 'unknown',
        getPage: () => {
            return import('mk2/pages/NotFoundPage' /* webpackChunkName: "forum.NotFoundPage" */);
        },
        modules: [ 'mk2/pages/NotFoundPage' ],
    },
];
