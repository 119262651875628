/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';

import { commonProp5 } from './commonProp5';
import { commonProp4 } from './commonProp4';

export const hiking_baby_carries : {[key: string]: Array<[string, string]>} = {
    p_sex: commonProp5,
    p_brand: [
        d("4baby"),
        d("Adelay"),
        d("African Baby Carrier"),
        d("Al&Izi"),
        d("Amazonas"),
        d("Andala"),
        d("Angel wings"),
        d("Artipoppe"),
        d("Babasling"),
        d("Baby K'tan"),
        d("Baby relax"),
        d("BabyBj\u00f6rn"),
        d("Babydoo"),
        d("Babyland"),
        d("Babylonia"),
        d("BabyMonkey"),
        d("Babymoov"),
        d("Babyvak - Ji\u0159\u00ed Pech"),
        d("Baie"),
        d("be lenka"),
        d("Beco"),
        d("Bertoni"),
        d("Bethbear"),
        d("BIMBI"),
        d("Boba"),
        d("bonprix"),
        d("Brevi"),
        d("Britax"),
        d("Buzzidil"),
        d("Bykay"),
        d("Caboo"),
        d("Chicco"),
        d("Chipolino"),
        d("Cybex"),
        d("Daiesu"),
        d("Deuter"),
        d("Didymos"),
        d("DIVA Milano"),
        d("Dolce Contatto"),
        d("Donkey"),
        d("Ellevill"),
        d("Emeibaby"),
        d("Emitex"),
        d("Ergo Baby"),
        d("Esprit"),
        d("Fidella"),
        d("Fillikid"),
        d("Girasol"),
        d("Haipa Daipa"),
        d("Hama"),
        d("Hauck"),
        d("Hippychick"),
        d("Hoppediz"),
        d("ILOVEMUM"),
        d("Indajani"),
        d("Infant"),
        d("Infantino"),
        d("Inglesina"),
        d("Jan\u00e9"),
        d("Jo\u017e\u00e1nek"),
        d("JPMB"),
        d("Karaush"),
        d("KiBi"),
        d("Kiddy"),
        d("Kinderkraft"),
        d("KMENT"),
        d("Kokadi"),
        d("Kokon"),
        d("KokoSkaa"),
        d("LadyBug"),
        d("Lascal"),
        d("Lenka"),
        d("LennyLamb"),
        d("Liliputi"),
        d("Linuschka"),
        d("Little White Dandelion"),
        d("Littlefrog"),
        d("LittleLife"),
        d("Lodger"),
        d("Loktu She"),
        d("Lorelli"),
        d("Love & Carry"),
        d("Luluna"),
        d("lupilu"),
        d("Madalo"),
        d("Madame googoo"),
        d("Maggion"),
        d("MaM"),
        d("Mamas & Papas"),
        d("MaMy"),
        d("Manduca"),
        d("ManyMonths"),
        d("Marsupi"),
        d("Maxi Cosi"),
        d("Medley"),
        d("Minimonkey"),
        d("Moby"),
        d("Moisha"),
        d("Mokosh"),
        d("MoniLu"),
        d("Monza"),
        d("Mothercare"),
        d("Moyo"),
        d("Natibaby"),
        d("Noiga"),
        d("Nona"),
        d("N\u00e9obulle"),
        d("OMNIFERA"),
        d("Oscha"),
        d("osprey"),
        d("Patapum"),
        d("Pavo"),
        d("Pellicano"),
        d("Pentelka"),
        d("Petite&Mars"),
        d("Phil&Teds"),
        d("Pierre Cardin"),
        d("Pollora"),
        d("Primi sogni"),
        d("Pr\u00e9maxx"),
        d("Pr\u00e9natal"),
        d("Red Castle"),
        d("Rischino"),
        d("Safety 1st"),
        d("Salewa"),
        d("Sensimo"),
        d("Sestrice"),
        d("Skaldino"),
        d("Snugli"),
        d("Solnce"),
        d("Stokke"),
        d("Storchenwiege"),
        d("Tatonka"),
        d("Tekhni"),
        d("Therm-A-Rest"),
        d("Tigex"),
        d("Tomy"),
        d("Topgal"),
        d("Tufi"),
        d("Tula"),
        d("Vatanai"),
        d("Vaude"),
        d("Wallaboo"),
        d("We made me"),
        d("Womar Zaffiro"),
        d("yakoyako"),
        d("Yaro"),
        d("Zopa"),
        d("Zumbucca"),
        d("\u0160anami"),
        ["HANDMADE", "Handmade"],
        ["OTHER_BRAND", "Jin\u00e1 zna\u010dka"],
    ],
    p_color: commonProp4,
};
