import { History } from 'history';
import { Route } from 'mk2/containers/Application/Application';
import { memoizeOne } from 'mk2/helpers/cache';
import { HistoryLocationState } from 'mk2/services/browserHistory';
import React from 'react';

export interface RouterContextValue {
    routes: Route[];
    history: History<HistoryLocationState>;
}

export const createRouterContextValue = memoizeOne((routes: Route[], history: History<HistoryLocationState>): RouterContextValue => (
    {routes, history}
));

export const RouterContext = React.createContext<RouterContextValue>(createRouterContextValue([], null));
