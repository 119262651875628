import React from 'react';

type Callback = () => void;

interface OwnProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string;

    // enforce that width and height are mandatory. We want to:
    // - avoid page reflows when image is loaded (speed optimization)
    // - be prepared for AMP
    // - be prepared for lazy-loading images if they are not visible on screen (speed optimization)
    width: number;
    height: number;

    /** If true, image is loaded only if it comes to viewport */
    lazy?: boolean;

    imgRef?(ref: HTMLImageElement);
}

interface State {
    loaded: boolean;
}

const cache = new Set<string>();
export class Img extends React.Component<OwnProps, State> {

    public static defaultProps = {
        lazy: true,
    };

    public static observer: IntersectionObserver;
    public static instanceMap: Map<Element, Callback> = new Map();

    private image: HTMLImageElement;

    constructor(props: OwnProps) {
        super(props);

        let loaded = !this.props.lazy;
        if (cache.has(props.src)) {
            loaded = true;
        }

        this.state = {
            loaded,
        };
    }

    public shouldComponentUpdate(n: OwnProps, ns: State) {
        return this.props.className !== n.className
            || this.props.src !== n.src
            || this.props.alt !== n.alt
            || this.props.height !== n.height
            || this.props.width !== n.width
            || this.state.loaded !== ns.loaded;
    }

    public componentDidMount() {
        if (!('IntersectionObserver' in window) || // No support for IntersectionObserver (IE11)
            'loading' in HTMLImageElement.prototype) { // Browser native lazy loading
            this.setState({
                loaded: true,
            }, () => {
                cache.add(this.props.src);
            });
        } else {
            if (!Img.observer) {
                Img.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio > 0) {
                            Img.observer.unobserve(entry.target);

                            const callback = Img.instanceMap.get(entry.target);
                            if (callback) {
                                callback();
                            }
                            Img.instanceMap.delete(entry.target);
                        }
                    });
                }, {
                    rootMargin: '50px 0px',
                    threshold: 0.01,
                });
            }

            if (!this.state.loaded) {
                Img.instanceMap.set(this.image, this.onViewportScrolll);
                Img.observer.observe(this.image);
            }
        }
    }

    public componentWillUnmount() {
        if ('IntersectionObserver' in window && // Only for browsers with IntersectionObserver support
            !('loading' in HTMLImageElement.prototype)) { // but do not support native loading
            Img.instanceMap.delete(this.image);
            Img.observer.unobserve(this.image);
        }
    }

    public render() {
        const { imgRef, src, lazy, ...rest } = this.props;
        return (
            // tslint:disable-next-line:jsx-ban-elements
            <img
                ref={this.setRef}
                {...rest}
                src={this.state.loaded ? src : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='}
                data-src={!this.state.loaded ? src : undefined}
                loading={lazy ? 'lazy' : 'eager'}
            />
        );
    }

    private setRef = (r: HTMLImageElement) => {
        this.image = r;
        if (this.props.imgRef) {
            this.props.imgRef(r);
        }
    };

    private onViewportScrolll = () => {
        this.setState({
            loaded: true,
        }, () => {
            cache.add(this.props.src);
        });
    };
}
