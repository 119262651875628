import { USERNAME } from 'mk2/helpers/urls';

const TICKET_TOKEN = ':ticketToken([a-zA-Z-\\d]+)?';

export const phoneVerificationUrl = '/users/phone-verification/';
export const usersDirectoryUrl = '/users/users-directory/';

export const loginUrl = '/login/';
export const signupUrl = '/signup/';
export const oldBrowserUrl = '/old-browser/';

export const formsGalleryUrl = '/forms-gallery/';

export const usersPasswordResetUrl = '/users/password-reset/';
export const usersFeedbackPollUrl = `/users/feedback/`;
export const usersPasswordResetPhoneUrl = `${usersPasswordResetUrl}phone/`;
export const usersPasswordResetEmailUrl = `${usersPasswordResetUrl}email/`;
export const usersPasswordResetSetNewUrl = `${usersPasswordResetUrl}:token([0-9a-z]+-[0-9a-z]+-[0-9a-f]+)/`;

export const usersPampersCouponUrl = `/users/pampers-coupon/`;
export const usersPampersCouponForUserUrl = `/users/pampers-coupon/${USERNAME}/`;
export const emailChangeUrl = `/users/email-change/${USERNAME}/`;
export const usersTicketHelpUrl = `/users/help/ticket/${TICKET_TOKEN}/`;
export const usersTicketHelpMessageUrl = `/users/help/ticket/${TICKET_TOKEN}/new/`;
