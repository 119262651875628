import { ZoneTargeting } from 'mk2/containers/AdSlot/interfaces';

export interface ResponseState {
    renderedAt: number; // Timestamp when server rendered his response
    registeredSlots: { [slotId: string]: { slotId: string; zone: string; targeting: ZoneTargeting } };
}

export function responseReducer(state: ResponseState = { renderedAt: null, registeredSlots: {} }, action: any) {
    return state;
}
